@import "../../../theme/variables.scss";

.container {
  height: 100%;
  width: 100%;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  &.disabled {
    border: 1px solid var(--Color-Neutal-Neutral-40, #e0e0e0);
    background: var(--Color-Neutal-Neutral-30, #ededed);
    color: #9e9e9e;
  }
}

.refreshButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  padding: 8px;
  border-radius: 6px;
  border: 1px solid $PRIMARY_MAIN;
  width: 135px;
  align-self: flex-start;
  cursor: pointer;
  color: $PRIMARY_MAIN;

  > span {
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
}

.primary {
  background-color: $PRIMARY_MAIN;
  color: $NEUTRAL_LIGHTEST;
  border-color: $PRIMARY_MAIN;
  border-width: 1px;
  border-style: solid;
}

.primaryDisabled {
  background-color: $PRIMARY_DISABLED;
  border-color: $PRIMARY_DISABLED;
  color: $NEUTRAL_LIGHTEST;
  cursor: pointer !important;
}

.secondaryBlack {
  background-color: $NEUTRAL_LIGHTEST;
  color: $NEUTRAL_100;
  border-color: $NEUTRAL_100;
  border-width: 1px;
  border-style: solid;
}

.secondaryBlue {
  background-color: $NEUTRAL_LIGHTEST;
  color: $PRIMARY_MAIN;
  border-color: $PRIMARY_MAIN;
  border-width: 1px;
  border-style: solid;
}

.secondaryBlueTransparent {
  background-color: transparent;
  color: $PRIMARY_MAIN;
  border-color: $PRIMARY_MAIN;
  border-width: 1px;
  border-style: solid;
}

.candidateTabButton {
  padding: 10px 16px;
  border-radius: 8px;
  border: 1px solid $NEUTRAL_30;
  background: $NEUTRAL_20;
  white-space: nowrap;

  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: $NEUTRAL_60;

  transition: all 0.3s;

  &.active {
    border-color: $PRIMARY_MAIN;
    color: $PRIMARY_MAIN;
    background-color: $PRIMARY_SURFACE;
  }
}

.buttonWithIcon {
  display: flex;
  padding: 10px 12px;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  border-radius: 8px;

  > span {
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    white-space: pre;
  }

  &:first-child {
    border: 1px solid $PRIMARY_MAIN;
    background-color: $NEUTRAL_10;
    color: $PRIMARY_MAIN;
  }

  &:last-child {
    border: none;
    background-color: $PRIMARY_MAIN;
    color: $NEUTRAL_10;
  }

  &:disabled {
    background-color: $PRIMARY_DISABLED !important;
  }
}
