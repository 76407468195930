@import "../../../../../../theme/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px 5px 16px 16px;

  .information{
    display: flex;
    align-items: center;
    gap: 8px;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $NEUTRAL_100;

    .infoContractType{  
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: $PRIMARY_MAIN;
    }
  }
}
