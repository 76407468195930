@import "../../../../../../theme/variables.scss";

.container {
  @include padding-inline(32, 16, 16px);
  padding-block: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid $NEUTRAL_40;
  display: flex;
  flex-direction: column;
  gap: 32px;
  background-color: $NEUTRAL_20;
  .titleResponsive {
    display: none;
  }

  > h1 {
    font-family: Poppins;
    font-size: 38px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    /* 49.4px */
    letter-spacing: 0.19px;
    display: none;
  }

  > div {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;

      .searchbarWrapper {
        width: 36%;
        height: 40px;
      }

      > button {
        display: flex;
        align-items: center;
        gap: 4px;
        padding: 10px 12px;
        background-color: $PRIMARY_MAIN;
        border-radius: 8px;
        border: none;
        color: $NEUTRAL_10;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
      }

      .paging {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 8px;

        > span {
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }

        > button {
          display: grid;
          place-items: center;
          padding: 8px;
          border-radius: 50%;
          border: 1px solid $NEUTRAL_40;
          background-color: $NEUTRAL_10;
        }
      }
    }

    .bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      // width: 50%;
      grid-template-columns: 1fr 1fr 1fr 1fr 2fr;
      gap: 16px;
      .searchBoxResp {
        display: none;
      }
      .paging {
        display: none;
      }

      .left {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 8px;
        width: 80%;
        .dropdownBox {
          width: 22%;
        }
      }

      .descCard {
        display: flex;
        // align-items: center;
        gap: 8px;
        // width: 50%;

        > div:first-child {
          width: 100%;
          display: flex;
          flex-direction: column;

          gap: 8px;
          > div:first-child {
            display: flex;
            align-items: center;
            gap: 8px;
            // width: 100%;
            > div {
              width: 20px;
              height: 20px;
              border-radius: var(--border-radius-04, 4px);
              background: var(--Primary-Main, #1571de);
            }
            > span {
              color: #000;
              // font-family: Inter;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }

          > div:last-child {
            display: flex;
            align-items: center;
            gap: 8px;
            // width: 100%;
            > div {
              width: 20px;
              height: 20px;
              border-radius: var(--border-radius-04, 4px);
              background: #94b2ff;
            }
            > span {
              color: #000;
              // width: 100px;
              // font-family: Inter;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
        }

        > div:last-child {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 8px;
          > div:first-child {
            display: flex;
            align-items: center;
            gap: 8px;
            > div {
              width: 20px;
              height: 20px;
              border-radius: var(--border-radius-04, 4px);
              background: var(--Color-Neutal-Neutral-50, #c2c2c2);
            }
            > span {
              color: #000;
              // font-family: Inter;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }

          > div:last-child {
            display: flex;
            align-items: center;
            gap: 8px;
            > div {
              width: 20px;
              height: 20px;
              border-radius: var(--border-radius-04, 4px);
              background: var(--Color-Neutal-Neutral-10, #fff);
            }
            > span {
              color: #000;
              // font-family: Inter;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .container {
    > div {
      .top {
        .searchbarWrapper {
          width: 53%;
        }
      }
      .bottom {
        .left {
          width: 65%;
          .dropdownBox {
            width: 40%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    .titleResponsive {
      color: #000;

      /* Heading 4/Bold */
      // font-family: Poppins;
      font-size: calc(24px + (32 - 24) * ((100vw - 280px) / (1600 - 280)));
      font-style: normal;
      font-weight: 700;
      line-height: 140%; /* 44.8px */
      letter-spacing: 0.16px;
    }
    > div {
      .top {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        // padding-bottom: 24px;

        .searchbarWrapper {
          display: none;
        }
        > button {
          display: none;
        }

        .paging {
          display: none;
        }
      }
      .bottom {
        flex-direction: column;
        align-items: flex-start;
        .descCard {
          width: 100%;
        }
        .left {
          width: 100%;
          .dropdownBox {
            width: 49%;
          }
        }
        .searchBoxResp {
          display: flex;
          flex-direction: column;
          width: 100%;
          gap: 8px;
        }

        .searchbarResponsive {
          width: 99%;
          display: flex;
          height: 40px;
        }
        .buttonResponsive {
          display: flex;
          width: 99%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10px 12px;
          background-color: $PRIMARY_MAIN;
          border-radius: 8px;
          border: none;
          color: $NEUTRAL_10;
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 20px;
          gap: 4px;
        }

        .paging {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 8px;
          width: 100%;

          > span {
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
          }

          > button {
            display: grid;
            place-items: center;
            padding: 8px;
            border-radius: 50%;
            border: 1px solid $NEUTRAL_40;
            background-color: $NEUTRAL_10;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 459px) {
  .container {
    > div {
      .bottom {
        .filterTitle {
          display: flex;
          // font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 140%; /* 22.4px */
          color: var(--neutral-100, #242424);
        }
        .descCard {
          flex-direction: column;
        }
        .left {
          width: 100%;
          .dropdownBox {
            width: 48.5%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 326px) {
  .container {
    > div {
      .bottom {
        .filterTitle {
          display: flex;
          // font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 140%; /* 22.4px */
          color: var(--neutral-100, #242424);
        }
        .left {
          width: 100%;
          .dropdownBox {
            width: 48%;
          }
        }
      }
    }
  }
}
