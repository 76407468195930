@import "../../../../../../theme/variables.scss";

.containerModal {
  border-radius: 8px;
  background: var(--Color-Neutal-Neutral-10, #fff);
  width: 700px;
  // height: 600px;
  .header {
    display: flex;
    flex-direction: column;
    gap: 4px;
    border-bottom: 1px solid var(--Color-Neutal-Neutral-40, #e0e0e0);
    padding: 16px;
    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
      > span {
        color: #000;

        /* Heading 6/SemiBold */
        // font-family: Poppins;
        font-size: calc(20px + (24 - 20) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 33.6px */
        letter-spacing: 0.12px;
      }

      .iconCross {
        cursor: pointer;
      }
    }

    > span {
      color: var(--Neutral-60, var(--Color-Neutal-Neutral-60, #9e9e9e));

      /* Caption 1/Reguler */
      //   font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 133.333% */
    }
  }

  .contents {
    padding: 16px;
    max-height: 430px;
    min-height: 430px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 24px;
    .intro {
      display: flex;
      //   align-items: center;
      justify-content: space-between;
      gap: 16px;

      .stackedIntro {
        display: flex;
        // align-items: center;
        gap: 8px;
        width: 100%;

        > div:first-child {
          width: 15%;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #f5f5f5;
          border-radius: 4px;
          padding: 8px;
          img {
            width: 16px;
            height: 16px;
          }
        }

        > div:last-child {
          display: flex;
          flex-direction: column;
          gap: 2px;
          background-color: #f5f5f5;
          border-radius: 4px;
          padding: 8px;
          width: 100%;
          > span:first-child {
            color: var(--Neutral-60, var(--Color-Neutal-Neutral-60, #9e9e9e));

            /* Caption 1/Reguler */
            // font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px; /* 133.333% */
          }

          > span:last-child {
            color: #000;

            /* Text M/Medium */
            // font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 142.857% */
          }
        }
      }
    }

    .formBox {
      display: flex;
      flex-direction: column;
      gap: 16px;
      .scoring {
        display: flex;
        flex-direction: column;
        gap: 8px;
        > span:first-child {
          color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));

          /* Text M/Medium */
          //   font-family: Poppins;
          font-style: normal;
          font-size: 13px;
          font-weight: 600;
          line-height: 20px; /* 142.857% */
        }

        .card {
          padding: 12px;
          border-radius: 8px;
          background-color: $NEUTRAL_20;
          display: flex;
          flex-direction: column;
          gap: 16px;
          &.redBorder {
            border: 1px solid var(--Danger-Main, #ff3e13);
          }
          > span {
            display: flex;
            align-items: center;
            justify-content: center;
            color: var(--Neutral-50, var(--Color-Neutal-Neutral-50, #c2c2c2));
            text-align: center;

            /* Text S/Reguler */
            // font-family: Poppins;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 153.846% */
          }

          .each {
            display: flex;
            align-items: center;
            gap: 16px;

            > span {
              flex-grow: 1;
              font-family: Poppins;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 16px;
              color: $NEUTRAL_90;
            }

            .indicators {
              display: flex;
              align-items: center;
              justify-content: space-between;
              position: relative;
              width: 400px;

              .line {
                position: absolute;
                width: 100%;
                height: 2px;
                background-color: $NEUTRAL_40;
              }

              .indicator {
                width: 20px;
                aspect-ratio: 1;
                border-radius: 50%;
                background-color: $NEUTRAL_20;
                z-index: 1;
                display: grid;
                place-items: center;
                border: 1px solid $NEUTRAL_60;
                font-family: Poppins;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                color: $NEUTRAL_60;
                cursor: pointer;
                transition: all 0.3s;

                &:hover {
                  background-color: $PRIMARY_SURFACE;
                  color: $PRIMARY_MAIN;
                  border-color: $PRIMARY_MAIN;
                }

                &.active {
                  background-color: $PRIMARY_MAIN;
                  border-color: $PRIMARY_MAIN;
                  color: $NEUTRAL_10;
                }
              }

              // .section {
              //   .divider {
              //     width: 40px;
              //     height: 2px;
              //     background-color: $NEUTRAL_30;
              //   }

              // }
            }
          }
        }

        > span:last-child {
          font-size: 12px;
          color: #ff3e13;
        }
      }

      .imagesBox {
        > span:last-child {
          font-size: 12px;
          color: #ff3e13;
        }
      }

      .stepper {
        display: flex;
        flex-direction: column;
        gap: 8px;
        > span:first-child {
          color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));

          /* Text M/Medium */
          //   font-family: Poppins;
          font-style: normal;
          font-size: 13px;
          font-weight: 600;
          line-height: 20px; /* 142.857% */
        }
        > div {
          display: flex;
          align-items: center;
          gap: 8px;
          > div {
            width: 100%;
            display: flex;
            align-items: center;
            gap: 8px;
            border-radius: var(--spacing-12px, 12px);
            border: 1px solid var(--Color-Neutal-Neutral-30, #ededed);
            padding: 12px;
            > span:first-child {
              color: #000;
              text-align: center;

              /* Text M/Reguler */
              //   font-family: Poppins;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px; /* 142.857% */
            }

            &.borderRed {
              border: 1px solid var(--Danger-Main, #ff3e13);
            }
          }
        }

        > span:last-child {
          font-size: 12px;
          color: #ff3e13;
        }
      }
    }
  }
  .buttonBox {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 4px;
    padding: 16px;
    border-top: 1px solid var(--Color-Neutal-Neutral-40, #e0e0e0);
    > span {
      font-size: 12px;
      color: #ff3e13;
    }
    .buttonSection {
      display: flex;
      align-items: center;
      gap: 16px;
      > div {
        width: 100%;
        cursor: pointer;
      }
      > div:first-child {
        padding: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        border: 1px solid var(--Neutral-100, #0a0a0a);
        background: var(--Neutral-10, #fff);
        color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));
        text-align: center;

        /* Text M/Reguler */
        // font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
      }

      > div:last-child {
        padding: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        background: var(--Primary-Main, #1571de);
        color: var(--Color-Neutal-Neutral-10, #fff);
        text-align: center;

        /* Text M/Reguler */
        // font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        &.disabled {
          background: var(--Color-Neutal-Neutral-20, #f5f5f5);
          color: #c2c2c2;
        }
      }
    }
  }
}

.containerNotif {
  display: flex;
  flex-direction: column;
  gap: 32px;
  border-radius: 8px;
  background: var(--Color-Neutal-Neutral-10, #fff);
  padding: 24px;
  width: 500px;
  > div:first-child {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    > div:first-child {
      img {
        width: 157.497px;
        height: 160.868px;
      }
    }

    > div {
      display: flex;
      flex-direction: column;
      gap: 8px;
      > span:first-child {
        color: var(--Primary-Main, #1571de);
        text-align: center;

        /* Heading 6/Bold */
        // font-family: Poppins;
        font-size: calc(20px + (24 - 20) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 700;
        line-height: 140%; /* 33.6px */
        letter-spacing: 0.12px;
      }

      > span:last-child {
        color: var(--Color-Neutal-Neutral-90, #404040);
        text-align: center;

        /* Text M/Reguler */
        // font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
      }
    }
  }

  > div:last-child {
    display: flex;
    align-items: center;
    gap: 16px;
    > div {
      width: 100%;
      cursor: pointer;
    }
    > div:first-child {
      padding: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      border: 1px solid var(--Neutral-100, #0a0a0a);
      background: var(--Neutral-10, #fff);
      color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));
      text-align: center;

      /* Text M/Reguler */
      // font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
    }

    > div:last-child {
      padding: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      background: var(--Primary-Main, #1571de);
      color: var(--Color-Neutal-Neutral-10, #fff);
      text-align: center;

      /* Text M/Reguler */
      // font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      //   &.disabled {
      //     background: var(--Color-Neutal-Neutral-20, #f5f5f5);
      //     color: #c2c2c2;
      //   }
    }
  }
}

.containerReject {
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-radius: 8px;
  background: var(--Color-Neutal-Neutral-10, #fff);
  padding: 16px;
  width: 500px;
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    > img {
      width: 100px;
      aspect-ratio: 1;
      object-fit: contain;
    }

    .desc {
      display: flex;
      flex-direction: column;
      gap: 4px;
      text-align: center;

      > h3 {
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
        letter-spacing: 0.12px;
      }

      > p {
        font-family: Poppins;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        color: $NEUTRAL_70;
      }
    }
  }

  .buttonBox {
    display: flex;
    flex-direction: column;
    gap: 4px;
    > span:first-child {
      color: red;
      font-size: 12px;
    }
    .actions {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 16px;

      > button {
        padding: 12px;
        border-radius: 8px;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        background-color: $NEUTRAL_10;
        border: 1px solid $PRIMARY_MAIN;
        color: $PRIMARY_MAIN;

        &:hover {
          background-color: $PRIMARY_SURFACE;
        }

        &:last-child {
          background-color: $PRIMARY_MAIN;
          color: $NEUTRAL_10;

          &:disabled {
            background-color: $PRIMARY_DISABLED;
            border-color: $PRIMARY_DISABLED;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px){
  .containerModal{
    .contents{
      max-height: 350px;
      min-height: 350px;
    }
  }

}

@media screen and (max-width: 600px) {
  .containerModal {
    .contents {
      .intro {
        flex-direction: column;
      }

      .formBox {
        .scoring {
          .card {
            .each {
              flex-direction: column;
              align-items: flex-start;
              .indicators {
                width: 100%;
              }
            }
          }
        }

        .stepper {
          > div {
            flex-direction: column;
          }
        }
      }
    }
  }
}
