.body {
  width: 650px;
  border-radius: 8px;
  background: var(--Color-Neutal-Neutral-10, #fff);
  box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.12);
  max-height: 600px;
  height: 570px;
  overflow-y: scroll;
  > div:first-child {
    display: flex;
    flex-direction: column;
    gap: 24px;

    position: sticky;
    top: 0;
    background: var(--Color-Neutal-Neutral-10, #fff);
    z-index: 3;
    border-radius: 8px 8px 0px 0px;
    > div:first-child {
      display: flex;
      align-items: center;
      gap: 16px;
      justify-content: space-between;
      padding: 16px 16px 0px 16px;
      .left {
        display: flex;
        align-items: center;
        gap: 8px;
        > div:first-child {
          width: 20px;
        }
        > div:last-child {
          color: var(--Neutral-100, #0a0a0a);

          /* Text L/SemiBold */
          //   font-family: Poppins;
          font-size: calc(14px + (16 - 14) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 600;
          line-height: 24px; /* 150% */
        }
      }

      .close {
        cursor: pointer;
      }
    }
    > div:last-child {
      display: flex;
      //   align-items: center;
      //   gap: 24px;
      border-bottom: 1px solid #dedddd;
      max-width: 120%;
      overflow-x: scroll;

      > div {
        padding-bottom: 16px;
        padding-left: 16px;
        padding-right: 16px;
        // border-bottom: 1px solid #9e9e9e;
        color: var(--Neutral-50, #c2c2c2);

        /* Text M/Medium */
        // font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
        // min-width: 200px;
        text-align: center;
        cursor: pointer;
        &.active {
          color: #1571de;
          border-bottom: 1px solid #1571de;
        }
      }
    }
  }

  .firstStep {
    padding: 16px 16px 16px 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    > div {
      display: flex;
      //   align-items: center;
      justify-content: space-between;
      width: 100%;
      gap: 24px;
      > span:first-child {
        color: var(--Neutral-60, var(--Color-Neutal-Neutral-60, #9e9e9e));

        /* Text M/Regular */
        // font-family: Poppins;
        font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        width: 50%;
      }
      > span:last-child {
        color: var(--Neutral-100, #0a0a0a);

        /* Text M/Regular */
        font-family: Poppins;
        font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        width: 50%;
      }
    }
  }

  .secondStep {
    padding: 16px 16px 16px 16px;
    .cards {
      display: flex;
      flex-direction: column;
      gap: 8px;
      > div {
        display: flex;
        // align-items: center;
        gap: 8px;
        .card {
          border-radius: 8px;
          border: 1px solid var(--Color-Neutal-Neutral-30, #ededed);
          background: var(--Color-Neutal-Neutral-10, #fff);
          padding: 16px;
          width: 50%;
          display: flex;
          flex-direction: column;
          gap: 24px;

          position: relative;
          > span {
            color: var(--Neutral-100, #0a0a0a);

            /* Text L/SemiBold */
            // font-family: Poppins;
            font-size: calc(
              14px + (16 - 14) * ((100vw - 280px) / (1600 - 280))
            );
            font-style: normal;
            font-weight: 600;
            line-height: 24px; /* 150% */
          }

          > div {
            display: flex;
            flex-direction: column;
            gap: 4px;
            align-items: center;
            position: relative;
            > div:first-child {
              img {
                width: 48px;
                height: 48px;
              }
            }

            > div:nth-child(2) {
              color: var(--Neutral-100, #0a0a0a);
              text-align: center;
              //   font-family: Poppins;
              font-size: calc(
                12px + (14 - 12) * ((100vw - 280px) / (1600 - 280))
              );
              font-style: normal;
              font-weight: 400;
              line-height: 140%; /* 19.6px */
            }

            > div:last-child {
              color: var(--Primary-Main, #1571de);
              text-align: center;

              /* Text M/Medium */
              //   font-family: Poppins;
              font-size: calc(
                12px + (14 - 12) * ((100vw - 280px) / (1600 - 280))
              );
              cursor: pointer;
              font-style: normal;
              font-weight: 500;
              line-height: 20px; /* 142.857% */
              // position: absolute;
              // bottom: 0;

              &.noActive {
                color: #9e9e9e;
              }
            }
          }
        }
      }
    }
  }

  .thirdStep {
    padding: 16px;
    > div {
      border-radius: 8px;
      border: 1px solid var(--Color-Neutal-Neutral-30, #ededed);
      background: var(--Color-Neutal-Neutral-10, #fff);
      padding: 16px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      > span:first-child {
        color: var(--Neutral-100, #0a0a0a);

        /* Text L/SemiBold */
        // font-family: Poppins;
        font-size: calc(14px + (16 - 14) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 22.4px */
      }

      > span:last-child {
        color: var(--Neutral-100, #0a0a0a);

        /* Text M/Reguler */
        // font-family: Poppins;
        font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .body {
    > div:first-child {
      > div:last-child {
        > div {
          min-width: 200px;
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .body {
    .secondStep {
      .cards {
        > div {
          flex-direction: column;
          .card {
            width: 100%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 468px) {
  .body {
    .firstStep {
      > div {
        flex-direction: column;
        gap: 8px;
        > span:first-child {
          width: 100%;
        }
        > span:last-child {
          width: 100%;
        }
      }
    }
  }
}
