@import "../../../../../../theme/variables.scss";

.container {
  z-index: inherit!important;

  .header {
    padding-bottom: 16px;
    display: flex;
    align-items: center;
    gap: 16px;

    h3 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 150%;
      letter-spacing: 0.005em;
      color: $PRIMARY_MAIN;
      flex-grow: 1;
    }

    button {
      display: flex;
      align-items: center;
      gap: 8px;
      border: none;
      background-color: $PRIMARY_MAIN;
      padding: 8px 12px;
      border-radius: 8px;
      min-height: 36px;

      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $NEUTRAL_10;
    }
  }

  .content {
    background: $NEUTRAL_LIGHTEST;
    border-radius: 8px;
    padding: 24px;
    grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
    display: grid;
    gap: 16px;

    .divider {
      display: flex;
      align-items: center;
      gap: 16px;

      > * {
        width: 100%;
        gap: 16px;
      }
    }
  }
}

.docCard {
  padding: 24px;
  border: 1px solid #E0E0E0;
  border-radius: 8px;


  h4 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
    letter-spacing: 0.005em;
    color: $NEUTRAL_100;
    padding-bottom: 24px;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
    gap: 0;

    img {
      height: 48px;
      aspect-ratio: 1;
      object-fit: contain;
    }

    p {
      font-family: 'Poppins';
      font-style: normal;
      text-align: center;
      color: $NEUTRAL_100;
      padding-top: 16px;
      padding-bottom: 2px;

      &.withDocument {
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
      }

      &.noDocument {
        font-weight: 600;
        font-size: 16px;
        line-height: 140%;
      }
    }

    button {
      background-color: transparent;
      border: none;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: $PRIMARY_MAIN;
    }

    span {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: $NEUTRAL_60;
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    .header {
      padding-bottom: 8px;
    }

    .content {
      padding: 0;
      gap: 10px;
      background-color: transparent;
      grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));

      .divider {
        flex-direction: column;
        gap: 10px;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .container {

    .header {
      >button {
        >span {
          display: none;
        }
      }
    }
  }
}