@import "../../../../theme/variables.scss";

.background {
  width: 100vw;
  height: 100%;
  background-color: $NEUTRAL_OVERLAY;
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  margin-inline: 0 !important;

  .container {
    width: 548px;
    background: $NEUTRAL_10;
    border-radius: 8px;
    max-height: calc(100vh - 50px);
    max-width: calc(100vw - 32px);
    overflow-y: auto;
    position: relative;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 24px 24px 16px 24px;
      border-bottom: 1px solid $NEUTRAL_40;

      p {
        font-weight: 600;
        font-size: 24px;
      }
      & > :last-child {
        cursor: pointer;
      }
    }
    .body {
      padding: 16px 24px 24px 24px;
      .listWrapper {
        display: flex;
        flex-direction: column;
        gap: 10px;
        .list {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 8px;
          border: 1px solid $NEUTRAL_40;
          border-radius: 8px;
          cursor: pointer;
          p {
            font-weight: 400;
            font-size: 14px;
          }
          & > :last-child {
            background: $PRIMARY_SOFT;
            color: $PRIMARY_MAIN;
            padding: 2px 8px;
            border-radius: 4px;
          }
        }
        .list:hover {
          background: $PRIMARY_SURFACE;
          border: 1px solid $PRIMARY_MAIN;
          color: $PRIMARY_MAIN;
          & > :last-child {
            background: $PRIMARY_MAIN;
            color: $NEUTRAL_10;
          }
        }
      }
    }
  }
}
