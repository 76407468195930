@import "../../../theme/variables.scss";

.container {
  display: block;
  position: relative;
  //   padding-left: 35px;
  // margin-bottom: 20px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  display: flex;
  // position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  display: flex;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  // background-color: $NEUTRAL-40;
  border: 1px solid #c2c2c2;
  border-radius: 50px;
}

/* On mouse-over, add a grey background color */
// .container:hover input ~ .checkmark {
//   // background-color: $NEUTRAL-40;
// }

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  // background-color: black;
  // border: none;
  color: $NEUTRAL_10;
}
.container input:disabled ~ .checkmark {
  background-color: $NEUTRAL_20;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 4px;
  top: 4px;
  width: 12px;
  height: 12px;
  background: #1571de;

  border-radius: 50px;
}

.containerRecrutment {
  display: block;
  position: relative;
  //   padding-left: 35px;
  // margin-bottom: 20px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.containerRecrutment input {
  display: flex;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmarkRecrutment {
  display: flex;
  // position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  // background-color: $NEUTRAL-40;
  border: 1px solid #c2c2c2;
  border-radius: 6px;
}

/* On mouse-over, add a grey background color */
.containerRecrutment:hover input ~ .checkmarkRecrutment {
  background-color: $NEUTRAL-40;
}

/* When the checkbox is checked, add a blue background */

.containerRecrutment input:checked ~ .checkmarkRecrutment {
  background-color: $PRIMARY_MAIN;
}

.containerRecrutment input:disabled ~ .checkmarkRecrutment {
  background-color: $NEUTRAL_20;
}

.containerRecrutment input ~ .readOnly {
  background-color: #1571de !important;
  opacity: 0.5;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmarkRecrutment:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.containerRecrutment input:checked ~ .checkmarkRecrutment:after {
  display: block;
}

/* Style the checkmark/indicator */
.containerRecrutment .checkmarkRecrutment:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.containerOther {
  display: flex;
  position: relative;
  // position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: $NEUTRAL-40;
  border: 1px solid #c2c2c2;
  border-radius: 6px;
  .disabled {
    position: absolute;

    left: 5px;
    top: 0px;
    width: 9px;
    height: 14px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
