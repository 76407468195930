@import "../../../theme/variables.scss";

.container {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 4px;
  border-radius: 8px;
  background-color: $NEUTRAL_20;
  border: 1px solid $NEUTRAL_30;

  >button {
    width: 50%;
    background-color: transparent;
    border: none;
    padding: 8px 4px;
    border-radius: 8px;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: $NEUTRAL_60;
    transition: all 0.3s ease-in-out;

    &.active {
      background-color: $PRIMARY_MAIN;
      color: $NEUTRAL_10;
    }
  }
}