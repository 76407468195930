@import "../../../../../../../theme/variables.scss";


.container {
  display: flex;
  flex-direction: column;
  gap: 16px;

  >* {
    border: 1px solid $NEUTRAL-30!important;
  }
}