@import "../../../theme/variables.scss";

.container {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  transition: all 0.3s ease-in-out;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    background-color: $PRIMARY_SURFACE;
  }

  &.active {
    background-color: $PRIMARY_MAIN;

    .id {
      background-color: $NEUTRAL_10;
      color: $PRIMARY_MAIN;
    }

    .label {
      span {
        color: $NEUTRAL_10!important;
      }
    }
  }

  .id {
    width: 32px;
    aspect-ratio: 1;
    border-radius: 50%;
    flex-shrink: 0;
    background-color: $NEUTRAL_30;
    display: grid;
    place-items: center;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: $NEUTRAL_100;
  }

  .label {
    display: flex;
    flex-direction: column;

    span {
      font-family: Poppins;
      font-style: normal;

      &:first-child {
        color: $NEUTRAL_DARKEST;
        font-size: 13px;
        font-weight: 500;
        line-height: 20px;
      }

      &:last-child {
        color: $NEUTRAL_60;
        font-family: Poppins;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
      }
    }
  }

}