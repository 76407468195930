@import "../../../../theme/variables.scss";

.container {
  background-color: $NEUTRAL-10;
  width: 900px;
  max-width: 90vw;
  border-radius: 8px;
  overflow: clip;
  height: 700px;
  max-height: 85vh;
  display: flex;
  flex-direction: column;

  .header {
    padding: 16px;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $PRIMARY_SURFACE;

    .hTop {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 16px;

      >h3 {
        flex-grow: 1;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
        color: $PRIMARY_MAIN;
      }

      >button {
        display: grid;
        place-items: center;
        background-color: transparent;
        border: none;
      }
    }

    >img {
      height: 139px;
    }
  }

  .content {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 100px;
    flex-grow: 1;
    overflow: auto;

    .cTitle {
      display: grid;
      grid-template-columns: 1fr auto;

      >h4 {
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
      }

      >span {
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 133.333%;
        color: $NEUTRAL-50;
      }
    }



    .detailsForm {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .dCity {
        display: grid;
        grid-template-columns: 6fr 2fr 2fr auto;
        gap: 8px;

        >*:last-child {
          display: flex;
          align-items: flex-end;
          padding-bottom: 12px;

          >button {
            padding: none;
            background-color: transparent;
            border: none;
            color: $DANGER_MAIN;

            &:disabled {
              color: $NEUTRAL-50;
            }
          }
        }
      }

      >button {
        padding: 8px;
        border-radius: 6px;
        background-color: transparent;
        border: 1px solid $PRIMARY_MAIN;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        color: $PRIMARY_MAIN;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 142.857%;
      }
    }

    .qualificationsForm {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .allCitiesCheckbox {
        display: flex;
        align-items: center;
        gap: 8px;

        >span {
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 142.857%;
          cursor: pointer;
        }
      }

      .fields {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .perQualification {
          display: grid;
          grid-template-columns: 3fr 5fr 250px auto;
          gap: 8px;


          >*:last-child {
            display: flex;
            align-items: flex-end;
            padding-bottom: 12px;

            >button {
              padding: none;
              background-color: transparent;
              border: none;
              color: $DANGER_MAIN;

              &:disabled {
                color: $NEUTRAL-50;
              }
            }
          }
        }

        >button {
          padding: 8px;
          border-radius: 6px;
          background-color: transparent;
          border: 1px solid $PRIMARY_MAIN;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 4px;
          color: $PRIMARY_MAIN;
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 142.857%;
        }
      }
    }
  }

  .actions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
    padding: 16px;

    >button {
      padding: 10px 16px;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 142.857%;
      border: 1px solid $PRIMARY_MAIN;
      border-radius: 8px;

      &:first-child {
        background-color: $NEUTRAL-10;
        color: $PRIMARY_MAIN;
      }

      &:last-child {
        background-color: $PRIMARY_MAIN;
        color: $NEUTRAL-10;

        &:disabled {
          border-color: $PRIMARY_DISABLED;
          background-color: $PRIMARY_DISABLED;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    max-width: 100vw;

    .content {

      .detailsForm {

        .dCity {
          position: relative;
          grid-template-areas:
            "a a a"
            "b c c"
          ;

          &:not(:nth-last-child(2), :last-child) {
            border-bottom: 1px solid $NEUTRAL-40;
            padding-bottom: 16px;
          }

          >* {

            &:first-child {
              grid-area: a;
            }

            &:nth-child(2) {
              grid-area: b;
            }

            &:nth-child(3) {
              grid-area: c;
            }

            &:last-child {
              position: absolute;
              top: 0;
              right: 0;
            }
          }


        }
      }

      .qualificationsForm {

        .fields {

          .perQualification {
            position: relative;
            display: grid;
            grid-template-areas:
              "d d d"
              "f f f"
              "e e e";

            &:not(:nth-last-child(2), :last-child) {
              border-bottom: 1px solid $NEUTRAL-40;
              padding-bottom: 16px;
            }

            >* {

              &:first-child {
                grid-area: d;
              }

              &:nth-child(2) {
                grid-area: e;
              }

              &:nth-child(3) {
                grid-area: f;
              }

              &:last-child {
                position: absolute;
                top: 0;
                right: 0;
              }
            }

          }
        }
      }
    }

    .actions {
      padding-bottom: 45px;
    }
  }
}