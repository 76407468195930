$PRIMARY_MAIN: #1571de;
$SECONDARY_MAIN: #27bb83;
$PRIMARY_LINEAR: linear-gradient(104.04deg, #1571de 0%, #4b92f1 100%);
$PRIMARY_DISABLED: #91bdf1;
$PRIMARY_SOFT: #e1edfe;
$PRIMARY_HOVER: #125eb9;
$PRIMARY_SURFACE: #f5f7fe;
$PRIMARY_SURFACE_HOVER: #f1f3ff95;
$SECONDARY_SOFT: #eafef3;
$NEUTRAL_LIGHTEST: #ffffff;
$NEUTRAL_LIGHTER: #fafafa;
$NEUTRAL_10: #ffffff;
$NEUTRAL_20: #f5f5f5;
$NEUTRAL_30: #ededed;
$NEUTRAL_40: #e0e0e0;
$NEUTRAL_50: #c2c2c2;
$NEUTRAL_60: #9e9e9e;
$NEUTRAL_70: #757575;
$NEUTRAL_80: #616161;
$NEUTRAL_90: #404040;
$NEUTRAL_100: #0a0a0a;
$NEUTRAL_DARKEST: #000000;
$DANGER_MAIN: #ff3e13;
$DANGER_OUTLINE_BORDER: #fc86a2;
$DANGER_SURFACE: #faeff2;
$DANGER_SOFT: #fff4f2;
$NEUTRAL_BOX_SHADOW: #035fcb24;
$NEUTRAL_OVERLAY: #00000080;
$NEUTRAL_BACKGROUND: #f4f4f4;
$NEUTRAL_LINE: 1px solid #e1d9d9;
$TEXTAREA_ICON_BG: #f5f6f8;

$PICTON_BLUE: #51cbee;
$BORDER_INPUT_ONFOCUS: 1px solid #51cbee;
$BOX_SHADOW_INPUT_ONFOCUS: 0 0 5px #51cbee;

$DARK_CHARCOAL: #333333;
$PLATINUM: #ece6e6;
$CHINESE_BLACK: #0f161f;
$TAUPE_GRAY: #878787;
$ALICE_BLUE: #f3f9ff;
$PLATINUM_SOLID: #e8e8e8;
$ROSSO_CORSA: #d90707;
$MISTY_ROSE: #ffe0e0;

$ACTIVE_MAIN: #96ab12;
$ACTIVE_SURFACE: #fef8f0;
$SUCCESS_MAIN: #27bb83;
$SUCCESS_SURFACE: #eafef3;
$ALERT_SURFACE: #fff9e9;

// BS TOOLS COLOR STYLES BELOW

$BS_NEUTRAL_10: #ffffff;
$BS_NEUTRAL_20: #fafafa;
$BS_NEUTRAL_30: #ededed;
$BS_NEUTRAL_40: #e0e0e0;
$BS_NEUTRAL_50: #c2c2c2;
$BS_NEUTRAL_60: #9e9e9e;
$BS_NEUTRAL_70: #757575;
$BS_NEUTRAL_80: #616161;
$BS_NEUTRAL_90: #404040;
$BS_NEUTRAL_100: #0a0a0a;

$BS_PRIMARY_MAIN: #1571de;
$BS_PRIMARY_SURFACE: #f5f7fe;
$BS_PRIMARY_HOVER: #125eb9;
$BS_PRIMARY_PRESSED: #0e4b94;
$BS_PRIMARY_DISABLE: #b1d0f4;

$BS_DANGER_MAIN: #d42701;
$BS_DANGER_SURFACE: #f02c011a;
$BS_DANGER_OUTLINE: #fa2d00;

$BS_ALERT_MAIN: #e9b62f;
$BS_ALERT_SURFACE: #fff9e9;
$BS_ALERT_OUTLINE: #f4da97;

$BS_SUCCESS_MAIN: #21960f;
$BS_SUCCESS_SURFACE: #22970f1a;
$BS_SUCCESS_OUTLINE: #f4fff2;

$BS_STROKE: #ededed;

@mixin width($max, $min, $minpx) {
  width: calc($minpx + ($max - $min) * ((100vw - 390px) / (1440 - 390)));
}
@mixin height($max, $min, $minpx) {
  height: calc($minpx + ($max - $min) * ((100vw - 390px) / (1440 - 390)));
}

@mixin font-size($max, $min, $minpx) {
  font-size: calc($minpx + ($max - $min) * ((100vw - 390px) / (1440 - 390)));
}

@mixin fluid-image-size($max, $min, $minpx) {
  width: calc($minpx + ($max - $min) * ((100vw - 390px) / (1440 - 390)));
  height: 100%;
}

@mixin gap($max, $min, $minpx) {
  gap: calc($minpx + ($max - $min) * ((100vw - 390px) / (1440 - 390)));
}
@mixin row-gap($max, $min, $minpx) {
  row-gap: calc($minpx + ($max - $min) * ((100vw - 390px) / (1440 - 390)));
}
@mixin column-gap($max, $min, $minpx) {
  column-gap: calc($minpx + ($max - $min) * ((100vw - 390px) / (1440 - 390)));
}
@mixin padding($max, $min, $minpx) {
  padding: calc($minpx + ($max - $min) * ((100vw - 390px) / (1440 - 390)));
}
@mixin padding-block($max, $min, $minpx) {
  padding-block: calc($minpx + ($max - $min) * ((100vw - 390px) / (1440 - 390)));
}

@mixin padding-inline($max, $min, $minpx) {
  padding-inline: calc($minpx + ($max - $min) * ((100vw - 390px) / (1440 - 390)));
}
@mixin padding-top($max, $min, $minpx) {
  padding-top: calc($minpx + ($max - $min) * ((100vw - 390px) / (1440 - 390)));
}
@mixin top($max, $min, $minpx) {
  top: calc($minpx + ($max - $min) * ((100vw - 390px) / (1440 - 390)));
}
@mixin padding-bottom($max, $min, $minpx) {
  padding-bottom: calc($minpx + ($max - $min) * ((100vw - 390px) / (1440 - 390)));
}
@mixin padding-left($max, $min, $minpx) {
  padding-left: calc($minpx + ($max - $min) * ((100vw - 390px) / (1440 - 390)));
}
@mixin left($max, $min, $minpx) {
  left: calc($minpx + ($max - $min) * ((100vw - 390px) / (1440 - 390)));
}
@mixin padding-right($max, $min, $minpx) {
  padding-right: calc($minpx + ($max - $min) * ((100vw - 390px) / (1440 - 390)));
}
