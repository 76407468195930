@import "../../../../../../../../../../../theme/variables.scss";

.container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 16px;

  .boxInput {

    .dualInputWrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 8px;
      align-items: end;
    }
    
    .label {
      padding-bottom: 4px;
      font-size: 13px;
      font-weight: 500;
      color: $NEUTRAL_100;

      span {
        color: $DANGER_MAIN;
      }
    }

    .selectWrapper {
      padding-bottom: 4px;
    }

    .allowancesWrapper,
    .equipmentsWrapper {
      padding-top: 8px;
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }

  .buttonWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;

    button {
      display: flex;
      align-items: center;
      gap: 6px;
      padding: 10px;
      padding-left: 16px;
      border: none;
      border-radius: 20px;
      background-color: $PRIMARY_SOFT;
      color: $PRIMARY_MAIN;
      transition: all 0.3s;

      span {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
      }

      &:disabled {
        color: $NEUTRAL_60;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    grid-template-columns: 1fr;

    .boxInput {

      .dualInputWrapper {
        grid-template-columns: 1fr;
      }
    }

  }
}
