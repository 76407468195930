.content {
  display: flex;
  flex-direction: column;
  gap: 8px;
  .locationRow {
    display: flex;
    align-items: center;
    // gap: 8px;
    .radio {
      width: 40px;
    }
    > span {
      width: 100%;
      color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));

      /* Text S/Reguler */
      //   font-family: Poppins;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 153.846% */
    }
  }
}
