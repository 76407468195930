@import "../../../theme/variables.scss";

.container {
  // flex-grow: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  &.height {
    height: 100px;
  }

  span {
    &:first-child {
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      letter-spacing: 0.1px;
    }

    &:last-child {
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: $NEUTRAL_60;
    }
  }
}
