@import "../../../theme/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 4px;

  .title {
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  .input {
    display: grid;
    grid-template-columns: 1fr 1fr 32px;
    gap: 8px;

    > div {
      &:last-child {
        display: grid;
        place-items: center;

        > button {
          display: grid;
          place-items: center;
          padding: 0;
          margin-left: 8px;
          background-color: transparent;
          border: none;
          color: $DANGER_MAIN;
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .container {
    .input {
      grid-template-areas:
        "one three"
        "two three";
      grid-template-columns: 1fr 40px;

      > * {
        &:first-child {
          grid-area: one;
        }

        &:nth-child(2) {
          grid-area: two;
        }

        &:last-child {
          grid-area: three;
        }
      }
    }
  }
}
