@import "../../../../../../../theme/variables.scss";

.container {

  h3 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: $NEUTRAL_DARKEST;
    padding-bottom: 10px;

  }

  .section {

    &:nth-child(2) {
      padding-bottom: 20px;
    }

    >.checkboxWrapper {
      background: #F7F7F7;
      border-radius: 8px;
      padding: 13px;
    }

    >.textAreaWrapper {
      padding-top: 15px;
    }

    h4 {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: $NEUTRAL_DARKEST;
      padding-bottom: 15px;
    }

    .content {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      row-gap: 20px;
      column-gap: 15px;
      padding-top: 22px;

      .stackedField {
        display: flex;
        flex-direction: column;
        gap: 11px;
      }

      .autocompleteFieldWrapper {

        >:first-child {
          padding-bottom: 4px;
          font-size: 13px;
          font-weight: 600;
          color: $NEUTRAL_100;

        }
      }

      .floatingText {

        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        text-align: center;
        color: #A3A3A3;
      }
    }
  }


}

@media screen and (max-width: 768px) {
  .content {
    grid-template-columns: 1fr!important;
  }
}