@import "../../../../../../theme/variables.scss";

.container {
  width: 100%;
  overflow: auto;

  .topWrapper {
    display: flex;
    align-items: center;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    .breadcrumbWrapper {
      padding: 24px 30px;
      padding-right: 0px;
      display: flex;
      align-items: center;
      gap: 24px;
      background-color: $NEUTRAL_LIGHTER;
      border-top: 1px solid $NEUTRAL_30;
      flex-grow: 1;
  
      >button {
        height: 40px;
        aspect-ratio: 1;
        background-color: $NEUTRAL_10;
        border-radius: 50%;
        border: none;
      }
  
      span {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
  
  
        &.breadcrumbText {
          color: $NEUTRAL_60;
          cursor: pointer;
        }
  
        &.breadcrumbTextBold {
          color: $NEUTRAL_100;
        }
      }
    }
  
    .typeWrapper {
      background-color: $NEUTRAL_20;
      border: 1px solid $NEUTRAL_40;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 15px;
      padding: 8px;
      margin-right: 24px;
      white-space: nowrap;
      text-overflow: ellipsis;
      height: calc(100% - 28px);
  
      .descWrapper {
        display: flex;
        flex-direction: column;
  
        >span:first-child {
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 140%;
          color: $NEUTRAL_100;
        }
  
        >p:last-child {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: $NEUTRAL_70;
        }
      }
  
  
  
      .contractIcon {
        color: $PRIMARY_MAIN
      }
  
    }
  }

 
  .stepperWrapper {
    background-color: $NEUTRAL_LIGHTER;
    padding-bottom: 30px;
    border-bottom: 1px solid $NEUTRAL_40;
  }

  .infoWrapper {
    padding: 8px 30px;
    display: flex;
    align-items: center;
    gap: 24px;

    img {
      width: 32px;
      aspect-ratio: 1;
    }

    > div {
      h3 {
        padding-bottom: 4px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: 0.005em;
      }

      span {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $NEUTRAL_DARKEST;
      }
    }

    &.complete {
      background-color: #ecefff;

      h3 {
        color: $PRIMARY_MAIN;
      }
    }

    &.incomplete {
      background-color: #ffe8e3;

      h3 {
        color: $DANGER_MAIN;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {

    .typeWrapper {
      display: none!important;
    }

    .breadcrumbWrapper {
      padding: 8px 18px!important;
      padding-right: 0px;
    }

    .stepperWrapper {
      padding-bottom: 14px;
    }

    .infoWrapper {
      padding: 8px 18px;
    }

  }
}
