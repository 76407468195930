$bannerhaeder: url("../../../../assets/Images/bg-jobseaker.png");

.bodyJobSeaker {
  display: flex;
  flex-direction: column;
  position: relative;

  .autoScroll {
    display: hidden;
    position: absolute;
    top: 0;
  }
}

.headerBody {
  display: flex;
  flex-direction: column;
  position: relative;

  .textHeaderBox {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 118px 16px 118px 16px;

    .text {
      display: flex;
      flex-direction: column;
      align-items: center;

      .H1 {
        color: var(--neutral-10, #fff);
        text-align: center;

        /* Heading 4/Bold */
        // font-family: Poppins;
        font-size: calc(20px + (32 - 20) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
        /* 44.8px */
        letter-spacing: 0.16px;
      }

      .p {
        color: var(--neutral-10, #fff);
        text-align: center;

        /* Text L/Reguler */
        // font-family: Poppins;
        font-size: calc(12px + (16 - 12) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        /* 22.4px */
      }
    }
  }

  .bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    // padding: 0px 100px;
    width: 100%;
    position: relative;
    bottom: 50px;
    max-width: calc(1440px) !important;
    padding-inline: 90px;
    margin-inline: auto;
  }

  .top {
    // position: relative;
    background-image: $bannerhaeder;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
    // padding: 181px;
    border-radius: 0px 0px 40px 40px;
  }
}

.contentJobSeaker {
  display: flex;
  flex-direction: column;
  padding: 40px 40px 40px 40px;
  gap: 24px;
  width: 100%;
  max-width: calc(1440px);
  margin: auto;
  min-height: 100vh;
  // height: 100vh;
  // height: 50vh;

  .topContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    .filterViewBox {
      display: flex;
      align-items: center;
      gap: 8px;

      .box {
        &.activeButton {
          border-radius: 8px;
          border: 1px solid var(--primary-main, #1571de);
          background: var(--primary-surface, #f5f7fe);
        }

        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        padding: 8px 16px;
        border-radius: 8px;
        border: 1px solid var(--neutral-40, #e0e0e0);
        background: var(--neutral-20, #f5f5f5);

        .text {
          &.activeText {
            color: var(--primary-main, #1571de);
          }

          color: var(--neutral-90, #404040);
          text-align: center;

          /* Text M/Reguler */
          //   font-family: Poppins;
          font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          /* 142.857% */
        }
      }
    }

    .textTitle {
      color: #000;

      /* Heading 7/Bold */
      //   font-family: Poppins;
      font-size: calc(14px + (20 - 14) * ((100vw - 280px) / (1600 - 280)));
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
      /* 30px */
      letter-spacing: 0.1px;
    }
  }
}

.bottomContent {
  display: flex;
  justify-content: space-between;
  gap: 24px;
  // height: 100vh;

  .cardSection {
    display: flex;
    flex-direction: column;
    .buttonSave {
      width: 80%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      padding: 12px;
      border-radius: 8px;
      background: var(--Primary-Main, #1571de);
      cursor: pointer;
      .searchText {
        color: var(--Color-Neutal-Neutral-10, #fff);
        text-align: center;

        /* Text M/Reguler */
        // font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
      }
    }
    width: 100%;
    .filterInTop {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      gap: 10px;
      border-radius: 8px;
      border: 1px solid var(--Neutral-30, #ededed);
      background: var(--Color-Neutal-Neutral-10, #fff);
      padding: 16px;
      .filterStart {
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 100%;
      }
    }
  }

  .filterSection {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 26%;
    .reset {
      color: var(--Primary-Main, #1571de);
      text-align: center;

      /* Text M/Reguler */
      // font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      cursor: pointer;
    }
    .iconFilter {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
    }
    .boxIcon {
      display: flex;
      align-items: center;
      gap: 8px;
      // cursor: pointer;
    }
    .filterSpan {
      color: #000;
      text-align: center;

      /* Text M/Medium */
      // font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
    }
  }
}

.rangePrice {
  color: var(--Neutral-90, var(--Color-Neutal-Neutral-90, #404040));

  /* Text M/Medium */
  // font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.slider {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

.filterType {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid var(--Neutral-30, #ededed);
  > span {
    color: #000;

    /* Text M/Medium */
    // font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
  }
  .list {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .check {
    display: flex;
    align-items: center;
    gap: 8px;
    > span {
      color: #000;

      /* Text S/Reguler */
      // font-family: Poppins;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 153.846% */
    }
  }
}

.boxCards {
  height: 610px;
  overflow: auto;
  padding-top: 20px;
  // padding-left: 10px;
}
.boxCards::-webkit-scrollbar {
  display: none;
}

.filterResponsive {
  display: none;
}

@media screen and (max-width: 800px) {
  .contentJobSeaker {
    .topContent {
      flex-direction: column;
      .textTitle {
        font-size: 24px;
        text-align: center;
      }
    }
  }
  .filterResponsive {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 16px;
    .bodyFilter {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px 12px;
      border-radius: 8px;
      border: 1px solid var(--Primary-Main, #1571de);
      background: var(--Neutral-10, #fff);

      /* Button Shadow */
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);

      cursor: pointer;
      gap: 4px;
      width: 169px;

      .filterSpan {
        color: var(--Primary-Main, #1571de);
        text-align: center;

        /* Text M/Reguler */
        // font-family: Poppins;

        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
      }
    }
  }
  .bottomContent {
    .cardSection {
      .filterInTop {
        flex-direction: column;
      }

      .buttonSave {
        width: 100%;
      }
    }
    .filterSection {
      display: none;
    }
  }
  .headerBody {
    .bottom {
      padding: 0px 18px;
    }

    .textHeaderBox {
      padding: 90px 16px;
    }

    .top {
      border-radius: 0px 0px 20px 20px;
    }
  }
}

@media screen and (max-width: 768px) {
  .contentJobSeaker {
    padding: 40px 15px 40px 15px;
  }

  .boxCards {
    padding-left: 0px;
  }
}
