@import "../../../../../../../theme/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .redStar {
    color: $DANGER_MAIN;
  }

  .errorWrapper {
    font-family: "Poppins";
    font-size: 14px;
    color: $DANGER_MAIN;
  }

  .showOnMobile {
    display: none !important;
  }

  > * {
    padding-inline: 16px;

    &.header {
      height: 66px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &.add {
        border-bottom: 1px solid $NEUTRAL_50;
      }

      h4 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: 0.005em;
        color: $NEUTRAL_100;
      }

      > div {
        width: 300px;
      }

      .primaryButton {
        display: flex;
        align-items: center;
        height: 36px;
        padding-inline: 12px;
        border: 1px solid $PRIMARY_MAIN;
        border-radius: 8px;
        background-color: $PRIMARY_MAIN;
        gap: 8px;
        position: relative;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $NEUTRAL_10;
        cursor: pointer;
        width: fit-content;

        &:disabled {
          background-color: $PRIMARY_DISABLED;
          border-color: $PRIMARY_DISABLED;
        }

        &:hover {
          background-color: #3285ec;
        }
      }

      button {
        display: flex;
        align-items: center;
        height: 36px;
        padding-inline: 12px;
        border: 1px solid $PRIMARY_MAIN;
        border-radius: 8px;
        background-color: $NEUTRAL_10;
        gap: 8px;
        position: relative;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $PRIMARY_MAIN;

        &:disabled {
          background-color: $PRIMARY_DISABLED;
          border-color: $PRIMARY_DISABLED;
        }

        &:hover {
          background-color: #f1f3ff95;
        }
      }
    }

    &.controller {
      display: flex;
      padding-bottom: 16px;

      &.add {
        padding-top: 16px;
        border-bottom: 1px solid $NEUTRAL_50;
      }

      > * {
        width: 50%;

        &.left {
          display: flex;
          align-items: center;
          gap: 12px;

          h5 {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 150%;
            letter-spacing: 0.005em;
            color: $NEUTRAL_DARKEST;
          }
        }

        &.right {
          display: flex;
          align-items: center;
          gap: 8px;
          justify-content: flex-end;

          button {
            display: flex;
            align-items: center;
            height: 36px;
            padding-inline: 16px;
            border: 1px solid $PRIMARY_MAIN;
            border-radius: 8px;
            background-color: $NEUTRAL_10;
            // gap: 8px;
            position: relative;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: $PRIMARY_MAIN;

            > span {
              &:nth-child(2) {
                display: inline-block;
                padding-right: 8px;
              }
            }

            &:hover {
              background-color: #f1f3ff95;
            }

            &.filled {
              background-color: $PRIMARY_MAIN;
              color: $NEUTRAL_10;

              &:hover {
                box-shadow: $NEUTRAL_BOX_SHADOW;
              }

              &:disabled {
                background-color: $PRIMARY_DISABLED;
                border-color: $PRIMARY_DISABLED;
              }
            }

            .options {
              position: absolute;
              top: 40px;
              left: 0;
              width: 100%;
              background-color: $NEUTRAL_10;
              border-radius: 0 0 8px 8px;
              box-shadow: 0px 0px 1px rgba(9, 30, 66, 0.31),
                0px 3px 5px rgba(9, 30, 66, 0.2);

              > * {
                padding: 10px;
                font-size: 13px;
                font-weight: 400;
                color: $NEUTRAL_100;
                user-select: none;
                border-bottom: 1px solid $NEUTRAL_50;

                &:hover {
                  background-color: $NEUTRAL_20;
                  cursor: pointer;
                }
              }
            }
          }

          .withCVWrapper {
            display: flex;
            align-items: center;
            gap: 8px;
            padding-right: 8px;

            .remove {
              color: $DANGER_MAIN;
              border-color: $DANGER_MAIN;
            }

            > span {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              color: $NEUTRAL_100;
              padding-left: 8px;
            }
          }
        }
      }
    }

    &.main {
      flex-grow: 1;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
      column-gap: 43px;
      row-gap: 24px;
      padding-top: 16px;
      padding-bottom: 47px;
      background-color: $PRIMARY_SURFACE;
    }

    &.footer {
      height: 56px;
      background-color: $NEUTRAL_10;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      border-top: 1px solid $NEUTRAL_50;
    }
  }

  .modalWrapper {
    position: fixed;
    transition: all 0.35s ease-in-out !important;
    z-index: 4;
    background-color: transparent;
    width: 100%;
    left: 0;
    margin: 0 !important;
    padding: 0 !important;

    &.hideModal {
      bottom: -1000px;
    }

    &.showModal {
      bottom: 0;
    }
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    background-color: $NEUTRAL_OVERLAY;
    opacity: 0;
    z-index: 3;

    &.visible {
      right: 0;
      bottom: 0;
      opacity: 1;
      transition: opacity 0.4s;
    }
  }
}

@media screen and (max-width: 1024px) {
  .left,
  .right {
    .hideOnMobile {
      display: none !important;
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    display: flex;
    flex-direction: column;
    height: 100%;

    button {
      border-radius: 4px !important;
    }

    .hideOnMobile,
    .hideOnMobile1 {
      display: none !important;
    }

    .showOnMobile {
      display: block !important;
    }

    > * {
      padding-inline: 16px;

      &.header {
        height: 100% !important;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding-top: 8px;
        gap: 8px;
        padding-bottom: 0 !important;

        &.add {
          border-bottom: none;
        }

        h4 {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 150%;
          letter-spacing: 0.005em;
          color: $NEUTRAL_100;
        }

        > div {
          width: 300px;
        }

        button {
          display: flex;
          align-items: center;
          height: 36px;
          padding-inline: 12px;
          border: 1px solid $PRIMARY_MAIN;
          border-radius: 8px;
          background-color: $NEUTRAL_10;
          gap: 8px;
          position: relative;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: $PRIMARY_MAIN;

          &:hover {
            background-color: #f1f3ff95;
          }
        }
      }

      &.controller {
        display: flex;
        padding-bottom: 16px;
        flex-wrap: wrap;

        &.add {
          padding-top: 16px;
          border-bottom: 1px solid $NEUTRAL_50;
        }

        > * {
          width: 50%;

          &.left {
            display: flex;
            align-items: center;
            gap: 12px;
            flex-shrink: 0;

            h5 {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 140%;
            }
          }

          &.right {
            display: flex;
            align-items: center;
            gap: 8px;
            justify-content: flex-end !important;

            button {
              display: flex;
              align-items: center;
              height: 36px;
              padding-inline: 16px;
              border: 1px solid $PRIMARY_MAIN;
              border-radius: 8px;
              background-color: $NEUTRAL_10;
              position: relative;
              font-family: "Poppins";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              color: $PRIMARY_MAIN;

              &:hover {
                background-color: #f1f3ff95;
              }

              &.filled {
                background-color: $PRIMARY_MAIN;
                color: $NEUTRAL_10;

                &:disabled {
                  background-color: $PRIMARY_DISABLED;
                  border-color: $PRIMARY_DISABLED;
                }

                &:hover {
                  box-shadow: $NEUTRAL_BOX_SHADOW;
                }
              }

              .options {
                position: absolute;
                top: 40px;
                left: 0;
                width: 100%;
                background-color: $NEUTRAL_10;
                border-radius: 0 0 8px 8px;
                box-shadow: 0px 0px 1px rgba(9, 30, 66, 0.31),
                  0px 3px 5px rgba(9, 30, 66, 0.2);

                > * {
                  padding: 10px;
                  font-size: 13px;
                  font-weight: 400;
                  color: $NEUTRAL_100;
                  user-select: none;
                  border-bottom: 1px solid $NEUTRAL_50;

                  &:hover {
                    background-color: $NEUTRAL_20;
                    cursor: pointer;
                  }
                }
              }
            }

            .withCVWrapper {
              display: flex;
              align-items: center;
              gap: 8px;
              padding-right: 0px;
              width: 100%;
              justify-content: flex-end;

              .remove {
                color: $DANGER_MAIN;
                border-color: $DANGER_MAIN;
              }

              > span {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: $NEUTRAL_100;
                padding-left: 8px;
              }
            }
          }
        }
      }

      .UploadedText {
        display: flex;
        align-items: center;
        padding-top: 8px;
        gap: 8px;
        // justify-content: flex-end;
        width: 100%;

        > span {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: $NEUTRAL_100;
          // padding-left: 8px;
        }
      }

      &.main {
        flex-grow: 1;
        display: grid;
        column-gap: 43px;
        row-gap: 24px;
        padding-top: 16px;
        padding-bottom: 47px;
        background-color: $PRIMARY_SURFACE;

        > button {
          height: 36px;
          padding-inline: 16px;
          border: 1px solid $PRIMARY_MAIN;
          border-radius: 8px;
          background-color: $NEUTRAL_10;
          gap: 8px;
          position: relative;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: $PRIMARY_MAIN;

          &:hover {
            background-color: #f1f3ff95;
          }

          &.filled {
            background-color: $PRIMARY_MAIN;
            color: $NEUTRAL_10;

            &:disabled {
              background-color: $PRIMARY_DISABLED;
              border-color: $PRIMARY_DISABLED;
            }

            &:hover {
              box-shadow: $NEUTRAL_BOX_SHADOW;
            }
          }

          .options {
            position: absolute;
            top: 40px;
            left: 0;
            width: 100%;
            background-color: $NEUTRAL_10;
            border-radius: 0 0 8px 8px;
            box-shadow: 0px 0px 1px rgba(9, 30, 66, 0.31),
              0px 3px 5px rgba(9, 30, 66, 0.2);

            > * {
              padding: 10px;
              font-size: 13px;
              font-weight: 400;
              color: $NEUTRAL_100;
              user-select: none;
              border-bottom: 1px solid $NEUTRAL_50;

              &:hover {
                background-color: $NEUTRAL_20;
                cursor: pointer;
              }
            }
          }
        }
      }

      &.footer {
        height: 56px;
        background-color: $NEUTRAL_10;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        border-top: 1px solid $NEUTRAL_50;
      }
    }
  }
}
