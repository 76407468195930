@import "../../../../../theme/variables.scss";

.container {
  height: calc(100vh - 72px);
  display: flex;
  flex-direction: column;

  .oneLine {
    white-space: nowrap;
    // max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .odd {

    td {
      background-color: $PRIMARY_SURFACE !important;
    }
  }


  .buttonWrapper {
    display: flex;
    gap: 8px;
  }

  .approveButton {
    background-color: $PRIMARY_MAIN;
    color: $NEUTRAL-10;
    padding: 8px 12px;
    border-radius: 6px;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 142.857%;
    border: none;
    width: 121px;
  }

  .rejectButton {
    display: flex;
    color: $DANGER_MAIN;
    background-color: transparent;
    padding: 8px 10px;
    border-radius: 6px;
    border: 1px solid $DANGER_MAIN;
    justify-content: center;
    align-items: center;
  }


  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    padding: 20px 30px;
    border-top: 1px solid $NEUTRAL-30;
    border-bottom: 1px solid $NEUTRAL-40;
    background-color: #fafafa;

    .searchbar {
      width: 350px;
      height: 44px;
    }

    >button {
      display: flex;
      align-items: center;
      gap: 4px;
      padding: 12px;
      background-color: $NEUTRAL-10;
      border: 1px solid $PRIMARY_MAIN;
      border-radius: 8px;
      color: $PRIMARY_MAIN;

      >span {
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 142.857%;
      }
    }
  }

  .tab {
    padding: 12px 30px;
    background-color: $NEUTRAL-10;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    .tabs {
      display: flex;
      align-items: center;
      gap: 4px;

      >button {
        padding: 6px 10px;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 142.857%;
        border: none;
        border-radius: 5px;
        background-color: transparent;
        color: $NEUTRAL_60;
        transition: all 0.3s ease;

        &.active {
          background-color: $PRIMARY_MAIN;
          color: $NEUTRAL-10;
        }
      }
    }

    .notes {
      display: flex;
      align-items: center;
      gap: 10px;
      background-color: $PRIMARY_SOFT;
      padding: 3px 3px 3px 10px;
      border-radius: 6px;

      >p {
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 142.857%;

        >span {
          font-weight: 700;
        }
      }

      >*:last-child {
        flex-shrink: 0;
      }
    }

  }

  .content {
    padding: 10px 30px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .tableWrapper {
      flex-grow: 1;
      background-color: $NEUTRAL-10;
      border-radius: 8px;
    }
  }


}

@media screen and (max-width: 768px) {
  .container {
    .tab {
      flex-direction: column;

      .tabs {
        width: 100%;

        >* {
          width: 100%;
        }
      }
    }
  }
}