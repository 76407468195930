@import "../../../../../../theme/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-grow: 1;
  padding: 16px;

  .adder {
    padding: 8px;
    padding-left: 16px;
    background-color: $BS_NEUTRAL_20;
    border-radius: 8px;
    border: 1px solid $NEUTRAL_30;
    display: flex;
    align-items: center;
    gap: 16px;

    > span {
      flex-grow: 1;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
    }

    > button {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 10px 12px;
      border-radius: 8px;
      background-color: $NEUTRAL_10;
      border: 1px solid $PRIMARY_MAIN;
      color: $PRIMARY_MAIN;

      > span {
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 32px;
    flex-grow: 1;
    overflow: auto;

    .allowances {
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding-bottom: 16px;
    }
    .empty {
      flex-grow: 1;
      // overflow: scroll;

      &.empty {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 4px;
        height: 100%;
        text-align: center;

        > span {
          &:first-child {
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 140%;
          }

          &:last-child {
            font-family: Poppins;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            color: $NEUTRAL_60;
          }
        }
      }
    }
  }
}

.variableCost {
  display: flex;
  flex-wrap: wrap;
  // flex-direction: column;
  gap: 16px;
  .inputCost {
    width: calc(
      50% - 8px
    ); /* Menyesuaikan lebar untuk 2 kolom dengan jarak antar kolom */
    display: flex;
    flex-direction: column;
    gap: 4px;
    .spanVariableCost {
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
  }
}

@media screen and (max-width: 480px) {
  .variableCost {
    .inputCost {
      width: 100%;
    }
  }
}
