@import "../../../../theme/variables.scss";

.Container {
  > :first-child {
    display: flex;
    gap: 2px;
    margin-bottom: 8px;
    > :first-child {
      font-weight: 500;
      font-size: 13px;
    }
    > :last-child {
      font-weight: 400;
      font-size: 11px;
      color: $DANGER_MAIN;
    }
  }
  
  .errorText {
    color: var(--Helper, #F5610D);
    /* Caption 1/Reguler */
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 133.333% */
  }
  
  .selectWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
    .select {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px;
      border: 1px solid $NEUTRAL_40;
      background: $NEUTRAL_20;
      border-radius: 8px;
      > p {
        font-weight: 400;
        font-size: 14px;
        color: $NEUTRAL_80;
      }
    }
    .selected {
      border: 1px solid $PRIMARY_MAIN;
      background: $PRIMARY_SURFACE;
      > p {
        color: $PRIMARY_MAIN;
      }
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 12px;
    .inputContainer {
      display: grid;
      grid-template-columns: 1fr 28px;
      border-radius: 8px;
      .inputAction {
        cursor: pointer;
        background: $DANGER_MAIN;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0px 8px 8px 0px;
        > :first-child {
          color: $NEUTRAL_10;
        }
      }
      .inputWrapper {
        background: $NEUTRAL_20;
        padding: 8px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        border-radius: 8px 0px 0px 8px;
        .inputDateContainer {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 8px;
          .dateInputWrapper {
            display: flex;
            flex-direction: column;
            gap: 4px;
            > :first-child {
              font-weight: 500;
              font-size: 13px;
            }
            .checkBox {
              display: flex;
              align-items: center;
              gap: 4px;
              > p {
                font-weight: 400;
                font-size: 12px;
              }
            }
            .inputDate {
              display: grid;
              grid-template-columns: 1fr 1fr;
              gap: 4px;
              > div {
                > :first-child {
                  height: 28px !important;
                }
                > :nth-child(2) {
                  height: 150px !important;
                  overflow-y: scroll;
                }
              }
            }
          }
        }
      }
    }
    .addExperienceContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 44px;
      background: $PRIMARY_SURFACE;
      cursor: pointer;
      border: 1px dashed $PRIMARY_MAIN;
      padding: 12px;
      border-radius: 8px;
      .addExperienceWrapper {
        display: flex;
        align-items: center;
        gap: 8px;
        color: $PRIMARY_MAIN;
        > :last-child {
          font-weight: 500;
          font-size: 13px;
        }
      }
    }
  }
  .hidden {
    display: none !important;
  }
}

.required {
  color: $DANGER_MAIN;
  font-weight: 400;
  font-size: 11px;
  position: relative;
  bottom:3px;
}

@media screen and (max-width: 768px) {
  .Container {
    .inputDateContainer {
      grid-template-columns: 1fr !important;
      grid-template-rows: 1fr;
    }
  }
}
