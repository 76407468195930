@import "../../../../../theme/variables.scss";

.container {
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  background-color: $NEUTRAL_10;
  max-width: 100vw;
  width: 436px;
  filter: drop-shadow(0px 4px 9px rgba(0, 0, 0, 0.12));
  gap: 24px;

  .header {
    padding: 16px 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .iconWrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    padding: 0 24px;
    text-align: center;

    .title {
      font-size: 20px;
      font-weight: 600;
      color: #0a0a0a;
    }

    .text {
      font-size: 14px;
      font-weight: 400;
      color: $NEUTRAL_60;
    }
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 24px 24px 24px;
    gap: 8px;

    .secondaryButton {
      // flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $NEUTRAL_10;
      padding: 10px 12px;
      border-radius: 8px;
      border: 1px solid $PRIMARY_MAIN;
      cursor: pointer;
      width: fit-content;

      .text {
        font-size: 14px;
        font-weight: 500;
        color: $PRIMARY_MAIN;
      }
    }

    .secondaryButton:hover {
      background-color: $NEUTRAL_30;
    }
  }
}

@media (width <= 768px) {
  .container {
    width: 100%;
  }
}
