.container {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 70px);

  .headerWrapper {
    position: sticky;
    top: 70px;
    z-index: 2;
  }

  .content {
    flex-grow: 1;
    display: grid;
    place-items: center;
    height: 100%;
  }
  // .content {
  //   min-height: 220px;
  // }
  // .content {
  //   overflow-y: auto;
  //   height: 100%;
  // }

  .loadingWrapper {
    flex-grow: 1;
    display: grid;
    place-items: center;
  }
}

@media screen and (max-width: 768px) {
  .container {
    .headerWrapper {
      position: unset;
      top: 0px;
    }
  }
}
