@import "../../../theme/variables.scss";

.container {
  height: 18px;
  aspect-ratio: 1 !important;
  border: 2px solid #333;
  background-color: transparent;
  border-radius: 2px;
  transition: all 0.3s;

  display: grid;
  place-items: center;

  &.checked {
    background-color: $PRIMARY_MAIN;
    border: none;
    color: $NEUTRAL_10;
  }
}

.containerJobPost {
  height: 18px;
  aspect-ratio: 1 !important;
  border: 1px solid #c2c2c2;
  background-color: transparent;
  border-radius: 6px;
  transition: all 0.3s;

  display: grid;
  place-items: center;

  &.error {
    border: 1px solid red;
  }

  &.checked {
    background-color: $PRIMARY_MAIN;
    border: none;
    color: $NEUTRAL_10;
  }
}

.containerRecommended {
  height: 20px;
  aspect-ratio: 1 !important;
  border: 1px solid #c2c2c2;
  background-color: transparent;
  border-radius: 6px;
  transition: all 0.3s;

  display: grid;
  place-items: center;

  &.checked {
    background-color: $PRIMARY_MAIN;
    border: none;
    color: $NEUTRAL_10;
  }
}
