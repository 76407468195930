@import "../../../../../../../theme/variables.scss";

.container {
  width: 100%;
  height: 100%;
  z-index: inherit;
  
  .tabWrapper {
    background: $NEUTRAL_10;
    padding-top: 15px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
    border-radius: 8px 8px 0px 0px;
    z-index: 10;
  }
  .contentWrapper {
    background: $NEUTRAL_10;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .buttonWrapper {
    padding-block: 24px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    button {
      display: grid;
      place-items: center;
      width: 98px;
      height: 36px;
      border-radius: 8px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      transition: all 0.3s;
      cursor: pointer;
      background: $NEUTRAL_10;
      border: 1px solid $NEUTRAL_100;
      color: $NEUTRAL_100;
      @media screen and (max-width: 768px) {
        width: 100% !important;
      }
    }
  }
}
