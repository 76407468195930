.container {
  border-radius: 16px;
  border: var(--Spacing-0, 1px) solid var(--Color-Neutal-Neutral-40, #e0e0e0);
  background: #f8fbff;
  display: flex;
  padding: 16px;

  flex-direction: column;
  gap: 16px;
  > div:first-child {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 16px;
    > div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      width: 100%;

      > div {
        display: flex;
        flex-direction: column;
        gap: 4px;

        > span:first-child {
          color: var(--Neutral-60, var(--Color-Neutal-Neutral-60, #9e9e9e));

          /* Text M/Medium */
          //   font-family: Poppins;
          font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 142.857% */
        }

        > span:last-child {
          color: var(--Neutral-90, var(--Color-Neutal-Neutral-90, #404040));

          /* Text L/SemiBold */
          //   font-family: Poppins;
          font-size: calc(14px + (16 - 14) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 600;
          line-height: 140%; /* 22.4px */
        }

        > div {
          display: flex;
          align-items: center;
          gap: 4px;
          > span:first-child {
            color: var(--Neutral-90, var(--Color-Neutal-Neutral-90, #404040));

            /* Text L/SemiBold */
            //   font-family: Poppins;
            font-size: calc(
              14px + (16 - 14) * ((100vw - 280px) / (1600 - 280))
            );
            font-style: normal;
            font-weight: 600;
            line-height: 140%; /* 22.4px */
          }

          > span:last-child {
            color: var(--Neutral-90, var(--Color-Neutal-Neutral-90, #404040));

            /* Text L/SemiBold */
            //   font-family: Poppins;
            font-size: calc(
              14px + (16 - 14) * ((100vw - 280px) / (1600 - 280))
            );
            font-style: normal;
            font-weight: 600;
            line-height: 140%; /* 22.4px */
            cursor: pointer;
          }
        }
      }
    }
  }

  > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    gap: 4px;
    > span {
      color: var(--Neutral-60, var(--Color-Neutal-Neutral-60, #9e9e9e));

      /* Text M/Medium */
      //   font-family: Poppins;
      font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
    }

    > div {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      width: 100%;
      > div {
        border-radius: var(--Spacing-0, 0px) 4px 4px var(--Spacing-0, 0px);
        border: 1px solid #c9d7ed;
        background: var(--Color-Neutal-Neutral-10, #fff);
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        gap: 10px;
        > div {
          height: 100%;
          width: 3px;
          background-color: #1571de;
          //   position: absolute;
          //   left: 0;
          //   top: 0;
          //   bottom: 0;
        }

        > span {
          padding-right: 10px;
          padding-top: 8px;
          padding-bottom: 8px;
          color: var(--Neutral-90, var(--Color-Neutal-Neutral-90, #404040));

          /* Text M/Medium */
          //   font-family: Poppins;
          font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 142.857% */
        }
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .container {
    > div:first-child {
      flex-direction: column;
    }
  }
}

// @media screen and (max-width: 500px) {
//   .container {
//     > div:nth-child(2) {
//       > div {
//         > div {
//           width: 100%;
//           > div {
//             position: absolute;
//             left: 0;
//             top: 0;
//             bottom: 0;
//           }
//         }
//       }
//     }
//   }
// }
