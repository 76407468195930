@import "../../../../../../theme/variables.scss";

.container {
  padding: 30px;
  padding-bottom: 10px;
  background-color: $NEUTRAL_LIGHTER;
  border-bottom: 1px solid $NEUTRAL_40;

  .searchBarWrapper {
    width: 356px;
    height: 40px;
  }
}

@media screen and (max-width:768px) {
  .container {
    padding: 8px 18px;

    .searchBarWrapper {
      width: 100%;
    }
  }
}
