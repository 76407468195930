@import "../../../../../../../../theme/variables.scss";

.tableWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll !important;
  z-index: 0;

  .showOnMobile {
    display: none;
  }

  .tableContainer {
    overflow: auto;
    background-color: inherit;
    border-bottom: 0;
    border: 1px solid $NEUTRAL_50;
    border-top: 0;
    border-inline: none;
    overflow-y: scroll !important;
    height: 380px;

    &::-webkit-scrollbar {
      display: none;
    }

    .table {
      border-collapse: separate;
      // min-width: 900px !important;

      .tableHead {
        .tableHeadCell {
          padding-inline: 20px !important;
          padding-block: 16px !important;

          &:first-child {
            border-right: 1px solid $NEUTRAL_40;
            position: sticky;
            left: 0;
            background-color: $NEUTRAL_10 !important;
            // z-index: 1;
            padding-inline: 16px !important;

            >div {
              width: 304px !important;
              display: flex;
              align-items: center;
              gap: 16px;
            }
          }

          div {
            width: 220px !important;
          }
        }
      }

      .tableBody {
        .tableCell {
          padding: 16px !important;

          >span {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: $NEUTRAL_100;
          }

          &.stickyLeft {
            position: sticky;
            left: 0;
            background-color: $NEUTRAL_10 !important;
            border-right: 1px solid $NEUTRAL_40;

            >div {
              display: flex;
              align-items: center;
              gap: 16px;

              .stackedProfile {
                background-color: transparent;
                border: none;
                display: flex;
                align-items: center;
                gap: 8px;
                cursor: pointer;

                span {
                  font-family: "Poppins";
                  font-style: normal;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 20px;
                  color: $PRIMARY_MAIN;
                  text-align: left;
                }
              }
            }
          }
        }
      }
    }
  }

  .modalWrapper {
    position: fixed;
    transition: all 0.35s ease-in-out !important;
    z-index: 4;
    background-color: transparent;
    width: 100%;
    left: 0;
    right: 0;

    &.hideModal {
      bottom: -1000px;
    }

    &.showModal {
      bottom: 0;
    }
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    background-color: $NEUTRAL_OVERLAY;
    opacity: 0;
    z-index: 3;

    &.visible {
      right: 0;
      bottom: 0;
      opacity: 1;
      transition: opacity 0.4s;
    }
  }
}

@media screen and (max-width: 768px) {
  .tableWrapper {
    .showOnMobile {
      display: table-cell;
    }

    .tableContainer {
      .table {
        width: 100%i !important;

        .tableHead {
          .tableHeadCell {
            padding: 12px 16px !important;

            &:first-child {
              position: relative;
              border-right: none;

              >div {
                width: 100% !important;
              }
            }
          }

          .showOnMobile {
            min-width: 100px !important;
            max-width: 150px !important;
          }
        }

        .tableBody {
          .tableCell {
            &.stickyLeft {
              position: relative;
              border-right: none;

              >div {
                width: 100% !important;
              }
            }

            &.showOnMobile {
              button {
                width: 100%;
                padding: 6px 12px;
                background-color: $PRIMARY_MAIN;
                border: none;
                border-radius: 8px;

                font-family: "Poppins";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: $NEUTRAL_10;
              }
            }
          }
        }

        .hideOnMobile {
          display: none !important;
        }
      }
    }
  }
}