.container {
  background-color: #fff;
  width: 442px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  border-radius: 8px;
  > div:first-child {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    .icon {
      width: 60px;
    }

    > div {
      display: flex;
      flex-direction: column;
      gap: 8px;
      > span:first-child {
        color: #0f161f;
        text-align: center;

        /* Heading 7/Bold */
        // font-family: Poppins;
        font-size: calc(16px + (20 - 16) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 30px */
        letter-spacing: 0.1px;
      }

      > span:last-child {
        color: #878787;
        text-align: center;

        /* Text M/Reguler */
        // font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        > span {
          color: var(--Color-Neutal-Neutral-19, #0a0a0a);
          font-weight: 700;
        }
      }
    }
  }

  > div:last-child {
    display: flex;
    flex-direction: column;
    gap: 4px;
    > span {
      font-size: 12px;
      color: red;
    }
    > div {
      display: flex;
      align-items: center;
      gap: 16px;
      > button:first-child {
        text-decoration: none;
        width: 100%;
        border-radius: 8px;
        border: 1px solid var(--Neutral-100, #0a0a0a);
        background: var(--Neutral-10, #fff);
        padding: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));
        text-align: center;

        /* Text M/Reguler */
        // font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
      }

      > button:last-child {
        // text-decoration: none;
        border: none;
        width: 100%;
        border-radius: 8px;
        background: var(--Primary-Main, #1571de);
        padding: 12px;
        justify-content: center;
        align-items: center;
        display: flex;
        color: var(--Color-Neutal-Neutral-10, #fff);
        text-align: center;

        /* Text M/Reguler */
        // font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        &.disabled {
          background-color: #91bdf1;
        }
      }
    }
  }
}
