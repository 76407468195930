@import "../../../../../../../../../theme/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .regionNameText {
    font-size: 20px;
    font-weight: 700;
    color: $NEUTRAL_100;
  }

  .manPowerInformationTable {
    padding: 16px;
    display: flex;
    flex-direction: column;
    background-color: $NEUTRAL_20;
    gap: 16px;
    border-radius: 8px;

    .manPowerTitleText {
      font-size: 18px;
      font-weight: 600;
      color: $PRIMARY_MAIN;
    }

    .salaryAllowancesWrapper {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 100%;

      .salaryAllowancesTop {
        display: flex;
        width: 100%;
        flex-direction: row;
        gap: 8px;
        justify-content: space-between;

        .salaryAllowancesCard {
          background-color: $NEUTRAL_10;
          display: flex;
          flex-direction: column;
          flex: 1;
          border-radius: 4px;
          padding: 8px 12px;

          .tinyGreyText {
            font-size: 12px;
            font-weight: 400;
            color: $NEUTRAL_60;
          }

          .text {
            font-size: 14px;
            font-weight: 500;
            color: $NEUTRAL_100;
          }

          .boldText {
            font-size: 14px;
            font-weight: 700;
            color: $NEUTRAL_100;
          }

          .contentWrapper {
            display: flex;
            justify-content: space-between;
          }
        }
      }

      .salaryAllowancesBottom {
        display: flex;
        width: 100%;
        flex-direction: row;
        gap: 8px;
        justify-content: space-between;

        .salaryAllowancesCard {
          background-color: $NEUTRAL_10;
          display: flex;
          flex-direction: column;
          flex: 1;
          border-radius: 4px;
          padding: 8px 12px;

          .tinyGreyText {
            font-size: 12px;
            font-weight: 400;
            color: $NEUTRAL_60;
          }

          .text {
            font-size: 14px;
            font-weight: 500;
            color: $NEUTRAL_100;
          }

          .boldText {
            font-size: 14px;
            font-weight: 700;
            color: $NEUTRAL_100;
          }

          .contentWrapper {
            display: flex;
            justify-content: space-between;
          }
        }
      }
    }

    .nextButtonWrapper {
      display: flex;
      align-items: center;
      padding-top: 8px;

      .nextButton {
        padding: 10px 16px;
        background-color: $PRIMARY_SOFT;
        display: flex;
        gap: 4px;
        align-items: center;
        border-radius: 20px;
        cursor: pointer;

        .iconArrow {
          color: $PRIMARY_MAIN;
        }

        .nextButtonTitle {
          font-size: 14px;
          font-weight: 500;
          color: $PRIMARY_MAIN;
        }
      }
    }

    .stepWrapper {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .fulfillmentDateWrapper {
        background-color: $NEUTRAL_10;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 12px;

        .fulfillmentDateTitle {
          font-size: 16px;
          font-weight: 600;
          color: $NEUTRAL_100;
        }

        .dateWrapper {
          display: flex;
          align-items: center;
          gap: 16px;

          .icon {
            color: $NEUTRAL_60;
          }

          .fulfillmentDateText {
            font-size: 14px;
            font-weight: 500;
            color: $NEUTRAL_100;
          }
        }
      }

      .jobQualificationWrapper {
        display: flex;
        flex-direction: column;

        .tableHeadWrapper {
          display: flex;
          background-color: $NEUTRAL_40;
          align-items: center;
          padding: 12px 16px;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;

          .tableHeadText {
            font-size: 14px;
            font-weight: 500;
            color: $NEUTRAL_100;
            flex: 1;
          }
        }

        .tableBodyWrapper {
          display: flex;
          flex-direction: column;
          background-color: $NEUTRAL_10;
          padding: 12px 16px;
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
          gap: 10px;
          width: 100%;

          .emptyText {
            font-size: 14px;
            font-weight: 400;
            color: $NEUTRAL_100;
          }

          .tableBodyData {
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: space-between;

            .tableBodyText {
              font-size: 14px;
              font-weight: 400;
              color: $NEUTRAL_100;
              flex: 1;
            }

            .tableBodyTextSB {
              font-size: 14px;
              font-weight: 500;
              color: $NEUTRAL_100;
              flex: 1;
            }
          }
        }
      }
    }
  }

  .workEquipmentWrapper {
    background-color: $NEUTRAL_20;
    padding: 16px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .titleText {
      font-size: 16px;
      font-weight: 700;
      color: $NEUTRAL_100;
    }

    .equipmentListWrapper {
      border-radius: 4px;
      background-color: $NEUTRAL_10;
      padding: 8px 12px;
      display: flex;
      flex-direction: column;

      .equipmentList {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .emptyText {
          font-size: 14px;
          font-weight: 400;
          color: $NEUTRAL_100;
        }

        .equipmentListData {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          .equipmentListText {
            font-size: 14px;
            font-weight: 400;
            color: $NEUTRAL_100;
          }

          .equipmentListTextSB {
            font-size: 14px;
            font-weight: 500;
            color: $NEUTRAL_100;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    .manPowerInformationTable {
      .salaryAllowancesWrapper {
        .salaryAllowancesBottom {
          flex-direction: column;
        }
      }
      .stepWrapper {
        .fulfillmentDateWrapper {
          .fulfillmentDateTitle {
            font-size: 14px;
          }
          .dateWrapper {
            .fulfillmentDateText {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
