@import "../../../../../theme/variables.scss";

.container {
  display: flex;
  flex-direction: row;
  padding: 25px 25px;
  gap: 20px;

  .emptyHistory {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: $NEUTRAL_60;

  }

  .generalInformationWrapper {
    background-color: #ffffff;
    width: 35%;
    padding: 24px 24px;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    gap: 24px;

    .employee {
      display: flex;
      flex-direction: column;
      gap: 16px;
      align-items: center;

      .picture {
        height: 100px;
        width: 100px;
        border-radius: 50px;
      }

      .statusCard {
        padding: 7px 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-width: 1.5px;
        border-style: solid;
        border-radius: 5px;

        .statusCardText {
          font-size: 14px;
          font-weight: 500;
        }
      }

      .employeeNameWrapper {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: center;

        .employeeName {
          font-size: 20px;
          font-weight: 600;
          color: #000000;
        }

        .employeePosition {
          font-size: 14px;
          font-weight: 400;
          color: #000000;
        }
      }

      .starsWrapper {
        .shiningStar {
          color: $PRIMARY_MAIN;
        }

        .notShiningStar {
          color: $NEUTRAL_40;
        }
      }
    }
  }

  .giDetailWrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 80%;
  }

  .giButtonsWrapper {
    display: flex;
    flex-direction: column;
    background-color: $NEUTRAL_LIGHTER;
    padding: 16px;
    border-radius: 10px;
    border: 1px solid $NEUTRAL_30;
    cursor: pointer;

    .giButtonsSection {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .giButtons {
      max-height: 0;
      transition: 0.1s;
      opacity: 0;
    }

    .activeGiButtons {
      display: flex;
      flex-direction: column;
      gap: 8px;
      max-height: 9999px;
      transition: 0.2s;
      text-align: left;
      opacity: 100%;
      padding-top: 16px;
    }

    .giButtonsWrapperText {
      font-size: 16px;
      font-weight: 600;
      color: $NEUTRAL_100;
    }

    .extendButton {
      flex: 1;
      padding: 10px;
      border-radius: 5px;
      background-color: $PRIMARY_MAIN;
      display: flex;
      justify-content: center;
      align-items: center;

      .extendButtonText {
        font-size: 14px;
        font-weight: 400;
        color: $NEUTRAL_10;
      }
    }

    .terminateButton {
      flex: 1;
      padding: 10px;
      border-radius: 5px;
      background-color: $NEUTRAL_10;
      border: 1px solid $PRIMARY_MAIN;
      display: flex;
      justify-content: center;
      align-items: center;

      .terminateButtonText {
        font-size: 14px;
        font-weight: 400;
        color: $PRIMARY_MAIN;
      }
    }
  }

  .otherInformationWrapper {
    width: 65%;
    background-color: $NEUTRAL_10;
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-radius: 8px;
    padding: 16px;

    .otherInformation {
      background-color: $PRIMARY_SURFACE;
      border-radius: 8px;

      .otherInformationHeader {
        padding: 16px;
        border-bottom: 1px solid $NEUTRAL_40;
        font-size: 16px;
        font-weight: 600;
        color: $NEUTRAL_100;
      }

      .otherInformationBody {
        padding: 16px;
        display: flex;
        flex-direction: column;
        gap: 16px;

        .supervisorName {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 8px;
        }

        .supervisorPicture {
          height: 32px;
          width: 32px;
          border-radius: 16px;
          background-color: $NEUTRAL_20;
        }

        .supervisorContact {
          display: flex;
          flex-direction: column;
          gap: 8px;

          .supervisorContactText {
            font-size: 14px;
            font-weight: 500;
            color: $NEUTRAL_100;
          }

          .contactWrapper {
            display: flex;
            flex-direction: row;
            gap: 8px;

            .contact {
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 4px;

              .supervisorContactIcon {
                color: $PRIMARY_MAIN;
              }

              .contactValueText {
                font-size: 13px;
                font-weight: 400;
              }
            }

            .contactBreaker {
              border-left: 1px solid $NEUTRAL_60;
            }
          }
        }

        .supervisorNameText {
          font-size: 14px;
          font-weight: 500;
          color: $NEUTRAL_100;
        }

        .informationLine {
          display: flex;
          flex-direction: row;
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .container {
    flex-direction: column;

    .generalInformationWrapper {
      width: 100%;
    }

    .giButtonsWrapper {
      flex-direction: column;
      padding-top: 24px;
    }

    .otherInformationWrapper {
      width: 100%;
      padding: 8px;
    }
  }
}