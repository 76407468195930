.container {
  // z-index: inherit;

  >* {
    padding: 24px 30px;
  }
}

@media screen and (max-width: 768px) {
  .container {

    >* {
      padding: 16px 18px;
    }
  }
}