@import "../../../../../../../theme/variables.scss";

.container {
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid $PLATINUM_SOLID;

  .header {
    padding: 10px;
    text-align: center;
    background-color: $ALICE_BLUE;
    border-bottom: 1px solid $PLATINUM_SOLID;

    h3 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 150%;
      text-align: center;
      letter-spacing: 0.005em;
      color: $NEUTRAL_100;
    }
  }

  .content {
    background-color: $NEUTRAL_10;
    padding: 16px;

    .areas {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    .remark {
      padding-top: 16px;

      h4 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 140%;
        color: $NEUTRAL_100;
        padding-bottom: 8px;
      }

      .box {
        padding: 12px;
        border: 1px solid $PLATINUM_SOLID;
        border-radius: 8px;
        min-height: 90px;

        p {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: $NEUTRAL_100;
        }
      }
    }
  }
}
