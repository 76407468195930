@import "../../../theme/variables.scss";

.container {
  display: flex;
  align-items: center;
  height: 36px;
  border: 1px solid $NEUTRAL_40;
  border-radius: 5px;

  > * {
    height: 100%;
    display: grid;
    place-items: center;

    &.subtract {
      width: 52px;
      background-color: #f5f7fe;
      border-bottom-left-radius: 5px;
      border-top-left-radius: 5px;
      color: $PRIMARY_MAIN;
      cursor: pointer;
    }

    &.add {
      width: 52px;
      background-color: #f5f7fe;
      border-bottom-right-radius: 5px;
      border-top-right-radius: 5px;
      color: $PRIMARY_MAIN;
      cursor: pointer;
    }

    &.value {
      width: 71px;
      border-inline: 1px solid $NEUTRAL_40;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      cursor: pointer;
    }
  }
}
