@import "../../../../theme/variables.scss";

.container {
  width: 518px;
  background-color: #fafafa;

  .alertWrapper,
  .alertWrapperBottom {
    display: none;
    opacity: 0;
    margin-bottom: 8px;
    padding: 9px 12px;
    border-radius: 8px;

    span {
      flex-grow: 1;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
    }

    &.success,
    &.error {
      opacity: 1;
      display: flex;
    }

    &.success {
      border: 1px solid $SUCCESS_MAIN;
      background-color: $ALERT_SURFACE;
      color: $SUCCESS_MAIN;
    }

    &.error {
      border: 1px solid $DANGER_MAIN;
      background-color: $DANGER_SURFACE;
      color: $DANGER_MAIN;
    }
  }

  .alertWrapperBottom {
    display: none;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-bottom: 16px;

    > div {
      padding: 24px;
      background-color: $NEUTRAL_10;
      border-radius: 8px;
      border: 1px solid $NEUTRAL_30;

      &.infoCard {
        .logo {
          padding-bottom: 16px;

          img {
            height: 42px;
            object-fit: contain;
          }
        }

        .header {
          padding-bottom: 16px;

          h3 {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 150%;
            letter-spacing: 0.005em;
            color: $PRIMARY_MAIN;
            padding-bottom: 8px;
          }

          p {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: $NEUTRAL_60;
          }
        }

        .companyInfo {
          .title {
            display: flex;
            align-items: center;
            gap: 8px;
            padding-bottom: 16px;

            .icon {
              height: 48px;
              aspect-ratio: 1;
              border-radius: 50%;
              display: grid;
              place-items: center;
              background-color: $PRIMARY_SOFT;
              color: $PRIMARY_MAIN;
            }

            h4 {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 140%;
              display: flex;
              align-items: center;
              color: $NEUTRAL_100;
            }
          }

          .data {
            display: flex;
            flex-direction: column;
            gap: 12px;
            padding: 16px;
            border-radius: 8px;
            background-color: $NEUTRAL_20;

            .stacked {
              display: flex;

              span {
                &:first-child {
                  width: 30%;
                  flex-shrink: 0;
                  font-family: "Poppins";
                  font-style: normal;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 20px;
                  color: $NEUTRAL_70;
                }

                &:last-child {
                  flex-grow: 1;
                  text-align: right;
                  font-family: "Poppins";
                  font-style: normal;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 20px;
                  text-align: right;
                  color: $NEUTRAL_100;
                }
              }
            }
          }
        }
      }

      &.bMCard {
        h3 {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 150%;
          letter-spacing: 0.005em;
          color: $PRIMARY_MAIN;
          padding-bottom: 15px;
        }

        p {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: $NEUTRAL_100;
          padding-bottom: 8px;
        }

        .bM {
          margin-top: 16px;
          display: flex;
          gap: 8px;
          align-items: center;
          padding: 6px 10px;
          border: 1px solid $NEUTRAL_40;
          border-radius: 8px;
          background-color: $NEUTRAL_10 !important;

          span {
            flex-grow: 1;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: $NEUTRAL_100;
          }

          button {
            display: grid;
            place-items: center;
            background-color: transparent;
            border: none;
          }
        }
      }
    }
  }

  .buttonWrapper {
    > button {
      width: 100%;
      height: 40px;
      background-color: $PRIMARY_MAIN;
      border: none;
      border-radius: 5px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: $NEUTRAL_10;

      &:disabled {
        background-color: $PRIMARY_DISABLED;
      }
    }
  }

  .doneText {
    width: 299px;
    text-align: center;

    span {
      color: $NEUTRAL_100;
      font-weight: 500;
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    width: 100%;
    padding: 18px;

    // .alertWrapper {
    //   display: none;
    // }

    .content {
      gap: 0;

      padding-bottom: 100px;

      &.smallerPadding {
        padding-bottom: 0;
      }

      > div {
        border: none;
        padding-inline: 0;
        padding-block: 12px;
        background-color: transparent;

        &.infoCard {
          padding-top: 0;

          .companyInfo {
            .data {
              margin-inline: -18px;
              background-color: transparent;

              // .stacked {
              //   // flex-direction: column;
              //   // gap: 4px;

              //   // span {
              //   //   width: 100%;
              //   //   text-align: left !important;
              //   // }
              // }
            }
          }
        }

        &.bMCard {
          padding-bottom: 0 !important;
        }
      }
    }

    .buttonWrapper {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: $NEUTRAL_10;
      padding: 8px 18px;
      padding-bottom: 42px;
      border-top: 1px solid #e8e8e8;
    }
  }

  .bottomLine {
    position: absolute;
    width: 134px;
    height: 5px;
    left: calc(50% - 134px / 2);
    bottom: 8px;
    background: #000000;
    border-radius: 100px;
  }
}
