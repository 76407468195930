@import "../../../../theme/variables.scss";

.container {
  background-color: $NEUTRAL-10;
  // max-height: 85vh;
  // overflow: scroll;
  border-radius: 8px;
  width: 528px;
  max-width: 100vw;

  .header {
    background-color: $PRIMARY_SURFACE;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 16px;
    padding-bottom: 0;
    border-radius: 8px;
    // position: sticky;
    // top: 0;
    // z-index: 1;

    > h3 {
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
      color: $PRIMARY_MAIN;
    }

    > img {
      width: 198px;
      max-width: 90vw;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
    max-height: 350px;
    overflow: auto;

    .typeField {
      position: relative;
      background-color: $NEUTRAL_20;
      height: 32px;
      border-radius: 4px;
      border: 1px solid $NEUTRAL-40;
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 6px 8px;
      width: 77px;

      > span {
        flex-grow: 1;
      }

      .types {
        position: absolute;
        background-color: $NEUTRAL-10;
        border-radius: 0 0 8px 8px;
        top: calc(100% + 4px);
        left: 0;
        width: 100%;
        box-shadow: 0px 0px 1px rgba(9, 30, 66, 0.31),
          0px 3px 5px rgba(9, 30, 66, 0.2);
        z-index: 1;

        > div {
          text-align: center;
          padding: 4px 8px;
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 142.857%;
          cursor: pointer;

          &.active {
            background-color: $PRIMARY_SURFACE;
          }

          &:hover {
            background-color: $NEUTRAL-20;
          }

          &:not(:last-child) {
            border-bottom: 1px solid $NEUTRAL-40;
          }
        }
      }
    }

    .dates {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 16px;
    }

    .remark {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 4px;
      > span {
        color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));

        /* Text S/Medium */
        // font-family: Poppins;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 153.846% */
        > span {
          color: red;
        }
      }

      .remarkInput {
        border-radius: 8px;
        border: 1px solid var(--Color-Neutal-Neutral-40, #e0e0e0);
        background: var(--Color-Neutal-Neutral-10, #fff);
        padding: 12px 4px 4px 12px;
      }

      .remarkInput:focus {
        outline: none !important;
        border-color: $PRIMARY_MAIN;
        border-width: 1px 1px 1px 1px;
        border-style: solid;
      }
    }
  }

  .actions {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    border-top: 1px solid #e0e0e0;

    > button {
      padding: 12px 16px;
      border-radius: 8px;
      border: 1px solid $PRIMARY_MAIN;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 142.857%;

      &:disabled {
        border-color: $PRIMARY_DISABLED;
      }

      &:first-child {
        background-color: $NEUTRAL-10;
        color: $PRIMARY_MAIN;

        &:disabled {
          color: $PRIMARY_DISABLED;
        }
      }

      &:last-child {
        background-color: $PRIMARY_MAIN;
        color: $NEUTRAL-10;

        &:disabled {
          background-color: $PRIMARY_DISABLED;
        }
      }
    }
  }
}
