@import "../../../../../../theme/variables.scss";

.container {
  // .header {
  //   display: flex;
  //   align-items: center;
  //   justify-content: space-between;
  //   padding-bottom: 16px;

  //   h3 {
  //     font-family: "Poppins";
  //     font-style: normal;
  //     font-weight: 600;
  //     font-size: 20px;
  //     line-height: 150%;
  //     letter-spacing: 0.005em;
  //     color: $PRIMARY_MAIN;
  //   }

  //   button {
  //     background-color: transparent;
  //     border: none;
  //     font-family: "Poppins";
  //     font-style: normal;
  //     font-weight: 500;
  //     font-size: 14px;
  //     line-height: 20px;
  //     text-align: center;
  //     color: $PRIMARY_MAIN;
  //     cursor: pointer;
  //   }
  // }

  .schemeSection {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
    gap: 16px;
    // padding-bottom: 24px;

    .schemeCard {
      background-color: $NEUTRAL_10;
      border-radius: 8px;
      padding: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 18px;
      cursor: pointer;

      .left {
        flex-shrink: 0;

        .Icon {
          color: $NEUTRAL_70;
        }
      }

      .middle {
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        h5 {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 140%;
          color: $NEUTRAL_100;
          padding-bottom: 4px;
        }

        > span {
          font-family: "Poppins";
          font-style: normal;
          font-size: 14px;
          line-height: 20px;

          &:nth-child(2) {
            font-weight: 500;
            padding-bottom: 2px;
            color: $NEUTRAL_100;
          }

          &:last-child {
            padding-top: 4px;
            color: $NEUTRAL_60;
          }
        }

        .location {
          display: flex;
          align-items: center;
          gap: 4px;
          color: $PRIMARY_MAIN;
          padding-bottom: 8px;

          >*:first-child {
            flex-shrink: 0;
          }

          span {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
          }
        }
      }

      .right {
        .Icon {
          color: $PRIMARY_MAIN;
        }
      }
    }
  }

  .chartSection {
    width: 100%;
    height: 100%;
    background: $NEUTRAL_LIGHTEST;
    padding: 24px;
    border-radius: 8px;

    .subSectionHeader {
      display: flex;
      align-items: center;
      padding-bottom: 12px;

      > * {
        width: 100%;
      }

      h4 {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 140%;
        color: $NEUTRAL_DARKEST;
      }

      > button {
        text-align: end;
        background-color: transparent;
        border: none;
        color: $PRIMARY_MAIN;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        cursor: pointer;

        &:disabled {
          color: $PRIMARY_DISABLED !important;
          cursor: auto;
        }
      }
    }

    .topSubSection {
      .chartDoubleWrapper {
        overflow-x: scroll;
        width: 100%;
      }

      .chartWrapper {
        height: 300px;
        padding-top: 18px;
        padding-bottom: 14px;
      }
    }

    hr {
      height: 1px;
      background-color: $NEUTRAL_40;
      border: none;
    }

    .BottomSubSection {
      padding-top: 24px;

      .subSectionCard {
        border: 1px solid $NEUTRAL_40;
        padding: 6px 10px;
        border-radius: 8px;

        > * {
          border-bottom: 1px solid $NEUTRAL_40;

          &:last-child {
            border-bottom: none;
          }
        }

        .stackedSummary {
          display: flex;
          align-items: center;
          padding-block: 10px;

          // >* {
          //     width: 100%;
          // }

          .leftStack {
            display: flex;
            align-items: center;
            gap: 16px;
            width: 100%;

            > * {
              color: $NEUTRAL_80;
            }

            h5 {
              font-weight: 400;
              font-size: 16px;
              line-height: 140%;
            }
          }

          .rightStack {
            text-align: right;

            p {
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 140%;
              text-align: right;
              color: $NEUTRAL_100;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .schemeSection {
    grid-template-columns: 1fr !important;
  }

  .schemeCard {

    .left {
      height: 100%;
    }
  }
}

@media screen and (max-width: 480px) {
  .chartSection {
    padding: 18px;

    .topSubSection {
      .chartWrapper {
        padding-top: 10px;

        // &.chartWrapperExtra {
        //     min-width: calc(100% + 200px);
        // }
      }
    }
  }
}
