@import "../../../../theme/variables.scss";

.container {
  background-color: $NEUTRAL-10;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  border-radius: 8px;
  width: 370px;
  max-width: 90vw;

  >span {
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: 0.1px;
    color: $PRIMARY_MAIN;
    padding-bottom: 8px;
  }

  >p {
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 142.857%;
    padding-bottom: 32px;
  }

  >button {
    padding: 12px 16px;
    border-radius: 8px;
    background-color: $NEUTRAL-10;
    border: 1px solid $PRIMARY_MAIN;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 142.857%;
    width: 100%;
    color: $PRIMARY_MAIN;
  }
}