@import "../../../../../../../../../../theme/variables.scss";

.background {
  width: 100vw;
  height: 100vh;
  background-color: $NEUTRAL_OVERLAY;
  z-index: 3;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-inline: 18px;

  .container {
    position: relative;
    border-radius: 8px;
    background-color: $NEUTRAL_LIGHTEST;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 16px;
    width: 370px;
    overflow-y: scroll;

    h4 {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      color: $PRIMARY_MAIN;
    }

    .form {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding-top: 16px;

      .boxInput {
        display: flex;
        flex-direction: column;
        gap: 4px;

        label {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          color: $NEUTRAL_100;
        }

        input {
          height: 44px;
          background: $NEUTRAL_10;
          border: 1px solid $NEUTRAL_40;
          border-radius: 8px;
          padding-inline: 12px;

          &:focus {
            outline: none !important;
            border-color: $PRIMARY_MAIN;
            border-width: 1px;
            border-style: solid;
          }

          &::-webkit-input-placeholder {
            /* Edge */
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            color: $NEUTRAL_60;
          }

          &:-ms-input-placeholder {
            /* Internet Explorer */
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            color: $NEUTRAL_60;
          }

          &::placeholder {
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            color: $NEUTRAL_60;
          }

        }
      }

      .actionsWrapper {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 8px;

        button {
          padding-inline: 16px;
          height: 36px;
          border-radius: 8px;
          cursor: pointer;

          span {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
          }

          &:first-child {
            border: 1px solid $PRIMARY_MAIN;
            background-color: transparent;
            color: $PRIMARY_MAIN;
          }

          &:last-child {
            border: 1px solid $PRIMARY_MAIN;
            background-color: $PRIMARY_MAIN;
            color: $NEUTRAL_10;
          }
        }
      }
    }

  }
}

.mobileContainer {
  background-color: $NEUTRAL_LIGHTEST;
  border-radius: 20px 20px 0px 0px;
  width: 100%;
  padding-inline: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 600px;
  overflow-y: scroll;
  z-index: 0;

  .TopWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 3;
    background-color: inherit;
    padding-top: 15px;

    .TopLine {
      background-color: #b4b7ba;
      height: 5px!important;
      width: 42px;
      border-radius: 5px;
    }
  
    .Header {
      padding-top: 17px;
      text-align: center;
      padding-bottom: 20px;
  
      h4 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 140%;
        text-align: center;
        color: $NEUTRAL_DARKEST;
      }
    }

  }


  .form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-bottom: 24px;

    .boxInput {
      display: flex;
      flex-direction: column;
      gap: 4px;

      label {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: $NEUTRAL_100;
      }

      input {
        height: 44px;
        background: $NEUTRAL_10;
        border: 1px solid $NEUTRAL_40;
        border-radius: 8px;
        padding-inline: 12px;

        &:focus {
          outline: none !important;
          border-color: $PRIMARY_MAIN;
          border-width: 1px;
          border-style: solid;
        }

        &::-webkit-input-placeholder {
          /* Edge */
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 20px;
          color: $NEUTRAL_60;
        }

        &:-ms-input-placeholder {
          /* Internet Explorer */
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 20px;
          color: $NEUTRAL_60;
        }

        &::placeholder {
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 20px;
          color: $NEUTRAL_60;
        }

      }
    }

    .actionsWrapper {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 8px;

      button {
        padding-inline: 16px;
        height: 36px;
        border-radius: 8px;
        cursor: pointer;

        span {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
        }

        &:first-child {
          border: 1px solid $PRIMARY_MAIN;
          background-color: transparent;
          color: $PRIMARY_MAIN;
        }

        &:last-child {
          border: 1px solid $PRIMARY_MAIN;
          background-color: $PRIMARY_MAIN;
          color: $NEUTRAL_10;
        }
      }
    }
  }

  
}