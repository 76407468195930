.leftBodyImages {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 16px;
  padding: 16px;
  // cursor: pointer;
  .textTitle {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    width: 100%;
    .textTop {
      color: var(--neutral-90, #404040);
      text-align: center;

      /* Text L/Bold */
      // font-family: Poppins;
      font-size: calc(14px + (16 - 14) * ((100vw - 280px) / (1600 - 280)));
      font-style: normal;
      font-weight: 700;
      line-height: 140%; /* 22.4px */
      > span {
        color: var(--Danger-Main, #ff3e13);
        // font-family: Poppins;
        font-size: calc(14px + (16 - 14) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 142.857% */
      }
    }
    .warningBox {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      padding: 8px;
      border-radius: 8px;
      background: var(--primary-surface, #f5f7fe);
      width: 100%;
      .boxWarn {
        display: flex;
        align-items: center;
        gap: 8px;
        // width: 100%;
      }
      .textWarn {
        color: #000;
        width: 100%;
        text-align: center;

        /* Text M/Reguler */
        //   font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
      }
    }
  }
}

.uploadBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 764px;
  .middleText {
    cursor: pointer;
    border-radius: 16px;
    padding: 16px;
    border: 1px dashed var(--neutral-50, #c2c2c2);
    background: var(--neutral-30, #ededed);
    width: 430px;
    // width: 430px;
    height: 764px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
    &.redBorder {
      border: 1px dashed red;
    }
    .dragAndDrop {
      color: var(--neutral-100, #0a0a0a);
      text-align: center;

      /* Text M/Medium */
      // font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
    }
    .p {
      color: var(--neutral-50, #c2c2c2);
      text-align: center;

      /* Text S/Reguler */
      // font-family: Poppins;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 153.846% */
    }
    .route {
      color: var(--primary-main, #1571de);
      text-align: center;
      // font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
      text-decoration-line: underline;
    }
    .imagesBoxUpload {
      width: 40px;
      height: 40px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .imagesPreview {
    width: 430px;
    // width: 430px;
    height: 764px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.actionImages {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 8px;
  .deleteFiles {
    width: 100%;
    display: flex;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    // flex: 1 0 0;
    border-radius: 8px;
    border: 1px solid var(--danger-main, #ff3e13);
    background: var(--neutral-10, #fff);
    color: var(--danger-main, #ff3e13);
    text-align: center;

    /* Text M/Reguler */
    // font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
  .uploadAgain {
    border-radius: 8px;
    border: 1px solid var(--primary-main, #1571de);
    background: var(--neutral-10, #fff);
    width: 100%;
    display: flex;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    // flex: 1 0 0;
    color: var(--primary-main, #1571de);
    text-align: center;

    /* Text M/Reguler */
    // font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
}
.boxDrop {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}
.dropDownImagesShow {
  cursor: pointer;
  //   position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid var(--neutral-40, #e0e0e0);
  background: var(--neutral-10, #fff);
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .leftContent {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .exempleText {
    color: var(--neutral-90, #404040);

    /* Text M/Reguler */
    // font-family: Poppins;
    font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    position: relative;
  }
  .dropdownContent {
    display: flex;
    align-items: center;
    justify-content: center;
    // width: 100%;

    img {
      width: 430px;
      height: 764px;
      object-fit: cover;
    }
  }
}

.iconCross {
  cursor: pointer;
}

@media screen and (max-width: 900px) {
  .dropDownImagesShow {
    .dropdownContent {
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .uploadBox {
    .middleText {
      width: 100%;
    }
    // width: 250px;
    width: 100%;
    height: 100%;
    .imagesPreview {
      width: 100%;
      height: 100%;
    }
  }
}

@media screen and (max-width: 420px) {
  .actionImages {
    flex-direction: column;
  }
}
