@import "../../../theme/variables.scss";

.container {
  width: 100%;
  z-index: 0;
}

.inputWrapper .input {
  width: 100%;
  padding: 10px 12px;
  border: 1px solid $NEUTRAL_40;
  border-radius: 8px;
  background-color: $NEUTRAL_10;
  height: 46px;

&:not(:disabled) {
  &.error {
    border-color: #F5610D;
  }
}
}

.errorText {
  color: var(--Helper, #F5610D);
  /* Caption 1/Reguler */
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  /* 133.333% */
}


.inputWrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.input {
  appearance: none;
  -webkit-appearance: none;
}
.input::-webkit-inner-spin-button,
.input::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.iconWrapper {
  position: absolute;
  right: 0;
  padding-right: 12px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

// .icon {
//   color: $NEUTRAL_80;
// }

// input[type="date"]::-webkit-calendar-picker-indicator {
//   background: none;
//   z-index: 2;
//   cursor: pointer;
//   width: 10%;
// }

.input:focus {
  outline: none !important;
  // border-color: $PRIMARY_MAIN;
  border-width: 1px;
  border-style: solid;

  &:not(.error) {
    border-color: $PRIMARY_MAIN;
  }
}

.input:disabled,
.input:read-only {
  outline: none !important;
  border-color: $NEUTRAL_40;
  border-width: 1px;
  border-style: solid;
  background-color: $NEUTRAL_30;
  color: $NEUTRAL_60;
}
