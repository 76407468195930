@import "../../../../../../../../../theme/variables.scss";

.container {
  background-color: $NEUTRAL_LIGHTEST;
  border-radius: 20px 20px 0px 0px;
  width: 100%;
  padding-top: 15px;
  padding-inline: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 24px;

  > * {
    width: 100%;
  }

  .topLine {
    background-color: #b4b7ba;
    height: 5px;
    width: 42px;
    border-radius: 5px;
  }

  .header {
    padding-top: 17px;
    text-align: center;
    padding-bottom: 20px;

    h3 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      text-align: center;
      color: $NEUTRAL_DARKEST;
    }
  }

  .infoWrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .stackedInfo {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: auto;
      padding-bottom: 8px;
      border-bottom: $NEUTRAL_LINE;

      &:last-child {
        border-bottom: none;
        padding-bottom: 0;

        > *:first-child {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 140%;
          color: $NEUTRAL_100;
        }

        > *:last-child {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 140%;
          text-align: right;
          color: $NEUTRAL_100;
        }
      }

      > *:first-child {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $NEUTRAL_70;
      }

      > *:last-child {
        text-align: right;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        text-align: right;
        color: $NEUTRAL_DARKEST;
      }
    }
  }
}
