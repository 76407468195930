.containerModal {
  width: 588px;
  border-radius: 8px;
  background: var(--Color-Neutal-Neutral-10, #fff);
  .header {
    padding: 16px 16px 12px 16px;
    > span {
      color: var(--Primary-Main, #1571de);
      //   text-align: center;

      /* Text L/SemiBold */
      //   font-family: Poppins;
      font-size: calc(14px + (16 - 14) * ((100vw - 280px) / (1600 - 280)));
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 22.4px */
    }
  }

  .listBox {
    display: flex;
    padding: 16px;

    flex-direction: column;
    gap: 16px;
    max-height: 300px;
    min-height: 300px;
    overflow: auto;
    > div {
      display: flex;
      align-items: center;
      gap: 4px;
      .icon {
        width: 16px;
        position: relative;
        top: 1px;
      }
      > span {
        color: var(--Color-Neutal-Neutral-70, #757575);

        /* Text M/Reguler */
        // font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
      }
    }
  }

  .notFoundBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-height: 300px;
    min-height: 300px;
    gap: 8px;
    > div:first-child {
      img {
        width: 200px;
        height: 100%;
      }
    }

    > span {
      color: var(--Primary-Main, #1571de);

      /* Heading 6/Bold */
      // font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%; /* 33.6px */
      letter-spacing: 0.12px;
    }
  }

  .buttonSection {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    background: var(--Color-Neutal-Neutral-10, #fff);
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
    > span {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      background: var(--Primary-Main, #1571de);
      padding: 12px 16px;
      color: var(--Color-Neutal-Neutral-10, #fff);
      text-align: center;

      /* Text M/Reguler */
      //   font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      cursor: pointer;
    }
  }
}
