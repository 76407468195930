@import "../../../theme/variables.scss";

.container {
  position: fixed;
  top: 0;
  transition: all 0.5s ease;
  right: -1000px;
  background-color: $NEUTRAL_10;
  width: 348px;
  border-radius: 12px 0px 0px 12px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  height: 100vh;
  z-index: 3;
  display: flex;
  flex-direction: column;
  overflow: auto;

  &.show {
    right: 0;
  }

  > * {
    &:not(:last-child) {
      border-bottom: 1px solid $NEUTRAL_40;
      padding-block: 16px;
      padding-inline: 24px;

      &:nth-last-child(2) {
        border-bottom: none;
        flex-grow: 1;
      }
    }
  }

  .header {
    // padding-top: 0;
    position: sticky;
    top: 0;
    background-color: $NEUTRAL_10;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    > span {
      color: var(--Danger-Main, #d42701);

      /* Text L/Medium */
      // font-family: Poppins;
      font-size: calc(14px + (16 - 14) * ((100vw - 280px) / (1600 - 280)));
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
      cursor: pointer;
    }
    > h3 {
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 30px;
      color: $NEUTRAL_90;
    }
  }

  .each {
    display: flex;
    flex-direction: column;
    gap: 8px;

    > span {
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      color: $NEUTRAL_90;
    }

    .statusOptions {
      display: flex;
      flex-direction: column;
      gap: 16px;

      > div {
        display: flex;
        align-items: center;
        gap: 8px;

        > span {
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          color: $NEUTRAL_90;
        }
      }
    }

    .regionOptions {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 16px;

      > div {
        display: flex;
        align-items: center;
        gap: 8px;

        > span {
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          color: $NEUTRAL_90;
        }
      }
    }

    .stageOptions {
      display: flex;
      flex-direction: column;
      gap: 16px;

      > div {
        display: flex;
        align-items: center;
        gap: 8px;

        > span {
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          color: $NEUTRAL_90;

          &.disabled {
            color: $NEUTRAL_50;
          }
        }
      }
    }
  }

  .footer {
    position: sticky;
    bottom: 0;
    padding: 12px 24px;
    background-color: $NEUTRAL_10;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;

    > button {
      padding: 10px 24px;
      background-color: $PRIMARY_MAIN;
      border: none;
      border-radius: 8px;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      color: $NEUTRAL-10;
    }
  }
}
