@import "../../../../../theme/variables.scss";

.container {
  // width: 100%;
  // min-height: 100%;
  height: calc(100% - 70px);
  overflow-y: scroll;
  // display: flex;
  // flex-direction: column;


  .navControlWrapper {
    // position: sticky;
    // top: 70px;
    position: sticky;
    top: 0;
    z-index: 2;
    border-bottom: 1px solid $NEUTRAL_40;
    background-color: $NEUTRAL_LIGHTER;

    .Wrapper {
      display: flex;
      align-items: center;
      width: 100%;
      overflow-x: scroll;


      &::-webkit-scrollbar {
        display: none;
      }

      .BreadcrumbWrapper {
        padding: 30px;
        padding-right: 0px!important;
        width: 100%;
        padding-bottom: 24px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 24px;

        >span {
          background-color: $NEUTRAL_LIGHTEST;
          width: 40px;
          aspect-ratio: 1;
          display: grid;
          place-items: center;
          border-radius: 50%;
          cursor: pointer;
          flex-shrink: 0;
        }

        .BreadcrumbText {
          font-weight: 500;
          font-size: 16px !important;
          line-height: 20px;
          color: $NEUTRAL_60;
          cursor: pointer;
        }

        .BreadcrumbText2 {
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          color: $NEUTRAL_100;
        }
      }

      .TypeWrapper {
        background-color: $NEUTRAL_20;
        border: 1px solid $NEUTRAL_40;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 15px;
        padding: 8px;
        margin-right: 24px;
        white-space: nowrap;
        text-overflow: ellipsis;

        .DescWrapper {
          display: flex;
          flex-direction: column;

          >span:first-child {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 140%;
            color: $NEUTRAL_100;
          }

          >p:last-child {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: $NEUTRAL_70;
          }
        }



        .contractIcon {
          color: $PRIMARY_MAIN
        }

      }
    }
  }

  .contentWrapper {
    width: 100%;
    // height: calc(100vh - 330px);
    overflow: auto;
    z-index: 0!important;
    // flex-grow: 1;
  }


  .errorWrapper, .loadingWrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 280px);
  }
}

@media screen and (max-width: 768px) {

  .navControlWrapper {
    z-index: 1;
  }
//   .container {
  
//   .contentWrapper {
//     // height: calc(100vh - 317px)!important;
//   }

// }
}