@import "../../../../theme/variables.scss";

.container {
  overflow: hidden;
  position: relative;
  .image {
    position: absolute;
    width: 100vw;
    top: 0;
    animation: scroll 35s linear infinite;
    > img {
      width: 100%;
    }
  }

  @keyframes scroll {
    0% {
      transform: translateY(0%);
    }
    97% {
      transform: translateY(calc(-100% + 600px));
    }
    100% {
      transform: translateY(0%);
    }
  }

  .background {
    position: absolute;
    top: 0;
    background-color: #000000b2;
    z-index: 2;
    width: 100vw;
    height: 600px;
  }
  .wrapper {
    height: 600px;
    display: flex;
    align-items: center;
    width: 100vw;
    display: grid;
    place-items: center;
    .textWrapper {
      z-index: 3;
      max-width: calc(1440px - 180px);
      // padding-inline: 90px;
      text-align: center;
      transform: translateY(-50px);
      > :first-child {
        color: $NEUTRAL_10;
        font-weight: 700;
        font-size: 38px;
        margin-bottom: 8px;
      }
      > :last-child {
        color: $NEUTRAL_30;
        font-weight: 400;
        font-size: 16px;
      }
    }
  }
}

// @media only screen and (max-width: 768px) {
//     .container{

//     }
// }
