@import "../../../../../theme/variables.scss";

.container {
  padding-block: 12px;
  // padding-block: 24px;
  padding-inline: 16px;
  padding-bottom: 90px;
  // padding-inline: 30px;
  min-height: 100%;
  z-index: 0 !important;
  overflow: clip;
  // position: relative;

  // .autoScroll {
  //   display: hidden;
  //   position: absolute;
  //   top: 0;
  // }


  .buttonsWrapper {
    display: flex;
    gap: 12px;
    justify-content: flex-end;
    padding-top: 16px;
    background: rgba(250, 250, 250, 0.98);
    position: fixed;
    padding: 16px 30px;
    height: 76px;
    bottom: 0;
    bottom: 0;
    right: 0;
    left: 76px;


    button {
      padding: 8px 16px;
      border-radius: 8px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      cursor: pointer;
      border: none;
      font-family: 'Poppins', sans-serif;
      display: flex;
      align-items: center;
      gap: 8px;

      &.previous {
        background: $NEUTRAL_10;
        border: 1px solid $NEUTRAL_100;
        color: $NEUTRAL_100;
      }

      &.next {
        background: $PRIMARY_MAIN;
        color: $NEUTRAL_10;

        &:disabled {
          background-color: $PRIMARY_DISABLED;
        }
      }
    }
  }
}

// @media screen and (max-width: 768px) {
//   .container {
//     padding-block: 16px;
//     padding-inline: 18px;
//   }
// }