@import "../../../../theme/variables.scss";


.background {
  width: 100vw;
  height: 100vh;
  background-color: $NEUTRAL_OVERLAY;
  z-index: 3;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-inline: 18px;

  .container {
    border-radius: 8px;
    background-color: $NEUTRAL_LIGHTEST;
    display: flex;
    flex-direction: column;
    padding-inline: 24px;
    padding-bottom: 24px;
    position: relative;
    width: 938px;
    max-height: calc(100vh - 60px);
    overflow: auto;

    // &::-webkit-scrollbar {
    //   display: none;
    // }
    .errorText {
      color: var(--Helper, #F5610D);
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }
    .header {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      padding-inline: 32px;
      padding-top: 24px;
      padding-bottom: 16px;
      border-bottom: 1px solid $NEUTRAL_30;
      position: sticky;
      top: 0;
      background-color: inherit;

      button {
        position: absolute;
        right: 0;
        display: grid;
        place-items: center;
        background-color: transparent;
        border: none;
      }
    }

    .content {
      padding-block: 16px;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      gap: 16px;
    }

    .buttonWrapper {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      button {
        background: $NEUTRAL_10;
        border: 1px solid $NEUTRAL_100;
        border-radius: 8px;
        height: 36px;
        padding: 8px 16px;

        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $NEUTRAL_100;

      }
    }

  }
}