@import "../../../../theme/variables.scss";
.bodyModal {
  height: 100vh;
  width: 100vw;
  background-color: #00000080;
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  transition: all 0.3s ease;
  &.fullSecreenBody {
    // height: 90vh;

    padding: 0;
    background-color: transparent;
    align-items: flex-end;
  }

  // border-radius: 8px;
}

.container {
  background-color: $NEUTRAL_10;
  width: 743px;
  max-width: 100vw;
  height: 90%;
  border-radius: 8px;
  transition: transform 0.3s ease;
  overflow: auto !important;

  &.fullScreenContainer {
    border-radius: 0px;
    width: 100%;
    height: 100%;
    transition: all 0.3s ease;
    // height: 100vh;
  }

  .errorWrapper {
    text-align: right;
    color: $DANGER_MAIN;

    &.visible {
      opacity: 1;
    }

    &.hide {
      opacity: 0;
    }
  }

  .profile {
    display: flex;
    align-items: center;
    gap: 16px;

    span {
      color: $PRIMARY_MAIN !important;
    }
  }

  .header {
    padding: 0px 20px 0px 20px;
    display: flex;
    align-items: center;
    gap: 16px;
    padding-bottom: 8px;
    padding-top: 16px;
    border-bottom: 1px solid $NEUTRAL_40;
    &.stickyHeader {
      position: sticky;
      width: 100%;
      z-index: 4;
      top: 0;
      background: var(--neutral-10, #fff);
    }

    > h3 {
      flex-grow: 1;
      font-family: Poppins;
      font-size: calc(14px + (24 - 14) * ((100vw - 280px) / (1600 - 280)));
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
      letter-spacing: 0.13px;
    }

    .leftButton {
      display: flex;
      align-items: center;
      gap: 16px;
      .fullScreen {
        position: relative;
        bottom: 2px;
        cursor: pointer;
      }

      > button {
        background-color: transparent;
        border: none;
      }
    }
  }

  .content {
    padding-bottom: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 100%;
    // height: 100%;
    // min-height: 80vh;
    // overflow: scroll;
    padding: 16px 24px;

    .tabs {
      display: flex;
      align-items: flex-end;
      border-bottom: 1px solid $NEUTRAL_40;
      position: sticky;
      top: 0;
      background-color: $NEUTRAL_10;
      padding-top: 16px;
      z-index: 1;

      > div {
        padding: 4px 12px;
        padding-bottom: 8px;
        border-bottom: 4px solid transparent;
        transition: all 0.3s;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        color: $NEUTRAL_70;

        &.active {
          border-color: $PRIMARY_MAIN;
          color: $PRIMARY_MAIN;
          font-weight: 600;
        }
      }
    }

    .main {
      display: flex;
      flex-direction: column;
      gap: 16px;
      z-index: 0;

      .each {
        display: flex;
        align-items: flex-start;
        gap: 8px;
        position: relative;
        // overflow: hidden;

        > * {
          &:first-child {
            width: 32px;
            aspect-ratio: 1;
            border-radius: 50%;
            background-color: $PRIMARY_SURFACE;
            border: 1px solid $PRIMARY_MAIN;
            display: grid;
            place-items: center;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 140%;
            color: $PRIMARY_MAIN;
            z-index: 1;
            flex-shrink: 0;
          }

          .topTitleCandidate {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }

          &:nth-child(2) {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            gap: 12px;
            // overflow: scroll;
            width: 100px;

            > span {
              font-family: Poppins;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 140%;
              // padding-b: 12px;
            }

            .tableWrapper {
              width: 100%;
              overflow: auto;
              border: 1px solid $NEUTRAL_40;
              border-radius: 8px;
              height: 350px;
              display: flex;
              flex-direction: column;

              .noData {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 4px;
                flex-grow: 1;

                > span {
                  &:first-child {
                    font-family: Poppins;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 140%;
                  }

                  &:last-child {
                    font-family: Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                    color: $NEUTRAL_60;
                  }
                }
              }

              .filler {
                flex-grow: 1;
              }

              .pagination {
                position: sticky;
                bottom: 0;
                left: 0;
                background-color: $NEUTRAL_10;
                border-top: 1px solid $NEUTRAL_40;
              }

              table {
                overflow: auto;
                width: 100%;
                border-collapse: separate;
                border-spacing: 0;

                thead {
                  tr {
                    th {
                      padding: 16px 24px;
                      white-space: nowrap;
                      background-color: $NEUTRAL_10;
                      font-family: Poppins;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: 20px;
                      border-bottom: 1px solid $NEUTRAL_40;
                      text-align: left;
                      position: sticky;
                      top: 0;

                      &:first-child {
                        position: sticky;
                        left: 0;
                        border-right: 1px solid $NEUTRAL_40;
                        z-index: 1;
                      }
                    }
                  }
                }

                tbody {
                  tr {
                    td {
                      padding: 16px 20px;
                      font-family: Poppins;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 20px;
                      border-bottom: 1px solid $NEUTRAL_40;

                      &:first-child {
                        position: sticky;
                        left: 0;
                        background-color: $NEUTRAL_10;
                        border-right: 1px solid $NEUTRAL_40;
                      }
                    }
                  }
                }
              }
            }
          }

          &.line {
            width: 4px;
            height: 100%;
            background-color: transparent;
            border-left: 2px dashed $PRIMARY_MAIN;
            position: absolute;
            left: 15px;
            top: 16px;
          }
        }
      }

      > button {
        align-self: flex-end;
        padding: 12px;
        border-radius: 8px;
        border: none;
        background-color: $PRIMARY_MAIN;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        color: $NEUTRAL_10;
        position: relative;
        bottom: 16px;

        &:disabled {
          background-color: $PRIMARY_DISABLED;
        }
      }
    }

    .cv {
      display: flex;
      flex-direction: column;

      > button {
        display: flex;
        align-items: center;
        gap: 8px;
        align-self: flex-start;
        background-color: transparent;
        border: none;
        color: $PRIMARY_MAIN;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }
    }
  }
}

.Popover {
  position: absolute;
  background-color: #fff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 0px 8px;
  // padding-bottom: 16px;
  width: 197px;
  top: 55px;
  right: 15px;
  z-index: 2;
  label {
    // display: block;
    display: none;
    width: 100%;

    padding-bottom: 6px;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $NEUTRAL_70;
  }

  div {
    &.StackedTop {
      display: flex;
      align-items: center;
      gap: 10px;
      cursor: pointer;
      border-bottom: 1px solid $NEUTRAL_30;

      &:hover {
        span {
          font-weight: 500;
        }
      }

      span {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        padding-block: 12px;
      }

      &:nth-child(2) {
        color: $NEUTRAL_80;
        // border-bottom: 1px solid $NEUTRAL_30;
        padding-top: 0;
      }

      &:nth-child(3) {
        padding-bottom: 0;

        span {
          color: $DANGER_MAIN;
        }
      }
    }
    &.Stacked {
      display: flex;
      align-items: center;
      gap: 10px;
      cursor: pointer;

      &:hover {
        span {
          font-weight: 500;
        }
      }

      span {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        padding-block: 12px;
      }

      &:nth-child(2) {
        color: $NEUTRAL_80;
        // border-bottom: 1px solid $NEUTRAL_30;
        padding-top: 0;
      }

      &:nth-child(3) {
        padding-bottom: 0;

        span {
          color: $DANGER_MAIN;
        }
      }
    }
  }
}

.stickyBox {
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: sticky;
  width: 100%;
  z-index: 4;
  top: 0;
  background: var(--neutral-10, #fff);
}

.navbarModal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  border-bottom: 1px solid var(--neutral-30, #ededed);
  background: var(--neutral-10, #fff);

  width: 100%;

  .rightNavbar {
    display: flex;
    align-items: center;
    gap: 24px;
    .divider {
      width: 1px;
      height: 28px;
      background: #e0e0e0;
    }
    .profile {
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 8px;
      .profileImages {
        border-radius: 38px;
        border: 1px solid var(--neutral-20, #f5f5f5);
        img {
          width: 38px;
          height: 38px;
        }
      }
      .descBox {
        display: flex;
        flex-direction: column;
        .roleDesc {
          color: var(--neutral-50, #c2c2c2);

          /* Caption 1/Reguler */
          // font-family: Poppins;
          font-size: calc(11em / 14);
          font-style: normal;
          font-weight: 400;
          line-height: 16px; /* 133.333% */
        }
        .nameDesc {
          color: var(--neutral-100, #0a0a0a);

          /* Caption 1/Medium */
          font-family: Poppins;
          font-size: calc(12em / 14);
          font-style: normal;
          font-weight: 500;
          line-height: 16px; /* 133.333% */
        }
      }
    }
  }
  .burgerWrapper {
    display: none;
  }
  .leftNavbarResp {
    display: none;
  }
  .leftNavbar {
    display: flex;
    align-items: center;
    gap: 180px;
    .rekrutment {
      overflow: hidden;
      color: var(--neutral-100, #0a0a0a);
      text-overflow: ellipsis;

      /* Heading 6/SemiBold */
      // font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 33.6px */
      letter-spacing: 0.12px;
    }
    .images365 {
      cursor: pointer;
      img {
        width: 86px;
        height: 38px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .Popover {
    left: 25px;
  }
  .navbarModal {
    .burgerWrapper {
      display: flex;
    }
    .leftNavbarResp {
      display: flex;
      align-items: center;
      gap: 24px;
      .imagesBox {
        border-radius: 38px;
        border: 1px solid var(--neutral-20, #f5f5f5);
        img {
          width: 38px;
          height: 38px;
        }
      }
    }
    .rightNavbar {
      display: none;
    }
    .leftNavbar {
      display: none;
    }
  }

  .dropdownNavbar {
    top: 80px;
    position: absolute;
    width: 100%;
    height: 100vh;
    z-index: 5;
    left: 0;
    right: 0;
    background-color: #00000080;

    .null {
      width: 100vw;
      height: 100vh;
      // background-color: #00000080;
      z-index: 1;
    }
    .navLink {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 11px;
      cursor: pointer;
      &.navLinkActive {
        background-color: #f1f3ff !important;
        .routeText {
          color: $PRIMARY_MAIN;
        }
      }
      .routeText {
        color: #757575;
        font-weight: 500;
        font-size: 13px;
      }
    }
    .box {
      display: flex;
      flex-direction: column;
      gap: 5px;
      background: #fff;
      width: 100vw;
      // height: 50px;
      border-radius: 0 0 8px 8px;
      padding: 16px 20px;
    }
  }

  .container {
    position: fixed;
    bottom: 0;
    width: 100%;
    // height: 100%;

    height: 87%;
    th,
    td {
      position: static !important;
      // left: auto;
    }

    thead {
      position: sticky;
      top: 0;
      z-index: 1;
    }
  }

  .bodyModal {
    align-items: flex-end;
    padding: 0;
  }
}
