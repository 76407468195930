@import "../../../../theme/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 60px;
  .headWrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .topSection {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    .smallTitle {
      @include font-size(16, 14, 14px);
      font-weight: 400;
      text-align: center;
    }
    .bigTitle {
      @include font-size(26, 20, 20px);
      font-weight: 600;
      color: $PRIMARY_MAIN;
      text-align: center;
    }
  }
  .pillsWrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding-inline: 48px;
    .pil {
      border: 1px solid #dadce0;
      padding: 8px 24px;
      border-radius: 100px;
      > p {
        font-weight: 400;
        font-size: 14px;
      }
    }
  }
  .bottomSection {
    display: flex;
    justify-content: center;
    align-items: center;
    > img {
      width: 716px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .container {
    gap: 32px !important;
    .pillsWrapper {
      padding-inline: 0px !important;
    }
    .bigTitle {
      font-weight: 700;
    }
    .bottomSection {
      > img {
        width: 100% !important;
      }
    }
  }
}
