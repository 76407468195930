@import "../../../../theme/variables.scss";

.container {
  background-color: $NEUTRAL-10;
  width: 354px;
  max-width: 100vw;
  border-radius: 8px;

  .header {
    padding: 16px;
    border-bottom: 1px solid $NEUTRAL-40;

    h3 {
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
      color: $PRIMARY_MAIN;
    }

    span {
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      color: $NEUTRAL-60;
    }
  }

  .content {
    padding: 8px 12px;

    .each {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  .actions {
    border-top: 1px solid $NEUTRAL-40;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;

    >button {
      padding: 12px 16px;
      border-radius: 8px;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;

      &:first-child {
        background-color: $NEUTRAL-10;
        color: $PRIMARY_MAIN;
        border: 1px solid $PRIMARY_MAIN;
      }

      &:last-child {
        background-color: $PRIMARY_MAIN;
        color: $NEUTRAL-10;
        border: 1px solid $PRIMARY_MAIN;

        &:disabled {
          background-color: $PRIMARY_DISABLED;
          border-color: $PRIMARY_DISABLED;
        }
      }
    }
  }
}