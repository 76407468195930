@import "../../../../../../../../theme/variables.scss";

.container {
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid $PLATINUM_SOLID;

  .header {
    padding: 10px;
    text-align: center;
    background-color: $ALICE_BLUE;
    border-bottom: 1px solid $PLATINUM_SOLID;

    h3 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 150%;
      text-align: center;
      letter-spacing: 0.005em;
      color: $NEUTRAL_100;
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
    background-color: #fff;
    > div {
      display: flex;
      flex-direction: column;
      gap: 8px;
      > span {
        color: var(--Primary-Main, #1571de);

        /* Heading 7/Bold */
        // font-family: Poppins;
        font-size: calc(16px + (20 - 16) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 30px */
        letter-spacing: 0.1px;
      }

      .delegationBox {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .delegations {
          display: flex;
          flex-direction: column;
          gap: 8px;
          padding: 16px;
          border-radius: 8px;
          border: 1px solid var(--Color-Neutal-Neutral-40, #e0e0e0);
          background: var(--Color-Neutal-Neutral-10, #fff);
          > div:first-child {
            display: flex;
            align-items: center;
            gap: 8px;
            > span {
              color: var(
                --Neutral-100,
                var(--Color-Neutal-Neutral-19, #0a0a0a)
              );

              /* Text L/SemiBold */
              // font-family: Poppins;
              font-size: calc(
                12px + (14 - 12) * ((100vw - 280px) / (1600 - 280))
              );
              font-style: normal;
              font-weight: 600;
              line-height: 140%; /* 22.4px */
            }
          }

          .box {
            display: flex;
            // align-items: center;
            justify-content: space-between;
            gap: 16px;
            .users {
              width: 100%;
              display: flex;
              flex-direction: column;
              // align-items: center;

              gap: 8px;
              border-radius: 8px;
              background: var(--Color-Neutal-Neutral-20, #f5f5f5);
              // height: 100%;
              // min-height: 200px;
              padding: 16px;
              flex-grow: 1;
              // height: 100vh;
              > span {
                color: var(
                  --Neutral-100,
                  var(--Color-Neutal-Neutral-19, #0a0a0a)
                );

                /* Text M/Medium */
                // font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px; /* 142.857% */
                // position: relative;
                // top: 0;
              }

              > div {
                display: flex;
                flex-direction: column;
                gap: 4px;
                flex-grow: 1;

                .notFound {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  flex-grow: 1;

                  .img {
                    img {
                      width: 150px;
                      height: 100%;
                    }
                  }

                  > span {
                    color: var(
                      --Neutral-50,
                      var(--Color-Neutal-Neutral-50, #c2c2c2)
                    );
                    text-align: center;

                    /* Text S/Reguler */
                    // font-family: Poppins;
                    font-size: calc(
                      10px + (13 - 10) * ((100vw - 280px) / (1600 - 280))
                    );
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px; /* 153.846% */
                  }
                }
                .cardUsers {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  gap: 16px;
                  border-radius: 8px;
                  border: 1px solid var(--Color-Neutal-Neutral-40, #e0e0e0);
                  background: var(--Color-Neutal-Neutral-10, #fff);
                  padding: 6px 10px;
                  > div {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    .images {
                      img {
                        width: 32px;
                        height: 32px;
                        border-radius: 32px;
                      }
                    }

                    .name {
                      display: flex;
                      flex-direction: column;
                      gap: 4px;
                      > span:first-child {
                        color: var(
                          --Neutral-100,
                          var(--Color-Neutal-Neutral-19, #0a0a0a)
                        );

                        /* Text M/Reguler */
                        // font-family: Poppins;
                        font-size: calc(
                          12px + (14 - 12) * ((100vw - 280px) / (1600 - 280))
                        );
                        font-style: normal;
                        font-weight: 400;
                        line-height: 20px; /* 142.857% */
                      }

                      > span:last-child {
                        color: var(
                          --Neutral-60,
                          var(--Color-Neutal-Neutral-60, #9e9e9e)
                        );

                        /* Caption 1/Reguler */
                        // font-family: Poppins;
                        font-size: calc(
                          10px + (12 - 10) * ((100vw - 280px) / (1600 - 280))
                        );
                        font-style: normal;
                        font-weight: 400;
                        line-height: 16px; /* 133.333% */
                      }
                    }
                  }

                  > span {
                    display: flex;
                    padding: 0px 16px;
                    justify-content: center;
                    align-items: center;
                    // gap: 10px;
                    color: var(--Primary-Main, #1571de);

                    /* Text L/SemiBold */
                    // font-family: Poppins;
                    font-size: calc(
                      14px + (16 - 14) * ((100vw - 280px) / (1600 - 280))
                    );
                    font-style: normal;
                    font-weight: 600;
                    line-height: 140%; /* 22.4px */
                    border-radius: 13px;
                    background: #e1e4f9;
                  }
                }
              }
            }
          }
        }
      }

      .positionBox {
        display: flex;
        flex-direction: column;
        gap: 8px;
        .box {
          // display: flex;
          border-radius: 8px;
          border: 1px solid var(--Color-Neutal-Neutral-40, #e0e0e0);
          background: var(--Color-Neutal-Neutral-10, #fff);
          &.borderYellow {
            border: 1px solid var(--Alert-Main, #e9b62f);
          }

          .headerPosition {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 16px;
            padding: 16px;
            border-radius: 8px 8px 0px 0px;
            background-color: var(--Primary-Surface, #f5f7fe);
            border-bottom: 1px solid var(--Color-Neutal-Neutral-40, #e0e0e0);
            // background-color: transparent;
            transition: background-color 1s ease-in-out;
            &.yellow {
              background-color: #e9b62f1a;
            }
            > div:first-child {
              display: flex;
              align-items: center;
              gap: 8px;
              > span {
                color: var(--Primary-Main, #1571de);

                /* Heading 7/SemiBold */
                // font-family: Poppins;
                font-size: calc(
                  12px + (14 - 12) * ((100vw - 280px) / (1600 - 280))
                );
                font-style: normal;
                font-weight: 600;
                line-height: 150%; /* 30px */
                letter-spacing: 0.1px;
              }
            }

            > div:last-child {
              display: flex;
              align-items: center;
              gap: 8px;
              > span:first-child {
                color: var(
                  --Neutral-100,
                  var(--Color-Neutal-Neutral-19, #0a0a0a)
                );

                /* Text M/Reguler */
                // font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px; /* 142.857% */
              }

              > div:nth-child(2) {
                display: flex;
                align-items: center;
                gap: 4px;
                > div {
                  border-radius: 28px;
                  img {
                    width: 20px;
                    height: 20px;
                    border-radius: 28px;
                  }
                }

                > span {
                  color: var(
                    --Neutral-100,
                    var(--Color-Neutal-Neutral-19, #0a0a0a)
                  );

                  /* Text M/Medium */
                  // font-family: Poppins;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 20px; /* 142.857% */
                }
              }
            }
          }

          .jobAplicationBox {
            display: flex;
            flex-direction: column;
            gap: 8px;
            padding: 16px;

            > span {
              color: var(
                --Neutral-100,
                var(--Color-Neutal-Neutral-19, #0a0a0a)
              );

              /* Text M/Bold */
              // font-family: Poppins;
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: 20px; /* 142.857% */
            }

            .qualificationBox {
              display: flex;
              flex-direction: column;
              border-radius: 4px;
              border: 1px solid #ededed;
              background-color: transparent;
              transition: background-color 1s ease-in-out;
              &.borderYellow {
                border: 1px solid var(--Alert-Main, #e9b62f);
              }

              &.yellow {
                background-color: #e9b62f1a;
              }
              .tableHead {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 16px;
                border-radius: 4px 4px 0px 0px;
                background: var(--Color-Neutal-Neutral-20, #f5f5f5);
                padding: 12px 16px;
                > span {
                  width: 100%;

                  color: var(
                    --Neutral-100,
                    var(--Color-Neutal-Neutral-19, #0a0a0a)
                  );

                  /* Text M/Medium */
                  // font-family: Poppins;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 20px; /* 142.857% */
                }
              }

              .tableBody {
                display: flex;
                flex-direction: column;
                gap: 8px;
                padding: 12px 16px;
                > div {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  gap: 24px;
                  > span:first-child {
                    width: 100%;
                    color: var(
                      --Neutral-100,
                      var(--Color-Neutal-Neutral-19, #0a0a0a)
                    );

                    /* Text M/Reguler */
                    // font-family: Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px; /* 142.857% */
                  }
                  > span:last-child {
                    width: 100%;
                    color: var(
                      --Neutral-100,
                      var(--Color-Neutal-Neutral-19, #0a0a0a)
                    );

                    /* Text M/Medium */
                    // font-family: Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px; /* 142.857% */
                  }
                }
              }
            }
          }

          .cityBox {
            display: flex;
            flex-direction: column;
            gap: 8px;
            padding: 16px;
            > span {
              color: var(
                --Neutral-100,
                var(--Color-Neutal-Neutral-19, #0a0a0a)
              );

              /* Text M/Bold */
              // font-family: Poppins;
              font-size: calc(
                12px + (14 - 12) * ((100vw - 280px) / (1600 - 280))
              );
              font-style: normal;
              font-weight: 700;
              line-height: 20px; /* 142.857% */
            }
          }

          .positionList {
            display: flex;
            flex-direction: column;
            gap: 16px;
            border-radius: 8px;
            border: 1px solid var(--Color-Neutal-Neutral-30, #ededed);
            padding: 12px;
            > div {
              display: flex;
              flex-direction: column;
              gap: 12px;
              padding-bottom: 16px;
              border-bottom: 1px solid #e0e0e0;
              background-color: transparent;
              transition: background-color 1s ease-in-out;

              &.borderYellow {
                border: 1px solid var(--Alert-Main, #e9b62f);
                border-radius: 8px;
                padding: 10px;
                padding-bottom: 10px;
                border-bottom: 1px solid var(--Alert-Main, #e9b62f);
                // border-bottom: 0.5px solid var(--Alert-Main, #e9b62f);
                // border-top: 0.5px solid var(--Alert-Main, #e9b62f);
              }

              &.yellow {
                background-color: #e9b62f1a;
              }

              &.noBorder {
                border-bottom: none;
                padding-bottom: 0px;
              }
              .first {
                display: flex;
                align-items: center;
                gap: 8px;
                > span {
                  color: var(
                    --Neutral-100,
                    var(--Color-Neutal-Neutral-19, #0a0a0a)
                  );

                  /* Text L/SemiBold */
                  // font-family: Poppins;
                  font-size: calc(
                    12px + (14 - 12) * ((100vw - 280px) / (1600 - 280))
                  );
                  font-style: normal;
                  font-weight: 600;
                  line-height: 140%; /* 22.4px */
                }
              }

              .second {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 16px;
                > div {
                  width: 100%;
                  display: flex;
                  flex-direction: column;
                  gap: 8px;
                  background-color: transparent;
                  transition: background-color 1s ease-in-out;
                  &.borderYellow {
                    border: 1px solid var(--Alert-Main, #e9b62f);
                    border-radius: 6px;
                    padding: 8px 16px;
                  }

                  &.yellow {
                    background-color: #e9b62f1a;
                  }

                  > span:first-child {
                    color: var(
                      --Neutral-60,
                      var(--Color-Neutal-Neutral-60, #9e9e9e)
                    );

                    /* Caption 1/Reguler */
                    // font-family: Poppins;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 16px; /* 133.333% */
                  }

                  > span:last-child {
                    color: var(
                      --Neutral-100,
                      var(--Color-Neutal-Neutral-19, #0a0a0a)
                    );

                    /* Text M/Medium */
                    // font-family: Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px; /* 142.857% */
                  }
                }
              }

              .third {
                display: flex;
                // align-items: center;
                justify-content: space-between;
                gap: 8px;
                > div {
                  width: 100%;
                  display: flex;
                  flex-direction: column;
                  gap: 8px;
                  border-radius: 6px;
                  background-color: var(--Color-Neutal-Neutral-20, #f5f5f5);
                  display: flex;
                  padding: 16px;
                  transition: background-color 1s ease-in-out;
                  &.yellow {
                    background-color: #e9b62f1a;
                  }

                  &.borderYellow {
                    border: 1px solid var(--Alert-Main, #e9b62f);
                    // border-bottom: 0.5px solid var(--Alert-Main, #e9b62f);
                    // border-top: 0.5px solid var(--Alert-Main, #e9b62f);
                  }
                  > span:first-child {
                    color: var(
                      --Neutral-100,
                      var(--Color-Neutal-Neutral-19, #0a0a0a)
                    );

                    /* Text M/Bold */
                    // font-family: Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 20px; /* 142.857% */
                  }

                  > div {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                    > div {
                      display: flex;
                      align-items: center;
                      justify-content: space-between;
                      gap: 16px;
                      > span:first-child {
                        color: #000;

                        /* Text M/Reguler */
                        // font-family: Poppins;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 20px; /* 142.857% */
                      }

                      > span:last-child {
                        color: #000;
                        text-align: right;

                        /* Text M/Medium */
                        // font-family: Poppins;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 20px; /* 142.857% */
                        > span {
                          font-size: 12px;
                        }
                      }
                    }
                  }
                }

                .notFound {
                  > span {
                    width: 100%;
                    color: var(
                      --Neutral-50,
                      var(--Color-Neutal-Neutral-50, #c2c2c2)
                    );

                    /* Text S/Reguler */
                    // font-family: Poppins;
                    font-size: calc(
                      12px + (14 - 12) * ((100vw - 280px) / (1600 - 280))
                    );
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px; /* 153.846% */
                    // display: flex;
                    // align-items: center;
                    // justify-content: center;
                  }
                }
              }
            }
          }
        }
      }

      .notFound {
        display: flex;
        align-items: center;
        justify-content: center;
        > div {
          display: flex;
          flex-direction: column;
          gap: 8px;
          .img {
            img {
              width: 200px;
              height: 100%;
            }
          }
          > span {
            color: var(--Neutral-50, var(--Color-Neutal-Neutral-50, #c2c2c2));
            text-align: center;

            /* Text S/Reguler */
            // font-family: Poppins;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 153.846% */
          }
        }
      }

      .remarkBox {
        min-height: 90px;
        border-radius: 8px;
        border: 1px solid var(--Color-Neutal-Neutral-40, #e0e0e0);
        background: var(--Color-Neutal-Neutral-10, #fff);
        padding: 12px 4px 4px 12px;
        &.borderYellow {
          border: 1px solid var(--Alert-Main, #e9b62f);
        }

        &.yellow {
          background-color: #e9b62f1a;
        }
      }
    }
  }

  // .content {
  //   background-color: $NEUTRAL_10;
  //   padding: 16px;

  //   .areas {
  //     display: flex;
  //     flex-direction: column;
  //     gap: 16px;
  //   }

  //   .remark {
  //     padding-top: 16px;

  //     h4 {
  //       font-family: "Poppins";
  //       font-style: normal;
  //       font-weight: 600;
  //       font-size: 16px;
  //       line-height: 140%;
  //       color: $NEUTRAL_100;
  //       padding-bottom: 8px;
  //     }

  //     .box {
  //       padding: 12px;
  //       border: 1px solid $PLATINUM_SOLID;
  //       border-radius: 8px;
  //       min-height: 90px;

  //       p {
  //         font-family: "Poppins";
  //         font-style: normal;
  //         font-weight: 400;
  //         font-size: 14px;
  //         line-height: 20px;
  //         color: $NEUTRAL_100;
  //       }
  //     }
  //   }
  // }
}

@media screen and (max-width: 768px) {
  .container {
    .body {
      > div {
        .delegationBox {
          .delegations {
            .box {
              flex-direction: column;
            }
          }
        }

        .positionBox {
          .box {
            .headerPosition {
              flex-direction: column;
              align-items: flex-start;
            }
            .positionList {
              > div {
                .second {
                  flex-direction: column;
                }

                .third {
                  flex-direction: column;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .container {
    .body {
      > div {
        .positionBox {
          .box {
            .headerPosition {
              > div:last-child {
                flex-direction: column;
                align-items: flex-start;
              }
            }
            .positionList {
              > div {
                .second {
                  flex-direction: column;
                }

                .third {
                  > div {
                    > div {
                      gap: 16px;
                      > div {
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 8px;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .delegationBox {
          .delegations {
            .box {
              .users {
                > div {
                  .notFound {
                    .img {
                      img {
                        width: 100%;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
