@import "../../../../../theme/variables.scss";

.container {
  background-color: $NEUTRAL-10;
  width: 474px;
  max-width: 100vw;
  border-radius: 12px;
  padding: 24px;
  display: flex;
  flex-direction: column;



  >h3 {
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    padding-bottom: 2px;
  }

  >p {
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: $NEUTRAL_60;
    padding-bottom: 24px;
  }

  .selectPKS {
    display: flex;
    flex-direction: column;

    .autocompleteWrapper {
      padding-bottom: 32px;
    }

    >button {
      background-color: $PRIMARY_MAIN;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      color: $NEUTRAL-10;
      padding: 10px 12px;
      border: none;
      border-radius: 8px;
    }
  }

  .processWrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .processes {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .separator {
        width: 19px;
        height: 56px;
        border-right: 2px solid $NEUTRAL_30;
      }
    }

    .actions {
      display: flex;
      align-items: center;
      gap: 8px;
      width: 100%;

      >button {
        width: 100%;
        padding: 10px 12px;
        border-radius: 8px;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;

        &:first-child {
          background-color: $NEUTRAL-10;
          color: $PRIMARY_MAIN;
          border: 1px solid $PRIMARY_MAIN;

          &:disabled {
            border: 1px solid $NEUTRAL-40;
            background-color: $NEUTRAL_20;
            color: $NEUTRAL-40;
          }
        }

        &:nth-child(2) {
          background-color: $PRIMARY_MAIN;
          color: $NEUTRAL-10;
          border: 1px solid $PRIMARY_MAIN;

          &:disabled {
            background-color: $PRIMARY_DISABLED;
            border: 1px solid $PRIMARY_DISABLED;
          }
        }
      }
    }
  }




  // additional
  .step {
    display: flex;
    align-items: center;
    gap: 8px;

    .logo {
      width: 36px;
      aspect-ratio: 1;
      overflow: clip;
      display: grid;
      place-items: center;
      background-color: $NEUTRAL-10;
      flex-shrink: 0;

      .default,
      .succeeded,
      .failed {
        display: grid;
        place-items: center;
        width: 100%;
        aspect-ratio: 1;
        border-radius: 50%;
      }

      .default {
        background-color: $NEUTRAL_30;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        color: $NEUTRAL_60;
      }

      .succeeded {
        background-color: $PRIMARY_MAIN;
        color: $NEUTRAL-10;
      }

      .failed {
        background-color: $BS-DANGER-MAIN;
        color: $NEUTRAL-10;
      }

      >img {
        width: 36px;
        object-fit: contain;
        animation: rotation 1.5s infinite linear;
      }
    }

    .text {
      display: flex;
      flex-direction: column;

      >p {
        font-family: Poppins;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        color: $NEUTRAL_60;

        &.black {
          color: $NEUTRAL_100;
        }

        &.blue {
          color: $PRIMARY_MAIN;
        }
      }

      >span {
        font-family: Poppins;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        color: $BS-DANGER-MAIN;
      }
    }
  }

  .choosePksStep {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 4px;

    .chooserWrapper {
      display: flex;
      flex-direction: column;
      gap: 4px;

      >span {
        font-family: Poppins;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        color: $NEUTRAL_90;

        &.grey {
          color: $NEUTRAL_60
        }
      }
    }

    .saveButtonWrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      >button {
        height: 44px;
        padding: 8px 12px;
        background-color: $PRIMARY_MAIN;
        color: $NEUTRAL-10;
        border-radius: 8px;
        border: none;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;

        &:disabled {
          background-color: $PRIMARY_DISABLED;
        }
      }

    }
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}