@import "../../../../../theme/variables.scss";

.container {
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  background-color: $NEUTRAL_10;
  max-width: 100vw;
  width: 436px;
  filter: drop-shadow(0px 4px 9px rgba(0, 0, 0, 0.12));
  gap: 24px;
  max-height: 712px;
  position: relative;

  .content {
    display: flex;
    gap: 16px;
    align-items: center;
    background-color: rgba(255, 236, 232, 1);
    padding: 32px 16px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    .iconWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 54px;
      width: 54px;
      border-radius: 27px;
      background-color: rgba(212, 39, 1, 1);

      .icon {
        color: $NEUTRAL_10;
      }
    }

    .textWrapper {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .title {
        font-size: 20px;
        font-weight: 600;
        color: rgba(212, 39, 1, 1);
      }

      .text {
        font-size: 14px;
        font-weight: 400;
        color: $NEUTRAL_90;
      }
    }
  }

  .textareaWrapper {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }

  // .textareaWrapper {
  //   width: 100%;
  //   overflow: auto;
  //   padding-left: 16px;
  //   padding-right: 16px;
  //   input {
  //     width: 100%;
  //     border-radius: 8px;
  //     // height: 50px;
  //     padding: 12px 12px;
  //     border: 1px solid #e9ecef;
  //   }
  //   input:focus {
  //     outline: none !important;
  //     border: 1px solid $PRIMARY_MAIN;
  //   }

  //   input::placeholder {
  //     font-family: "Poppins";
  //     font-style: normal;
  //     font-weight: 400;
  //     font-size: 13px;
  //     line-height: 20px;
  //     color: $NEUTRAL_70;
  //     width: 100%;
  //     opacity: 1;
  //   }
  // }

  .buttonBox {
    display: flex;
    flex-direction: column;
    gap: 8px;
    > span {
      font-size: 12px;
      color: red;
    }

    .buttons {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
      position: sticky;
      bottom: 0;
      padding: 0 16px 16px 16px;
      width: 100%;

      .primaryButton {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $PRIMARY_MAIN;
        padding: 10px 0;
        flex: 1;
        border-radius: 8px;
        border: 1px solid $PRIMARY_MAIN;
        cursor: pointer;
        &.disabledButton {
          background-color: $PRIMARY_DISABLED;
          border: 1px solid $PRIMARY_DISABLED;
        }

        .text {
          font-size: 14px;
          font-weight: 500;
          color: $NEUTRAL_10;
        }
      }

      .primaryButton:hover {
        background-color: $PRIMARY_HOVER;

        &:disabled {
          background-color: $PRIMARY_DISABLED;
          border-color: $PRIMARY_DISABLED;
        }
      }

      .secondaryButton {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $NEUTRAL_10;
        padding: 10px 0;
        border-radius: 8px;
        border: 1px solid $NEUTRAL_60;
        cursor: pointer;

        .text {
          font-size: 14px;
          font-weight: 500;
          color: $NEUTRAL_90;
        }
      }

      .secondaryButton:hover {
        background-color: $NEUTRAL_30;
      }
    }
  }
}
