.emptyData {
  display: flex;
  align-items: center;
  justify-content: center;
  //   padding: 12px 24px 24px 24px;
}

.emptyBox {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  .images {
    // width: 338px;
    // height: 281px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 330px;
    height: 270px;
    img {
      width: 100%;
      height: 100%;
    }
  }

  span {
    color: #333;
    text-align: center;

    /* Heading 7/Bold */
    // font-family: Poppins;
    //   font-family: Poppins;
    font-size: calc(16px + (20 - 16) * ((100vw - 280px) / (1600 - 280)));
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 30px */
    letter-spacing: 0.1px;
  }
}

@media screen and (max-width: 570px) {
  .emptyBox {
    .images {
      width: 100%;
      height: 100%;
    }
  }
}
