@import "../../../../theme/variables.scss";

.container {
  width: 100%;
  height: calc(100% - 70px);
  background-color: $NEUTRAL_BACKGROUND;
  z-index: 0;
  overflow-y: scroll;

  .loadingWrapper {
    height: calc(100vh - 220px);
    display: grid;
    place-items: center;

  }



  .navControlWrapper {
    position: sticky;
    top: 0;
    z-index: 1;
  }


  .contentWrapper {
    z-index: 0;
    // height: calc(100vh - 150px);
    overflow: auto;
  }
}

@media screen and (max-width: 768px) {
  

  .container {

    // .contentWrapper {
    //   height: calc(100vh - 120px);
    // }

    .loadingWrapper {

      >div {
        width: 150px;
      }
    }
  }
}