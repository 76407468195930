@import "../../../../theme/variables.scss";

.container {
  background-color: $NEUTRAL-10;
  padding: 24px;
  border-radius: 8px;
  max-height: 85vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 536px;
  max-width: 100vw;

  .header {
    display: flex;
    justify-content: space-between;

    .cancelButton {
      width: initial;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: transparent;
      padding: 8px;
      border-radius: 8px;
      border: 1px solid $DANGER_MAIN;
      cursor: pointer;

      color: $DANGER_MAIN;
      font-weight: 400;
      font-size: 14px;

      &:disabled {
        border-color: $PRIMARY_DISABLED;
      }
    }
  }

  .errorText {
    color: var(--Helper, #f5610d);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }

  .viewDetail {
    background-color: transparent;
    border: none;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 142.857%;
  }

  > h3 {
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
  }

  > form {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .reason {
      display: flex;
      flex-direction: column;
      gap: 8px;

      > p {
        font-family: Poppins;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }

    .attachment {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .preview,
      .box {
        display: flex;
        flex-direction: column;
        gap: 8px;
        .errorFile {
          font-size: 12px;
          color: red;
        }

        .previewCard {
          display: flex;
          align-items: center;
          gap: 8px;
          padding: 10px;
          border: 1px solid $NEUTRAL-40;
          border-radius: 8px;

          > img {
            width: 52px;
            aspect-ratio: 1;
            object-fit: cover;
            border-radius: 8px;
          }

          .desc {
            display: flex;
            flex-direction: column;
            width: 100px;
            flex-grow: 1;

            > span {
              &:first-child {
                font-family: Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              &:last-child {
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                color: $NEUTRAL_60;
              }
            }
          }

          .fileActions {
            display: flex;
            align-items: center;
            gap: 8px;

            > button {
              background-color: transparent;
              border: none;
              display: grid;
              place-items: center;

              &:first-child {
                color: $PRIMARY_MAIN;
              }

              &:last-child {
                color: $DANGER_MAIN;
              }

              &:disabled {
                color: $NEUTRAL-40 !important;
              }
            }
          }
        }
      }
    }

    .actions {
      display: flex;
      align-items: center;
      gap: 16px;
      justify-content: flex-end;

      > button {
        padding: 12px;
        border-radius: 8px;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 142.857%;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;

        &.cancel {
          background-color: $NEUTRAL-10;
          border: 1px solid $PRIMARY_MAIN;
          color: $PRIMARY_MAIN;

          &:disabled {
            border: 1px solid $NEUTRAL-50;
            color: $NEUTRAL-50;
          }
        }

        &.submit {
          background-color: $PRIMARY_MAIN;
          border: 1px solid $PRIMARY_MAIN;
          color: $NEUTRAL-10;
          width: 84px;

          &:disabled {
            background-color: $PRIMARY_DISABLED;
            border-color: $PRIMARY_DISABLED;
          }
        }
      }
    }
  }
}

.imgContainer {
  max-height: 85vh;
  max-width: 90vw;
  position: relative;
  background-color: $NEUTRAL-10;
  overflow: clip;
  display: grid;
  place-items: center;

  > button {
    display: grid;
    place-items: center;
    background-color: $NEUTRAL-100;
    color: $NEUTRAL-10;
    border-radius: 50%;
    padding: 4px;
    aspect-ratio: 1;
    position: absolute;
    top: 8px;
    right: 8px;
    border: none;
  }

  > img {
    max-height: 85vh;
    max-width: 90vw;
    object-fit: contain;
  }
}

@media screen and (max-width: 768px) {
  .imgContainer {
    max-width: 100vw !important;
  }
}
