@import "../../../theme/variables.scss";

.sync {
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .sTop {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    gap: 20px;

    > div {
      background-color: $NEUTRAL-10;
      border-radius: 8px;
      padding: 10px;
      display: flex;
      flex-direction: column;
      gap: 4px;

      > div {
        width: 36px;
        aspect-ratio: 1;
        border-radius: 50%;
        display: grid;
        place-items: center;
      }

      > span {
        @include font-size(32, 24, 24px);
        font-weight: 600;
        font-family: "Poppins", sans-serif;
      }

      > p {
        font-family: "Poppins", sans-serif;
        font-size: 12px;
        color: $NEUTRAL-70;
      }
    }
  }

  .sBottom {
    display: flex;
    align-items: center;
    gap: 20px;

    > * {
      flex-grow: 0;
      &:last-child {
        flex-grow: 1 !important;
        width: 100%;
      }
    }
  }
}

.dcContainer {
  background-color: $NEUTRAL-10;
  padding: 8px 10px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 242px;
  height: 295px;

  > span {
    font-size: 14px;
    font-weight: 700;
  }

  .legends {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;

    > div {
      display: flex;
      align-items: center;
      gap: 4px;

      > div {
        width: 8px;
        aspect-ratio: 1;
        border-radius: 50%;
      }

      > span {
        font-size: 13px;
      }
    }
  }
}

.gcContainer {
  background-color: $NEUTRAL-10;
  padding: 8px 10px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  // gap: 20px;
  height: 295px;

  .gcHeader {
    display: flex;
    align-items: center;
    gap: 16px;

    > span {
      font-size: 14px;
      font-weight: 700;
    }

    > div {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-grow: 1;
      gap: 12px;

      .selectWrapper {
        border: 1px solid #e6eaec;
        padding: 2px 12px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        gap: 10px;
        position: relative;

        > span {
          font-size: 13px;
          color: #6f6f70;
        }

        .options {
          position: absolute;
          top: calc(100% + 12px);
          left: 0;
          width: 100%;
          background-color: $NEUTRAL_10;
          border-radius: 8px;
          overflow: hidden;
          box-shadow: 0px 0px 1px rgba(9, 30, 66, 0.31),
            0px 3px 5px rgba(9, 30, 66, 0.2);
          z-index: 1;
          padding-block: 4px;
          max-height: 200px;
          overflow-y: auto;

          > div {
            padding: 8px 12px;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: $NEUTRAL_90;
            display: flex;
            align-items: center;
            gap: 8px;
            cursor: pointer;

            &:hover {
              background-color: $PRIMARY_SURFACE_HOVER;
            }

            > img {
              width: 20px;
              aspect-ratio: 1;
              border-radius: 50%;
              object-fit: cover;
            }
          }
        }
      }
    }
  }

  > p {
    display: flex;
    align-items: flex-end;
    padding-bottom: 0;
    @include font-size(32, 24, 24px);
    color: $PRIMARY_MAIN;
    font-weight: 600;
    gap: 4px;

    > span {
      font-size: 12px;
      font-weight: 400;
      color: $NEUTRAL-50;
      padding-bottom: 12px;
    }
  }
}

@media screen and (max-width: 1540px) {
  .sync {
    .sBottom {
      > * {
        &:last-child {
          flex-grow: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 1040px) {
  .sync {
    .sBottom {
      flex-wrap: wrap !important;

      > * {
        &:last-child {
          flex-grow: 1;
        }
      }
    }
  }
}
