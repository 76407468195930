@import "../../../theme/variables.scss";

.wrapper {
  align-items: center;
  gap: 16px;

  .errorText {
    color: var(--Helper, #f5610d);
    /* Caption 1/Reguler */
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 133.333% */
  }

  .container {
    // height: 100%;
    width: 100%;
    display: flex;

    .error {
      border-color: #f5610d !important;
    }

    .phoneCodeWrapper {
      // background-color: $NEUTRAL_40;
      border-radius: 8px 0 0 8px;
      width: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 13px;
      font-weight: 400;
      color: $NEUTRAL_60;
      padding: 10px 4px 12px 12px;
    }

    .phoneContent {
      width: 100%;
      display: flex;
      border-radius: 8px 8px 8px 8px;
      border-width: 1px 1px 1px 1px;
      border-style: solid;
      border-color: $NEUTRAL_40;
      transition: 0.2s;

      &:focus-within {
        outline: none !important;
        border-color: $PRIMARY_MAIN;
        border-width: 1px 1px 1px 1px;
        border-style: solid;
      }

      .phoneCodeWrapper {
        width: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        font-weight: 400;
        color: $NEUTRAL_60;
        padding: 10px 4px 12px 12px;
      }

      .input {
        width: 100%;
        border-radius: 0 8px 8px 0;
        padding: 12px 12px 12px 0px;
        transition: 0.2s;
        border: 0px;
        font-size: 13px;
      }

      .input:focus {
        outline: none !important;
      }

      .input[type="number"]::-webkit-outer-spin-button,
      .input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      .buttonVerification {
        margin-left: auto;
        padding: 4px;
        width: 128px;
        transition: 0.2s;

        &:disabled {
          background-color: $NEUTRAL_30;
        }

        .verifiedWrap {
          display: flex;
          font-size: 14px;
          border-radius: 8px;
          padding: 8px;
          gap: 4px;
          background-color: $SUCCESS_SURFACE;
          color: $SUCCESS_MAIN;
        }
      }
    }

    .phoneContentDisabled {
      background-color: $NEUTRAL_30;
    }

    .input:disabled {
      outline: none !important;
      background-color: $NEUTRAL_30;
      color: $NEUTRAL_60;
    }
  }
}
