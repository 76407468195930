@import "../../../../../../theme/variables.scss";

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  flex: 1;

  .icon {
    color: $NEUTRAL_70;
  }

  .textWrapper {
    display: flex;
    flex-direction: column;
    gap: 2px;

    .title {
      font-size: 12px;
      font-weight: 600;
      color: $NEUTRAL_60;
    }

    .value {
      font-size: 14px;
      font-weight: 500;
      color: $NEUTRAL_100;
    }
  }
}
