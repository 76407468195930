@import "../../../theme/variables.scss";

.container {
  width: 100%;
  height: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $NEUTRAL_LIGHTER;
  border-radius: 8px;
  position: relative;
}

.errorText {
  color: var(--Helper, #f5610d);
  /* Caption 1/Reguler */
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  /* 133.333% */
}

.input {
  border-width: 0;
  height: 100%;
  padding: 16px;
  width: 100%;
  border-radius: 8px;
  background-color: $NEUTRAL_10;
  border: 1px solid $NEUTRAL_40;
  transition: 0.2s;

  &.error {
    border-color: #f5610d;
  }
}

.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input:focus {
  outline: none !important;

  &:not(.error) {
    border: 1px solid $PRIMARY_MAIN;
  }
}

.arrows {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  padding-right: 16px;

  div {
    cursor: pointer;
  }
}
