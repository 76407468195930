@import "../../../theme/variables.scss";

.container {
  height: 80vh;
  background-color: #fafafa;
  border-radius: 8px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  position: relative;

  .showOnMobile {
    display: none;
  }

  .header {
    position: sticky;
    top: 0;
    background-color: #fafafa;
    z-index: 1;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-bottom: 1px solid $NEUTRAL_40;

    .mainHeader {
      display: flex;
      align-items: center;
      gap: 16px;
      position: relative;
      .searchbar {
        // width: 360px;
        height: 45px;
      }

      > * {
        white-space: nowrap;

        &:nth-child(2) {
          flex-grow: 1;
        }
      }

      h4 {
        color: $NEUTRAL_DARKEST;
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        letter-spacing: 0.1px;
      }

      button {
        background-color: transparent;
        border: none;
        color: $PRIMARY_MAIN;
        text-align: right;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        /* 142.857% */
      }
    }

    .controllerWrapper {
      display: flex;
      align-items: center;
      gap: 8px;
      width: 100%;
      overflow: auto;
      scrollbar-width: none; /* Sembunyikan scrollbar di Firefox */
    }
    .controllerWrapper::-webkit-scrollbar {
      // scrollbar-width: thin;
      // width: 1px;
    }
  }

  .filler {
    background-color: #fafafa;
    height: 2px;
    width: 100%;
    border-bottom: 1px solid $NEUTRAL_40;
    // z-index: 1;
  }

  .content {
    flex-grow: 1;
    width: 100%;

    table {
      width: 100%;
      border-collapse: collapse;
      background-color: #fafafa;

      thead {
        position: sticky;
        top: 0;
        background-color: #fafafa;

        tr {
          background-color: #fafafa;

          th {
            padding: 16px;
            color: $NEUTRAL_DARKEST;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;

            .borderBottom {
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 1px;
              background-color: $NEUTRAL_40;
            }
          }
        }
      }

      tbody {
        tr {
          td {
            padding: 12px 16px;
            border-bottom: 1px solid $NEUTRAL_40;
            color: $NEUTRAL_DARKEST;
            font-family: Poppins;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
          }
        }
      }
    }
  }

  .paginationWrapper {
    position: sticky;
    bottom: 0;
    border-top: 1px solid $NEUTRAL_40;
  }
}

@media screen and (max-width: 480px) {
  .hideOnMobile,
  .leftSeparator,
  .rightSeparator {
    display: none !important;
  }

  .showOnMobile {
    display: table-cell !important;
  }
}
