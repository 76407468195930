@import "../../../../../../theme/variables.scss";

.container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;

    .title {
        font-size: 13px;
        font-weight: 600;
        color: $NEUTRAL_100;
    }

    .dataWrapper {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(272px, 1fr));
        row-gap: 20px;
        column-gap: 15px;

        .dataCard {
            display: flex;
            justify-content: space-between;
            gap: 8px;
            padding: 8px;
            border: 1px solid $BS_NEUTRAL_30;
            border-radius: 8px;
            cursor: pointer;

            .iconWrapper {
                display: flex;
                align-items: center;
            }
        }
        .dataCardSelected {
            border: 1px solid $BS_PRIMARY_MAIN;
            border-radius: 8px;
        }
    }
}