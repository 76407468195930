@import "../../../theme/variables.scss";

.container {
  position: relative;
  width: 100%;
  background-color: $NEUTRAL_LIGHTEST;
  border: 1px solid $NEUTRAL_40;
  border-radius: 8px;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  transition: 0.2s;
  cursor: pointer;
  align-items: center;

  &.error {
    border-color: #F5610D;
  }
}

.errorText {
  color: var(--Helper, #F5610D);
  /* Caption 1/Reguler */
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  /* 133.333% */
}

.containerColorDisabled {
  background-color: $NEUTRAL_30;
}

.activeContainer {
  border-color: $PRIMARY_MAIN;
  transition: 0.2s;
}

.options {
  position: absolute;
  width: 100%;
  margin-top: 5px;
  border-radius: 0 0 8px 8px;
  background-color: $NEUTRAL_10;
  transition: 0.2s;
  box-shadow: 0px 0px 1px rgba(9, 30, 66, 0.31),
    0px 3px 5px rgba(9, 30, 66, 0.2);
  z-index: 2;
  max-height: 300px;
  overflow: auto;
}

.option {
  padding: 10px;
  font-size: 13px;
  font-weight: 400;
  color: $NEUTRAL_100;
  user-select: none;
}

.optionWrapper:hover {
  background-color: $NEUTRAL_20;
  cursor: pointer;
}

.borderBottom {
  margin: 0 10px;
  border-bottom: 1px solid $NEUTRAL_50;
}

.valueText {
  color: $NEUTRAL_100;
  font-size: 13px;
  font-weight: 400;
  user-select: none;
}

.placeholderText {
  color: $NEUTRAL_60;
  font-size: 13px;
  font-weight: 400;
  user-select: none;
}

.radioWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
}

.radio {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 1px solid $NEUTRAL_50;
}

.selectedRadio {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 5px solid $PRIMARY_MAIN;
}
