@import "../../../../../theme/variables.scss";

.container {
  width: 100%;
  min-height: calc(100% - 70px);
  background-color: $NEUTRAL_BACKGROUND;
  z-index: 0;

  .loadingWrapper {
    height: calc(100vh - 60px);
    display: grid;
    place-items: center;
  }

  .controllerWrapper {
    position: sticky;
    top: 70px;
    z-index: 1;

  }

  .contentWrapper {
    z-index: 0;
  }
}

@media screen and (max-width: 768px) {}