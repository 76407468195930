@import "../../../../theme/variables.scss";


.container {
  background-color: $NEUTRAL_10;
  border-radius: 8px;
  width: 733px;
  // max-height: 80vh;
  max-width: 90vw;
  overflow: hidden;

  .header {
    display: flex;
    align-items: center;
    gap: 24px;
    padding: 18px 20px;
    box-shadow: 0px 1px 5px 2px rgba(0, 0, 0, 0.05);
    position: sticky;
    top: 0;
    background-color: $NEUTRAL_10;

    h3 {
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      flex-grow: 1;
    }

    button {
      display: flex;
      align-items: center;
      gap: 4px;
      border: none;

      &:nth-child(2) {
        padding: 8px 16px;
        border-radius: 8px;
        background-color: $PRIMARY_MAIN;
        color: $NEUTRAL_10;

        &:disabled {
          background-color: $PRIMARY_DISABLED;
        }
      }

      &:last-child {
        background-color: transparent;
      }
    }
  }

  .content {
    padding: 18px 29px;
    height: 75vh;
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow: auto;

    >div {
      height: 100%;
      display: grid;
      place-items: center;
      color: $NEUTRAL_60;
    }

  }
}

.v2Container {
  background-color: $NEUTRAL_10;
  width: 726px;
  max-height: 85vh;
  border-radius: 8px;
  overflow: auto;

  >* {
    padding: 24px;

    &.header {
      padding-bottom: 16px;
      border-bottom: 1px solid $NEUTRAL_40;
      display: flex;
      align-items: center;
      position: sticky;
      top: 0;
      background-color: $NEUTRAL_10;
  

      >h3 {
        flex-grow: 1;
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
        letter-spacing: 0.12px;
      }

      >button {
        display: grid;
        place-items: center;
        background-color: transparent;
        border: none;
      }
    }
  }
}

@media screen and (max-width:768px) {
  .container {
    width: 100vw !important;
    max-width: 100vw !important;
    border-radius: 20px 20px 0px 0px !important;

    .hideOnMobile {
      display: none;
    }
  }
}