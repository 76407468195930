@import "../../../../../../../theme/variables.scss";

.background {
  width: 100vw;
  height: 100vh;
  background-color: $NEUTRAL_OVERLAY;
  z-index: 3;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-inline: 18px;

  .container {
    border-radius: 8px;
    background-color: $NEUTRAL_LIGHTEST;
    display: flex;
    flex-direction: column;
    padding: 16px;
    position: relative;
    width: 370px;

    .header {
      padding-bottom: 16px;

      h3 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 140%;
        display: flex;
        align-items: center;
        text-align: center;
        color: $PRIMARY_MAIN;
      }
    }

    .content {
      width: 100%;
      height: 100%;

      .formWrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;

        .boxForm {
          display: flex;
          flex-direction: column;
          gap: 4px;

          label {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            color: $NEUTRAL_70;
          }

          input[type="text"] {
            height: 44px;
            background: $NEUTRAL_LIGHTEST;
            border: 1px solid $NEUTRAL_40;
            border-radius: 8px;
            padding-inline: 8px;

            &:focus {
              outline: none !important;
              border-color: $PRIMARY_MAIN;
              border-width: 1px;
              border-style: solid;
            }
          }

          .phoneNumberWrapper {
            display: flex;
            align-items: center;
            height: 44px;

            > div {
              height: 100%;
              width: 49px;
              display: grid;
              place-items: center;
              background: $NEUTRAL_20;
              border: 1px solid $NEUTRAL_40;
              border-radius: 8px 0px 0px 8px;
              font-family: "Poppins";
              font-style: normal;
              font-weight: 400;
              font-size: 13px;
              line-height: 20px;
              text-align: center;
              color: $NEUTRAL_60;
            }

            > input {
              height: 100%;
              background: $NEUTRAL_LIGHTEST;
              border-width: 1px 1px 1px 0px;
              border-style: solid;
              border-color: $NEUTRAL_40;
              border-radius: 0px 8px 8px 0px;
              flex-grow: 1;
              padding-inline: 8px;

              &:focus {
                outline: none !important;
                border-color: $PRIMARY_MAIN;
                border-width: 1px;
                border-style: solid;
              }
            }
          }
        }

        .alertWrapper {
          span {
            font-size: 13px;
            color: $DANGER_MAIN;

            &.Normal {
              opacity: 0;
            }

            &.alert {
              opacity: 1;
            }
          }
        }

        .buttonWrapper {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 8px;
          height: 36px;

          button {
            height: 100%;
            border-radius: 8px;
            padding-inline: 12px;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            cursor: pointer !important;

            &:first-child {
              border: 1px solid $PRIMARY_MAIN;
              color: $PRIMARY_MAIN;
              background: $NEUTRAL_LIGHTEST;
            }

            &:last-child {
              border: none;
              background: $PRIMARY_MAIN;
              color: $NEUTRAL_LIGHTEST;

              &:disabled {
                background-color: $PRIMARY_DISABLED;
              }
            }
          }
        }
      }
    }
  }
}
