@import "../../../../../theme/variables.scss";

.container {
  padding: 24px 30px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    background-color: $NEUTRAL_OVERLAY;
    opacity: 0;
    z-index: 3;

    &.Visible {
      right: 0;
      bottom: 0;
      opacity: 1;
      transition: opacity 0.4s;
    }
  }
}

@media screen and (max-width:768px) {

  .container {
    padding: 16px 18px;
  }
}