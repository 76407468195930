@import "../../../../../theme/variables.scss";

.container {
  height: 100%;
  scrollbar-gutter: auto;
  // position: relative;
  overflow: auto;

  .modalText {
    width: 346px;
    display: block;
  }

  .submitHeader {
    position: sticky;
    top: 0;
    // width: calc(100% - 281px);
    background-color: #ECEFFF;
    padding: 8px 30px;
    display: flex;
    align-items: center;
    gap: 8px;
    z-index: 1;

    &.error {
      h3,
      p {
        color: $DANGER_MAIN !important;
      }
    }

    .left {
      display: flex;
      align-items: center;
      gap: 24px;
      flex-grow: 1;

      img {
        width: 32px;
        aspect-ratio: 1;
        object-fit: contain;
      }

      .middle {
        flex-grow: 1;

        h3 {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 150%;
          letter-spacing: 0.005em;
          color: $PRIMARY_MAIN;
          padding-bottom: 4px;
        }

        p {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: $NEUTRAL_DARKEST;
        }
      }
    }

    button {
      padding: 8px 16px;
      background-color: $PRIMARY_MAIN;
      border-radius: 8px;
      border: none;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $NEUTRAL_10;
      transition: all 0.3s;

      &:disabled {
        background-color: $PRIMARY_DISABLED;
      }
    }
  }

  .contentWrapper {
    scrollbar-gutter: auto;
    padding: 24px 30px;
    z-index: 0;
  }
}

@media screen and (max-width: 768px) {
  .container {
    .modalText {
      width: 250px;
      max-width: 100%;
      display: block;
    }
    .submitHeader {
      padding: 8px 18px;
      flex-direction: column;
      width: 100%;

      button {
        width: 100%;
      }
    }

    .contentWrapper {
      padding: 16px 18px;
    }
  }
}
