@import "../../../theme/variables.scss";

.container {
  height: 100%;
  width: 100%;
}

.title {
  padding-bottom: 4px;
  > div {
    font-size: 13px;
    font-weight: 600;
    font-family: "Poppins";
  }
  display: flex;
  align-items: flex-start;
  gap: 2px;

  .redStar {
    color: $DANGER_MAIN;
    font-weight: 400;
    font-size: 11px;
  }
}
