@import "../../../../../theme/variables.scss";

.container {
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  background-color: $NEUTRAL_10;
  max-width: 100vw;
  width: 436px;
  filter: drop-shadow(0px 4px 9px rgba(0, 0, 0, 0.12));
  gap: 24px;
  max-height: 712px;
  position: relative;

  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 32px;
    background-color: rgba(235, 255, 232, 1);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    .iconWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 86px;
      width: 86px;
      border-radius: 43px;
      background-color: rgba(34, 151, 15, 1);

      .icon {
        color: $NEUTRAL_10;
      }
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    padding-left: 16px;
    padding-right: 16px;

    .textWrapper {
      display: flex;
      flex-direction: column;
      gap: 8px;
      text-align: center;

      .title {
        font-size: 20px;
        font-weight: 600;
        color: rgba(34, 151, 15, 1);
      }

      .text {
        font-size: 14px;
        font-weight: 400;
        color: $NEUTRAL_60;

        .span {
          font-size: 14px;
          font-weight: 500;
          color: $NEUTRAL_100;
        }
      }
    }
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 0 16px 16px 16px;

    .primaryButton {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $PRIMARY_MAIN;
      padding: 10px 10px;
      border-radius: 8px;
      border: 1px solid $PRIMARY_MAIN;
      cursor: pointer;

      .text {
        font-size: 14px;
        font-weight: 500;
        color: $NEUTRAL_10;
      }
    }

    .primaryButton:hover {
      background-color: $PRIMARY_HOVER;
    }

    .secondaryButton {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $NEUTRAL_10;
      padding: 10px 10px;
      border-radius: 8px;
      border: 1px solid $PRIMARY_MAIN;
      cursor: pointer;

      .text {
        font-size: 14px;
        font-weight: 500;
        color: $PRIMARY_MAIN;
      }
    }

    .secondaryButton:hover {
      background-color: $NEUTRAL_30;
    }
  }
}
