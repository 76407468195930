@import "../../../../../../../../theme/variables.scss";

.container {
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .remarkWrapper {
    padding-top: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .remarkTitleText {
      font-size: 16px;
      font-weight: 600;
      color: $NEUTRAL_100;
    }

    .remark {
      background-color: $NEUTRAL_20;
      border-radius: 4px;
      padding: 8px 12px;
      display: flex;
      flex-direction: column;
      gap: 4px;

      .descriptionTitleText {
        font-size: 12px;
        font-weight: 400;
        color: $NEUTRAL_60;
      }

      .descriptionContentText {
        font-size: 14px;
        font-weight: 500;
        color: $NEUTRAL_100;
      }
    }
  }
}
