@import "../../../theme/variables.scss";

.container {
  display: flex;
  flex-direction: column;

  .field {
    background-color: $NEUTRAL-10;
    height: 44px;
    border: 1px solid $NEUTRAL-40;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding-inline: 12px;
    position: relative;
    cursor: pointer;

    &.disabled {
      cursor: auto !important;
      background-color: $NEUTRAL-40;
    }


    >span {
      font-family: Poppins;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 153.846%;
      flex-grow: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
  
      &.placeholder {
        color: $NEUTRAL-60;

      }

    }

    .options {
      position: absolute;
      top: calc(100% + 8px);
      left: 0px;
      width: 100%;
      border-radius: 0px 0px 8px 8px;
      background: $NEUTRAL-10;
      box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.25);
      padding: 8px 12px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      z-index: 1;

      .option {
        border-bottom: 1px solid $NEUTRAL-40;
        display: flex;
        align-items: center;
        gap: 6px;
        padding-bottom: 10px;

        >span {
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 142.857%;
          cursor: pointer;
        }
      }
    }
  }
}