@import "../../../theme/variables.scss";

.outer {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: $NEUTRAL_30;
  display: grid;
  place-items: center;
  transition: background-color 0.3s;
  cursor: pointer;

  .inner {
    background-color: $NEUTRAL_10;
    width: 20px;
    aspect-ratio: 1;
    border-radius: 50%;
  }

  &.checked {
    background-color: $PRIMARY_MAIN;

    .inner {
      width: 12px;
    }
  }
}
