@import "../../../theme/variables.scss";

.container {
  display: flex;
  align-items: center;
  gap: 13px;

  span {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: $NEUTRAL_DARKEST;
  }
}