@import "../../../../../theme/variables.scss";

.container {
  width: 100%;

  .topWrapper {
    position: sticky;
    top: 70px;
    z-index: 2;
    border-bottom: 1px solid $NEUTRAL_40;
  }

  .breadcrumbWrapper {
    width: 100%;
    padding: 30px;
    padding-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 24px;
    background-color: $NEUTRAL_LIGHTER;

    > span {
      background-color: $NEUTRAL_LIGHTEST;
      width: 40px;
      height: 40px;
      display: grid;
      place-items: center;
      border-radius: 50%;
      cursor: pointer;
    }

    .breadcrumbText {
      font-weight: 500;
      font-size: 16px !important;
      line-height: 20px;
      color: $NEUTRAL_60;
      cursor: pointer;
    }

    .breadcrumbText2 {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: $NEUTRAL_100;
    }
  }

  .loadingWrapper {
    height: calc(100vh - 220px);
    display: grid;
    place-items: center;
  }

  .contentWrapper {
    width: 100%;
    // min-height: calc(100vh - 220px);
    z-index: 0!important;
  }
}

@media screen and (max-width: 768px) {
  .container {
    .breadcrumbWrapper {
      padding: 18px;
      padding-bottom: 8px;
      padding-top: 10px;
    }
  }

  .controlHeader {
    width: 100%;
    padding-inline: 18px !important;
    flex-direction: column;
    height: 100% !important;
    gap: 8px;

    .buttonWrapper {
      // display: none;
      width: 100% !important;
    }
  }
}
