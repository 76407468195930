@import "../../../theme/variables.scss";

.autocompleteFieldWrapper {
  display: flex;
  flex-direction: column;
  // gap: 4px;

}
.errorText {
  color: var(--Helper, #F5610D);
  /* Caption 1/Reguler */
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  /* 133.333% */
}
