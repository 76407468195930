.container {
  height: 100vh;
  width: 100vw;
  background-color: #00000080;
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
}

.bodyContainer {
  width: 28%;
  padding: 20px;
  //   padding: 90px;
  //   padding: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  //   gap: 8px;
  .closeBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // width: 370px;
    width: 130%;
    .closeBox {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px;
      border-radius: 50px;
      background: var(--neutral-20, #f5f5f5);
    }
  }
  .imagesBox {
    width: 100%;
    height: 100%;
    // width: 380px;
    // height: 670px;
    // height: 700px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

@media only screen and (max-width: 1000px) {
  .bodyContainer {
    width: 50%;
    padding: 20px;
  }
}

@media only screen and (max-width: 540px) {
  .bodyContainer {
    .closeBar {
      .closeBox {
        padding: 4px;
      }
    }
    width: 70%;
    padding: 20px;
  }
}

@media only screen and (max-width: 390px) {
  .bodyContainer {
    width: 90%;
    padding: 20px;
  }
}
