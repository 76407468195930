@import "../../../../theme/variables.scss";

.container {
  width: 442px;
  max-width: 100vw;
  background-color: $NEUTRAL_10;
  border-radius: 8px;
  padding: 32px;
  max-height: 85vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 24px;

  >* {
    >h3 {
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
      letter-spacing: 0.12px;
      text-align: center;
    }

    >p {
      font-family: Poppins;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: #878787;
      text-align: center;
    }

    &.rejectContent {
      display: flex;
      flex-direction: column;
      align-items: center;

      >* {
        width: 100%;
      }

      >h3 {
        padding-bottom: 8px;
      }

      >p {
        padding-bottom: 16px;
      }
    }

    &.acceptContent {
      display: flex;
      flex-direction: column;
      align-items: center;

      >* {
        width: 100%;
      }

      >img {
        width: 120px;
        aspect-ratio: 1;
        object-fit: contain;
      }

      >h3 {
        padding-top: 16px;
        padding-bottom: 4px;
      }
    }

    &.actions {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 16px;

      >* {
        padding: 12px;
        border-radius: 8px;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;

        &:first-child {
          background-color: $NEUTRAL_10;
          color: $NEUTRAL_100;
          border: 1px solid $NEUTRAL_100;
        }

        &:last-child {
          background-color: $PRIMARY_MAIN;
          border: 1px solid $PRIMARY_MAIN;
          color: $NEUTRAL_10;

          &:disabled {
            background-color: $PRIMARY_DISABLED;
            border-color: $PRIMARY_DISABLED;
          }
        }

      }
    }
  }


}

.oldContainer {
  background-color: $NEUTRAL_10;
  padding: 32px;
  border-radius: 8px;
  width: 354px;
  display: flex;
  flex-direction: column;
  align-items: center;

  >h3 {
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    /* 33.6px */
    letter-spacing: 0.12px;
    padding-bottom: 8px;
  }

  >p {
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #878787;
    text-align: center;
    padding-bottom: 16px;

    >span {
      font-style: italic;

      &.accept {
        color: $PRIMARY_MAIN;
      }

      &.reject {
        color: $DANGER_MAIN;
      }

    }

  }

  .profile {
    width: 100%;
    padding: 8px 12px;
    border-radius: 8px;
    background-color: $NEUTRAL_20;
    margin-bottom: 24px;
  }

  .actions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    width: 100%;

    >button {
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      padding: 12px;
      border-radius: 8px;

      >span {
        font-style: italic;
      }

      &:first-child {
        background-color: $NEUTRAL_10;
        color: $NEUTRAL_100;
        border: 1px solid $NEUTRAL_100;
      }

      &:last-child {
        background-color: $PRIMARY_MAIN;
        color: $NEUTRAL_10;
        border: 1px solid $PRIMARY_MAIN;

        &:disabled {
          background-color: $PRIMARY_DISABLED;
          border-color: $PRIMARY_DISABLED;
        }
      }

    }
  }


}