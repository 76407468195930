@import "../../../theme/variables.scss";

.container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: $NEUTRAL_10;
  border: 1px solid $NEUTRAL_40;
  border-radius: 8px;
  padding: 6px 12px 6px 12px;
  gap: 4px;

  .profileSection {
    display: flex;
    gap: 8px;
    align-items: center;

    .picture {
      height: 32px;
      width: 32px;
      border-radius: 50%;
      background-color: $NEUTRAL_80;
      object-fit: cover;
    }

    .pictureWrapper {
      background-color: $PRIMARY_SOFT;
      height: 32px;
      width: 32px;
      border-radius: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .fontName {
      font-size: calc(9px + (14 - 9) * ((100vw - 280px) / (1600 - 280)));
      font-weight: 400;
      color: $NEUTRAL_100;
    }
  }

  .left {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .toggleButton {
    display: flex;
    align-items: center;
    gap: 4px;

    .setPic {
      font-size: calc(9px + (12 - 9) * ((100vw - 280px) / (1600 - 280)));
      font-weight: 400;
      color: $NEUTRAL_100;
    }
  }

  .icon {
    cursor: pointer;
    display: flex;
    align-items: center;

    .crossCircleIcon {
      color: $NEUTRAL_90;
    }
  }
}
