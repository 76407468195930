@import "../../../../../../../theme/variables.scss";

.container {
  background-color: $NEUTRAL_LIGHTEST;
  border-radius: 20px 20px 0px 0px;
  width: 100%;
  padding-top: 15px;
  padding-inline: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 24px;

  >* {
    width: 100%;
  }

  .topLine {
    background-color: #b4b7ba;
    height: 5px;
    width: 42px;
    border-radius: 5px;
  }

  .header {
    padding-top: 17px;
    text-align: center;
    padding-bottom: 20px;

    h3 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      text-align: center;
      color: $NEUTRAL_DARKEST;
    }
  }

  .infoWrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 10px;

    .stackedInfo {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: auto;
      padding-bottom: 8px;
      border-bottom: $NEUTRAL_LINE;

      &:last-child {
        border-bottom: none;
      }

      >*:first-child {
        // width: 35%;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $NEUTRAL_70;
      }

      >*:last-child {
        // width: 65%;
        text-align: right;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        text-align: right;
        color: $NEUTRAL_DARKEST;
      }
    }
  }

  .onProcess {
    display: grid;
    place-items: center;
    width: 107px;
    height: 28px;
    background: $PRIMARY_SOFT;
    border: 1px solid $PRIMARY_MAIN;
    border-radius: 8px;
    font-size: 14px;
    line-height: 20px;
    color: $PRIMARY_MAIN !important;
  }

  .finished {
    display: grid;
    place-items: center;
    width: 107px;
    height: 28px;
    background: $SECONDARY_SOFT;
    border: 1px solid $SECONDARY_MAIN;
    border-radius: 8px;
    font-size: 14px;
    line-height: 20px;
    color: $SECONDARY_MAIN !important;
  }

  button {
    padding: 10px;
    background-color: $PRIMARY_MAIN;
    border-radius: 5px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $NEUTRAL_10;
    border: none;
  }
}