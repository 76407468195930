@import "../../../../theme/variables.scss";

.container {
  height: calc(100vh - 114px);
  width: 100%;
  display: flex;

  .leftSide {
    flex: 1;
    border-right: 1px solid #ededed;
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-height: 100%;

    .header {
      display: flex;
      flex-direction: row;
      gap: 8px;
      padding: 16px 16px 0 16px;
    }

    .editorWrapper {
      max-height: 100%;
      height: 100%;
      overflow: auto;
      background-color: $NEUTRAL_10;
      z-index: 0;
      width: 100%;

      .toolbar {
        background-color: $NEUTRAL_20;
        border-radius: 8px 8px 0 0;
        position: sticky;
        top: 0;
        z-index: 2;
        margin: 0 16px;
        border: 1px solid #ededed;
      }

      .editor {
        padding: 16px;
        background-color: $NEUTRAL_10;
        min-height: 540px;
        width: 100%;
      }
    }
  }

  .rightSide {
    flex: 1;
    display: flex;
    flex-direction: column;
    max-height: 100%;

    .header {
      display: flex;
      align-items: center;
      gap: 8px;
      justify-content: flex-end;
      border-bottom: 1px solid $NEUTRAL_20;
      padding: 16px;

      .buttonWrapper {
        width: fit-content;
      }

      .secondaryButton {
        background-color: $NEUTRAL_10;
        border: 1px solid $PRIMARY_MAIN;
        border-radius: 8px;
        display: flex;
        align-items: center;
        padding: 10px 12px;
        gap: 4px;
        cursor: pointer;

        .label {
          font-size: 14px;
          font-weight: 500;
          color: $PRIMARY_MAIN;
        }

        .icon {
          color: $PRIMARY_MAIN;
        }
      }

      .secondaryButton:hover {
        background-color: $NEUTRAL_30;
      }

      .primaryButton {
        background-color: $PRIMARY_MAIN;
        border: 1px solid $PRIMARY_MAIN;
        border-radius: 8px;
        display: flex;
        align-items: center;
        padding: 10px 12px;
        gap: 4px;
        cursor: pointer;

        .label {
          font-size: 14px;
          font-weight: 500;
          color: $NEUTRAL_10;
        }

        .icon {
          color: $NEUTRAL_10;
        }
      }

      .primaryButton:hover {
        background-color: #125eb9;
      }
    }

    .loaderWrapper {
      width: 100%;
      height: 100%;
      aspect-ratio: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .noPdfWrapper {
      width: 100%;
      height: 100%;
      aspect-ratio: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 16px;

      img {
        height: 80px;
        width: 80px;
      }

      .textWrapper {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: center;
        width: 50%;

        .title {
          font-size: 20px;
          font-weight: 600;
          color: $NEUTRAL_100;
        }

        .information {
          font-size: 14px;
          font-weight: 400;
          color: $NEUTRAL_60;
          text-align: center;
        }
      }
    }

    .hidden {
      display: none;
    }

    .embedWrapper {
      padding: 16px;
      height: 100%;

      embed {
        border-radius: 8px;
        height: 100%;
        width: 100%;
      }
    }
    .htmlRenderWrapper {
      ul li {
        list-style-type: none;
        margin-left: 30px;
        &:before {
          content: "• ";
          margin-left: -30px;
        }
      }
      ol li {
        list-style-type: decimal;
        margin-left: 30px;
        font-size: 12px;
      }

      > p {
        white-space: pre-line;
        font-family: "Calibri" !important;
      }
      a {
        color: black;
        font-weight: 600;
      }
    }
  }
}
