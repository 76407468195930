@import "../../../../../theme/variables.scss";

.container {
  overflow-x: hidden;

  .mobileSubTitle {
    display: none;
  }

  >* {
    padding-inline: 30px;
  }

  .header {
    padding-bottom: 24px;
    border-bottom: 1px solid $NEUTRAL_40;
    display: flex;

    .left {
      flex-grow: 1;

      h3 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 140%;
        letter-spacing: 0.005em;
        color: $NEUTRAL_100;
      }

      .infoContractWrapper{
        display: flex;
        align-items: center;
        padding: 8px 4px 0px 0px;
        font-family: "Poppins";
        font-size: 16px;
        font-weight: 600;
        color: $NEUTRAL_100;
        gap: 12px;

        .contractTypeWrapper{
          display: flex;
          align-items: center;
          font-weight: 400;
          font-size: 14px;
          padding: 4px 8px;
          color: $PRIMARY_MAIN;
          border-radius: 8px;
          background-color: $PRIMARY_SOFT;
          gap: 8px;

          .contractType{
            color: $PRIMARY_MAIN;
            font-weight: 700;
            font-size: 14px;
          }
        }
      }
    }

    .right {
      display: flex;
      align-items: flex-end;

      .fulfillmentDateWrapper{
        display: flex;
        align-items: center;
        padding: 4px 8px;
        background-color: $PRIMARY_SOFT;
        border-radius: 8px; 
        gap: 8px;
        
        span {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          // line-height: 130%;
          color: $PRIMARY_MAIN;
  
          &:last-child {
            padding-left: 8px;
            font-weight: 700;
            color: $PRIMARY_MAIN;
          }
        }
      }
    }
  }

  .stepsController {
    padding-block: 24px;
    border-bottom: 1px solid #e0e0e0;
    width: 100%;
    margin-inline: 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 16px;

    .controllers {
      display: flex;
      align-items: center;
      gap: 8px;
      overflow-x: scroll;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .step {
      display: flex;
      align-items: center;
      gap: 8px;
      white-space: nowrap;
      text-overflow: ellipsis;

      button {
        display: flex;
        align-items: center;
        padding: 8px 12px;
        gap: 8px;
        height: 38px;
        background: #ffffff;
        border: 1px solid #c2c2c2;
        border-radius: 50px;
        transition: all 0.3s;
        cursor: pointer;

        &:hover {
          background-color: #f1f3ff95;
        }

        span {
          display: grid;
          place-items: center;
          font-family: "Poppins";
          font-style: normal;
          transition: all 0.3s;

          &:first-child {
            width: 20px;
            height: 20px;
            border: 1px solid $NEUTRAL_50;
            border-radius: 50%;
            font-weight: 600;
            font-size: 14px;
            line-height: 140%;
            text-align: center;
            color: $NEUTRAL_100;
            flex-shrink: 0;
          }

          &:last-child {
            font-weight: 400;
            font-size: 16px;
            line-height: 140%;
            text-align: center;
            color: $NEUTRAL_100;
            width: 100%;
          }
        }

        &.Active {
          border: 1px solid $PRIMARY_MAIN;
          background-color: $PRIMARY_SURFACE;

          span {
            &:first-child {
              background-color: $PRIMARY_MAIN;
              border: none;
              color: $NEUTRAL_10;
            }

            &:last-child {
              color: $PRIMARY_MAIN;
            }
          }
        }
      }

      .separator {
        background-color: $NEUTRAL_100;
        height: 4px;
        width: 16px;
        border-radius: 50px;

        &.hidden {
          display: none;
        }
      }
    }

    .position {
      display: flex;
      gap: 8px;
      // margin-left: 20px;

      .selectedPosition {
        border: 1px solid $PRIMARY_MAIN !important;
        background-color: $PRIMARY_SURFACE !important;

        span {
          color: $PRIMARY_MAIN !important;

          &:last-child {
            background-color: $PRIMARY_MAIN !important;
            color: $NEUTRAL_10 !important;
          }

        }
      }

      .label {
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 8px;
        height: 40px;
        background-color: $NEUTRAL_10;
        border: 1px solid $NEUTRAL_50;
        border-radius: 8px;
        cursor: pointer;
        transition: 0.2s;
        white-space: nowrap;

        span {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: $NEUTRAL_100;
          transition: 0.2s;


          &:last-child {
            display: grid;
            place-items: center;
            color: #1571de;
            background-color: $PRIMARY_SOFT;
            padding-inline: 8px;
            border-radius: 4px;
          }
        }
      }

      .morePosition {
        display: flex;
        justify-content: center;
        align-items: center;
        background: $PRIMARY_MAIN;
        color: $NEUTRAL_10;
        border: 1px solid $PRIMARY_SOFT;
        border-radius: 8px;
        padding: 8px;
        cursor: pointer;

        >p {
          font-weight: 500;
          font-size: 14px;
        }
      }
    }
  }

  .dataController {
    display: flex;
    padding-top: 24px;
    width: 100%;

    .box {
      display: flex;
      flex-direction: column;

      width: 50%;

      .left {
        display: flex;
        gap: 8px;
        flex-wrap: wrap;

        .label {
          display: flex;
          align-items: center;
          gap: 8px;
          padding: 8px;
          height: 40px;
          background-color: $NEUTRAL_10;
          border: 1px solid $NEUTRAL_50;
          border-radius: 8px;

          span {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: $NEUTRAL_100;

            &:last-child {
              display: grid;
              place-items: center;
              color: #1571de;
              background-color: $PRIMARY_SOFT;
              padding-inline: 8px;
              border-radius: 4px;
            }
          }
        }
      }

      .right {
        display: flex;
        justify-content: flex-end;
        gap: 16px;

        .filterWrapper {
          width: 208px;
        }
      }
    }
  }

  .content {
    padding-block: 16px;
    z-index: 0;

    .form {
      @include padding(24, 16, 16px);
      background-color: $NEUTRAL_10;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      gap: 20px;

      .formHeader {

        .candidateName {
          >span {
            font-size: 20px !important;
            font-style: normal;
            font-weight: 700;
            line-height: 150%;
          }

        }

        .label {
          display: flex;
          align-items: center;
          gap: 4px;
          color: $NEUTRAL_100;
          font-size: 14px;
        }
      }

      .formContent {
        display: flex;
        flex-direction: column;
        gap: 20px;

        >div {
          display: flex;
          flex-direction: column;
          gap: 4px;

          >span {
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 140%;
          }

          &.contractDate {
            .range {
              display: grid;
              grid-template-columns: 1fr auto 1fr;
              gap: 8px;

              .separator {
                display: grid;
                place-items: center;
                // margin-top: 22px;
              }

              .filler {
                opacity: 0;
              }
            }
          }

          &.salary {

            .requirements {
              display: grid;
              grid-template-columns: 1fr 1fr;
              gap: 8px;
            }
          }

          &.allowances {
            position: relative;

            .allowanceHeader {
              display: flex;
              align-items: center;
              gap: 16px;

              >span {
                flex-grow: 1;
                font-family: Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 140%;
              }
            }

            .addAllowance {
              display: flex;
              align-items: center;
              gap: 4px;
              background-color: $NEUTRAL-10;
              color: $PRIMARY_MAIN;
              font-family: Poppins;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 20px;
              border: 1px solid $PRIMARY_MAIN;
              border-radius: 8px;
              padding: 10px 12px;

              &.absolute {
                position: absolute;
                top: 12px;
                right: 0;
              }
            }

            .empty {
              display: flex;
              align-items: center;
              justify-content: space-between;
              gap: 8px;
              flex-wrap: wrap;
              padding: 8px 8px 8px 16px;
              border: 1px solid $NEUTRAL-40;
              border-radius: 8px;

              >span {
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                color: $NEUTRAL_60
              }
            }

            .fieldsWrapper {
              display: flex;
              flex-direction: column;
              gap: 8px;

              .each {
                display: flex;
                flex-direction: column;
                gap: 4px;

                .fields {
                  display: grid;
                  grid-template-columns: 1fr 1fr auto;
                  gap: 8px;

                  >button {
                    padding: 0;
                    display: grid;
                    place-items: center;
                    background-color: transparent;
                    border: none;
                    color: $DANGER_MAIN;
                    margin-top: 8px;
                    margin-bottom: 8px;
                  }
                }
              }
            }
          }
        }



      }

      .formActions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 8px;

        >button {
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          min-width: 120px;
          padding: 8px;
          border-radius: 6px;

          &:first-child {
            background-color: $NEUTRAL-10;
            color: $NEUTRAL_100;
            border: 1px solid $NEUTRAL_100;
          }

          &:last-child {
            background-color: $PRIMARY_MAIN;
            color: $NEUTRAL_10;
            border: 1px solid $PRIMARY_MAIN;

            &:disabled {
              background-color: $PRIMARY_DISABLED;
              border-color: $PRIMARY_DISABLED;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    .position {
      padding-left: 20px;

    }

    .mobileSubTitle {
      display: block;
      // padding-bottom: 8px;
      padding-inline: 16px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: $NEUTRAL_100;
    }

    >* {
      padding-inline: 0;
      margin-inline: 16px;

      &.header {
        flex-direction: column;
        gap: 8px;
        margin-inline: 16px;

        .left {
          h3 {
            font-size: 20px;
          }
        }

        .right {
          font-size: 12px;
        }
      }

      &.stepsController {
        border-bottom: none !important;
        padding-block: 16px;
        flex-direction: column;

        .controllers {
          margin-inline: 0px !important;
          padding-inline: 16px;

          .step {
            button {
              padding: 8px 10px;

              span {
                &:last-child {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }

      &.dataController {
        border-top: 1px solid $NEUTRAL_40;
        flex-direction: column;
        margin-inline: 0;
        padding-top: 0;

        .box {
          // flex-direction: column;
          border-bottom: 1px solid $NEUTRAL_40;
          width: 100%;
          padding-block: 16px;

          &:last-child {
            border-bottom: none !important;
          }

          .right,
          .left {
            padding-inline: 16px;
          }

          .left {
            .label {
              height: 32px;
              border-radius: 4px;
            }
          }

          .right {
            flex-direction: column;

            >* {
              width: 100% !important;
            }
          }

          // >* {
          //   width: calc(100% - 32px);

          //   &:first-child {
          //     padding-bottom: 16px;
          //   }

          //   &:last-child {
          //     flex-direction: column;
          //     padding-top: 16px;

          //     >* {
          //       width: 100% !important;
          //     }
          //   }
          // }
        }
      }

      &.content {
        padding: 0 !important;
        margin-inline: 0;
        padding-bottom: 24px !important;
        padding-top: 8px !important;
      }
    }
  }
}