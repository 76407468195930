@import "../../../../../../../../theme/variables.scss";

.container {
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid $PLATINUM_SOLID;

  .header {
    padding: 10px;
    text-align: center;
    background-color: $ALICE_BLUE;
    border-bottom: 1px solid $PLATINUM_SOLID;

    h3 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 150%;
      text-align: center;
      letter-spacing: 0.005em;
      color: $NEUTRAL_100;
    }
  }

  .content {
    background-color: $NEUTRAL_10;
    padding: 16px;

    .areas {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding-bottom: 16px;
    }

    .TOP {
      padding: 10px 16px;
      display: flex;
      align-items: center;
      gap: 16px;
      background-color: #f3f6ff;
      border-radius: 8px;
      transition: background-color 1s ease-in-out;
      &.borderYellow {
        border: 1px solid var(--Alert-Main, #e9b62f);
        // border-bottom: 0.5px solid var(--Alert-Main, #e9b62f);
        // border-top: 0.5px solid var(--Alert-Main, #e9b62f);
      }

      &.yellow {
        background-color: #e9b62f1a;
      }
      img {
        height: 32px;
        aspect-ratio: 1;
        object-fit: contain;
      }

      span {
        &:nth-child(2) {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: $NEUTRAL_90;
          flex-grow: 1;
        }

        &:last-child {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 150%;
          letter-spacing: 0.005em;
          color: $NEUTRAL_100;
        }
      }
    }
  }
}
