@import "../../../../theme/variables.scss";

.container {
  background: $PRIMARY_MAIN;
  padding: 24px 32px;
  color: $NEUTRAL_10;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  .textWrapper {
    text-align: center;
    display: flex;
    flex-direction: column;
    > :first-child {
      @include font-size(16, 12, 12px);
      font-weight: 400;
      margin-bottom: 8px;
    }
    > :nth-child(2) {
      @include font-size(32, 20, 20px);
      font-weight: 700;
      margin-bottom: 16px;
    }
    > :last-child {
      font-size: 14px;
      font-weight: 400;
    }
  }
  .buttonWrapper {
    display: flex;
    gap: 16px;
    justify-content: center;
    a {
      text-decoration: none;
    }
    .button {
      display: flex;
      text-align: left;
      align-items: center;
      justify-content: center;
      min-width: 190px;
      gap: 8px;
      background: $NEUTRAL_100;
      padding: 8px 22px;
      border-radius: 16px;
      cursor: pointer;
      > img {
        width: 32px;
        height: 32px;
      }
      .buttonTextWrapper {
        > :first-child {
          @include font-size(14, 12, 12px);
          font-weight: 400;
          color: white;
        }
        > :last-child {
          @include font-size(20, 14, 14px);
          font-weight: 600;
          color: white;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .container {
    border-radius: 16px;
    gap: 24px !important;
    padding: 24px 16px !important;

    .textWrapper {
      gap: 8px;
      > * {
        margin: 0 !important;
      }
    }
    .buttonWrapper {
      flex-direction: column;
      .button {
        width: 100%;
      }
    }
  }
}
