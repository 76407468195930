.container {
  height: 100vh;
  width: 100vw;
  background-color: #00000080;
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
}

.modalNotif {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding: 32px;
  border-radius: 8px;
  background: var(--neutral-10, #fff);

  /* Card Double Shadow */
  box-shadow: 0px 0px 1px 0px rgba(9, 30, 66, 0.31),
    0px 3px 5px 0px rgba(9, 30, 66, 0.2);
  .buttonBox {
    display: flex;
    align-items: center;
    gap: 16px;
    width: 100%;
    .save {
      width: 100%;
      display: flex;
      padding: 8px 12px;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      background: var(--primary-main, #1571de);
      color: #fff;
      text-align: center;

      /* Text M/Reguler */
      //   font-family: Poppins;
      cursor: pointer;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
    }
    .cancel {
      display: flex;
      width: 100%;
      padding: 8px 12px;
      justify-content: center;
      align-items: center;
      //   gap: 4px;
      border-radius: 8px;
      border: 1px solid var(--neutral-100, #0a0a0a);
      background: var(--neutral-10, #fff);
      color: var(--neutral-100, #0a0a0a);
      text-align: center;

      /* Text M/Reguler */
      //   font-family: Poppins;
      cursor: pointer;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
    }
  }
  .topNotif {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    .textP {
      color: #878787;
      text-align: center;

      /* Text S/Reguler */
      //   font-family: Poppins;
      font-size: calc(10px + (13 - 10) * ((100vw - 280px) / (1600 - 280)));
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 153.846% */
    }
    .warningText {
      color: #0f161f;

      /* Heading 6/SemiBold */
      //   font-family: Poppins;
      text-align: center;
      font-size: calc(14px + (24 - 14) * ((100vw - 280px) / (1600 - 280)));
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 33.6px */
      letter-spacing: 0.12px;
    }
  }
}

@media screen and (max-width: 350px) {
  .modalNotif {
    .buttonBox {
      flex-direction: column;
    }
  }
}
