@import "../../../../theme/variables.scss";

.holdText {
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500!important;
  line-height: 20px;
}

.background {
  width: 100vw;
  height: 100vh;
  background-color: $NEUTRAL_OVERLAY;
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .emptyWrapper {
    flex-grow: 1;
  }

  .addressWrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;

    > * {
      width: 100%;

      &.divider {
        height: 1px;
        background-color: $NEUTRAL_50;
      }
    }
  }

  .errorWrapper {
    color: $DANGER_MAIN;
    font-size: 12px;
    opacity: 0;

    &.error {
      opacity: 1;
    }
  }

  .container {
    background-color: $NEUTRAL_10;
    border-radius: 8px;
    width: 732px;
    display: flex;
    flex-direction: column;
    // max-height: 737px;
    // height: calc(100vh - 100px);
    height: 85vh;
    // max-width: calc(100vw - 50px);
    overflow-y: hidden;

    // overflow-y: hidden;
    // position: relative;
    .header {
      padding: 22px 20px 12px 20px;
      border-bottom: 1px solid $NEUTRAL_40;
      display: flex;
      flex-direction: column;
      gap: 24px;

      // position: sticky;
      // top: 0;
      // background-color: $NEUTRAL_10;
      & > :first-child {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .closeIcon {
        cursor: pointer;
      }

      h3 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: calc(20px + (32 - 20) * ((100vw - 280px) / (1600 - 280)));
        line-height: 48px;
        display: flex;
        align-items: center;
        color: $NEUTRAL_100;
      }

      .headerControl {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        justify-content: space-between;
        align-items: center;

        .backArrow {
          cursor: pointer;
          color: $PRIMARY_MAIN;
          display: flex;
          gap: 8px;
        }

        > :last-child {
          font-weight: 600;

          font-size: calc(14px + (20 - 14) * ((100vw - 280px) / (1600 - 280)));
        }
      }

      .controller {
        display: flex;
        align-items: center;
        gap: 8px;

        .inviteButtonWrapper {
          flex-grow: 1;
          display: flex;
          justify-content: flex-end;

          .inviteButton {
            & > :first-child {
              padding: 12px;
            }
          }
        }

        > button {
          display: flex;
          align-items: center;
          gap: 15px;
          padding: 8px 16px;
          background: #ffffff;
          border: 1px solid $NEUTRAL_40;
          border-radius: 8px;
          color: $NEUTRAL_100;
          transition: all 0.3s;

          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;

          &:hover {
            background-color: $PRIMARY_SURFACE_HOVER;
            font-weight: 500;
          }

          &.active {
            border-color: $PRIMARY_MAIN;
            background-color: $PRIMARY_SURFACE;
            color: $PRIMARY_MAIN;
            font-weight: 500;
          }
        }
      }
    }

    .content {
      overflow: auto;
      padding: 16px 20px;
      transition: 1s;
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      .unqualifiedSection {
        .intro {
          // display: flex;
          // align-items: center;
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 16px;
          padding-bottom: 24px;

          .stackedIntro {
            flex: 1;
            display: flex;
            align-items: center;
            gap: 8px;

            div {
              &:first-child {
                display: grid;
                place-items: center;
                padding: 12px;
                background-color: $NEUTRAL_20;
                border-radius: 5px;

                > img {
                  height: 16px;
                  aspect-ratio: 1;
                  object-fit: contain;
                }
              }

              &:last-child {
                display: flex;
                flex: 1;
                flex-direction: column;
                padding: 8px 16px;
                background-color: $PRIMARY_SURFACE;
                border-radius: 4px;

                span {
                  font-family: "Poppins";
                  font-style: normal;

                  &:first-child {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 16px;
                    color: $NEUTRAL_60;
                  }

                  &:last-child {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 20px;
                    color: $NEUTRAL_100;
                  }
                }
              }
            }
          }
        }

        .body {
          display: flex;
          flex-direction: column;
          gap: 16px;

          .progressBar {
            width: 100%;
            overflow-x: scroll;
            > :first-child {
              > :first-child {
                > :first-child {
                  display: none;
                }
              }

              > :last-child {
                > :last-child {
                  display: none;
                }
              }
            }
          }

          .storageSection {
            & > :first-child {
              font-weight: 400;
              font-size: 14px;
              margin-bottom: 8px;
            }

            .wrapper {
              display: grid;
              grid-template-columns: 1fr 1fr;
              gap: 8px;

              .card {
                border: 1px solid $NEUTRAL_40;
                border-radius: 8px;
                background-color: $NEUTRAL_20;
                display: flex;
                padding: 8px 12px;
                align-items: center;
                gap: 12px;
                cursor: pointer;

                .radioButton {
                  > input {
                    width: 20px;
                    height: 20px;
                  }
                }

                .icon {
                  background-color: $NEUTRAL_10;
                  width: 32px;
                  height: 32px;
                  border-radius: 50%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }

                .text {
                  > :first-child {
                    font-weight: 400;
                    font-size: 11px;
                    color: $NEUTRAL_60;
                  }

                  > :last-child {
                    font-weight: 500;
                    font-size: 14px;
                  }
                }
              }
            }
          }

          .tableContainer {
            display: flex;
            flex-direction: column;
            gap: 8px;

            .isClientSetter {
              display: flex;
              align-items: center;
              padding: 8px;
              border-radius: 8px;
              border: 1px solid $NEUTRAL_30;
              gap: 16px;

              > span {
                flex-grow: 1;
                font-family: Poppins;
                font-size: calc(
                  12px + (14 - 12) * ((100vw - 280px) / (1600 - 280))
                );
                width: 50%;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
              }

              >div {
                flex-shrink: 0;
              }

            }

            .searchbarWrapper {
              position: relative;

              > button {
                position: absolute;
                right: 50px;
                top: 10px;
                display: flex;
                align-items: center;
                gap: 4px;
                border: none;
                background-color: transparent;
                font-family: Poppins;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 16px;
                color: $PRIMARY_MAIN;
              }
            }

            .positionList {
              > .tableWrapper {
                border: 1px solid $NEUTRAL_40;
                padding: 16px;
                display: flex;
                border-radius: 8px 8px 0px 0px;
                flex-direction: column;
                gap: 8px;
                height: 250px;
                overflow: auto;

                .list {
                  display: flex;
                  align-items: center;
                  gap: 8px;
                  background-color: $PRIMARY_SURFACE;
                  padding: 8px;
                  border-radius: 8px;

                  .icon {
                    color: $NEUTRAL_60;
                  }

                  > :last-child {
                    font-weight: 400;
                    font-size: 14px;
                  }
                }
              }

              > .paginationWrapper {
                border: 1px solid $NEUTRAL_40;
                border-radius: 0 0 8px 8px;
              }
            }
          }

          .actionContainer {
            display: flex;
            justify-content: flex-end;
            // padding-block: 8px;

            .buttonWrapper {
              display: flex;
              gap: 8px;

              > div {
                padding: 12px 16px;
              }
            }
          }

          .customTableText {
            display: flex;
            align-items: center;
            gap: 12px;
          }

          .remarkContainer {
            > :first-child {
              font-weight: 500;
              font: 14px;
              margin-bottom: 8px;
            }

            > :last-child {
              textarea {
                min-height: 220px;
              }
            }
          }
        }

        .radioButton {
          display: flex;
          align-items: center;
          justify-content: center;

          > input {
            width: 20px;
            height: 20px;
            cursor: pointer;
          }
        }
      }

      .inviteContainer {
        overflow-y: scroll;
        // height: 570px;
        height: 100%;

        .intro {
          display: grid;
          // align-items: center;
          grid-template-columns: 1fr 1fr;
          gap: 16px;
          padding-bottom: 34px;

          .stackedIntro {
            flex: 1;
            display: flex;
            align-items: center;
            gap: 8px;

            div {
              &:first-child {
                display: grid;
                place-items: center;
                padding: 12px;
                background-color: $NEUTRAL_20;
                border-radius: 5px;

                > img {
                  height: 16px;
                  aspect-ratio: 1;
                  object-fit: contain;
                }
              }

              &:last-child {
                display: flex;
                flex: 1;
                flex-direction: column;
                padding: 8px 16px;
                background-color: $PRIMARY_SURFACE;
                border-radius: 4px;

                span {
                  font-family: "Poppins";
                  font-style: normal;

                  &:first-child {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 16px;
                    color: $NEUTRAL_60;
                  }

                  &:last-child {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 20px;
                    color: $NEUTRAL_100;
                  }
                }
              }
            }
          }
        }

        form {
          display: flex;
          flex-direction: column;
          gap: 16px;

          .section {
            display: flex;
            align-items: center;
            gap: 15px;

            &.address {
              flex-direction: column;

              > * {
                width: 100%;
              }
            }

            &.smallerGap {
              gap: 8px;
            }

            > * {
              flex: 1;

              &.boxForm {
                display: flex;
                flex-direction: column;
                gap: 4px;

                label {
                  font-family: "Poppins";
                  font-style: normal;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 20px;
                  color: $NEUTRAL_100;
                }

                .selectWrapper {
                  height: 44px;
                  background: $NEUTRAL_10;
                  border: 1px solid $NEUTRAL_40;
                  border-radius: 8px;
                  position: relative;
                  display: flex;
                  align-items: center;

                  > img {
                    width: 20px;
                    aspect-ratio: 1;
                    border-radius: 50%;
                    object-fit: cover;
                    margin-left: 12px;
                  }

                  input {
                    border: none;
                    color: $NEUTRAL_100 !important;
                    padding-inline-start: 8px !important;
                    padding-inline-end: 53px !important;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;

                    &::placeholder {
                      font-family: "Poppins";
                      font-style: normal;
                      font-weight: 400;
                      font-size: 13px;
                      line-height: 20px;
                      color: $NEUTRAL_70;
                      width: 100%;
                      opacity: 1;
                    }

                    &:focus {
                      border: none;
                      outline: none;
                    }
                  }

                  > span,
                  input {
                    padding-inline: 12px;
                    font-family: "Poppins";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 20px;
                    color: $NEUTRAL_70;
                    width: 100%;

                    &.filled {
                      color: $NEUTRAL_100;
                    }
                  }

                  &.active {
                    border-color: $PRIMARY_MAIN;
                  }

                  .expandButton {
                    position: absolute;
                    right: 8px;
                    top: 10.5px;
                    display: grid;
                    place-items: center;
                    padding: 0;
                    background-color: transparent;
                    border: none;
                  }

                  .clearButton {
                    position: absolute;
                    right: 35px;
                    top: 13.5px;
                    display: grid;
                    place-items: center;
                    padding: 0;
                    background-color: transparent;
                    border: none;
                  }

                  .optionsWrapper {
                    position: absolute;
                    top: 50px;
                    width: 100%;
                    background-color: $NEUTRAL_10;
                    border-radius: 8px;
                    overflow: hidden;
                    box-shadow: 0px 0px 1px rgba(9, 30, 66, 0.31),
                      0px 3px 5px rgba(9, 30, 66, 0.2);
                    z-index: 1;
                    padding-block: 4px;
                    max-height: 200px;
                    overflow: auto;

                    > div {
                      padding: 8px 12px;
                      font-family: "Poppins";
                      font-style: normal;
                      font-weight: 400;
                      font-size: 14px;
                      line-height: 20px;
                      color: $NEUTRAL_90;
                      display: flex;
                      align-items: center;
                      gap: 8px;

                      &:hover {
                        background-color: $PRIMARY_SURFACE_HOVER;
                      }

                      > img {
                        width: 20px;
                        aspect-ratio: 1;
                        border-radius: 50%;
                        object-fit: cover;
                      }
                    }
                  }
                }

                .stacked {
                  display: flex;
                  align-items: center;
                  gap: 8px;
                  height: 44px;

                  > * {
                    flex: 1;
                    height: 100%;
                  }

                  .inputWrapper {
                    position: relative;

                    .placeholderReplacement,
                    ::placeholder,
                    ::-webkit-input-placeholder {
                      position: absolute;
                      top: 12px;
                      left: 12px;
                      font-family: "Poppins";
                      font-style: normal;
                      font-weight: 400;
                      font-size: 13px;
                      line-height: 20px;
                      color: $NEUTRAL_70;
                      opacity: 1;
                    }

                    .endAdornment {
                      position: absolute;
                      right: 8px;
                      top: 12px;
                      background-color: transparent;
                      border: none;
                      color: $PRIMARY_MAIN;
                      display: grid;
                      place-items: center;
                      // z-index: 0;
                    }
                  }
                }

                > input,
                .inputWrapper input {
                  border: 1px solid $NEUTRAL_40;
                  border-radius: 8px;
                  padding-inline: 12px;
                  height: 44px;
                  width: 100%;
                  background-color: transparent;

                  &[type="time"]::-webkit-calendar-picker-indicator {
                    opacity: 0;
                  }

                  &:focus {
                    outline: none;
                    border-color: $PRIMARY_MAIN;
                  }
                }

                .empty {
                  &:before {
                    content: "Time";
                    font-family: "Poppins";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 20px;
                    color: $NEUTRAL_70;
                    position: absolute;
                    background: $NEUTRAL_10;
                    width: 70px;
                  }

                  &:focus:before {
                    width: 0;
                    content: "";
                  }
                }
              }
            }
          }

          > button {
            align-self: flex-end;
            width: 100px;
            height: 36px;
            background: $PRIMARY_MAIN;
            border-radius: 8px;
            border: none;

            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: $NEUTRAL_10;

            &:disabled {
              background-color: $PRIMARY_DISABLED;
            }
          }
        }
      }

      .candidateHistory {
        padding-block: 8px;

        .historyContainer {
          display: flex;
          flex-direction: column;
          padding-left: 25px;

          .historyWrapper {
            border-left: 5px dashed $PRIMARY_MAIN;
            padding-left: 33px;
            padding-bottom: 16px;
            position: relative;

            .head {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 8px;

              .textHead {
                > :first-child {
                  font-weight: 500;
                  font-size: 14px;
                  color: $NEUTRAL_60;
                }

                :last-child {
                  font-weight: 600;
                  font-size: 16px;
                }
              }

              .status {
                padding: 6px 8px;
                border-radius: 8px;
                display: flex;
                align-items: center;
                gap: 8px;

                > p {
                  font-weight: 500;
                  font-size: 14px;
                }
              }

              .notPassed {
                background: #fff8eb;
                color: #ffc355;
              }

              .unqualified {
                background: $DANGER_SURFACE;
                color: $DANGER_MAIN;
              }
            }

            .historyContent {
              padding: 16px;
              border: 1px solid $NEUTRAL_40;
              border-radius: 8px;

              .contentHead {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-bottom: 8px;
                margin-bottom: 8px;
                border-bottom: 1px solid $NEUTRAL_30;

                .profileWrapper {
                  display: flex;
                  align-items: center;
                  gap: 8px;
                  position: relative;

                  .profilePicture {
                    height: 32px;
                    width: 32px;
                    border-radius: 50%;

                    > img {
                      width: 100%;
                      height: 100%;
                    }
                  }

                  .userInformation {
                    display: flex;
                    flex-direction: column;

                    .username {
                      font-size: 14px;
                      font-weight: 400;
                      color: $NEUTRAL_100;
                    }

                    .jobTitle {
                      font-size: 11px;
                      font-weight: 400;
                      color: $NEUTRAL_60;
                    }
                  }
                }

                > :first-child {
                  font-weight: 700;
                  font-size: 16px;
                }
              }

              .contentBody {
                > :first-child {
                  font-weight: 500;
                  font-size: 14px;
                }

                > :last-child {
                  font-weight: 400;
                  font-size: 14px;
                  color: $NEUTRAL_90;
                }
              }
            }

            .topIcon {
              width: 50px;
              height: 50px;
              border-radius: 50%;
              background: $PRIMARY_SURFACE;
              display: flex;
              align-items: center;
              justify-content: center;
              color: $PRIMARY_MAIN;
              position: absolute;
              top: -1px;
              left: 0;
              transform: translateX(-55%);
            }
          }

          > :last-child {
            border-left: 5px solid $NEUTRAL_10;
          }
        }
      }
    }
  }

  .errorWrapper {
    color: $DANGER_MAIN;
    font-size: 12px;
    opacity: 0;

    &.error {
      opacity: 1;
    }
  }

  .addressWrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;

    > * {
      width: 100%;

      &.divider {
        height: 1px;
        background-color: $NEUTRAL_50;
      }
    }
  }

  .hidden {
    display: none !important;
  }
}

@media screen and (max-width: 768px) {
  .background {
    // align-items: flex-end;
    padding: 0;


    .container {
      position: fixed;
      bottom: 0;
      width: 100%;
      height: 79.5%;

      // width: 100vw;
      // height: 100%;
      border-radius: 16px 16px 0 0;

      .content {
        // padding-bottom: 0px;
        .unqualifiedSection {
          .body {
            // padding-bottom: 0px;
            .actionContainer {
              .buttonWrapper {
                width: 100%;
              }
            }
          }
        }
      }

      .inviteContainer {
        .intro {
          // flex-direction: column;
          grid-template-columns: 1fr!important;

          > * {
            width: 100%;
          }
        }

        form {
          .section {
            flex-direction: column;

            > * {
              width: 100%;
            }
          }
        }
      }

      .unqualifiedSection {

        .wrapper, .intro {
          grid-template-columns: 1fr!important;
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .background {
    .container {
      .header {
        .controller {
          flex-direction: column;
          align-items: flex-start;
          .inviteButtonWrapper {
            width: 100%;
            .inviteButton {
              width: 100%;
            }
          }
          .active {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
        }
      }
      .content {
        .unqualifiedSection {
          .intro {
            flex-direction: column;
            width: 100%;
            align-items: flex-start;
            .stackedIntro {
              width: 100%;
            }
          }
          .body {
            .storageSection {
              .wrapper {
                display: flex;
                flex-direction: column;
              }
            }
          }
        }
      }
    }
  }
}
