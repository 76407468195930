@import "../../../../theme/variables.scss";

.container {
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr 1fr;

  .left {
    background-image: url('../../../../assets/Images/sl-bg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    // padding: 40px 20px;
    @include padding(32, 24, 24px);
    position: relative;
    display: grid;
    place-items: center;
    overflow: clip;

    >*:not(.bg) {
      z-index: 1;
    }

    .logoWrapper {
      display: flex;
      align-items: center;
      gap: 16px;
      position: absolute;
      top: 40px;
      left: 30px;

      .logo {
        display: grid;
        place-items: center;
        // width: 52px;
        @include width (52, 24, 24px);
        aspect-ratio: 1;
        border-radius: 50%;
        background-color: $NEUTRAL-10;

        >img {
          width: 92%;
          object-fit: contain;
        }
      }

      >span {
        font-family: Poppins, sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        color: $NEUTRAL-10;
      }
    }

    .icon {
      width: 562px;
      @include width(562, 154, 154px);
      max-width: calc(100vw - 80px);
      object-fit: contain;
    }

    .bg {
      position: absolute;
      z-index: 0;
      // opacity: 0.1;
      width: 100%;
      height: 100%;
      background: linear-gradient(160deg, #1571DE -1.6%, rgba(21, 113, 222, 0.54) 104.46%);
    }

  }

  .right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
    @include padding-block (56, 32, 32px);
    @include padding-inline (56, 16, 16px);

    .text {
      display: flex;
      flex-direction: column;

      >h3 {
        font-family: Poppins;
        // font-size: 38px;
        @include font-size(38, 20, 20px);
        font-style: normal;
        font-weight: 700;
        line-height: 130%;
        letter-spacing: 0.19px;
        color: $PRIMARY_MAIN;
        padding-bottom: 4px;
      }

      .first {
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
        padding-bottom: 12px;
      }

      .second {
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        color: $NEUTRAL_90;
        padding-bottom: 8px;
      }

      .third {
        font-family: Poppins;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        color: $NEUTRAL_70;
      }
    }

    .info {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 12px;
      border-radius: 4px;
      width: 100%;

      >*:first-child {
        flex-shrink: 0;
      }

      >span {
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        /* 142.857% */
      }

      &.filled {
        border: 1px solid $PRIMARY_MAIN;
        background-color: $PRIMARY_SOFT;

      }

      &.notFilled {
        background-color: $ACTIVE_SURFACE;

        >span {
          color: #FFA928;
        }

      }
    }

    >button {
      background-color: $PRIMARY_MAIN;
      width: 100%;
      padding: 12px;
      outline: none;
      border: none;
      border-radius: 8px;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 142.857%;
      color: $NEUTRAL-10;
    }

  }

}


.outerWrapper {
  height: 100vh;
  @include padding-inline(138, 18, 18px);
  max-width: 1660px;
  margin: auto;
  display: flex;
  flex-direction: column;
  overflow: clip;

  .header {
    position: sticky;
    top: 0;
    @include padding-top(42, 32, 32px);
    background-color: $NEUTRAL-10;
    z-index: 1;

    .logo {
      display: flex;
      align-items: center;
      gap: 16px;
      justify-content: space-between;
      // padding-bottom: 30px;
      @include padding-bottom (30, 16, 16px);

      >img {
        height: 41px;
        object-fit: contain;
      }

      .permata {
        display: flex;
        align-items: center;
        gap: 8px;

        .logoBG {
          width: 46px;
          aspect-ratio: 1;
          border-radius: 50%;
          background-color: $PRIMARY_SURFACE;
          display: grid;
          place-items: center;

          >img {
            width: 92%;
            object-fit: contain;
          }
        }

        .logoText {
          display: flex;
          flex-direction: column;

          >span {
            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 133.333%;
            color: $NEUTRAL_60;
          }

          >p {
            font-family: Poppins;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 150%;
            letter-spacing: 0.1px;
          }
        }

      }
    }

    .stepper {
      display: grid;
      grid-template-columns: auto 1fr auto;
      gap: 18px;
      width: 100%;
      max-width: 100vw;
      overflow: auto;


      .button {

        >button {
          display: grid;
          place-items: center;
          border-radius: 50%;
          @include width (46, 32, 32px);
          aspect-ratio: 1;
          background-color: $NEUTRAL_20;
          border: none;
          color: $PRIMARY_MAIN;

          &:disabled {
            color: $NEUTRAL_50;
          }
        }
      }
    }

    .divider {
      height: 1px;
      width: 100%;
      background-color: $NEUTRAL_30;
    }
  }

  .wrapper {
    height: 100px;
    overflow: auto;
    flex-grow: 1;
    @include padding-bottom(42, 32, 32px);

  }



  .content {
    @include padding-bottom(24, 18, 18px);
    z-index: 0;
    // overflow: clip;

    .errorInfo {
      margin-top: 16px;
      display: flex;
      align-items: center;
      gap: 8p;
      padding: 12px;
      border-radius: 4px;
      background-color: #FFE5E5;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: $DANGER_MAIN;

      >*:first-child {
        flex-shrink: 0;
      }
    }
  }

  .action {
    display: flex;
    justify-content: flex-end;

    .next {
      padding: 12px;
      border-radius: 8px;
      background-color: $PRIMARY_MAIN;
      border: none;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 142.857%;
      color: $NEUTRAL-10;

      &:disabled {
        background-color: $PRIMARY_DISABLED;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .outerWrapper {
    display: block;
    height: auto !important;
  }
  .container {
    grid-template-columns: 1fr;
    height: auto;

    .left {
      gap: 26px;

      .logoWrapper {
        position: static;
        width: 100%;
      }
    }

  }

  .wrapper {
    height: 100%!important;

    &.extraPadding {
      padding-bottom: 140px;
    }
  }
}