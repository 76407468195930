@import "../../../../theme/variables.scss";

// .background {
//   width: 100vw;
//   height: 100vh;
//   background-color: $NEUTRAL_OVERLAY;
//   z-index: 1000;
//   position: fixed;
//   top: 0;
//   left: 0;
//   bottom: 0;
//   right: 0;
//   display: flex;
//   justify-content: center;
//   align-items: center;

  .container {
    background-color: $NEUTRAL_10;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 541px;
    max-height: 80%;
    overflow-y: scroll;

    .header {
      padding: 16px 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 100%;

      .profileText {
        font-size: 20px;
        font-weight: 600;
        color: $NEUTRAL_100;
      }

      .closeButton {
        position: absolute;
        right: 18px;
        top: 16px;
        cursor: pointer;

        .closeIcon {
          color: $NEUTRAL_100;
        }
      }
    }

    h4 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 140%;
      color: $CHINESE_BLACK;
      margin-top: 16px;
      margin-bottom: 4px;
    }

    p {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      text-align: center;
      color: $TAUPE_GRAY;
      margin-bottom: 16px;
    }
  }
// }

@media screen and (max-width: 768px) {
  // .background {
  //   justify-content: flex-end;
  //   align-items: flex-end;

    .container {
      width: 100%;
      padding-bottom: 24px;
      border-radius: 20px 20px 0 0;

      .topLine {
        width: 42px;
        height: 5px;
        background-color: #B4B7BA;
        border-radius: 5px;
        margin-top: 5px;
      }

      .header {
        .closeButton {
          display: none;
        }
      }
    }
  // }
}
