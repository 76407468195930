@import "../../../../../../theme/variables.scss";

.container {

  .navigationWrapper {
    position: sticky;
    top: 70px;
    z-index: 2;
  }

  .loadingWrapper {
    height: calc(100vh - 220px);
    display: grid;
    place-items: center;
  }

  .infoWrapper {
    background-color: #FFE5E5;
    padding: 12px 30px;
    display: flex;
    align-items: center;
    gap: 8px;
  
    >span {
      color: $DANGER_MAIN;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 142.857%;
    }
  }

  .layoutWrapper {

    .errorWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      height: calc(100vh - 280px);    }
  }
}