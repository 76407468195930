.container {
  // background-color: ;
  //   z-index: 1000;
  .header {
    display: flex;
    padding: 16px;
    flex-direction: column;
    gap: 8px;
    position: sticky;
    top: 0;
    background-color: #fff;
    border-radius: 8px 8px 0px 0px;
    z-index: 3;
    .top {
      display: flex;
      align-items: center;
      > div {
        display: flex;
        align-items: center;
        gap: 8px;

        cursor: pointer;
        > div:first-child {
          width: 24px;
          position: relative;
          top: 2px;
        }
        > span:nth-child(2) {
          color: var(--Neutral-70, var(--Color-Neutal-Neutral-70, #757575));

          /* Text L/Reguler */
          // font-family: Poppins;
          font-size: calc(14px + (16 - 14) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 22.4px */
        }
      }
    }

    .middle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
      > span {
        color: #000;
        // font-family: Poppins;
        font-size: calc(20px + (26 - 20) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      > div {
        display: flex;
        align-items: center;
        gap: 16px;
        > div:first-child {
          background-color: #f3f5f7;
          border-radius: 50%;
          //   width: 52px;
          //   height: 52px;
          padding: 8px 12px;
          > img {
            width: 25px;
            height: 30px;
            // object-fit: cover;
          }
        }

        > span {
          color: #000;
          //   font-family: Poppins;
          font-size: calc(14px + (18 - 14) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 500;
          line-height: 150%; /* 27px */
        }
      }
    }

    .bottom {
      > div {
        display: flex;
        align-items: center;
        width: 100%;
        gap: 4px;
        padding: 8px 16px;
        background: var(--Primary-Soft, #e1edfe);
        > span {
          color: var(--Primary-Main, #1571de);
          text-align: center;

          /* Text M/Reguler */
          //   font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
        }
      }
    }
  }

  .contents {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 0px 16px 16px 16px;

    > div:first-child {
      display: flex;
      //   align-items: center;
      justify-content: space-between;
      gap: 16px;
      > div:first-child {
        border-radius: 8px;
        border: 1px solid var(--Color-Neutal-Neutral-40, #e0e0e0);
        padding: 16px;
        display: flex;

        gap: 16px;
        width: 50%;
        > div {
          display: flex;
          flex-direction: column;
          gap: 8px;
          width: 100%;

          > span {
            color: #000;

            /* Text M/Bold */
            // font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px; /* 142.857% */
          }
          .details {
            display: flex;
            flex-direction: column;
            gap: 8px;
            padding: 8px;
            border-radius: 8px;
            background: var(--Color-Neutal-Neutral-20, #f5f5f5);
            height: 100%;
            > div {
              display: flex;
              align-items: center;
              gap: 4px;
              > div {
                > img {
                  width: 20px;

                  height: 20px;
                  border-radius: 20px;
                }
              }

              > span {
                color: var(
                  --Neutral-100,
                  var(--Color-Neutal-Neutral-19, #0a0a0a)
                );
                // font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%; /* 19.6px */
              }
            }
          }
        }
      }

      > div:last-child {
        > div {
          border-radius: 8px;
          background: var(--Primary-Main, #1571de);
          padding: 12px 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
          cursor: pointer;
          &.disabled {
            background: var(--Neutral-40, #e0e0e0);
            > span {
              color: var(--Neutral-60, var(--Color-Neutal-Neutral-60, #9e9e9e));
            }
          }
          > span {
            color: var(--Color-Neutal-Neutral-10, #fff);
            text-align: center;

            /* Text M/Reguler */
            // font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
          }
        }
      }
    }
  }
}

.profileRow {
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  > div:first-child {
    > img {
      width: 36px;
      height: 36px;
      border-radius: 36px;
      object-fit: cover;
    }
  }

  > div:last-child {
    color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));

    /* Text S/Medium */
    // font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 153.846% */
  }
}

.scoresRow {
  color: var(--Primary-Main, #1571de);
  //   font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
  cursor: pointer;
}

@media screen and (max-width: 820px) {
  .container {
    .contents {
      > div:first-child {
        flex-direction: column;
        > div:first-child {
          order: 2;
          width: 100%;
          flex-direction: column;
        }
        > div:last-child {
          order: 1;
        }
      }
    }
  }
}
