@import "../../../../../theme/variables.scss";

.background {
  width: 100vw;
  height: 100%;
  // background-color: $NEUTRAL_OVERLAY;
  //   z-index: 3;
  //   position: fixed;
  //   top: 0;
  //   left: 0;
  //   bottom: 0;
  //   right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;

  .container {
    width: 705px;
    background: $NEUTRAL_10;
    border-radius: 8px;
    // padding-block: 24px;
    // max-height: calc(100vh - 50px);
    // min-height: calc(100vh - 50px);
    height: 596px;
    max-width: calc(100vw - 50px);
    overflow-y: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    // gap: 16px;

    .header,
    .normal,
    .buttonsWrapper {
      padding-inline: 24px;
      border-top: 1px solid #ededed;
    }

    .header {
      display: flex;
      align-items: center;
      padding-top: 24px;
      padding-bottom: 24px;

      h3 {
        display: flex;
        align-items: center;
        flex-grow: 1;

        span {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 150%;
          letter-spacing: 0.005em;
          color: $NEUTRAL_100;
          padding-right: 10px;
        }
      }

      button {
        background-color: transparent;
        border: 0 solid $NEUTRAL-100;
      }

      .searchBarWrapper {
        width: 250px;
      }
    }

    .normal {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      gap: 16px;
      overflow: auto;
      height: 100px;
      padding-top: 16px;
      padding-bottom: 16px;
      .titleBox {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
        > span:first-child {
          color: #000;

          /* Text L/SemiBold */
          // font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 140%; /* 22.4px */
          width: 100%;
        }

        > span:last-child {
          color: var(--Neutral-60, var(--Color-Neutal-Neutral-60, #9e9e9e));

          /* Caption 1/Reguler */
          // font-family: Poppins;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px; /* 133.333% */
          width: 100%;
          text-align: right;
        }
      }
      .onBack {
        display: flex;
        align-items: center;
        gap: 8px;
        cursor: pointer;
        > div {
          width: 16px;
          position: relative;
          top: 1px;
        }
        > span {
          color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));
          text-align: center;

          /* Text M/Reguler */
          // font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
          &.disabled {
            color: #c2c2c2;
          }
        }
      }

      .detailWrapper {
        display: flex;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;

        .detailBox {
          flex-grow: 1;
          display: flex;
          gap: 8px;
          align-items: center;
          justify-content: flex-start;
          width: 100%;

          .iconDash {
            display: flex;
            width: 40px;
            height: 40px;
            padding: 8px;
            background-color: $NEUTRAL_20;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
          }

          .contentDetailBox {
            display: flex;
            width: 100%;
            border-radius: 4px;
            flex-direction: column;
            padding: 8px 16px 8px 16px;
            background-color: $PRIMARY_SURFACE;
            span {
              &:first-child {
                font-family: "Poppins";
                font-size: 12px;
                font-weight: 400;
                color: $NEUTRAL-60;
              }
              &:last-child {
                font-family: "Poppins";
                font-size: 14px;
                font-weight: 500;
                color: $NEUTRAL-100;
              }
            }
          }
        }
      }

      .title {
        display: flex;
        align-items: center;
        gap: 16px;

        > h4 {
          flex-grow: 1;
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 140%;
        }

        > button {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 4px;
          padding: 8px;
          border-radius: 6px;
          background-color: $NEUTRAL-10;
          border: 1px solid $PRIMARY_MAIN;
          color: $PRIMARY_MAIN;

          > span {
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
          }
        }
      }

      .content {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        gap: 8px;

        .titleWrapper {
          display: flex;
          align-items: center;
          padding-top: 16px;

          > h4 {
            flex-grow: 1;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 140%;
          }
        }

        .topBarWrapper {
          display: flex;
          align-items: center;
          gap: 16px;

          .backBox {
            flex-grow: 1;
            display: flex;
            gap: 12px;

            font-size: 14px;
            font-weight: 400;
            color: $PRIMARY_MAIN;
            cursor: pointer;
          }

          .searchBarBox {
            flex-grow: 1;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        h4 {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: $NEUTRAL_100;
          padding-bottom: 8px;
        }

        .tableWrapper {
          height: 100px;
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          overflow-y: scroll !important;

          .tableContainer {
            border: 1px solid #d9d9d9;
            border-radius: 8px;
            flex-grow: 1;

            .table {
              border-collapse: separate;

              .tableHead {
                position: sticky;
                top: 0;
                background-color: $NEUTRAL-10;
                z-index: 1;

                .tableHeadRow {
                  .tableHeadCell {
                    padding: 14px 16px !important;
                  }
                }
              }

              .tableBody {
                .tableRow {
                  &.selected {
                    background-color: $PRIMARY_SURFACE;
                  }

                  .tableCell {
                    padding: 12px 16px !important;

                    .stackedProfile {
                      background-color: transparent;
                      border: none;
                      display: flex;
                      align-items: center;
                      gap: 8px;
                    }
                  }

                  .buttonIcon {
                    display: grid;
                    place-items: center;
                    background-color: transparent;
                    border: none;
                    color: $PRIMARY_MAIN;

                    &.expand {
                      position: relative;
                    }

                    &:disabled {
                      color: $NEUTRAL_60;
                    }

                    > img {
                      height: 24px;
                      aspect-ratio: 1;
                      z-index: 0;
                    }
                  }
                }
              }
            }

            .bottom {
              width: 100%;
              height: 24px;
            }
          }
        }

        .emptyWrapper {
          flex-grow: 1;
          height: 476px;
        }

        .emptyDialogWrapper {
          flex-grow: 1;
          height: 200px;
        }

        .paginationWrapper {
          border-top: 1px solid $NEUTRAL_50;
        }

        .checkBoxWrapper {
          display: grid;
          align-items: center;
          grid-template-columns: 50% 50%;
          gap: 8px;

          .containerSend {
            display: flex;
            align-items: center;
            gap: 16px;
            padding: 12px;
            border: 1px solid $NEUTRAL-40;
            border-radius: 12px;
          }

          .containerSendDisable {
            display: flex;
            align-items: center;
            gap: 16px;
            padding: 12px;
            border: 1px solid $NEUTRAL-40;
            border-radius: 12px;
            background-color: $NEUTRAL-20;
            color: $NEUTRAL_60;
          }

          .containerSendEmailError {
            display: flex;
            // justify-content: center;
            align-items: center;
            color: $PRIMARY_MAIN;
            gap: 8px;
          }
        }
      }
    }

    .buttonsWrapper {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-top: 24px;
      padding-bottom: 24px;
      gap: 8px;

      button {
        flex-grow: 1;
        padding: 8px 16px;
        border-radius: 8px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        transition: all 0.3s;

        &.submitOnly {
          flex-grow: 0;
        }

        &:first-child {
          background-color: $NEUTRAL_10;
          border: 1px solid $PRIMARY_MAIN;
          color: $PRIMARY_MAIN;
        }

        &:last-child {
          background-color: $PRIMARY_MAIN;
          border: none;
          color: $NEUTRAL_10;

          &.process {
            background-color: $PRIMARY_SOFT;
          }

          &:disabled {
            background-color: $PRIMARY_DISABLED;
            border-color: $PRIMARY_DISABLED;
          }
        }
      }
    }

    .errorWrapper {
      display: flex;
      padding: 12px;
      color: $DANGER_MAIN;
      font-size: 14px;
    }
  }
}

@media screen and (max-width: 768px) {
  .background {
    .container {
      width: 100%;
      max-width: unset;
    }
  }
}

@media screen and (max-width: 485px) {
  .background {
    .container {
      .normal {
        .detailWrapper {
          flex-direction: column;
          .detailBox {
            width: 100%;
          }
        }
      }
    }
  }
}
