.container {
  width: 100%;
  min-height: 100%;

  .contentWrapper {
    width: 100%;
    min-height: calc(100vh - 220px);
    z-index: 0 !important;
  }

  .loadingWrapper {
    height: calc(100vh - 85px - 32px);
    display: grid;
    place-items: center;
  }
}