.containerDisabled {
  border-radius: 16px;
  border: var(--Spacing-0, 1px) solid var(--Color-Neutal-Neutral-40, #e0e0e0);
  background: var(--Color-Neutal-Neutral-30, #ededed);
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  //   min-height: 150px;
  > span {
    width: 50%;
    color: var(--Neutral-60, var(--Color-Neutal-Neutral-60, #9e9e9e));

    /* Text M/Medium */
    // font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
  }
  > div {
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    .rangeBox {
      display: flex;
      flex-direction: column;
      gap: 4px;
      > span:first-child {
        color: var(--Neutral-60, var(--Color-Neutal-Neutral-60, #9e9e9e));

        /* Text M/Reguler */
        // font-family: Poppins;
        font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
      }

      > span:last-child {
        color: var(--Neutral-60, var(--Color-Neutal-Neutral-60, #9e9e9e));

        /* Heading 7/SemiBold */
        // font-family: Poppins;
        font-size: calc(16px + (20 - 16) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 600;
        line-height: 150%; /* 30px */
        letter-spacing: 0.1px;
      }
    }

    .linier {
      height: 4px;
      width: 100%;
      border-radius: 4px;
      background: var(--Neutral-20, #f5f5f5);
    }

    .minMaxBox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
      > span {
        color: var(--Neutral-60, var(--Color-Neutal-Neutral-60, #9e9e9e));

        /* Text M/Medium */
        // font-family: Poppins;
        font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
      }
    }
  }
}

.containerActive {
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  border-radius: 16px;
  border: var(--Spacing-0, 1px) solid var(--Color-Neutal-Neutral-40, #e0e0e0);
  background: #f8fbff;
  > span {
    width: 50%;
    color: var(--Neutral-90, var(--Color-Neutal-Neutral-90, #404040));

    /* Text M/Medium */
    // font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
  }
  > div {
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    .rangeBox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
      > div:first-child {
        display: flex;
        flex-direction: column;
        gap: 4px;
        > span:first-child {
          color: var(--Neutral-60, var(--Color-Neutal-Neutral-60, #9e9e9e));

          /* Text M/Reguler */
          // font-family: Poppins;
          font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
        }

        > span:last-child {
          color: var(--Neutral-90, var(--Color-Neutal-Neutral-90, #404040));

          /* Heading 7/SemiBold */
          //   font-family: Poppins;
          font-size: calc(16px + (20 - 16) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 600;
          line-height: 150%; /* 30px */
          letter-spacing: 0.1px;
        }
      }

      > div:nth-child(2) {
        border-radius: 8px;
        border: var(--Spacing-0, 1px) solid var(--Primary-Main, #1571de);
        background: var(--Primary-Surface, #f5f7fe);
        padding: 8px 12px;
        display: flex;
        align-items: center;
        gap: 8px;
        > div:first-child {
          width: 20px;
        }
        > div:nth-child(2) {
          display: flex;
          flex-direction: column;
          gap: 4px;
          > span {
            color: var(--Neutral-60, var(--Color-Neutal-Neutral-60, #9e9e9e));
            text-align: center;

            /* Caption 1/Reguler */
            // font-family: Poppins;
            font-size: calc(
              10px + (12 - 10) * ((100vw - 280px) / (1600 - 280))
            );
            font-style: normal;
            font-weight: 400;
            line-height: 16px; /* 133.333% */
          }

          > div {
            display: flex;
            align-items: center;
            gap: 6px;
            > span:first-child {
              color: var(--Primary-Main, #1571de);

              /* Text M/Medium */
              //   font-family: Poppins;
              font-size: calc(
                12px + (14 - 12) * ((100vw - 280px) / (1600 - 280))
              );
              font-style: normal;
              font-weight: 500;
              line-height: 20px; /* 142.857% */
            }
            > span:nth-child(2) {
              width: 5px;
              height: 5px;
              border-radius: 50px;
              background-color: #000;
            }

            > span:last-child {
              color: var(--Neutral-100, #0a0a0a);

              /* Caption 1/Reguler */
              //   font-family: Poppins;
              font-size: calc(
                10px + (12 - 10) * ((100vw - 280px) / (1600 - 280))
              );
              font-style: normal;
              font-weight: 400;
              line-height: 16px;
            }
          }
        }
      }
    }

    .linier {
      width: 100%;
      padding-right: 10px;
      padding-left: 10px;
    }

    .minMaxBox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
      > span {
        color: var(--Neutral-60, var(--Color-Neutal-Neutral-60, #9e9e9e));

        /* Text M/Medium */
        // font-family: Poppins;
        font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .containerActive {
    flex-direction: column;
    align-items: flex-start;
    > span {
      width: 100%;
    }
    > div {
      width: 100%;
    }
  }
  .containerDisabled {
    flex-direction: column;
    align-items: flex-start;
    > span {
      width: 100%;
    }
    > div {
      width: 100%;
      .rangeBox {
        // > span:first-child {
        //   display: none;
        // }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .containerActive {
    > div {
      .rangeBox {
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        > div:first-child {
          > span:first-child {
            display: none;
          }
        }
        > div:nth-child(2) {
          width: 100%;
        }
      }
    }
  }

  .containerDisabled {
    > div {
      .rangeBox {
        > span:first-child {
          display: none;
        }
      }
    }
  }
}
