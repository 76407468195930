@import "../../../../../theme/variables.scss";

.container {
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  background-color: $NEUTRAL_10;
  max-width: 100vw;
  width: 436px;
  filter: drop-shadow(0px 4px 9px rgba(0, 0, 0, 0.12));
  gap: 16px;
  max-height: 712px;
  position: relative;

  .content {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
    background-color: rgba(235, 255, 232, 1);
    padding: 16px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    .iconWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 54px;
      width: 54px;
      border-radius: 27px;
      background-color: #22970f;

      .icon {
        color: $NEUTRAL_10;
      }
    }

    .textWrapper {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .title {
        font-size: 20px;
        font-weight: 600;
        color: #22970f;
      }

      .text {
        font-size: 14px;
        font-weight: 400;
        color: #404040;
      }
    }
  }

  .formWrapper {
    display: flex;
    flex-direction: column;
    padding: 0 16px 16px 16px;
    gap: 16px;

    .toggleWrapper {
      padding: 8px;
      background-color: $PRIMARY_SOFT;
      border-radius: 8px;
      width: 100%;
      display: flex;
      justify-content: space-between;

      .titleWrapper {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;

        .icon {
          color: $PRIMARY_MAIN;
        }

        .text {
          font-size: 14px;
          font-weight: 500;
          color: $NEUTRAL_100;
        }
      }
    }

    .textareaWrapper {
      width: 100%;
      overflow: auto;
    }

    .buttonBox {
      display: flex;
      flex-direction: column;
      gap: 8px;
      > span {
        font-size: 12px;
        color: red;
      }
      .buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 8px;
        width: 100%;

        .primaryButton {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: $PRIMARY_MAIN;
          padding: 10px 0;
          flex: 1;
          border-radius: 8px;
          border: 1px solid $PRIMARY_MAIN;
          cursor: pointer;

          &:disabled {
            background-color: $PRIMARY_DISABLED;
            border-color: $PRIMARY_DISABLED;
          }

          .text {
            font-size: 14px;
            font-weight: 500;
            color: $NEUTRAL_10;
          }
        }

        .primaryButton:hover {
          background-color: $PRIMARY_HOVER;
        }

        .secondaryButton {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: $NEUTRAL_10;
          padding: 10px 0;
          border-radius: 8px;
          border: 1px solid $NEUTRAL_60;
          cursor: pointer;

          .text {
            font-size: 14px;
            font-weight: 500;
            color: $NEUTRAL_90;
          }
        }

        .secondaryButton:hover {
          background-color: $NEUTRAL_30;
        }
      }
    }
  }
}
