@import "../../../theme/variables.scss";

.container {
  font-family: Poppins;
  color: $DANGER_MAIN;
  font-weight: 500;

  &.hidden {
    opacity: 0;
  }
}