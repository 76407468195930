@import "../../../../../../../theme/variables.scss";
@import "../../../../../../../theme/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .dCity {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}
.dEmpty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  >img {
    height: 59px;
    object-fit: contain;
  }

  >span {
    font-size: 12px;
  }
}
.dCard {
  background-color: $NEUTRAL-20;
  padding: 16px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  >span {
    font-size: 14px;
    font-weight: 500;
  }

  .dContent {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }


}

.duCard {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: $NEUTRAL-10;
  padding: 6px 10px;
  border: 1px solid $NEUTRAL-40;
  border-radius: 8px;

  >img {
    width: 32px;
    aspect-ratio: 1;
    border-radius: 50%;

  }

  >div {
    display: flex;
    flex-direction: column;

    >span {
      font-size: 14px;
    }

    >p {
      font-size: 12px;
      color: $NEUTRAL-60;
    }
  }
}

.pCard {
  border: 1px solid $NEUTRAL-40;
  border-radius: 8px;
  overflow: clip;

  .pHeader {
    background-color: $PRIMARY_SURFACE;
    padding: 16px;
    display: flex;
    align-items: center;
    gap: 8px;

    >span {
      font-size: 20px;
      font-weight: 600;
      color: $PRIMARY_MAIN;
      flex-grow: 1;
    }

    .dedicatedRO {
      display: flex;
      align-items: center;
      gap: 8px;

      >img {
        width: 28px;
        object-fit: contain;
      }

      >span {
        font-size: 14px;
      }

      >p {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }

  .pContent {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .pEach {
      display: flex;
      flex-direction: column;
      gap: 8px;

      >span {
        font-family: 14px;
        font-weight: 500;
      }

      .qContainer {
        border: 1px solid $NEUTRAL-30;
        border-radius: 8px;

        .qGrid {
          display: grid;
          grid-template-columns: 320px 1fr;
          gap: 16px;

        }

        .qHeader {
          background-color: $NEUTRAL-20;
          padding: 16px;

          >span {
            font-size: 14px;
            font-weight: 500;
          }
        }

        .qContent {
          padding: 16px;
          display: flex;
          flex-direction: column;
          gap: 10px;

          .qEach {

            >span {
              font-size: 14px;

              &:nth-child(2) {
                font-weight: 500;
              }
            }
          }

        }
      }

      .cWrapper {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .cHeader {
          display: flex;
          align-items: center;
          gap: 8px;

          >span {
            font-size: 16px;
            font-weight: 600;
          }
        }
      }
    }
  }
}

.aCard {
  background-color: $NEUTRAL-20;
  padding: 16px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  >span {
    font-size: 14px;
    font-weight: 700;
  }

  .aWrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;

    >div {
      display: flex;
      align-items: center;
      gap: 8px;
      justify-content: space-between;

      >span {
        font-size: 14px;

        &:nth-child(2) {
          font-weight: 500;
        }
      }
    }
  }
}