.bodyFifthStep {
  display: flex;
  flex-direction: column;
  padding: 12px 24px 12px 24px;
}

.contentPerformance {
  display: flex;
  flex-direction: column;
  gap: 20px;
  .card {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .titleType {
      color: #000;
      //   font-family: Poppins;
      font-size: calc(16px + (18 - 16) * ((100vw - 280px) / (1600 - 280)));
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    .list {
      display: flex;
      flex-direction: column;
      gap: 12px;
      .listBox {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
        //   width: 90%;
        width: 100%;
        .title {
          color: #000;
          // font-family: Poppins;
          font-size: 13px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          width: 60%;
        }
        .nameBox {
          display: flex;
          align-items: center;

          gap: 16px;
          width: 100%;
          > span {
            color: var(--Color-Neutal-Neutral-19, #0a0a0a);
            font-family: Poppins;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            display: flex;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 520px) {
  .contentPerformance {
    .card {
      .list {
        gap: 24px;
        .listBox {
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
          .title {
            width: 100%;
          }
          .nameBox {
            width: 100%;
            > span:nth-child(1) {
              display: none;
            }
          }
        }
      }
    }
  }
}
