@import "../../../theme/variables.scss";

.container {
  background-color: $NEUTRAL_20;
  height: 100%;
  width: 100%;
  border-radius: 4px;
  border-color: $NEUTRAL_40;
  border-width: 1px;
  border-style: solid;
  padding: 0 8px;
  align-items: center;
}

.container:focus {
  outline: none !important;
  border-width: 1px;
}
