.modalContainer {
  background-color: #fff;
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    > div {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 4px;

      > div {
        display: flex;
        align-items: center;
        gap: 4px;
        .iconCopy:hover {
          color: #1571de;
        }
        > span {
          color: #000;

          /* Caption 1/Reguler */
          //   font-family: Poppins;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px; /* 133.333% */

          &.statusText {
            border-radius: 31px;
            background: #f7ead0;
            display: flex;
            // height: 28px;
            padding: 4px 9px;
            justify-content: center;
            align-items: center;
            color: #d58d00;
          }

          &.pink {
            background: #ffeafd;
            color: #f400a2;
          }

          &.red {
            background: var(--Danger-Surface, #faeff2);
            color: var(--Danger-Main, #ff3e13);
          }
          &.green {
            background: var(--Success-Surface, #eafef3);
            color: var(--Success-Main, #27bb83);
          }
          &.grey {
            background: var(--Color-Neutal-Neutral-30, #ededed);
            color: #b2a8a8;
          }
          &.detail {
            color: #1571de;
            text-decoration: underline;
            cursor: pointer;
            &.disabledLink {
              color: var(--Neutral-60, var(--Color-Neutal-Neutral-60, #9e9e9e));
              text-decoration: none;
            }
          }
        }

        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          cursor: pointer;
          .remark {
            position: absolute;
            top: 20px;
            right: 0;
            width: 200px;
            background-color: #363535;
            border-radius: 4px;
            padding: 10px;
            z-index: 2;
            color: var(--Neutral-10, var(--Color-Neutal-Neutral-10, #fff));

            /* Text S/Reguler */
            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            line-height: 20px; /* 153.846% */
            > span {
              font-weight: 400;
              > span {
                font-weight: 700;
              }
            }
          }
        }
      }
    }
    > span:first-child {
      color: var(--Neutral-70, var(--Color-Neutal-Neutral-70, #757575));

      /* Caption 1/Reguler */
      //   font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 133.333% */
    }

    > span:last-child {
      color: #000;

      /* Caption 1/Reguler */
      //   font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 133.333% */
      &.statusText {
        border-radius: 31px;
        background: #f7ead0;
        display: flex;
        // height: 28px;
        padding: 4px 9px;
        justify-content: center;
        align-items: center;
        color: #d58d00;
      }

      &.red {
        background: var(--Danger-Surface, #faeff2);
        color: var(--Danger-Main, #ff3e13);
      }
      &.green {
        background: var(--Success-Surface, #eafef3);
        color: var(--Success-Main, #27bb83);
      }
      &.grey {
        background: var(--Color-Neutal-Neutral-30, #ededed);
        color: #b2a8a8;
      }
      &.detail {
        color: #1571de;
        text-decoration: underline;
        cursor: pointer;
        &.disabledLink {
          color: var(--Neutral-60, var(--Color-Neutal-Neutral-60, #9e9e9e));
          text-decoration: none;
        }
      }
    }
  }
}
