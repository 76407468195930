@import "../../../../../theme/variables.scss";
.desktopContainer {
  width: 808px;
  display: flex;
  padding: 20px;
  flex-direction: column;
  gap: 50px;

  .Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    border-bottom: 5px solid #1571de;
    padding-bottom: 20px;

    > img {
      height: 41px;
      object-fit: contain;
    }

    .Logo {
      display: flex;
      gap: 16px;
      align-items: center;

      & > :first-child {
        background-color: #f3f5f7;
        width: 56px;
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;

        > img {
          width: 26px;
          height: 38px;
        }
      }

      & > :last-child {
        display: flex;
        flex-direction: column;

        > span {
          font-family: Poppins;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          color: $NEUTRAL_60;
        }

        > p {
          font-weight: 600;
          font-size: 16px;
        }
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    > span:first-child {
      color: #000;
      //   font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-align: center;
    }
    > div:nth-child(2) {
      > img {
        width: 354.305px;
        height: 264px;
      }
    }
    > span:nth-child(3) {
      color: #000;
      text-align: center;
      //   font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-align: center;
    }
  }
}
