@import "../../../../../../theme/variables.scss";

.container {
  .breadcrumbWrapper {
    padding: 24px 30px;
    display: flex;
    align-items: center;
    gap: 24px;
    background-color: $NEUTRAL_LIGHTER;
    border-bottom: 1px solid $NEUTRAL_40;

    > button {
      height: 40px;
      aspect-ratio: 1;
      background-color: $NEUTRAL_10;
      border-radius: 50%;
      border: none;
    }

    span {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;

      &.breadcrumbText {
        color: $NEUTRAL_60;
        cursor: pointer;
      }

      &.breadcrumbTextBold {
        color: $NEUTRAL_100;
      }
    }
  }

  .infoWrapper {
    display: flex;
    align-items: center;
    gap: 24px;
    padding: 8px 30px;
    padding-bottom: 16px;
    background-color: #ffe8e3;

    img {
      width: 42px;
      aspect-ratio: 1;
    }

    .info {
      h3 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: 0.005em;
        color: $DANGER_MAIN;
        padding-bottom: 4px;
      }

      span {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $NEUTRAL_DARKEST;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    .breadcrumbWrapper {
      padding: 16px 18px !important;
    }

    .infoWrapper {
      padding-inline: 18px;
      gap: 16px;
    }
  }
}
