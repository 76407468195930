@import "../../../../theme/variables.scss";

.container {
  background-color: $NEUTRAL-10;
  border-radius: 8px;
  width: 662px;
  max-width: 100vw;
  max-height: 85vh;
  overflow: auto;

  .header {
    padding: 16px;
    padding-bottom: 8px;
    display: flex;
    align-items: center;
    gap: 16px;
    border: 1px solid $NEUTRAL-30;
    position: sticky;
    top: 0;
    background-color: $NEUTRAL-10;
    z-index: 1;

    > img {
      width: 32px;
      object-fit: cover;
    }

    .middle {
      padding-bottom: 4px;
      flex-grow: 1;

      .mTop {
        display: flex;
        align-items: center;
        gap: 8px;

        > h3 {
          flex-grow: 1;
          font-size: 16px;
          font-weight: 600;
        }

        > button {
          background-color: transparent;
          border: none;
        }
      }

      .mBottom {
        display: flex;
        align-items: center;
        gap: 16px;

        > p {
          display: flex;
          align-items: center;
          gap: 8px;
          font-size: 14px;

          > span {
            font-weight: 700;
          }
        }

        .status {
          padding: 4px 10px;
          border-radius: 40px;
          text-align: center;
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 142.857%;
          border: 1px solid transparent;
          width: 100px;

          &.verified {
            background-color: $SUCCESS_SURFACE;
            border-color: $SUCCESS_MAIN;
            color: $SUCCESS_MAIN;
          }

          &.waiting {
            background-color: $ACTIVE_SURFACE;
            border-color: #ee962f;
            color: #ee962f;
          }
        }
      }
    }
  }

  .content {
    padding: 16px;
    min-height: 300px;

    .firstLoadLoading {
      height: 300px;
      display: grid;
      place-items: center;
    }

    .empty {
      height: 300px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-bottom: 24px;

      > img {
        width: 50px;
        aspect-ratio: 100%;
      }

      > span {
        &:nth-child(2) {
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
          display: block;
          padding-top: 16px;
          padding-bottom: 2px;
        }

        &:last-child {
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          color: $NEUTRAL_60;
        }
      }
    }

    .logs {
      display: flex;
      flex-direction: column;
      padding-inline-start: 20px;

      .each {
        display: flex;
        align-items: center;
        padding: 12px 16px;

        border-left: 1px dashed $NEUTRAL_50;
        position: relative;

        &:first-child {
          padding-top: 0px;

          .overflowHider {
            width: 3px;
            height: 2px;
            background-color: $NEUTRAL_10;
            position: absolute;
            left: -2px;
            top: 0;
            z-index: 1;
          }

          .bullet {
            top: 2px;
          }
        }

        &:last-child {
          padding-bottom: 0px;
          // border-left: none;

          .overflowHider {
            width: 3px;
            // height: calc(100% - 30px);
            height: 2px;
            background-color: $NEUTRAL_10;
            position: absolute;
            left: -2px;
            bottom: 0;
            z-index: 1;
          }
        }

        .bullet {
          width: 15px;
          height: 15px;
          border-radius: 50%;
          background-color: $PRIMARY_MAIN;
          border: 3px solid #d9eaff;
          position: absolute;
          left: -8px;
          top: 15px;
        }

        .main {
          flex-grow: 1;

          > div {
            // display: flex;

            &:first-child {
              display: flex;
              // align-items: center;
              justify-content: space-between;
              gap: 8px;
              padding-bottom: 4px;
              .remark {
                color: $PRIMARY_MAIN;

                /* Text M/Medium */
                // font-family: Poppins;
                font-size: calc(
                  12px + (14 - 12) * ((100vw - 280px) / (1600 - 280))
                );
                font-style: normal;
                font-weight: 500;
                line-height: 20px; /* 142.857% */
                > span {
                  font-weight: 700;
                }
              }

              // > span {
              //   font-size: 14px;
              //   font-weight: 500;
              //   color: $PRIMARY_MAIN;
              // }

              > p {
                white-space: nowrap;
                font-size: 12px;
                color: $NEUTRAL-60;
              }
            }

            &:nth-child(2) {
              padding-bottom: 8px;

              .descBox {
                display: flex;
                flex-direction: column;
                gap: 4px;
                > span {
                  color: var(--Neutral-70, #757575);

                  /* Ception 2/Regular */
                  // font-family: Poppins;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 16px; /* 133.333% */
                  > span {
                    color: var(--Neutral-70, #757575);

                    /* Ception 2/Regular */
                    // font-family: Poppins;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 800;
                    line-height: 16px; /* 133.333% */
                    // color: red;
                  }
                }
              }

              .desc {
                display: flex;
                flex-direction: column;
                gap: 4px;
                > span {
                  color: var(--Neutral-70, #757575);

                  /* Ception 2/Regular */
                  // font-family: Poppins;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 16px; /* 133.333% */
                  display: flex;
                  // gap: 4px;

                  > span:first-child {
                    min-width: 50px;
                  }
                  > span:last-child {
                    width: 100%;
                  }
                }
              }
            }

            &:nth-child(3) {
              display: flex;
              // align-items: center;
              gap: 4px;
              border-top: 1px solid #ededed;
              padding-top: 8px;
              // padding-left: 20px;
              > span:first-child {
                color: var(--Color-Neutal-Neutral-70, #757575);
                // font-family: Poppins;
                font-size: calc(
                  10px + (12 - 10) * ((100vw - 280px) / (1600 - 280))
                );
                font-style: italic;
                font-weight: 400;
                line-height: 16px; /* 123.077% */
                min-width: 70px;
                width: 70px;
              }
              > span:nth-child(2) {
                color: var(
                  --Neutral-100,
                  var(--Color-Neutal-Neutral-19, #0a0a0a)
                );
                // font-family: Poppins;
                font-size: calc(
                  10px + (12 - 10) * ((100vw - 280px) / (1600 - 280))
                );
                font-style: normal;
                font-weight: 700;
                line-height: 16px;
                // width: 100%;
                min-width: 10px;
              }
              > span:nth-child(3) {
                color: var(
                  --Neutral-100,
                  var(--Color-Neutal-Neutral-19, #0a0a0a)
                );
                // font-family: Poppins;
                font-size: calc(
                  10px + (12 - 10) * ((100vw - 280px) / (1600 - 280))
                );
                font-style: normal;
                font-weight: 700;
                line-height: 16px;
                width: 100%;
              }
            }
          }
        }
      }
    }

    .seeMoreWrapper {
      display: flex;
      align-items: center;
      justify-content: center;

      > button {
        display: flex;
        align-items: center;
        background-color: transparent;
        border: none;
        color: $NEUTRAL-60;
        font-size: 14px;

        &:hover {
          color: $NEUTRAL-100;
        }
      }
    }

    .isLoadingMore {
      display: flex;
      align-items: center;
      justify-content: center;

      > span {
        display: flex;
        align-items: center;
        color: $NEUTRAL-60;
        font-size: 14px;
      }
    }
  }
}
