@import "../../../theme/variables.scss";

.container {
  display: flex;
  width: 100vw;
  height: 100vh;
  background-color: $NEUTRAL_LIGHTER;
  position: relative;
}

.smallLogo {
  height: 42px;
  object-fit: contain;
}
.iconDash {
  width: 20px;
}

// temporary
.iconExpand {
  width: 20px;
  position: fixed;
  right: 0;
  margin-right: 24px;
}

.Popover {
  label {
    // display: block;
    display: none;
    width: 100%;
    border-bottom: 1px solid $NEUTRAL_30;
    padding-bottom: 6px;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $NEUTRAL_70;
  }

  div {
    &.Stacked {
      display: flex;
      align-items: center;
      gap: 10px;
      cursor: pointer;

      &:hover {
        span {
          font-weight: 500;
        }
      }

      span {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        padding-block: 12px;
      }

      &:nth-child(2) {
        color: $NEUTRAL_80;
        border-bottom: 1px solid $NEUTRAL_30;
        padding-top: 0;
      }

      &:nth-child(3) {
        padding-bottom: 0;

        span {
          color: $DANGER_MAIN;
        }
      }
    }
  }
}

.subMenuWrapper {
  margin-left: 15px;

  .subNav {
    display: flex;
    align-items: center;
    position: relative;

    .mainBranch {
      border-left: 2px solid #f3f3f3;
      position: absolute;
      top: 0;
      bottom: 50%;
    }

    .treeBranch {
      background-color: #f3f3f3;
      width: 9px;
      height: 2px;
    }
  }
}

.chatBox {
  background-color: $NEUTRAL_20;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .logo {
    height: 60px;
    aspect-ratio: 1;
    background-color: $PRIMARY_SOFT;
    border-radius: 50%;
    display: grid;
    place-items: center;

    img {
      height: 32px;
      aspect-ratio: 1;
      object-fit: cover;
    }
  }

  h4 {
    padding-top: 16px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    color: $PRIMARY_MAIN;
  }

  p {
    padding-top: 4px;
    padding-bottom: 8px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    color: $NEUTRAL_60;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 8px 16px;
    border: 1px solid $PRIMARY_MAIN;
    background-color: transparent;
    border-radius: 8px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $PRIMARY_MAIN;
    transition: all ease-in-out 0.3s;

    &:hover {
      background-color: #f1f3ff95;
      font-weight: 500;
    }
  }
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  background-color: $NEUTRAL_OVERLAY;
  opacity: 0;
  z-index: 4;

  &.Visible {
    right: 0;
    bottom: 0;
    opacity: 1;
    transition: opacity 0.4s;
  }
}

.burgerWrapper {
  display: none;
}

.burgerWrapper2 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  z-index: -1;
}

.content {
  margin-left: 76px;
  width: 100%;
  min-height: calc(100vh - 70px);
  height: 100%;
  overflow-y: auto;
  background-color: $NEUTRAL_BACKGROUND;
  &.contentClient {
    margin-left: 0;
  }
}

.sideBarDefault {
  min-width: 76px;
  width: 76px;
  height: 100%;
  background-color: $NEUTRAL_LIGHTEST;
  display: flex;
  flex-direction: column;
  transition: 300ms;
  position: absolute;
  z-index: 6;
  .logoWrapper {
    padding: 8px 20px 50px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.controlMobile {
      display: none;
    }

    &.controlWeb {
      display: flex;
    }

    .iconWrapper {
      display: flex;
      align-items: center;
      // padding: 16px 24px;
      width: 27px;
      height: 60px;

      img {
        // flex-shrink: 0;
        cursor: pointer;
      }
    }

    img {
      // height: 42px;
      // width: 102px;

      &.iconOnly {
        display: none;
      }
    }
  }

  .navControl {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
    padding-left: 16px;
    padding-right: 20px;
  }

  .settingWrapper {
    @extend .navControl;
    padding-bottom: 50px;
  }

  .navWrapper {
    @extend .navControl;
    // padding: 1;

    flex: 1;
  }

  .navButton {
    width: 100%;
    min-width: 0;
    border: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 11px;
    border-radius: 8px;
    gap: 10.83px;
    cursor: pointer;
    transition: 300ms;
    background-color: $NEUTRAL_LIGHTEST;

    &:hover {
      background-color: #f1f3ff95;
    }

    &:disabled {
      cursor: default;
    }

    span {
      font-weight: 500;
      color: $NEUTRAL_70;
      transition: all 300ms;
      min-width: 200px;
      // max-width: 281px;
      // max-width: 200px;
      display: flex;
      justify-content: flex-start;
      // justify-content: flex-start;
    }

    &.active {
      background-color: #f1f3ff;

      span {
        color: $PRIMARY_MAIN;
        transition: all 300ms;
      }
    }
  }

  .navActive {
    transition: 300ms;

    button {
      background-color: #f1f3ff !important;
    }

    span {
      transition: all 300ms;
      color: $PRIMARY_MAIN;
    }
  }
}

.sideBarDefault:hover {
  width: 281px;
  transition: 300ms;
  box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(12.649999618530273px);
  .logoWrapper {
    padding: 8px 20px 50px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.controlMobile {
      display: none;
    }

    &.controlWeb {
      display: flex;
    }

    .iconWrapper {
      display: flex;
      align-items: center;
      // padding: 16px 24px;
      width: 27px;
      height: 60px;

      img {
        // flex-shrink: 0;
        cursor: pointer;
      }
    }

    img {
      // height: 42px;
      // width: 102px;

      &.iconOnly {
        display: none;
      }
    }
  }

  .navControl {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
    padding-left: 16px;
    padding-right: 20px;
  }

  .settingWrapper {
    @extend .navControl;
    padding-bottom: 50px;
  }

  .navWrapper {
    @extend .navControl;
    flex: 1;
  }

  .navButton {
    width: 100%;
    min-width: 0;
    border: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 11px;
    border-radius: 8px;
    gap: 10.83px;
    cursor: pointer;
    transition: 300ms;
    background-color: $NEUTRAL_LIGHTEST;

    &:hover {
      background-color: #f1f3ff95;
    }

    &:disabled {
      cursor: default;
    }

    span {
      font-weight: 500;
      color: $NEUTRAL_70;
      transition: all 300ms;
    }

    &.active {
      background-color: #f1f3ff;

      span {
        color: $PRIMARY_MAIN;
        transition: all 300ms;
      }
    }
  }

  .navActive {
    transition: 300ms;

    button {
      background-color: #f1f3ff !important;
    }

    span {
      color: $PRIMARY_MAIN;
      transition: all 300ms;
    }
  }
}

.sideBar {
  min-width: 281px;
  width: 281px;
  height: 100%;
  background-color: $NEUTRAL_LIGHTEST;
  display: flex;
  flex-direction: column;
  transition: 200ms;

  .logoWrapper {
    padding: 20px 20px 50px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.controlMobile {
      display: none;
    }

    &.controlWeb {
      display: flex;
    }

    img {
      height: 42px;
      width: 102px;
      cursor: pointer;

      &.iconOnly {
        display: none;
      }
    }
  }

  .navControl {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
    padding-left: 30px;
    padding-right: 20px;
  }

  .settingWrapper {
    @extend .navControl;
    padding-bottom: 50px;
  }

  .navWrapper {
    @extend .navControl;
    flex: 1;
  }

  .navButton {
    width: 100%;
    min-width: 0;
    border: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 11px;
    border-radius: 8px;
    gap: 10.83px;
    cursor: pointer;
    transition: 300ms;
    background-color: $NEUTRAL_LIGHTEST;

    &:hover {
      background-color: #f1f3ff95;
    }

    &:disabled {
      cursor: default;
    }

    span {
      font-weight: 500;
      color: $NEUTRAL_70;
    }

    &.active {
      background-color: #f1f3ff;

      span {
        color: $PRIMARY_MAIN;
      }
    }
  }

  .navActive {
    transition: 300ms;

    button {
      background-color: #f1f3ff !important;
    }

    span {
      color: $PRIMARY_MAIN;
    }
  }
}

.topBarWrapper {
  height: 70px;
  background-color: $NEUTRAL_LIGHTEST;
  flex: 1;
  position: sticky;
  display: flex;
  align-items: center;
  top: 0;
  z-index: 3;
}

.topBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: 30px;
  width: 100%;
  // max-width: 1440px;
  margin: auto;

  // border-bottom: 1px solid $NEUTRAL_30;
  // opacity: 1;

  // &.hide {
  //   opacity: 0;
  // }
}

.topControl {
  display: flex;
  gap: 24px;
  align-items: center;

  &.controlMobile {
    display: none;
  }

  h2 {
    font-weight: 600;
    text-transform: capitalize;
  }

  .profile {
    display: flex;
    align-items: center;
    gap: 8px;

    img {
      height: 38px;
      aspect-ratio: 1;
      border-radius: 50%;
      object-fit: cover;
    }

    .userTag {
      display: flex;
      flex-direction: column;

      span {
        line-height: 16px;
        text-transform: capitalize;
      }

      span:first-child {
        font-size: calc(12em / 14);
        font-weight: 500;
      }

      span:last-child {
        font-size: calc(11em / 14);
        color: #c2c2c2;
      }
    }
  }
}

.verLine {
  width: 1px;
  height: 28px;
  background-color: $NEUTRAL_20;
}

// @media screen and (max-width: 768px) and (min-width: 481px) {

//   .sideBar {
//     width: 100px;

//     .logoWrapper {
//       padding: 0;
//       display: flex;
//       justify-content: center;
//       align-items: center;
//       padding: 20px 0 30px 0;

//       img {
//         height: 35px;
//         width: 35px;

//         &:not(.iconOnly) {
//           display: none;
//         }

//         &.iconOnly {
//           display: block;
//         }
//       }
//     }

//     .navButton {
//       justify-content: center;

//       span {
//         display: none;
//       }
//     }

//     .navControl {
//       padding-left: 20px;
//     }
//   }
// }

@media screen and (max-width: 768px) {
  .content {
    margin-left: 0;
    transition: 200ms;
    border-radius: 0;
  }
  .burgerWrapper {
    z-index: 1 !important;
    display: grid;
    place-items: center;
  }

  .topBar {
    flex-direction: row-reverse;
  }

  .chatBox {
    margin-top: 24px;
  }

  .logoWrapper {
    &.controlMobile {
      display: flex !important;

      .topControlMobile {
        display: flex;
        align-items: center;
        gap: 24px;
        flex-direction: row-reverse;

        .profile {
          img {
            height: 38px;
            width: 38px;
            aspect-ratio: 1;
            object-fit: cover;
            border-radius: 50%;
          }
        }
      }
    }

    &.controlWeb {
      display: none !important;
    }
  }

  .topControl {
    display: flex;
    flex-direction: row-reverse;

    h2 {
      display: none;
    }

    &.controlMobile {
      display: flex;
    }

    &.controlWeb {
      display: none;
    }
  }

  .sideBar {
    flex-direction: column;
    width: 100vw !important;
    height: auto;
    position: fixed;
    // left: 0;
    // right: 0;
    z-index: 5;
    transition: all 0.4s ease-in-out !important;
    border-radius: 0 0 8px 8px;
    padding-bottom: 0;

    .logoWrapper {
      display: flex;
      padding-bottom: 20px;
      padding-inline: 30px;
    }

    &.opened {
      top: 0;
    }

    &.closed {
      top: -1000px;
    }

    .settingWrapper {
      padding-bottom: 30px;

      // button {
      //   display: flex;
      //   align-items: center;
      //   justify-content: center;
      // }
    }

    .navButton {
      justify-content: flex-start;
    }

    .navControl {
      padding-left: 20px;
    }
  }
}
