.container {
  background-color: #fff;
  width: 453px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 8px;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    border-bottom: 1px solid #ededed;
    padding-bottom: 16px;
    > span {
      //   overflow: hidden;
      color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));
      //   text-overflow: ellipsis;

      /* Heading 6/SemiBold */
      //   font-family: Poppins;
      font-size: calc(16px + (20 - 16) * ((100vw - 280px) / (1600 - 280)));
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 33.6px */
      letter-spacing: 0.12px;
    }
    .cross {
      cursor: pointer;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .warning {
      display: flex;
      align-items: center;
      gap: 4px;
      padding: 8px;
      border-radius: 8px;
      background: var(--Primary-Soft, #e1edfe);
      > div {
        width: 16px;
      }
      > span {
        color: var(--Primary-Main, #1571de);

        /* Text M/Medium */
        //   font-family: Poppins;
        font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
      }
    }

    .inputBox {
      display: flex;
      flex-direction: column;
      gap: 4px;
      > span {
        color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));
        // font-family: Poppins;
        font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
        > span {
          color: var(--Danger-Main, #ff3e13);
          //   font-family: Poppins;
          font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 142.857% */
        }
      }
    }

    .buttonSection {
      display: flex;
      flex-direction: column;
      gap: 4px;
      > span {
        font-size: 12px;
        color: red;
      }
      .button {
        display: flex;
        align-items: center;
        gap: 4px;
        width: 100%;
        > div:first-child {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 8px;
          border: 1px solid var(--Primary-Main, #1571de);
          background: var(--Color-Neutal-Neutral-10, #fff);
          padding: 12px;
          color: var(--Primary-Main, #1571de);
          text-align: center;

          /* Text M/Reguler */
          //   font-family: Poppins;
          font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
          cursor: pointer;
        }

        > div:last-child {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 12px;
          border-radius: 8px;
          background: var(--Primary-Main, #1571de);
          color: var(--Color-Neutal-Neutral-10, #fff);
          text-align: center;

          /* Text M/Reguler */
          //   font-family: Poppins;
          font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
          cursor: pointer;
          &.loading {
            background-color: #8ab8ef;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .container {
    border-radius: 8px 8px 0px 0px;
  }
}
