@import "../../../../../theme/variables.scss";

.container {
  position: relative;

  .autoScroll {
    display: hidden;
    position: absolute;
    top: 0;
  }

  >h3 {
    @include padding-top(24, 18, 18px);
    padding-bottom: 30px;
    background-color: $NEUTRAL-10;
    z-index: 1;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .content {
    display: grid;
    grid-template-columns: 358px 1fr;
    gap: 16px;

    .left {
      display: flex;
      flex-direction: column;
    }

    .right {
      .card {
        display: flex;
        flex-direction: column;
        @include gap(20, 16, 16px);

        >span {
          font-family: Poppins;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }

      .grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 15px;
        @include row-gap(20, 16, 16px);
      }
    }

  }

}


.logoCard {
  width: 100%;
  position: sticky;
  background-color: $NEUTRAL-20;
  aspect-ratio: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 20px;
  text-align: center;
  gap: 8px;
  padding: 50px 10px;

  >img {
    width: 100%;
    max-height: 210px;
    object-fit: contain;
  }

  .buttonWrapper {

    >button {
      border: none;
      background-color: $PRIMARY_MAIN;
      color: $NEUTRAL-10;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;
      padding: 12px 32px;
      border-radius: 50px;
    }

  }

}

.emptyPlaceholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 358px;
  max-width: 468px;
  margin: auto;
  text-align: center;

  >img {
    width: 100%;
    max-height: 240px;
    object-fit: contain;
  }

  >span {
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    padding-bottom: 5px;
  }

  >p {
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: $NEUTRAL_60;
  }
}

.deleteButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: #FFE0E0;
  border: none;
  border-radius: 8px;
  color: #D90707;
  padding: 8px 10px;

  >span {
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 171.429%;
  }
}

@media screen and (max-width: 768px) {

  .container {
    padding-top: 16px;

    >h3 {
      height: 0px;
      position: absolute;
      top: 0 !important;
      z-index: -1;
      opacity: 0;
    }

    .content {
      grid-template-columns: 1fr;

      .left {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
      }

      .right {
        .grid {
          grid-template-columns: 1fr;
        }
      }
    }

  }


  .logoCard {
    aspect-ratio: unset;
    background-color: $NEUTRAL-10;
    border-radius: 0;
    border-top: 1px solid $NEUTRAL-30;
    padding: 15px 18px;
    padding-bottom: 60px;
    width: 100%;

    >img {
      display: none;
    }

    .buttonWrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  .emptyPlaceholder {
    >span {
      padding-top: 40px;
    }
  }
}