@import "../../../../../../theme/variables.scss";

.profileSection {
  background: $NEUTRAL_LIGHTEST;
  border-radius: 8px;
  padding: 24px;

  .header {
    display: flex;
    align-items: center;
    padding-bottom: 16px;

    h3 {
      flex-grow: 1;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      color: $NEUTRAL_100;
    }

    button {
      padding: 0;
      height: 18px;
      background-color: transparent;
      color: $PRIMARY_MAIN;
      border: none;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .boxProfile {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;

      h4 {
        width: 100%;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $NEUTRAL_70;
      }

      p {
        width: 100%;
        text-align: right;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: $NEUTRAL_100;

        &.website {
          &:hover {
            color: $PRIMARY_MAIN !important;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .profileSection {
    padding: 16px;
  }
}

@media screen and (max-width: 480px) {
  .profileSection {
    padding: 16px;
    gap: 8px;

    .boxProfile {
      flex-direction: column;

      p {
        text-align: left!important;
      }
    }
  }
}
