@import "../../../../../../../../theme/variables.scss";

.container {
  padding: 16px 24px;

  .sectionWrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    .childSection {
      background: $NEUTRAL_20;
      padding: 16px;
      margin-top: -8px;
    }
    .section {
      .headTitle {
        font-weight: 700;
        font-size: 20px;
        color: $NEUTRAL_100;
        margin-bottom: 16px;
      }
      .sectionTitle {
        font-weight: 600;
        font-size: 16px;
        color: $NEUTRAL_100;
        padding-bottom: 10px;
      }
      .threeRows {
        grid-template-columns: 1fr 1fr 1fr;
      }
      .twoRows {
        grid-template-columns: 1fr 1fr;
      }
      .contentWrapper {
        display: grid;
        gap: 16px;
        width: 100%;
        // flex-wrap: wrap;
        .childContent {
          background: $NEUTRAL_10 !important;
        }
        .content {
          border-radius: 4px;
          background: $NEUTRAL_20;
          width: 100%;
          padding: 8px 12px;
          .contentTitle {
            font-weight: 400;
            font-size: 12px;
            color: $NEUTRAL_60;
            padding-bottom: 4px;
          }
          .contentBody {
            font-weight: 500;
            font-size: 14px;
            color: $NEUTRAL_100;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    .childSection {
      margin-top: -20px !important;
    }
    .contentWrapper {
      grid-template-columns: 1fr !important;
      gap: 4px !important;
    }
    .headTitle {
      margin-bottom: 8px !important;
    }
  }
}
