@import "../../../../../../theme/variables.scss";

.container {
  padding: 24px 30px;

  .header {
    padding: 16px;
    background-color: $NEUTRAL_10;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .stackedInfo {
      display: flex;
      flex-direction: column;
      gap: 10px;

      > span {
        font-family: "Poppins";
        font-style: normal;
        color: $NEUTRAL_DARKEST;

        &:first-child {
          font-weight: 500;
          font-size: 16px;
          line-height: 140%;
        }

        &:last-child {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;

          &.bold {
            font-weight: 700;
          }
        }
      }

      .address {
        display: flex;
        flex-direction: column;
        gap: 4px;

        span {
          &:first-child {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 140%;
          }

          &:last-child {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
    }
  }

  .content {
    padding-top: 16px;

    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}

@media screen and (max-width: 768px) {
  .container {
    padding: 16px 18px;

    .header {
      .stackedInfo {
        > span {
          &:first-child {
            font-weight: 500;
            font-size: 16px;
            line-height: 140%;
          }

          &:last-child {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;

            &.bold {
              font-weight: 700;
            }
          }
        }

        .address {
          display: flex;
          flex-direction: column;
          gap: 4px;

          span {
            &:first-child {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 700;
              font-size: 16px;
              line-height: 140%;
            }

            &:last-child {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
            }
          }
        }
      }
    }

    .content {
      padding-top: 24px;
    }
  }
}
