.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 16px;
  border-radius: 16px;
  border: 1px solid var(--Color-Neutal-Neutral-40, #e0e0e0);
  background: #f8fbff;

  > div {
    display: flex;
    // align-items: center;
    justify-content: space-between;
    gap: 16px;
    > span:first-child {
      color: var(--Neutral-60, var(--Color-Neutal-Neutral-60, #9e9e9e));

      /* Text M/Medium */
      //   font-family: Poppins;
      font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
      width: 100%;
    }

    > span:last-child {
      width: 100%;
      color: var(--Neutral-90, var(--Color-Neutal-Neutral-90, #404040));
      text-align: right;

      /* Text L/SemiBold */
      //   font-family: Poppins;
      font-size: calc(14px + (16 - 14) * ((100vw - 280px) / (1600 - 280)));
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 22.4px */
    }
  }
}

.containerDataNull {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  border: 1px solid var(--Color-Neutal-Neutral-40, #e0e0e0);
  background: #f8fbff;
  padding: 16px;
  > div {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    > div {
      img {
        width: 74.949px;
        height: 67.999px;
      }
    }

    > span {
      color: var(--Neutral-50, var(--Color-Neutal-Neutral-50, #c2c2c2));
      text-align: center;

      /* Text M/Reguler */
      //   font-family: Poppins;
      font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
    }
  }
}

@media screen and (max-width: 668px) {
  .container {
    > div {
      flex-direction: column;
      align-items: flex-start;
      > span:last-child {
        text-align: left;
      }
    }
  }
}
