@import "../../../theme/variables.scss";

.container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.title {
  // padding-bottom: 4px;
  font-size: 13px;
  font-weight: 600;
  color: $NEUTRAL_100;

  span {
    color: $DANGER_MAIN;
  }
}
