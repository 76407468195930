.overlay {
  display: flex;
  justify-content: center;
  align-items: center;

  .container {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 3px solid rgba(195, 195, 195, 0.6);
    border-radius: 50%;
    border-top-color: #636767;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
    &.containerContract {
      width: 40px;
      height: 40px;
      border: 6px solid rgba(195, 195, 195, 0.6);
      border-top-color: #1571de;
    }
    @keyframes spin {
      to {
        -webkit-transform: rotate(360deg);
      }
    }
    @-webkit-keyframes spin {
      to {
        -webkit-transform: rotate(360deg);
      }
    }
  }
}
