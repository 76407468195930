@import "../../../../theme/variables.scss";

.container {
  // max-width: 950px;
  position: relative;

  .notifWrapper {
    display: flex;
    gap: 16px;
    align-items: center;
    width: 100%;
    padding: 16px;
    border: 1px solid #fc86a2;
    background: $DANGER_SURFACE;
    border-radius: 8px;
    margin-bottom: 24px;
    .logoWrapper {
      display: flex;
      gap: 8px;
      align-items: center;
      > img {
        width: 24px;
        height: 24px;
      }
      > p {
        font-weight: 600;
        font-size: 16px;
        color: $DANGER_MAIN;
      }
    }
    > p {
      font-weight: 400;
      font-size: 14px;
    }
  }

  .topWrapper {
    .header {
      display: flex;
      align-items: center;
      gap: 20px;

      > div {
        height: 75px;
        aspect-ratio: 1;
        display: grid;
        place-items: center;
        border-radius: 50%;
        background-color: #f3f5f7;

        > img {
          height: 50px;
          aspect-ratio: 1;
          object-fit: contain;
        }
      }

      > span {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 150%;
        color: $NEUTRAL_DARKEST;
      }
    }

    .dateWrapper {
      padding-block: 20px;
      text-align: right;

      span {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: $NEUTRAL_DARKEST;
      }
    }
  }

  .contentWrapper {
    h3 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 150%;
      color: $NEUTRAL_DARKEST;
      text-align: center;
      padding-bottom: 58px;
    }

    div {
      span,
      p,
      a {
        font-family: "Poppins";
        font-style: normal;
        font-size: 14px;
        line-height: 150%;
        color: $NEUTRAL_DARKEST;

        &.normal {
          font-weight: 400;
        }

        &.bold {
          font-weight: 700;
        }
      }

      .link {
        color: $PRIMARY_MAIN;
        cursor: pointer;
      }

      &.to {
        padding-bottom: 24px;
      }

      &.intro {
        padding-bottom: 13px;
      }

      &.details {
        display: flex;
        flex-direction: column;
        gap: 13px;
        padding-bottom: 13px;

        .stacked {
          display: flex;
          .address {
            display: flex;
            flex-direction: column;
            gap: 2px;
            > :first-child {
              font-weight: 700;
              font-size: 14px;
            }
            & > :last-child {
              max-width: 472px;
              font-family: "Poppins";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 150%;
            }
            .interviewLink {
              color: #1571de;
              text-decoration: underline;
              cursor: pointer;
            }
          }

          > * {
            &:first-child {
              width: 60px;
            }

            &:nth-child(2) {
              padding-inline: 24px;
            }
          }
        }
      }

      &.outro {
        padding-bottom: 24px;
      }

      &.closure {
        padding-bottom: 72px;
      }
    }
  }

  .RemarksNote {
    background-color: $NEUTRAL_20;
    padding: 16px;
    border-radius: 8px;
    margin-top: 16px;
    margin-bottom: 24px;
    display: flex;
    gap: 10px;
    > img {
      width: 24px;
      height: 24px;
    }
    > .RemarksText {
      > :first-child {
        font-weight: 500;
        font-size: 14px;
      }
      > :last-child {
        font-weight: 400;
        font-size: 14px;
      }
    }
  }

  .notesWrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .section {
      display: flex;
      flex-direction: column;
      gap: 10px;

      h4 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: $NEUTRAL_DARKEST;
      }

      .stacked {
        display: flex;
        align-items: flex-start;
        gap: 10px;
        color: $NEUTRAL_DARKEST;

        > *:first-child {
          flex-shrink: 0;
        }
        .address {
          display: flex;
          flex-direction: column;
          gap: 2px;
          > :first-child {
            font-weight: 500;
            font-size: 14px;
          }
          & > :last-child {
            max-width: 472px;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 150%;
            color: #1571de;
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
    }
  }

  .errorWrapper {
    padding-top: 60px;
    padding-bottom: 60px;
    font-size: 16px;
    color: $DANGER_MAIN;
    opacity: 0;

    &.error {
      opacity: 1;
    }
  }

  .actionsWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    // flex-wrap: wrap;
    // align-items: center;

    padding-bottom: 34px;

    > * {
      width: 100%;
      padding: 12px;
      border-radius: 8px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;

      .red {
        color: $DANGER_MAIN;
      }

      &:hover {
        font-weight: 500;
      }

      &:first-child,
      &:nth-child(2) {
        background-color: $NEUTRAL_10;
        border: 1px solid $NEUTRAL_100;
        color: $NEUTRAL_100;
      }

      &:last-child {
        background-color: $PRIMARY_MAIN;
        border: 1px solid $PRIMARY_MAIN;
        color: $NEUTRAL_10;

        &:hover {
          box-shadow: $BOX_SHADOW_INPUT_ONFOCUS;
        }

        &:disabled {
          background-color: $PRIMARY_DISABLED;
          border-color: $PRIMARY_DISABLED;
        }
      }
    }
  }

  .Hidden {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .container {
    // max-width: 100%;

    .topWrapper {
      padding-bottom: 58px;

      .header {
        display: flex;
        align-items: center;
        gap: 10px;

        > div {
          height: 32px;
          aspect-ratio: 1;
          display: grid;
          place-items: center;
          border-radius: 50%;
          background-color: #f3f5f7;

          > img {
            height: 20px;
            aspect-ratio: 1;
            object-fit: contain;
          }
        }

        > span {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 150%;
          color: $NEUTRAL_DARKEST;
        }
      }

      .dateWrapper {
        display: none;
        padding-block: 20px;
        text-align: right;

        span {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 150%;
          color: $NEUTRAL_DARKEST;
        }
      }
    }

    .contentWrapper {
      h3 {
        display: none;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 150%;
        color: $NEUTRAL_DARKEST;
        text-align: center;
        padding-bottom: 58px;
      }

      div {
        span,
        p,
        a {
          font-family: "Poppins";
          font-style: normal;
          font-size: 14px;
          line-height: 150%;
          color: $NEUTRAL_DARKEST;

          &.normal {
            font-weight: 400;
          }

          &.bold {
            font-weight: 700;
          }
        }

        a {
          color: $PRIMARY_MAIN;
        }

        &.to {
          padding-bottom: 24px;
        }

        &.intro {
          padding-bottom: 13px;
        }

        &.details {
          display: flex;
          flex-direction: column;
          gap: 13px;
          padding-bottom: 13px;

          .stacked {
            display: flex;

            > * {
              &:first-child {
                width: 60px;
                flex-shrink: 0;
              }

              &:nth-child(2) {
                padding-inline: 24px;
              }
            }
          }
        }

        &.outro {
          padding-bottom: 24px;
        }

        &.closure {
          padding-bottom: 108px;
        }
      }
    }
    .RemarksNote {
      margin-bottom: 16px;
    }

    .notesWrapper {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .section {
        display: flex;
        flex-direction: column;
        gap: 10px;

        h4 {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 150%;
          color: $NEUTRAL_DARKEST;
        }

        .stacked {
          display: flex;
          align-items: center;
          gap: 10px;
          color: $NEUTRAL_DARKEST;

          p {
            max-width: 472px;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 150%;
          }
        }
      }
    }

    .errorWrapper {
      padding-block: 30px;
    }

    .actionsWrapper {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr 1fr;
      gap: 12px;

      > * {
        width: 100%;
        padding: 8px 12px;
        border-radius: 8px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;

        &:hover {
          font-weight: 500;
        }

        &:first-child {
          background-color: $NEUTRAL_10;
          border: 1px solid $NEUTRAL_100;
          color: $NEUTRAL_100;
        }

        &:last-child {
          background-color: $PRIMARY_MAIN;
          border: 1px solid $PRIMARY_MAIN;
          color: $NEUTRAL_10;

          &:hover {
            box-shadow: $BOX_SHADOW_INPUT_ONFOCUS;
          }
        }
      }
    }
  }
}
