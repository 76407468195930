@import "../../../../../../theme/variables.scss";

.container {
  background-color: $NEUTRAL_10;
  border: 1px solid $NEUTRAL_50;
  border-radius: 8px;
  overflow-y: scroll;

  .showOnMobile {
    display: none !important;
  }

  > * {
    padding-inline: 16px;

    &.topWrapper {
      position: sticky;
      top: 0;
      z-index: 1;
      background-color: inherit;
      border-bottom: 1px solid $NEUTRAL_50;

      .header {
        min-height: 66px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &.add {
          border-bottom: 1px solid $NEUTRAL_50;
        }

        h4 {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 150%;
          letter-spacing: 0.005em;
          color: $NEUTRAL_100;
        }

        > div {
          width: 300px;


          &.titleWrapper {
            flex-grow: 1;
            display: flex;
            align-items: center;
            @include gap(24, 16, 16px);
          }
        }

        button {
          display: flex;
          align-items: center;
          height: 36px;
          padding-inline: 12px;
          border: 1px solid $PRIMARY_MAIN;
          border-radius: 8px;
          background-color: $NEUTRAL_10;
          gap: 8px;
          position: relative;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: $PRIMARY_MAIN;
        }
      }

      .controller {
        display: flex;
        padding-bottom: 16px;

        &.add {
          padding-top: 16px;
          border-bottom: 1px solid $NEUTRAL_50;
        }

        > * {
          width: 50%;

          &.left {
            display: flex;
            align-items: center;
            gap: 12px;

            button {
              padding: 8px 16px;
              background: $NEUTRAL_10;
              border: 1px solid $NEUTRAL_50;
              border-radius: 100px;
              font-family: "Poppins";
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 20px;
              display: flex;
              align-items: center;
              text-align: center;
              color: $NEUTRAL_100;
              transition: all 0.3s;
              position: relative;

              .options {
                position: absolute;
                top: 40px;
                left: 0;
                width: 100%;
                background-color: $NEUTRAL_10;
                border-radius: 8px;
                z-index: 1;
                box-shadow: 0px 0px 1px rgba(9, 30, 66, 0.31),
                  0px 3px 5px rgba(9, 30, 66, 0.2);

                > * {
                  position: relative;
                  padding: 8px 12px;
                  font-size: 13px;
                  font-weight: 400;
                  color: $NEUTRAL_100;
                  user-select: none;
                  // border-bottom: 1px solid $NEUTRAL_50;
                  text-align: left;

                  &:last-child {
                    border-bottom: none;
                    border-radius: 8px;

                    .separator {
                      display: none;
                    }
                  }

                  &:hover {
                    background-color: $NEUTRAL_20;
                    cursor: pointer;
                  }

                  .separator {
                    height: 1px;
                    width: calc(100% - 24px);
                    position: absolute;
                    background-color: $NEUTRAL_50;
                    left: 12px;
                    bottom: 0;
                  }
                }
              }

              &.active {
                border: 1px solid $PRIMARY_MAIN;
                color: $PRIMARY_MAIN;
                background-color: $PRIMARY_SURFACE;
              }

              &:hover {
                background-color: #f1f3ff95;
              }
            }

            h5 {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 600;
              font-size: 18px;
              line-height: 150%;
              letter-spacing: 0.005em;
              color: $NEUTRAL_DARKEST;
            }
          }

          &.right {
            display: flex;
            align-items: center;
            gap: 8px;
            justify-content: flex-end;

            button {
              display: flex;
              align-items: center;
              height: 36px;
              padding-inline: 16px;
              border: 1px solid $PRIMARY_MAIN;
              border-radius: 8px;
              background-color: $NEUTRAL_10;
              gap: 8px;
              position: relative;
              font-family: "Poppins";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              color: $PRIMARY_MAIN;

              &:hover {
                background-color: #f1f3ff95;
              }

              .options {
                position: absolute;
                top: 40px;
                left: 0;
                width: 100%;
                background-color: $NEUTRAL_10;
                border-radius: 8px;
                z-index: 1;
                box-shadow: 0px 0px 1px rgba(9, 30, 66, 0.31),
                  0px 3px 5px rgba(9, 30, 66, 0.2);

                > * {
                  position: relative;
                  padding: 8px 12px;
                  font-size: 13px;
                  font-weight: 400;
                  color: $NEUTRAL_100;
                  user-select: none;
                  // border-bottom: 1px solid $NEUTRAL_50;
                  text-align: left;

                  &:last-child {
                    border-bottom: none;
                    border-radius: 8px;

                    .separator {
                      display: none;
                    }
                  }

                  &:hover {
                    background-color: $NEUTRAL_20;
                    cursor: pointer;
                  }

                  .separator {
                    height: 1px;
                    width: calc(100% - 24px);
                    position: absolute;
                    background-color: $NEUTRAL_50;
                    left: 12px;
                    bottom: 0;
                  }
                }
              }
            }
          }
        }
      }
    }

    &.main {
      padding-inline: 0px !important;
    }
  }

  .modalWrapper {
    position: fixed;
    transition: all 0.35s ease-in-out !important;
    z-index: 4;
    background-color: transparent;
    width: 100%;
    left: 0;
    padding-inline: 0 !important;

    &.hideModal {
      bottom: -1000px;
    }

    &.showModal {
      bottom: 0;
    }
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    background-color: $NEUTRAL_OVERLAY;
    opacity: 0;
    z-index: 3;

    &.visible {
      right: 0;
      bottom: 0;
      opacity: 1;
      transition: opacity 0.4s;
    }
  }
}

@media screen and (max-width: 1024px) {
  .left,
  .right {
    .hideOnMobile {
      display: none !important;
    }

    .showOnMobile {
      display: flex !important;
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    border-radius: 0px;

    .hideOnMobile {
      display: none !important;
    }

    .showOnMobile {
      display: flex !important;
    }

    .topWrapper {
      padding-top: 8px;

      button {
        border-radius: 4px !important;
      }

      .header {
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        padding-bottom: 16px;

        > * {
          width: 100% !important;
        }
      }

      .controller {
        padding-top: 16px;

        .left,
        .right {
          padding-top: 8px;
        }
      }
    }
  }
}

.reinviteModalWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
