@import "../../../../theme/variables.scss";

.container {
  background-color: $NEUTRAL_10;
  border-radius: 8px;
  // overflow: auto;
  width: 600px;
  display: flex;
  flex-direction: column;
  height: 100%;

  // gap: 12px;

  &.wider {
    width: 726px;
  }

  .header {
    display: flex;
    flex-direction: column;
    gap: 16px;
    // border-bottom: 1px solid $NEUTRAL_40;
    background-color: $NEUTRAL-10;
    border-radius: 8px;
    padding: 16px 16px 12px 16px;

    .titleBox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
      > span:first-child {
        color: #000;

        /* Text L/SemiBold */
        // font-family: Poppins;
        font-size: calc(12px + (16 - 12) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 22.4px */
      }

      > span:last-child {
        color: var(--Neutral-60, var(--Color-Neutal-Neutral-60, #9e9e9e));

        /* Caption 1/Reguler */
        // font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 133.333% */
      }
    }

    .title {
      display: flex;
      align-items: center;
      gap: 15px;

      h3 {
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        letter-spacing: 0.1px;
      }
    }

    a {
      color: $NEUTRAL_100;
    }

    .template {
      padding: 8px 12px;
      border-radius: 8px;
      border: 1px solid $NEUTRAL_40;
      display: flex;
      align-items: center;
      gap: 8px;

      > span {
        flex-grow: 1;
        font-family: Poppins;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 12px;
    // flex-grow: 1;
    // z-index: 0;
    max-height: 350px;
    overflow: auto;
    padding: 0px 16px 12px 16px;

    .infoBox {
      display: flex;
      flex-direction: column;
      gap: 6px;
      padding: 12px;
      border-radius: var(--spacing-12px, 12px);
      background: #fffee4;
      .icon {
        display: flex;
        align-items: center;
        gap: 8px;
        > div:first-child {
          width: 20px;
        }
        > span:last-child {
          color: #fa0;

          /* Text M/Bold */
          // font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 20px; /* 142.857% */
        }
      }

      .descTex {
        display: flex;
        flex-direction: column;
        gap: 4px;
        padding-left: 8px;
        > span {
          color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));
          font-family: Poppins;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 184.615% */
          > span {
            font-weight: 700;
          }
        }
      }
    }

    > span {
      align-self: flex-start;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: $NEUTRAL_70;
    }

    .field {
      height: 200px;
      // max-height: 271px;

      border-radius: 8px;
      border: 1px dashed $NEUTRAL_50;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      // background-color: $NEUTRAL_20;
      @include padding(16, 8, 8px);

      &.height {
        height: 100%;
        @include padding(8, 8, 8px);
        border-radius: 8px;
        border: 1px solid var(--Color-Neutal-Neutral-30, #ededed);
      }

      &.hide {
        height: 1px;
        overflow: hidden;
        opacity: 0;
      }

      .progressWrapper {
        width: 100%;
        text-align: center;
        display: flex;
        align-items: center;
        gap: 16px;
        @include padding(16, 8, 8px);
        margin-top: 8px;

        .progress {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          gap: 4px;
          text-align: left;

          > span {
            color: $PRIMARY_MAIN;
            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
          }
        }

        > button {
          display: grid;
          place-items: center;
        }
      }

      > input {
        visibility: hidden;
      }

      > p {
        font-family: Poppins;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }

      span {
        font-family: Poppins;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        color: $NEUTRAL_50;
      }

      button {
        font-family: Poppins;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        text-decoration-line: underline;
        color: $PRIMARY_MAIN;
        background-color: transparent;
        border: none;
      }
    }

    .withFile {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .upper {
        display: flex;
        flex-direction: column;
        gap: 4px;

        .label {
          background-color: $PRIMARY_SURFACE;
          @include padding(12, 8, 8px);
          border-radius: 4px;
          overflow: auto;
          display: flex;
          align-items: center;
          gap: 8px;

          > span {
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            flex-grow: 1;
          }

          > button {
            display: grid;
            place-items: center;
            background-color: transparent;
            border: none;
          }
        }
      }

      .lower {
        display: flex;
        flex-direction: column;
        gap: 8px;
        height: 400px;

        > * {
          width: 100%;
        }
      }
    }
  }

  .actions {
    padding: 12px 16px 12px 16px;

    border-top: 1px solid $NEUTRAL_40;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 4px;
    > span {
      font-size: 12px;
      color: red;
    }

    > div:last-child {
      display: flex;
      align-items: center;
      gap: 12px;

      > button {
        padding: 12px;
        border-radius: 8px;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        border: 1px solid $PRIMARY_MAIN;
        width: 120px;

        &:first-child {
          background-color: $NEUTRAL_10;
          color: $PRIMARY_MAIN;
          &:disabled {
            background-color: $NEUTRAL_40;
            border-color: $NEUTRAL_40;
            color: #9e9e9e;
          }
        }

        &:last-child {
          background-color: $PRIMARY_MAIN;
          color: $NEUTRAL_10;

          &:disabled {
            background-color: $PRIMARY_DISABLED;
            border-color: $PRIMARY_DISABLED;
          }
        }
      }
    }
  }
}

.locationRow {
  display: flex;
  align-items: center;
  // gap: 8px;
  .radio {
    width: 40px;
  }
  > span {
    width: 100%;
    color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));

    /* Text S/Reguler */
    //   font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 153.846% */
  }
}

.positionRaw {
  display: flex;
  align-items: center;
  gap: 12px;
  width: 160px;

  > span {
    color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));

    /* Text S/Reguler */
    // font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 153.846% */
  }
}

.cityBoxRaw {
  width: 150px;
  border-radius: 4px;
  gap: 8px;
  padding: 2px 8px 2px 8px;
  color: $PRIMARY_MAIN;
  background-color: $PRIMARY_SURFACE;
  > span {
    font-size: 12px;
  }
}

.clientBoxRaw {
  display: flex;
  align-items: center;
  gap: 2px;
  width: 200px;
  .iconBox {
    width: 20px;
    position: relative;
    top: 2px;
  }
  .clientName {
    &.blue {
      color: var(--Primary-Disabled, #91bdf1);
    }
    // overflow: hidden;
    color: #000;
    // text-overflow: ellipsis;
    // white-space: nowrap;
    // font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.modalSuccess {
  display: flex;
  width: 301px;
  padding: 32px;

  flex-direction: column;
  align-items: center;
  gap: 16px;
  border-radius: 8px;
  background: var(--Color-Neutal-Neutral-10, #fff);

  /* Card Double Shadow */
  box-shadow: 0px 3px 5px 0px rgba(9, 30, 66, 0.2),
    0px 0px 1px 0px rgba(9, 30, 66, 0.31);

  .icon {
    width: 86px;
  }

  > span:nth-child(2) {
    color: #0f161f;

    /* Heading 6/SemiBold */
    // font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 33.6px */
    letter-spacing: 0.12px;
  }

  > span:nth-child(3) {
    color: #878787;
    text-align: center;

    /* Text S/Reguler */
    // font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 153.846% */
  }

  > div:last-child {
    border-radius: 8px;
    border: 1px solid var(--Primary-Main, #1571de);
    background: var(--Color-Neutal-Neutral-10, #fff);
    display: flex;
    padding: var(--spacing-12px, 12px);

    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: var(--Primary-Main, #1571de);
    text-align: center;

    /* Text M/Reguler */
    // font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
}
@media screen and (max-width: 768px) {
  .container {
    width: 100vw !important;
    border-radius: 16px 16px 0 0;
    .content {
      max-height: 280px;
    }

    .actions {
      width: 100%;
      > div:last-child {
        flex-direction: column;
        width: 100%;
        > button {
          width: 100%;
        }
      }
    }
  }
}
