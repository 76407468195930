@import "../../../../../../theme/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .ModalWrapper {
    position: fixed;
    transition: all 0.35s ease-in-out !important;
    z-index: 4;
    background-color: transparent;
    left: 0;
    right: 0;

    &.HideModal {
      bottom: -500px;
    }

    &.ShowModal {
      bottom: 0;
    }
  }

  .Overlay {
    position: fixed;
    top: 0;
    left: 0;
    background-color: $NEUTRAL_OVERLAY;
    opacity: 0;
    z-index: 3;

    &.Visible {
      right: 0;
      bottom: 0;
      opacity: 1;
      transition: opacity 0.4s;
    }
  }

  .sectionTitle {
    color: $PRIMARY_MAIN;
    font-size: 20px;
    font-weight: 600;
    padding-top: 8px;
  }

  .tableWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

    .searchbarSection {
      padding: 16px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      background-color: $NEUTRAL_10;

      .searchbarWrapper {
        width: 33.33%;
        height: 36px;
      }
    }

    .table {
      border-collapse: separate !important;
      width: 100%;
    }
  }
}

@media screen and (max-width: 992px) {
  .container {
    .tableWrapper {
      .searchbarSection {
        .searchbarWrapper {
          width: 60%;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    .tableWrapper {
      .searchbarSection {
        .searchbarWrapper {
          width: 100%;
        }
      }
    }
  }
}
