@import "../../../../../../theme/variables.scss";

.container {
  height: 100%;
  background-color: $NEUTRAL_10;
  margin: 16px 100px;
  border-radius: 8px;
  // padding: 24px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  > div:first-child {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 16px;
    .left {
      display: flex;
      align-items: center;
      gap: 16px;
      > div:first-child {
        width: 24px;
      }
      > div:nth-child(2) {
        display: flex;
        flex-direction: column;
        gap: 4px;
        > span:first-child {
          color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));

          /* Heading 7/SemiBold */
          //   font-family: Poppins;
          font-size: calc(16px + (20 - 16) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 600;
          line-height: 150%; /* 30px */
          letter-spacing: 0.1px;
        }

        > span:last-child {
          color: var(--Neutral-60, var(--Color-Neutal-Neutral-60, #9e9e9e));

          /* Text M/Reguler */
          //   font-family: Poppins;
          font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
        }
      }
    }

    .right {
      > span {
        padding: 4px 16px;
        border-radius: 8px;
        border: 1px solid var(--Primary-Main, #1571de);
        background: var(--Primary-Surface, #f5f7fe);
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--Primary-Main, #1571de);
        text-align: center;

        /* Text M/Medium */
        // font-family: Poppins;
        font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
      }
    }
  }

  > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    gap: 16px;
    > div:first-child {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
      .left {
        display: flex;
        align-items: center;
        gap: 16px;
        .imagesBox {
          img {
            width: 24px;
            height: 24px;
          }
        }

        > div:nth-child(2) {
          display: flex;
          flex-direction: column;
          gap: 4px;
          > span:first-child {
            color: var(--Neutral-60, var(--Color-Neutal-Neutral-60, #9e9e9e));

            /* Text M/Reguler */
            // font-family: Poppins;
            font-size: calc(
              12px + (14 - 12) * ((100vw - 280px) / (1600 - 280))
            );
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
          }

          > span:last-child {
            color: var(--Neutral-90, var(--Color-Neutal-Neutral-90, #404040));

            /* Text L/SemiBold */
            // font-family: Poppins;
            font-size: calc(
              14px + (16 - 14) * ((100vw - 280px) / (1600 - 280))
            );
            font-style: normal;
            font-weight: 600;
            line-height: 140%; /* 22.4px */
          }
        }
      }

      .right {
        > span {
          cursor: pointer;
          border-radius: 8px;
          border: 1px solid var(--Primary-Main, #1571de);
          background: var(--Neutral-10, #fff);
          padding: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: var(--Primary-Main, #1571de);
          text-align: center;

          /* Text M/Medium */
          // font-family: Poppins;
          font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 142.857% */
        }
      }
    }

    > div:nth-child(2) {
      display: flex;
      flex-direction: column;
      gap: 8px;
      > span {
        color: #000;

        /* Heading 7/Bold */
        // font-family: Poppins;
        font-size: calc(16px + (20 - 16) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 30px */
        letter-spacing: 0.1px;
      }
    }

    > div:nth-child(3) {
      display: flex;
      flex-direction: column;
      gap: 8px;

      > span:first-child {
        color: #000;

        /* Heading 7/Bold */
        // font-family: Poppins;
        font-size: calc(16px + (20 - 16) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 30px */
        letter-spacing: 0.1px;
      }
      > span:nth-child(2) {
        color: red;
        font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
      }
    }

    > div:nth-child(4) {
      display: flex;
      flex-direction: column;
      gap: 8px;
      .top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
        > span {
          color: #000;

          /* Heading 7/Bold */
          // font-family: Poppins;
          font-size: calc(16px + (20 - 16) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 700;
          line-height: 150%; /* 30px */
          letter-spacing: 0.1px;
        }

        .checkbox {
          display: flex;
          align-items: center;
          gap: 4px;

          > span {
            color: #000;

            /* Text M/Reguler */
            // font-family: Poppins;
            font-size: calc(
              12px + (14 - 12) * ((100vw - 280px) / (1600 - 280))
            );
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
          }
        }
      }
    }

    > div:nth-child(5) {
      display: flex;
      flex-direction: column;
      gap: 8px;
      > span:first-child {
        color: #000;

        /* Heading 7/Bold */
        // font-family: Poppins;
        font-size: calc(16px + (20 - 16) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 30px */
        letter-spacing: 0.1px;
      }
      > span:nth-child(2) {
        color: red;
        font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
      }
    }

    > div:nth-child(6) {
      display: flex;
      // align-items: center;
      flex-direction: column;
      // gap: 8px;
      > span {
        color: red;
        font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
      }
    }

    > div:nth-child(7) {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      border-top: 1px solid #e0e0e0;
      padding-top: 16px;
      > div {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-end;

        > span:first-child {
          color: red;

          /* Heading 7/Bold */
          // font-family: Poppins;
          font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 30px */
          letter-spacing: 0.1px;
          text-align: right;
        }
        > div:last-child {
          display: flex;
          align-items: center;
          gap: 8px;
          > div:first-child {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 8px;
            border: 1px solid var(--Primary-Main, #1571de);
            background: var(--Neutral-10, #fff);
            padding: 12px;
            color: var(--Primary-Main, #1571de);
            text-align: center;

            /* Text M/Reguler */
            // font-family: Poppins;
            font-size: calc(
              12px + (14 - 12) * ((100vw - 280px) / (1600 - 280))
            );
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
            cursor: pointer;
          }

          > div:last-child {
            border-radius: 8px;
            background: var(--Primary-Main, #1571de);
            display: flex;
            padding: 12px;
            justify-content: center;
            align-items: center;
            color: var(--Color-Neutal-Neutral-10, #fff);
            text-align: center;

            /* Text M/Reguler */
            font-family: Poppins;
            font-size: calc(
              12px + (14 - 12) * ((100vw - 280px) / (1600 - 280))
            );
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
            cursor: pointer;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .container {
    margin: 16px 30px;
  }
}

@media screen and (max-width: 768px) {
  .container {
    // margin: 16px 30px;
    > div:first-child {
      flex-direction: column;
      align-items: flex-start;
      .left {
        width: 100%;
      }

      .right {
        width: 100%;
      }
    }

    > div:nth-child(2) {
      > div:first-child {
        flex-direction: column;
        align-items: flex-start;
        .left {
          width: 100%;
        }
        .right {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .container {
    > div:nth-child(2) {
      > div:nth-child(4) {
        .top {
          flex-direction: column;
          align-items: flex-start;
          gap: 4px;
        }
      }

      > div:nth-child(7) {
        justify-content: center;
        > div {
          width: 100%;
          > div:last-child {
            flex-direction: column;
            width: 100%;
            > div:first-child {
              width: 100%;
            }
            > div:last-child {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
