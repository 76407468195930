@import "../../../theme/variables.scss";

.container {
  font-family: "Poppins";
  position: relative;
  width: 100%;
  background-color: $NEUTRAL_10;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  transition: 0.2s;
  align-items: center;
}

.containerColorDisabled {
  background-color: $NEUTRAL_30;
}

.activeContainer {
  border-color: $PRIMARY_MAIN;
  transition: 0.2s;
}

.options {
  font-family: "Poppins";
  position: absolute;
  width: 100%;
  margin-top: 5px;
  border-radius: 8px;
  background-color: $NEUTRAL_10;
  transition: 0.2s;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.option {
  padding: 12px 16px;
  font-size: 14px;
  font-weight: 400;
  color: $NEUTRAL_90;
  user-select: none;
}

.optionWrapper:hover {
  background-color: $NEUTRAL_20;
  cursor: pointer;
}

.borderBottom {
  margin: 0 10px;
}

.valueText {
  color: $NEUTRAL_100;
  font-size: 16px;
  font-weight: 400;
  user-select: none;
}

.placeholderText {
  color: $NEUTRAL_60;
  font-size: 16px;
  font-weight: 400;
  user-select: none;
}

.searchInput {
  font-family: "Poppins";
  position: relative;
  width: 90%;
  background-color: $NEUTRAL_10;
  border: unset;
  display: flex;
  justify-content: space-between;
  transition: 0.2s;
  align-items: center;
}

.iconCloseWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .iconClose {
    color: $NEUTRAL_100;
  }
}

.input:focus {
  outline: none !important;
  border-width: unset;
  border-style: unset;
  border-color: unset;
}

input::placeholder {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: $NEUTRAL_80;
}

.searchInput:focus {
  outline: none !important;
  border-width: 1px;
  border-style: unset;
  border-color: unset;
}
