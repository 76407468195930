@import "../../../../theme/variables.scss";

.container {
  .topSection {
    background-image: linear-gradient(
        to right,
        $PRIMARY_SOFT 1px,
        transparent 1px,
        transparent 100%
      ),
      linear-gradient($PRIMARY_SOFT 1px, transparent 1px, transparent 100%);
    background-size: 50px 50px;
    // height: 400px;
    background-color: $PRIMARY_SURFACE;
    background-clip: content-box;
    padding: 1px;
    border-radius: 24px 24px 0 0;
    .sectionWrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 50px;
      .titleWrapper {
        padding-top: 60px;
        text-align: center;
        .titleHead {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 5px;
          > p {
            @include font-size(16, 12, 12px);
            font-weight: 400;
          }
          .line {
            width: 18px;
            height: 3px;
            background: $PRIMARY_MAIN;
          }
        }
        .tagLine {
          margin-top: 8px;
          > p {
            @include font-size(26, 16, 16px);
            font-weight: 600;
            color: $NEUTRAL_90;
            line-height: 36px;
            > span {
              color: $PRIMARY_MAIN;
              border-bottom: 3px solid $PRIMARY_MAIN;
              padding-bottom: 2px;
            }
          }
        }
      }
      .mobileAppWrapper {
        display: flex;
        align-items: flex-end;
        gap: 24px;
        justify-content: center;
        > img {
          @include fluid-image-size(227, 100, 100px);
          max-width: calc(1440px / 4);
        }
      }
    }
  }
  .bottomSection {
    background: $NEUTRAL_100;
    color: $NEUTRAL_10;
    padding: 60px 40px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 32px;
    border-radius: 0 0 16px 16px;
    .textWrapper {
      display: flex;
      flex-direction: column;
      gap: 8px;
      justify-content: center;
      > :first-child {
        @include font-size(24, 20, 20px);
        font-weight: 700;
        > span {
          color: $PRIMARY_MAIN;
          white-space: pre-wrap;
        }
      }
      > :last-child {
        font-size: 14px;
        font-weight: 400;
      }
    }
    .cardsWrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 24px;
      .cardContainer {
        padding: 16px;
        background: $BS_NEUTRAL_90;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        width: 100%;
        .cardHead {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          .cardTitle {
            > :first-child {
              font-weight: 700;
              font-size: 16px;
              margin-bottom: 4px;
            }
            > :last-child {
              font-weight: 400;
              font-size: 14px;
            }
          }
          > img {
            width: 32px;
            height: 32px;
          }
        }
        .cardBody {
          .qrWrapper {
            padding: 10px;
            background: $NEUTRAL_10;
            border-radius: 8px;
          }
        }
        .cardAction {
          width: 100%;
          > div {
            padding: 12px;
            border-radius: 32px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .container {
    .topSection {
      .sectionWrapper {
        gap: 24px !important;
        padding: 16px 21px 0px 21px !important;
        .mobileAppWrapper {
          gap: 8px !important;
        }
        .titleWrapper {
          padding-top: 0 !important;
        }
      }
    }
    .bottomSection {
      padding: 24px 16px !important;
      grid-template-columns: 1fr !important;
      .cardsWrapper {
        grid-template-columns: 1fr !important;
      }
    }
  }
}
