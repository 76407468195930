@import "../../../../theme/variables.scss";

.container {
  background-color: $NEUTRAL-10;
  width: 472px;
  max-width: 100vw;
  padding: 16px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;

  > * {
    width: 100%;
  }

  .desc {
    text-align: center;
    padding-top: 8px;
    padding-bottom: 24px;

    > p {
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 142.857%;

      > span {
        font-weight: 700;
      }
    }
  }

  .actionsBox {
    display: flex;
    flex-direction: column;
    gap: 4px;
    > span {
      font-size: 12px;
      color: red;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;

    > button {
      width: 100%;
      padding: 10px 12px;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 142.857%;
      border-radius: 8px;
      border: 1px solid $PRIMARY_MAIN;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:first-child {
        background-color: $NEUTRAL-10;
        color: $PRIMARY_MAIN;

        &:disabled {
          border-color: $PRIMARY_DISABLED;
          color: $PRIMARY_DISABLED;
        }
      }

      &:nth-child(2) {
        background-color: $PRIMARY_MAIN;
        color: $NEUTRAL-10;

        &:disabled {
          background-color: $PRIMARY_DISABLED;
          border-color: $PRIMARY_DISABLED;
        }
      }
    }
  }
}
