@import "../../../../theme/variables.scss";

.container {
  background-color: $NEUTRAL_10;
  width: 442px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  border-radius: 8px;
  max-height: 85vh;
  overflow: auto;

  >* {
    width: 100%;
  }
  

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    >img {
      width: 100px;
      aspect-ratio: 1;
      object-fit: contain;
    }

    .desc {
      display: flex;
      flex-direction: column;
      gap: 4px;
      text-align: center;

      >h3 {
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
        letter-spacing: 0.12px;
      }

      >p {
        font-family: Poppins;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        color: $NEUTRAL_70
      }
    }
  }

  .actions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;

    >button {
      padding: 12px;
      border-radius: 8px;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      background-color: $NEUTRAL_10;
      border: 1px solid $PRIMARY_MAIN;
      color: $PRIMARY_MAIN;

      &:hover {
        background-color: $PRIMARY_SURFACE;
      }

      &:last-child {
        background-color: $PRIMARY_MAIN;
        color: $NEUTRAL_10;

        &:disabled {
          background-color: $PRIMARY_DISABLED;
          border-color: $PRIMARY_DISABLED;
        }
      }

    }
  }

}

.formContainer {
  background-color: $NEUTRAL_10;
  width: 726px;
  max-width: 100vw;
  border-radius: 8px;
  max-height: 85vh;
  overflow: auto;

  >* {
    padding: 24px;

    &.errorWrapper {
      padding-block: 0;
    }
  }

  .errorWrapper {
    text-align: right;
    color: $DANGER_MAIN;

    &.visible {
      opacity: 1;
    }

    &.hide {
      opacity: 0;
    }
  }

  .header {
    padding-bottom: 16px;
    display: flex;
    align-items: center;
    gap: 16px;
    border-bottom: 1px solid $NEUTRAL_40;
    position: sticky;
    top: 0;
    background-color: $NEUTRAL_10;
    z-index: 2;

    >h3 {
      flex-grow: 1;
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
      letter-spacing: 0.12px;
    }

    >button {
      display: grid;
      place-items: center;
      background-color: transparent;
      border: none;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    z-index: 0;

    .intro {
      display: flex;
      align-items: flex-start;
      // flex-direction: column;
      gap: 16px;
      padding-bottom: 8px;

      .stackedIntro {
        width: 100%;
        flex: 1;
        display: flex;
        align-items: center;
        gap: 8px;

        div {
          &:first-child {
            display: grid;
            place-items: center;
            padding: 12px;
            background-color: $NEUTRAL_20;
            border-radius: 5px;

            >img {
              height: 16px;
              aspect-ratio: 1;
              object-fit: contain;
            }
          }

          &:last-child {
            display: flex;
            flex: 1;
            flex-direction: column;
            padding: 8px 16px;
            background-color: $PRIMARY_SURFACE;
            border-radius: 4px;

            span {
              font-family: "Poppins";
              font-style: normal;

              &:first-child {
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                color: $NEUTRAL_60;
              }

              &:last-child {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: $NEUTRAL_100;
              }
            }
          }
        }
      }
    }

    .scoring {
      display: flex;
      flex-direction: column;
      gap: 16px;

      >span {
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }

      .card {
        padding: 12px;
        border-radius: 8px;
        background-color: $NEUTRAL_20;
        display: flex;
        flex-direction: column;
        gap: 16px;

        .each {
          display: flex;
          align-items: center;
          gap: 16px;

          >span {
            flex-grow: 1;
            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
            color: $NEUTRAL_90
          }

          .indicators {
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;
            width: 400px;

            .line {
              position: absolute;
              width: 100%;
              height: 2px;
              background-color: $NEUTRAL_40;
            }

            .indicator {
              width: 20px;
              aspect-ratio: 1;
              border-radius: 50%;
              background-color: $NEUTRAL_20;
              z-index: 1;
              display: grid;
              place-items: center;
              border: 1px solid $NEUTRAL_60;
              font-family: Poppins;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 16px;
              color: $NEUTRAL_60;
              cursor: pointer;
              transition: all 0.3s;

              &:hover {
                background-color: $PRIMARY_SURFACE;
                color: $PRIMARY_MAIN;
                border-color: $PRIMARY_MAIN;
              }

              &.active {
                background-color: $PRIMARY_MAIN;
                border-color: $PRIMARY_MAIN;
                color: $NEUTRAL_10;
              }
            }

            // .section {
            //   .divider {
            //     width: 40px;
            //     height: 2px;
            //     background-color: $NEUTRAL_30;
            //   }

            // }

          }
        }
      }

    }

  }

  .actions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    // position: sticky;
    // bottom: 0;

    >button {
      padding: 12px;
      border-radius: 8px;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      background-color: $NEUTRAL_10;
      border: 1px solid $PRIMARY_MAIN;
      color: $PRIMARY_MAIN;

      &:hover {
        background-color: $PRIMARY_SURFACE;
      }

      &:last-child {
        background-color: $PRIMARY_MAIN;
        color: $NEUTRAL_10;

        &:disabled {
          background-color: $PRIMARY_DISABLED;
          border-color: $PRIMARY_DISABLED;
        }
      }

    }
  }
}

@media screen and (max-width: 768px) {
  .formContainer, .container {
    border-radius: 16px 16px 0 0;
    width: 100%;
  }
}