@import "../../../../../theme/variables.scss";

.PdfContainer {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
  height: 100vh;
  // min-height: 100%;
  width: 100vw;
  p {
    margin: 0;
  }
  .PdfWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    > embed {
      width: 100%;
      height: 100%;
    }
  }
  .ImagesPdfWrapper {
    flex-direction: column;
    overflow: auto;
    gap: 8px;
    background-color: #f4f4f4;
    display: flex;
    width: 100%;
    align-items: center;
    padding: 16px;
    > img {
      width: 80vw;
    }
  }
  .Footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 32px;
    border: 1px solid #ededed;
    .LeftSection {
      & > :first-child {
        font-weight: 600;
        font-size: 16px;
        color: $PRIMARY_MAIN;
        margin-bottom: 8px;
      }
      .ContractText {
        display: flex;
        align-items: center;
        gap: 12px;
        .ContractDesc {
          display: flex;
          flex-direction: column;
          gap: 2px;
          & > :first-child {
            font-weight: 600;
            font-size: 14px;
          }
          & > :last-child {
            font-weight: 400;
            font-size: 12px;
            color: $NEUTRAL_60;
          }
        }
      }
    }
    .RightSection {
      cursor: pointer;
    }
  }
  .DisabledButton {
    background-color: $NEUTRAL_30 !important;
    opacity: 0.6;
  }
}

@media (width <= 768px) {
  .contractCardMobile {
    display: flex;
    border-radius: 12px 12px 0px 0px;
    padding: 32px 16px;
    width: 100%;
    height: 100%;

    .cardBox {
      // display: ;
      border-radius: 12px 12px 0px 0px;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
      width: 100%;
      // max-height: 470px;

      .topCard {
        display: flex;
        align-items: center;
        gap: 16px;
        background: #001e58;
        border-radius: 12px 12px 0px 0px;
        padding: 16px;
        .permata {
          color: var(--Neutral-10, #fff);

          /* Text L/SemiBold */
          // font-family: Poppins;
          font-size: calc(14px + (16 - 14) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 600;
          line-height: 24px; /* 150% */
        }
        .imagesPermata {
          width: 32px;
          // height: 40px;
          background-color: #fff;
          border-radius: 50%;
          padding: 10px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      .bottomCard {
        padding: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        gap: 16px;
        .nameCandidateBox {
          display: flex;
          flex-direction: column;
          gap: 10px;
          .desc {
            color: var(--Neutral-100, #000);
            text-align: center;

            /* Text M/Regular */
            // font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
          }
          .name {
            color: var(--Neutral-100, #000);
            text-align: center;

            /* Text L/SemiBold */
            // font-family: Poppins;
            font-size: calc(
              14px + (16 - 14) * ((100vw - 280px) / (1600 - 280))
            );
            font-style: normal;
            font-weight: 600;
            line-height: 24px; /* 150% */
          }
        }

        .buttonBox {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 10px;
          padding: 16px;
          border-radius: 8px;
          border: 1px solid var(--Neutral-Stroke, #ededed);
          background: var(--Neutral-10, #fff);
          width: 100%;
          .leftButton {
            display: flex;
            align-items: center;
            gap: 8px;
          }
          .download {
            color: var(--Neutral-100, #000);

            /* Text M/Regular */
            // font-family: Poppins;
            font-size: calc(
              12px + (14 - 12) * ((100vw - 280px) / (1600 - 280))
            );
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
          }
        }

        .contactText {
          color: #000;
          text-align: center;

          /* Text L/SemiBold */
          // font-family: Poppins;
          font-size: calc(14px + (16 - 14) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 600;
          line-height: 24px; /* 150% */
        }

        .infoContact {
          display: flex;
          flex-direction: column;
          gap: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          .infoBox {
            display: flex;
            align-items: center;
            gap: 4px;
            > span {
              color: #000;
              text-align: center;

              /* Ception 2/Regular */
              // font-family: Poppins;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 16px; /* 133.333% */
            }
          }
        }
      }
    }
  }
  .PdfContainer {
    height: 100%;
    // margin-bottom: 100px;

    .Footer {
      position: fixed;
      bottom: 0;
      flex-direction: column;
      gap: 16px;
      background: var(--Neutral-10, #fff);
      .RightSection {
        width: 100%;
        > button {
          width: 100%;
        }
      }
    }
  }
}
