@import "../../../theme/variables.scss";

.wrapper {
  display: flex;
  align-items: center;
  gap: 16px;

  .container {
    height: 100%;
    width: 100%;
    position: relative;

    .eyeIcon {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-right: 12px;
      cursor: pointer;
    }
  }

  .input {
    width: 100%;
    padding: 12px;
    border-radius: 8px;
    border: 1px solid $NEUTRAL_40;
    transition: 0.2s;
  }

  .input:focus {
    outline: none !important;
    border-color: $PRIMARY_MAIN;
    border-width: 1px;
    border-style: solid;
  }

  .input:disabled {
    outline: none !important;
    border-color: $NEUTRAL_40;
    border-width: 1px;
    border-style: solid;
    background-color: $NEUTRAL_30;
    color: $NEUTRAL_60;
  }

  button {
    cursor: pointer;
    background-color: transparent;
    border: none;
    color: $DANGER_MAIN;
  }
}
