.container {
  display: flex;
  flex-direction: column;
  width: 750px;
  border-radius: 8px;

  background: var(--Color-Neutal-Neutral-10, #fff);
}

.header {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  border-bottom: 1px solid #ededed;
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    > span {
      // overflow: hidden;
      color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));
      // text-overflow: ellipsis;
      // white-space: nowrap;
      //   font-family: Poppins;
      font-size: calc(24px + (32 - 24) * ((100vw - 280px) / (1600 - 280)));
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .iconCross {
      cursor: pointer;
    }
  }

  .bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    .buttonSection {
      display: flex;
      align-items: center;
      gap: 8px;
      > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 8px;
        border-radius: 8px;
        border: 1px solid var(--Color-Neutal-Neutral-50, #c2c2c2);
        background: var(--Color-Neutal-Neutral-10, #fff);
        padding: 8px 16px;
        cursor: pointer;
        > span {
          color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));

          /* Text M/Reguler */
          //   font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
        }
        &.active {
          border: 1px solid var(--Primary-Main, #1571de);
          background: var(--Primary-Surface, #f5f7fe);
          > span {
            color: var(--Primary-Main, #1571de);
          }
        }
      }
    }

    .totalData {
      color: var(--Neutral-60, var(--Color-Neutal-Neutral-60, #9e9e9e));

      /* Text M/Reguler */
      // font-family: Poppins;
      font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
    }
  }
}

.contents {
  padding: 16px;
  max-height: 500px;
  min-height: 500px;
  overflow: auto;

  .personalDataBox {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .profile {
      display: flex;
      //   align-items: center;
      justify-content: space-between;
      gap: 24px;
      border-bottom: 1px solid #27bb83;
      padding-bottom: 16px;
      .listbox {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;

        .nameBox {
          display: flex;
          align-items: center;
          gap: 10px;
          > span {
            color: #000;
            // overflow: hidden;

            // text-overflow: ellipsis;
            // white-space: nowrap;
            /* Heading 5/SemiBold */
            // font-family: Poppins;
            // font-size: 26px;
            font-style: normal;
            font-weight: 600;
            line-height: 140%; /* 36.4px */
            letter-spacing: 0.13px;
            width: 100%;
          }

          > span:first-child {
            font-size: calc(
              16px + (26 - 16) * ((100vw - 280px) / (1600 - 280))
            );
          }

          // > span:nth-child(2) {
          //   width: 2px;

          //   height: 20px;
          //   background: #000;
          // }

          // > span:nth-child(3) {
          //   font-size: calc(
          //     14px + (16 - 14) * ((100vw - 280px) / (1600 - 280))
          //   );
          // }
        }

        .list {
          display: flex;
          flex-direction: column;
          gap: 8px;
          > span {
            color: #000;

            /* Text L/SemiBold */
            // font-family: Poppins;
            font-size: calc(
              14px + (16 - 14) * ((100vw - 280px) / (1600 - 280))
            );
            font-style: normal;
            font-weight: 600;
            line-height: 140%; /* 22.4px */
          }

          > div {
            display: flex;
            // align-items: center;
            justify-content: space-between;
            gap: 16px;
            > span:first-child {
              width: 50%;
              color: var(--Neutral-70, var(--Color-Neutal-Neutral-70, #757575));

              /* Caption 1/Reguler */
              //   font-family: Poppins;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 16px; /* 133.333% */
            }
            > span:last-child {
              width: 100%;
              color: #000;

              /* Caption 1/Reguler */
              //   font-family: Poppins;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 16px; /* 133.333% */
            }
          }
        }
      }
      .images {
        width: 30%;
        img {
          width: 120px;
          height: 120px;
          border-radius: 240px;
          object-fit: cover;
          // background: url(<path-to-image>) lightgray 50% / cover no-repeat, var(--Color-Neutal-Neutral-10, #FFF);
        }
      }
    }

    .experiencesBox {
      display: flex;
      flex-direction: column;
      gap: 16px;
      border-bottom: 1px solid #27bb83;
      padding-bottom: 16px;
      &.document {
        border: none;
      }
      > span:first-child {
        color: #000;

        /* Text L/SemiBold */
        // font-family: Poppins;
        font-size: calc(14px + (16 - 14) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 22.4px */
      }
      .notFound {
        color: #000;

        /* Text M/Medium */
        // font-family: Poppins;
        font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
      }

      .informationList {
        display: flex;
        // align-items: center;
        justify-content: space-between;
        gap: 16px;
        > span:first-child {
          width: 100%;
          color: var(--Neutral-70, var(--Color-Neutal-Neutral-70, #757575));

          /* Caption 1/Reguler */
          //   font-family: Poppins;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px; /* 133.333% */
        }
        > span:last-child {
          width: 100%;
          color: #000;

          /* Caption 1/Reguler */
          //   font-family: Poppins;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px; /* 133.333% */
        }
      }

      .experincesList {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .list {
          display: flex;
          flex-direction: column;
          gap: 8px;
          border-bottom: 1px solid #ededed;
          padding-bottom: 16px;
          &.noBorder {
            border: none;
            padding-bottom: 0px;
          }
          > span {
            color: #000;

            /* Text M/Medium */
            // font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 142.857% */
            text-decoration: underline;
          }
          > div {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 16px;
            > span:first-child {
              width: 100%;
              color: var(--Neutral-70, var(--Color-Neutal-Neutral-70, #757575));

              /* Caption 1/Reguler */
              //   font-family: Poppins;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 16px; /* 133.333% */
            }
            > span:last-child {
              width: 100%;
              color: #000;

              /* Caption 1/Reguler */
              //   font-family: Poppins;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 16px; /* 133.333% */
            }
          }
        }
      }

      .documentList {
        display: flex;
        flex-direction: column;
        gap: 8px;
        .doc {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 16px;
          border: 1px solid #e0e0e0;
          border-radius: 8px;
          padding: 16px;
          width: 100%;
          .icon {
            cursor: pointer;
          }
          > div {
            display: flex;
            align-items: center;
            gap: 8px;
            > span {
              //   width: 80%;
              width: 100%;
              color: var(--Neutral-70, var(--Color-Neutal-Neutral-70, #757575));

              /* Caption 1/Reguler */
              //   font-family: Poppins;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 16px; /* 133.333% */
            }
          }
        }
      }
    }
  }

  .candidateLogsbox {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .logs {
      display: flex;
      // align-items: center;
      gap: 6px;
      align-items: flex-start;
      height: 115px;

      .dividerLog {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 6px;

        > div:first-child {
          width: 16px;
          height: 16px;
          border-radius: 50px;
          background-color: #1571de;
        }

        > div:last-child {
          img {
            width: 2px;
            height: 90px;
          }
        }
      }

      .logList {
        position: relative;
        top: -1px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 8px;
        width: 100%;
        .listBox {
          display: flex;
          flex-direction: column;
          gap: 4px;
          width: 100%;
          > div:first-child {
            display: flex;
            flex-direction: column;
            gap: 4px;
            > span:first-child {
              color: #000;

              /* Text M/Bold */
              // font-family: Poppins;
              font-size: calc(
                12px + (14 - 12) * ((100vw - 280px) / (1600 - 280))
              );
              font-style: normal;
              font-weight: 700;
              line-height: 20px; /* 142.857% */
            }
            > div:nth-child(2) {
              display: none;
            }

            > div:nth-child(3) {
              display: flex;
              align-items: center;
              gap: 4px;
              > div:first-child {
                width: 16px;
                position: relative;
                top: 2px;
              }
              > span:nth-child(2) {
                color: var(--Primary-Main, #1571de);

                /* Text M/Medium */
                // font-family: Poppins;
                font-size: calc(
                  12px + (14 - 12) * ((100vw - 280px) / (1600 - 280))
                );
                font-style: normal;
                font-weight: 700;
                line-height: 20px; /* 142.857% */
              }
              > span:nth-child(3) {
                width: 5px;
                height: 5px;
                border-radius: 50px;
                background-color: #1571de;
              }

              > span:nth-child(4) {
                color: var(--Primary-Main, #1571de);

                /* Text M/Medium */
                // font-family: Poppins;
                font-size: calc(
                  12px + (14 - 12) * ((100vw - 280px) / (1600 - 280))
                );
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
              }
            }
          }

          > div:last-child {
            color: var(--Neutral-60, var(--Color-Neutal-Neutral-60, #9e9e9e));

            /* Text M/Reguler */
            // font-family: Poppins;
            font-size: calc(
              12px + (14 - 12) * ((100vw - 280px) / (1600 - 280))
            );
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
            > span {
              font-weight: 700;
            }
          }
        }

        .date {
          width: 45%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 4px;
          color: var(--Neutral-60, var(--Color-Neutal-Neutral-60, #9e9e9e));

          /* Text M/Reguler */
          // font-family: Poppins;
          font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
          > span {
            width: 4px;
            height: 4px;
            background-color: #9e9e9e;
            border-radius: 50px;
          }
        }
      }
    }

    .loadingSpinner {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      bottom: 20px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .contents {
    max-height: 400px;
    min-height: 400px;
  }
}

@media screen and (max-width: 768px) {
  .contents {
    .candidateLogsbox {
      .logs {
        height: 150px;
        .dividerLog {
          > div:last-child {
            img {
              height: 120px;
            }
          }
        }
        .logList {
          .date {
            display: none;
          }
          .listBox {
            > div:first-child {
              > div:nth-child(2) {
                display: flex;
                align-items: center;
                gap: 4px;
                color: var(
                  --Neutral-60,
                  var(--Color-Neutal-Neutral-60, #9e9e9e)
                );

                /* Text M/Reguler */
                // font-family: Poppins;
                font-size: calc(
                  12px + (14 - 12) * ((100vw - 280px) / (1600 - 280))
                );
                font-style: normal;
                font-weight: 400;
                line-height: 20px; /* 142.857% */
                width: 100%;
                > span {
                  width: 4px;
                  height: 4px;
                  background-color: #9e9e9e;
                  border-radius: 50px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .contents {
    .personalDataBox {
      .profile {
        flex-direction: column;
        .images {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .listbox {
          .nameBox {
            > span:first-child {
              width: 50%;
            }
          }
        }
      }

      .experiencesBox {
        .informationList {
          flex-direction: column;
        }

        .experincesList {
          .list {
            gap: 16px;
            > div {
              flex-direction: column;
              gap: 8px;
            }
          }
        }
      }
    }
  }
}
