@import "../../../../theme/variables.scss";

.detailModal {
  display: none;
}

@media screen and (max-width: 820px) {
  .detailModal {
    height: 100vh;
    width: 100vw;
    background-color: #00000080;
    z-index: 999;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 16px;
    .body {
      position: fixed;
      bottom: 0;
      width: 100vw !important;
      // height: 100%;
      background-color: #fff;
      border-radius: 8px 8px 0px 0px;
      max-height: 80vh;
      overflow: auto;
      padding: 16px;
    }

    .infoCandidate {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      text-align: center;
      color: $NEUTRAL_100;
      padding-bottom: 16px;

      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid $NEUTRAL_40;
      padding-top: 16px;
    }

    .cardInfo {
      display: flex;
      flex-direction: column;
      gap: 24px;
      padding-top: 16px;
      .nameProfile {
        // font-family: Poppins;
        font-size: calc(16px + (20 - 16) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        color: #0a0a0a;
      }

      .detailList {
        display: flex;
        flex-direction: column;
        gap: 16px;
        .list {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 16px;
          border-bottom: 1px solid $NEUTRAL_40;
          padding-bottom: 16px;
          .positionTitle {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: calc(
              12px + (14 - 12) * ((100vw - 280px) / (1600 - 280))
            );
            line-height: 20px;
            color: $NEUTRAL_70;
          }
          .linkName {
            &.disabled {
              color: var(--Neutral-60, var(--Color-Neutal-Neutral-60, #9e9e9e));
            }
            &.disabledLink {
              color: var(--Neutral-60, var(--Color-Neutal-Neutral-60, #9e9e9e));
              text-decoration: none;
            }
            text-align: right;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: calc(
              12px + (14 - 12) * ((100vw - 280px) / (1600 - 280))
            );
            line-height: 20px;
            text-align: right;
            color: var(--Primary-Main, #1571de);
            cursor: pointer;
            text-decoration: underline;
          }
          .positionName {
            text-align: right;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: calc(
              12px + (14 - 12) * ((100vw - 280px) / (1600 - 280))
            );
            line-height: 20px;
            text-align: right;
            color: $NEUTRAL_DARKEST;
          }
        }

        .listStatus {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 16px;
          .positionTitle {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: calc(
              12px + (14 - 12) * ((100vw - 280px) / (1600 - 280))
            );
            line-height: 20px;
            color: $NEUTRAL_70;
          }

          .positionName {
            text-align: right;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: calc(
              12px + (14 - 12) * ((100vw - 280px) / (1600 - 280))
            );
            line-height: 20px;
            text-align: right;
            color: $NEUTRAL_DARKEST;
          }
        }
      }
    }
  }
}
