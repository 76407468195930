@import "../../../../theme/variables.scss";

.Container {
  .ModalWrapper {
    background: $NEUTRAL_10;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  // color: $DANGER_MAIN;
  // font-size: 12px;

  .SuccessModal {
    width: 410px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 32px;
    gap: 24px;

    .SuccessLogo {
      & > img {
        width: 100px;
        height: 100px;
      }
    }

    .SuccessText {
      text-align: center;
      display: flex;
      flex-direction: column;
      gap: 8px;

      .RejectText {
        color: #d42701;
      }

      & > :first-child {
        font-weight: 600;
        font-size: 24px;
      }

      & > :last-child {
        font-weight: 400;
        font-size: 14px;
        color: $NEUTRAL_60;
      }
    }

    .SuccessAction {
      //   width: 100%;
      .ButtonText {
        padding-inline: 12px;
      }
    }
  }

  .ConfirmModal {
    width: 396px;
    height: auto;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .ConfirmLogo {
      width: 54px;
      height: 54px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .SuccessLogo {
      background-color: #22970f1a;
      color: $BS_SUCCESS_MAIN;
    }

    .RejectLogo {
      background-color: #f02c011a;

      > img {
        width: 32px;
        height: 32px;
      }
    }

    // .remarkWrapper {
    //   padding-block: 16px;
    // }

    .ConfirmAction {
      display: flex;
      gap: 8px;

      .primaryButton {
        height: 44px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $DANGER_MAIN;
        padding: 10px 0;
        border-radius: 8px;
        border: 1px solid $DANGER_MAIN;
        cursor: pointer;

        &:disabled {
          background: rgba(240, 44, 1, 0.3);
          border: none;
        }

        .text {
          font-size: 14px;
          font-weight: 500;
          color: $NEUTRAL_10;
        }
      }

      .primaryButton:hover {
        background-color: #d42701;
      }
    }

    .ConfirmText {
      & > :first-child {
        font-weight: 600;
        font-size: 20px;
      }

      & > :last-child {
        font-weight: 400;
        font-size: 14px;
        color: $NEUTRAL_60;
      }
    }
  }

  .ButtonText {
    padding-block: 10px;
  }
}

.bottom {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.errorStatus {
  color: $DANGER_MAIN;
  font-size: 12px;
}

@media (width <=768px) {
  .Container {
    .SuccessModal {
      width: calc(100vw - 40px) !important;
      height: auto !important;
    }

    .ConfirmModal {
      width: calc(100vw - 40px) !important;
      height: auto !important;
    }
  }
}
