@import "../../../../../../theme/variables.scss";

.tableWrapper {
  width: 100%;
  height: 100%;
  // padding-inline: 30px;
  // padding-block: 24px;

  .floatRight {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100px;
  }

  .tableContainer {
    background-color: $NEUTRAL_LIGHTEST;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    overflow: auto;
    border-bottom: 0;

    &::-webkit-scrollbar {
      display: none;
    }

    .table {
      border-collapse: separate;
      z-index: 0;

      .firstHeadCell {
        border-right: 1px solid $NEUTRAL_40;
        position: sticky;
        left: 0;
        background-color: $NEUTRAL_10 !important;
        z-index: 1;
      }

      .oddRow {
        background-color: #f5f7fe !important;
      }

      .evenRow {
        background-color: $NEUTRAL_LIGHTEST !important;
      }

      .showOnMobile {
        display: none;
      }

      .tableRow {
        border: 0 !important;
        cursor: pointer;

        .tableCell {
          border-bottom: none !important;
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 20px;
          color: #404040;

          &.firstCell {
            border-right: 1px solid $NEUTRAL_40;
            position: sticky;
            left: 0;
            background-color: inherit;
            z-index: 1;
          }

          .link {
            display: flex !important;
            align-items: center !important;
            justify-content: flex-start;
            gap: 8px;

            span:first-child {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 36px;
              height: 36px;
              background: $PRIMARY_SOFT;
              border-radius: 50%;
              flex-shrink: 0;
            }

            span:last-child {
              font-weight: 500;
              font-size: 13px;
              line-height: 20px;
              color: $NEUTRAL_100;
            }

            span:last-child:hover {
              color: $PRIMARY_MAIN !important;
            }
          }
        }
      }
    }
  }

  .PaginationContainer {
    border-top: 1px solid $NEUTRAL_40 !important;
  }

  .AddButtonWrapper {
    display: none;
  }

  .modalWrapper {
    position: fixed;
    transition: all 0.35s ease-in-out !important;
    z-index: 4;
    background-color: transparent;

    &.hideModal {
      bottom: -500px;
    }

    &.showModal {
      bottom: 0;
    }
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    background-color: $NEUTRAL_OVERLAY;
    opacity: 0;
    z-index: 3;

    &.visible {
      right: 0;
      bottom: 0;
      opacity: 1;
      transition: opacity 0.4s;
    }
  }
}

@media screen and (max-width: 768px) {
  .tableWrapper {
    padding-top: 16px;
    padding-inline: 18px;
    padding-bottom: 100px;

    .table {
      min-width: 200px !important;
      max-width: 768px !important;
    }

    .firstHeadCell {
      border-right: 0 !important;
    }

    .firstCell {
      border-right: 0 !important;
    }

    .hideOnMobile {
      display: none !important;
    }

    .showOnMobile {
      display: table-cell !important;

      button {
        width: 77px;
        height: 28px;
        background: #1571de;
        border-radius: 8px;
        border: none;
        color: $NEUTRAL_LIGHTEST;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
      }
    }
  }

  .AddButtonWrapper {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    height: 92px;
    background: $NEUTRAL_LIGHTEST;
    padding-top: 8px;
    padding-inline: 18px;
    display: flex;
    justify-content: center;
    display: block !important;

    button {
      height: 40px;
      width: 100%;
      background: $PRIMARY_MAIN;
      border: none;
      border-radius: 8px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: $NEUTRAL_LIGHTEST;
    }
  }

  .modalWrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: $NEUTRAL_LIGHTEST;
    width: 100%;
    display: grid;
    place-items: center;
    z-index: 4;
  }
}
