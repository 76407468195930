@import "../../../theme/variables.scss";

.container {
  width: 100%;
  z-index: inherit;

  .titleWrapper {
    padding-bottom: 4px;
    font-size: 13px;
    font-weight: 500;
    color: $NEUTRAL_100;

    span {
      color: $DANGER_MAIN;
    }
  }

  .multiSelectWrapper {
    padding-bottom: 4px;
  }

  .labelsWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 8px;
  }
}
