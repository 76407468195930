@import "../../../theme/variables.scss";

.container {
  width: 100%;

  .titleWrapper {
    padding-bottom: 16px;
    font-size: 13px;
    font-weight: 500;
    color: $NEUTRAL_100;
  }

  .multiSelectWrapper {
    padding-bottom: 4px;
  }

  .labelsWrapper {
    width: 100%;
  }

  .labelsWrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
}
