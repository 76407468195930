@import "../../../../../theme/variables.scss";

.tableWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  // overflow-y: scroll !important;
  z-index: 0;
  border-radius: 8px;
  max-height: 476px;

  .holdText {
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500 !important;
    line-height: 20px;
  }

  .resultPopup {
    position: absolute;
    background-color: $NEUTRAL_10;
    border-radius: 8px;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);
    right: 16px;
    top: 60px;
    width: 184px;
    z-index: 1;
    // padding: 4px 12px;

    > * {
      text-align: left;

      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: $NEUTRAL_90;
      position: relative;

      &:not(.divider) {
        padding: 8px 12px;
      }

      .divider {
        width: calc(100% - 24px);
        height: 1px;
        background-color: $NEUTRAL_40;
        position: absolute;
        bottom: 0;
      }

      &:hover {
        background-color: $NEUTRAL_20;
      }

      // &:first-child {
      //   // border-bottom: 1px solid $NEUTRAL_40;
      // }
    }
  }

  .showOnMobile {
    display: none;
  }

  .emptyWrapper {
    flex-grow: 1;
    height: 476px;
  }

  .tableContainer {
    overflow: auto;
    background-color: inherit;
    border-bottom: 0;
    border-radius: 8px;
    // border: 1px solid $NEUTRAL_50;
    border-top: 0;
    border-inline: none;
    overflow-y: scroll !important;
    // height: 380px;

    height: auto;

    &.grow {
      //   height: 100px;
      flex-grow: 1;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    .table {
      border-collapse: separate;
      //   min-width: 900px !important;
      width: 100%;

      .tableHead {
        .tableHeadRow {
          .tableHeadCell {
            padding-inline: 16px !important;
            padding-block: 14px !important;
            position: sticky;
            top: 0;
            background-color: $NEUTRAL-10;
            gap: 12px;

            .stackedProfile {
              display: flex;
              gap: 24px;
            }

            &:first-child {
              //   border-right: 1px solid $NEUTRAL_40;
              position: sticky;
              left: 0;
              background-color: $NEUTRAL_10 !important;
              z-index: 1;
              padding-inline: 16px !important;

              > div {
                width: 304px;
                display: flex;
                align-items: center;
                gap: 24px;
              }
            }

            &.headAvatar {
              border-right: 1px solid $NEUTRAL_40;
              position: sticky;
              left: 2;
              background-color: $NEUTRAL_10 !important;
              z-index: 1;
              padding-inline: 16px !important;

              > div {
                width: 304px;
                display: flex;
                align-items: center;
                gap: 16px;
              }
            }

            &.status {
              position: sticky;
              right: 0;
              z-index: 1;
              background-color: $NEUTRAL_10 !important;
              border-left: 1px solid $NEUTRAL_40;

              .statusOption {
                position: absolute;
                // background-color: red;
                width: 100%;
                height: 200px;
              }

              div {
                width: 108px !important;
                display: flex;
                justify-content: center;

                button {
                  margin: auto;
                  background-color: transparent;
                  border: none;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 8px;

                  &.Expand {
                    color: $PRIMARY_MAIN;

                    span {
                      color: $PRIMARY_MAIN !important;
                    }
                  }
                }
              }
            }

            div {
              width: 220px !important;
            }

            span {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 500;
              font-size: calc(
                12px + (14 - 12) * ((100vw - 280px) / (1600 - 280))
              );
              line-height: 20px;
              color: $NEUTRAL_100;
            }
          }
        }
      }

      .tableBody {
        .tableCell {
          padding: 16px !important;

          > span {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: calc(
              12px + (14 - 12) * ((100vw - 280px) / (1600 - 280))
            );
            line-height: 20px;
            color: $NEUTRAL_100;
            overflow: hidden;
            display: -webkit-box;
            line-clamp: 2;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }

          .statusText {
            color: var(--Neutral-90, var(--Color-Neutal-Neutral-90, #404040));

            /* Text M/Regular */
            // font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
          }

          &.stickyLeft {
            position: sticky;
            left: 0;
            background-color: $NEUTRAL_10 !important;
            border-right: 1px solid $NEUTRAL_40;

            > div {
              display: flex;
              align-items: center;
              gap: 24px;

              .stackedProfile {
                background-color: transparent;
                border: none;
                display: flex;
                align-items: center;
                gap: 32px;
                cursor: pointer;

                span {
                  font-family: "Poppins";
                  font-style: normal;
                  font-weight: 500;
                  font-size: calc(
                    10px + (14 - 10) * ((100vw - 280px) / (1600 - 280))
                  );
                  line-height: 20px;
                  color: $PRIMARY_MAIN;
                  text-align: left;
                }

                .avatarWrapper {
                  display: flex;
                  align-items: center;
                  gap: 16px;
                }
              }
            }
          }

          &.stickyRight {
            position: sticky;
            right: 0;
            background-color: $NEUTRAL_10 !important;
            border-left: 1px solid $NEUTRAL_40;
            z-index: 0;
          }

          .actionButton {
            display: flex;
            height: 44px;
            width: 98px;
            justify-content: center;
            align-items: center;
          }

          .options {
            position: absolute;
            top: 0px;
            right: 0px;
            z-index: 1;
          }

          .menuButton {
            display: grid;
            place-items: center;
            background-color: transparent;
            border: none;
            cursor: pointer;

            &.expand {
              position: relative;
            }

            .menuIcon {
              height: 24px;
              aspect-ratio: 1;
              z-index: 0;
            }
          }
        }
      }
    }
  }

  .paginationWrapper {
    border-top: 1px solid $NEUTRAL_50;
  }

  .modalWrapper {
    position: fixed;
    transition: all 0.35s ease-in-out !important;
    z-index: 4;
    background-color: transparent;
    width: 100%;
    left: 0;

    &.hideModal {
      bottom: -1000px;
    }

    &.showModal {
      bottom: 0;
    }
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    background-color: $NEUTRAL_OVERLAY;
    opacity: 0;
    z-index: 3;

    &.visible {
      right: 0;
      bottom: 0;
      opacity: 1;
      transition: opacity 0.4s;
    }
  }
}

.profile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  text-decoration: none;
  .positionName {
    color: var(--Neutral-80, #616161);
    text-align: start;

    /* Caption 1/Reguler */
    // font-family: Poppins;
    font-size: calc(10px + (12 - 10) * ((100vw - 280px) / (1600 - 280)));
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
  }
}

.linkText {
  overflow: hidden;
  color: var(--Primary-Main, #1571de);
  text-overflow: ellipsis;

  /* Text M/Reguler */
  //   font-family: Poppins;
  font-size: calc(10px + (14 - 10) * ((100vw - 280px) / (1600 - 280)));
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  cursor: pointer;
  text-decoration: underline;
  &.disabledLink {
    color: var(--Neutral-60, var(--Color-Neutal-Neutral-60, #9e9e9e));
    text-decoration: none;
  }
  &.disabled {
    color: var(--Neutral-60, var(--Color-Neutal-Neutral-60, #9e9e9e));
  }
}

.buttonDetail {
  display: none;
}

@media screen and (max-width: 820px) {
  .showMobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    .buttonDetail {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 6px 12px;
      cursor: pointer;
      // text-decoration: underline;
      color: var(--Color-Neutal-Neutral-10, #fff);

      /* Text M/Medium */
      // font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
      border-radius: 6px;
      background: var(--Primary-Main, #1571de);

      //   width: 20px;
      //   height: 20px;
      img {
        width: 25px;
        height: 100%;
      }
    }
  }
  .tableWrapper {
    .showOnMobile {
      display: table-cell;
    }

    .tableContainer {
      .table {
        width: 100% !important;

        .tableHead {
          .tableHeadCell {
            padding: 12px 16px !important;

            &:first-child {
              border-right: none !important;
              position: relative !important;
              left: 0;
              background-color: $NEUTRAL_10 !important;
              // z-index: 1;
              padding-inline: 16px !important;

              > div {
                width: 100% !important;
                display: flex;
                align-items: center;
                gap: 16px;
              }
            }
          }

          .showOnMobile {
            min-width: 100px !important;
            max-width: 150px !important;
          }
        }

        .tableBody {
          .tableCell {
            &.stickyLeft {
              position: relative;
              border-right: none;
              // display: none!important;

              > div {
                width: 100% !important;
              }
            }

            &.showOnMobile {
              button {
                width: 100%;
                padding: 6px 12px;
                background-color: $PRIMARY_MAIN;
                border: none;
                border-radius: 8px;

                font-family: "Poppins";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: $NEUTRAL_10;
              }
            }
          }
        }

        .hideOnMobile {
          display: none !important;
        }
      }
    }
  }
}
