.container {
  display: flex;
  flex-direction: column;

  >*{
    padding-block: 20px;

    &:first-child {
      padding-top: 24px;
    }

    &:not(:last-child){
      border-bottom: 1px solid #ECE6E6;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    display: flex;
    flex-direction: column;
  
    >*{
      padding-block: 20px;
  
      &:first-child {
        padding-top: 10px;
      }
  
      &:not(:last-child){
        border-bottom: 1px solid #ECE6E6;
      }
  
      &:last-child {
        padding-bottom: 0;
      }
    }
  }
}