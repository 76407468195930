.container {

  .loadingWrapper {
    height: 100%;
    display: grid;
    place-items: center;
  }
}

@media screen and (max-width: 768px) {

  .loadingWrapper {

    >div {
      width: 150px;
    }
  }

  .contentWrapper {
    height: 100%;


  }
}