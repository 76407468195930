@import "../../../../../../../../../theme/variables.scss";

.background {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .container {
    background-color: $NEUTRAL_10;
    border-radius: 8px;
    padding: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .circle {
      background-color: $PRIMARY_MAIN;
      width: 86px;
      aspect-ratio: 1;
      border-radius: 50%;
      display: grid;
      place-items: center;

      .checkIcon {
        color: $NEUTRAL_10;
      }
    }

    h4 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 140%;
      color: $CHINESE_BLACK;
      margin-top: 16px;
      margin-bottom: 4px;
    }

    p {
      width: 257px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      text-align: center;
      color: $TAUPE_GRAY;
      margin-bottom: 16px;
    }

    button {
      background-color: $NEUTRAL_10;
      border-radius: 8px;
      border: 1px solid $PRIMARY_MAIN;
      padding: 8px 16px;
      cursor: pointer;

      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      text-align: center;
      color: $PRIMARY_MAIN;
    }
  }
}
