@import "../../../../theme/variables.scss";

.background {
  width: 100vw;
  height: 100vh;
  background-color: $NEUTRAL_OVERLAY;
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .container {
    background-color: $NEUTRAL_10;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 345px;
    aspect-ratio: 1;
    max-height: 80%;
    overflow-y: scroll;
    min-width: 320px;
    &.containerJobPost {
      max-width: unset;
      min-width: unset;
      width: 456px;
    }

    .animationWrapper {
      width: 200px;
      aspect-ratio: 1;
      display: grid;
      place-items: center;
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100% !important;

      .iconWrapper {
        padding: 10%;

        .icon {
          color: $PRIMARY_MAIN;
        }
      }

      p {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        color: #0f161f;
        padding-bottom: 1%;
      }

      span {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 140%;
        text-align: center;
        letter-spacing: 0.005em;
        color: #878787;
        padding-bottom: 6%;
      }

      button {
        background: #1571de;
        border-radius: 5px;
        padding: 10px 28px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        letter-spacing: 0.005em;
        color: $NEUTRAL_10;
        border: none;
      }
    }

    .errorWrapper {
      display: flex;
      flex-direction: column;
      gap: 24px;
      // gap: 100px;
      padding-inline: 32px;
      // padding-top: 0px;
      justify-content: flex-end;
      align-items: center;
      text-align: center;

      > div {
        color: $DANGER_MAIN;
        font-family: Poppins;
        font-size: 14px;
      }

      > button {
        background-color: $NEUTRAL_10;
        border: 1px solid $NEUTRAL_100;
        border-radius: 5px;
        padding: 10px 28px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        letter-spacing: 0.005em;
      }
    }
  }
}

.imagesError {
  width: 100px;
  height: 100px;
  img {
    width: 100%;
    height: 100%;
  }
}

.errorJobPost {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px;
  .closeButton {
    display: flex;
    align-items: center;
    justify-content: center;

    > span {
      border-radius: 8px;
      border: 1px solid var(--Primary-Main, #1571de);
      background: var(--Neutral-10, #fff);
      padding: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--Primary-Main, #1571de);
      text-align: center;

      /* Text M/Reguler */
      font-family: Poppins;
      font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      cursor: pointer;
    }
  }
  .errorText {
    display: flex;
    flex-direction: column;
    grid-area: 8px;
    // gap: 8px;
    > span:first-child {
      color: var(--Danger-Main, #ff3e13);
      text-align: center;

      /* Heading 3/Bold */
      // font-family: Poppins;
      font-size: calc(24px + (38 - 24) * ((100vw - 280px) / (1600 - 280)));
      font-style: normal;
      font-weight: 700;
      line-height: 130%; /* 49.4px */
      letter-spacing: 0.19px;
    }
    > span:nth-child(2) {
      color: var(--Color-Neutal-Neutral-90, #404040);
      text-align: center;

      /* Text M/Reguler */
      // font-family: Poppins;
      font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
    }
  }
  .imageBox {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 300px;
      height: 100%;
    }
  }
}

.recruiter {
  color: var(--Color-Neutal-Neutral-90, #404040);

  /* Text M/Bold */
  // font-family: Poppins;
  font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}
@media screen and (max-width: 768px) {
  .container {
    width: 80vw;
    max-width: 80vw;
  }
}
