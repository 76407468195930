@import "../../../../../theme/variables.scss";

.container {
  width: 100%;
  min-height: 100%;

  .errorWrapper {
    height: calc(100vh - 220px);
  }

  .controlHeader {
    width: 100%;
    height: 46px;
    padding-inline: 30px;
    // background-color: $NEUTRAL_LIGHTER;

    display: flex;
    align-items: center;
    justify-content: space-between;

    padding-bottom: 10px;

    .searchBarWrapper {
      height: 36px;
      width: 356px;
    }

    .buttonWrapper {
      height: 36px;
      min-width: 104px;
    }
  }

  .breadcrumbWrapper {
    width: 100%;
    padding: 30px;
    padding-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 24px;

    >span {
      width: 40px;
      height: 40px;
      display: grid;
      place-items: center;
      border-radius: 50%;
      cursor: pointer;
      background-color: $NEUTRAL_10;
    }

    .breadcrumbText, .breadcrumbText2 {
      @include font-size(14, 12, 12px)
    }

    .breadcrumbText {
      font-weight: 500;
      line-height: 20px;
      color: $NEUTRAL_60;
      cursor: pointer;
    }

    .breadcrumbText2 {
      font-weight: 500;
      line-height: 20px;
      color: $NEUTRAL_100;
    }
  }

  .loadingWrapper {
    height: calc(100vh - 220px);
    display: grid;
    place-items: center;
  }

  .contentWrapper {
    width: 100%;
    min-height: 100vh;
    z-index: 0 !important;
  }
}

@media screen and (max-width: 768px) {
  .container {
    .breadcrumbWrapper {
      padding: 18px;
      padding-bottom: 8px;
      padding-top: 18px;
      gap: 16px;

      >span {
        width: 40px !important;
        height: 40px !important;
        flex-shrink: 0;
      }
    }
  }

  .controlHeader {
    width: 100%;
    padding-inline: 18px !important;
    flex-direction: column;
    height: 100% !important;
    gap: 8px;

    .buttonWrapper {
      // display: none;
      width: 100% !important;
    }

    .searchBarWrapper {
      width: 100% !important;
    }
  }
}