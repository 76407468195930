@import "../../../../../../../theme/variables.scss";

.container {
  border-radius: 16px;
  border: var(--Spacing-0, 1px) solid var(--Color-Neutal-Neutral-40, #e0e0e0);
  background: #f8fbff;
  display: flex;
  padding: 16px;
  flex-direction: column;
  gap: 16px;
  // &.redBorder {
  //   border: 1px solid red;
  // }
  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    > span {
      width: 50%;
      color: var(--Neutral-90, var(--Color-Neutal-Neutral-90, #404040));

      /* Text M/Medium */
      //   font-family: Poppins;
      font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
      > span {
        color: var(--Danger-Main, #ff3e13);
        // font-family: Poppins;
        font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
      }
    }
    > div {
      width: 70%;
      .experiences {
        display: flex;
        align-items: center;
        gap: 8px;
        > div {
          border-radius: 8px;
          border: 1px solid var(--Neutral-30, #ededed);
          background: var(--Neutral-20, #f5f5f5);
          display: flex;
          padding: 12px 8px;
          justify-content: center;
          align-items: center;
          color: #000;
          text-align: center;

          /* Text S/Reguler */
          //   font-family: Poppins;
          font-size: calc(10px + (13 - 10) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 153.846% */
          width: 100%;
          cursor: pointer;
          &.active {
            // border-radius: 8px;
            color: var(--Primary-Main, #1571de);
            border: 1px solid var(--Primary-Main, #1571de);
            background: var(--Primary-Surface, #f5f7fe);
          }
          &.redBorder {
            border: 1px solid red;
            // background-color: rgb(245, 226, 226);
          }
        }
      }

      .criteriaBox {
        display: flex;
        align-items: center;
        gap: 8px;
        flex-wrap: wrap;
        // width: 60%;
        .listTags {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          gap: 10px;

          .boxList {
            display: flex;
            align-items: center;
            justify-content: space-between;
            // width: 20%;
            border-radius: 50px;
            border: 1px solid var(--neutral-40, #e0e0e0);

            &.v2 {
              border-radius: 8px;

              > span {
                padding: 8px;
              }

              .iconBox {
                padding: 8px 4px;
              }
            }

            background: var(--neutral-10, #fff);

            .iconBox {
              border-left: 1px solid #e0e0e0;
              padding: 2px 8px;
              cursor: pointer;
            }

            .iconDelete {
              position: relative;
              top: 1.5px;
            }

            .nameList {
              padding: 2px 8px;
              color: var(--neutral-90, #404040);
              text-align: center;

              /* Caption 1/Medium */
              // font-family: Poppins;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 16px;
              /* 133.333% */
            }
          }
        }
        .buttonSection {
          &.buttonDisabled {
            background-color: $PRIMARY_SURFACE;
            border: 1px dashed $PRIMARY_DISABLED;
            color: $PRIMARY_DISABLED;
          }

          &.redBorder {
            border: 1px dashed red;
          }

          display: flex;
          align-items: center;
          gap: 8px;
          display: flex;
          padding: 12px;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
          border: 1px dashed var(--primary-main, #1571de);
          background: var(--primary-surface, #f5f7fe);
          color: var(--primary-main, #1571de);
          text-align: center;
          // font-family: Poppins;
          font-size: 13px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          /* 153.846% */
          cursor: pointer;
          // width: 50%;
        }

        &.cV2 {
          display: grid;
          grid-template-columns: 1fr auto;
        }
      }

      .remark {
        width: 100%;
        border-radius: 8px;
        border: 1px solid #e6eaec;
        background: #fff;
        height: 101px;
        padding: 12px 4px 4px 12px;

        &.disable {
          background-color: $NEUTRAL_30;
        }
        &.redBorder {
          border: 1px solid red;
        }
      }

      .remark:focus {
        outline: none !important;
        border-width: 1px;
        border-style: solid;
        border-color: #1571de;
        &.redBorder {
          border-color: red;
        }
      }

      .inputTextForm {
        // width: 60%;
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 12px;
        border-radius: 8px;
        border: 1px solid var(--neutral-40, #e0e0e0);
        background: #fff;

        &.redBorder {
          border: 1px solid red;
        }

        .listTags {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          gap: 10px;

          .boxList {
            display: flex;
            align-items: center;
            justify-content: space-between;
            // width: 20%;
            border-radius: 50px;
            border: 1px solid var(--neutral-40, #e0e0e0);

            &.v2 {
              border-radius: 8px;

              > span {
                padding: 8px;
              }

              .iconBox {
                padding: 8px 4px;
              }
            }

            background: var(--neutral-10, #fff);

            .iconBox {
              border-left: 1px solid #e0e0e0;
              padding: 2px 8px;
              cursor: pointer;
            }

            .iconDelete {
              position: relative;
              top: 1.5px;
            }

            .nameList {
              padding: 2px 8px;
              color: var(--neutral-90, #404040);
              text-align: center;

              /* Caption 1/Medium */
              // font-family: Poppins;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 16px;
              /* 133.333% */
            }
          }
        }

        &.inputTextFormDiable {
          background-color: $NEUTRAL_30;
        }

        .inputAddTags {
          border: none;
          padding: 0px 0px;
          width: 100%;
          height: 100%;
          height: 20px;
          // height: 100%;
          resize: none;
          overflow: hidden;
          // border-bottom: 1px solid #e0e0e0;
        }

        .inputAddTags:focus {
          outline: none !important;
          border: none;
          border-bottom: 1px solid #e0e0e0;
        }

        .inputAddTags::placeholder {
          color: var(--neutral-60, #9e9e9e);

          // /* Text S/Reguler */
          // font-family: Poppins;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          /* 153.846% */
        }
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .container {
    > div {
      flex-direction: column;
      align-items: flex-start;
      > span {
        width: 100%;
      }
      > div {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .container {
    > div {
      > div {
        .experiences {
          flex-direction: column;
        }
      }
    }
  }
}
