@import "../../../../theme/variables.scss";

.container {
  background-color: $NEUTRAL-10;
  width: 403px;
  max-width: 100vw;
  border-radius: 8px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .logo {
    width: 200px;
    height: 170px;
    position: relative;

    >img {
      width: 100%;
      object-fit: contain;
    }

    .lottieWrapper {
      position: absolute;
      width: 300px;
      left: -25%;
      top: -30%;
    }

  }

  .loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    padding-top: 24px;

    >img {
      width: 50px;
      object-fit: contain;
      animation: rotation 1.5s infinite linear;
    }

    >span {
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 142.857%;
    }

  }

  .text {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    padding-top: 24px;
    padding-bottom: 32px;

    >h3 {
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
      letter-spacing: 0.1px;
    }

    >p {
      text-align: center;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 142.857%;
      color: $NEUTRAL_60;
    }
  }

  .actions {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;

    &.flex {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    >button {
      padding: 12px;
      border-radius: 8px;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 142.857%;
      border: 1px solid $PRIMARY_MAIN;
      min-width: 120px;

      &.back {
        background-color: transparent;
        color: $PRIMARY_MAIN;
      }

      &.submit {
        background-color: $PRIMARY_MAIN;
        color: $NEUTRAL-10;
      }
    }
  }

}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}