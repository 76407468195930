@import "../../../../theme/variables.scss";

.containerTemporary {
  .search {
    // padding-inline: 90px;
    transform: translateY(-50%);
    z-index: 3;
    position: relative;

    > * {
      margin: auto;
      max-width: calc(1440px - 180px);
    }
  }
  .mobileText {
    display: none;
    > :first-child {
      font-size: 20px;
      font-weight: 700;
      color: $NEUTRAL_90;
      margin-bottom: 8px;
    }
    > :last-child {
      font-weight: 400;
      font-size: 14px;
      color: $NEUTRAL_60;
    }
  }
}

@media only screen and (max-width: 768px) {
  .containerTemporary {
    padding-top: 16px;
    .mobileText {
      text-align: center;
      display: flex;
      flex-direction: column;
      margin-bottom: 24px;
      padding-inline: 18px;
    }
    .showcaseWrapper {
      display: none;
    }
    .search {
      padding-inline: 18px !important;
      transform: none !important;
    }
  }
}
