.container {
  .navigatorWrapper {
    position: sticky;
    top: 70px;
  }

  .loadingWrapper {
    height: calc(100vh - 220px);
    display: grid;
    place-items: center;
  }

  .contentWrapper {
    // height: calc(100vh - 145px);
    overflow-y: scroll;
  }
}

@media screen and (max-width: 768px) {
  // .container {

  //   .contentWrapper {
  //     height: calc(100vh - 140px);
  //   }
  // }
}
