@import "../../../../theme/variables.scss";

.container {
  .header {
    display: flex;
    padding: 16px 30px;
    border-bottom: 1px solid var(--Color-Neutal-Neutral-40, #e0e0e0);
    background: var(--Color-Neutal-Neutral-20, #f5f5f5);
    position: sticky;
    justify-content: space-between;
    align-items: center;
    top: 70px;
    z-index: 2;

    .searchWrapper {
      display: flex;
      gap: 12px;
      justify-content: flex-start;
      align-items: center;
      width: 50%;
    }

    .rightBox {
      display: flex;
      align-items: center;
      gap: 12px;
      .buttonUpload {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        padding: 8px;
        border-radius: 8px;
        border: 1px solid var(--Primary-Main, #1571de);
        background: var(--Color-Neutal-Neutral-10, #fff);
        cursor: pointer;
        &.disabled {
          border: 1px solid var(--Color-Neutal-Neutral-40, #e0e0e0);
          background: var(--Color-Neutal-Neutral-30, #ededed);
          // color: #9e9e9e;
          > span {
            color: #9e9e9e;
          }
        }
        > span {
          color: var(--Primary-Main, #1571de);
          text-align: center;

          /* Text M/Reguler */
          // font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
        }
      }

      .addButton {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        padding: 8px;
        border-radius: 8px;
        background: var(--Primary-Main, #1571de);
        cursor: pointer;
        &.disabled {
          border: 1px solid var(--Color-Neutal-Neutral-40, #e0e0e0);
          background: var(--Color-Neutal-Neutral-30, #ededed);
          // color: #9e9e9e;
          > span {
            color: #9e9e9e;
          }
        }
        > span {
          color: var(--Color-Neutal-Neutral-10, #fff);
          text-align: center;

          /* Text M/Reguler */
          // font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
        }
      }
    }

    .buttonAdd {
      width: 30%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .content {
    padding: 16px 30px;
    display: flex;
    flex-direction: column;
    gap: 12px;

    .selectorWrapper {
      display: flex;
      width: inherit;
      background-color: $NEUTRAL-10;
      border-radius: 5px;
      padding: 4px;

      .selectorBox {
        padding: 8px 0px 8px 0px;
        flex-grow: 1;
        display: flex;
        font-family: "Poppins";
        font-weight: 500;
        font-size: 14px;
        text-align: center;
        align-items: center;
        justify-content: center;
        color: $PRIMARY_MAIN;
        background-color: $NEUTRAL-10;
        border: none;

        &.active {
          background-color: $PRIMARY_MAIN;
          color: $NEUTRAL-10;
          border-radius: 5px;
        }
      }
    }

    .messageWrapper {
      width: inherit;
      padding: 8px 10px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 8px;
      background-color: $PRIMARY_SOFT;
      border-radius: 4px;

      font-family: "Poppins";
      font-weight: 400;
      font-size: 12px;

      .iconDash {
        height: 20px;
        width: 20px;
      }
    }

    .tableBox {
      border-radius: 8px;
      border: 1px solid var(--Color-Neutal-Neutral-40, #e0e0e0);
      background: var(--Color-Neutal-Neutral-10, #fff);
      height: 100%;
    }
  }

  .main {
    border-top: 1px solid #e0e0e0;
  }
}

@media screen and (max-width: 768px) {
  .container {
    .header {
      flex-direction: column;
      justify-content: flex-start;
      align-items: start;
      gap: 12px;

      .searchWrapper {
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        gap: 8px;
      }

      .buttonAdd {
        justify-content: flex-start;
        align-items: start;
      }
    }
    .content {
      .selectorWrapper {
        flex-direction: column;
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .container {
    .header {
      .rightBox {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        .buttonUpload {
          width: 100%;
        }

        .addButton {
          width: 100%;
        }
      }
    }
  }
}
