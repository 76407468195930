.body {
  // width: 80%;
  border-radius: 8px;
  background: var(--neutral-10, #fff);
  width: 1000px;
  height: 580px;
  overflow: auto;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 24px 16px 24px;
    border-bottom: 1px solid var(--neutral-30, #ededed);
    position: sticky;
    top: 0;
    z-index: 1;
    background: var(--neutral-10, #fff);
    .flbNumberBox {
      display: flex;
      flex-direction: column;
      gap: 4px;
      .title {
        color: var(--neutral-60, #9e9e9e);

        /* Text M/Reguler */
        //   font-family: Poppins;
        font-size: calc(10px + (14 - 10) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
      }
      .docNum {
        color: #000;

        /* Text L/SemiBold */
        //   font-family: Poppins;
        font-size: calc(12px + (16 - 12) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 22.4px */
      }
    }

    .logoPermata {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .iconClose {
        display: none;
      }
    }

    .leftLogo {
      display: flex;
      align-items: center;
      gap: 16px;
      .nameLogo {
        color: #000;

        /* Text L/SemiBold */
        //   font-family: Poppins;
        font-size: calc(12px + (16 - 12) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 22.4px */
      }
      .imagesBox {
        width: 50px;
        background: #f3f5f7;
        border-radius: 50%;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .contentFlBDetail {
    display: flex;
    flex-direction: column;
    padding: 30px 100px 30px 100px;
    gap: 24px;
    .headerContent {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .numberFLBBox {
        display: flex;
        flex-direction: column;
        gap: 4px;
        .numberDoc {
          color: #000;

          font-size: calc(10px + (14 - 10) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 400;
          // line-height: 140%; /* 22.4px */
        }
        .formTitle {
          color: #000;

          /* Text L/SemiBold */
          //   font-family: Poppins;
          font-size: calc(12px + (16 - 12) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 600;
        }
      }
      .logoPermata {
        display: flex;
        align-items: center;
        gap: 16px;
        .nameLogo {
          color: #000;

          /* Text L/SemiBold */
          //   font-family: Poppins;
          font-size: calc(12px + (16 - 12) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 600;
          // line-height: 140%; /* 22.4px */
        }
        .imagesBox {
          width: 15%;
          background: #f3f5f7;
          border-radius: 50%;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .contenDetailFlb {
      display: flex;
      flex-direction: column;
      gap: 32px;

      .descBox {
        display: flex;
        flex-direction: column;
        gap: 16px;
        .desc {
          display: flex;
          flex-direction: column;
          gap: 4px;
          .address {
            color: #000;

            font-size: calc(
              10px + (14 - 10) * ((100vw - 280px) / (1600 - 280))
            );
            font-style: normal;
            font-weight: 400;
          }
          .clientFrom {
            color: #000;

            font-size: calc(
              10px + (14 - 10) * ((100vw - 280px) / (1600 - 280))
            );
            font-style: normal;
            font-weight: 700;
          }
          .forClient {
            color: #000;

            font-size: calc(
              10px + (14 - 10) * ((100vw - 280px) / (1600 - 280))
            );
            font-style: normal;
            font-weight: 400;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 890px) {
  .contentFlBDetail {
    padding: 30px 30px 30px 30px;
  }
}

@media screen and (max-width: 768px) {
  .body {
    .header {
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      .logoPermata {
        width: 100%;
        .iconClose {
          display: flex;
          cursor: pointer;
        }
      }
      .leftLogo {
        .imagesBox {
          width: 32px;

          height: 32px;
        }
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .body {
    height: 500px;

    .contentFlBDetail {
      .headerContent {
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
      }
    }
  }
}
