.bodyModal {
  height: 100vh;
  width: 100vw;
  background-color: #00000080;
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  .container {
    border-radius: 8px;
    background: var(--neutral-10, #fff);
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 32px;
    // width: 50%;
  }

  .top {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    .reasonTitle {
      color: #0f161f;
      text-align: center;

      /* Heading 6/SemiBold */
      //   font-family: Poppins;
      font-size: calc(14px + (24 - 14) * ((100vw - 280px) / (1600 - 280)));
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 33.6px */
      letter-spacing: 0.12px;
    }
    .desc {
      color: #878787;
      text-align: center;

      /* Text S/Reguler */
      //   font-family: Poppins;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 153.846% */
    }
  }

  .inputForm {
    display: flex;
    flex-direction: column;
    gap: 8px;
    .label {
      color: var(--neutral-100, #0a0a0a);

      /* Text M/Medium */
      //   font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
    }
    textarea {
      resize: none;
      padding: 12px;
      border-radius: 8px;
      border: 1px solid var(--neutral-40, #e0e0e0);
      background: #fff;
      width: 100%;
      height: 88px;
    }
  }

  .buttonSection {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 16px;
    .cancel {
      cursor: pointer;
      display: flex;
      //   height: 44px;
      padding: 12px;
      justify-content: center;
      align-items: center;
      width: 100%;
      border-radius: 8px;
      border: 1px solid var(--neutral-100, #0a0a0a);
      background: var(--neutral-10, #fff);
      color: var(--neutral-100, #0a0a0a);
      text-align: center;

      /* Text M/Reguler */
      //   font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
    }
    .sent {
      cursor: pointer;
      display: flex;
      //   height: 44px;
      padding: 12px;
      justify-content: center;
      align-items: center;
      width: 100%;
      border-radius: 8px;
      background: var(--primary-main, #1571de);
      color: #fff;
      text-align: center;

      /* Text M/Reguler */
      //   font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
    }
  }
}
