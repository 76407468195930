@import "../../../../../../theme/variables.scss";

.container {
  width: 100%;
  height: 100%;

  .header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 18px;

    h3 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 150%;
      letter-spacing: 0.005em;
      color: $PRIMARY_MAIN;
      flex-grow: 1;
    }

    .indicators {
      display: flex;
      align-items: center;
      gap: 18px;

      >div {
        display: flex;
        align-items: center;
        gap: 8px;

        >div {
          width: 37px;
          height: 10px;
          border-radius: 10px;
        }

        >span {
          font-family: Poppins;
          font-size: 13px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          color: $NEUTRAL-90;
        }

        &.fulfilled {
          >div {
            background-color: $PRIMARY_MAIN;
          }
        }

        &.candidates {
          >div {
            background-color: $PRIMARY_DISABLED;
          }
        }
      }
    }
  }

  .tableWrapper {
    padding-top: 16px;
    width: 100%;
    height: 100%;

    .stickyLeft {
      border-right: 1px solid $NEUTRAL_40;
      position: sticky;
      left: 0;
      background-color: inherit !important;
      z-index: 1;
    }

    // .stickyRight {
    //   border-left: 1px solid $NEUTRAL_40;
    //   position: sticky;
    //   right: 0;
    //   background-color: inherit !important;
    // }

    .tableContainer {
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
      overflow: auto !important;
      border-bottom: 0;
      width: 100%;

      .table {
        border-collapse: separate;

        .showOnMobile {
          display: none;
        }

        .tableRow {
          border: 0 !important;

          &.oddRow {
            background-color: #f5f7fe !important;
          }

          &.evenRow {
            background-color: $NEUTRAL_LIGHTEST !important;
          }

          .tableCell {
            border-bottom: none !important;
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            color: #404040;

            .statusLabel {
              display: grid;
              place-items: center;
              width: 107px;
              height: 36px;
              border-radius: 8px;
              font-family: "Poppins";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;

              &.onProcess {
                border: 1px solid $PRIMARY_MAIN;
                color: $PRIMARY_MAIN;
                background: $PRIMARY_SOFT;
              }

              &.Finished {
                border: 1px solid $SECONDARY_MAIN;
                color: $SECONDARY_MAIN;
                background: $SECONDARY_SOFT;
              }
            }

            button {
              height: 28px;
              width: 77px;
              background: $PRIMARY_MAIN;
              border: none;
              border-radius: 8px;
              font-style: normal;
              font-weight: 500;
              font-size: 13px;
              line-height: 20px;
              color: $NEUTRAL_LIGHTEST;

              &.NoBg {
                background-color: transparent;
                color: $PRIMARY_MAIN;
                padding: 0 !important;
                width: auto;
                height: auto;
              }
            }
          }
        }
      }
    }
  }

  .PaginationContainer {
    border-top: 1px solid $NEUTRAL_40;
  }
}

@media screen and (max-width: 768px) {
  .tableWrapper {
    padding-block: 0;
    padding-top: 8px !important;

    .stickyLeft {
      border-right: 0 !important;
    }

    .hideOnMobile {
      display: none !important;
    }

    .showOnMobile {
      display: table-cell !important;
    }
  }
}