@import "../../../theme/variables.scss";

.container {
  display: grid;
  place-items: center;
  padding: 60px;
  @include padding-block(60, 26, 26px);
  @include padding-inline(60, 18, 18px);

  >* {
    max-width: 950px;
  }


  .loadingWrapper {
    height: 100vh;
    display: grid;
    place-items: center;
  }
}

// @media screen and (max-width: 768px) {
// .container {
//   padding: 26px 18px;
// }
// }