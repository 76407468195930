@import "../../../../../theme/variables.scss";

.blur {
  background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  height: 100px;
  width: 100%;
  bottom: 0;
  z-index: 2;
  position: absolute;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.container {
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  background-color: $NEUTRAL_10;
  max-width: 100vw;
  width: 588px;
  height: 712px;
  position: static;
  filter: drop-shadow(0px 4px 9px rgba(0, 0, 0, 0.12));

  .headerWrapper {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 0;
    padding: 16px;
    z-index: 1;
    background-color: $NEUTRAL_10;

    .header {
      display: flex;
      justify-content: space-between;

      .titleWrapper {
        display: flex;
        align-items: center;
        gap: 16px;

        .title {
          font-size: 20px;
          font-weight: 700;
          color: $NEUTRAL_100;
        }

        .titleIconWrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: $NEUTRAL_20;
          height: 54px;
          width: 54px;
          border-radius: 27px;

          img {
            height: 32px;
            width: 32px;
          }
        }
      }

      .iconWrapper {
        cursor: pointer;
        display: grid;
        place-items: center;
        background-color: transparent;
        border: none;

        .icon {
          color: $NEUTRAL_100;
        }
      }
    }
  }

  .body {
    overflow: auto;
    .tabHead {
      border-bottom: 1px solid #ededed;
      padding-bottom: 8px;
      .tabWrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        border: 1px solid #ededed;
        margin-inline: 20px;
        background: $NEUTRAL_20;
        border-radius: 8px;
        padding: 4px;
        .tabSelector {
          display: grid;
          place-items: center;
          // padding: 4px;
          color: $NEUTRAL_60;
          cursor: pointer;
          transition: 0.2s;
          > p {
            padding-block: 8px;
            font-weight: 500;
            font-size: 14px;
          }
        }
        .activeTab {
          background: $PRIMARY_MAIN;
          border-radius: 6px;
          color: $NEUTRAL_10;
        }
      }
    }
    .contentWrapper {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding-bottom: 8px;
      margin-top: 8px;
    }

    .addParamWrapper {
      display: flex;
      justify-content: space-between;
      background: $PRIMARY_SURFACE;
      padding: 8px 16px;
      margin-top: -8px;
      color: $PRIMARY_MAIN;
      > p {
        font-weight: 500;
        font-size: 14px;
      }
      & > :last-child {
        cursor: pointer;
      }
    }
    .inputContainer {
      padding: 8px 16px;
      .inputWrapper {
        display: grid;
        grid-template-columns: 1fr 1fr auto;
        gap: 8px;
        place-items: center;
        & > :last-child {
          color: $DANGER_MAIN;
          margin-top: 20px;
          cursor: pointer;
        }
      }
    }
    .inputAction {
      display: flex;
      gap: 8px;
      justify-content: flex-end;
      padding: 24px 16px;
      padding-top: 0;
      .buttonWrapper {
        > div {
          padding: 10px 12px;
        }
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      gap: 8px;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      padding: 0 24px;

      .title {
        font-size: 14px;
        font-weight: 600;
        color: $NEUTRAL_100;
      }

      .cards {
        background-color: $NEUTRAL_10;
      }
    }
  }
}

@media (width <=768px) {
  .container {
    width: 100%;
  }
}

.cardContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $NEUTRAL_30;
  padding: 8px 0;

  .name {
    font-size: 12px;
    font-weight: 400;
    color: $NEUTRAL_70;
  }

  .tag {
    font-size: 12px;
    font-weight: 400;
    color: $NEUTRAL_100;
  }
}

.cardContainer:last-child {
  border: 0 solid $NEUTRAL_30;
}
