.container {
  width: 528px;

  //   justify-content: ;
  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    border-radius: 8px 8px 0px 0px;
    background: var(--Primary-Surface, #f5f7fe);
    padding: 16px 16px 0px 16px;
    > span:first-child {
      color: var(--Primary-Main, #1571de);
      text-align: center;

      /* Text L/SemiBold */
      //   font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 22.4px */
    }
    > span:nth-child(2) {
      color: var(--Primary-Main, #1571de);
      text-align: center;

      /* Text M/Bold */
      //   font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px; /* 142.857% */
    }
    > div:nth-child(3) {
      img {
        width: 198.289px;
        height: 133.716px;
      }
    }
  }

  .inputBox {
    display: flex;
    flex-direction: column;

    gap: 16px;
    padding: 16px;
    background-color: #ffffff;
    .selectedBM {
      display: flex;
      flex-direction: column;
      gap: 4px;
      > span:first-child {
        color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));

        /* Text M/Medium */
        // font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
      }

      .autoComplete {
        width: 100%;
      }
    }

    .checkBox {
      display: flex;
      //   align-items: center;
      gap: 8px;
      > div:first-child {
        display: flex;
        align-items: center;
        justify-content: center;
        .checkbox {
          height: 20px;
          width: 20px;
          border-radius: 6px;
          border: 2px solid #c2c2c2 !important;
        }
      }
      > span:nth-child(2) {
        color: #000;
        // text-align: center;

        /* Text S/Reguler */
        // font-family: Poppins;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 153.846% */
        &.red {
          color: red;
        }
      }
    }
  }

  .buttonSection {
    display: flex;
    flex-direction: column;

    align-items: flex-end;
    gap: 8px;
    background-color: #fff;
    padding: 16px;
    border-radius: 0px 0px 8px 8px;
    > span:first-child {
      font-size: 12px;
      color: red;
    }

    > div:last-child {
      display: flex;
      align-items: center;
      gap: 8px;
      > span:first-child {
        display: flex;
        padding: 12px 16px;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        border: 1px solid var(--Primary-Main, #1571de);
        background: var(--Color-Neutal-Neutral-10, #fff);
        cursor: pointer;
        border-radius: 8px;
        border: 1px solid var(--Primary-Main, #1571de);
        background: var(--Color-Neutal-Neutral-10, #fff);
        color: var(--Primary-Main, #1571de);
        text-align: center;

        /* Text M/Reguler */
        // font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        width: 100px;
      }

      > span:last-child {
        width: 100px;
        display: flex;
        padding: 12px 16px;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        background: var(--Primary-Main, #1571de);
        color: var(--Color-Neutal-Neutral-10, #fff);
        text-align: center;

        /* Text M/Reguler */
        // font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        cursor: pointer;
      }
    }
  }
}

.successModal {
  width: 528px;
  border-radius: 8px;
  background: var(--Color-Neutal-Neutral-10, #fff);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
  padding: 32px;
  > div:nth-child(2) {
    color: #0f161f;
    text-align: center;

    /* Heading 6/SemiBold */
    // font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 33.6px */
    letter-spacing: 0.12px;
  }

  > div:nth-child(3) {
    color: #878787;
    text-align: center;

    /* Text S/Reguler */
    // font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 153.846% */
  }

  > div:nth-child(4) {
    border-radius: 8px;
    border: 1px solid var(--Primary-Main, #1571de);
    background: var(--Color-Neutal-Neutral-10, #fff);
    padding: 12px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--Primary-Main, #1571de);
    text-align: center;

    /* Text M/Reguler */
    // font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    cursor: pointer;
  }
}

@media screen and (max-width: 768px) {
  .successModal {
    border-radius: 8px 8px 0px 0px;
  }
  .container {
    .buttonSection {
      border-radius: 0px;
    }
  }
}
