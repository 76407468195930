@import "../../../../../../theme/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  width: 700px;
  border-radius: 8px;

  background: var(--Color-Neutal-Neutral-10, #fff);
}

.header {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-bottom: 1px solid var(--Color-Neutal-Neutral-40, #e0e0e0);
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    > span:first-child {
      overflow: hidden;
      color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));
      text-overflow: ellipsis;
      white-space: nowrap;
      //   font-family: Poppins;
      font-size: calc(24px + (32 - 24) * ((100vw - 280px) / (1600 - 280)));
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .iconCross {
      cursor: pointer;
    }
  }

  .bottom {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
    .backButton {
      display: flex;
      align-items: center;
      gap: 4px;
      cursor: pointer;
      > span {
        color: var(--Primary-Main, #1571de);

        /* Text M/Medium */
        // font-family: Poppins;
        font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
      }
    }
    > span {
      color: #000;
      text-align: right;

      /* Heading 7/SemiBold */
      //   font-family: Poppins;
      font-size: calc(16px + (20 - 16) * ((100vw - 280px) / (1600 - 280)));
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 30px */
      letter-spacing: 0.1px;
    }
  }
}

.contents {
  padding: 20px;
  max-height: 400px;
  //   min-height: 400px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
  .profileCandidate {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    .profile {
      width: 100%;
      display: flex;
      //   align-items: center;
      gap: 6px;

      > div {
        background-color: #f5f5f5;
        border-radius: 4px;
        padding: 8px 16px;
        img {
          width: 16px;
          height: 16px;
        }
      }

      > div:first-child {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      > div:last-child {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 4px;
        > span:first-child {
          color: var(--Neutral-60, var(--Color-Neutal-Neutral-60, #9e9e9e));

          /* Caption 1/Reguler */
          //   font-family: Poppins;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px; /* 133.333% */
        }

        > span:last-child {
          color: #000;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;

          /* Text M/Medium */
          //   font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 142.857% */
        }
      }
    }
  }

  .inputBox {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
      > div:first-child {
        display: flex;
        flex-direction: column;
        gap: 4px;
        width: 100%;
        > span {
          color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));

          /* Text M/Medium */
          //   font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 142.857% */
        }
      }

      > div:last-child {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 4px;
        > span {
          color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));

          /* Text M/Medium */
          //   font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 142.857% */
        }

        .dateInputBox {
          display: flex;
          align-items: center;
          gap: 8px;
          > div {
            width: 100%;
            .inputWrapper {
              position: relative;

              .placeholderReplacement,
              ::placeholder,
              ::-webkit-input-placeholder {
                position: absolute;
                top: 12px;
                left: 12px;
                font-family: "Poppins";
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 20px;
                color: $NEUTRAL_70;
                opacity: 1;
              }

              .endAdornment {
                position: absolute;
                right: 8px;
                top: 12px;
                background-color: transparent;
                border: none;
                color: $PRIMARY_MAIN;
                display: grid;
                place-items: center;
                // z-index: 0;
              }
            }

            > input,
            .inputWrapper input {
              border: 1px solid $NEUTRAL_40;
              border-radius: 8px;
              padding-inline: 12px;
              height: 44px;
              width: 100%;
              background-color: transparent;

              &[type="time"]::-webkit-calendar-picker-indicator {
                opacity: 0;
              }

              &:focus {
                outline: none;
                border-color: $PRIMARY_MAIN;
              }
            }
          }
        }
      }
    }

    .second {
      display: flex;
      flex-direction: column;
      gap: 4px;
      > span {
        color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));

        /* Text M/Medium */
        //   font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
      }

      > input,
      .inputWrapper input {
        border: 1px solid $NEUTRAL_40;
        border-radius: 8px;
        padding-inline: 12px;
        height: 44px;
        width: 100%;
        background-color: transparent;

        &:focus {
          outline: none;
          border-color: $PRIMARY_MAIN;
        }
      }

      .customAddressBox {
        padding-top: 4px;
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
    }

    .third {
      display: flex;
      flex-direction: column;
      gap: 4px;
      > span {
        color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));

        /* Text M/Medium */
        //   font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
      }
    }
  }
}

.buttonSection {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px;
  border-top: 1px solid var(--Color-Neutal-Neutral-40, #e0e0e0);
  > div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 4px;
    .error {
      color: red;
      font-size: 12px;
      text-align: right;
    }
    > div {
      border-radius: 8px;
      background: var(--Primary-Main, #1571de);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 12px;
      color: var(--Color-Neutal-Neutral-10, #fff);
      text-align: center;

      /* Text M/Reguler */
      // font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      width: 100px;
      cursor: pointer;
      &.disabled {
        background-color: #e0e0e0;
        color: "#9E9E9E";
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .contents {
    .profileCandidate {
      flex-direction: column;
    }

    .inputBox {
      .top {
        flex-direction: column;
      }
    }
  }
}
