@import "../../../../../theme/variables.scss";

.container {

  .breadcrumbWrapper {
    display: flex;
    align-items: center;
    gap: 24px;
    padding: 30px;
    padding-right: 0px;
    padding-bottom: 10px;
    background-color: $NEUTRAL_LIGHTER;

    button {
      display: grid;
      place-items: center;
      height: 40px;
      aspect-ratio: 1;
      border-radius: 50%;
      border: none;
      background-color: $NEUTRAL_10;
    }

    .breadcrumbText {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: $NEUTRAL_60
    }

    .breadcrumbTextBold {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: $NEUTRAL_100;
    }
  }
}

@media screen and (max-width: 768px) {
  .container {

    .breadcrumbWrapper {
      padding: 8px 0px 8px 18px;
      gap: 16px;

      >button {
        height: 32px;
      }

      .breadcrumbText {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
      }

      .breadcrumbTextBold {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}