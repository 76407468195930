@import "../../../../../../theme/variables.scss";

.container {
  padding: 24px 30px;
  overflow: auto;
  height: 100%;

  .card {
    padding: 32px;
    border-radius: 8px;
    background-color: $NEUTRAL_10;
    border: 1px solid $NEUTRAL_30;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .header {
      > h3 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: 0.005em;
        color: $PRIMARY_MAIN;
        padding-bottom: 8px;
      }

      > p {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $NEUTRAL_60;
      }
    }

    .title {
      display: flex;
      align-items: center;
      gap: 8px;

      > div {
        height: 48px;
        aspect-ratio: 1;
        border-radius: 50%;
        background-color: $PRIMARY_SOFT;
        color: $PRIMARY_MAIN;
        display: grid;
        place-items: center;
      }

      h4 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 140%;
        color: $NEUTRAL_100;
      }
    }

    .profileCard,
    .orderCard {
      padding: 16px;
      background-color: $NEUTRAL_20;
      border-radius: 8px;
    }

    .profileCard {
      display: flex;
      flex-direction: column;
      gap: 12px;

      .stacked {
        display: flex;
        gap: 16px;

        span {
          font-family: "Poppins";
          font-style: normal;

          &:first-child {
            flex-grow: 1;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: $NEUTRAL_70;
          }

          &:last-child {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            text-align: right;
            color: $NEUTRAL_100;
          }
        }
      }
    }

    .orderCard {
      display: flex;
      align-items: center;
      gap: 16px;

      .topSection {
        flex-grow: 1;
        display: flex;
        align-items: center;
        gap: 16px;

        >div {

          &:first-child {
            flex-shrink: 0;
            color: $NEUTRAL_70;

          }

        }

      }



      .middle {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        > h5,
        > span {
          font-family: "Poppins";
          font-style: normal;

          &:first-child {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 140%;
            color: $NEUTRAL_100;
            padding-bottom: 4px;
          }

          &:nth-child(2) {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: $NEUTRAL_100;
            padding-bottom: 2px;
          }

          &:nth-child(4) {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: $NEUTRAL_60;
          }
        }

        > div {
          display: flex;
          align-items: center;
          gap: 6px;
          color: $PRIMARY_MAIN;
          padding-bottom: 8px;

          >*:first-child {
            flex-shrink: 0;
          }

          span {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: $PRIMARY_MAIN;
          }
        }
      }

      > button {
        padding: 8px 16px;
        gap: 4px;
        min-height: 36px;
        background: $PRIMARY_MAIN;
        border-radius: 8px;
        border: none;

        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: $NEUTRAL_10;

        &:hover {
          box-shadow: $BOX_SHADOW_INPUT_ONFOCUS;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    padding: 16px 18px;

    .card {
      padding: 16px;

      .orderCard {
        flex-direction: column;
        align-items: flex-start;

        .topSection {
          width: 100%;
        }

        button {
          width: 100%;
        }
      }
    }
  }
}
