@import "../../../theme/variables.scss";

.selectWrapper {
  height: 44px;
  background: $NEUTRAL_10;
  border: 1px solid $NEUTRAL_40;
  border-radius: 8px;
  position: relative;
  display: flex;
  align-items: center;
  &.disableInput {
    background-color: $NEUTRAL_30;
  }

  .disableInput {
    background-color: $NEUTRAL_30;
  }

  .icon {
    position: absolute;
  }

  .iconSend {
    position: relative;
    // position: absolute;
    left: 15px;
  }

  .inputSearchJobSeeker {
    border: none;
    color: $NEUTRAL_100 !important;
    padding-inline-start: 25px !important;
    padding-inline-end: 53px !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    &::placeholder {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      color: $NEUTRAL_70;
      width: 100%;
      opacity: 1;
    }
  }

  .inputJobNew {
    padding: 12px;
    border-radius: 8px;
    border: 1px solid black;
  }

  .placeholder {
    position: relative;
    // right: 20px;
    left: 20px;
    color: var(--Neutral-60, var(--Color-Neutal-Neutral-60, #9e9e9e));

    /* Text M/Reguler */
    // font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }

  input {
    border-radius: 8px;
    border: 1px solid black;
    // background: var(--Neutral-10, #fff);

    &::placeholder {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      color: $NEUTRAL_70;
      width: 100%;
      opacity: 1;
    }

    &:focus {
      border: none;
      outline: none;
    }
  }

  > span,
  input {
    padding-inline: 12px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: $NEUTRAL_70;
    width: 100%;

    &.filled {
      color: $NEUTRAL_100;
    }
  }

  &.active {
    border-color: $PRIMARY_MAIN;
  }

  .expandButton {
    position: absolute;
    display: grid;
    place-items: center;
    padding: 0;
    background-color: transparent;
    border: none;
  }

  .iconClose {
    position: absolute;
    right: 35px;
    // top: 5px;
    // top: 13.5px;
  }

  .clearButton,
  .spinner {
    position: absolute;
    right: 27px;
    top: 27px;
    display: grid;
    place-items: center;
    padding: 0;
    background-color: transparent;
    border: none;

    &.spinner {
      right: 60px;
      top: 10px;
    }
  }

  .optionsWrapper {
    position: absolute;
    top: 50px;
    width: 100%;
    background-color: $NEUTRAL_10;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0px 0px 1px rgba(9, 30, 66, 0.31),
      0px 3px 5px rgba(9, 30, 66, 0.2);
    z-index: 1;
    padding-block: 4px;
    max-height: 200px;
    overflow-y: auto;

    > div {
      padding: 8px 12px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $NEUTRAL_90;
      display: flex;
      align-items: center;
      gap: 8px;

      &:hover {
        background-color: $PRIMARY_SURFACE_HOVER;
      }

      > img {
        width: 20px;
        aspect-ratio: 1;
        border-radius: 50%;
        object-fit: cover;
      }
    }
  }
}
