.inputBox {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  .iconJob {
    position: absolute;
    left: 15px;
  }
  .inputSearchJob {
    width: 100%;
    border-radius: 8px;
    border: 1px solid var(--neutral-40, #e0e0e0);
    background: var(--neutral-10, #fff);
    padding: 12px 12px 12px 45px;
    &.inputAdd {
      padding: 12px 12px 12px 12px;
    }
  }
  input:focus {
    outline: none !important;
    border-width: 1px;
    border-style: solid;
    border-color: #1571de;
  }
  .inputSearchJob::placeholder {
    color: var(--neutral-60, #9e9e9e);

    /* Text M/Reguler */
    // font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }

  .iconClose {
    position: absolute;
    right: 15px;
  }
}
