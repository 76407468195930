@import "../../../../../theme/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
  padding-bottom: 26px;

  .basicProfileSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;

    .basicProfileLeft {
      display: flex;
      gap: 23px;
      align-items: center;

      .basicProfileInformation {
        display: flex;
        flex-direction: column;
        gap: 4px;

        .clientName {
          font-size: 16px;
          font-weight: 600;
          color: $NEUTRAL_100;
        }

        .clientPosition {
          font-size: 13px;
          font-weight: 400;
          color: $NEUTRAL_60;
        }
      }

      .profilePicture {
        height: 75px;
        width: 75px;
        background-color: $NEUTRAL_60;
        border-radius: 37.5px;
      }
    }

    .editProfileButton {
      padding: 8px;
      border: 1px solid $PRIMARY_MAIN;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 4px;
      cursor: pointer;

      .editProfileButtonIcon {
        color: $PRIMARY_MAIN;
      }

      .editProfileButtonTitle {
        font-size: 14px;
        font-weight: 500;
        color: $PRIMARY_MAIN;
      }
    }
  }
}

.personalInformationSection {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 24px;

  .personalInformationText {
    font-size: 16px;
    font-weight: 600;
    color: $NEUTRAL_90;
  }

  .personalInformationWrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .personalInformation {
      display: flex;
      flex-direction: row;
      gap: 16px;
      align-items: center;
      position: relative;

      .informationTitle {
        flex: 1;
        font-size: 14px;
        font-weight: 500;
        color: $NEUTRAL_100;
      }

      .informationValue {
        flex: 2;
        font-size: 14px;
        font-weight: 400;
        color: $NEUTRAL_100;

        .greySpan {
          font-size: 14px;
          font-weight: 400;
          color: $NEUTRAL_60;
        }
      }

      .icon {
        position: absolute;
        right: 0;
        color: $NEUTRAL_100;
        cursor: pointer;
      }
    }
  }
}
