@import "../../../theme/variables.scss";

.container {
  height: 100vh;
  width: 100vw;
  background: $NEUTRAL_LIGHTER;
  display: grid;
  place-items: center;
  overflow: auto;
  padding-top: 72px;
  padding-bottom: 100px;

  .loadingWrapper {
    height: 100vh;
    display: grid;
    place-items: center;
  }

}

@media screen and (max-width: 768px) {
  .container {
    padding-block: 0px;
    background: $NEUTRAL_LIGHTER;
    display: flex;
    align-items: flex-start;
  }
}