@import "../../../../theme/variables.scss";

.container {
  background-color: $NEUTRAL-10;
  border-radius: 8px;
  width: 673px;
  max-width: 100vw;
  height: 468px;
  padding: 16px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  gap: 12px;

  .header {
    display: flex;
    align-items: center;
    gap: 12px;

    .hLeft {
      flex-grow: 1;

      >h3 {
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
        color: $PRIMARY_MAIN;
      }

      >span {
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 142.857%;
      }
    }

    .hRight {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 12px;

      >div {
        display: flex;
        flex-direction: column;
        gap: 2px;
        background-color: $PRIMARY_SURFACE;
        border-radius: 4px;
        padding: 4px 12px;

        >span {
          font-family: Poppins;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 133.333%;
        }

        >p {
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 140%;
        }
      }
    }
  }

  .content {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    border: .5px solid $NEUTRAL-40;
    border-radius: 8px;
    overflow: auto;

    .cHeader {
      display: grid;
      max-width: calc(100vw - 32px);
      grid-template-columns: 40% 30% 30%;

      >div {
        border: .5px solid $NEUTRAL-40;
        padding: 12px 18px;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 142.857%;
        color: $PRIMARY_MAIN;

        &:first-child {
          border-radius: 8px 0 0 0;
        }

        &:last-child {
          border-radius: 0 8px 0 0;
        }
      }
    }

    .cBody {
      width: 100%;
      max-width: calc(100vw - 32px);

      .cBRow {
        display: grid;
        grid-template-columns: 40% 30% 30%;

        >div, >input {
          border: .5px solid $NEUTRAL-40;
          padding: 12px 18px;
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 142.857%;
          color: $NEUTRAL_90;

        &:not(:first-child){
            text-align: right;
          }

            &:focus {
              border: 2px solid $PRIMARY_MAIN!important;
              outline: none;
              text-align: left;
            }
        }
      }

    }
  }

  .actions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;

    >button {
      padding: 12px 16px;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      // line-height: 142.857%;
      border-radius: 8px;
      border: 1px solid $PRIMARY_MAIN;
      display: flex;
      align-items: center;
      justify-content: center;

      &:first-child {
        background-color: $NEUTRAL-10;
        color: $PRIMARY_MAIN;
      }

      &:last-child {
        background-color: $PRIMARY_MAIN;
        color: $NEUTRAL-10;

        &:disabled {
          background-color: $PRIMARY_DISABLED;
          border-color: $PRIMARY_DISABLED;
        }
      }
    }
  }
}