@import "../../../../../../theme/variables.scss";

.flbContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: $NEUTRAL-10;
  padding: 16px;

  .header {
    display: flex;
    align-items: center;

    >h3 {
      flex-grow: 1;
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      letter-spacing: 0.1px;
      color: $PRIMARY_MAIN;
    }

    .searchWrapper {
      height: 44px;
      width: 350px;
    }
  }

  .content {
    // display: grid;
    // grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
    // gap: 16px;
    // padding-top: 16px;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    border: 1px solid $NEUTRAL-40;

    >*:not(table):not(.paginationWrapper, .tableWrapper) {
      flex-grow: 1;
    }

    .tableWrapper {
      overflow: auto;
    }


    table {
      width: 100%;
      border-collapse: collapse;
      border-spacing: 0;
      overflow: auto;

      thead {

        tr {

          th {
            padding: 16px 20px;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            border-bottom: 1px solid $NEUTRAL-40;
            white-space: nowrap;
          }
        }
      }

      tbody {

        tr {
          cursor: pointer;

          &.odd {

            td {
              background-color: $PRIMARY_SURFACE !important;
            }
          }

          td {
            padding: 24px 20px;
            font-family: Poppins;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            color: $NEUTRAL-90;

            .div {
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }

    .paginationWrapper {
      border-top: 1px solid $NEUTRAL_40;
    }
  }
}

.mpContainer {
  background-color: $NEUTRAL-10;
  border-radius: 8px;
  padding: 16px;
}