@import "../../../theme/variables.scss";

.breadCrumb {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  overflow-x: scroll;
  gap: 18px;
  overflow-y: hidden;
  padding-right: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;

  &::-webkit-scrollbar {
    display: none;
  }

  > * {
    display: flex;
    align-items: center;

    .separator {
      padding-left: 18px;
    }
  }
}
