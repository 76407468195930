@import "../../../theme/variables.scss";

.selectWrapper {
  height: 44px;
  background: $NEUTRAL_10;
  border: 1px solid $NEUTRAL_40;
  border-radius: 8px;
  position: relative;
  display: flex;
  align-items: center;
  &.selectWrapperRecommanded {
    border-radius: 0px;
    border: none;
  }

  .label {
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 133.333%;
    color: $PRIMARY_MAIN;
    background-color: $PRIMARY_SURFACE;
    border-radius: 4px;
    padding: 2px 8px;
  }

  .labelWrapper {
    position: absolute;
    right: 55px;
  }

  &.isError {
    border: 1px solid red;
  }

  &.disableInput {
    background-color: $NEUTRAL_30;
  }

  .disableInput {
    background-color: $NEUTRAL_30;
  }

  .icon {
    position: absolute;
  }

  .iconSend {
    position: relative;
    // position: absolute;
    left: 15px;
  }

  .inputSearchJobSeeker {
    border: none;
    color: $NEUTRAL_100 !important;
    padding-inline-start: 25px !important;
    padding-inline-end: 53px !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &::placeholder {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      color: $NEUTRAL_70;
      width: 100%;
      opacity: 1;
    }
  }

  input {
    border: none;
    color: $NEUTRAL_100 !important;
    // padding-inline-start: 14px !important;
    padding-inline-end: 53px !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &.disableInput {
      // background-color: $NEUTRAL_30;
      color: $NEUTRAL_70 !important;
    }

    &::placeholder {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      color: $NEUTRAL_70;
      width: 100%;
      opacity: 1;
    }

    &:focus {
      border: none;
      outline: none;
    }
  }

  > span,
  input {
    padding-inline: 12px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: $NEUTRAL_70;
    width: 100%;

    &.filled {
      color: $NEUTRAL_100;
    }
  }

  &.active {
    border-color: $PRIMARY_MAIN;
  }

  &.error {
    border-color: #f5610d !important;
  }

  .expandButton {
    position: absolute;
    display: grid;
    place-items: center;
    padding: 0;
    background-color: transparent;
    border: none;
  }

  .iconClose {
    position: absolute;
    right: 35px;
    // top: 5px;
    // top: 13.5px;
  }

  .clearButton,
  .spinner {
    position: absolute;
    right: 35px;
    top: 13.5px;
    display: grid;
    place-items: center;
    padding: 0;
    background-color: transparent;
    border: none;

    &.spinner {
      right: 60px;
      top: 10px;
      &.spinnerHc {
        right: 35px;
        top: 10px;
      }
    }
  }

  .optionsWrapper {
    position: absolute;
    top: 50px;
    width: 100%;
    background-color: $NEUTRAL_10;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0px 0px 1px rgba(9, 30, 66, 0.31),
      0px 3px 5px rgba(9, 30, 66, 0.2);
    z-index: 1;
    padding-block: 4px;
    max-height: 200px;
    overflow-y: auto;
    &.optionsWrapperHC {
      z-index: 2;
    }
    &.optionsWrapperRecommended {
      border-radius: 0px;
      top: 45px;
    }

    > div {
      padding: 8px 12px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $NEUTRAL_90;
      display: flex;
      align-items: center;
      gap: 8px;

      &:hover {
        background-color: $PRIMARY_SURFACE_HOVER;
      }

      > span {
        flex-grow: 1;
      }

      > img {
        width: 20px;
        aspect-ratio: 1;
        border-radius: 50%;
        object-fit: cover;
      }
    }
  }

  .optionRecommended {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    > div:first-child {
      border-radius: 50px;
      background: var(--Color-Neutal-Neutral-30, #ededed);
      width: 30px;
      height: 30px;
      display: flex;
      padding: 4px;

      flex-direction: column;
      align-items: center;
    }

    > div:last-child {
      display: flex;
      flex-direction: column;
      gap: 2px;
      > span:first-child {
        color: var(--Primary-Main, #1571de);
        // font-family: Poppins;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
      }

      > span:last-child {
        // overflow: hidden;
        color: var(--Neutral-50, var(--Color-Neutal-Neutral-50, #c2c2c2));
        // text-overflow: ellipsis;
        // white-space: nowrap;
        // // font-family: Poppins;
        // width:;
        // max-width: 400px;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: flex;
        align-items: center;
        gap: 4px;
      }
    }
  }
}

.recommendedCandidate {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  > div:first-child {
    position: relative;
    top: 2px;
    > img {
      width: 36px;
      height: 36px;
      border-radius: 50px;
    }
  }

  > div:last-child {
    display: flex;
    flex-direction: column;
    gap: 2px;
    > span:first-child {
      color: var(--Primary-Main, #1571de);
      // font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
      // width: 100%;S
    }
    > span:last-child {
      // overflow: hidden;
      color: var(--Neutral-70, var(--Color-Neutal-Neutral-70, #757575));
      // text-overflow: ellipsis;
      // font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      display: flex;
      align-items: center;
      gap: 2px;
      // text-align: center;
    }
  }
}

.recommendedPosition {
  display: flex;
  flex-direction: column;
  // align-items: center;
  // justify-content: space-between;
  gap: 2px;
  width: 100%;

  > span:first-child {
    color: var(--Primary-Main, #1571de);
    // font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    // width: 100%;S
  }
  > span:last-child {
    // overflow: hidden;
    color: var(--Neutral-70, var(--Color-Neutal-Neutral-70, #757575));
    // text-overflow: ellipsis;
    // font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    // text-align: center;
  }
}

.errorDesc {
  font-size: 12px;
  color: #f5610d;
}
