@import "../../../../../../theme/variables.scss";

.container {
  padding: 24px 30px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

@media screen and (max-width:768px) {

  .container {
    padding: 16px 18px;
  }
}