@import "../../../../../theme/variables.scss";

.container {
  @include padding-block(32, 12, 12px);
  @include padding-inline(30, 16, 16px);

  .errorWrapper {
    height: calc(100vh - 60px)
  }

  .headTitle {
    @include padding-bottom(32, 10, 10px);
    display: none;
    & > h1 {
      font-weight: 600;
      @include font-size(38, 20, 20px);
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    .headTitle {
      display: block!important;
    }
  }
}
