@import "../../../../../../../../theme//variables.scss";

.container {
  border-radius: 8px;
  border: 1px solid #e8e8e8;
  background-color: $NEUTRAL_10;
  > div:first-child {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background: #f3f9ff;
    border-radius: 8px 8px 0px 0px;
    color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));
    text-align: center;

    /* Heading 7/Bold */
    // font-family: Poppins;
    font-size: calc(16px + (20 - 16) * ((100vw - 280px) / (1600 - 280)));
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 30px */
    letter-spacing: 0.1px;
  }

  > div:nth-child(2) {
    padding: 16px;
    .tableBox {
      border-radius: 8px;
      border: 1px solid var(--Color-Neutal-Neutral-30, #ededed);
      background: var(--Color-Neutal-Neutral-10, #fff);

      .tableHead {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
        padding: 16px;
        border-bottom: 1px solid #e0e0e0;

        > span {
          width: 250px;
          color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));

          /* Text M/Medium */
          //   font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 142.857% */
          text-align: start;
        }
        > span:first-child {
          width: 300px;
        }

        > span:nth-child(3) {
          width: 250px;
        }

        > span:last-child {
          width: 150px;
        }
      }

      .tablebody {
        display: flex;
        flex-direction: column;
        .body {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 16px;
          padding: 16px;
          border-bottom: 1px solid var(--Color-Neutal-Neutral-30, #ededed);
          > span {
            width: 250px;
            color: var(--Neutral-90, var(--Color-Neutal-Neutral-90, #404040));
            // font-family: Poppins;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-align: start;
            &.defaultValue {
              width: 150px;
              padding: 4px 10px;

              justify-content: center;
              align-items: center;
              display: flex;
              border-radius: 40px;
              border: 1px solid var(--Success-Main, #27bb83);
              background: var(--Success-Surface, #eafef3);
              color: var(--Success-Main, #27bb83);
            }

            &.yellow {
              width: 150px;
              padding: 4px 10px;

              justify-content: center;
              align-items: center;
              display: flex;
              border-radius: 40px;
              border: 1px solid var(--Active-Outline-Border, #fbdbb6);
              background: var(--Active-Surface, #fef8f0);
              color: #ee962f;
            }

            &.red {
              width: 150px;
              padding: 4px 10px;

              justify-content: center;
              align-items: center;
              display: flex;
              border-radius: 40px;
              border: 1px solid var(--Danger-Main, #ff3e13);
              background: var(--Danger-Surface, #faeff2);
              color: var(--Danger-Main, #ff3e13);
            }

            > span {
              color: var(--Neutral-60, var(--Color-Neutal-Neutral-60, #9e9e9e));
              //   font-family: Poppins;
              font-size: 13px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              //   text-decoration-line: underline;
              &.active {
                color: var(--Primary-Main, #1571de);
                cursor: pointer;
              }
            }
          }
          > div {
            width: 300px;
            display: flex;
            flex-direction: column;
            gap: 4px;
            > span:first-child {
              color: var(--Neutral-90, var(--Color-Neutal-Neutral-90, #404040));
              // font-family: Poppins;
              font-size: 13px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              text-align: start;
            }
            > span:last-child {
              color: var(--Neutral-60, var(--Color-Neutal-Neutral-60, #9e9e9e));

              /* Text S/Medium */
              //   font-family: Poppins;
              font-size: 13px;
              font-style: normal;
              font-weight: 500;
              line-height: 20px; /* 153.846% */
            }
          }

          > span:nth-child(3) {
            width: 250px;
          }

          &.borderNone {
            border-bottom: none;
          }
          &.blue {
            background-color: var(--Primary-Surface, #f5f7fe);
            transition: background-color 1s ease-in-out;
          }
          &.white {
            background-color: var(--Color-Neutal-Neutral-10, #fff);
            transition: background-color 1s ease-in-out;
          }

          &.yellow {
            background-color: #e9b62f1a;
          }

          &.borderYellow {
            border: 1px solid var(--Alert-Main, #e9b62f);
          }
        }
      }
    }

    .tableBoxResponsive {
      display: none;
    }
  }
}

@media screen and (max-width: 1024px) {
  .container {
    > div:nth-child(2) {
      .tableBox {
        display: none;
      }

      .tableBoxResponsive {
        border-radius: 8px;
        border: 1px solid var(--Color-Neutal-Neutral-30, #ededed);
        background: var(--Color-Neutal-Neutral-10, #fff);
        display: flex;
        flex-direction: column;

        .tableHead {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 16px;
          padding: 16px;
          border-bottom: 1px solid #e0e0e0;

          > span {
            width: 100%;
            color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));

            /* Text M/Medium */
            //   font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 142.857% */
          }
        }

        .tablebody {
          display: flex;
          flex-direction: column;
          .body {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 16px;
            padding: 16px;
            border-bottom: 1px solid var(--Color-Neutal-Neutral-30, #ededed);
            > div:first-child {
              width: 100%;
              display: flex;
              flex-direction: column;
              gap: 4px;
              > span:first-child {
                width: 100%;
                color: var(
                  --Neutral-90,
                  var(--Color-Neutal-Neutral-90, #404040)
                );
                // font-family: Poppins;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-align: start;
              }
              > span:last-child {
                color: var(
                  --Neutral-60,
                  var(--Color-Neutal-Neutral-60, #9e9e9e)
                );

                /* Text S/Medium */
                //   font-family: Poppins;
                font-size: 13px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px; /* 153.846% */
              }
            }

            > div:last-child {
              width: 100%;
              > div {
                width: 150px;
                padding: 8px;
                background: $PRIMARY_MAIN;
                border-radius: 8px;
                font-family: "Poppins";
                font-style: normal;
                font-weight: 500;
                font-size: 13px;
                line-height: 20px;
                color: $NEUTRAL_10;
                border: none;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
              }
            }

            &.blue {
              background: var(--Primary-Surface, #f5f7fe);
            }
            &.white {
              background: var(--Color-Neutal-Neutral-10, #fff);
            }

            &.borderNone {
              border-bottom: none;
            }

            &.yellow {
              background-color: #e9b62f1a;
            }

            &.borderYellow {
              border: 1px solid var(--Alert-Main, #e9b62f);
            }
          }
        }
      }
    }
  }

  .containerDetail {
    display: flex;
    flex-direction: column;
    gap: 12px;
    background-color: #fff;
    border-radius: 8px;
    padding: 12px;
    width: 600px;
    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;

      > span:first-child {
        color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));

        /* Text M/Medium */
        // font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
        width: 50%;
      }

      > span:last-child {
        color: var(--Neutral-90, var(--Color-Neutal-Neutral-90, #404040));
        // font-family: Poppins;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: end;
        width: 50%;
        &.active {
          color: var(--Primary-Main, #1571de);
          cursor: pointer;
          text-decoration: underline;
        }

        &.disabled {
          color: var(--Neutral-90, var(--Color-Neutal-Neutral-90, #b4b4b4));
          //   text-decoration: underline;
        }
        &.green {
          width: 150px;
          padding: 4px 10px;

          justify-content: center;
          align-items: center;
          display: flex;
          border-radius: 40px;
          border: 1px solid var(--Success-Main, #27bb83);
          background: var(--Success-Surface, #eafef3);
          color: var(--Success-Main, #27bb83);
        }

        &.yellow {
          width: 150px;
          padding: 4px 10px;

          justify-content: center;
          align-items: center;
          display: flex;
          border-radius: 40px;
          border: 1px solid var(--Active-Outline-Border, #fbdbb6);
          background: var(--Active-Surface, #fef8f0);
          color: #ee962f;
        }

        &.red {
          width: 150px;
          padding: 4px 10px;

          justify-content: center;
          align-items: center;
          display: flex;
          border-radius: 40px;
          border: 1px solid var(--Danger-Main, #ff3e13);
          background: var(--Danger-Surface, #faeff2);
          color: var(--Danger-Main, #ff3e13);
        }
      }
    }

    .title {
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));
      text-align: center;

      /* Heading 7/Bold */
      //   font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 30px */
      letter-spacing: 0.1px;
    }
  }
}

@media screen and (max-width: 768px) {
  .containerDetail {
    border-radius: 8px 8px 0px 0px;
  }
}
