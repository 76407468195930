.remarkContainer {
  display: flex;
  width: 633px;
  padding: 24px;

  flex-direction: column;
  gap: 16px;
  border-radius: 8px;
  background: var(--Color-Neutal-Neutral-10, #fff);
  .title {
    color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));

    /* Heading 7/SemiBold */
    // font-family: Poppins;
    font-size: calc(16px + (20 - 16) * ((100vw - 280px) / (1600 - 280)));
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 30px */
    letter-spacing: 0.1px;
  }

  .remark {
    color: #000;

    /* Text M/Reguler */
    // font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    max-height: 250px;
    overflow: auto;
  }

  .close {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: 1px solid var(--Primary-Main, #1571de);
    background: var(--Color-Neutal-Neutral-10, #fff);
    padding: 12px 16px;
    color: var(--Primary-Main, #1571de);
    text-align: center;

    /* Text M/Medium */
    // font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    cursor: pointer;
  }
}
