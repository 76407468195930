@import "../../../../theme/variables.scss";

.container {
  background-color: $NEUTRAL_10;
  width: 726px;
  border-radius: 8px;
  max-height: 80vh;
  max-width: 100vw;
  overflow: auto;

  >* {
    padding: 24px;

    &.header {
      padding-bottom: 16px;
      display: flex;
      align-items: center;
      gap: 16px;
      border-bottom: 1px solid $NEUTRAL_40;
      position: sticky;
      top: 0;
      background-color: $NEUTRAL_10;
      z-index: 1;

      >h3 {
        flex-grow: 1;
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
        letter-spacing: 0.12px;
      }

      >button {
        display: grid;
        place-items: center;
        background-color: transparent;
        border: none;
      }
    }

    &.content {
      display: flex;
      flex-direction: column;
      gap: 16px;
      z-index: 0;

      .intro {
        // display: flex;
        // align-items: flex-start;
        // flex-direction: column;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 16px;

        .stackedIntro {
          width: 100%;
          flex: 1;
          display: flex;
          align-items: center;
          gap: 8px;

          div {
            &:first-child {
              display: grid;
              place-items: center;
              padding: 12px;
              background-color: $NEUTRAL_20;
              border-radius: 5px;

              >img {
                height: 16px;
                aspect-ratio: 1;
                object-fit: contain;
              }
            }

            &:last-child {
              display: flex;
              flex: 1;
              flex-direction: column;
              padding: 8px 16px;
              background-color: $PRIMARY_SURFACE;
              border-radius: 4px;

              span {
                font-family: "Poppins";
                font-style: normal;

                &:first-child {
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 16px;
                  color: $NEUTRAL_60;
                }

                &:last-child {
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 20px;
                  color: $NEUTRAL_100;
                }
              }
            }
          }
        }
      }

      .component {
        display: flex;
        flex-direction: column;
        gap: 8px;

        >span {
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 140%;
        }

        .scores {
          border-radius: 8px;
          border: 1px solid $NEUTRAL_40;
          border-spacing: 0;
          overflow: hidden;

          thead {
            background-color: $PRIMARY_SURFACE;

            tr {
              th {
                font-family: Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 140%;
                padding: 12px;
                border-bottom: 1px solid $NEUTRAL_40;
                text-align: left;
                width: 50%;
              }
            }
          }

          tbody {
            tr {

              td {
                padding: 8px 12px;
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                white-space: nowrap;

                &.bold {
                  font-weight: 700;
                }

                >span {
                  font-size: 20px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 150%;
                  letter-spacing: 0.1px;
                }
              }

              &:not(:last-child) {
                td {
                  border-bottom: 1px solid $NEUTRAL_40;
                }

              }

              &:last-child {
                background-color: $NEUTRAL_20;

                // td {

                //   &:last-child {
                //     font-family: Poppins;
                //     font-size: 20px;
                //     font-style: normal;
                //     font-weight: 700;
                //     line-height: 150%;
                //     letter-spacing: 0.1px;
                //   }
                // }
              }
            }
          }
        }

        >p {
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }

        >img {
          border-radius: 8px;
          min-height: 357px;
          max-height: 450px;
          object-fit: cover;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    border-radius: 16px 16px 0 0;

    .content {

      .intro {
        grid-template-columns: 1fr;
      }
    }
  }
}