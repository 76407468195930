@import "../../../../../../theme/variables.scss";

.container {
  width: 100%;

  .topWrapper {
    position: sticky;
    top: 70px;
    z-index: 2;
    border-bottom: 1px solid $NEUTRAL_40;
    background-color: $NEUTRAL_LIGHTER;
  }

  .loadingWrapper {
    height: calc(100vh - 220px);
    display: grid;
    place-items: center;
  }



  .contentWrapper {
    width: 100%;
    // min-height: calc(100vh - 220px);
    z-index: 0 !important;
  }
}

@media screen and (max-width: 768px) {
  .container {
    .breadcrumbWrapper {
      padding: 18px;
      padding-bottom: 8px;
      padding-top: 10px;
    }
  }

  .controlHeader {
    width: 100%;
    padding-inline: 18px !important;
    flex-direction: column;
    height: 100% !important;
    gap: 8px;

    .buttonWrapper {
      // display: none;
      width: 100% !important;
    }

    .SearchBarWrapper {
      width: 100% !important;
    }
  }
}