@import "../../../../../../../theme/variables.scss";

.container {
  margin-top: 16px;
  background-color: $NEUTRAL_10;
  height: 534px;
  border-bottom: 1px solid $NEUTRAL_40;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px 8px 0 0;

  .logo {
    height: 188px;
    aspect-ratio: 1;
    border-radius: 100%;
    display: grid;
    place-items: center;
    background-color: $PRIMARY_SURFACE;

    img {
      height: 84px;
      aspect-ratio: 1;
      object-fit: contain;
    }
  }

  h3 {
    padding-top: 16px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 140%;
    text-align: center;
    letter-spacing: 0.005em;
    color: $NEUTRAL_DARKEST;
  }

  p {
    padding-top: 8px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: $NEUTRAL_70;
    padding-bottom: 24px;
  }

  button {
    padding: 10px;
    background-color: $PRIMARY_MAIN;
    border-radius: 8px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: $NEUTRAL_10;
    width: 203px;
    border: none;
    height: 40px;
  }
}

@media screen and (max-width: 768px) {
  .container {
    margin-top: 8px;
    height: 306px;

    .logo {
      height: 100px;

      img {
        height: 54px;
      }
    }

    h3 {
      padding-top: 16px;
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      letter-spacing: 0.005em;
    }

    p {
      padding-top: 8px;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      padding-bottom: 24px;
    }

    button {
      padding: 10px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      width: 203px;
    }
  }
}
