@import "../../../../theme/variables.scss";

.Container {
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  .Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 32px;
    background-color: $NEUTRAL_20;
    min-width: 107px;
    .ProfileSection {
      & > :first-child {
        font-weight: 600;
        font-size: 16px;
        margin-bottom: 8px;
      }
      .ProfileWrapper {
        display: flex;
        gap: 12px;
        & > :first-child {
          width: 42px;
          height: 42px;
          background-color: $PRIMARY_MAIN;
          border-radius: 50%;
        }
        .Profile {
          display: flex;
          flex-direction: column;
          gap: 4px;
          & > :first-child {
            font-weight: 500;
            font-size: 14px;
          }
          & > :last-child {
            font-weight: 400;
            font-size: 12px;
            color: $NEUTRAL_60;
          }
        }
      }
    }
    .TitleSection {
      & > p {
        font-size: 20px;
        font-weight: 600;
        color: $NEUTRAL_100;
      }
    }
    .ActionSection {
      display: flex;
      gap: 8px;
      .secondaryButton {
        background-color: $NEUTRAL_10;
        border: 1px solid #d42701;
        border-radius: 8px;
        display: flex;
        align-items: center;
        padding: 10px 12px;
        gap: 4px;
        cursor: pointer;

        .label {
          font-size: 14px;
          font-weight: 500;
          color: #d42701;
          white-space: pre;
        }

        .icon {
          color: $DANGER_MAIN;
        }
      }
      .secondaryButton:hover {
        background-color: $NEUTRAL_30;
      }
      .DisabledButton {
        background-color: $NEUTRAL_30 !important;
        opacity: 0.6;
      }
    }
  }
  .PdfContainer {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .PdfWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    > embed {
      width: 100%;
      height: 100%;
    }
  }
  .ImagesPdfWrapper {
    flex-direction: column;
    overflow: auto;
    gap: 8px;
    background-color: #f4f4f4;
    display: flex;
    width: 100%;
    align-items: center;
    padding: 16px;
    > img {
      width: 80vw;
    }
  }
  .ModalWrapper {
    background: $NEUTRAL_10;
    border-radius: 12px;
  }
  .SuccessModal {
    width: 410px;
    height: 310px;
  }
  .ConfirmModal {
    width: 396px;
    height: 216px;
    padding: 16px;
  }
}

@media (width <= 768px) {
  .Container {
    .Header {
      flex-direction: column;
      gap: 16px;
      .TitleSection {
        order: 3;
      }
      .ProfileSection {
        width: 100%;
      }
    }
    .SuccessModal {
      width: 361px !important;
      height: auto !important;
    }
    .ConfirmModal {
      width: 100vw !important;
      height: auto !important;
    }
  }
}
