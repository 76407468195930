@import "../../../../../../../../../theme/variables.scss";

.container {
  width: 100%;
  background-color: $NEUTRAL_10;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;

  img {
    width: 195px;
    aspect-ratio: 1;
  }

  .desc {
    padding-top: 12px;
    padding-bottom: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;


    h3 {
      font-weight: 600;
      font-size: 18px;
      line-height: 150%;
      text-align: center;
      letter-spacing: 0.005em;
      color: $PRIMARY_MAIN;
    }

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      text-align: center;
      color: $NEUTRAL_70;
    }
  }

  .buttonWrapper {
    width: 100%;
    height: 66px;

    &.mobile {
      display: none;
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    padding: 16px;

    img {
      width: 115px;
    }

    .desc {
      gap: 4px;

      h3 {
        font-size: 16px;
      }

      p {
        font-size: 12px;
      }
    }
  }

  .buttonWrapper{
    height: 40px!important;

    &.mobile {
      display: block!important;
    }

    &.desktop {
      display: none;
    }
  }
}