@import "../../../../theme/variables.scss";

.container {
  .profileHead {
    display: flex;
    // flex-direction: column;
    gap: 24px;
    align-items: center;
    padding-bottom: 16px;
    .profilePicture {
      > img {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
    .profileDesc {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .profileInfo {
        display: flex;
        flex-direction: column;
        gap: 8px;
        & > :first-child {
          font-weight: 600;
          font-size: 16px;
          text-align: left;
        }
        .info {
          display: grid;
          grid-template-columns: 120px 1fr;
          // gap: 20px;

          p {
            font-weight: 400;
            font-size: 12px;
            text-align: left;
          }
          & > :first-child {
            color: $NEUTRAL_70;
          }
        }
      }

      .descHead {
        display: flex;
        gap: 12px;
        align-items: center;
        // justify-content: space-between;
        .divider {
          width: 2px;
          height: 26px;
          background: $NEUTRAL_100;
        }
        & > :first-child {
          font-weight: 600;

          font-size: calc(20px + (26 - 20) * ((100vw - 280px) / (1600 - 280)));
        }
        & > :last-child {
          font-weight: 600;
          font-size: calc(14px + (16 - 14) * ((100vw - 280px) / (1600 - 280)));
        }
      }
    }
  }
  .section {
    border-top: 1px solid $SUCCESS_MAIN;
    padding-block: 16px;
    .sectionExp {
      > :first-child {
        font-weight: 600;
        font-size: 16px;
        padding-bottom: 16px;
      }
      .experience {
        display: grid;
        grid-template-columns: 200px 1fr;
        gap: 16px;
        border-bottom: 1px solid $NEUTRAL_30;
        padding-bottom: 16px;
        margin-bottom: 16px;
        .left {
          & > :first-child {
            font-weight: 400;
            font-size: 12px;
            color: $NEUTRAL_70;
          }
          & > :last-child {
            font-weight: 500;
            font-size: 14px;
          }
        }
        .right {
          > p {
            font-weight: 400;
            font-size: 12px;
          }
        }
      }
      & > :last-child {
        border-bottom: none;
        padding-bottom: 0;
        margin-bottom: 0;
      }
      .noExp {
        > p {
          font-weight: 500;
          font-size: 14px;
        }
      }
    }
    .sectionEducation {
      & > :first-child {
        font-weight: 600;
        font-size: 16px;
        margin-bottom: 16px;
      }
      & > :last-child {
        font-weight: 500;
        font-size: 14px;
      }
    }
    .sectionAdditional {
      & > :first-child {
        font-weight: 600;
        font-size: 16px;
        margin-bottom: 16px;
      }
      .wrapper {
        display: flex;
        flex-direction: column;
        gap: 8px;
        .additional {
          display: grid;
          grid-template-columns: 1fr 1fr;
          & > :first-child {
            font-weight: 400;
            font-size: 12px;
          }
          & > :last-child {
            font-weight: 500;
            font-size: 12px;
          }
        }
      }
    }
  }
  .documentSection {
    padding-block: 16px;
    & > :first-child {
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 8px;
    }
    .documentWrapper {
      display: flex;
      flex-direction: column;
      gap: 8px;
      .document {
        border: 1px solid $NEUTRAL_30;
        padding: 20px 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        > .left {
          display: flex;
          align-items: center;
          gap: 16px;
          & > :first-child {
            font-weight: 400;
            font-size: 14px;
          }
        }
        & > :last-child {
          color: $PRIMARY_MAIN;
          cursor: pointer;
        }
      }
    }
  }
  .actionSection {
    padding-block: 16px;
    & > :first-child {
      font-weight: 400;
      font-size: 14px;
      margin-bottom: 8px;
    }
    .actionButton {
      > :first-child {
        padding-block: 12px;
      }
    }
  }
  .textNeutral60 {
    color: $NEUTRAL_60;
  }
}

@media screen and (max-width: 768px) {
  .container {
    .profileHead {
      // flex-direction: column;

      .profilePicture {
        width: 30%;
        > img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .container {
    .profileHead {
      flex-direction: column;
      .profileDesc {
        width: 100%;
        .profileInfo {
          // border-top: 1px solid  $SUCCESS_MAIN;
          padding-top: 20px;
        }

        .descHead {
          justify-content: center;
        }
      }
    }
  }
}
