.container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  height: calc(100vh - 62px - 32px);
  overflow: clip;
  .navigatorWrapper {
    position: sticky;
    top: 70px;
    z-index: 1;
  }

  .loadingWrapper {
    height: calc(100vh - 220px);
    display: grid;
    place-items: center;
  }

  .contentWrapper {
    // height: calc(100vh - 145px);
    overflow-y: auto;
  }

  .schemeDetailBox {
    margin-inline: auto;

    max-width: 1920px;
    display: flex;
    // align-items: center;
    justify-content: space-between;
    gap: 16px;
    width: 100%;
    padding: 24px 24px;

    .detailFLBBox {
      width: 70%;
      height: 100%;
      &.width {
        width: 100%;
      }
    }

    .updateLogsBox {
      width: 100%;
      border-radius: 8px;
      border: 1px solid #e8e8e8;
      background: var(--Color-Neutal-Neutral-10, #fff);
      // height: 100%;
      height: 100%;
      position: sticky;
      top: 24px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .container {
    .schemeDetailBox {
      .stickyResponsive {
        position: fixed;
        right: 0px;
        > div {
          display: flex;
          height: 86px;
          padding: 10px;
          align-items: center;
          justify-content: center;
          border-radius: 12px 0px 0px 12px;
          background: var(--Primary-Main, #1571de);
          box-shadow: 0px 6px 24px 0px rgba(81, 88, 173, 0.25);
          cursor: pointer;
        }
      }
    }
  }
}
