@import "../../../../../../../theme/variables.scss";

.tableHead {
  width: 100% !important;
  background-color: $PRIMARY_SURFACE;

  .showOnMobile {
    display: none;
  }

  .tableHeadRow {
    .tableHeadCell {
      border-bottom: none !important;
      padding: 16px !important;

      span {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: $NEUTRAL_100;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .tableHead {
    .showOnMobile {
      display: table-cell;
    }

    .hideOnMobile {
      display: none;
    }

    .tableHeadRow {
      .tableHeadCell {
        border-bottom: none !important;
        padding-block: 8px !important;

        span {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: $NEUTRAL_100;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        &:last-child {
          width: 25%;
        }
      }
    }
  }
}
