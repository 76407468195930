

@import "../../../../../theme/variables.scss";

.container {
  // width: 100%;
  // min-height: 100%;
  height: calc(100% - 70px);
  overflow-y: scroll;

  .headerWrapper {
    position: sticky;
    top: 0;
    z-index: 3;

  }

  .topWrapper {
    // position: sticky;
    // top: 70px;
    border-bottom: 1px solid $NEUTRAL_40;
    background-color: $NEUTRAL_LIGHTER;

    >* {
      max-width: 1440px;
      margin-inline: auto;
    }

    .Wrapper {
      display: flex;
      align-items: center;
      width: 100%;
      overflow-x: scroll;

      &::-webkit-scrollbar {
        display: none;
      }

      .breadcrumbWrapper {
        padding: 30px;
        padding-right: 0px !important;
        width: 100%;
        padding-bottom: 24px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 24px;

        > span {
          background-color: $NEUTRAL_LIGHTEST;
          width: 40px;
          aspect-ratio: 1;
          display: grid;
          place-items: center;
          border-radius: 50%;
          cursor: pointer;
          flex-shrink: 0;
        }

        .breadcrumbText {
          font-weight: 500;
          font-size: 16px !important;
          line-height: 20px;
          color: $NEUTRAL_60;
          cursor: pointer;
        }

        .breadcrumbText2 {
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          color: $NEUTRAL_100;
        }
      }

      .typeWrapper {
        background-color: $NEUTRAL_20;
        border: 1px solid $NEUTRAL_40;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 15px;
        padding: 6px 8px;
        margin-right: 24px;
        white-space: nowrap;
        text-overflow: ellipsis;

        .DescWrapper {
          display: flex;
          flex-direction: column;

          > span:first-child {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 140%;
            color: $NEUTRAL_100;
          }

          > p:last-child {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: $NEUTRAL_70;
          }
        }

        .contractIcon {
          color: $PRIMARY_MAIN;
        }
      }
    }
  }

  .infoWrapper {
    background-color: #FFE5E5;
    padding: 12px 30px;
    display: flex;
    align-items: center;
    gap: 8px;
  
    >span {
      color: $DANGER_MAIN;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 142.857%;
    }
  }

  .controlHeader {
    width: 100%;
    height: 46px;
    padding-inline: 30px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    padding-bottom: 10px;

    .searchBarWrapper {
      height: 36px;
      width: 356px;
    }

    .buttonWrapper {
      height: 36px;
      width: 104px;
    }
  }

  .stepsWrapper {
    // padding-bottom: 30px;
    padding-inline: 0;
    padding-top: 0;
    width: 100%;
    height: 100%;
    background-color: $NEUTRAL_LIGHTER;
    overflow-x: hidden;
  }

  .contentWrapper {
    width: 100%;
    // height: 100%;
    // min-height: calc(100vh - 250px);
    // overflow-x: hidden;
    // height: calc(100vh - 270px);
    overflow: auto;
    z-index: 0 !important;
  }

  .errorWrapper,
  .loadingWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 280px);
  }
}

// @media screen and (min-width: 769px) and (max-width: 1140px) {
//   .Wrapper {
//     display: block !important;
//     padding-bottom: 24px;
//   }
// }

@media screen and (max-width: 768px) {
  .container {
    .breadcrumbWrapper {
      padding: 18px !important;
      padding-bottom: 8px !important;
      padding-top: 10px;
    }

    .topWrapper {
      z-index: 1;
    }

    .typeWrapper {
      display: none !important;
    }

    .stepsWrapper {
      padding-bottom: 14px;
      // overflow-x: scroll;
    }
  }

  // .contentWrapper {
  //   height: calc(100vh - 230px)!important;
  // }

  .controlHeader {
    width: 100%;
    padding-inline: 18px !important;

    .buttonWrapper {
      display: none;
    }

    .searchBarWrapper {
      width: 100% !important;
    }
  }
}
