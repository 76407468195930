@import "../../../../../theme/variables.scss";

.ContractSignContainer {
  width: 808px;
  display: flex;
  padding: 20px;
  flex-direction: column;
  gap: 24px;

  .Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    > img {
      height: 41px;
      object-fit: contain;
    }

    .Logo {
      display: flex;
      gap: 16px;
      align-items: center;

      & > :first-child {
        background-color: #f3f5f7;
        width: 56px;
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;

        > img {
          width: 26px;
          height: 38px;
        }
      }

      & > :last-child {
        display: flex;
        flex-direction: column;

        > span {
          font-family: Poppins;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          color: $NEUTRAL_60;
        }

        > p {
          font-weight: 600;
          font-size: 16px;
        }
      }
    }
  }

  .section {
    padding-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    align-items: center;
    justify-content: center;
  }

  .firstSection {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    .cardWarning {
      border-radius: 8px;
      border-right: 1px solid var(--Neutral-Stroke, #ededed);
      border-bottom: 1px solid var(--Neutral-Stroke, #ededed);
      border-left: 1px solid var(--Neutral-Stroke, #ededed);
      background: var(--Neutral-10, #fff);
      position: relative;
      //   padding: 12px;
    }
    .warn {
      padding: 12px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      .warnH1 {
        //styleName: Heading 4/SemiBold;
        // font-family: Poppins;
        color: #1571de;
        font-size: calc(16px + (20 - 16) * ((100vw - 280px) / (1600 - 280)));
        font-weight: 600;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: left;
      }
      .box {
        display: flex;
        align-items: center;
        gap: 8px;
        .p {
          //styleName: Text M/Regular;
          //   font-family: Poppins;
          color: #404040;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0em;
          text-align: left;
        }
      }
    }

    .blueBorder {
      width: 100%;
      position: relative;
      top: 0;
      background: var(--Primary-Main, #1571de);
      height: 4px;
      border-radius: 8px 8px 0px 0px;
    }

    .iconInform {
      width: 20px;
    }
  }
  .imagesBox {
    img {
      width: 250px;
      height: 100%;
    }
  }

  .thirdSection {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 16px;
  }

  .descLocation {
    display: flex;
    flex-direction: column;
    gap: 4px;
    .descP {
      //styleName: Text L/Medium;
      //   font-family: Poppins;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: center;
      color: #0a0a0a;
    }
    .descText {
      //styleName: Text M/Regular;
      //   font-family: Poppins;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: center;
      color: #9e9e9e;
    }
  }

  .fourthSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    width: 100%;
    .nextButton {
      background-color: #1571de;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      border-radius: 8px;
      //   gap: 4px;
      width: 70%;
      //styleName: Text M/Medium;
      //   font-family: Poppins;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: center;
      color: #ffffff;
      cursor: pointer;
    }

    .buttonRefresh {
      background-color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      border-radius: 8px;
      //   gap: 4px;
      width: 70%;
      border: 1px solid #1571de;

      //styleName: Text M/Medium;
      //   font-family: Poppins;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: center;
      color: #1571de;
      cursor: pointer;
    }

    .buttonNextDisabled {
      background-color: #b1d0f4;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      border-radius: 8px;
      //   gap: 4px;
      width: 70%;
      //styleName: Text M/Medium;
      //   font-family: Poppins;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: center;
      color: #ffffff;
    }
  }
}

.notifBlockLocation {
  height: 100vh;
  width: 100vw;
  background-color: #00000080;
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;

  .modalNotif {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    background: var(--neutral-10, #fff);
    width: 396px;
    // height: 50vh;
    // height: 89%;
    overflow: auto !important;
    padding: 16px;
    gap: 24px;
    .top {
      display: flex;
      flex-direction: column;
      gap: 8px;
      align-items: center;
      justify-content: center;
      .p {
        //styleName: Text M/Regular;
        // font-family: Poppins;
        font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: center;
      }
      .H1 {
        //styleName: Heading 4/SemiBold;
        // font-family: Poppins;
        font-size: 20px;
        font-weight: 600;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: center;
      }
    }

    .bottom {
      padding: 8px;
      border-radius: 8px;
      border: 1px solid #9e9e9e;
      gap: 4px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #ffffff;
      //styleName: Text M/Medium;
      //   font-family: Poppins;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: center;
      color: #0a0a0a;
      cursor: pointer;
    }
  }
}

@media (width <=768px) {
  .ContractSignContainer {
    padding: 16px;
    .firstSection {
      .cardWarning {
        border: none;
        background: none;
      }

      .warn {
        padding: 0px;
      }

      .blueBorder {
        display: none;
      }
    }
  }
}

@media (width <=500px) {
  .ContractSignContainer {
    // padding: 16px;
    .imagesBox {
      img {
        width: 200px;
        height: 100%;
      }
    }

    .fourthSection {
      .nextButton {
        width: 100%;
      }
      .buttonRefresh {
        width: 100%;
      }
      .buttonNextDisabled {
        width: 100%;
      }
    }
  }
}
