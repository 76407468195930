@import "../../../../../../../../theme/variables.scss";

.container {
  > h4 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 150%;
    letter-spacing: 0.005em;
    color: $PRIMARY_MAIN;
    padding-bottom: 16px;
  }

  .demandContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-bottom: 16px;
  }

  .equipmentsContainer {
    padding: 16px;
    border-radius: 8px;
    border: 1px solid $PLATINUM_SOLID;

    h5 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      color: $NEUTRAL_100;
      padding-bottom: 16px;
    }

    .content {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .stacked {
        display: flex;

        > * {
          flex-grow: 1;
          font-family: "Poppins";
          font-style: normal;

          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: $NEUTRAL_DARKEST;

          &:first-child {
            font-weight: 400;
          }

          &:last-child {
            font-weight: 500;
            text-align: right;
          }
        }
      }
    }
  }
}
