@import "../../../../../../theme/variables.scss";

.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .titleWrapper {
    display: flex;
    align-items: center;
    padding-top: 16px;

    > h4 {
      flex-grow: 1;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
    }
  }

  .topBarWrapper {
    display: flex;
    align-items: center;
    gap: 16px;

    .backBox {
      flex-grow: 1;
      display: flex;
      gap: 12px;

      font-size: 14px;
      font-weight: 400;
      color: $PRIMARY_MAIN;
      cursor: pointer;
    }

    .searchBarBox {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  h4 {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: $NEUTRAL_100;
    padding-bottom: 8px;
  }

  .tableWrapper {
    max-height: 290px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    // overflow-y: scroll !important;
    border: 1px solid #d9d9d9;
    border-radius: 8px;

    .tableContainer {
      border-radius: 8px;
      flex-grow: 1;

      .table {
        border-collapse: separate;

        .tableHead {
          position: sticky;
          top: 0;
          background-color: $NEUTRAL-10;
          z-index: 1;
          overflow-y: hidden;

          .tableHeadRow {
            .tableHeadCell {
              padding: 14px 16px !important;
            }
          }
        }

        .tableBody {
          .tableRow {
            &.selected {
              background-color: $PRIMARY_SURFACE;
            }

            .tableCell {
              padding: 12px 16px !important;
              span {
                font-size: 12px;
              }

              .stackedProfile {
                background-color: transparent;
                border: none;
                display: flex;
                align-items: center;
                gap: 8px;
                width: 160px;
              }
            }

            .buttonIcon {
              display: grid;
              place-items: center;
              background-color: transparent;
              border: none;
              color: $PRIMARY_MAIN;

              &.expand {
                position: relative;
              }

              &:disabled {
                color: $NEUTRAL_60;
              }

              > img {
                height: 24px;
                aspect-ratio: 1;
                z-index: 0;
              }
            }
          }
        }
      }

      .bottom {
        width: 100%;
        height: 24px;
      }
    }
  }

  .emptyWrapper {
    flex-grow: 1;
    height: 476px;
  }

  .emptyDialogWrapper {
    flex-grow: 1;
    height: 200px;
  }

  .paginationWrapper {
    border-top: 1px solid $NEUTRAL_50;
  }

  .checkBoxWrapper {
    display: grid;
    align-items: center;
    grid-template-columns: 50% 50%;
    gap: 8px;

    .containerSend {
      display: flex;
      align-items: center;
      gap: 16px;
      padding: 12px;
      border: 1px solid $NEUTRAL-40;
      border-radius: 12px;
    }

    .containerSendDisable {
      display: flex;
      align-items: center;
      gap: 16px;
      padding: 12px;
      border: 1px solid $NEUTRAL-40;
      border-radius: 12px;
      background-color: $NEUTRAL-20;
      color: $NEUTRAL_60;
    }

    .containerSendEmailError {
      display: flex;
      // justify-content: center;
      align-items: center;
      color: $PRIMARY_MAIN;
      gap: 8px;
    }
  }
}

.buttonsWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 16px;
  padding-bottom: 24px;
  gap: 8px;

  button {
    flex-grow: 1;
    padding: 8px 16px;
    border-radius: 8px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    transition: all 0.3s;

    &.submitOnly {
      flex-grow: 0;
    }

    &:first-child {
      background-color: $NEUTRAL_10;
      border: 1px solid $PRIMARY_MAIN;
      color: $PRIMARY_MAIN;
    }

    &:last-child {
      background-color: $PRIMARY_MAIN;
      border: none;
      color: $NEUTRAL_10;

      &.process {
        background-color: $PRIMARY_SOFT;
      }

      &:disabled {
        background-color: $PRIMARY_DISABLED;
        border-color: $PRIMARY_DISABLED;
      }
    }
  }
}

.errorWrapper {
  display: flex;
  padding: 12px;
  color: $DANGER_MAIN;
  font-size: 14px;
}

@media screen and (max-width: 485px) {
}
