@import "../../../../theme/variables.scss";

.container {
  background-color: $NEUTRAL_10;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.12);
  width: 451px;
  max-width: 85vw;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .header {
    display: flex;
    align-items: center;
    gap: 16px;

    >h3 {
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
      flex-grow: 1;
    }

    button {
      display: grid;
      place-items: center;
      background-color: transparent;
      border: none;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      color: $DANGER_MAIN;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .dateWrapper {
      // display: grid;
      // grid-template-columns: 1fr auto 1fr;
      // grid-template-rows: auto;
      display: flex;
      align-items: center;
      gap: 8px;

      >* {

        &:first-child,
        &:last-child {
          width: calc(100% - 20px - 12px);
        }

        &:nth-child(2) {
          flex-shrink: 0;
        }
      }
    }

    >button {
      margin-top: 8px;
      padding: 10px 12px;
      border-radius: 8px;
      background-color: $PRIMARY_MAIN;
      border: none;
      color: $NEUTRAL_10;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;

      
    }
  }
}


@media screen and (max-width: 768px) {
  .container {

    .dateWrapper {
      flex-direction: column;
      padding: 0;

      >* {
        width: 100%!important;
      }
    }
  }
}