.rightVideoFiles {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 16px;
  padding: 16px;

  .textTitle {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    // gap: 34px;
    width: 100%;
    .textTop {
      color: var(--neutral-90, #404040);
      text-align: center;

      /* Text L/Bold */
      // font-family: Poppins;
      font-size: calc(14px + (16 - 14) * ((100vw - 280px) / (1600 - 280)));
      font-style: normal;
      font-weight: 700;
      line-height: 140%; /* 22.4px */
      > span {
        color: var(--Danger-Main, #ff3e13);
        // font-family: Poppins;
        font-size: calc(14px + (16 - 14) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 142.857% */
      }
    }
    .warningBox {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      padding: 8px;
      border-radius: 8px;
      background: var(--primary-surface, #f5f7fe);
      width: 100%;
      .boxWarn {
        display: flex;
        align-items: center;
        gap: 8px;
        // width: 100%;
      }

      .textWarn {
        color: #000;
        width: 100%;
        text-align: center;

        /* Text M/Reguler */
        //   font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        // width: 100%;
        //   line-height: 140%; /* 22.4px */
      }
    }
  }
}
.imagesBox {
  width: 50%;
  height: 764px;
  .img {
    width: 100%;
    height: 100%;
  }
}
.imagesPreview {
  width: 100%;
  // height: 764px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  .iconbox {
    position: absolute;
    bottom: 0;
    // padding: 16px;
    padding-bottom: 20px;
    padding-left: 50px;
    padding-right: 50px;
    display: flex;
    align-items: center;
    gap: 4px;
  }
  .iconPlay {
    border-radius: 100px;
    background: var(--neutral-10, #fff);
    display: flex;
    width: 40px;
    height: 40px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  img {
    width: 430px;
    height: 764px;
    object-fit: cover;
  }
  .video {
    width: 430px;
    // height: 100%;
    height: 764px;
    object-fit: cover;
  }
}

.uploadBox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;

  .middleText {
    cursor: pointer;
    border-radius: 16px;
    padding: 16px;
    border: 1px dashed var(--neutral-50, #c2c2c2);
    background: var(--neutral-30, #ededed);
    width: 430px;
    // width: 430px;
    height: 764px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
    // z-index: 2;
    &.redborder {
      border: 1px dashed red;
    }
    .dragAndDrop {
      color: var(--neutral-100, #0a0a0a);
      text-align: center;

      /* Text M/Medium */
      // font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
    }
    .p {
      color: var(--neutral-50, #c2c2c2);
      text-align: center;

      /* Text S/Reguler */
      // font-family: Poppins;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 153.846% */
    }
    .route {
      color: var(--primary-main, #1571de);
      text-align: center;
      // font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
      text-decoration-line: underline;
    }
    .imagesBoxUpload {
      width: 40px;
      height: 40px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.actionImages {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 8px;
  .deleteFiles {
    width: 100%;
    display: flex;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    // flex: 1 0 0;
    border-radius: 8px;
    border: 1px solid var(--danger-main, #ff3e13);
    background: var(--neutral-10, #fff);
    color: var(--danger-main, #ff3e13);
    text-align: center;
    cursor: pointer;
    /* Text M/Reguler */
    // font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
  .uploadAgain {
    cursor: pointer;
    border-radius: 8px;
    border: 1px solid var(--primary-main, #1571de);
    background: var(--neutral-10, #fff);
    width: 100%;
    display: flex;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    // flex: 1 0 0;
    color: var(--primary-main, #1571de);
    text-align: center;

    /* Text M/Reguler */
    // font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
}

.boxDrop {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}
.dropDownImagesShow {
  //   position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid var(--neutral-40, #e0e0e0);
  background: var(--neutral-10, #fff);
  .top {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // padding: 16px;
  }
  .leftContent {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .exempleText {
    color: var(--neutral-90, #404040);

    /* Text M/Reguler */
    // font-family: Poppins;
    font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    position: relative;
  }
  .dropdownContent {
    // padding: 16px;
    // padding-bottom: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100%;
    // width: 345px;
    // width: 430px;
    height: 100%;
    img {
      width: 345px;
      height: 600px;
      position: absolute;
      // background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #000000 150%);
      border-radius: 50px;
      // padding: 8px
    }
    .video {
      // position: relative;
      background-color: #000;
      width: 320px;
      // padding: 8px;
      border-radius: 65px;
      height: 600px;
      object-fit: cover;
    }
  }
  .dropdownContentImages {
    position: relative;
    // padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    // width: 430px;

    height: 600px;
    .imgTemplate {
      width: 345px;
      height: 600px;
      position: absolute;
      // background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #000000 150%);
      border-radius: 50px;
    }
    .backgroundImage {
      background-color: #000;
      width: 320px;
      height: 570px;
      border-radius: 30px;
    }
    .img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px;
      // background-color: #000;
    }
  }
}

.iconCross {
  cursor: pointer;
}

.iconPlay {
  border-radius: 100px;
  background: var(--neutral-10, #fff);
  display: flex;
  width: 50px;
  height: 50px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: 270px;
}

@media screen and (max-width: 1200px) {
  .iconPlay {
    top: 180px;
  }
  .dropDownImagesShow {
    .dropdownContentImages {
      height: 420px;
      .imgTemplate {
        width: 245px;
        height: 420px;
        border-radius: 35px;
      }
      .backgroundImage {
        width: 220px;
        height: 400px;
        border-radius: 20px;
      }
    }
    // height: 500px;
    .dropdownContent {
      // height: 420px;
      img {
        width: 245px;
        height: 410px;
        border-radius: 35px;
      }
      .video {
        width: 220px;
        height: 410px;
        border-radius: 30px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .uploadBox {
    .middleText {
      width: 100%;
    }
  }
  .imagesPreview {
    img {
      width: 100%;
      height: 100%;
      // object-fit: none;
      // width: 50%;
    }
    .video {
      width: 100%;
    }
  }
}

@media screen and (max-width: 420px) {
  .iconPlay {
    top: 150px;
    width: 30px;
    height: 30px;
  }
  .dropDownImagesShow {
    .dropdownContentImages {
      // padding: 16px;
      .imgTemplate {
        width: 90%;
        height: 100%;
        border-radius: 35px;
      }
      .backgroundImage {
        // border-radius: 50px;

        width: 85%;
        // height: 100%;
      }
    }
    .dropdownContent {
      // height: 330px;
      img {
        width: 189px;
        height: 325px;
        // width: 100%;
        border-radius: 30px;
      }
      .video {
        width: 175px;
        height: 325px;

        // width: 90%;
        // height: 100%;
      }
    }
  }

  .actionImages {
    flex-direction: column;
  }
}

@media screen and (max-width: 360px) {
  .iconPlay {
    top: 100px;
    width: 30px;
    height: 30px;
  }
  .dropDownImagesShow {
    .dropdownContentImages {
      .imgTemplate {
        border-radius: 25px;
      }
    }
    .dropdownContent {
      // height: 235px;
      img {
        width: 125px;
        height: 225px;
        // width: 100%;
        border-radius: 20px;
      }
      .video {
        width: 120px;
        height: 225px;
        border-radius: 20px;
        // width: 90%;
        // height: 100%;
      }
    }
  }

  .actionImages {
    flex-direction: column;
  }
}
