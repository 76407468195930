@import "../../../../../theme/variables.scss";

.ModalContainer {
  background-color: $NEUTRAL_10;
  min-width: 514px;
  .ModalHeader {
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > :first-child {
      font-size: 20px;
      font-weight: 600;
      color: $NEUTRAL_100;
    }
    .Icon {
      cursor: pointer;
      display: block;
    }
  }
  .ModalBody {
    padding: 16px 16px 1px 16px;
    & > :first-child {
      font-size: 14px;
      font-weight: 400;
      color: $NEUTRAL_100;
      text-align: center;
    }
    .ModalSelectWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 16px;
      margin-block: 24px;
      width: 100%;
      .ModalSelection {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        padding: 16px;
        width: 50%;
        border: 1px solid #ededed;
        cursor: pointer;
        .IconWrapper {
          border-radius: 50%;
          background: #1571de1a;
          align-items: center;
          width: 84px;
          height: 84px;
          display: flex;
          justify-content: center;
          align-items: center;

          .ModalDocumentIcon {
            color: $PRIMARY_MAIN;
          }
        }
        .IconText {
          font-size: 24px;
          font-weight: 500;
          color: $NEUTRAL_100;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .ModalContainer {
    min-width: 100px !important;
    .ModalHeader {
        justify-content: center;
      .Icon {
        display: none !important;
      } 
    }
  }
}
