@import "../../../../../theme/variables.scss";

.container {
  max-width: 1164px;
  padding-bottom: 40px;
  padding-inline: 30px;
  height: 100vh;
  overflow: hidden;

  .topWrapper {
    display: flex;
    flex-direction: column;
    gap: 30px;
    border-bottom: 1px solid #ece6e6;
    // position: sticky;
    // top: 0;
    background-color: $NEUTRAL_10;
    z-index: 3;
    padding-top: 40px;

    .logoWrapper {
      display: flex;
      align-items: center;
      gap: 16px;

      > div {
        height: 52px;
        aspect-ratio: 1;
        border-radius: 50%;
        display: grid;
        place-items: center;
        background-color: #f3f5f7;

        > img {
          width: 40%;
        }
      }
    }

    .stepControllerWrapper {
      display: flex;
      // align-items: center;
      // gap: 5%;

      > *:nth-child(2) {
        flex-grow: 1;
        overflow: auto;
      }

      button {
        display: grid;
        place-items: center;
        background-color: #f4f4f4;
        height: 46px;
        aspect-ratio: 1;
        border-radius: 50%;
        border: none;
        color: #cfcfcf;
      }
    }
  }

  .mainWrapper {
    overflow: auto !important;
    height: 100%;
    padding-bottom: 24px;

    &::-webkit-scrollbar {
      width: 5px;
      height: 0;
      border-radius: 8px;
    }

    &::-webkit-scrollbar-track {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      margin: 10px;
    }

    &::-webkit-scrollbar-thumb:vertical {
      background-color: darkgray;
    }

    .buttonWrapper {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-top: 16px;

      button {
        background: $PRIMARY_MAIN;
        border-radius: 8px;
        padding: 10px 16px;
        border: none;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: $NEUTRAL_10;

        &:disabled {
          background-color: $PRIMARY_DISABLED;
        }
      }
    }

    .errorWrapper {
      margin-top: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 12px;
      background-color: $DANGER_SOFT;
      border-radius: 8px;
      gap: 8px;

      .errorText {
        color: var(--Helper, #f5610d);
        /* Caption 1/Reguler */
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        /* 133.333% */
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    max-width: 1164px;
    padding-inline: 0px;
    padding-bottom: 24px;

    .topWrapper {
      display: flex;
      flex-direction: column;
      gap: 30px;
      border-bottom: 1px solid #ece6e6;
      position: sticky;
      top: 0;
      background-color: $NEUTRAL_10;
      z-index: 3;
      padding-top: 24px;

      .logoWrapper {
        display: flex;
        align-items: center;
        gap: 16px;
        padding-inline: 18px;

        > div {
          height: 52px;
          aspect-ratio: 1;
          border-radius: 50%;
          display: grid;
          place-items: center;
          background-color: #f3f5f7;

          > img {
            width: 40%;
          }
        }
      }

      .stepControllerWrapper {
        display: flex;
        // align-items: center;
        // gap: 5%;

        > *:nth-child(2) {
          flex-grow: 1;
          overflow: auto;
        }

        button {
          display: none;
        }
      }
    }

    .mainWrapper {
      padding-bottom: 70px;
    }

    .contentWrapper {
      padding-inline: 18px;
    }

    .buttonWrapper {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-inline: 18px;

      button {
        background: $PRIMARY_MAIN;
        border-radius: 8px;
        padding: 10px 16px;
        border: none;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: $NEUTRAL_10;
        // width: 100%;

        &:disabled {
          background-color: $PRIMARY_DISABLED;
        }
      }
    }
  }
}
