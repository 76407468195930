@import "../../../../theme/variables.scss";

.container {
  background-color: $NEUTRAL_10;
  border-radius: 8px;
  padding: 24px;
  width: 541px;
  max-height: 85vh;
  overflow: auto;

  .autocomplete {
    display: flex;
    flex-direction: column;
    gap: 4px;

    &.z2 {
      z-index: 2;
    }

    p {
      font-family: Poppins;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }

    .fieldWrapper {
      position: relative;

      .addNewButton {
        position: absolute;
        z-index: 1;
        right: 60px;
        top: 14px;
        display: flex;
        align-items: center;
        gap: 4px;
        color: $PRIMARY_MAIN;
        background-color: transparent;
        border: none;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
      }
    }
  }

  .asterisk {
    color: $DANGER_MAIN;
  }

  .ErrorWrapper {
    text-align: right;
    color: $DANGER_MAIN;

    &.Visible {
      opacity: 1;
    }

    &.hide {
      opacity: 0;
    }
  }

  &.mobile {
    padding: 0px;
    border-radius: 20px 20px 0px 0px;
    padding-top: 0;
    width: 100%;

    .topWrapper {
      border-radius: 20px 20px 0px 0px;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: sticky;
      top: 0;
      z-index: 3;
      background-color: inherit;
      padding-top: 15px;
      border-bottom: 1.5px solid #E8E8E8;
    }

    .topLine {
      background-color: #b4b7ba;
      height: 5px !important;
      width: 42px;
      border-radius: 5px;
    }

    .header {
      padding-top: 17px;
      padding-bottom: 20px;

    }

    .form {
      max-height: 70vh;
      padding-top: 16px;
      padding-inline: 18px;
    }

    .buttonsWrapper {
      padding-top: 8px;
      border-top: 1.5px solid #E8E8E8;
      margin-inline: -18px;
      padding-inline: 18px;
      padding-bottom: 24px;
      background-color: $NEUTRAL_10;

      button {
        width: 50%;
      }

    }

    .stacked {
      flex-direction: column;
      gap: 16px;
    }
  }

  .header {
    text-align: center;
    padding-bottom: 16px;

    h3 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      text-align: center;
      color: $NEUTRAL_DARKEST;
    }
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow-y: scroll;

    .stacked {
      display: flex;
      align-items: flex-start;
      gap: 8px;

      >* {
        width: 50%;
      }
    }

    .buttonsWrapper {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 12px;
      position: sticky;
      bottom: 0;
      background-color: $NEUTRAL_10;

      button {
        padding: 8px 16px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        border-radius: 8px;

        &:first-child {
          background-color: $NEUTRAL_10;
          border: 1px solid $NEUTRAL_100;
          color: $NEUTRAL_100;
        }
        &:last-child {
          background-color: $PRIMARY_MAIN;
          border: none;
          color: $NEUTRAL_10;

          &:disabled {
            background-color: $PRIMARY_DISABLED !important;
          }
        }
      }
    }
  }
}