@import "../../../theme/variables.scss";

.container {
  background-color: $NEUTRAL_10;
  border-radius: 8px;
  border: 1px solid $NEUTRAL_30;
  box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.12);
  padding: 16px;

  &.multiple {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .title {
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    color: $NEUTRAL_100;
    padding-bottom: 6px;
    text-align: left;
  }

  .content {

    >button {
      display: flex;
      align-items: center;
      white-space: nowrap;
      background-color: transparent;
      border: none;
      gap: 8px;
      width: 100%;
      padding-top: 10px;
      padding-bottom: 8px;
      padding-inline: 4px;

      &:disabled {
        >* {
          color: $NEUTRAL_50!important;
        }
      }

      // &:not(:first-child) {
      // }

      &:not(:last-child) {
        border-bottom: 1px solid $NEUTRAL_30;
      }

      &:hover {
        background-color: $NEUTRAL_20;
        // >span {
        //   // font-weight: 500;
        // }
      }

      >span {
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        display: block;




      }
    }
  }
}

// @media screen and (max-width : 768px) {

//   .container {

//   }
// }