@import "../../../../theme/variables.scss";

.container {
  background-color: $NEUTRAL_10;
  border-radius: 8px 8px 0px 0px;
  max-height: 80vh;
  overflow: auto;

  &.extraPadding {
    padding-bottom: 36px;
  }

  .topLineWrapper {
    height: 12px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: sticky;
    top: 0;
    background-color: $NEUTRAL_10;


    .topLine {
      width: 44px;
      height: 4px;
      border-radius: 50px;
      background-color: $NEUTRAL_50;
    }
  }

  .content {
    >* {
      padding: 16px;

      &:not(:last-child) {
        border-bottom: 1px solid $NEUTRAL_40;
      }

      &:nth-last-child(2) {
        border-bottom: none;
      }


      &.profileWrapper {
        position: sticky;
        top: 12px;
        background-color: $NEUTRAL_10;

        .profileCard {
          padding: 8px 16px;
          background-color: $NEUTRAL_20;
          border-radius: 8px;
          display: flex;
          align-items: center;
          gap: 8px;

          >img {
            height: 36px;
            aspect-ratio: 1;
            object-fit: cover;
            border-radius: 50%;
          }

          >span {
            font-family: Poppins;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            color: E$NEUTRAL_100;
          }
        }
      }

      &.descWrapper {
        display: flex;
        flex-direction: column;
        gap: 24px;

        .desc {
          display: flex;
          flex-direction: column;
          gap: 4px;

          >span {

            &:first-child {
              font-family: Poppins;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 20px;
              color: $NEUTRAL_100;
            }

            &:nth-child(2) {
              font-family: Poppins;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 140%;
              color: $NEUTRAL_90;
            }
          }
        }

        >button {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 8px 12px;
          gap: 4px;
          color: $PRIMARY_MAIN;
          background-color: $NEUTRAL_10;
          border: 1px solid $PRIMARY_MAIN;
          border-radius: 8px;

          span {
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
          }
        }
      }

      &.actionsWrapper {
        display: flex;
        align-items: center;
        gap: 7px;
        padding-bottom: 48px;
        position: sticky;
        bottom: 0;
        border-top: 1px solid $NEUTRAL_40;
        background-color: $NEUTRAL_10;


        >* {
          width: 50%;
          padding: 8px 12px;
          border-radius: 8px;
          border: none;
          color: $NEUTRAL_10;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 4px;

          &:first-child {
            background-color: $DANGER_MAIN;
          }

          &:last-child {
            background-color: $PRIMARY_MAIN;
          }

          &.rejectedButton {
            background-color: $DANGER_SURFACE;
            color: $DANGER_MAIN;
            width: 100%;
          }
    
          &.acceptedButton {
            background-color: $SUCCESS_SURFACE;
            color: $SUCCESS_MAIN;
            width: 100%;
          }

          &.rejected, &.noresponse {
            background-color: transparent;
            color: $DANGER_MAIN!important;
            border: none;
            cursor: auto;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            padding: 0;
            width: 100%;
    
            &.noresponse {
              color: $NEUTRAL_100!important;
            }
          }


        }
      }
    }
  }
}