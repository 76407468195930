@import "../../../theme/variables.scss";

.container {
  height: 100%;
  width: 100%;
  border-radius: 8px;
  background-color: $NEUTRAL_20;
  border: 1px dashed $NEUTRAL_50;
  min-height: 155px;

  &.error {
    border-color: #f5610d !important;
  }

  &.disabled {
    border: 1px solid var(--Color-Neutal-Neutral-40, #e0e0e0);

    background: var(--Color-Neutal-Neutral-30, #ededed);
  }
}

.content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
}

.uploadIcon {
  color: $NEUTRAL_50;
}

.text {
  padding: 2px 0 2px 0;
  &.disabled {
    color: #9e9e9e;
    text-decoration: none;
    cursor: unset;
  }

  &.transparent {
    opacity: 0;
  }
}

.mainText {
  font-size: 13px;
  font-weight: 500;
  color: $NEUTRAL_100;
  text-align: center;
}

.middleText {
  font-size: 13px;
  font-weight: 400;
  color: $NEUTRAL_50;
}

.linkText {
  font-size: 13px;
  font-weight: 500;
  color: $PRIMARY_MAIN;
  text-decoration: underline;
  cursor: pointer;
}

.filenameText {
  font-size: 13px;
  font-weight: 400;
  color: $NEUTRAL_100;
  display: -webkit-box;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fileImage {
  height: 72px;
  width: 146px;
  border-radius: 8px;
  cursor: pointer;
  object-fit: cover;
}
.imageSkeleton {
  height: 72px;
  width: 146px;
  border-radius: 8px;
  border: 1px solid $NEUTRAL_40;
  background-color: transparent;
  color: $NEUTRAL_70;
  display: grid;
  place-items: center;
}

.loadingContainer {
  width: 90px;
  height: 72px;
}

@media screen and (max-width: 768px) {
  .container {
    background-color: $NEUTRAL_10;
  }
}
