@import "../../../theme/variables.scss";

.container {
  // height: 100%;
  width: 100%;
  display: flex;
  &.redborder {
    border: 1px solid #f5610d;
    border-radius: 8px;
  }

  &.error {

    .input {
      border-color: #F5610D !important;
    }
  }


  .input:disabled {
    outline: none !important;
    border-color: $NEUTRAL_40;
    border-width: 1px;
    border-style: solid;
    background-color: $NEUTRAL_30;
    color: $NEUTRAL_60;
  }
}

.errorText {
  font-size: 12px;
  color: #f5610d;
}

.input {
  width: 100%;
  padding: 12px;
  border-radius: 0 8px 8px 0;
  border: 1px solid $NEUTRAL_40;
  transition: 0.2s;
}

.input:focus {
  outline: none !important;
  border-color: $PRIMARY_MAIN;
  border-width: 1px;
  border-style: solid;
}

.input {
  appearance: none;
  -webkit-appearance: none;
}
.input::-webkit-inner-spin-button,
.input::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.phoneCodeWrapper {
  background-color: $NEUTRAL_40;
  border-radius: 8px 0 0 8px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: 400;
  color: $NEUTRAL_60;
}
