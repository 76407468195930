@import "../../../theme/variables.scss";

.container {
  background-color: $PRIMARY_SOFT;
  padding: 24px;
  padding-top: 12px;
  padding-bottom: 32px;
  border-radius: 12px;
  box-shadow: 0px 0px 1px 0px rgba(9, 30, 66, 0.31), 0px 3px 5px 0px rgba(9, 30, 66, 0.20);
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
  overflow: hidden;

  >* {
    z-index: 1;
  }

  >img {
    position: absolute;
    right: -187px;
    top: -10px;
    width: 700px;
    object-fit: cover;
    z-index: 0;
  }

  .header {

    >img {
      height: 34px;
      object-fit: contain;
    }

  }

  .content {
    display: flex;
    gap: 20px;

    >img {
      width: 164px;
      aspect-ratio: 1;
      object-fit: cover;
      border-radius: 50%;
    }

    .desc {
      display: flex;
      flex-direction: column;
      gap: 4px;

      >h3 {
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
        letter-spacing: 0.12px;
      }

      >span {
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
      }

      .contact {
        display: flex;
        align-items: center;
        gap: 12px;

        >span {
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 140%;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    // height: 421px;
    padding-bottom: 100px;

    >img {
      top: auto;
      bottom: -263px;
      transform: rotate(20deg);
    }

    .content {
      flex-direction: column;
      align-items: center;

      .desc {
        align-items: center;
      }
    }
  }
}