@import "../../../../../../../../theme/variables.scss";

.container {
  width: 100%;
  min-height: 100%;
  border-radius: 8px;
  background-color: $NEUTRAL_10;
  padding: 24px;

  .header {
    display: flex;
    align-items: center;
    gap: 18px;
    padding-bottom: 16px;


    h3 {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 150%;
      letter-spacing: 0.005em;
      color: $NEUTRAL_100;
    }

  }


  
}

@media screen and (max-width: 768px) {
  .container {
    padding: 0;
    background-color: transparent;

    .header {
      padding-bottom: 8px;

      h3 {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 140%;
      }

    }
  }
}