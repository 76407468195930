@import "../../../theme/variables.scss";

.container {
  display: flex;
  padding: 4px 12px;
  border-radius: 4px;
  border: 1px solid $NEUTRAL_40;
  background-color: $NEUTRAL_10;
  align-items: center;
  gap: 10px;
}

.icon {
  cursor: pointer;
}

.labelFont {
  font-size: 12px;
  font-weight: 400;
  color: $NEUTRAL_90;
}
