@import "../../../../theme/variables.scss";

.newTag {
  padding: 2px 4px;
  border-radius: 25px;
  background-color: $PRIMARY_SURFACE;

  color: $PRIMARY_MAIN;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.container {
  width: 726px;
  max-width: 100vw;
  background-color: $NEUTRAL_10;
  border-radius: 8px;
  box-shadow: 0px 0px 1px 0px rgba(9, 30, 66, 0.31),
    0px 3px 5px 0px rgba(9, 30, 66, 0.2);
  max-height: 85vh;
  overflow: auto;
  display: flex;
  flex-direction: column;

  > * {
    // padding: 24px;
    @include padding(24, 16, 16px);
  }

  .addressWrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;

    > * {
      width: 100%;

      &.divider {
        height: 1px;
        background-color: $NEUTRAL_50;
      }
    }
  }

  .errorWrapper {
    color: $DANGER_MAIN;
    font-size: 12px;
    opacity: 0;

    &.error {
      opacity: 1;
    }
  }

  .header {
    display: flex;
    align-items: center;
    gap: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid $NEUTRAL_40;
    position: sticky;
    top: 0;
    background-color: $NEUTRAL-10;

    > h3 {
      flex-grow: 1;
      font-family: Poppins;
      // font-size: 26px;
      @include font-size(26, 20, 20px);
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
      letter-spacing: 0.13px;
    }

    > button {
      background-color: transparent;
      border: none;
    }
  }

  .content {
    flex-grow: 1;
    padding-block: 16px;
    overflow-y: scroll;
    // height: 570px;

    .intro {
      // display: flex;
      // align-items: flex-start;
      // flex-direction: column;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 16px;
      padding-bottom: 24px;

      .stackedIntro {
        width: 100%;
        flex: 1;
        display: flex;
        // align-items: center;
        gap: 8px;

        div {
          &:first-child {
            display: grid;
            place-items: center;
            padding: 12px;
            background-color: $NEUTRAL_20;
            border-radius: 5px;

            > img {
              height: 16px;
              aspect-ratio: 1;
              object-fit: contain;
            }
          }

          &:last-child {
            display: flex;
            flex: 1;
            flex-direction: column;
            padding: 8px 16px;
            background-color: $PRIMARY_SURFACE;
            border-radius: 4px;

            span {
              font-family: "Poppins";
              font-style: normal;

              &:first-child {
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                color: $NEUTRAL_60;
              }

              &:last-child {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: $NEUTRAL_100;
              }
            }
          }
        }
      }
    }

    form {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .section {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;

        &.half {
          display: grid;
          grid-template-columns: 1fr 1fr;
        }

        &.interviewer {
          > *:first-child {
            display: grid;
            grid-template-areas:
              "a a"
              "b c";
            grid-template-columns: 143px 1fr;

            > :first-child {
              grid-area: a;
            }

            > :nth-child(2) {
              grid-area: b;
            }

            > :nth-child(3) {
              grid-area: c;
            }
          }
        }

        &.smallerGap {
          gap: 8px;
        }

        > * {
          flex: 1;
          width: 100%;

          &.boxForm {
            display: flex;
            flex-direction: column;
            gap: 4px;

            label {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 20px;
              color: $NEUTRAL_100;
            }

            .selectWrapper {
              height: 44px;
              background: $NEUTRAL_10;
              border: 1px solid $NEUTRAL_40;
              border-radius: 8px;
              position: relative;
              display: flex;
              align-items: center;

              > img {
                width: 20px;
                aspect-ratio: 1;
                border-radius: 50%;
                object-fit: cover;
                margin-left: 12px;
              }

              input {
                border: none;
                color: $NEUTRAL_100 !important;
                padding-inline-start: 8px !important;
                padding-inline-end: 53px !important;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;

                &::placeholder {
                  font-family: "Poppins";
                  font-style: normal;
                  font-weight: 400;
                  font-size: 13px;
                  line-height: 20px;
                  color: $NEUTRAL_70;
                  width: 100%;
                  opacity: 1;
                }

                &:focus {
                  border: none;
                  outline: none;
                }
              }

              > span,
              input {
                padding-inline: 12px;
                font-family: "Poppins";
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 20px;
                color: $NEUTRAL_70;
                width: 100%;

                &.filled {
                  color: $NEUTRAL_100;
                }
              }

              &.active {
                border-color: $PRIMARY_MAIN;
              }

              .expandButton {
                position: absolute;
                right: 8px;
                top: 10.5px;
                display: grid;
                place-items: center;
                padding: 0;
                background-color: transparent;
                border: none;
              }

              .clearButton {
                position: absolute;
                right: 35px;
                top: 13.5px;
                display: grid;
                place-items: center;
                padding: 0;
                background-color: transparent;
                border: none;
              }

              .optionsWrapper {
                position: absolute;
                top: 50px;
                width: 100%;
                background-color: $NEUTRAL_10;
                border-radius: 8px;
                overflow: hidden;
                box-shadow: 0px 0px 1px rgba(9, 30, 66, 0.31),
                  0px 3px 5px rgba(9, 30, 66, 0.2);
                z-index: 1;
                padding-block: 4px;
                max-height: 200px;
                overflow: auto;

                > div {
                  padding: 8px 12px;
                  font-family: "Poppins";
                  font-style: normal;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 20px;
                  color: $NEUTRAL_90;
                  display: flex;
                  align-items: center;
                  gap: 8px;

                  &:hover {
                    background-color: $PRIMARY_SURFACE_HOVER;
                  }

                  > img {
                    width: 20px;
                    aspect-ratio: 1;
                    border-radius: 50%;
                    object-fit: cover;
                  }

                  &.addNew {
                    border-top: 1px solid $NEUTRAL_40;
                    cursor: pointer;

                    > span {
                      font-family: Poppins;
                      font-size: 13px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 20px;

                      &:last-child {
                        color: $NEUTRAL_60;
                      }
                    }
                  }
                }
              }
            }

            .stacked {
              display: flex;
              align-items: center;
              gap: 8px;
              height: 44px;

              &.column {
                flex-direction: column;
                height: auto;
              }

              > * {
                flex: 1;
                height: 100%;
                width: 100%;
              }

              .inputWrapper {
                position: relative;

                // .placeholderReplacement,
                // ::placeholder,
                // ::-webkit-input-placeholder {
                //   position: absolute;
                //   top: 12px;
                //   left: 12px;
                //   font-family: 'Poppins';
                //   font-style: normal;
                //   font-weight: 400;
                //   font-size: 13px;
                //   line-height: 20px;
                //   color: $NEUTRAL_70;
                //   opacity: 1;
                // }

                .endAdornment {
                  position: absolute;
                  right: 8px;
                  top: 12px;
                  background-color: transparent;
                  border: none;
                  color: $PRIMARY_MAIN;
                  display: grid;
                  place-items: center;
                  // z-index: 0;
                }
              }
            }

            > input,
            .inputWrapper input {
              border: 1px solid $NEUTRAL_40;
              border-radius: 8px;
              padding-inline: 12px;
              height: 44px;
              width: 100%;
              background-color: transparent;

              &[type="time"]::-webkit-calendar-picker-indicator {
                opacity: 0;
              }

              &:focus {
                outline: none;
                border-color: $PRIMARY_MAIN;
              }
            }

            // input[type="date"]:in-range::-webkit-datetime-edit-year-field,
            // input[type="date"]:in-range::-webkit-datetime-edit-month-field,
            // input[type="date"]:in-range::-webkit-datetime-edit-day-field,
            // input[type="date"]:in-range::-webkit-datetime-edit-text {
            //   color: transparent;
            //   display: none;
            // }

            .empty {
              &:before {
                content: "Time";
                font-family: "Poppins";
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 20px;
                color: $NEUTRAL_70;
                position: absolute;
                background: $NEUTRAL_10;
                width: 70px;
              }

              &:focus:before {
                width: 0;
                content: "";
              }
            }
          }
        }
      }

      > button {
        align-self: flex-end;
        // width: 100%;
        width: 100px;
        height: 36px;
        background: $PRIMARY_MAIN;
        border-radius: 8px;
        border: none;

        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $NEUTRAL_10;

        &:disabled {
          background-color: $PRIMARY_DISABLED;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    border-radius: 16px 16px 0 0;

    .content {
      .intro {
        grid-template-columns: 1fr;
      }

      form {
        .section {
          &.half {
            grid-template-columns: 1fr;
          }
        }
      }
    }
  }
}
