@import "../../../theme/variables.scss";

.container {
  background-color: $BS_PRIMARY_SURFACE;
  padding: 8px;
  border-radius: 8px;
  .SelectWrapper {
    display: flex;
    align-items: center;
    gap: 8px;

    > span {
      flex-grow: 1;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }

    > *:first-child,
    > *:last-child {
      flex-shrink: 0;
    }
  }
  .InputContainer {
    transition: max-height 0.5s ease-out;
    max-height: 0 !important;
    overflow: scroll;
    & > :first-child {
      background: $NEUTRAL_10;
      padding: 12px 16px;
      display: flex;
      flex-direction: column;
      gap: 12px;
      border-radius: 8px;
      border: 1px solid #ededed;
    }
  }
  .ShowInput {
    max-height: 1000px !important;
    margin-top: 8px;
  }
}
