@import "../../../../theme/variables.scss";

.tableWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll !important;
  z-index: 0;
  height: 420px;

  .checkButton {
    background-color: transparent;
    border: none;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration-line: underline;
    color: $PRIMARY_MAIN;
    &.disabledButton {
      color: #9e9e9e;
    }
  }

  .showOnMobile {
    display: none;
  }

  .emptyWrapper {
    flex-grow: 1;
  }

  .tableContainer {
    overflow: auto;
    background-color: inherit;
    border-bottom: 0;
    border-top: 0;
    border-inline: none;
    overflow-y: scroll !important;
    height: auto;

    &.grow {
      height: 100px;
      flex-grow: 1;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    .table {
      border-collapse: separate;
      // min-width: 900px !important;

      .tableHead {
        .tableHeadCell {
          padding-inline: 20px !important;
          padding-block: 16px !important;
          position: sticky;
          top: 0;
          background-color: $NEUTRAL-10;
          // z-index: 1;

          &:first-child {
            border-right: 1px solid $NEUTRAL_40;
            position: sticky;
            left: 0;
            background-color: $NEUTRAL_10 !important;
            z-index: 1;
            padding-inline: 16px !important;

            > div {
              width: 304px !important;
              display: flex;
              align-items: center;
              gap: 16px;
            }
          }

          &.stickyRight {
            position: sticky;
            right: 0;
            z-index: 1;
            background-color: $NEUTRAL_10 !important;
            border-left: 1px solid $NEUTRAL_40;
            text-align: center;
          }

          > div {
            &:not(.customWidth) {
              width: 220px !important;
            }
          }
        }
      }

      .tableBody {
        .tableCell {
          padding: 16px !important;

          > div {
            display: flex;
            align-items: center;
            gap: 16px;

            > span {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              color: $NEUTRAL_100;
            }

            .chatIcon {
              color: $PRIMARY_MAIN;
            }
          }

          > span {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: $NEUTRAL_100;
          }

          .unavailableText {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: $NEUTRAL_60;
          }

          &.stickyLeft {
            position: sticky;
            left: 0;
            background-color: $NEUTRAL_10 !important;
            border-right: 1px solid $NEUTRAL_40;

            > div {
              display: flex;
              align-items: center;
              gap: 16px;

              .stackedProfile {
                background-color: transparent;
                border: none;
                display: flex;
                align-items: center;
                gap: 8px;
                cursor: pointer;

                span {
                  font-family: "Poppins";
                  font-style: normal;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 20px;
                  color: $PRIMARY_MAIN;
                  text-align: left;
                }
              }
            }
          }

          &.stickyRight {
            position: sticky;
            right: 0;
            background-color: $NEUTRAL_10 !important;
            border-left: 1px solid $NEUTRAL_40;
            text-align: center;
          }
        }
      }
    }
  }

  .paginationContainer {
    border-top: 1px solid $NEUTRAL_50;
  }
}

@media screen and (max-width: 768px) {
  .tableWrapper {
    .showOnMobile {
      display: table-cell;
    }

    .tableContainer {
      .table {
        width: 100%i !important;

        .tableHead {
          .tableHeadCell {
            padding: 12px 16px !important;

            &:first-child {
              position: relative;
              border-right: none;

              > div {
                width: 100% !important;
              }
            }
          }

          .showOnMobile {
            min-width: 100px !important;
            max-width: 150px !important;
          }
        }

        .tableBody {
          .tableCell {
            &.stickyLeft {
              position: relative;
              border-right: none;

              > div {
                width: 100% !important;
              }
            }

            &.showOnMobile {
              button {
                width: 100%;
                padding: 6px 12px;
                background-color: $PRIMARY_MAIN;
                border: none;
                border-radius: 8px;

                font-family: "Poppins";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: $NEUTRAL_10;
              }
            }
          }
        }

        .hideOnMobile {
          display: none !important;
        }
      }
    }
  }
}
