@import "../../../../../.././../theme/variables.scss";

.container {
  background-color: $NEUTRAL_10;
  border-radius: 8px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  padding-block: 100px;

  .imageBox {
    width: 188px;
    aspect-ratio: 1;
    border-radius: 50%;
    display: grid;
    place-items: center;
    background-color: $PRIMARY_SURFACE;
    max-width: 100%;
    padding: 18px;

    img {
      width: 84px;
      aspect-ratio: 1;
      object-fit: contain;
      max-width: 100%;
    }
  }

  h3 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 140%;
    text-align: center;
    letter-spacing: 0.005em;
    color: $NEUTRAL_DARKEST;
    padding-top: 16px;
    padding-bottom: 8px;
  }

  p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: $NEUTRAL_70;
    padding-bottom: 24px;
  }

  button {
    width: 203px;
    max-width: 100%;
    height: 40px;
    background: $PRIMARY_MAIN;
    border-radius: 5px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: $NEUTRAL_10;
    border: none;
    padding: 8px;
  }
}

@media screen and (max-width: 768px) {
  .container {
    width: 100%;
    aspect-ratio: 1;
    padding: 18px;

    .imageBox {
      width: 100px;

      img {
        width: 54px;
      }
    }

    h3 {
      font-size: 16px;
    }

    p {
      font-size: 12px;
      line-height: 16px;
    }

    button {
      width: 203px;
      max-width: 100%;
      height: 40px;
      background: $PRIMARY_MAIN;
      border-radius: 5px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: $NEUTRAL_10;
    }
  }
}
