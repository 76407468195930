.bodyResend {
  height: 100vh;
  width: 100vw;
  background-color: #00000080;
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
}

@import "../../../../theme/variables.scss";

.error {
  color: $DANGER_MAIN;
  font-size: 12px;
}

.bottom {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.container {
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  background-color: $NEUTRAL_10;
  max-width: 100vw;
  width: 436px;
  filter: drop-shadow(0px 0px 1px rgba(9, 30, 66, 0.31));
  gap: 24px;
  padding: 32px;

  .iconWrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    padding: 0 24px;
    text-align: center;

    .title {
      font-size: 20px;
      font-weight: 600;
      color: $NEUTRAL_100;
      font-family: Poppins;
    }

    .text {
      font-size: 14px;
      font-weight: 400;
      color: $NEUTRAL_60;
      font-family: Poppins;
    }
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    font-family: Poppins;

    .primaryButton {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $PRIMARY_MAIN;
      padding: 10px 0;
      flex: 1;
      border-radius: 8px;
      border: 1px solid $PRIMARY_MAIN;
      cursor: pointer;
      display: flex;
      gap: 8px;
      &.disabled {
        background: var(--Neutral-20, #f5f5f5);
      }

      .text {
        font-size: 14px;
        font-weight: 500;
        color: $NEUTRAL_10;
        &.disabledText {
          color: var(--Neutral-60, #9e9e9e);
        }
      }
    }

    .primaryButton:hover {
      background-color: #3285ec;
    }

    .secondaryButton {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $NEUTRAL_10;
      padding: 10px 0;
      border-radius: 8px;
      border: 1px solid $NEUTRAL_60;
      cursor: pointer;

      .text {
        font-size: 14px;
        font-weight: 500;
        color: $NEUTRAL_90;
      }
    }

    .secondaryButton:hover {
      background-color: $NEUTRAL_30;
    }
  }
}

@media (width <=768px) {
  .container {
    width: 100vw;
    position: fixed;
    bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding-left: 16px;
    padding-right: 16px;

    .buttons {
      flex-direction: column;

      .primaryButton,
      .secondaryButton {
        width: 100%;
      }
    }
  }
}
