@import "../../../theme/variables.scss";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.titleWrapper {
  padding-bottom: 4px;
  font-size: 13px;
  font-weight: 500;
  color: $NEUTRAL_100;

  span {
    color: $DANGER_MAIN;
  }
}