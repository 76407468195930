@import "../../../theme/variables.scss";

.container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 30px;
  padding-left: 0px;
  padding-right: 0px;
  // overflow-x: scroll !important;
  height: 80px;

  .warning {
    border: 2px solid $DANGER_MAIN;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .each {
    display: flex;
    align-items: center;
    gap: 8px;
    // width: 100%;
    position: relative;
    flex-grow: 1;
    // min-width: 150px;
    min-width: 130px;

    .divider {
      height: 3px;
      width: 100%;

      &.None {
        opacity: 0;
      }

      &.left {
        border-radius: 0 3px 3px 0;
      }

      &.right {
        border-radius: 3px 0 0 3px;
      }
    }

    .desc {
      display: flex;
      align-items: center;
      gap: 12px;

      width: 100%;
      justify-content: center;

      .button {
        position: relative;
        width: 36px !important;
        height: 36px !important;
        border-radius: 50%;
        display: grid;
        place-items: center;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        cursor: pointer;

        &.disabled {
          cursor: auto;
        }
      }
    }

    .label {
      position: absolute;
      top: 40px;
      width: 150px;
      // left: 0;

      text-align: center;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 13px !important;
      line-height: 20px;

      display: grid;
      place-items: center;
      justify-content: center;

      // >span {
      //   margin-left: auto;
      //   margin-right: auto;
      //   left: 0;
      //   right: 0;  
      // }

    }

    &.active {
      .divider {
        background-color: $PRIMARY_MAIN;

        &.inactive {
          background-color: $NEUTRAL_40 !important;
        }
      }

      .desc {
        .button {
          background-color: $PRIMARY_MAIN;
          color: $NEUTRAL_10;

        }
      }

      .label {
        color: $PRIMARY_MAIN;
      }
    }

    &.inactive {
      .divider {
        background-color: $NEUTRAL_40;
      }

      .desc {
        .button {
          background-color: $NEUTRAL_30;
          color: $NEUTRAL_60;
        }
      }
    }
  }
}

.containerFilledPipeline {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  // padding-bottom: 30px;
  padding-left: 0px;
  padding-right: 0px;
  overflow-x: scroll !important;
  height: 60px;

  .warning {
    border: 2px solid $DANGER_MAIN;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .each {
    display: flex;
    align-items: center;
    gap: 8px;
    // width: 100%;
    position: relative;
    flex-grow: 1;
    // min-width: 150px;
    min-width: 130px;

    .divider {
      height: 3px;
      width: 100%;

      &.None {
        opacity: 0;
      }

      &.left {
        border-radius: 0 3px 3px 0;
      }

      &.right {
        border-radius: 3px 0 0 3px;
      }
    }

    .desc {
      display: flex;
      align-items: center;
      gap: 12px;

      width: 100%;
      justify-content: center;

      .button {
        position: relative;
        width: 36px !important;
        height: 36px !important;
        border-radius: 50%;
        display: grid;
        place-items: center;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        cursor: pointer;

        &.disabled {
          cursor: auto;
        }

      }
    }

    .label {
      position: absolute;
      top: 40px;
      width: 150px;
      // left: 0;

      text-align: center;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 13px !important;
      line-height: 20px;
    }

    &.active {
      .divider {
        background-color: $PRIMARY_MAIN;

        &.inactive {
          background-color: $NEUTRAL_40 !important;
        }
      }

      .desc {
        .button {
          background-color: $PRIMARY_MAIN;
          color: $NEUTRAL_10;
        }
      }

      .label {
        color: $PRIMARY_MAIN;
      }
    }

    &.inactive {
      .divider {
        background-color: $NEUTRAL_40;
      }

      .desc {
        .button {
          background-color: $NEUTRAL_30;
          color: $NEUTRAL_60;
        }
      }
    }
  }
}

.stepperContainer {
  display: table;
  width: 100%;
  margin: 0 auto;
  overflow: auto;

  .step {
    display: table-cell;
    position: relative;
    padding-block: 24px;
    padding-inline: 12px;

    &:first-child {
      width: 15%;
      padding-inline: 0;

      .leftBar {
        display: none;
      }
    }

    &:last-child {
      width: 15%;
      padding-inline: 0;

      .rightBar {
        display: none;
      }
    }

    .circle {
      width: 30px;
      height: 30px;
      margin: 0 auto;
      border-radius: 50%;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;

      &.pointer {
        cursor: pointer;
      }

      span {
        font-family: "Poppins";
        font-size: 16px;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
      }
    }

    .label {
      text-align: center;
      padding-top: 5px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
    }

    &.active {
      .circle {
        background-color: $NEUTRAL_10;
        border: 2px solid $PRIMARY_MAIN;
        color: $PRIMARY_MAIN;

        span {
          color: $PRIMARY_MAIN;
        }
      }

      .label {
        color: $PRIMARY_MAIN;
      }
    }

    &.inactive {
      .circle {
        background-color: $NEUTRAL_10;
        border: 2px solid $NEUTRAL_40;

        span {
          color: $NEUTRAL_60;
        }
      }

      .label {
        color: $NEUTRAL_60;
      }
    }

    .leftBar,
    .rightBar {
      position: absolute;
      top: 38px;
      height: 3px;

      &.activeBar {
        background-color: $PRIMARY_MAIN;
      }

      &.inactiveBar {
        background-color: $NEUTRAL_40;
      }
    }

    .rightBar {
      right: 0;
      left: 50%;
      margin-left: 20px;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }

    .leftBar {
      left: 0;
      right: 50%;
      margin-right: 20px;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  }
}

.candidatesStepperContainer {
  display: flex;
  align-items: center;
  overflow: auto;
  border-top: 1px solid $NEUTRAL_30;
  border-bottom: 1px solid $NEUTRAL_30;

  &.noBorderTop {
    border-top: none;
  }

  &.noBorderBottom {
    border-bottom: none;
  }
}

@media only screen and (max-width: 768px) {
  .stepperContainer {
    >* {
      width: calc(100% / 3) !important;
      padding: 16px 8px !important;

      .rightBar,
      .leftBar {
        top: 30px !important;
      }
    }
  }

  .container {
    height: 80px;

    // .divider {
    //   width: 90px!important;
    // }

    .label {
      width: 120px !important;
    }
  }

  .containerFilledPipeline {
    height: 80px;

    // .divider {
    //   width: 90px!important;
    // }

    .label {
      width: 120px !important;
    }
  }
}