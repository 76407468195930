@import "../../../../../../../../theme/variables.scss";

.container {
  padding: 16px 24px;
  display: flex;
  justify-content: center;
  .card {
    width: 50%;
    padding: 32px;
    background-color: $NEUTRAL_20;
    border: 1px solid $NEUTRAL_40;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s;
    position: relative;

    @media screen and (max-width: 768px) {
      width: 100%;
    }

    .iconWrapper {
      position: absolute;
      right: 10px;
      top: 10px;
      color: $PRIMARY_MAIN;
      transition: all 0.3s;

      opacity: 1;
    }

    .cardHeader {
      display: flex;
      flex-direction: column;
      gap: 16px;
      text-align: center;

      h4 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 150%;
        text-align: center;
        letter-spacing: 0.005em;
        color: $NEUTRAL_DARKEST;
      }

      span {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $NEUTRAL_70;
      }
    }

    .cardContent {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding-top: 32px;

      .rule {
        display: flex;
        gap: 16px;

        img {
          height: 18px;
          aspect-ratio: 1;
        }

        p {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 20px;
          color: $DARK_CHARCOAL;
        }
      }
    }
  }
}
