@import "../../../theme/variables.scss";

.container {
  width: 100%;
  display: flex;
  gap: 8px;

  input {
    width: 100%;
    font-size: 18px !important;
    font-weight: 600;
    text-align: center;
    border-radius: 8px;
    padding: 12px;
    height: 50px !important;
    border: 1px solid $NEUTRAL_40;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;

    &:focus {
      outline: none !important;
      border-color: $PRIMARY_MAIN;
      border-width: 1px;
      border-style: solid;
    }

    &.alert {
      border-color: $DANGER_OUTLINE_BORDER !important;
      -webkit-box-shadow: 0 0 5px $DANGER_OUTLINE_BORDER !important;
      -moz-box-shadow: 0 0 5px $DANGER_OUTLINE_BORDER !important;
      box-shadow: 0 0 5px $DANGER_OUTLINE_BORDER !important;
    }
  }
}
