@import "../../../../../../../theme/variables.scss";

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;

  .autoScroll {
    display: hidden;
    position: absolute;
    top: 0;
  }

  .noData {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #333333;
  }

  .each {
    background-color: $NEUTRAL_10;
    border-radius: 8px;

    h4 {
      padding: 16px 24px;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      color: $PRIMARY_MAIN;
      border-bottom: 1px solid $NEUTRAL_30;
    }

    .cardsWrapper {
      padding: 24px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 16px;

      .selectWrapper {
        background-color: $NEUTRAL_20;
        padding: 16px;
        border-radius: 8px;
      }
    }
  }

  .buttonWrapper {
    display: flex;
    gap: 12px;
    justify-content: flex-end;
    padding-top: 16px;


    button {
      padding: 8px 16px;
      border-radius: 8px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      cursor: pointer;


      &:first-child {
        background: $NEUTRAL_10;
        border: 1px solid $NEUTRAL_100;
        color: $NEUTRAL_100;
      }

      &:last-child {
        background: $PRIMARY_MAIN;
        border: 1px solid $PRIMARY_MAIN;
        color: $NEUTRAL_10;

        &:disabled {
          background-color: $PRIMARY_DISABLED;
          border-color: $PRIMARY_DISABLED;
        }

      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {

    .each {
      h4 {
        padding: 16px;
      }


      .cardsWrapper { 
        padding: 16px;
        grid-template-columns: 1fr!important;

        .selectWrapper {
          padding: 8px;
        }
      }
    }

    .buttonWrapper {
      >* {
        width: 100%;
      }
    }

  
  }
}