@import "../../../../../../theme/variables.scss";

.container {
  position: relative;
  padding: 30px;

  .header {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .title {
      width: 222px;
      height: 22px;
      background: $NEUTRAL_40;
    }

    .Description {
      width: 345px;
      height: 16px;
      background: $NEUTRAL_40;
      max-width: 100%;
    }
  }

  .content {
    display: flex;
    gap: 16px;
    width: 100%;
    padding-top: 24px;
  }
}

.card {
  background-color: $NEUTRAL_10;
  border: 1px solid $NEUTRAL_40;
  border-radius: 8px;
  padding: 32px;
  width: 100%;

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    .title {
      width: 350px;
      height: 24px;
      background: #eaecf2;
    }

    .subTitle {
      width: 230px;
      height: 16px;
      background: #eaecf2;
    }
  }

  .content {
    display: flex;
    flex-direction: column;

    .stacked {
      display: flex;
      align-items: center;
      gap: 16px;

      .bullet {
        width: 16px;
        height: 16px;
        background: #eaecf2;
        flex-shrink: 0;
      }

      .text {
        height: 16px;
        background: #eaecf2;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    padding: 16px;

    .header {
      .title {
        height: 18px;
      }

      .Description {
        height: 14px;
      }
    }

    .content {
      flex-direction: column;
    }
  }

  .card {
    padding: 16px;

    .header {
      > * {
        max-width: 100%;
      }
    }

    .content {
      .stacked {
        > * {
          height: 14px;
        }
      }
    }
  }
}
