@import "../../../../theme/variables.scss";

.container {
  display: grid;
  place-items: center;

  > * {
    width: 100vw;
  }
}
