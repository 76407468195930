@import "../../../theme/variables.scss";

.container {
  width: 100%;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;

  &.noToggle {
    padding-bottom: 4px;
  }
}

.title {
  font-size: 13px;
  font-weight: 500;
  color: $NEUTRAL_100;

  span {
    color: $DANGER_MAIN;
  }
}
