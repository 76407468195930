.containerCost {
  display: flex;
  flex-direction: column;
  gap: 4px;

  > span {
    color: var(--Primary-Main, #1571de);
    // font-family: Helvetica;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 30px */
    letter-spacing: 0.1px;
  }

  .tableWrapper {
    display: flex;
    flex-direction: column;
    overflow: auto;

    .tableHead {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
      border-bottom: 1px solid #ece6e6;
      padding-bottom: 15px;
      padding-top: 15px;
      > span {
        color: #000;
        // font-feature-settings: "liga" off, "clig" off;
        // font-family: Poppins;
        font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
        min-width: 150px;
      }
      > span:first-child {
        text-align: left;
      }

      > span:nth-child(2) {
        text-align: center;
      }

      > span:last-child {
        text-align: right;
      }
    }

    .tableBody {
      display: flex;
      flex-direction: column;

      .tableCell {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
        padding-bottom: 15px;
        padding-top: 15px;
        border-bottom: 1px solid #ece6e6;
        &.noBorder {
          border: none;
        }
        > span {
          color: #000;
          text-align: right;
          //   font-feature-settings: "liga" off, "clig" off;
          //   font-family: Poppins;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          min-width: 150px;
        }

        > span:first-child {
          text-align: left;
        }

        > span:nth-child(2) {
          text-align: center;
        }

        > span:last-child {
          text-align: right;
        }
      }
    }
  }

  .summary {
    display: flex;
    justify-content: flex-end;
    > div {
      width: 350px;
      display: flex;
      flex-direction: column;
      > div:first-child {
        display: flex;
        flex-direction: column;
        gap: 4px;
        border-top: 1px solid #000;
        padding-top: 9px;
        padding-bottom: 9px;
        > div {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 16px;
          > span {
            color: #000;
            //   font-feature-settings: "liga" off, "clig" off;
            //   font-family: Poppins;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }

      > div {
        display: flex;
        flex-direction: column;
        gap: 4px;
        border-top: 1px solid #000;
        padding-top: 9px;
        padding-bottom: 9px;
        > div {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 16px;
          > span {
            color: #000;
            //   font-feature-settings: "liga" off, "clig" off;
            //   font-family: Poppins;
            font-size: 13px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            &.manfee {
              color: var(--Neutral-70, var(--Color-Neutal-Neutral-70, #757575));
            }
          }
        }
      }
    }
  }
}
