.container {
  height: calc(100vh - 70px);
  overflow: hidden;

  .loadingWrapper {
    height: 100%;
    display: grid;
    place-items: center;
  }

  .controllerWrapper {
    position: sticky;
    top: 70;
    width: 100%;
  }

  .contentWrapper {
    height: 100%;
    overflow: auto;
  }

}

@media screen and (max-width: 768px) {}