@import "../../../../../../../../../../theme/variables.scss";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;

  img {
    height: 195px;
    aspect-ratio: 1;
  }

  h4 {
    padding-top: 12px;
    padding-bottom: 8px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.005em;
    color: $PRIMARY_MAIN;
  }

  p {
    padding-top: 8px;
    padding-bottom: 18px;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    color: $NEUTRAL_70;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    height: 36px;
    padding-inline: 16px;
    border-radius: 8px;
    border: 1px solid $PRIMARY_MAIN;
    background-color: transparent;
    color: $PRIMARY_MAIN;
    cursor: pointer;

    &.mobile {
      display: none;
    }

    span {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    padding: 16px;

    p {
      padding-bottom: 16px;
    }

    button {
      &.mobile {
        display: flex;
      }

      &.desktop {
        display: none;
      }
    }
  }
}
