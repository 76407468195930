@import "../../../theme/variables.scss";

.container {
  width: 100%;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;

  &.noToggle {
    padding-bottom: 4px;
  }
}

.title {
  > p {
    font-size: 13px;
    font-weight: 500;
    color: $NEUTRAL_100;
  }
  display: flex;
  align-items: flex-start;
  gap: 2px;
  span {
    color: $DANGER_MAIN;
    font-weight: 400;
    font-size: 11px;
  }
}

.errorText {
  color: var(--Helper, #F5610D);
  /* Caption 1/Reguler */
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  /* 133.333% */
}
