@import "../../../../theme/variables.scss";

.background {
    width: 100vw;
    height: 100vh;
    background-color: $NEUTRAL_OVERLAY;
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  
    .container {
      background-color: $NEUTRAL_10;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      max-width: 345px;
      aspect-ratio: 1;
      max-height: 80%;
      // overflow-y: scroll;
      min-width: 320px;
  
      .animationWrapper {
        width: 200px;
        aspect-ratio: 1;
        display: grid;
        place-items: center;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .container {
      width: 80vw;
      max-width: 80vw;
    }
  }