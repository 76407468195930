@import "../../../../../../theme/variables.scss";

.formWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    gap: 16px;

    .titleForm{
        color: $NEUTRAL_100;
        font-weight: 400;
        font-size: 16px;
    }

    .imageInput {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 24px;

        .error {
          margin-top: 12px;
          color: $DANGER_MAIN;
          font-size: 13px;
        }

        .Section {
          width: 282px;
          height: 244px;

          .ImageidContainer {
            position: relative;
            border-radius: 8px;

            height: 100%;

            & > img {
              height: 100%;
              width: 100%;
              border-radius: 8px;
              object-fit: contain;
            }

            .Footer {
              position: absolute;
              bottom: 0;
              border-radius: 8px;
              display: flex;
              color: $NEUTRAL_10;
              width: 100%;
              height: 30%;
              align-items: center;
              justify-content: center;
              background: linear-gradient(
                0deg,
                #000 0%,
                rgba(0, 0, 0, 0) 100%
              );
              gap: 12px;
              cursor: pointer;
            }
          }

          .DropdownSection {
            border: 2px dashed $NEUTRAL_50;
            height: 100%;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 8px;
            width: 100%;
            color: $NEUTRAL_50;
            font-size: 14px;
            font-weight: 400;

            &.onPointer {
              cursor: pointer;
            }

            .buttonSubmit {
                cursor: pointer;
                border-radius: 8px;
                background-color: $NEUTRAL_10;
                border: 1px solid $PRIMARY_MAIN;
                display: flex;
                padding: 12px;
                justify-content: center;
                align-items: center;
                color: $PRIMARY_MAIN;
                text-align: center;
            
                /* Text M/Reguler */
                // font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px; /* 142.857% */
              }
          }

          .disabledOnDrop {
            border: 2px dashed $PRIMARY_DISABLED !important;
            color: $PRIMARY_DISABLED !important;

            .label {
              color: $NEUTRAL_60;
            }
          }
        }
      }
}

@media screen and (max-width: 768px) {
}