@import "../../../../../theme/variables.scss";

.tableWrapper {
  padding: 24px 30px;

  .table {
    border-collapse: separate !important;
  }

  .modalWrapper {
    position: fixed;
    transition: all 0.35s ease-in-out !important;
    z-index: 4;
    background-color: transparent;
    left: 0;
    right: 0;

    &.hideModal {
      bottom: -500px;
    }

    &.showModal {
      bottom: 0;
    }
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    background-color: $NEUTRAL_OVERLAY;
    opacity: 0;
    z-index: 3;

    &.visible {
      right: 0;
      bottom: 0;
      opacity: 1;
      transition: opacity 0.4s;
    }
  }
}

@media screen and (max-width: 768px) {
  .tableWrapper {
    padding: 21px 18px;
  }
}
