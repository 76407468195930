@import "../../../theme/variables.scss";

.container {
  height: 28px;
  width: 50px;
  background-color: $NEUTRAL_40;
  border-radius: 50px;
  padding: 2px;
  position: relative;
  cursor: pointer;
}

.toggle {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background-color: $NEUTRAL_LIGHTEST;
  position: absolute;
  left: 2px;
  transition: 0.5s;
}

.containerActive {
  background-color: $PRIMARY_MAIN;
  height: 28px;
  width: 50px;
  border-radius: 50px;
  padding: 2px;
  position: relative;
  cursor: pointer;
}

.toggleActive {
  left: 24px;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background-color: $NEUTRAL_LIGHTEST;
  position: absolute;
  transition: 0.5s;
}
