@import "../../../theme/variables.scss";

.container {
  width: 1000px;
  border-radius: 8px;
  // overflow: clip;
  // overflow-y: visible;

  .filterIconBox {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
  }
  .filterSpan {
    color: var(--Neutral-90, var(--Color-Neutal-Neutral-90, #404040));
    text-align: center;

    /* Text M/Reguler */
    // font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }

  .topTitleFilter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .temukanPekerjaanmu {
      color: #000;

      /* Heading 7/Bold */
      // font-family: Poppins;
      font-size: calc(14px + (20 - 14) * ((100vw - 280px) / (1600 - 280)));
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 30px */
      letter-spacing: 0.1px;
    }
  }

  .bodyFilter {
    display: flex;
    flex-direction: column;
    gap: 11px;
    padding: 16px;
    background: var(--primary-surface, #f5f7fe);
    overflow: visible;
    z-index: 1;
  }

  .inputSection {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 16px;
  }

  .resetSection {
    // display: flex;
    // align-items: center;
    display: grid;
    grid-template-columns: 1fr 170px;
    gap: 16px;
    background-color: #fafafa;
    border-top: 1px solid $NEUTRAL-40;

    > span {
      flex-grow: 1;
      padding: 8px 16px;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: $NEUTRAL_90;
    }

    > button {
      padding: 8px 16px;
      background-color: #fcebe7;
      border: none;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      color: #ff3e13;
    }
  }

  .buttonBox {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    border-radius: 8px;
    background: var(--primary-main, #1571de);
    cursor: pointer;
    width: 50%;
    gap: 8px;

    .searchText {
      color: var(--color-neutal-neutral-10, #fff);
      text-align: center;

      /* Text M/Reguler */
      // font-family: Poppins;
      font-size: calc(10px + (14 - 10) * ((100vw - 280px) / (1600 - 280)));
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      /* 142.857% */
    }

    .resetText {
      color: #000;
      text-align: center;

      /* Text M/Reguler */
      // font-family: Poppins;
      font-size: calc(10px + (14 - 10) * ((100vw - 280px) / (1600 - 280)));
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      /* 142.857% */
    }
  }

  .inputBox {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;

    .searchBox {
      width: 100%;
    }

    .text {
      color: #000;
      // text-align: center;

      /* Text M/Medium */
      // font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      /* 142.857% */
    }
  }
}

@media screen and (max-width: 1000px) {
  .container {
    width: 80%;

    .inputSection {
      flex-direction: column;
      align-items: flex-start;
    }

    .buttonBox {
      width: 100%;
    }
  }
}

@media screen and (max-width: 650px) {
  .container {
    width: 100%;

    .resetSection {
      grid-template-columns: 1fr;
      gap: 0;
    }

    .inputSection {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  // .buttonBox {
  //   width: 100%;
  // }
}
