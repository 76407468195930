@import "../../../../../../theme/variables.scss";

.container {
  display: flex;
  align-items: center;
  gap: 24px;
  background-color: $NEUTRAL_LIGHTER;
  padding-inline: 30px;
  padding-top: 24px;
  padding-bottom: 10px;
  border-bottom: 1px solid $NEUTRAL_40;

  > button {
    display: grid;
    place-items: center;
    height: 40px;
    aspect-ratio: 1;
    border-radius: 50%;
    background-color: $NEUTRAL_10;
    border: none;
  }

  .inactive,
  .active {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    background-color: transparent;
    border: none;
  }

  .inactive {
    color: $NEUTRAL_60;
  }

  .active {
    color: $NEUTRAL_100;
  }
}

@media screen and (max-width: 768px) {
  .container {
    padding-top: 16px;
    padding-left: 18px;
    padding-right: 0;

    > button {
      height: 32px;
    }

    .inactive,
    .active {
      font-size: 14px;
    }
  }
}
