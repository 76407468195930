@import "../../../theme/variables.scss";

// nanti nyalain kalau gajadi
// .nav {
//   width: 100%;
//   height: 80px;
//   display: flex;
//   position: sticky;
//   top: 0;
//   justify-content: space-between;
//   background-color: #fff;
//   z-index: 10;
//   align-items: center;
//   // padding-right: 70px;
//   box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.05);

//   .register {
//     background-color: #1571de;
//     color: #fff;
//     border-radius: 20px;
//     height: 40px;
//     width: 110px;
//   }
// }

// .modalContainer {
//   background-color: $NEUTRAL_10;
//   padding: 0px 18px 30px 18px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   gap: 30px;
//   border-radius: 0px 0px 20px 20px;
//   .h1 {
//     color: var(--neutral-60, #9e9e9e);

//     /* Text M/Reguler */
//     // font-family: Poppins;
//     font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
//     font-style: normal;
//     font-weight: 400;
//     line-height: 20px; /* 142.857% */
//     &.spanActive {
//       color: #000;
//       border-bottom: 2px solid #000;
//     }
//   }

//   span {
//     color: #000;
//     font-weight: 400;
//     font-size: 16px;
//     cursor: pointer;

//     &:hover {
//       font-weight: 500;
//       border-bottom: 1px solid #000;
//       transition: 0.08s;
//     }

//     &.active {
//       font-weight: 500;
//       border-bottom: 1px solid #000;
//     }
//   }
// }

// .modalWrapper {
//   position: fixed;
//   // transition: all 0.35s ease-in-out !important;
//   z-index: 2;
//   background-color: transparent;
//   left: 0;
//   right: 0;

//   &.hideModal {
//     top: -500px;
//   }

//   &.showModal {
//     top: 80px;
//   }
// }

// .overlay {
//   position: fixed;
//   top: 80px;
//   left: 0;
//   background-color: $NEUTRAL_OVERLAY;
//   opacity: 0;
//   z-index: 1;

//   &.visible {
//     right: 0;
//     bottom: 0;
//     opacity: 1;
//     transition: opacity 0.4s;
//   }
// }

// .linkArea {
//   height: 100%;
//   display: flex;

//   .hamburgerMenu {
//     display: none;
//   }

//   .logoArea {
//     height: 100%;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     padding-left: 42px;
//     padding-right: 36px;

//     .logo {
//       width: 120px;
//     }
//   }

//   .wrapper {
//     justify-content: center;
//     align-items: flex-end;
//     padding-bottom: 20px;
//     padding-inline: 5px;
//     display: flex;
//     user-select: none;

//     &.product {
//       width: 100px;
//       margin-left: 20px;
//     }
//   }

//   .link {
//     text-decoration: none;
//     color: #000;
//     font-weight: 400;
//     font-size: 16px;
//     cursor: pointer;

//     &:hover {
//       font-weight: 500;
//       border-bottom: 1px solid #000;
//       transition: 0.08s;
//     }

//     &.active {
//       font-weight: 500;
//       border-bottom: 1px solid #000;
//     }
//   }
// }

// .actionArea {
//   height: 100%;
//   display: flex;
//   // padding-top: 20px;
//   padding-right: 70px;

//   .languagePicker {
//     display: flex;
//     margin-inline: 10px;

//     .languageSelect {
//       padding-left: 15px;
//       // -webkit-appearance: none;
//       width: 80px;
//       height: 35px;
//       border: 0 solid #fff;
//       font-size: 16px;
//       background-color: transparent;
//       z-index: 2;

//       &:focus {
//         outline: none;
//       }
//     }

//     .arrowWrapper {
//       display: flex;
//       align-items: center;
//       transform: translateX(-28px);
//       margin-right: -28px;
//       padding-top: 2px;
//     }

//     .globalWrapper {
//       display: flex;
//       align-items: center;
//       transform: translateX(12px);
//       margin-left: -12px;
//       padding-top: 2px;
//     }
//   }

//   .loginButton {
//     margin-inline: 5px;
//     text-transform: capitalize;
//     font-size: 16px;
//     font-weight: 500;
//     font-family: "Poppins", sans-serif;
//     width: 120px;

//     &.login {
//       background-color: transparent;
//       color: #1571de;
//       border-radius: 17.5px;
//     }

//     &.register {
//       background-color: #1571de;
//       color: #fff;
//       border-radius: 20px;
//       height: 40px;
//     }
//   }
// }

// .popover {
//   label {
//     display: block;
//     width: 100%;
//     border-bottom: 1px solid $NEUTRAL_30;
//     padding-bottom: 6px;

//     font-family: "Poppins";
//     font-style: normal;
//     font-weight: 500;
//     font-size: 14px;
//     line-height: 20px;
//     color: $NEUTRAL_70;
//   }

//   div {
//     padding-top: 6px;
//     padding-inline: 6px;

//     &.stacked {
//       display: flex;
//       align-items: center;
//       gap: 10px;
//       cursor: pointer;

//       &:hover {
//         span {
//           font-weight: 500;
//         }
//       }

//       span {
//         font-family: "Poppins";
//         font-style: normal;
//         font-weight: 400;
//         font-size: 16px;
//         line-height: 20px;
//       }

//       &:nth-child(2) {
//         padding-top: 12px;

//         span {
//           color: $NEUTRAL_90;
//         }
//       }

//       &:nth-child(3) {
//         padding-top: 16px;

//         span {
//           color: $DANGER_MAIN;
//         }
//       }
//     }
//   }
// }

// .centerAligner {
//   display: flex;
//   align-items: center;
//   height: 100%;
// }

// .control {
//   padding-inline: 15px;
//   display: flex;
//   align-items: center;
//   min-width: 150px;
//   cursor: pointer;

//   & > div {
//     display: flex;
//     height: 100%;
//     align-items: center;
//   }

//   .category {
//     height: 32px;
//     width: 121px;
//     border-radius: 5px;
//     padding-inline: 8px;
//     // -webkit-appearance: none;
//     outline: none;
//     background-color: #f4f4f4;
//     color: #6f6f70;
//     border: 0px;
//     left: 4px;
//     position: relative;
//     z-index: 2;
//     margin-left: 15px;
//   }

//   .selectArrow {
//     transform: translateX(-20px);
//     margin-right: -10px;
//     position: relative;
//     z-index: 2;
//     pointer-events: none;
//   }

//   .search {
//     height: 40px;
//     width: 395px;
//     border: 1.5px solid #d3c9c9;
//     border-radius: 8px;
//     transform: translateX(-129px);
//     margin-right: -129px;
//     padding-left: 135px;
//     padding-right: 35px;
//     outline: none;
//     z-index: 1px;
//   }

//   .inputSearch {
//     transform: translateX(-28px);
//     margin-right: -15px;
//     position: relative;
//     z-index: 2;
//   }

//   .profilePicture {
//     aspect-ratio: 1;
//     height: 42px;
//     border-radius: 21px;
//   }

//   .User {
//     flex-direction: column;
//     justify-content: center;
//     align-items: flex-start;
//     margin-left: 10px;

//     div {
//       max-width: 160px;
//       text-overflow: ellipsis;
//       white-space: nowrap;
//       overflow: hidden;
//     }

//     :first-child {
//       line-height: 21px;

//       span {
//         font-size: 14px;
//         font-weight: 500;
//         color: #000;
//       }
//     }

//     :last-child {
//       line-height: 15px;

//       span {
//         font-size: 10px;
//         color: #00000099;
//       }
//     }
//   }

//   .verticalLine {
//     width: 1px;
//     height: 28px;
//     background-color: #e4e4e4;
//   }

//   .bell {
//     margin-inline: -10px;

//     .notch {
//       height: 12px;
//       aspect-ratio: 1;
//       border-radius: 50%;
//       background-color: #ff0000;
//       position: absolute;
//       transform: translate(20px, -10px);
//       pointer-events: none;
//     }
//   }

//   .register {
//     background-color: #1571de;
//     color: #fff;
//     border-radius: 20px;
//     height: 40px;
//   }
// }

// @media only screen and (max-width: 768px) {
//   .nav {
//     padding-right: 0;
//     padding-inline: 20px;

//     .linkArea {
//       align-items: center;

//       .hamburgerMenu {
//         display: block;
//       }

//       .logoArea {
//         padding-right: 0;
//         padding-left: 10px;

//         & > img {
//           width: 102px;
//           height: 42px;
//         }
//       }

//       .wrapper {
//         display: none;
//       }
//     }

//     .actionArea {
//       padding-right: 0;
//     }

//     .jobSeekersButton {
//       width: auto !important;
//     }

//     .loginButton {
//       width: 93px;
//       height: 36px;
//     }
//   }
// }

.wrapper {
  max-width: 100vw;
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.05);
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 10;
}

.headerBody {
  margin: auto;
  max-width: 1440px;
  width: 100%;
  height: 100%;
  .linkArea {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // gap: 16px;
    padding: 16px 40px 12px 40px;
  }
  .left {
    display: flex;
    align-items: center;
    gap: 40px;
  }

  .logoBox {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .span {
    color: var(--neutral-60, #9e9e9e);

    /* Text M/Reguler */
    // font-family: Poppins;
    font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    &.spanActive {
      color: #000;
      border-bottom: 2px solid #000;
    }
  }
  .imagesBox {
    width: 102px;
    height: 42px;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .right {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .login {
    background: #fff;
    border: 1px solid var(--neutral-40, #e0e0e0);
    padding: 8px 25px;
    color: #1571de;
    border-radius: 23.5px;
    font-size: calc(10px + (14 - 10) * ((100vw - 280px) / (1600 - 280)));
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
    letter-spacing: 0.07px;
    text-decoration: none;
    cursor: pointer;
    &.blueBg {
      background: #1571de;
      color: #fff;
    }
  }

  .register {
    background: #1571de;
    padding: 8px 25px;
    color: #fff;
    border-radius: 23.5px;
    font-size: calc(10px + (14 - 10) * ((100vw - 280px) / (1600 - 280)));
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
    letter-spacing: 0.07px;
    text-decoration: none;
    cursor: pointer;
  }

  .buttonApp {
    border-radius: 23.5px;
    background: #1571de;
    padding: 8px 25px;
    color: #fff;
    // font-family: Poppins;
    font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
    letter-spacing: 0.07px;
    text-decoration: none;
    cursor: pointer;
  }

  .iconDown {
    cursor: pointer;
  }

  .profileBox {
    display: flex;
    align-items: center;
    gap: 16px;
    cursor: pointer;
    .nameProfile {
      display: flex;
      flex-direction: column;
      gap: 4px;
      .username {
        color: #000;
        // font-family: Poppins;
        font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 21px */
        letter-spacing: 0.07px;
      }
      .position {
        color: var(--neutral-60, #9e9e9e);

        /* Text M/Reguler */
        // font-family: Poppins;
        font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
      }
    }
    .imagesProfile {
      // width: 30%;
      width: 40px;
      height: 40px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .dropDownProfile {
    position: absolute;
    // width: 15%;
    top: 65px;
    right: 30px;

    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 8px 8px 16px 8px;
    border-radius: 8px;
    background: var(--neutral-10, #fff);
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    .act {
      padding-bottom: 8px;
      border-bottom: 1px solid #ededed;
      color: var(--neutral-70, #757575);

      /* Text M/Medium */
      // font-family: Poppins;
      font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
    }
    .routeAction {
      display: flex;
      flex-direction: column;
      gap: 16px;
      .box {
        display: flex;
        align-items: center;
        gap: 8px;
        cursor: pointer;
        .logout {
          color: var(--danger-main, #ff3e13);
          // font-family: Poppins;
          font-size: calc(14px + (16 - 14) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 125% */
        }
        .dashboard {
          color: var(--neutral-90, #404040);
          // font-family: Poppins;
          font-size: calc(14px + (16 - 14) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 125% */
        }
      }
    }
  }
}
.bgModal {
  display: none;
  .modalContainer {
    display: none;
  }
}

.bgModalProfile {
  display: none;
}

@media only screen and (max-width: 768px) {
  .headerBody {
    .profileBox {
      .nameProfile {
        display: none;
      }
    }

    .linkArea {
      padding: 16px 18px 12px 18px;
    }
    .span {
      display: none;
    }
    .dropDownProfile {
      display: none;
    }
  }

  .bgModal {
    position: absolute;
    display: flex;
    top: 65px;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    .modalContainer {
      background-color: $NEUTRAL_10;
      padding: 18px 18px 18px 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 30px;
      border-radius: 0px 0px 20px 20px;
      .h1 {
        color: var(--neutral-60, #9e9e9e);

        /* Text M/Reguler */
        // font-family: Poppins;
        font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        &.spanActive {
          color: #000;
          border-bottom: 2px solid #000;
        }
      }

      span {
        color: #000;
        font-weight: 400;
        font-size: 16px;
        cursor: pointer;

        &:hover {
          font-weight: 500;
          border-bottom: 1px solid #000;
          transition: 0.08s;
        }

        &.active {
          font-weight: 500;
          border-bottom: 1px solid #000;
        }
      }
    }

    .null {
      width: 100%;
      height: 100%;
      background-color: $NEUTRAL_OVERLAY;
      opacity: 1;
      z-index: 1;
      // background: #000;
    }
  }

  .bgModalProfile {
    position: absolute;
    display: flex;
    top: 65px;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    .profileBoxDropdown {
      display: flex;
      align-items: center;
      gap: 16px;
      cursor: pointer;
      padding-bottom: 8px;
      border-bottom: 1px solid #ededed;
      .nameProfile {
        display: flex;
        flex-direction: column;
        gap: 4px;
        .username {
          color: #000;
          // font-family: Poppins;
          font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 21px */
          letter-spacing: 0.07px;
        }
        .position {
          color: var(--neutral-60, #9e9e9e);

          /* Text M/Reguler */
          // font-family: Poppins;
          font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
        }
      }
      .imagesProfile {
        // width: 30%;
        width: 40px;
        height: 40px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .modalContainer {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 16px;
      // border-radius: 8px;
      background: var(--neutral-10, #fff);
      box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);

      .routeAction {
        display: flex;
        flex-direction: column;
        gap: 16px;
        .box {
          display: flex;
          align-items: center;
          gap: 8px;
          cursor: pointer;
          .logout {
            color: var(--danger-main, #ff3e13);
            // font-family: Poppins;
            font-size: calc(
              14px + (16 - 14) * ((100vw - 280px) / (1600 - 280))
            );
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 125% */
          }
          .dashboard {
            color: var(--neutral-90, #404040);
            // font-family: Poppins;
            font-size: calc(
              14px + (16 - 14) * ((100vw - 280px) / (1600 - 280))
            );
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 125% */
          }
        }
      }
    }
    .null {
      width: 100%;
      height: 100%;
      background-color: $NEUTRAL_OVERLAY;
      opacity: 1;
      z-index: 1;
      // background: #000;
    }
  }
}

@media only screen and (max-width: 400px) {
  .bgModal {
    top: 59px;
  }
  .headerBody {
    .imagesBox {
      width: 50px;
      height: 25px;
    }
  }
}
