.container {
  .header {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    border-bottom: 1px solid var(--Color-Neutal-Neutral-40, #e0e0e0);
    > span {
      color: #000;

      /* Heading 6/SemiBold */
      //   font-family: Poppins;
      font-size: calc(20px + (24 - 20) * ((100vw - 280px) / (1600 - 280)));
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 33.6px */
      letter-spacing: 0.12px;
    }
    .iconCross {
      cursor: pointer;
    }
  }

  .contents {
    display: flex;
    min-width: 700px;
    max-width: 120%;
    overflow: scroll;
    .first {
      width: 100%;
      min-width: 350px;
      // border-right: 1px solid #e0e0e0;
      display: flex;
      flex-direction: column;
      .inputBox {
        width: 100%;
        position: relative;
        border-bottom: 1px solid var(--Neutral-30, #ededed);
        border-right: 1px solid #e0e0e0;
        > input {
          width: 100%;
          //   border: n;
          border: none;
          padding: 12px;
        }

        > input::placeholder {
          overflow: hidden;
          color: var(--Neutral-60, var(--Color-Neutal-Neutral-60, #9e9e9e));
          text-overflow: ellipsis;
          white-space: nowrap;

          /* Text M/Reguler */
          //   font-family: Poppins;
          font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
        }

        > input:focus {
          outline: none !important;
          border-color: #1571de;
          border-width: 1px 1px 1px 1px;
          border-style: solid;
          //   border-radius: 4px;
        }

        .iconSearch {
          position: absolute;
          right: 0;
          top: 0px;
          padding: 12px;
        }
      }

      .tableHead {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px;
        border-bottom: 1px solid var(--Neutral-30, #ededed);
        border-right: 1px solid #e0e0e0;
        > span:first-child {
          width: 10%;
          color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));

          /* Text M/Medium */
          // font-family: Poppins;
          font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 142.857% */
        }
        > span:last-child {
          width: 100%;
          color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));

          /* Text M/Medium */
          // font-family: Poppins;
          font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 142.857% */
        }
      }

      .tableBody {
        display: flex;
        flex-direction: column;
        max-height: 450px;
        min-height: 450px;
        // overflow-x: clip;
        overflow-y: scroll;
        border-right: 1px solid #e0e0e0;

        .notFound {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-grow: 1;
          padding: 16px;
          > div {
            display: flex;
            flex-direction: column;
            gap: 4px;
            align-items: center;
            > div {
              img {
                width: 206.775px;
                height: 154.072px;
              }
            }

            > span {
              color: var(--Neutral-60, var(--Color-Neutal-Neutral-60, #9e9e9e));
              text-align: center;

              /* Text S/Reguler */
              // font-family: Poppins;
              font-size: 13px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px; /* 153.846% */
            }
          }
        }
        .body {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 16px;
          border-bottom: 1px solid var(--Neutral-30, #ededed);
          cursor: pointer;
          position: relative;
          height: 56px;
          &.active {
            background-color: #1571de;
            &:hover {
              background-color: #1571de;
            }
            > span:first-child {
              color: var(--Neutral-10, var(--Color-Neutal-Neutral-10, #fff));
            }
            > div:nth-child(2) {
              > div:last-child {
                > span:first-child {
                  color: var(
                    --Neutral-10,
                    var(--Color-Neutal-Neutral-10, #fff)
                  );
                }
                > span:last-child {
                  color: var(
                    --Neutral-40,
                    var(--Color-Neutal-Neutral-40, #e0e0e0)
                  );
                }
              }
            }
          }
          > span:first-child {
            width: 10%;
            color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));

            /* Text M/Reguler */
            // font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
          }
          > div:nth-child(2) {
            width: 100%;
            display: flex;
            align-items: center;
            gap: 8px;
            > div:first-child {
              border-radius: 50px;
              background: var(--Color-Neutal-Neutral-30, #ededed);
              width: 30px;
              height: 30px;
              display: flex;
              padding: 4px;

              flex-direction: column;
              align-items: center;
            }
            > div:last-child {
              display: flex;
              flex-direction: column;
              gap: 2px;

              > span:first-child {
                color: var(--Primary-Main, #1571de);
                // font-family: Poppins;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-transform: uppercase;
                // text-overflow: ellipsis;
              }

              > span:last-child {
                // overflow: hidden;
                color: var(
                  --Neutral-50,
                  var(--Color-Neutal-Neutral-50, #c2c2c2)
                );
                // text-overflow: ellipsis;
                // white-space: nowrap;
                // // font-family: Poppins;
                // width:;
                // max-width: 400px;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                display: flex;
                align-items: center;
                gap: 4px;
              }
            }
          }
          > div:nth-child(3) {
            width: 30px;
            position: relative;
            top: 3px;
            // content: url("../../../../../../assets/Images/Polygon-13.png");
          }
        }
        .body:hover {
          background-color: #f5f7fe;
        }
      }
    }
    .second {
      width: 100%;
      display: flex;
      flex-direction: column;
      min-width: 300px;
      .inputBox {
        width: 100%;
        position: relative;
        border-bottom: 1px solid var(--Neutral-30, #ededed);
        border-right: 1px solid #e0e0e0;
        > input {
          width: 100%;
          //   border: n;
          border: none;
          padding: 12px;
        }

        > input::placeholder {
          overflow: hidden;
          color: var(--Neutral-60, var(--Color-Neutal-Neutral-60, #9e9e9e));
          text-overflow: ellipsis;
          white-space: nowrap;

          /* Text M/Reguler */
          //   font-family: Poppins;
          font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
        }

        > input:focus {
          outline: none !important;
          border-color: #1571de;
          border-width: 1px 1px 1px 1px;
          border-style: solid;
          //   border-radius: 4px;
        }

        .iconSearch {
          position: absolute;
          right: 0;
          top: 0px;
          padding: 12px;
        }
      }

      .tableHead {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px;
        border-bottom: 1px solid var(--Neutral-30, #ededed);
        border-right: 1px solid #e0e0e0;
        > span:first-child {
          width: 10%;
          color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));

          /* Text M/Medium */
          // font-family: Poppins;
          font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 142.857% */
        }
        > span:last-child {
          width: 100%;
          color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));

          /* Text M/Medium */
          // font-family: Poppins;
          font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 142.857% */
        }
      }

      .tableBody {
        display: flex;
        flex-direction: column;
        max-height: 450px;
        min-height: 450px;
        // overflow-x: clip;
        overflow-y: scroll;
        border-right: 1px solid #e0e0e0;
        .notFound {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-grow: 1;
          padding: 16px;
          > div {
            display: flex;
            flex-direction: column;
            gap: 4px;
            align-items: center;
            > div {
              img {
                width: 206.775px;
                height: 154.072px;
              }
            }

            > span {
              color: var(--Neutral-60, var(--Color-Neutal-Neutral-60, #9e9e9e));
              text-align: center;

              /* Text S/Reguler */
              // font-family: Poppins;
              font-size: 13px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px; /* 153.846% */
            }
          }
        }

        .bodySecond {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 16px;
          border-bottom: 1px solid var(--Neutral-30, #ededed);
          cursor: pointer;
          position: relative;
          height: 56px;
          &.active {
            background-color: #1571de;
            &:hover {
              background-color: #1571de;
            }
            > span:first-child {
              color: var(--Neutral-10, var(--Color-Neutal-Neutral-10, #fff));
            }
            > div:nth-child(2) {
              > span:first-child {
                color: var(--Neutral-10, var(--Color-Neutal-Neutral-10, #fff));
              }
              > span:last-child {
                color: var(
                  --Neutral-40,
                  var(--Color-Neutal-Neutral-40, #e0e0e0)
                );
              }
            }
          }

          > span:first-child {
            width: 10%;
            color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));

            /* Text M/Reguler */
            // font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
          }
          > div:nth-child(2) {
            width: 100%;
            display: flex;

            flex-direction: column;
            gap: 2px;
            // justify-content: space-between;

            > span:first-child {
              color: var(--Primary-Main, #1571de);
              // font-family: Poppins;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              text-transform: uppercase;
              // width: 100%;S
            }
            > span:last-child {
              // overflow: hidden;
              color: var(--Neutral-70, var(--Color-Neutal-Neutral-70, #757575));
              // text-overflow: ellipsis;
              // font-family: Poppins;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              // text-align: center;
            }
          }
          > div:nth-child(3) {
            width: 30px;
            position: relative;
            top: 3px;
          }
        }
        .bodySecond:hover {
          background-color: #f5f7fe;
        }
      }
    }

    .third {
      width: 100%;
      min-width: 300px;
      .inputBox {
        width: 100%;
        position: relative;
        border-bottom: 1px solid var(--Neutral-30, #ededed);
        border-right: 1px solid #e0e0e0;
        > input {
          width: 100%;
          //   border: n;
          border: none;
          padding: 12px;
        }

        > input::placeholder {
          overflow: hidden;
          color: var(--Neutral-60, var(--Color-Neutal-Neutral-60, #9e9e9e));
          text-overflow: ellipsis;
          white-space: nowrap;

          /* Text M/Reguler */
          //   font-family: Poppins;
          font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
        }

        > input:focus {
          outline: none !important;
          border-color: #1571de;
          border-width: 1px 1px 1px 1px;
          border-style: solid;
          //   border-radius: 4px;
        }

        .iconSearch {
          position: absolute;
          right: 0;
          top: 0px;
          padding: 12px;
        }
      }

      .tableHead {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px;
        border-bottom: 1px solid var(--Neutral-30, #ededed);
        border-right: 1px solid #e0e0e0;
        > span:first-child {
          width: 10%;
          color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));

          /* Text M/Medium */
          // font-family: Poppins;
          font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 142.857% */
        }
        > span:last-child {
          width: 100%;
          color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));

          /* Text M/Medium */
          // font-family: Poppins;
          font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 142.857% */
        }
      }

      .tableBody {
        display: flex;
        flex-direction: column;
        max-height: 450px;
        min-height: 450px;
        // overflow-x: clip;
        overflow-y: scroll;
        border-right: 1px solid #e0e0e0;
        .notFound {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-grow: 1;
          padding: 16px;
          > div {
            display: flex;
            flex-direction: column;
            gap: 4px;
            align-items: center;
            > div {
              img {
                width: 206.775px;
                height: 154.072px;
              }
            }

            > span {
              color: var(--Neutral-60, var(--Color-Neutal-Neutral-60, #9e9e9e));
              text-align: center;

              /* Text S/Reguler */
              // font-family: Poppins;
              font-size: 13px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px; /* 153.846% */
            }
          }
        }

        .bodySecond {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 16px;
          border-bottom: 1px solid var(--Neutral-30, #ededed);
          cursor: pointer;
          position: relative;
          height: 56px;

          > span:first-child {
            width: 10%;
            color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));

            /* Text M/Reguler */
            // font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
          }
          > div:nth-child(2) {
            display: flex;
            align-items: center;
            gap: 8px;
            width: 100%;
            > div:first-child {
              > img {
                width: 36px;
                height: 36px;
                border-radius: 50px;
              }
            }

            > div:last-child {
              display: flex;
              flex-direction: column;
              gap: 2px;
              > span:first-child {
                color: var(--Primary-Main, #1571de);
                // font-family: Poppins;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-transform: uppercase;
                // width: 100%;S
              }
              > span:last-child {
                // overflow: hidden;
                color: var(
                  --Neutral-70,
                  var(--Color-Neutal-Neutral-70, #757575)
                );
                // text-overflow: ellipsis;
                // font-family: Poppins;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                display: flex;
                align-items: center;
                gap: 2px;
                // text-align: center;
              }
            }
            // justify-content: space-between;

            // > span:first-child {
            //   color: var(--Primary-Main, #1571de);
            //   // font-family: Poppins;
            //   font-size: 12px;
            //   font-style: normal;
            //   font-weight: 400;
            //   line-height: normal;
            //   text-transform: uppercase;
            //   // width: 100%;S
            // }
            // > span:last-child {
            //   // overflow: hidden;
            //   color: var(--Neutral-70, var(--Color-Neutal-Neutral-70, #757575));
            //   // text-overflow: ellipsis;
            //   // font-family: Poppins;
            //   font-size: 12px;
            //   font-style: normal;
            //   font-weight: 400;
            //   line-height: normal;
            //   // text-align: center;
            // }
          }
        }
      }
    }
  }

  .contentsResponsive {
    display: flex;
    flex-direction: column;
    width: 100%;
    > div:first-child {
      display: flex;
      width: 100%;
      > div {
        width: 100%;
        border: 1px solid #ededed;
        // background: var(--Color-Neutal-Neutral-10, #fff);
        display: flex;
        flex-direction: column;
        gap: 2px;
        padding: 3px 12px;
        cursor: pointer;
        &.active {
          border-right: 1px solid var(--Color-Neutal-Neutral-30, #ededed);
          background: var(--Primary-Main, #1571de);

          > span:first-child {
            color: var(--Neutral-10, var(--Color-Neutal-Neutral-10, #fff));
          }
          > span:last-child {
            color: var(--Neutral-10, var(--Color-Neutal-Neutral-10, #fff));
          }
        }
        > span:first-child {
          color: var(--Color-Neutal-Neutral-50, #c2c2c2);

          /* Caption 1/Reguler */
          // font-family: Poppins;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px; /* 133.333% */
        }

        > span:last-child {
          color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));

          /* Text S/Medium */
          // font-family: Poppins;
          font-size: 13px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 153.846% */
        }
      }
    }

    .inputBox {
      width: 100%;
      position: relative;
      border-bottom: 1px solid var(--Neutral-30, #ededed);
      // border-right: 1px solid #e0e0e0;
    }

    .tableHead {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px;
      border-bottom: 1px solid var(--Neutral-30, #ededed);
      border-right: 1px solid #e0e0e0;
      > span:first-child {
        width: 10%;
        color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));

        /* Text M/Medium */
        // font-family: Poppins;
        font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
      }
      > span:last-child {
        width: 100%;
        color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));

        /* Text M/Medium */
        // font-family: Poppins;
        font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
      }
    }

    .tableBody {
      display: flex;
      flex-direction: column;
      max-height: 400px;
      min-height: 400px;
      // overflow-x: clip;
      overflow-y: scroll;
      border-right: 1px solid #e0e0e0;

      .notFound {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
        padding: 16px;
        > div {
          display: flex;
          flex-direction: column;
          gap: 4px;
          align-items: center;
          > div {
            img {
              width: 206.775px;
              height: 154.072px;
            }
          }

          > span {
            color: var(--Neutral-60, var(--Color-Neutal-Neutral-60, #9e9e9e));
            text-align: center;

            /* Text S/Reguler */
            // font-family: Poppins;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 153.846% */
          }
        }
      }
      .body {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px;
        border-bottom: 1px solid var(--Neutral-30, #ededed);
        cursor: pointer;
        position: relative;
        height: 56px;
        &.active {
          background-color: #1571de;
          &:hover {
            background-color: #1571de;
          }
          > span:first-child {
            color: var(--Neutral-10, var(--Color-Neutal-Neutral-10, #fff));
          }
          > div:nth-child(2) {
            > div:last-child {
              > span:first-child {
                color: var(--Neutral-10, var(--Color-Neutal-Neutral-10, #fff));
              }
              > span:last-child {
                color: var(
                  --Neutral-40,
                  var(--Color-Neutal-Neutral-40, #e0e0e0)
                );
              }
            }
          }
        }
        > span:first-child {
          width: 10%;
          color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));

          /* Text M/Reguler */
          // font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
        }
        > div:nth-child(2) {
          width: 100%;
          display: flex;
          align-items: center;
          gap: 8px;
          > div:first-child {
            border-radius: 50px;
            background: var(--Color-Neutal-Neutral-30, #ededed);
            width: 30px;
            height: 30px;
            display: flex;
            padding: 4px;

            flex-direction: column;
            align-items: center;
          }
          > div:last-child {
            display: flex;
            flex-direction: column;
            gap: 2px;

            > span:first-child {
              color: var(--Primary-Main, #1571de);
              // font-family: Poppins;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              text-transform: uppercase;
              // text-overflow: ellipsis;
            }

            > span:last-child {
              // overflow: hidden;
              color: var(--Neutral-50, var(--Color-Neutal-Neutral-50, #c2c2c2));
              // text-overflow: ellipsis;
              // white-space: nowrap;
              // // font-family: Poppins;
              // width:;
              // max-width: 400px;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              display: flex;
              align-items: center;
              gap: 4px;
            }
          }
        }
        > div:nth-child(3) {
          width: 30px;
          position: relative;
          top: 3px;
          // content: url("../../../../../../assets/Images/Polygon-13.png");
        }
      }
      .body:hover {
        background-color: #f5f7fe;
      }
      .bodySecond {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px;
        border-bottom: 1px solid var(--Neutral-30, #ededed);
        cursor: pointer;
        position: relative;
        height: 56px;
        &.active {
          background-color: #1571de;
          &:hover {
            background-color: #1571de;
          }
          > span:first-child {
            color: var(--Neutral-10, var(--Color-Neutal-Neutral-10, #fff));
          }
          > div:nth-child(2) {
            > span:first-child {
              color: var(--Neutral-10, var(--Color-Neutal-Neutral-10, #fff));
            }
            > span:last-child {
              color: var(--Neutral-40, var(--Color-Neutal-Neutral-40, #e0e0e0));
            }
          }
        }

        > span:first-child {
          width: 10%;
          color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));

          /* Text M/Reguler */
          // font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
        }
        > div:nth-child(2) {
          width: 100%;
          display: flex;

          flex-direction: column;
          gap: 2px;
          // justify-content: space-between;

          > span:first-child {
            color: var(--Primary-Main, #1571de);
            // font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: uppercase;
            // width: 100%;S
          }
          > span:last-child {
            // overflow: hidden;
            color: var(--Neutral-70, var(--Color-Neutal-Neutral-70, #757575));
            // text-overflow: ellipsis;
            // font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            // text-align: center;
          }
        }
        > div:nth-child(3) {
          width: 30px;
          position: relative;
          top: 3px;
        }
      }
      .bodySecond:hover {
        background-color: #f5f7fe;
      }

      .bodyThird {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px;
        border-bottom: 1px solid var(--Neutral-30, #ededed);
        cursor: pointer;
        position: relative;
        height: 56px;

        > span:first-child {
          width: 10%;
          color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));

          /* Text M/Reguler */
          // font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
        }
        > div:nth-child(2) {
          display: flex;
          align-items: center;
          gap: 8px;
          width: 100%;
          > div:first-child {
            > img {
              width: 36px;
              height: 36px;
              border-radius: 50px;
            }
          }

          > div:last-child {
            display: flex;
            flex-direction: column;
            gap: 2px;
            > span:first-child {
              color: var(--Primary-Main, #1571de);
              // font-family: Poppins;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              text-transform: uppercase;
              // width: 100%;S
            }
            > span:last-child {
              // overflow: hidden;
              color: var(--Neutral-70, var(--Color-Neutal-Neutral-70, #757575));
              // text-overflow: ellipsis;
              // font-family: Poppins;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              display: flex;
              align-items: center;
              gap: 2px;
              // text-align: center;
            }
          }
          // justify-content: space-between;

          // > span:first-child {
          //   color: var(--Primary-Main, #1571de);
          //   // font-family: Poppins;
          //   font-size: 12px;
          //   font-style: normal;
          //   font-weight: 400;
          //   line-height: normal;
          //   text-transform: uppercase;
          //   // width: 100%;S
          // }
          // > span:last-child {
          //   // overflow: hidden;
          //   color: var(--Neutral-70, var(--Color-Neutal-Neutral-70, #757575));
          //   // text-overflow: ellipsis;
          //   // font-family: Poppins;
          //   font-size: 12px;
          //   font-style: normal;
          //   font-weight: 400;
          //   line-height: normal;
          //   // text-align: center;
          // }
        }
      }
    }
  }

  .buttonSection {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 16px;
    border-top: 1px solid var(--Color-Neutal-Neutral-40, #e0e0e0);
    > div {
      display: flex;
      align-items: center;
      gap: 8px;
      > .first {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        border: 1px solid var(--Primary-Main, #1571de);
        background: var(--Neutral-10, #fff);
        padding: 12px;
        color: var(--Primary-Main, #1571de);
        text-align: center;

        /* Text M/Reguler */
        // font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        cursor: pointer;
        &.disabled {
          background: var(--Neutral-40, #e0e0e0);
          color: var(--Neutral-60, var(--Color-Neutal-Neutral-60, #9e9e9e));
          border: none;
        }
      }

      .second {
        cursor: pointer;
        border-radius: 8px;
        background: var(--Primary-Main, #1571de);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px;
        color: var(--Color-Neutal-Neutral-10, #fff);
        text-align: center;

        /* Text M/Reguler */
        // font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        &.disabled {
          background: var(--Neutral-40, #e0e0e0);
          color: var(--Neutral-60, var(--Color-Neutal-Neutral-60, #9e9e9e));
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .container {
    .contents {
      .first {
        .tableBody {
          max-height: 300px;
          min-height: 300px;
        }
      }

      .second {
        .tableBody {
          max-height: 300px;
          min-height: 300px;
        }
      }

      .third {
        .tableBody {
          max-height: 300px;
          min-height: 300px;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    .contentsResponsive {
      .tableBody {
        max-height: 250px;
        min-height: 250px;
      }
    }
  }
}
