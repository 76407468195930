@import "../../../../../../../../theme/variables.scss";

.container {
  width: 100%;
  min-height: 100%;
  z-index: inherit;

  .modalWrapper {
    position: fixed;
    transition: all 0.35s ease-in-out !important;
    z-index: 4;
    background-color: transparent;
    left: 0;
    width: 100%;

    &.hideModal {
      bottom: -1000px;
    }

    &.showModal {
      bottom: 0;
    }
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    background-color: $NEUTRAL_OVERLAY;
    opacity: 0;
    z-index: 3;

    &.visible {
      right: 0;
      bottom: 0;
      opacity: 1;
      transition: opacity 0.4s;
    }
  }
}
