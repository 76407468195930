@import "../../../../theme/variables.scss";

.container {
  width: 90vw;
  background-color: $NEUTRAL-10;
  border-radius: 12px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  gap: 16px;
  max-height: 90vh;


  .header {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .top {
      display: flex;
      align-items: center;
      gap: 16px;

      >h3 {
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        /* 30px */
        letter-spacing: 0.1px;
        color: $PRIMARY_MAIN;
      }

      >span {
        flex-grow: 1;
      }

      .jpButton {
        display: flex;
        align-items: center;
        gap: 4px;
        background-color: $NEUTRAL-10;
        border: 1px solid $PRIMARY_MAIN;
        border-radius: 8px;
        color: $PRIMARY_MAIN;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 142.857%;
        padding: 8px;
      }

      .cButton {
        display: grid;
        place-items: center;
        padding: 0;
        border: none;
        background-color: transparent;
      }
    }

    .clientName {
      display: flex;
      align-items: center;
      gap: 8px;
      color: $NEUTRAL-60;

      >span {
        font-family: Poppins;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 153.846%;
      }
    }
  }

  .content {
    border: 1px solid $NEUTRAL-40;
    border-radius: 8px;
  }

  .cHeader {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 16px;
    border-bottom: 1px solid $NEUTRAL-40;

    >h4 {
      flex-grow: 1;
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
      letter-spacing: 0.1px;
      white-space: nowrap;
    }

    .cHRight {
      display: flex;
      align-items: center;
      gap: 16px;

      .searchWrapper {
        height: 44px;
        max-width: 440px;
        width: 300px;
      }

      >button {
        display: flex;
        align-items: center;
        gap: 8px;
        white-space: nowrap;
        background-color: transparent;
        border: none;
        color: $PRIMARY_MAIN;

        >span {
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 142.857%;
        }
      }

    }

  }

  .details {
    height: 500px;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid $NEUTRAL-40;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .dHeader {
      >button {
        display: flex;
        align-items: center;
        gap: 4px;
        background-color: transparent;
        border: none;

        >span {
          font-family: Poppins;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 150%;
          letter-spacing: 0.1px;
        }
      }
    }

    .dContent {
      display: grid;
      grid-template-columns: 300px 1fr;
      height: 100px;
      flex-grow: 1;
      overflow: auto;

      .dLeft {
        border-right: 1px solid $NEUTRAL-30;
        display: flex;
        flex-direction: column;

        .dLTop {
          display: flex;
          align-items: center;
          gap: 8px;
          border-bottom: 1px solid $NEUTRAL-30;
          @include padding(20, 16, 16px);

          .name {
            display: flex;
            flex-direction: column;

            >span {
              font-family: Poppins;
              // font-size: 20px;
              @include font-size(20, 16, 16px);
              font-style: normal;
              font-weight: 700;
              line-height: 150%;
              letter-spacing: 0.1px;
            }

            >p {
              font-family: Poppins;
              // font-size: 16px;
              @include font-size(16, 14, 14px);
              font-style: normal;
              font-weight: 400;
              line-height: 140%;
              /* 22.4px */
              color: $NEUTRAL-80;
            }
          }


        }

        .dLBottom {
          display: flex;
          flex-direction: column;
          @include padding(20, 16, 16px);
          height: 100px;
          overflow: auto;
          flex-grow: 1;
          // padding-inline: 20px;

          .menu {
            background-color: transparent;
            border: none;
            @include padding(12, 10, 10px);
            border-radius: 8px;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 142.857%;
            text-align: start;

            &:hover {
              background-color: $PRIMARY_SOFT;
            }

            &.active {
              background-color: $PRIMARY_MAIN;
              color: $NEUTRAL-10;
            }


          }
        }
      }

      .dRight {
        overflow: auto;
        padding-inline: 20px;
        display: flex;
        flex-direction: column;


        .dRightHeader {
          padding-bottom: 16px;
          position: sticky;
          top: 0;
          display: flex;
          flex-direction: column;
          gap: 16px;
          background-color: $NEUTRAL-10;

          >h4 {
            font-family: Poppins;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 150%;
            letter-spacing: 0.1px;
          }

          .switcher {
            display: flex;
            align-items: center;
            gap: 8px;
            flex-wrap: wrap;

            .switchButton {
              padding: 12px 16px;
              border-radius: 100px;
              border: 1px solid $NEUTRAL-40;
              background-color: transparent;
              font-family: Poppins;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 142.857%;
              white-space: nowrap;

              &.active {
                border-color: $PRIMARY_MAIN;
                color: $PRIMARY_MAIN;
              }
            }
          }
        }

        .menuContent {
          display: flex;
          flex-direction: column;
          gap: 12px;
        }

        .familyData,
        .educationData {
          flex-grow: 1;

          .fType {
            display: flex;
            flex-direction: column;
            gap: 8px;

            &:not(:last-child) {
              border-bottom: 1px solid $NEUTRAL-40;
              padding-bottom: 16px;
            }

            >h5 {
              font-family: Poppins;
              @include font-size(16, 14, 14px);
              font-style: normal;
              font-weight: 600;
              line-height: 140%;
              /* 22.4px */
            }

            .fData {
              display: flex;
              flex-direction: column;
              gap: 8px;

              &:not(:last-child) {
                border-bottom: 1px solid $NEUTRAL-20;
                padding-bottom: 8px;
              }

            }

          }

        }

        .documentData {
          display: flex;
          flex-direction: column;
          gap: 16px;
          flex-grow: 1;


          .documents {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
            gap: 16px;
          }
        }
      }
    }
  }

  .center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

}

.dataDetails {
  display: grid;
  grid-template-columns: 250px auto 1fr;
  @include gap(16, 8, 8px);

  >span {
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: $NEUTRAL-90;

    &:first-child {
      font-weight: 500;
      color: $NEUTRAL-100;
    }
  }
}

.filter {
  position: fixed;
  right: -1000px;
  top: 0;
  height: 100vh;
  background-color: $NEUTRAL-10;
  width: 291px;
  transition: 0.3s ease;
  border-radius: 8px 0 0 8px;
  overflow: auto;


  &.show {
    right: 0;
  }

  .header {
    padding: 10px 8px;
    border-bottom: 1px solid $NEUTRAL-20;
    position: sticky;
    top: 0;
    background-color: $NEUTRAL-10;
    z-index: 1;

    .statusSearch {
      height: 44px;
    }
  }

  .content {
    display: flex;
    align-items: center;
    gap: 20px;

    .type {
      padding: 8px 10px;

      >h4 {
        border-bottom: 1px solid $NEUTRAL-40;
        padding-bottom: 8px;
      }

      &:not(:last-child) {
        border-bottom: 1px solid $NEUTRAL-20;
      }

      .subTypes {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding-top: 8px;

        .subType {
          display: flex;
          flex-direction: column;
          gap: 4px;

          >span {
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 142.857%;
            color: $NEUTRAL-90;
          }

          .option {
            display: flex;
            align-items: center;
            gap: 8px;

            >span {
              font-family: Poppins;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 142.857%;
            }
          }
        }
      }
    }
  }
}

.docCard {
  padding: 12px;
  border: 1px solid $NEUTRAL-30;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 20px;

  .preview {
    width: 84px;
    aspect-ratio: 1;
    border-radius: 8px;
    overlay: clip;
    display: grid;
    place-items: center;
    background-color: #E5F1FF;
    color: $PRIMARY_MAIN;

    >img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .desc {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    >span {
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    >p {
      color: #A0A0A0;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  >button {
    display: grid;
    place-items: center;
    background-color: transparent;
    border: none;
    color: $PRIMARY_MAIN;

    &:disabled {
      color: $PRIMARY_DISABLED
    }
  }
}

.empty {
  color: #A0A0A0;
  font-family: Poppins;
  @include font-size(16, 14, 14px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media screen and (max-width:768px) {
  .container {
    width: 100vw;

    .content {
      .cHeader {
        flex-wrap: wrap;

        >h4 {
          width: 100%;
        }

        .cHRight {
          width: 100%;

          .searchWrapper {
            flex-grow: 1;
            width: 100%;
          }

        }

      }
    }
  }

  .dataDetails {
    grid-template-columns: 130px auto 1fr;
  }
}