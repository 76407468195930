.container {
  overflow: auto;
  height: calc(100vh - 70px);

  .loadingWrapper {
    height: 100%;
    display: grid;
    place-items: center;

  }

  .navigatorWrapper {
    position: sticky;
    top: 0;
  }

  .contentWrapper {
    height: 100%;
  }
}

@media screen and (max-width: 768px) {
  .contentWrapper {
    height: 100%;


  }
}