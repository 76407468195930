.container {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 70px);
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    padding: 16px 30px;
    border-bottom: 1px solid;
    border-bottom: 1px solid var(--Color-Neutal-Neutral-40, #e0e0e0);
    background: var(--Color-Neutal-Neutral-20, #f5f5f5);
    position: sticky;
    top: 70px;
    z-index: 2;
    > div:first-child {
      display: flex;
      align-items: center;
      gap: 8px;
      > span:first-child {
        width: 30px;
        height: 30px;
        padding: 4px;
        border-radius: 50px;
        background-color: #fff;

        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      > span:nth-child(2) {
        color: #000;

        /* Heading 4/Bold */
        // font-family: Poppins;
        font-size: calc(16px + (30 - 16) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 700;
        line-height: 140%; /* 44.8px */
        letter-spacing: 0.16px;
      }

      > div:last-child {
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 6px 8px;
        border-radius: 9px;
        background: var(--Success-Surface, #eafef3);
        .dots {
          width: 12px;
          height: 12px;
          border-radius: 50px;
          background-color: #27bb83;
        }
        > span {
          color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));

          /* Text M/Medium */
          //   font-family: Poppins;
          font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 142.857% */
        }

        &.yellow {
          background: #fffdd9;
          border-radius: 9px;

          .dots {
            width: 12px;
            height: 12px;
            background-color: #e5dd1c;
            border-radius: 50px;
          }
        }

        &.green {
          border-radius: 9px;
          background: var(--Success-Surface, #eafef3);
          .dots {
            width: 12px;
            height: 12px;
            background-color: #27bb83;
            border-radius: 50px;
          }
        }

        &.grey {
          background-color: #e0dddd;
          // background-color: #c2c2c2;
          border-radius: 9px;
          .dots {
            width: 12px;
            height: 12px;
            background-color: #c2c2c2;
            border-radius: 50px;
          }
        }

        &.red {
          background: var(--Danger-Surface, #faeff2);
          .dots {
            width: 12px;
            height: 12px;
            background-color: #ff3e13;
            border-radius: 50px;
          }
        }
      }
    }

    > div:last-child {
      display: flex;
      align-items: center;
      gap: 16px;
      position: relative;
      .roDedicated {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 24px;
        border-radius: 8px;
        border: 1px solid var(--Color-Neutal-Neutral-30, #ededed);
        background: var(--Color-Neutal-Neutral-10, #fff);
        padding: 4px 6px 4px 12px;
        cursor: pointer;
        &.boxShadow {
          box-shadow: 0px 0px 1px rgba(9, 30, 66, 0.31),
            0px 3px 5px rgba(9, 30, 66, 0.2);
        }
        .dedicated {
          display: flex;
          align-items: center;
          gap: 8px;
          > span:nth-child(2) {
            color: var(--Neutral-90, var(--Color-Neutal-Neutral-90, #404040));

            /* Caption 1/Medium */
            // font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px; /* 133.333% */
          }
          .images {
            border-radius: 60px;
            position: relative;
            top: 2px;
            img {
              width: 24px;
              height: 24px;
            }
          }
        }

        .wa {
          position: relative;
          top: 2px;
          img {
            width: 30.063px;
            height: 30.153px;
          }
        }
      }

      .first {
        padding: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        border: 1px solid var(--Primary-Main, #1571de);
        background: var(--Neutral-10, #fff);
        color: var(--Primary-Main, #1571de);
        text-align: center;

        /* Text M/Medium */
        // font-family: Poppins;
        font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
        cursor: pointer;
        &.disabled {
          color: var(--Neutral-50, var(--Color-Neutal-Neutral-50, #c2c2c2));
          background-color: #e0dddd;
          border: 1px solid #e0dddd;
        }
      }

      .second {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px;
        border-radius: 8px;
        background: var(--Danger-Main, #ff3e13);
        color: var(--Color-Neutal-Neutral-10, #fff);
        text-align: center;

        /* Text M/Reguler */
        // font-family: Poppins;
        font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        cursor: pointer;
        &.disabled {
          color: var(--Neutral-50, var(--Color-Neutal-Neutral-50, #c2c2c2));
          background-color: #e0dddd;
          border: 1px solid #e0dddd;
        }

        &.darkBlue {
          background: var(--Primary-Main, #1571de);
        }
      }

      .third {
        display: flex;
        align-items: center;
        cursor: pointer;
      }

      .dropdown {
        position: absolute;
        top: 20px;
        background-color: #1571de;
        right: 0;
        top: 38px;
        border-radius: 8px;
        background: var(--Color-Neutal-Neutral-10, #fff);

        /* Dropdown Option Shadow */
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        display: flex;
        flex-direction: column;
        width: 250px;
        > span {
          color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));
          //   font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 19.6px */
          padding: 12px;
          &.disabled {
            color: var(--Neutral-50, var(--Color-Neutal-Neutral-50, #c2c2c2));
          }
          cursor: pointer;
          > span {
            font-weight: 700;
          }
        }
        > span:first-child {
          border-bottom: 1px solid #ededed;
        }

        > span:hover {
          background-color: #ededed;
        }
      }
    }
  }

  .content {
    display: flex;
    // align-items: center;
    flex-direction: row; /* Default to row */
    justify-content: space-between;
    gap: 16px;
    padding: 16px 30px;

    > div:first-child {
      width: 42%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      height: 100%;

      //   position: fixed;
      //   top: 160px;
      //   z-index: 2;

      .selectedImages {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 8px;
        border-radius: 8px;
        background: var(--Color-Neutal-Neutral-10, #fff);
        width: 100%;

        > span {
          color: var(--Primary-Main, #1571de);
          text-align: center;

          /* Text M/Medium */
          //   font-family: Poppins;
          font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 142.857% */
          padding: 8px 8px 8px 8px;
          width: 100%;
          //   background-color: #1571de;
          cursor: pointer;
          &.active {
            border-radius: 5px;
            background: var(--Primary-Main, #1571de);
            color: var(--Neutral-10, var(--Color-Neutal-Neutral-10, #fff));
          }
        }
      }

      .imagesBoxNull {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 16px;
        border-radius: 12px;
        border: 2px dashed var(--Color-Neutal-Neutral-40, #e0e0e0);
        height: 500px;
        width: 320px;

        > div {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 10px;
          .images {
            width: 167.839px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: 100%;
              height: 100%;
            }
          }
          > span {
            color: var(--Neutral-50, var(--Color-Neutal-Neutral-50, #c2c2c2));
            text-align: center;

            /* Text M/Reguler */
            // font-family: Poppins;
            font-size: calc(
              12px + (14 - 12) * ((100vw - 280px) / (1600 - 280))
            );
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
          }
        }
      }

      .imagesBox {
        height: 500px;
        width: 320px;
        border-radius: 12px;
        position: relative;
        img {
          height: 500px;
          width: 320px;
          object-fit: cover;
          border-radius: 12px;
        }

        .video {
          height: 500px;
          width: 320px;
          object-fit: cover;
          border-radius: 12px;
        }
        .iconPlayBox {
          position: absolute;
          bottom: 10px;
          left: 12px;
          display: flex;
          align-items: center;
          gap: 4px;
        }
        .iconPlay {
          display: flex;
          width: 29.767px;
          height: 29.767px;
          padding: 5.953px;
          justify-content: center;
          align-items: center;
          border-radius: 74.419px;
          background: var(--Color-Neutal-Neutral-10, #fff);
          cursor: pointer;
        }
      }
    }

    > div:nth-child(2) {
      width: 100%;
      height: 100%;
      border-radius: 8px;
      background: var(--Color-Neutal-Neutral-10, #fff);
      //   padding: 16px;
      .headerContent {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
        padding: 16px;
        border-bottom: 1px solid #e0e0e0;
        > div:first-child {
          display: flex;
          align-items: center;
          gap: 16px;
          > div:first-child {
            width: 24px;
          }

          > div:last-child {
            display: flex;
            flex-direction: column;
            gap: 2px;
            > span:first-child {
              color: var(
                --Neutral-100,
                var(--Color-Neutal-Neutral-19, #0a0a0a)
              );

              /* Heading 7/SemiBold */
              //   font-family: Poppins;
              font-size: calc(
                16px + (20 - 16) * ((100vw - 280px) / (1600 - 280))
              );
              font-style: normal;
              font-weight: 600;
              line-height: 150%; /* 30px */
              letter-spacing: 0.1px;
            }

            > span:last-child {
              color: var(--Neutral-60, var(--Color-Neutal-Neutral-60, #9e9e9e));

              /* Text M/Reguler */
              //   font-family: Poppins;
              font-size: calc(
                12px + (14 - 12) * ((100vw - 280px) / (1600 - 280))
              );
              font-style: normal;
              font-weight: 400;
              line-height: 20px; /* 142.857% */
            }
          }
        }

        > div:last-child {
          > span {
            border-radius: 8px;
            border: 1px solid var(--Primary-Main, #1571de);
            background: var(--Primary-Surface, #f5f7fe);
            padding: 4px 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: var(--Primary-Main, #1571de);
            text-align: center;

            /* Text M/Medium */
            // font-family: Poppins;
            font-size: calc(
              12px + (14 - 12) * ((100vw - 280px) / (1600 - 280))
            );
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 142.857% */
          }
        }
      }

      .flbContent {
        padding: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
        border-bottom: 1px solid #e0e0e0;
        > div:first-child {
          display: flex;
          align-items: center;
          gap: 16px;
          .imagesBox {
            img {
              width: 24px;
              height: 24px;
            }
          }

          > div:nth-child(2) {
            display: flex;
            flex-direction: column;
            gap: 2px;
            > span:first-child {
              color: var(--Neutral-60, var(--Color-Neutal-Neutral-60, #9e9e9e));

              /* Text M/Reguler */
              //   font-family: Poppins;
              font-size: calc(
                12px + (14 - 12) * ((100vw - 280px) / (1600 - 280))
              );
              font-style: normal;
              font-weight: 400;
              line-height: 20px; /* 142.857% */
            }

            > span:last-child {
              color: var(--Neutral-90, var(--Color-Neutal-Neutral-90, #404040));

              /* Text L/SemiBold */
              //   font-family: Poppins;
              font-size: calc(
                14px + (16 - 14) * ((100vw - 280px) / (1600 - 280))
              );
              font-style: normal;
              font-weight: 600;
              line-height: 140%; /* 22.4px */
            }
          }
        }

        > div:last-child {
          > span {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 12px;
            border-radius: 8px;
            border: 1px solid var(--Primary-Main, #1571de);
            background: var(--Neutral-10, #fff);
            color: var(--Primary-Main, #1571de);
            text-align: center;

            /* Text M/Medium */
            // font-family: Poppins;
            font-size: calc(
              12px + (14 - 12) * ((100vw - 280px) / (1600 - 280))
            );
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 142.857% */
            cursor: pointer;
          }
        }
      }

      .bodyContent {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 16px;
        > div {
          display: flex;
          flex-direction: column;
          gap: 8px;
          > span {
            color: #000;

            /* Heading 7/Bold */
            // font-family: Poppins;
            font-size: calc(
              16px + (20 - 16) * ((100vw - 280px) / (1600 - 280))
            );
            font-style: normal;
            font-weight: 700;
            line-height: 150%; /* 30px */
            letter-spacing: 0.1px;
          }
        }
      }
    }

    // > div:last-child {
    //   display: none;
    // }
  }
}

.notification {
  position: fixed;
  top: -100px; /* start above the viewport */
  left: 50%;
  transform: translateX(-50%);
  border-radius: 8px;
  background: var(--Success-Main, #27bb83);
  color: var(--Neutral-10, var(--Color-Neutal-Neutral-10, #fff));

  /* Text M/Medium */
  // font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  padding: 8px 12px;
  // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: top 0.5s ease-in-out;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  &.show {
    top: 20px;
  }
}

@media screen and (max-width: 1200px) {
  .container {
    .content {
      flex-direction: column;
      align-items: center;
      > div:first-child {
        width: 100%;
        // display: none;
        order: 2;
      }
      > div:nth-child(2) {
        order: 1;
      }
    }
  }
}

@media screen and (max-width: 820px) {
  .container {
    .header {
      flex-direction: column;
      align-items: flex-start;
      //   width: 100%;
      > div:last-child {
        gap: 8px;
        // width: 50%;
        .dropdown {
          width: 100%;
        }
      }
    }

    .content {
      > div:nth-child(2) {
        .headerContent {
          flex-direction: column;
          align-items: flex-start;
          > div:last-child {
            width: 100%;
          }
        }

        .flbContent {
          flex-direction: column;
          align-items: flex-start;
          > div:last-child {
            width: 100%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 468px) {
  .container {
    .header {
      > div:last-child {
        flex-direction: column;
        align-items: flex-start;
      }
    }
    .content {
      > div:first-child {
        .imagesBoxNull {
          width: 100%;
        }

        .imagesBox {
          width: 100%;
          img {
            width: 100%;
          }

          .video {
            width: 100%;
          }
          //   height: 100;
        }
      }
    }
  }
}
