.container {
  padding: 16px;
  border-radius: 16px;
  border: 1px solid var(--Color-Neutal-Neutral-40, #e0e0e0);
  background: #f8fbff;
  display: flex;
  flex-direction: column;
  gap: 10px;
  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    > span {
      width: 50%;
      color: var(--Neutral-90, var(--Color-Neutal-Neutral-90, #404040));

      /* Text M/Medium */
      //   font-family: Poppins;
      font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
      > span {
        color: var(--Danger-Main, #ff3e13);
        // font-family: Poppins;
        font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
      }
    }
    > div {
      width: 70%;
      display: flex;
      align-items: center;
      gap: 8px;
      > div {
        border-radius: 8px;
        border: 1px solid var(--Neutral-30, #ededed);
        background: var(--Neutral-20, #f5f5f5);
        padding: 12px 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #000;
        text-align: center;

        /* Text S/Reguler */
        // font-family: Poppins;
        font-size: calc(10px + (13 - 10) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 153.846% */
        width: 25%;
        cursor: pointer;

        &.active {
          color: var(--Primary-Main, #1571de);
          border: 1px solid var(--Primary-Main, #1571de);
          background: var(--Primary-Surface, #f5f7fe);
        }

        &.redBorder {
          border: 1px solid red;
        }
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .container {
    > div {
      flex-direction: column;
      align-items: flex-start;
      > span {
        width: 100%;
      }
      > div {
        width: 100%;
        > div {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .container {
    > div {
      > div {
        flex-direction: column;
        > div {
          width: 100%;
        }
      }
    }
  }
}
