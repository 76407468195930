@import "../../../../theme/variables.scss";


.container {
  background-color: $NEUTRAL-10;
  padding: 24px;
  border-radius: 8px;
  width: 633px;
  max-width: 100vw;
  max-height: 85vh;
  overflow: auto;

  .contact {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;

    >span {
      max-width: 134px;
      text-overflow: ellipsis;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
    }
  }

  .header {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
    padding-bottom: 16px;

    >h3 {
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      letter-spacing: 0.1px;
    }

    >div {
      display: flex;
      align-items: center;
      gap: 16px;
      justify-content: space-between;

      >span {
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
      }

      >button {
        display: flex;
        align-items: center;
        gap: 4px;
        background-color: $NEUTRAL-10;
        border: 1px solid $PRIMARY_MAIN;
        border-radius: 8px;
        padding: 8px;
        color: $PRIMARY_MAIN;

        >span {
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 142.857%;
        }
      }
    }
  }

  .searchbarWrapper {
    padding-bottom: 8px;
  }

  .form {
    display: grid;
    grid-template-columns: 1fr;
    gap: 12px;

    .workUnitChooser {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .options {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
        gap: 8px;

        .option {
          display: flex;
          align-items: center;
          gap: 4px;
          padding: 12px;
          border: 1px solid $NEUTRAL-40;
          border-radius: 8px;
          position: relative;
          overflow: clip;

          &.error {
            border-color: #F5610D !important;
          }

          .cover {
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            z-index: 1;
            cursor: pointer;
          }

          >span {
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 142.857%;
          }
        }
      }
    }

    .other {
      display: grid;
      grid-template-columns: 1fr;
      gap: 4px;

      >p {
        font-family: Poppins;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 133.333%;

        >span {
          padding-left: 4px;
          color: $NEUTRAL-60;
        }
      }
    }
  }

  .errorWrapper {
    padding-top: 12px;
  }

  .actions {
    padding-top: 24px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;

    >button {
      padding-inline: 16px;
      border: 1px solid $PRIMARY_MAIN;
      border-radius: 8px;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 142.857%;
      height: 44px;
      display: grid;
      place-items: center;
      min-width: 96px;

      &:first-child {
        background-color: $NEUTRAL-10;
        color: $PRIMARY_MAIN;

        &:disabled {
          color: $PRIMARY_DISABLED;
        }
      }

      &:last-child {
        background-color: $PRIMARY_MAIN;
        color: $NEUTRAL-10;

        &:disabled {
          background-color: $PRIMARY_DISABLED;
        }
      }

      &:disabled {
        border-color: $PRIMARY_DISABLED;
      }

    }
  }


}