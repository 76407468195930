@import "../../../theme/variables.scss";

.container {
  display: flex;
  background-color: $NEUTRAL_20;
  height: 100%;
  width: 100%;
  align-items: center;
}

.pictureWrapper {
  background-color: $PRIMARY_SOFT;
  height: 32px;
  width: 32px;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.picture {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  object-fit: cover;
}

.contactPersonName {
  font-weight: 500;
  color: $NEUTRAL_100;
  font-size: 14px;
  padding-left: 10px;
}
