@import "../../../../../../../theme/variables.scss";

.container {
  width: 100%;

  >h4 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    color: $NEUTRAL_DARKEST;
    padding-bottom: 8px;
  }

  .topWrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;
    padding-bottom: 16px;

    .left {
      display: flex;
      align-items: center;
      gap: 8px;
      flex-grow: 1;
      overflow-x: scroll;

      &::-webkit-scrollbar {
        display: none;
      }


      .each {
        display: flex;
        align-items: center;
        gap: 16px;
        padding: 8px 12px;
        background-color: $NEUTRAL_10;
        border-radius: 4px 4px 0 0;
        border-bottom: 2px solid $NEUTRAL_40;
        transition: all 0.3s;
        cursor: pointer;

        &.active {
          border-color: $PRIMARY_MAIN;

          span {
            color: $PRIMARY_MAIN !important;

            &:last-child {
              background-color: $PRIMARY_SOFT !important;
            }
          }
        }

        .logoWrapper {
          height: 35px;
          aspect-ratio: 1;
          border-radius: 50%;
          background-color: #F3F5F7;
          display: grid;
          place-items: center;
        }

        img {
          height: 24px;
          aspect-ratio: 1;
          object-fit: contain;
        }

        .Initial {
          width: 35px;
          height: 35px;
          background-color: #4a7dca;
        }

        .detail {
          gap: 8px;

          span {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            color: $NEUTRAL_90;
            transition: all 0.3s;

            &:first-child {
              font-size: 14px;
              display: block;
              padding-bottom: 8px;
            }

            &:last-child {
              font-size: 12px;
              padding: 4px;
              background-color: $NEUTRAL_30;
              border-radius: 4px;
            }
          }
        }
      }
    }

    .right {
      padding: 8px 12px;
      background-color: $NEUTRAL_10;
      border-radius: 4px;
      display: flex;
      align-items: center;
      gap: 32px;
      overflow-x: scroll;

      &::-webkit-scrollbar {
        display: none;
      }

      .each {
        display: flex;
        flex-direction: column;
        gap: 8px;
        position: relative;

        .title {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: $NEUTRAL_90;
        }

        .content {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 20px;
          color: $NEUTRAL_90;
        }

        .divider1,
        .divider2 {
          position: absolute;
          height: 32px;
          width: 1px;
          background-color: $NEUTRAL_30;
        }

        .divider1 {
          left: -16px;
          top: 15%;
        }

        .divider2 {
          right: -16px;
          top: 15%;
        }
      }
    }
  }

  .bottomWrapper {
    display: flex;
    align-items: center;
    gap: 16px;

    .amountWrapper {
      flex-grow: 1;
      display: flex;
      align-items: center;
      gap: 8px;
      color: $PRIMARY_MAIN;

      span {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;

        &:nth-child(2) {
          padding-right: 8px;
          font-size: 14px;
          line-height: 20px;
        }

        &:nth-child(3) {
          font-size: 12px;
          line-height: 16px;
          padding: 4px;
          background-color: $PRIMARY_SOFT;
          border-radius: 4px;
        }
      }
    }

    .searchBarWrapper {
      width: 356px;
      height: 40px;
    }


  }
}

@media screen and (max-width: 768px) {
  .container {
  
    .topWrapper {
      margin-inline: -18px;

  
      .left {
        padding-inline: 18px;
  
  
      }
  
      .right {
        margin-inline: 18px;
      }
    }
  
    .bottomWrapper {
      display: flex;
      align-items: center;
      gap: 16px;

      flex-direction: column;

      >* {
        width: 100%!important;
      }
  
  
    }
  }
}
