@import "../../../../../theme/variables.scss";

.container {
  display: grid;
  grid-template-columns: 1fr;
  height: calc(100vh - 85px - 32px);

  overflow: clip;
  grid-template-rows: auto 1fr;

  .row {
    &:hover {
      > td {
        background-color: $PRIMARY_SURFACE_HOVER;
      }
    }
  }

  .status {
    padding: 4px 8px;
    border: 1px solid $NEUTRAL-100;
    border-radius: 4px;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 153.846%;
    text-align: center;

    &.update {
      // border-radius: 4px;
      color: var(--Alert-Main, #e9b62f);
      border: 1px solid var(--Alert-Main, #e9b62f);
      background: var(--Alert-Surface, rgba(233, 182, 47, 0.1));
    }

    &.approved {
      // border-radius: 4px;
      border: 1px solid var(--Primary-Main, #1571de);
      background: var(--Primary-Surface, rgba(21, 113, 222, 0.1));
      color: $PRIMARY_MAIN;
    }

    &.rejected {
      background-color: $DANGER_SURFACE;
      border-color: $DANGER_MAIN;
      color: $DANGER_MAIN;
    }
  }

  .headerBox {
    padding: 12px 24px;
    .header {
      display: flex;
      align-items: center;
      gap: 16px;

      .hLeft {
        display: flex;
        align-items: center;
        gap: 8px;
        flex-grow: 1;
        width: 100%;

        > button {
          padding: 10px 18px;
          border: 1px solid $NEUTRAL-30;
          border-radius: 8px;
          background-color: #f5f5f5;
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 142.857%;
          color: $NEUTRAL-60;
          transition: all 0.3s ease;

          &.active {
            background-color: $PRIMARY_SURFACE;
            border-color: $PRIMARY_MAIN;
            color: $PRIMARY_MAIN;
          }
        }
      }

      .rightBox {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 8px;
        width: 100%;
        .hRight {
          width: 250px;
        }

        .filterBox {
          display: flex;
          padding: 10px 12px;
          justify-content: center;
          align-items: center;
          gap: 4px;
          cursor: pointer;
          border-radius: 4px;
          width: 90px;
          > span {
            color: var(--Neutral-90, #404040);
            text-align: center;

            /* Text M/Medium */
            // font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 142.857% */
          }
        }
        .filterBox:hover {
          background: var(--Primary-Surface, #f5f7fe);
        }
        .downloadReportBox {
          gap: 4px;
          border-radius: 8px;
          background: var(--Primary-Main, #1571de);
          display: flex;
          padding: 10px 12px;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          width: 200px;
          > span {
            color: var(--Color-Neutal-Neutral-10, #fff);
            text-align: center;

            /* Text M/Medium */
            // font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 142.857% */
          }
        }
      }
    }

    .filterBox {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 12px;
      padding-top: 8px;
      padding-left: 4px;
      .filter {
        color: var(--Neutral-100, #0a0a0a);
        // text-align: center;

        /* Text M/Reguler */
        // font-family: Poppins;
        font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
      }

      .field {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
        padding: 5px 16px;
        border-radius: 100px;
        border: 1px solid var(--Color-Neutal-Neutral-40, #e0e0e0);
        background: var(--Color-Neutal-Neutral-10, #fff);
        > div:first-child {
          display: flex;
          align-items: center;
          gap: 4px;
          > span:first-child {
            color: var(--Neutral-70, #757575);
            text-align: center;

            /* Text M/Reguler */
            // font-family: Poppins;
            font-size: calc(
              12px + (14 - 12) * ((100vw - 280px) / (1600 - 280))
            );
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
          }
          > span:last-child {
            color: var(--Neutral-100, #0a0a0a);

            /* Text M/Reguler */
            // font-family: Poppins;
            font-size: calc(
              12px + (14 - 12) * ((100vw - 280px) / (1600 - 280))
            );
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
          }
        }

        .cross {
          cursor: pointer;
        }
      }
    }
  }

  .content {
    overflow: auto;
  }
}

.docNumber {
  display: flex;
  align-items: center;
  gap: 4px;
  > div {
    position: relative;
    top: 3px;
    cursor: pointer;
    width: 20px;
  }
}

.iconRow {
  width: 20px;
  cursor: pointer;
}

@media screen and (max-width: 1024px) {
  .container {
    .headerBox {
      .header {
        flex-direction: column;
        align-items: flex-start;
        .hLeft {
          flex-wrap: wrap;
        }
        .rightBox {
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;

          .hRight {
            width: 100%;
          }
          .filterBox {
            width: unset;
            order: 2; /* Make this item appear last */
            float: right;
          }
          .downloadReportBox {
            width: 100%;
            order: 1; /* Make this item appear just before .filterBox */
          }
        }
      }
    }
  }
}
