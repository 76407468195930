@import "../../../../../../theme/variables.scss";

.container {
  width: 100%;
  height: 100%;

  .header {
    padding-bottom: 16px;

    h3 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 150%;
      letter-spacing: 0.005em;
      color: $PRIMARY_MAIN;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .regionCard {
      width: 100%;
      height: 200%;
      background: $NEUTRAL_LIGHTEST;
      border-radius: 8px;
      padding: 24px;
      border: 1px solid $NEUTRAL_40;

      .cardHeader {
        padding-bottom: 16px;
        display: flex;
        align-items: center;

        h4 {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 150%;
          letter-spacing: 0.005em;
          color: $NEUTRAL_100;
          flex-grow: 1;
        }

        >button {
          padding: 12px;
          border-radius: 8px;
          border: 1px solid $PRIMARY_MAIN;
          background-color: $NEUTRAL-10;
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          color: $PRIMARY_MAIN;

          &:disabled {
            background-color: $NEUTRAL-20;
            color: $NEUTRAL-40;
            border-color: $NEUTRAL-40;
          }
        }
      }

      .cardContent {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
        gap: 16px;

        >div {
          background-color: $NEUTRAL_20;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    .header {
      padding-bottom: 8px;
    }

    .content {
      .regionCard {
        padding: 16px;

        .cardHeader {
          padding-bottom: 8px;
        }

        .cardContent {
          display: flex;
          flex-direction: column;
          gap: 8px;
        }
      }
    }
  }
}