@import "../../../../../../theme/variables.scss";

.tableWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll !important;
  z-index: 0;
  z-index: inherit;


  .showOnMobile {
    display: none;
  }

  .tableContainer {
    overflow: auto;
    background-color: inherit;
    border-bottom: 0;
    border: 1px solid $NEUTRAL_50;
    border-top: 0;
    border-inline: none;
    overflow-y: scroll !important;


    &::-webkit-scrollbar {
      display: none;
    }

    .table {
      border-collapse: separate;

      .tableHead {
        border-top-left-radius: 8px;


        .tableHeadCell {
          padding-inline: 20px !important;
          padding-block: 16px !important;
          background-color: $NEUTRAL_10 !important;

          white-space: nowrap;
          text-overflow: ellipsis;


          &:first-child {
            border-right: 1px solid $NEUTRAL_40;
            position: sticky;
            left: 0;
            padding-inline: 16px !important;
            border-top-left-radius: 8px;
            overflow: hidden;


            >div {
              width: 304px !important;
              display: flex;
              align-items: center;
              gap: 16px;
            }
          }

          &:last-child, &:nth-last-child(2) {
            border-top-right-radius: 8px;
          }
          // div {
          //   width: 200px !important;
          // }
        }

      }

      .tableBody {

        .tableRow {

          &:last-child{

            .tableCell{
              border-bottom: none !important;
            }
          }
        }

        .tableCell {
          padding: 16px !important;
          background-color: $NEUTRAL_10;

          white-space: nowrap;
          text-overflow: ellipsis;



          >div {
            display: flex;
            align-items: center;
            gap: 16px;
            text-align: left !important;

            >span {
              font-family: 'Poppins';
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              color: $NEUTRAL_100;

    

              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .chatIcon {
              color: $PRIMARY_MAIN;
            }
          }

          >span {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: $NEUTRAL_100;

       
            &.address {
              width: 180px;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              white-space: normal;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }


          &.stickyLeft {
            position: sticky;
            left: 0;
            border-right: 1px solid $NEUTRAL_40;

         

              .stackedProfile {
                background-color: transparent;
                border: none;
                display: flex;
                align-items: center;
                gap: 8px;
                cursor: pointer;
                text-align: left;

                span {
                  font-family: 'Poppins';
                  font-style: normal;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 20px;
                  // color: $PRIMARY_MAIN;
                  color: $NEUTRAL_100;
                }
              }
            }
          }
      }
    }
  }

  .modalWrapper {
    position: fixed;
    transition: all 0.35s ease-in-out !important;
    z-index: 4;
    background-color: transparent;
    left: 0;
    width: 100%;

    &.hideModal {
      bottom: -500px;
    }

    &.showModal {
      bottom: 0;
    }
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    background-color: $NEUTRAL_OVERLAY;
    opacity: 0;
    z-index: 3;

    &.visible {
      right: 0;
      bottom: 0;
      opacity: 1;
      transition: opacity 0.4s;
    }
  }

}

@media screen and (max-width: 768px) {
  .tableWrapper {

    .showOnMobile {
      display: table-cell;
    }

    .tableContainer {

      .table {
        width: 100%i !important;

        .tableHead {

          .tableHeadCell {
            padding: 12px 16px !important;

            &:first-child {
              width: 70%!important;
              position: relative;
              border-right: none;

              >div {
                width: 100% !important;
              }
            }

          }


          .showOnMobile {
              min-width: 100px !important;
              max-width: 150px !important;

          }
        }

        .tableBody {

          .tableCell {

            white-space: normal;
            text-overflow: ellipsis;

            &.stickyLeft {
              position: relative;
              border-right: none;

              >div {
                width: 100% !important;

              }
            }

            &.showOnMobile {

              button {
                width: 100%;
                padding: 6px 12px;
                background-color: $PRIMARY_MAIN;
                border: none;
                border-radius: 8px;

                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: $NEUTRAL_10;

                white-space: nowrap;
              }

            }
          }
        }


        .hideOnMobile {

          display: none !important;
        }
      }
    }
  }
}