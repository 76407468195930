@import "../../../../theme/variables.scss";

.background {
  width: 100vw;
  height: 100vh;
  background-color: $NEUTRAL_OVERLAY;
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .container {
    width: 536px;
    background-color: $NEUTRAL_10;
    border-radius: 8px;
    border: 1px solid $NEUTRAL_40;
    padding: 24px;
    max-width: 90vw;

    &.noPadding {
      padding: 0;
      border: none;
    }
    
    .errorText {
      color: var(--Helper, #F5610D);
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }

    .titleWrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 16px;

      .title {
        display: flex;
        align-items: center;
        gap: 16px;

        span {
          font-size: 16px;
          font-weight: 600;
          color: $NEUTRAL_100;
        }
      }

      .input {
        width: 60%;
      }

      .withoutInput {
        visibility: hidden;
      }
    }

    .uploadBoxWrapper {
      height: 155px;

      &.extraHeight {
        height: 271px;
      }
    }

    .filesWrapper {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding-top: 24px;

      .fileCard {
        padding: 16px;
        border: 1px solid $NEUTRAL_30;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: $NEUTRAL_10;
        gap: 18px;

        .left {
          display: flex;
          align-items: center;
          gap: 18px;
          flex-grow: 1;
          width: 100%;

          > div {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            gap: 4px;
          }

          .fileIcon {
            color: $NEUTRAL_90;
          }

          span {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            color: $PRIMARY_MAIN;
          }
        }

        button {
          display: grid;
          place-items: center;
          background-color: transparent;
          border: none;
          color: $NEUTRAL_90;
          cursor: pointer;
        }
      }
    }

    .commandText {
      padding-bottom: 4px;
      font-size: 12px;
      font-weight: 500;
      color: $NEUTRAL_70;
    }

    .buttonsWrapper {
      display: flex;
      justify-content: flex-end;
      padding-top: 16px;
      gap: 16px;

      button {
        padding: 8px 16px;
        border-radius: 8px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        border: none;

        &.Cancel {
          background-color: $NEUTRAL_10;
          border: 1px solid $PRIMARY_MAIN;
          color: $PRIMARY_MAIN;
        }

        &.Add {
          background-color: $PRIMARY_MAIN;
          color: $NEUTRAL_10;

          &:disabled {
            background-color: $PRIMARY_DISABLED;
            cursor: auto;
          }
        }
      }
    }
  }
}

.mobileContainer {
  width: 100%;
  background-color: $NEUTRAL_10;
  border-radius: 8px 8px 0 0;
  border: 1px solid $NEUTRAL_40;
  padding: 0px;
  margin: 0 !important;

  &.noPadding {
    padding: 0;
    border: none;
  }

  > * {
    padding: 16px;
  }

  .errorText {
    color: var(--Helper, #F5610D);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }

  .titleWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    border-bottom: 1px solid $NEUTRAL_40;

    .title {
      display: flex;
      align-items: center;
      gap: 16px;
      width: 100%;
      justify-content: center;

      span {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 140%;
        color: $NEUTRAL_100;
      }
    }

    .input {
      width: 60%;
    }

    .withoutInput {
      visibility: hidden;
    }
  }

  .uploadBoxWrapper {
    height: 155px;

    &.extraHeight {
      height: 271px;
    }
  }

  .filesWrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-top: 24px;

    .fileCard {
      padding: 16px;
      border: 1px solid $NEUTRAL_30;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: $NEUTRAL_10;
      gap: 18px;

      .left {
        display: flex;
        align-items: center;
        gap: 18px;
        flex-grow: 1;
        width: 100%;

        > div {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          gap: 4px;
        }

        .fileIcon {
          color: $NEUTRAL_90;
        }

        span {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          color: $PRIMARY_MAIN;
        }
      }

      button {
        display: grid;
        place-items: center;
        background-color: transparent;
        border: none;
        color: $NEUTRAL_90;
        cursor: pointer;
      }
    }
  }

  .commandText {
    padding-bottom: 4px;
    font-size: 12px;
    font-weight: 500;
    color: $NEUTRAL_70;
  }

  .buttonsWrapper {
    display: flex;
    justify-content: flex-end;
    // padding-top: 0px;
    gap: 16px;

    button {
      padding: 8px 16px;
      border-radius: 8px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      border: none;
      width: 100%;

      &.Cancel {
        background-color: $NEUTRAL_10;
        border: 1px solid $PRIMARY_MAIN;
        color: $PRIMARY_MAIN;
      }

      &.Add {
        background-color: $PRIMARY_MAIN;
        color: $NEUTRAL_10;

        &:disabled {
          background-color: $PRIMARY_DISABLED;
          cursor: auto;
        }
      }
    }
  }
}
