@import "../../../../../../../theme/variables.scss";

.container {
  padding-block: 24px;
  padding-inline: 30px;
  min-height: 100%;
  z-index: 0 !important;
  overflow-y: scroll;
}

@media screen and (max-width: 768px) {
  .container {
    padding-inline: 18px;
    padding-block: 16px;
  }
}