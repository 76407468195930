@import "../../../../../../../../../../theme/variables.scss";


.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .boxWrapper {
    padding: 16px;
    background-color: $NEUTRAL_20;
    border-radius: 8px;


    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
      flex-wrap: wrap;

      h4 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 20px;
        color: $PRIMARY_MAIN;
      }

      .inputWrapper{
        flex-grow: 1;

        .titleInput {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 20px;
          color: $PRIMARY_MAIN;
          background-color: transparent;
          border: none;
          padding-inline: 8px;
          height: 36px;
  
  
          &:focus {
            border-bottom: 1.5px solid $PRIMARY_MAIN;
            outline: none;
          }
        }
      }

      .rightController {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 18px;

        .actionsWrapper {
          display: flex;
          align-items: center;
          gap: 18px;

          button {
            display: grid;
            place-items: center;
            border: none;
            background: transparent;
            cursor: pointer;
            padding-inline: 3px;

            &.deleteButton {
              color: $DANGER_MAIN;
            }

            &.editButton {
              color: $PRIMARY_MAIN;
            }
          }
        }


      }
    }


    &.addSameBox {
      margin-bottom: 16px;

      p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        padding-bottom: 10px;

        span {
          font-weight: 700;
        }
      }

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        background-color: transparent;
        border: none;
        color: $PRIMARY_MAIN;
        cursor: pointer;
      }
    }


  }


}

@media screen and (max-width: 768px) {

  .container {
    gap: 8px;
    overflow-y: visible !important;

    .boxWrapper {
      padding: 8px;

      .topWrapper {

        .header {
          flex-direction: column;
          align-items: flex-start;
          gap: 16px;

          .rightController {
            width: 100%;
            justify-content: space-between;
          }
        }
      }

    }
  }

  .addSameBox {
    margin-bottom: 0 !important;
  }
}