@import "../../../theme/variables.scss";

.container {
  width: 100%;

  .paper {
    border-radius: 8px !important;
    box-shadow: 0px 0px 1px rgba(9, 30, 66, 0.31),
      0px 3px 5px rgba(9, 30, 66, 0.2);
    padding: 0;
  }

  li {
    // border-bottom: 1px solid #c2c2c2;
    padding: 12px;
    position: relative;

    &:last-child {
      .separator {
        display: none;
      }
    }

    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }

    .separator {
      position: absolute;
      height: 1px;
      width: calc(100% - 24px);
      left: 12px;
      bottom: 0;
      background-color: $NEUTRAL_50;
    }
  }
}
