@import "../../../theme/variables.scss";

.container {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px;
  width: 100%;

  .dNone {
    display: none;
  }


  .left {
    flex-grow: 1;
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;

    .lTop {
      display: flex;
      align-items: center;
      gap: 8px;

      >span {
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
      }

      .divider {
        height: 20px;
        width: 1px;
        background-color: $NEUTRAL_30;
      }

      .amount {
        background-color: $PRIMARY_SURFACE ;
        padding: 8px;
        border-radius: 8px;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
        color: $PRIMARY_MAIN;
      }
    }

    .lBottom {
      display: flex;
      align-items: center;
      gap: 8px;

      >button {
        padding: 10px 16px;
        background-color: $NEUTRAL-20;
        border-radius: 8px;
        border: 1px solid $NEUTRAL-30;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 142.857%;
        color: $NEUTRAL-60;
        transition: all .3s ease;

        &.active {
          background-color: $PRIMARY_SURFACE;
          color: $PRIMARY_MAIN;
          border-color: $PRIMARY_MAIN;
        }
      }
    }


  }

  .right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 16px;

    .info {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 8px;
      border-radius: 8px;
      background-color: $PRIMARY_SOFT;

      >p {
        font-family: Poppins;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 153.846%;
        color: $BS-NEUTRAL-90;

        >span {
          font-weight: 700;
        }
      }
    }

    .searchWrapper {
      width: 360px;
      max-width: 100%;
    }

  }
}

@media screen and (max-width:768px) {
  .container {
    flex-direction: column;

    >* {
      width: 100%;
    }
  }
}