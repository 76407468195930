.lineChartContent {
  height: 100%;
  // max-width: 900px;
  overflow-x: scroll;
  position: relative;

  .loading {
    position: absolute;
    top: calc(50% - 40px);
    left: calc(50% - 40px);
  }

  .line {
    width: 100%;
    height: 100%;
    overflow-x: scroll;
  }

}
