@import "../../../../../theme/variables.scss";

.Main {
  display: grid;
  grid-template-columns: 1fr;
  height: calc(100vh - 85px - 32px);
  overflow: clip;
  // grid-template-rows: 1fr auto;
  grid-template-rows: auto 1fr;


  .Hide {
    display: none;
  }

  .ControlSectionWrapper {
    display: flex;
    padding-inline: 16px;
    row-gap: 16px;
    column-gap: 8px;

    &> :last-child {
      justify-self: flex-end;
      align-self: flex-end;
    }

    .ButtonWrapper {
      display: flex;
      gap: 16px;
    }

    .SearchWrapper {
      flex-grow: 1;

      .searchbar {
        width: 360px;
        height: 100%;
      }
    }
  }

  .TableWrapper {
    padding-block: 16px;
    z-index: inherit;
    overflow: auto;
  }

  .actionsWrapper {
    display: flex;
    justify-content: flex-end;

    .PopupMenu {
      z-index: 2;
      position: absolute;
      margin-top: 30px;
    }

    >button {
      display: grid;
      place-items: center;
      background-color: transparent;
      border: none;

      img {
        width: 24px;
        aspect-ratio: 1;
        object-fit: cover;
      }
    }
  }

  .TableNameWrapper {
    display: flex;
    gap: 12px;
    align-items: center;

    >span {
      font-size: 14px;
      font-weight: 500;
    }

    .IconWrapper {
      border-radius: 50%;
      background: #1571de1a;
      align-items: center;
      width: 42px;
      height: 42px;
      display: flex;
      justify-content: center;
      align-items: center;

      .Icon {
        color: $PRIMARY_MAIN;
      }
    }
  }

}

@media screen and (max-width: 768px) {

  .ControlSectionWrapper {
    flex-direction: column;
  }

  .searchbar {
    width: 100% !important;
  }
}