@import "../../../../../theme/variables.scss";

.container {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 24px;

  .profilePictureWrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    .profilePicture {
      height: 75px;
      width: 75px;
      background-color: $NEUTRAL_60;
      border-radius: 37.5px;
      position: relative;

      .cameraWrapper {
        position: absolute;
        height: 28px;
        width: 28px;
        border-radius: 14px;
        background-color: $NEUTRAL_100;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 3px solid $NEUTRAL_10;
        right: -2px;
        top: 48px;

        .icon {
          color: $NEUTRAL_10;
        }
      }
    }
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 0 24px;

    .formTitle {
      font-size: 16px;
      font-weight: 600;
      color: $NEUTRAL_90;
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    padding: 16px 24px;

    .buttonsWrapper {
      display: flex;
      align-items: center;
      gap: 12px;
    }

    .cancelButton {
      padding: 8px 12px;
      border: 1px solid $NEUTRAL_100;
      border-radius: 8px;
      cursor: pointer;

      .cancelText {
        font-size: 14px;
        font-weight: 400;
        color: $NEUTRAL_100;
      }
    }

    .saveButton {
      padding: 8px 12px;
      background-color: $PRIMARY_MAIN;
      border-radius: 8px;
      cursor: pointer;

      .saveText {
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
      }
    }
  }
}
