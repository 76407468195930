@import "../../../../theme/variables.scss";

.container {
  width: 726px;
  background-color: $NEUTRAL_10;
  border-radius: 8px;
  max-height: 85vh;
  max-width: 100vw;
  overflow: auto;

  .grow {
    flex-grow: 1;
  }

  > * {
    padding: 24px;
  }

  .loading {
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: $NEUTRAL_60;
    text-align: center;
    padding: 24px;
  }

  .errorWrapper {
    text-align: right;
    color: $DANGER_MAIN;

    &.visible {
      opacity: 1;
    }

    &.hide {
      opacity: 0;
    }
  }

  .header {
    display: flex;
    align-items: center;
    gap: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid $NEUTRAL_40;
    position: sticky;
    top: 0;
    background-color: $NEUTRAL_10;
    z-index: 1;

    > h3 {
      flex-grow: 1;
      font-family: Poppins;
      font-size: 26px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
      letter-spacing: 0.13px;
    }

    > button {
      background-color: transparent;
      border: none;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    z-index: 0;

    .intro {
      // display: flex;
      // align-items: flex-start;
      // flex-direction: column;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 16px;
      // padding-bottom: 24px;

      .stackedIntro {
        width: 100%;
        flex: 1;
        display: flex;
        align-items: center;
        gap: 8px;

        div {
          &:first-child {
            display: grid;
            place-items: center;
            padding: 12px;
            background-color: $NEUTRAL_20;
            border-radius: 5px;

            > img {
              height: 16px;
              aspect-ratio: 1;
              object-fit: contain;
            }
          }

          &:last-child {
            display: flex;
            flex: 1;
            flex-direction: column;
            padding: 8px 16px;
            background-color: $PRIMARY_SURFACE;
            border-radius: 4px;

            span {
              font-family: "Poppins";
              font-style: normal;

              &:first-child {
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                color: $NEUTRAL_60;
              }

              &:last-child {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: $NEUTRAL_100;
              }
            }
          }
        }
      }
    }

    .modeSetter {
      display: flex;
      flex-direction: column;
      gap: 8px;

      > span {
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }

      .modes {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 8px;

        .each {
          display: flex;
          align-items: center;
          gap: 12px;
          padding: 8px 12px;
          background-color: $NEUTRAL_20;
          border-radius: 8px;
          border: 1px solid $NEUTRAL_40;

          .icon {
            display: grid;
            place-items: center;
            width: 32px;
            aspect-ratio: 1;
            border-radius: 50%;
            background-color: $NEUTRAL_10;
          }

          .title {
            display: flex;
            flex-direction: column;

            > span {
              &:first-child {
                font-family: Poppins;
                font-size: 11px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                color: $NEUTRAL_60;
              }

              &:last-child {
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
                text-transform: capitalize;
              }
            }
          }
        }
      }
    }

    .list {
      display: flex;
      flex-direction: column;
      gap: 8px;

      > span {
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
        padding-bottom: 8px;
      }

      .isClientSetter {
        display: flex;
        align-items: center;
        gap: 16px;
        padding: 8px;
        border-radius: 8px;
        border: 1px solid $NEUTRAL_30;

        > span {
          flex-grow: 1;
          font-family: Poppins;
          font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 500;
          width: 50%;
          // width: 10px;
          line-height: 20px;
        }
      }

      .searchbarWrapper {
        position: relative;

        > button {
          position: absolute;
          right: 50px;
          top: 10px;
          display: flex;
          align-items: center;
          gap: 4px;
          border: none;
          background-color: transparent;
          font-family: Poppins;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
          color: $PRIMARY_MAIN;
        }
      }

      .outerWrapper {
        display: flex;
        flex-direction: column;
        border-radius: 8px;
        border: 1px solid $NEUTRAL_40;
        overflow: auto;

        .tableWrapper {
          overflow: auto;

          &.grow {
            flex-grow: 1;
            height: auto;
          }

          table {
            border-spacing: 0;
            width: 100%;

            thead {
              position: sticky;
              top: 0;
              tr {
                th {
                  border-bottom: 1px solid $NEUTRAL_40;
                  text-align: left;
                  padding: 16px 20px;
                  font-family: Poppins;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: normal;
                  background-color: $NEUTRAL-10;
                }
              }
            }

            tbody {
              tr {
                // &:not(:last-child) {
                td {
                  border-bottom: 1px solid $NEUTRAL_30;
                }
                // }

                td {
                  padding: 12px 20px;
                  font-family: Poppins;
                  font-size: 13px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 20px;

                  > div {
                    display: flex;
                    align-items: center;
                    gap: 12px;
                  }
                }
              }
            }
          }
        }

        .emptyWrapper {
          flex-grow: 1;
        }

        .paginationWrapper {
          // border-radius: 0 0 8px 8px;
          border-top: 1px solid $NEUTRAL_40;
          position: sticky;
          bottom: 0;
        }
      }

      .listWrapper {
        margin: 16px;
        display: flex;
        flex-direction: column;
        gap: 8px;

        .each {
          display: flex;
          align-items: center;
          gap: 8px;
          color: $NEUTRAL_60;
          padding: 8px;
          background-color: $PRIMARY_SURFACE;
          border-radius: 8px;

          > span {
            color: $NEUTRAL_100;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
          }
        }
      }
    }
    .buttonSection {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      > button {
        // align-self: flex-end;
        padding: 12px;
        background-color: $PRIMARY_MAIN;
        border-radius: 8px;
        border: none;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        color: $NEUTRAL_10;

        &:disabled {
          background-color: $PRIMARY_DISABLED;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    > * {
      padding-bottom: 0px;
    }
    width: 100%;
    .content {
      .buttonSection {
        position: sticky;
        bottom: 0;
        background: #fff;
        padding-top: 12px;
        padding-bottom: 12px;
        > button {
          width: 100%;

          &:disabled {
            background-color: $PRIMARY_DISABLED;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 450px) {
  .container {
    .content {
      .modeSetter {
        .modes {
          display: flex;
          flex-direction: column;
        }
      }
      .intro {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
