@import "../../../../../../../../theme/variables.scss";

.container {
  z-index: inherit !important;
  position: relative;
  padding: 40px 48px;
  .autoScroll {
    display: hidden;
    position: absolute;
    top: 0;
  }

  h3 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
    color: $PRIMARY_MAIN;
  }

  .summary {
    margin-top: 16px;
  }

  .content {
    padding-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    border: 1px solid $NEUTRAL_40;
    border-radius: 8px;

    .noData {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      color: #333333;
    }

    .each {
      background-color: $NEUTRAL_10;
      padding: 24px;
      border-radius: 8px;
      z-index: 0 !important;

      h4 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 140%;
        color: $NEUTRAL_100;
        padding-bottom: 9px;
      }

      .tableWrapper {
        width: 100%;
        height: 100%;
        z-index: 0 !important;

        .tableContainer {
          border-bottom: 0;
          z-index: 0 !important;
          overflow-x: scroll !important;
          position: relative;

          .table {
            border-collapse: separate;
            background-color: $NEUTRAL_10 !important;

            .showOnMobile {
              display: none;
            }

            .tableHeadCell {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 20px;
              color: $NEUTRAL_DARKEST !important;
              border-bottom: 1.5px solid $PLATINUM !important;
              padding-block: 15px !important;

              .InfoIcon {
                color: $PRIMARY_MAIN;
              }
            }

            .stickyLeft {
              position: sticky;
              left: 0;
              background-color: $NEUTRAL_10 !important;
              z-index: 1;
            }

            .stickyRight {
              position: sticky;
              right: 0;
              background-color: $NEUTRAL_10 !important;
            }

            .tableRow {
              border: 0 !important;

              .tableCell {
                background-color: $NEUTRAL_10;
                font-family: "Poppins";
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 20px;
                color: $NEUTRAL_DARKEST;
                border-bottom: 1.5px solid $PLATINUM !important;
                padding-block: 15px;

                &.showOnMobile {
                  display: none;

                  button {
                    width: 77px;
                    height: 28px;
                    background: #1571de;
                    border-radius: 8px;
                    border: none;
                    color: $NEUTRAL_LIGHTEST;
                    font-family: "Poppins";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 20px;
                  }
                }
              }

              &.lastRow {
                .tableCell {
                  border-bottom: none !important;
                }
              }
            }
          }
        }
      }

      .summary {
        margin-top: 5px;
        width: 290px;
        float: right !important;
        border-top: 1px solid $NEUTRAL_DARKEST;
        padding-top: 17.5px;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .boxSum {
          display: flex;
          align-items: center;
          justify-content: space-between;

          &.last {
            border-top: 1px solid $NEUTRAL_DARKEST;
            padding-top: 8px;
            margin-top: 7.5px;
          }

          span {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            color: $NEUTRAL_DARKEST;
          }

          &.last {
            span {
              font-weight: 600;
            }
          }
        }
      }
    }
  }

  .modalWrapper {
    position: fixed;
    transition: all 0.35s ease-in-out !important;
    z-index: 4;
    background-color: transparent;
    left: 0;
    width: 100%;

    &.onHide {
      bottom: -500px;
    }

    &.onShow {
      bottom: 0;
    }
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    background-color: $NEUTRAL_OVERLAY;
    opacity: 0;
    z-index: 3;

    &.visible {
      right: 0;
      bottom: 0;
      opacity: 1;
      transition: opacity 0.4s;
    }
  }
  .summarySection {
    margin-top: 16px;
  }
}

@media screen and (max-width: 768px) {
  .footer {
    left: 0 !important;
  }

  .HideOnMobile {
    display: none !important;
  }

  .showOnMobile {
    display: table-cell !important;
  }

  .container {
    padding: 16px 18px !important;

    .content {
      .tableCell {
        padding-block: 11px !important;
      }

      .each {
        padding: 16px;

        h4 {
          padding-bottom: 0 !important;
        }
      }
    }

    .buttonWrapper {
      padding-bottom: 180px;

      > * {
        width: 100%;
      }
    }

    .summary {
      display: none !important;
    }

    .footer {
      margin-left: 0;
      width: 100%;
      padding-top: 8px;
      padding-bottom: 30px;
      padding-inline: 18px;

      .top {
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        border-bottom: none;
        padding-bottom: 0;

        > * {
          justify-content: space-between;
          width: 100%;

          .InputWrapper {
            input {
              width: 80px;
            }
          }

          .InputSelectWrapper {
            display: none;
          }

          .InputSelectModal {
            display: flex;

            input {
              width: 100px !important;
            }
          }

          .DescWrapper {
            span {
              &:nth-child(2) {
                padding-right: 16px !important;
              }
            }
          }
        }
      }

      .bottom {
        span {
          font-size: 14px;
        }

        > div {
          font-size: 16px !important;
        }
      }
    }
  }
}
