@import "../../../../../../theme/variables.scss";

.container {
  width: 100%;
  height: 100%;
  max-width: 1620px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.wrapper {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));

  gap: 16px;
  @include padding-inline(32, 16, 16px);
  padding-block: 16px;
}

.emptyData {
  display: flex;
  // height: 100%;
  // flex-grow: 1;
  align-items: center;
  justify-content: center;
  // padding: 16px;
  // padding: 100px 16px;
  place-items: center;
}

.boxText {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  // place-items: center;
  .click {
    color: #a4a4a4;
    text-align: center;
    // font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .warn {
    color: #333;
    text-align: center;
    // font-family: Poppins;
    font-size: calc(20px + (24 - 20) * ((100vw - 280px) / (1600 - 280)));
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  // justify-content: center;
}

@media screen and (max-width: 1554px) {
  .wrapper {
    // display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  }
}

@media screen and (max-width: 570px) {
  .wrapper {
    // display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}
