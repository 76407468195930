@import "../../../../../../theme/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;

    >button {
      background-color: transparent;
      padding: 0;
      display: grid;
      place-items: center;
      border: none;
    }
  }

  .header {
    width: 100%;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 150%;
      letter-spacing: 0.005em;
      color: $PRIMARY_MAIN;
    }

    >div {
      width: 191px;
      height: 100%;

      span {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 6px;
      }
    }
  }

  .content {
    // display: grid;
    // grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
    // gap: 16px;
    // padding-top: 16px;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    border: 1px solid $NEUTRAL-40;

    >*:not(table) {
      flex-grow: 1;
    }

    table {
      width: 100%;
      border-collapse: collapse;
      border-spacing: 0;

      thead {

        tr {

          th {
            padding: 16px 20px;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            border-bottom: 1px solid $NEUTRAL-40;
          }
        }
      }

      tbody {

        tr {

          &.odd {

            td {
              background-color: $PRIMARY_SURFACE !important;
            }
          }

          td {
            padding: 24px 20px;
            font-family: Poppins;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            color: $NEUTRAL-90;
          }
        }
      }
    }
  }

  .bottomButton {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .container {
    .header {
      >div {
        display: none;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      padding-bottom: 10px;
      padding-top: 8px;
    }

    .bottomButton {
      display: block;
      width: 100%;
      height: 40px;

      span {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 6px;
      }
    }
  }
}