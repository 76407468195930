@import "../../../../theme/variables.scss";
.container {
  .header {
    display: flex;
    flex-direction: column;

    // border-bottom: 1px solid var(--Color-Neutal-Neutral-40, #e0e0e0);
    background: var(--Color-Neutal-Neutral-20, #f5f5f5);
    position: sticky;
    top: 70px;
    z-index: 2;
    gap: 16px;

    .contenHeader {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 16px 30px;
      border-bottom: 1px solid var(--Color-Neutal-Neutral-40, #e0e0e0);

      .routesBackBox {
        display: flex;
        align-items: center;
        gap: 16px;
        .iconBack {
          border-radius: 80px;
          background: var(--Color-Neutal-Neutral-10, #fff);
          padding: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .routesName {
          display: flex;
          align-items: center;
          gap: 4px;
          .Pipeline {
            color: var(--Neutral-60, var(--Color-Neutal-Neutral-60, #9e9e9e));
            //   font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
          .detail {
            color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));
            //   font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }
      }
      .nameCandidate {
        color: #000;

        /* Heading 4/Bold */
        //   font-family: Poppins;
        font-size: calc(24px + (32 - 24) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 700;
        line-height: 140%; /* 44.8px */
        letter-spacing: 0.16px;
      }
    }

    .steperBox {
      display: flex;
      flex-direction: column;

      .navs {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px 30px;
        > button {
          background-color: transparent;
          border: none;
          font-family: Poppins;
          font-size: 13px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          // color: $BS_PRIMARY_MAIN;
          cursor: pointer;
        }
      }
    }
  }

  .contentDetail {
    display: flex;
    flex-direction: column;
    padding: 2px 30px 16px 30px;
    gap: 24px;
  }

  .containerBox {
    border-radius: 12px;
    background: var(--Color-Neutal-Neutral-10, #fff);
    // padding-top: 16px;
    .headerBox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
      padding: 24px;
      border-bottom: 1px solid #ece6e6;

      z-index: 1;
      .buttonBox {
        display: flex;
        align-items: center;
        // flex-wrap: wrap;
        // justify-content: flex-end;
        gap: 8px;
        // width: 50%;
        .buttonType {
          // width: 100%;
          cursor: pointer;
          border-radius: 100px;
          border: 1px solid var(--Neutral-40, #e0e0e0);
          background: var(--Neutral-10, #fff);
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 12px 16px;
          color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));
          text-align: center;

          /* Text M/Reguler */
          // font-family: Poppins;
          font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
          &.active {
            border-radius: 100px;
            border: 1px solid var(--Primary-Main, #1571de);
            background: var(--Primary-Surface, #f5f7fe);
            color: var(--Primary-Main, #1571de);
          }
        }
      }
      .containerHeader {
        display: flex;
        //   width: 963px;
        align-items: center;
        gap: 8px;

        .candidateHeader {
          display: flex;
          flex-direction: column;
          gap: 4px;
          .name {
            color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));

            /* Heading 7/Bold */
            //   font-family: Poppins;
            font-size: calc(
              16px + (20 - 16) * ((100vw - 280px) / (1600 - 280))
            );
            font-style: normal;
            font-weight: 700;
            line-height: 150%; /* 30px */
            letter-spacing: 0.1px;
          }
          .position {
            color: var(--Neutral-80, #616161);

            /* Text L/Reguler */
            //   font-family: Poppins;
            font-size: calc(
              14px + (16 - 14) * ((100vw - 280px) / (1600 - 280))
            );
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 22.4px */
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .container {
    .containerBox {
      .headerBox {
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .container {
    .containerBox {
      .headerBox {
        .buttonBox {
          flex-direction: column;
          width: 100%;
          .buttonType {
            width: 100%;
          }
        }
      }
    }
  }
}
