@import "../../../../../../theme/variables.scss";

.container {
  position: relative;

  .autoScroll {
    display: hidden;
    position: absolute;
    top: 0;
  }

  .redStar {
    color: $DANGER_MAIN;
  }

  > h3 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    padding-bottom: 30px;
    padding-top: 24px;
    position: sticky;
    top: 0;
    background-color: $NEUTRAL_10;
    z-index: 3;
  }

  .content {
    display: flex;
    gap: 20px;

    .left {
      width: 30%;
      aspect-ratio: 1;
      background: #f4f4f4;
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 10%;
      align-self: flex-start;
      min-width: 300px !important;

      > img {
        width: 95%;
      }

      .buttonWrapper {
        width: 100%;
        display: grid;
        place-items: center;

        > button {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $PRIMARY_MAIN;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 24px;
          color: $NEUTRAL_10;
          border: none;
          gap: 8%;
          padding: 14px;
          border-radius: 50px;
          width: 65%;

          > *:first-child {
            flex-shrink: 0;
          }
        }
      }

      .desc {
        display: flex;
        flex-direction: column;
        gap: 8px;
        text-align: center;

        > span {
          margin: auto;

          &:first-child {
            font-family: Poppins;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            max-width: 75%;
          }

          &:last-child {
            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            max-width: 90%;
          }
        }
      }
    }

    .right {
      flex-grow: 1;
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    padding-top: 10px;

    .zero {
      height: 0px !important;
      padding: 0 !important;
    }

    .none {
      display: none !important;
    }

    > h3 {
      display: none;
    }

    .content {
      flex-direction: column;
      align-items: center;
      gap: 0;
      .left {
        display: none;
      }
      .right {
        width: 100%;
        // gap: 0;
      }
    }

    // .content {
    //   flex-direction: column;
    //   align-items: center;
    //   gap: 0;

    //   .left {
    //     width: 100%;
    //     gap: 5vh;
    //     background-color: transparent;
    //     aspect-ratio: 2/1;
    //     padding-top: 25%;
    //     padding-bottom: 8%;
    //     position: static !important;
    //     display: none;

    //   }

    //   .right {
    //     width: 100%;

    //   }
    // }
  }
}
