@import "../../../../../../../theme/variables.scss";

.container {
  padding: 24px 30px;
  // width: 100%;
  &.containerHC {
    padding: 0px;
  }

  .headerWrapper {
    border-radius: 8px;
    display: grid;
    grid-template-columns: 1fr;
    overflow: clip;

    .title {
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
      letter-spacing: 0.1px;
      text-align: center;
      background: #f3f9ff;
      border-bottom: 1px solid $NEUTRAL-30;
      padding: 10px 16px;
    }

    &.v2 {
      border: 1px solid $NEUTRAL-30;
    }

    .wrapperBox {
      background-color: $NEUTRAL_10;
      padding: 16px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      .headerWrap {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 16px;
        .left {
          display: flex;
          align-items: center;
          gap: 10px;
          .imgBox {
            background-color: #f3f5f7;
            width: 35px;
            height: 40px;
            border-radius: 50px;
            padding: 10px;
            img {
              width: 100%;
              height: 100%;
              // object-fit: cover;
            }
          }

          > span {
            color: #000;

            /* Text L/Bold */
            // font-family: Poppins;
            font-size: calc(
              14px + (16 - 14) * ((100vw - 280px) / (1600 - 280))
            );
            font-style: normal;
            font-weight: 700;
            line-height: 140%; /* 22.4px */
          }
        }

        .right {
          display: flex;
          flex-direction: column;
          gap: 2px;
          > span:first-child {
            color: #000;

            /* Text L/Bold */
            // font-family: Poppins;
            font-size: calc(
              14px + (16 - 14) * ((100vw - 280px) / (1600 - 280))
            );
            font-style: normal;
            font-weight: 700;
            line-height: 140%; /* 22.4px */
          }
          > span:last-child {
            color: #000;
            text-align: right;

            /* Text M/Reguler */
            // font-family: Poppins;
            font-size: calc(
              12px + (14 - 12) * ((100vw - 280px) / (1600 - 280))
            );
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
          }
        }
      }
    }

    .innerWrapper {
      display: flex;
      gap: 16px;

      align-items: flex-start;

      .header {
        display: flex;
        flex-direction: column;
        gap: 16px;
        flex-grow: 1;

        .stackedInfo {
          display: flex;
          flex-direction: column;
          gap: 10px;

          > span {
            font-family: "Poppins";
            font-style: normal;
            color: $NEUTRAL_DARKEST;

            &:first-child {
              font-weight: 500;
              font-size: 16px;
              line-height: 140%;
            }

            &:last-child {
              font-weight: 500;
              font-size: 14px;
              line-height: 20px;
            }
          }

          .Bold {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 140%;
          }

          .address {
            display: flex;
            flex-direction: column;
            gap: 4px;
            > div {
              display: flex;
              align-items: center;
              gap: 8px;
              > span {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 140%;
              }
              > div {
                display: flex;
                align-items: center;
                gap: 8px;
                > div {
                  width: 5px;
                  height: 5px;
                  border-radius: 50px;
                  background-color: #000000;
                }
                > span {
                  color: var(--Primary-Main, #1571de);
                  // font-family: Helvetica;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 140%; /* 22.4px */
                  cursor: pointer;
                }
              }
            }

            span {
              &:first-child {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 140%;
              }

              &:last-child {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
              }
            }
          }
        }
      }

      > img {
        @include width(238, 60, 60px);
        object-fit: contain;
      }
    }
  }

  .content {
    padding-top: 16px;

    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}

@media screen and (max-width: 768px) {
  .container {
    padding: 16px 18px;
    &.containerHC {
      padding: 0px;
    }

    .headerWrapper {
      .innerWrapper {
        .header {
          .stackedInfo {
            .address {
              gap: 8px;
              > div {
                flex-direction: column;
                align-items: flex-start;
                width: 100%;

                > div {
                  > div {
                    display: none;
                  }
                  > span {
                    color: var(--Primary-Main, #1571de);
                    // font-family: Helvetica;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 140%; /* 22.4px */
                    cursor: pointer;
                  }
                }
              }
            }
          }
        }
      }
    }

    .header {
      .stackedInfo {
        > span {
          &:first-child {
            font-weight: 500;
            font-size: 16px;
            line-height: 140%;
          }

          &:last-child {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;

            &.Bold {
              font-weight: 700;
            }
          }
        }
      }
    }

    .content {
      padding-top: 24px;
    }
  }
}
@media screen and (max-width: 600px) {
  .container {
    .headerWrapper {
      .wrapperBox {
        .headerWrap {
          flex-direction: column;
          gap: 8px;
          .right {
            > span:last-child {
              text-align: left;
            }
          }
        }
      }
    }
  }
}
