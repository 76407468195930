@import "../../../../../../theme/variables.scss";
.bodyFilter {
  height: 100vh;
  width: 100vw;
  background-color: #00000080;
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  > div {
    height: 100vh;
    border-radius: 12px 0px 0px 12px;
    background: var(--Color-Neutal-Neutral-10, #fff);
    width: 400px;
    position: relative;
    animation: slideLeft 0.5s ease-out forwards; /* animasi slide ke kiri */
    transform: translateX(100%); /* awalnya sembunyikan ke kanan */
    > div:first-child {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 16px;
      .header {
        border-bottom: 1px solid #e0e0e0;
        padding-bottom: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
        > span {
          color: var(--Neutral-90, var(--Color-Neutal-Neutral-90, #404040));

          /* Heading 6/SemiBold */
          // font-family: Poppins;
          font-size: calc(16px + (24 - 16) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 600;
          line-height: 140%; /* 33.6px */
          letter-spacing: 0.12px;
        }
        > div {
          color: var(--Danger-Main, #ff3e13);
          text-align: center;
          /* Text M/Reguler */
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
          // text-decoration: underline;
          cursor: pointer;
        }
      }

      .inputBox {
        display: flex;
        flex-direction: column;
        gap: 8px;
        > span {
          color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));

          /* Text M/Medium */
          // font-family: Poppins;
          font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 142.857% */
        }

        .searchBox {
          width: 100%;
          height: 45px;
          position: relative;
          .inputSearch {
            border-radius: 8px;
            border: 1px solid #e6eaec;
            background: #fff;
            width: 100%;
            padding: 12px 12px 12px 40px;
          }
          .inputSearch:focus {
            outline: none !important;
            border-color: $PRIMARY_MAIN;
            border-width: 1px 1px 1px 1px;
            border-style: solid;
          }
          .iconSearch {
            position: absolute;
            padding: 12px;
          }
        }
        &.border {
          border-top: 1px solid #e0e0e0;
          padding-top: 16px;
        }
      }
    }

    .buttonSection {
      position: fixed;
      bottom: 0;
      width: 100%;
      background: var(--Color-Neutal-Neutral-10, #fff);
      box-shadow: 0px -4px 24px 0px rgba(132, 132, 132, 0.15);
      padding: 12px 16px;
      border-radius: 0px 0px 0px 12px;
      > span {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        background: var(--Primary-Main, #1571de);
        width: 100%;
        padding: 12px;
        color: var(--Color-Neutal-Neutral-10, #fff);
        text-align: center;

        /* Text M/Reguler */
        // font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        cursor: pointer;
      }
    }
  }

  @keyframes slideLeft {
    to {
      transform: translateX(0); /* geser ke kiri sampai muncul */
    }
  }
}

@media screen and (max-width: 450px) {
  .bodyFilter {
    > div {
      width: 100%;
      border-radius: 12px 12px 0px 0px;
      max-height: 80%;
      position: fixed;
      bottom: 0;
      animation: slideUp 0.5s ease-out forwards; /* Animation sliding from bottom to top */
      transform: translateY(100%); /* Initially hide below */
    }

    @keyframes slideUp {
      to {
        transform: translateY(0); /* Slide up to reveal */
      }
    }
  }
}
