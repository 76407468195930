.container {

  .content {
    min-height: 100vh;

    .loadingWrapper {
      height: 100vh;
      display: grid;
      place-items: center;
    }

    .errorWrapper {
      height: 100vh;
    }
  }


}