@import "../../../../../../../../theme/variables.scss";

.container {
  width: 100%;
  min-height: 100%;
  border-radius: 8px;
  background-color: $NEUTRAL_10;
  padding: 24px;

  h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.005em;
    color: $NEUTRAL_100;
  }

  .content {
    padding-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 24px;

   
  }
}

@media screen and (max-width: 768px) {
  .container {
    padding: 16px;
    // background-color: transparent;

    h3 {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 140%;
    }

    .content {
      padding-top: 8px;
      flex-direction: column;
    }
  }
}