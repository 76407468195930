@import "../../../../../../../../theme/variables.scss";

.container {
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid $PLATINUM_SOLID;

  .Header {
    padding: 10px;
    text-align: center;
    background-color: $ALICE_BLUE;
    border-bottom: 1px solid $PLATINUM_SOLID;

    h3 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 150%;
      text-align: center;
      letter-spacing: 0.005em;
      color: $NEUTRAL_100;
    }
  }

  .content {
    background-color: $NEUTRAL_10;

    .row {
      display: flex;
      border-bottom: 1px solid $PLATINUM_SOLID;
      padding-inline: 16px;
      // gap: 24px;
      background-color: transparent;
      transition: background-color 1s ease-in-out;

      &.isActive {
        background-color: #e9b62f1a;
        // transition: background-color 1s ease-in-out;
      }

      &.borderYellow {
        border: 1px solid var(--Alert-Main, #e9b62f);
        // border-bottom: 0.5px solid var(--Alert-Main, #e9b62f);
        // border-top: 0.5px solid var(--Alert-Main, #e9b62f);
        // border-bottom: 0px;
      }

      > * {
        width: 50% !important;
        flex-grow: 0 !important;
        flex-shrink: 0 !important;
      }

      &:last-child {
        // border-bottom: none;
        border-radius: 0px 0px 8px 8px;
      }

      .col {
        .Top {
          width: 100%;
          display: flex;
          align-items: center;
          gap: 24px;

          > * {
            width: 100%;
          }
        }

        &.goingDown {
          flex-direction: column;

          > * {
            width: 100% !important;
            white-space: pre-wrap;
          }
        }

        &:first-child {
          width: 48%;
          display: flex;
          align-items: center;
          flex-shrink: 0;

          h4 {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 140%;
            color: $NEUTRAL_100;
          }
        }

        &:last-child {
          display: flex;
          gap: 24px;
          width: 100%;

          > * {
            flex-grow: 1;
          }

          > span {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: $NEUTRAL_100;
          }

          &.miniPadding {
            padding-block: 8px;
          }

          &.normalPadding {
            padding-block: 18px;

            > span {
              white-space: pre-wrap;
            }
          }

          .stacked {
            display: flex;
            flex-direction: column;
            gap: 4px;
            width: 100%;

            span {
              font-family: "Poppins";
              font-style: normal;

              &:first-child {
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                color: $NEUTRAL_60;
              }

              &:last-child {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: $NEUTRAL_100;
              }
            }
          }

          .colSection {
            display: flex;
            flex-direction: column;
            gap: 8px;

            h5 {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 140%;
              color: $NEUTRAL_100;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    .goingDown {
      .Top {
        flex-direction: column;
      }
    }

    .content {
      .row {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid $PLATINUM_SOLID;
        padding: 8px 16px;
        gap: 8px;

        > * {
          width: 100% !important;
        }

        &:last-child {
          border-bottom: none;
        }

        .col {
          &:first-child {
            width: 100%;
            display: flex;
            align-items: center;
            flex-shrink: 0;

            h4 {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 140%;
              color: $NEUTRAL_100;
            }
          }

          &:last-child {
            display: flex;
            gap: 8px;
            width: 100%;
            flex-direction: column;

            > * {
              flex-grow: 1;
            }

            > span {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 20px;
              color: $NEUTRAL_100;
            }

            &.miniPadding {
              padding-block: 0px;
            }

            &.normalPadding {
              padding-block: 0px;
            }

            .stacked {
              display: flex;
              flex-direction: column;
              gap: 4px;
              width: 100%;

              span {
                font-family: "Poppins";
                font-style: normal;

                &:first-child {
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 16px;
                  color: $NEUTRAL_60;
                }

                &:last-child {
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 20px;
                  color: $NEUTRAL_100;
                }
              }
            }

            .colSection {
              display: flex;
              flex-direction: column;
              gap: 8px;

              h5 {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 140%;
                color: $NEUTRAL_100;
              }
            }
          }
        }
      }
    }
  }
}
