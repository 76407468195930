@import "../../../../../../../theme/variables.scss";

.container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 16px;
  padding: 16px;
  border-radius: 8px;
  background-color: $NEUTRAL_10;
}

@media screen and (max-width: 768px) {
  .container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
    padding: 16px;
    border-radius: 8px;
    background-color: $NEUTRAL_10;
  }
}
