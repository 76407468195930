@import "./theme/variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-decoration: none;
}

@font-face {
  font-family: Calibri;
  src: url(./assets//Fonts/calibri-regular.ttf);
}

@font-face {
  font-family: Calibri;
  src: url(./assets//Fonts/calibri-bold.ttf);
  font-weight: bold;
}

@font-face {
  font-family: Calibri;
  src: url(./assets//Fonts/calibri-bold-italic.ttf);
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: Calibri;
  src: url(./assets//Fonts/calibri-italic.ttf);
  font-style: italic;
}

p,
span,
h1,
h2,
h3,
h4,
h5,
h6,
textarea,
input {
  font-family: "Poppins", sans-serif;
}

// span,
// p {
//   font-size: 14px;
// }

// h1 {
//   font-size: 32px;
// }

// h2 {
//   font-size: 24px;
// }

// h3 {
//   font-size: 18.72px;
// }

// h4 {
//   font-size: 16px;
// }

// h5 {
//   font-size: 13.28px;
// }

// h6 {
//   font-size: 10.72px;
// }

.htmlRenderWrapper {
  >p {
    white-space: pre-wrap;
    font-family: "Calibri" !important;
  }
}

.public-DraftStyleDefault-block {
  margin: 4px 0 !important;
}

button {
  cursor: pointer;

  &:disabled {
    cursor: auto;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }
}

a:active,
a:focus {
  outline: 0;
  border: none;
}

.App {
  text-align: center;
}

.App-logo {
  height: 50vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    position: absolute;
    animation: App-logo-spin 2s ease-in-out infinite;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  0% {
    transform: translateX(-100%) translateY(-100%) rotate(0deg);
  }

  25% {
    transform: translateX(100%) translateY(-100%) rotate(90deg);
  }

  50% {
    transform: translateX(100%) translateY(100%) rotate(180deg);
  }

  75% {
    transform: translateX(-100%) translateY(100%) rotate(270deg);
  }

  100% {
    transform: translateX(-100%) translateY(-100%) rotate(360deg);
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

input[type="date"]:not(.has-value) {
  position: relative;
  color: transparent;
}

input[type="date"]:not(.has-value):before {
  color: #9e9e9e;
  content: attr(placeholder);
  font-family: "Poppins", sans-serif;
  font-size: 12.5px;
  font-weight: 400;
  position: absolute;
}

input[type="date"]:not(.has-value):in-range::-webkit-datetime-edit-year-field,
input[type="date"]:not(.has-value):in-range::-webkit-datetime-edit-month-field,
input[type="date"]:not(.has-value):in-range::-webkit-datetime-edit-day-field,
input[type="date"]:not(.has-value):in-range::-webkit-datetime-edit-text {
  color: transparent;
}

input[type="date"]:disabled::-webkit-datetime-edit-year-field,
input[type="date"]:disabled::-webkit-datetime-edit-month-field,
input[type="date"]:disabled::-webkit-datetime-edit-day-field,
input[type="date"]:disabled::-webkit-datetime-edit-text {
  color: transparent;
}

::-webkit-datetime-edit-year-field:not([aria-valuenow]),
::-webkit-datetime-edit-month-field:not([aria-valuenow]),
::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
  color: transparent;
}

input[type="date"]:disabled::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #fff;
}

input[type="date"]:disabled:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #fff;
}

input[type="date"]:disabled::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #fff;
}

input[type="date"]:disabled:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #fff;
}

// ::-webkit-scrollbar{
//   height: 4px;
//   width: 4px;
//   background: $NEUTRAL_40;
// }
// ::-webkit-scrollbar-thumb:horizontal{
//   background: #000;
//   border-radius: 10px;
// }

// ::-webkit-scrollbar {
//   width: 10px;
//   height: 10px;
//   // width: thin;
// }

// ::-webkit-scrollbar-track {
//   // background: $NEUTRAL-30; 
//   background-color: $NEUTRAL-10;
// }

::-webkit-scrollbar-thumb {
  background: $NEUTRAL_50;
  border-radius: 10px;
}