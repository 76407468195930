@import "../../../theme/variables.scss";

.docCard {
  padding: 24px;
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  background-color: $NEUTRAL_10;


  h4 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
    letter-spacing: 0.005em;
    color: $NEUTRAL_100;
    padding-bottom: 24px;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
    gap: 0;

    img {
      height: 48px;
      aspect-ratio: 1;
      object-fit: contain;
    }

    p {
      font-family: 'Poppins';
      font-style: normal;
      text-align: center;
      color: $NEUTRAL_100;
      padding-top: 16px;
      padding-bottom: 2px;

      &.withDocument {
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
      }

      &.noDocument {
        font-weight: 600;
        font-size: 16px;
        line-height: 140%;
      }
    }

    button {
      background-color: transparent;
      border: none;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: $PRIMARY_MAIN;
    }

    span {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: $NEUTRAL_60;
    }
  }
}
