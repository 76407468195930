@import "../../../../theme/variables.scss";

.container {
  .header {
    z-index: 0;
    position: relative;
    background-image: url("../../../../assets/Images/client-header.png");
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    min-height: 144px;
    display: flex;
    // justify-content: space-between;
    flex-direction: column;
    gap: 8px;
    padding: 24px 32px;
    .routerBack {
      display: flex;
      align-items: center;
      gap: 4px;
      cursor: pointer;
      .icon {
        width: 16px;
        position: relative;
        top: 1.5px;
      }

      .back {
        color: var(--Neutral-10, var(--Color-Neutal-Neutral-10, #fff));
        text-align: center;

        /* Text M/Reguler */
        //   font-family: Poppins;
        // font-family: Poppins;
        font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
      }
    }
    .fulfillmentTitleBox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
      padding-bottom: 10px;
      .fulfillmentTitle {
        color: var(--Neutral-10, var(--Color-Neutal-Neutral-10, #fff));

        /* Heading 5/SemiBold */
        // font-family: Poppins;
        font-size: calc(20px + (26 - 20) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 36.4px */
        letter-spacing: 0.13px;
        width: 100%;
      }

      .icon {
        width: 32px;
        height: 32px;
        padding: 7px;
        border-radius: 8px;
        background: var(--Color-Neutal-Neutral-10, #fff);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }

    .addButton {
      background-color: $PRIMARY_MAIN;
      color: $NEUTRAL-10;
      padding: 8px 12px;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      border: none;
      border-radius: 8px;
      display: flex;
      align-items: center;
      gap: 4px;
      white-space: nowrap;

      &:disabled {
        background-color: $PRIMARY_DISABLED;
      }
    }
  }

  .content {
    padding: 32px;
    position: relative;
    bottom: 60px;
    display: flex;
    flex-direction: column;
    // gap: 16px;

    .progressBarFulfillment {
      border-radius: 8px;
      background: var(--Color-Neutal-Neutral-10, #fff);
      display: flex;
      padding: 10px;

      flex-direction: column;
      gap: 16px;
      min-height: 110px;
      .top {
        display: flex;
        // align-items: center;
        justify-content: space-between;
        gap: 16px;
        > span:first-child {
          color: var(--Primary-Main, #1571de);

          /* Heading 7/SemiBold */
          //   font-family: Poppins;
          font-size: calc(16px + (20 - 16) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 600;
          line-height: 150%; /* 30px */
          letter-spacing: 0.1px;
          width: 50%;
        }

        .descQuota {
          display: flex;
          align-items: center;
          gap: 16px;
          flex-wrap: wrap;
          justify-content: flex-end;
          width: 100%;
          > div {
            display: flex;
            align-items: center;
            gap: 4px;
            .blueDark {
              width: 37px;
              height: 10px;
              border-radius: 10px;
              background: var(--Primary-Main, #1571de);
            }

            .blueSky {
              border-radius: 10px;
              background: var(--Primary-Disabled, #91bdf1);
              width: 37px;
              height: 10px;
            }

            .grey {
              width: 37px;
              height: 10px;
              border-radius: 10px;
              background: #e6e3e3;
            }

            > span {
              color: var(--Neutral-90, var(--Color-Neutal-Neutral-90, #404040));
              //   font-family: Poppins;
              font-size: 13px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }
          }
        }
      }

      .progressBar {
        width: 100%;
      }
    }

    .dashboard {
      padding-top: 24px;
    }

    .positionBox {
      display: flex;
      flex-direction: column;
      gap: 24px;
      .position {
        display: flex;
        flex-direction: column;
        gap: 10px;
        border-radius: 8px;
        background: var(--Color-Neutal-Neutral-10, #fff);

        /* Shadow/300 */
        box-shadow: 0px 6px 8px -6px rgba(24, 39, 75, 0.12),
          0px 8px 16px -6px rgba(24, 39, 75, 0.08);

        .top {
          padding: 16px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 16px;
          > span:first-child {
            color: var(--Neutral-100, #3e4856);

            /* Heading 7/SemiBold */
            // font-family: Poppins;
            font-size: calc(
              16px + (20 - 16) * ((100vw - 280px) / (1600 - 280))
            );
            font-style: normal;
            font-weight: 600;
            line-height: 150%; /* 30px */
            letter-spacing: 0.1px;
            width: 100%;
          }

          > div:nth-child(2) {
            display: flex;
            align-items: center;
            gap: 4px;
            justify-content: center;
            padding: 8px;
            border-radius: 6px;
            border: 1px solid var(--Primary-Main, #1571de);
            background: var(--Color-Neutal-Neutral-10, #fff);
            width: 200px;
            cursor: pointer;
            > span {
              color: var(--Primary-Main, #1571de);
              text-align: center;

              /* Text M/Reguler */
              // font-family: Poppins;
              font-size: calc(
                12px + (14 - 12) * ((100vw - 280px) / (1600 - 280))
              );
              font-style: normal;
              font-weight: 400;
              line-height: 20px; /* 142.857% */
            }
          }
        }

        .totalBox {
          display: flex;
          // align-items: center;

          max-width: 120%;
          overflow: scroll;

          > div {
            width: 100%;

            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 6px 26px;
            // width: 100%;
            border-right: 1px solid #dee2e7;
            border-bottom: 1px solid #dee2e7;
            border-top: 1px solid #dee2e7;

            &.noBoreder {
              border-right: none;
            }
            // &.vacant{

            // }

            > span:first-child {
              // display: flex;
              // align-items: center;
              // text-align: center;
              // justify-content: center;
              text-align: center;
              gap: 4px;
              width: 100%;
              color: var(--Neutral-90, #556377);

              /* Caption 1/Reguler */
              // font-family: Poppins;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 16px; /* 133.333% */
              > span {
                color: var(--Primary-Main, #1571de);
                text-align: center;
                // font-family: Poppins;
                font-size: 10px;
                font-style: normal;
                font-weight: 400;
                line-height: 12px; /* 120% */
              }
              > img {
                width: 10px;
                height: 100%;
              }
            }

            > span:nth-child(2) {
              color: var(--Primary-Main, #1571de);

              /* Text L/SemiBold */
              // font-family: Poppins;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 140%; /* 22.4px */
            }

            > span:nth-child(3) {
              color: var(--Neutral-50, #bbc3ce);

              /* Caption 2/Reguler */
              // font-family: Poppins;
              font-size: 11px;
              font-style: normal;
              font-weight: 400;
              line-height: 16px; /* 145.455% */
            }
          }
        }

        .tableBox {
          padding-bottom: 16px;
          padding-left: 16px;
          padding-right: 16px;
        }
      }
    }

    .noFoundBox {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 16px;
      gap: 16px;
      border-radius: 8px;
      background: var(--Color-Neutal-Neutral-10, #fff);

      /* Shadow/300 */
      box-shadow: 0px 6px 8px -6px rgba(24, 39, 75, 0.12),
        0px 8px 16px -6px rgba(24, 39, 75, 0.08);
      .images {
        img {
          width: 295.429px;
          height: 220.13px;
        }
      }

      > span:nth-child(2) {
        color: var(--Primary-Main, #1571de);

        /* Heading 6/Bold */
        // font-family: Poppins;
        font-size: calc(20px + (24 - 20) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 700;
        line-height: 140%; /* 33.6px */
        letter-spacing: 0.12px;
      }

      > span:nth-child(3) {
        color: var(--Primary-Main, #1571de);

        /* Text L/Reguler */
        // font-family: Poppins;
        font-size: calc(14px + (16 - 14) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 22.4px */
      }
    }
  }
}

.cityRow {
  color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));

  /* Text S/Medium */
  // font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 153.846% */
}

.dateRow {
  color: var(--Neutral-90, var(--Color-Neutal-Neutral-90, #404040));
  // font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media screen and (max-width: 768px) {
  .container {
    .content {
      .progressBarFulfillment {
        .top {
          flex-direction: column;
          align-items: flex-start;
          > span:first-child {
            width: 100%;
          }

          .descQuota {
            justify-content: flex-start;
          }
        }
      }

      .positionBox {
        .position {
          .top {
            flex-direction: column;
            align-items: flex-start;
            > div:nth-child(2) {
              width: unset;
            }
          }
        }
      }
    }
  }
}
