.container {
  border-radius: 12px;
  background: var(--Color-Neutal-Neutral-10, #fff);
  box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.12);
  width: 500px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  .top {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 54px;
      height: 54px;
      background-color: #1571de1a;
      border-radius: 50px;
    }

    .downloadTitle {
      display: flex;
      flex-direction: column;
      gap: 4px;
      > span:first-child {
        color: var(--Neutral-90, #404040);

        /* Heading 4/SemiBold */
        // font-family: Poppins;
        font-size: calc(16px + (20 - 16) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 600;
        line-height: 26px; /* 130% */
      }

      > span:last-child {
        color: var(--Neutral-60, var(--Color-Neutal-Neutral-60, #9e9e9e));

        /* Text M/Regular */
        // font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
      }
    }

    .dateForm {
      display: flex;
      //   align-items: center;
      gap: 4px;
      width: 100%;
      .startDate {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 4px;
        .searchWrapper {
          width: 100%;
        }
        > span {
          color: var(--Neutral-100, #0a0a0a);

          /* Text M/Medium */
          //   font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 142.857% */
        }
      }

      .endDate {
        display: flex;
        flex-direction: column;
        gap: 4px;
        width: 100%;
        .searchWrapper {
          width: 100%;
        }
        > span {
          color: var(--Neutral-100, #0a0a0a);

          /* Text M/Medium */
          //   font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 142.857% */
        }
      }
    }
  }

  .bottom {
    display: flex;
    flex-direction: column;
    gap: 4px;
    > span {
      font-size: 12px;
      color: red;
    }
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--Color-Neutal-Neutral-10, #fff);
    text-align: center;

    /* Text M/Medium */
    // font-family: Poppins;
    padding: 10px 12px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    border-radius: 8px;
    background: var(--Primary-Main, #1571de);
    cursor: pointer;
    .disabled {
      background: #77a6e0;
    }
  }
  .button:hover {
    background: #77a6e0;
  }
}

@media screen and (max-width: 768px) {
  .container {
    border-radius: 8px 8px 0px 0px;
  }
}

@media screen and (max-width: 450px) {
  .container {
    .top {
      .dateForm {
        flex-direction: column;
        gap: 8px;
      }
    }
  }
}
