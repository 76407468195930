@import "../../../theme/variables.scss";

.containerModal {
  position: absolute;
  right: 0;
  top: 30px;
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  // gap: 12px;
  border-radius: 8px;
  background: var(--neutral-10, #fff);

  /* Dropdown Option Shadow */
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  .actionBox {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .link {
    display: flex;
    align-items: center;
    gap: 12px;
    cursor: pointer;
    .copyLink {
      color: var(--neutral-100, #0a0a0a);

      /* Text M/Medium */
      // font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
    }
  }
  .action {
    display: flex;
    align-items: center;
    gap: 12px;
    cursor: pointer;
    .nonActive {
      color: var(--danger-main, #ff3e13);

      /* Text M/Medium */
      // font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
    }
    .publish {
      color: var(--primary-main, #1571de);

      /* Text M/Medium */
      // font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
    }
    .unPublish {
      color: #e5dd1c;

      /* Text M/Medium */
      // font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
    }
    .active {
      color: var(--active-main, #96ab12);

      /* Text M/Medium */
      // font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
    }
  }
}

.NotifContainer {
  position: absolute;
  transition: max-height 0.2s ease-out;
  overflow: hidden;
  bottom: 15px;
  // top: 0px;
  right: 0;
  left: 0;
  padding-left: 16px;
  padding-right: 16px;

  // max-height: 200px;
  width: 100%;

  .NotificationWrapper {
    display: flex;
    padding: 8px;
    border: 1px solid $SUCCESS_MAIN;
    background: $SUCCESS_SURFACE;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    // margin-top: 24px;
    // transition:all 1s ease-in;

    .notif {
      display: flex;
      gap: 8px;
      align-items: center;
      color: $SUCCESS_MAIN;
      font-size: calc(10px + (14 - 10) * ((100vw - 280px) / (1600 - 280)));
    }
    & > :last-child {
      cursor: pointer;
    }
  }
}

.Hide {
  max-height: 0 !important;
}

.boxTooltip {
  display: flex;
  flex-direction: column;
  gap: 4px;
  .box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
  }
}

.tooltipClient {
  display: flex;
  flex-direction: column;
  gap: 4px;
  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    > span:first-child {
      width: 50%;
    }
    > span:last-child {
      text-align: end;
      width: 100%;
    }
  }
}

.containerJobPost {
  border-radius: 8px;
  border: 1px solid var(--Color-Neutal-Neutral-40, #e0e0e0);
  background: var(--Color-Neutal-Neutral-10, #fff);
  height: auto;
  position: relative;
  // min-height: 350px;
  // max-width: 280px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .header {
    padding: 8px 16px;
    border-bottom: 1px solid var(--Color-Neutal-Neutral-30, #ededed);
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    position: relative;

    .expiredFlag {
      width: 120px;
      height: 120px;
      overflow: hidden;
      position: absolute;
      top: 0;
      right: 0;
      > span {
        position: absolute;
        display: block;
        width: 150px;
        padding: 5px 0;
        background-color: #ff3e13;
        box-shadow: -1px 0px 4px 0px #94250d inset,
          0px -2px 4px 0px #94250d inset;
        color: var(--Neutral-10, var(--Color-Neutal-Neutral-10, #fff));
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        text-align: center;
        transform: rotate(45deg);
        top: 15px;
        right: -42px;
        filter: drop-shadow(0px 4px 6.8px rgba(12, 66, 130, 0.55));
      }
    }

    .dropdownBox {
      display: flex;
      flex-direction: column;
      // gap: 8px;
      border-radius: 8px;
      background: var(--Color-Neutal-Neutral-10, #fff);

      /* Dropdown Option Shadow */
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      // padding: 0px 8px;
      position: absolute;
      right: 15px;
      top: 45px;
      // box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

      .box {
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 8px 8px;
        > span {
          font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 19.6px */
          // padding: 8px 0px;
          color: #1571de;
          cursor: pointer;
          &.active {
            color: var(--active-main, #96ab12);
          }
          &.unPublish {
            color: #e5dd1c;
          }

          &.nonActive {
            color: var(--Danger-Main, #ff3e13);
          }

          &.copyLink {
            color: #0a0a0a;
          }
        }
      }
      > span {
        color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));
        // font-family: Poppins;
        font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 19.6px */
        padding: 8px 8px;
        &.disabled {
          color: var(--Neutral-50, var(--Color-Neutal-Neutral-50, #c2c2c2));
        }

        cursor: pointer;
        > span {
          font-weight: 700;
        }
      }

      > span:nth-child(2) {
        border-bottom: 1px solid #ededed;
        border-top: 1px solid #ededed;
      }

      .red {
        color: var(--Danger-Main, #ff3e13);
      }

      .blue {
        color: #1571de;
      }
    }

    &.grey {
      background: var(--Color-Neutal-Neutral-50, #c2c2c2);
      border-radius: 8px 8px 0px 0px;
      .left {
        > div:first-child {
          display: flex;
          align-items: center;
          gap: 8px;
          > span:first-child {
            color: var(--Neutral-10, var(--Color-Neutal-Neutral-10, #fff));
          }

          > div {
            position: relative;
            top: 2px;
            width: 20px;
            cursor: pointer;
          }
        }
        > span:last-child {
          color: var(--Primary-Soft, #e1edfe);
        }
      }
    }

    &.darkBlue {
      background: var(--Primary-Main, #1571de);
      border-radius: 8px 8px 0px 0px;
      .left {
        > div:first-child {
          display: flex;
          align-items: center;
          gap: 8px;
          > span:first-child {
            color: var(--Neutral-10, var(--Color-Neutal-Neutral-10, #fff));
          }
          > div {
            position: relative;
            top: 2px;
            width: 20px;
            cursor: pointer;
          }
        }
        > span:last-child {
          color: var(--Primary-Soft, #e1edfe);
        }
      }
    }

    &.lightBlue {
      background: #94b2ff;
      border-radius: 8px 8px 0px 0px;
      .left {
        > div:first-child {
          display: flex;
          align-items: center;
          gap: 8px;
          > span:first-child {
            color: var(--Neutral-10, var(--Color-Neutal-Neutral-10, #fff));
          }
          > div {
            position: relative;
            top: 2px;
            width: 20px;
            cursor: pointer;
          }
        }
        > span:last-child {
          color: var(--Primary-Soft, #e1edfe);
        }
      }
    }
    .left {
      display: flex;
      flex-direction: column;
      gap: 4px;
      width: 200px;
      > div:first-child {
        display: flex;
        align-items: center;
        gap: 8px;

        > span:first-child {
          overflow: hidden;
          color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));
          text-overflow: ellipsis;
          white-space: nowrap;

          /* Text L/SemiBold */
          // font-family: Poppins;
          font-size: calc(14px + (16 - 14) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 600;
          line-height: 140%; /* 22.4px */
        }

        > div {
          position: relative;
          top: 2px;
          width: 20px;
          cursor: pointer;
        }
      }

      > span:last-child {
        color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));

        /* Body/Reguler/Text 2 */
        // font-family: Poppins;
        font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
      }
    }

    .right {
      width: 20px;
      cursor: pointer;
      // position: relative;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 16px;
    // height: 100%;
    padding: 16px 8px 8px 8px;
    > div:first-child {
      display: flex;
      flex-direction: column;
      gap: 4px;

      > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
        > span:first-child {
          color: var(--Neutral-60, var(--Color-Neutal-Neutral-60, #9e9e9e));

          /* Text M/Reguler */
          // font-family: Poppins;
          font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
        }

        .dedicatedbox {
          display: flex;
          align-items: center;
          gap: 4px;
          .images {
            border-radius: 60px;
            position: relative;
            top: 2px;
            img {
              width: 24px;
              height: 24px;
            }
          }
          .dedicated {
            position: relative;
            overflow: clip;

            .hover {
              color: var(
                --Neutral-100,
                var(--Color-Neutal-Neutral-19, #0a0a0a)
              );

              font-size: calc(
                12px + (14 - 12) * ((100vw - 280px) / (1600 - 280))
              );
              font-style: normal;
              font-weight: 500;
              line-height: 20px; /* 142.857% */

              position: absolute;
              top: 0;

              left: 0;
              background-color: #fff;
              z-index: 1;
              transition: 0.5s;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              width: 100%;
              cursor: pointer;
            }
            .hover[is-hovered="true"] {
              transform: translateY(-100%);
            }

            .textUp {
              color: var(--Primary-Main, #1571de);
              text-align: center;

              font-family: Poppins;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 20px; /* 142.857% */
              text-decoration-line: underline;
              font-size: calc(
                12px + (14 - 12) * ((100vw - 280px) / (1600 - 280))
              );
              display: flex;
              align-items: center;
              gap: 4px;
              cursor: pointer;
            }
          }
        }

        > span:last-child {
          color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));
          text-align: right;

          /* Text M/Medium */
          // font-family: Poppins;
          font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 142.857% */
        }
      }
    }

    > div:nth-child(2) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
      > div:first-child {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 8px;
        border-radius: 9px;
        padding: 6px 8px;
        height: 30px;
        > span {
          color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));

          /* Text M/Medium */
          // font-family: Poppins;
          font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 142.857% */
        }
        &.yellow {
          background: #fffdd9;
          border-radius: 9px;

          .dots {
            width: 12px;
            height: 12px;
            background-color: #e5dd1c;
            border-radius: 50px;
          }
        }

        &.green {
          border-radius: 9px;
          background: var(--Success-Surface, #eafef3);
          .dots {
            width: 12px;
            height: 12px;
            background-color: #27bb83;
            border-radius: 50px;
          }
        }

        &.grey {
          background-color: #f5f5f5;
          // background-color: #c2c2c2;
          border-radius: 9px;
          .dots {
            width: 12px;
            height: 12px;
            background-color: #c2c2c2;
            border-radius: 50px;
          }
        }

        &.red {
          background: var(--Danger-Surface, #faeff2);
          .dots {
            width: 12px;
            height: 12px;
            background-color: #ff3e13;
            border-radius: 50px;
          }
        }
      }
      > div:last-child {
        height: 30px;
        width: 100%;
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 6px 8px;
        border-radius: 8px;
        background: var(--Primary-Surface, #f5f7fe);
        > span {
          overflow: hidden;
          color: var(--Neutral-100, #242424);
          text-overflow: ellipsis;

          /* Text M/Medium */
          // font-family: Poppins;
          font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 142.857% */
        }
      }
    }

    > div:nth-child(3) {
      display: flex;
      flex-direction: column;
      gap: 8px;
      > div:first-child {
        display: flex;
        align-items: center;
        gap: 4px;
        > div {
          border-radius: 4px;
          border: 1px solid var(--Color-Neutal-Neutral-50, #c2c2c2);
          display: flex;
          padding: 4px 8px;
          justify-content: center;
          align-items: center;
          color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));

          /* Text S/Medium */
          // font-family: Poppins;
          font-size: calc(10px + (13 - 10) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 153.846% */
        }
      }

      > div:nth-child(2) {
        display: flex;
        align-items: center;
        gap: 8px;
        .iconPin {
          width: 16px;
          position: relative;
          top: 1px;
        }

        .placement {
          display: flex;
          // flex-direction: column;
          align-items: center;
          gap: 4px;
          // cursor: pointer;
          > span {
            color: var(--Neutral-100, #242424);

            /* Text M/Reguler */
            // font-family: Poppins;
            font-size: calc(
              12px + (14 - 12) * ((100vw - 280px) / (1600 - 280))
            );
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
          }

          .slice {
            font-weight: 700;
          }
        }

        > span {
          color: var(--Neutral-100, #242424);

          /* Text M/Reguler */
          // font-family: Poppins;
          font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
          // cursor: pointer;
        }
      }

      > div:nth-child(3) {
        display: flex;
        align-items: center;
        gap: 8px;
        .iconMoney {
          width: 16px;
        }

        > span {
          color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));

          /* Text M/Reguler */
          // font-family: Poppins;
          font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
        }
      }
    }

    > div:nth-child(4) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
      width: 100%;
      > div {
        width: 100%;
        border-radius: 6px;
        border: 1px solid var(--Primary-Main, #1571de);
        background: var(--Neutral-10, #fff);
        padding: 8px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--Primary-Main, #1571de);
        text-align: center;

        /* Text M/Reguler */
        // font-family: Poppins;
        font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        cursor: pointer;
        &.darkblue {
          border-radius: 6px;
          background: var(--Primary-Main, #1571de);
          color: var(--Color-Neutal-Neutral-10, #fff);
        }

        &.blueDisabled {
          background-color: #94b2ff;
          color: var(--Color-Neutal-Neutral-10, #fff);
          border-radius: 6px;
          border: 1px solid #94b2ff;
        }
        &.disabled {
          color: var(--neutral-60, #9e9e9e);
          // text-align: center;
          border: 1px solid var(--neutral-50, #c2c2c2);
          background: var(--neutral-20, #f5f5f5);
        }
      }
    }
  }
}

.notification {
  position: fixed;
  top: -100px; /* start above the viewport */
  left: 50%;
  transform: translateX(-50%);
  border-radius: 8px;
  background: var(--Success-Main, #27bb83);
  color: var(--Neutral-10, var(--Color-Neutal-Neutral-10, #fff));
  // text-align: center;

  /* Text M/Medium */
  // font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  padding: 8px 12px;
  // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: top 0.5s ease-in-out;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  &.show {
    top: 20px;
  }
}
