.filterModalBody {
  min-height: 100vh;
  min-width: 100vw;
  background-color: #00000080;
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
}
.iconX {
  cursor: pointer;
}

.rangePriceBox {
  display: flex;
  flex-direction: column;
  gap: 8px;
  .sliderBox {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
  .priceTemplate {
    color: var(--Neutral-90, var(--Color-Neutal-Neutral-90, #404040));

    /* Text M/Medium */
    // font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
  }
}

.buttonBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 16px 20px 24px 20px;
  .buttonRight {
    gap: 8px;
    display: flex;
    padding: 12px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: var(--Primary-Main, #1571de);
    cursor: pointer;
    .searchText {
      color: var(--Color-Neutal-Neutral-10, #fff);
      text-align: center;

      /* Text M/Reguler */
      // font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
    }
  }

  .buttonLeft {
    display: flex;
    padding: 12px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px solid var(--Danger-Main, #ff3e13);
    background: var(--Neutral-10, #fff);
    cursor: pointer;
    color: var(--Danger-Main, #ff3e13);
    text-align: center;

    /* Text M/Reguler */
    // font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
}

.rangeDefault {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .spanRP {
    color: var(--Color-Neutal-Neutral-90, #404040);

    /* Text M/Reguler */
    // font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
}
.containerFilter {
  width: 800px;
  max-height: 80%;
  overflow-y: scroll;
  border-radius: 8px;
  //   border-bottom: 1px solid var(--Neutral-30, #ededed);
  background: var(--Color-Neutal-Neutral-10, #fff);
}
.boxSelect {
  display: flex;
  align-items: center;
  //   justify-content: center;
  border: 1px solid var(--Neutral-60, #9e9e9e);
  background: var(--Neutral-10, #fff);
  border-radius: 8px;

  .selected {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-right: 1px solid var(--Neutral-60, #9e9e9e);
    padding: 12px 8px;

    // border-radius: 8px;
    border-radius: 8px 0px 0px 8px;
    // background-color: #0a0a0a;
    cursor: pointer;
    color: #000;
    text-align: center;

    /* Text S/Reguler */
    // font-family: Poppins;
    font-size: calc(8px + (13 - 8) * ((100vw - 280px) / (1600 - 280)));
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 153.846% */
    &.selectedMiddle {
      border-radius: 0px;
    }
    &.selectActive {
      //   border-radius: 24px;
      background: var(--Primary-Main, #1571de);
      color: var(--Color-Neutal-Neutral-10, #fff);
    }

    &.selectedPkm {
      border-right: 0px;
      border-radius: 0px 8px 8px 0px;
    }
  }
}

.boxSelectExperinces {
  display: flex;
  align-items: center;
  //   justify-content: center;
  border: 1px solid var(--Neutral-60, #9e9e9e);
  background: var(--Neutral-10, #fff);
  border-radius: 8px;

  .selected {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-right: 1px solid var(--Neutral-60, #9e9e9e);
    padding: 12px 8px;

    // border-radius: 8px;
    border-radius: 8px 0px 0px 8px;
    // background-color: #0a0a0a;
    cursor: pointer;
    color: #000;
    text-align: center;

    /* Text S/Reguler */
    // font-family: Poppins;
    font-size: calc(10px + (13 - 10) * ((100vw - 280px) / (1600 - 280)));
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 153.846% */
    &.selectedMiddle {
      border-radius: 0px;
    }
    &.selectActive {
      //   border-radius: 24px;
      background: var(--Primary-Main, #1571de);
      color: var(--Color-Neutal-Neutral-10, #fff);
    }

    &.selectedPkm {
      border-right: 0px;
      border-radius: 0px 8px 8px 0px;
    }
  }
}
.topBox {
  position: sticky;
  background: var(--Color-Neutal-Neutral-10, #fff);
  z-index: 3;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px 8px 20px;
  border-bottom: 1px solid var(--Neutral-30, #ededed);
  .filtText {
    overflow: hidden;
    color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));
    text-overflow: ellipsis;

    /* Heading 6/SemiBold */
    // font-family: Poppins;
    font-size: calc(14px + (24 - 14) * ((100vw - 280px) / (1600 - 280)));
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 33.6px */
    letter-spacing: 0.12px;
  }
}

.contentFilter {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px 20px 24px 20px;
}

.inputBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  .labelBox {
    display: flex;
    align-items: center;
    gap: 8px;
    .label {
      color: var(--Neutral-90, var(--Color-Neutal-Neutral-90, #404040));

      /* Text M/Medium */
      //   font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
    }
  }

  .input {
    width: 70%;
  }
}

.boxSelectResponsive {
  display: none;
}

@media screen and (max-width: 650px) {
  .boxSelectResponsive {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    // gap: 5px;
    .selected {
      margin-bottom: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
      // border-right: 1px solid var(--Neutral-60, #9e9e9e);
      padding: 12px 8px;
      border: 1px solid var(--Neutral-60, #9e9e9e);
      background: var(--Neutral-10, #fff);

      // border-radius: 8px;
      border-radius: 8px 0px 0px 8px;
      // background-color: #0a0a0a;
      cursor: pointer;
      color: #000;
      text-align: center;

      /* Text S/Reguler */
      // font-family: Poppins;
      font-size: calc(10px + (13 - 10) * ((100vw - 280px) / (1600 - 280)));
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 153.846% */
      &.selectedMiddle {
        border-radius: 0px 8px 8px 0px;
        border-left: 0px;
      }
      &.selectActive {
        //   border-radius: 24px;
        background: var(--Primary-Main, #1571de);
        color: var(--Color-Neutal-Neutral-10, #fff);
      }

      &.selectedPkm {
        // border-right: 0px;
        // border-radius: 0px 8px 8px 0px;
      }
    }
  }
  .boxSelectExperinces {
    display: none;
  }
  .filterModalBody {
    padding: 0px;
  }

  .containerFilter {
    position: fixed;
    bottom: 0;
    width: 100%;
    border-radius: 8px 8px 0px 0px;
  }

  .inputBox {
    flex-direction: column;
    align-items: flex-start;
    .input {
      width: 100%;
    }
  }

  .buttonBox {
    flex-direction: column;
    .buttonRight {
      width: 100%;
    }
    .buttonLeft {
      width: 100%;
    }
  }
}
