@import "../../../../../theme/variables.scss";

.background {
    width: 100vw;
    height: 100vh;
    background-color: $NEUTRAL_OVERLAY;
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  
    .container {
      background-color: $NEUTRAL_10;
      border-radius: 8px;
      padding: 24px;
      gap: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 482px;
      max-width: calc(100vw - 30px);
  
      .titleWrapper{
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        text-align: center;

        .title {
            display: flex;
            align-items: center;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 26px;
            color: $NEUTRAL_100;
            text-align: center;
          }

          .buttonBack{
            background: transparent;
            border: 0px;
          }
      }

      p {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
      }

      .error {
        color: $DANGER_MAIN;
        text-align: left;
      }

      .buttonWrapper{
        display: flex;
        width: 100%;
        justify-content: space-between;
        gap: 8px;

        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;

        .buttonAccept{
            width: 50%;
            padding: 8px 16px;
            cursor: pointer;
            border-radius: 8px;
            border: 1px solid $PRIMARY_MAIN;
            background-color: $PRIMARY_MAIN;
            color: $NEUTRAL_10;

            &:hover{
                border-color: $BS_PRIMARY_HOVER;
                background-color: $BS_PRIMARY_HOVER;
            }
        }
        .buttonDecline{
            width: 50%;
            padding: 8px 16px;
            cursor: pointer;
            border-radius: 8px;
            border: 1px solid $NEUTRAL_60;
            background-color: $NEUTRAL_10;
            color: $NEUTRAL_100;

            &:hover{
                border-color: $PRIMARY_MAIN;
                background-color: $NEUTRAL_10;
                color: $PRIMARY_MAIN;
            }
        }

      }
      
    }
  }