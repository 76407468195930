@import "../../../../../../theme/variables.scss";

.container {
  .topBox {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid $NEUTRAL_40;
    background-color: $NEUTRAL_LIGHTER;
  }
  .postBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 24px 16px 24px;
  }

  .buttonDisabled {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;

    text-align: center;
    // cursor: pointer;
    /* Text M/Medium */
    // font-family: Poppins;
    border-radius: 8px;
    border: 1px solid var(--neutral-50, #c2c2c2);
    background: var(--neutral-10, #fff);
    color: var(--neutral-50, #c2c2c2);
    text-align: center;

    /* Text M/Medium */
    // font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    color: var(--primary-main, #1571de);
    text-align: center;
    cursor: pointer;
    /* Text M/Medium */
    // font-family: Poppins;
    border-radius: 8px;
    border: 1px solid var(--primary-main, #1571de);
    background: var(--neutral-10, #fff);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
  }

  .titleSpan {
    color: var(--neutral-100, #0a0a0a);

    /* Heading 7/Bold */
    // font-family: Poppins;
    font-size: calc(20px + (20 - 20) * ((100vw - 280px) / (1600 - 280)));
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 30px */
    letter-spacing: 0.1px;
  }
  .breadcrumbWrapper {
    width: 100%;
    padding: 16px 24px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;

    position: sticky;
    top: 70px;
    z-index: 2;
    .span {
      background: var(--neutral-10, #fff);
      width: 40px;
      height: 40px;
      display: grid;
      place-items: center;
      border-radius: 50%;
      cursor: pointer;
      text-decoration: none;
    }

    > span {
      // border-radius: 80px;
      background: var(--neutral-10, #fff);
      width: 40px;
      height: 40px;
      display: grid;
      place-items: center;
      border-radius: 50%;
      cursor: pointer;
    }
    .breadcrumbTextBox {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    .breadcrumbText {
      font-weight: 500;
      font-size: calc(10px + (16 - 10) * ((100vw - 280px) / (1600 - 280)));
      line-height: 20px;
      color: $NEUTRAL_60;
      cursor: pointer;
    }

    .breadcrumbText2 {
      font-weight: 500;
      font-size: calc(10px + (16 - 10) * ((100vw - 280px) / (1600 - 280)));
      line-height: 20px;
      color: $NEUTRAL_100;
    }
  }

  .info {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 30px;
    color: var(--primary-main, #1571de);
    text-align: center;

    /* Text M/Medium */
    // font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    background-color: $PRIMARY_SOFT;
  }
}

@media screen and (max-width: 768px) {
  .container {
    .info {
      display: none;
    }
    .breadcrumbWrapper {
      .span {
        width: 25px;
        height: 25px;
      }
    }
    .button {
      display: none;
    }
    .buttonDisabled {
      display: none;
    }
  }
}
