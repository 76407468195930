@import "../../../../../../theme/variables.scss";

.tableWrapper {
  padding: 24px 30px;
  height: 100%;

  .showOnMobile {
    display: none;
  }

  .tableContainer {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    &::-webkit-scrollbar {
      display: none;
    }

    .table {
      border-collapse: separate;

      .tableHead {
        background-color: $NEUTRAL_10;

        .tableHeadRow {
          .tableHeadCell {
            padding: 16px 20px;

            span {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 20px;
              color: $NEUTRAL_100;
            }

            &.firstHeadCell {
              min-width: 228px;
              border-right: 1px solid $NEUTRAL_40;
              position: sticky;
              left: 0;
              background-color: $NEUTRAL_10 !important;
              z-index: 1;
            }
          }
        }
      }

      .tableBody {
        .tableRow {
          &.oddRow {
            background-color: $PRIMARY_SURFACE;
          }

          &.evenRow {
            background-color: $NEUTRAL_10;
          }

          .tableCell {
            border-bottom: none !important;
            padding: 16px 20px;

            > span {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 400;
              font-size: 13px;
              line-height: 20px;
              color: $NEUTRAL_90;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            > button {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 500;
              font-size: 13px;
              line-height: 20px;
              color: $PRIMARY_MAIN;
              background-color: transparent;
              border: none;
            }

            &.firstCell {
              border-right: 1px solid $NEUTRAL_40;
              position: sticky;
              left: 0;
              background-color: inherit;

              .stacked {
                display: flex;
                align-items: center;
                gap: 8px;

                div {
                  width: 36px;
                  aspect-ratio: 1;
                  border-radius: 50%;
                  background-color: $PRIMARY_SOFT;
                  display: grid;
                  place-items: center;
                  color: $PRIMARY_MAIN;
                }

                span {
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  font-family: "Poppins";
                  font-style: normal;
                  font-weight: 500;
                  font-size: 13px;
                  line-height: 20px;
                  color: $NEUTRAL_100;
                }
              }
            }
          }
        }
      }
    }
  }

  .paginationContainer {
    width: 100%;
    border-top: 1px solid $NEUTRAL_40;
  }

  .modalWrapper {
    position: fixed;
    transition: all 0.35s ease-in-out !important;
    z-index: 4;
    background-color: transparent;
    left: 0;
    right: 0;

    &.hideModal {
      bottom: -500px;
    }

    &.showModal {
      bottom: 0;
    }
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    background-color: $NEUTRAL_OVERLAY;
    opacity: 0;
    z-index: 3;

    &.visible {
      right: 0;
      bottom: 0;
      opacity: 1;
      transition: opacity 0.4s;
    }
  }

}

@media screen and (max-width: 768px) {
  .tableWrapper {
    padding: 16px 18px;

    .hideOnMobile {
      display: none;
    }

    .showOnMobile {
      display: table-cell !important;

      button {
        background-color: $PRIMARY_MAIN !important;
        width: 100% !important;
        padding: 4px 8px !important;
        color: $NEUTRAL_10 !important;
        border-radius: 8px;
        font-family: 'Poppins';
        font-style: normal!important;
        font-weight: 500!important;
        font-size: 13px!important;
        line-height: 20px;
      }
    }

    .firstHeadCell {
      min-width: 50% !important;
      width: auto !important;
      border-right: none!important;
    }

    .tableCell {
      padding: 12px 16px !important;

      &.firstCell {
        border-right: none!important;

        span {
          white-space: normal !important;
        }
      }
    }


  }
}
