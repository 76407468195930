@import "../../../../../../../theme/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .type {
    background-color: $NEUTRAL-20;
    padding: 24px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .cardHeader {
      display: flex;
      flex-direction: column;

      >h4 {
        font-size: 20px;
        font-weight: 700;
      }

      >span {
        font-weight: 14px;
        color: $NEUTRAL-70;
      }
    }

    .cardContent {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .rule {
        display: flex;
        align-items: center;
        gap: 10px;

        >img {
          width: 18px;
          aspect-ratio: 1;
          object-fit: contain;
        }

        >p {
          font-size: 13px;
          color: #333;
        }
      }
    }
  }

  .documents {
    display: flex;
    flex-direction: column;
    gap: 16px;

    >h4 {
      font-size: 20px;
      font-weight: 700;
    }

    .empty {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 12px;
      padding: 24px;
      text-align: center;
  
      > img {
        width: 150px;
        object-fit: contain;
        max-width: 90vw;
      }
  
      > div {
        width: 374px;
        max-width: 90vw;
        display: flex;
        flex-direction: column;
        gap: 8px;
  
        > span {
          font-family: Poppins;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 150%;
          letter-spacing: 0.09px;
          color: $PRIMARY_MAIN;
        }
  
        > p {
          font-family: Poppins;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 153.846%;
          color: $NEUTRAL_70;
        }
      }
    }

    .link {
      text-decoration: underline;
      color: $PRIMARY_MAIN;
      cursor: pointer;
    }
  }
}