@import "../../../../../../theme/variables.scss";

.container {
  background-color: $NEUTRAL_10;
  border: 1px solid $NEUTRAL_50;
  border-radius: 8px;
}

@media screen and (max-width: 768px) {
  .container {
    border-radius: 0px;
  }
}
