@import "../../../theme/variables.scss";

.container {
  height: 100%;
  width: 100%;
  background-color: $NEUTRAL_10;
  border-radius: 8px;
  border: 1px solid $NEUTRAL_40;
  padding: 24px;
  display: flex;
  flex-direction: column;

  &.noPadding {
    padding: 0;
    border: none;
  }

  .errorWrapper {
    padding-bottom: 8px;
  }

  .errorText {
    color: var(--Helper, #f5610d);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }

  .titleWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 16px;

    .title {
      font-size: 16px;
      font-weight: 600;
      color: $NEUTRAL_100;
    }

    .input {
      width: 60%;
    }

    .withoutInput {
      visibility: hidden;
      width: 0%;
    }
  }

  .filesWrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-top: 24px;

    .fileCard {
      padding: 16px;
      border: 1px solid $NEUTRAL_30;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: $NEUTRAL_10;
      gap: 18px;

      .left {
        display: flex;
        align-items: center;
        gap: 18px;
        flex-grow: 1;
        width: 100%;

        > div {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          gap: 4px;
        }

        .fileIcon {
          color: $NEUTRAL_90;
        }

        span {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          color: $PRIMARY_MAIN;
        }
      }

      button {
        display: grid;
        place-items: center;
        background-color: transparent;
        border: none;
        color: $NEUTRAL_90;
        cursor: pointer;
      }
    }
  }

  .commandText {
    padding-bottom: 4px;
    font-size: 12px;
    font-weight: 500;
    color: $NEUTRAL_70;
  }

  .buttonWrapper {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding-top: 16px;
    flex-grow: 1;

    .button {
      width: 93px;
      height: 36px;
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    padding: 16px;

    &.noPadding {
      background-color: transparent !important;
    }

    .titleWrapper {
      flex-direction: column;
      justify-content: center;
      padding-bottom: 10px;

      .input {
        width: 100%;
        padding-top: 10px;
      }

      .withoutInput {
        display: none;
      }
    }

    .uploadBoxWrapper {
      height: 160px;

      &.extraHeight {
        height: 142px;
      }
    }

    .buttonWrapper {
      padding-top: 10px;
    }
  }
}
