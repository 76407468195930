@import "../../../../../../../theme/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .bpjs {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}

.each {
  display: flex;
  flex-direction: column;
  gap: 16px;

  >h3 {
    font-size: 20px;
    font-weight: 700;
  }


}

.infoCard {
  padding: 8px 12px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 4px;

  >span {
    font-size: 12px;
    color: $NEUTRAL_60;
  }

  >p {
    font-size: 14px;
  }
}

.gridWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 8px;

  .title {
    font-size: 16px;
    font-weight: 600;
  }

  .grid {
    display: grid;
    gap: 16px;
  }
}


@media screen and (max-width:768px) {
  .grid {
    grid-template-columns: 1fr !important;

    &.half {
      grid-template-columns: 1fr 1fr !important;

    }
  }
}