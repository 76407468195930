@import "../../../theme/variables.scss";

.container {
  font-family: "Poppins";
  position: relative;
  width: 100%;
  background-color: $NEUTRAL_LIGHTEST;
  border: 1px solid $NEUTRAL_40;
  border-radius: 8px;
  padding: 12px;
  display: flex;
  // justify-content: space-between;
  transition: 0.2s;
  cursor: pointer;
  align-items: center;
}

.containerPenilaian {
  font-family: "Poppins";
  position: relative;
  width: 100%;
  // background-color: $NEUTRAL_LIGHTEST;
  // border: 1px solid $NEUTRAL_40;
  border-radius: 8px;
  // padding: 12px;
  display: flex;
  // justify-content: space-between;
  transition: 0.2s;
  cursor: pointer;
  align-items: center;
}

.valueText,
.placeholderText {
  flex-grow: 1;
}

.iconWrapper {
  display: grid;
  place-items: center;
  padding-right: 8px;
}

.containerColorDisabled {
  background-color: $NEUTRAL_30;
}

.activeContainer {
  border-color: $PRIMARY_MAIN;
  transition: 0.2s;
}

.leftDropdown {
  display: flex;
  align-items: center;
  gap: 8px;
}

.options {
  font-family: "Poppins";
  position: absolute;
  width: 100%;
  margin-top: 5px;
  border-radius: 0 0 8px 8px;
  overflow: hidden;
  background-color: $NEUTRAL_10;
  transition: 0.2s;
  box-shadow: 0px 0px 1px rgba(9, 30, 66, 0.31),
    0px 3px 5px rgba(9, 30, 66, 0.2);
  z-index: 1;
}

.inputAddEvaluations {
  width: 80%;
  height: 20px;
  border: none;
  padding: 0px 0px;
  border-bottom: 1px solid #e0e0e0;
  resize: none;
  overflow: hidden;
  // width: 100%;
}
.inputAddEvaluations:focus {
  outline: none !important;
  border: none;

  border-bottom: 1px solid #e0e0e0;
}
.inputAddEvaluations::placeholder {
  color: var(--neutral-60, #9e9e9e);

  /* Text S/Reguler */
  // font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 153.846% */
}

.option {
  padding: 10px;
  font-size: 13px;
  font-weight: 400;
  color: $NEUTRAL_100;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.optionWrapper:hover {
  background-color: $NEUTRAL_20;
  cursor: pointer;
}

.borderBottom {
  margin: 0 10px;
  border-bottom: 1px solid $NEUTRAL_50;
}

.valueText {
  color: $NEUTRAL_100;
  font-size: 13px;
  font-weight: 400;
  user-select: none;
}

.optionDisable {
  color: var(--neutral-60, #9e9e9e);

  /* Text M/Reguler */
  // font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.warningDisable {
  color: var(--danger-main, #ff3e13);
  text-align: right;

  /* Text M/Reguler */
  // font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.optionWrapperDisable {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.placeholderText {
  color: $NEUTRAL_60;
  font-size: 13px;
  font-weight: 400;
  user-select: none;
}

.radioWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
}

.radio {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 1px solid $NEUTRAL_50;
}

.selectedRadio {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 5px solid $PRIMARY_MAIN;
}

.iconEdit {
  cursor: pointer;
}

.boxListDropdown {
  display: flex;
  flex-direction: column;
  height: 120px;
  overflow-y: scroll;
}

.boxListDropdownNone {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.otherChoose {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 10px;
  cursor: pointer;
  border-top: 1px solid #ededed;
  position: sticky;
  bottom: 0;
  background-color: $NEUTRAL_10;
  .otherTitle {
    color: var(--neutral-100, #0a0a0a);

    /* Text M/Reguler */
    // font-family: Poppins;
    font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
  .desc {
    color: var(--neutral-60, #9e9e9e);

    /* Text M/Reguler */
    // font-family: Poppins;
    font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
}

.nameoOptionBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
  > span:first-child {
    color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));

    /* Text M/Reguler */
    // font-family: Poppins;
    font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
  > span:nth-child(2) {
    display: flex;
    padding: 2px 8px;
    // align-items: flex-start;
    align-items: center;
    justify-content: center;
    // gap: 8px;

    border-radius: 4px;
    background: var(--Primary-Surface, #f5f7fe);

    color: var(--Primary-Main, #1571de);
    text-align: center;

    /* Caption 1/Medium */
    // font-family: Poppins;
    font-size: calc(10px + (12 - 10) * ((100vw - 280px) / (1600 - 280)));
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 133.333% */
  }
}

.valueBox {
  display: flex;
  align-items: center;
  gap: 8px;
  > span:first-child {
    color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));

    /* Text S/Reguler */
    // font-family: Poppins;
    font-size: calc(12px + (13 - 12) * ((100vw - 280px) / (1600 - 280)));
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 153.846% */
  }
  > span:nth-child(2) {
    display: flex;
    padding: 2px 8px;
    // align-items: flex-start;
    align-items: center;
    justify-content: center;
    // gap: 8px;
    color: var(--Primary-Main, #1571de);
    text-align: center;

    /* Caption 1/Medium */
    // font-family: Poppins;
    font-size: calc(10px + (12 - 10) * ((100vw - 280px) / (1600 - 280)));
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 133.333% */

    border-radius: 4px;
    background: var(--Primary-Surface, #f5f7fe);
  }
}

@media screen and (max-width: 400px) {
  .otherChoose {
    flex-direction: column;
    align-items: flex-start;
  }
  .inputAddEvaluations {
    width: 100%;
  }
}
