@import "../../../theme/variables.scss";

.wrapper {
  display: flex;
  align-items: center;
  gap: 16px;

  .container {
    width: 100%;
    display: flex;

    .input {
      width: 100%;
      padding: 12px;
      border-radius: 8px 0 0 8px;
      border: 1px solid $NEUTRAL_40;
      transition: 0.2s;

      &.round {
        border-radius: 8px;
      }
    }

    .input:focus {
      outline: none !important;
      border-color: $PRIMARY_MAIN;
      border-width: 1px;
      border-style: solid;
    }

    .adornment {
      background-color: $NEUTRAL_40;
      border-radius: 0 8px 8px 0;
      padding: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 13px;
      font-weight: 400;
      color: $NEUTRAL_60;
    }
  }

  button {
    cursor: pointer;
    background-color: transparent;
    border: none;
    color: $DANGER_MAIN;
  }
}
