// .footer {
//   width: 100%;
//   // min-height: 350px;
//   background-color: #091031;
//   display: flex;
//   // padding: 60px 149px 100px 158px; // TOP RIGHT BOTTOM LEFT

//   & > div {
//     display: flex;
//     flex-direction: column;

//     span,
//     a {
//       color: #fff;
//     }

//     > a {
//       text-decoration: none;
//       font-size: 1rem;
//     }

//     .Title {
//       margin-top: 40px;
//       margin-bottom: 15px;
//       font-size: 18px;
//       font-weight: 500;
//     }
//   }

//   .contactArea {
//     flex: 1.8;

//     .logoWrapper {
//       margin-bottom: 30px;
//       .logo {
//         height: 60px;
//         object-fit: contain;
//       }
//     }

//     span {
//       color: #fff;
//       margin-block: 5px;
//     }

//     .primary {
//       font-size: 18px;
//       font-weight: 500;
//     }

//     .secondary {
//       font-size: 13px;
//     }
//   }

//   .navigationArea {
//     flex: 1.3;
//     span {
//       margin-block: 5px;
//       cursor: pointer;
//     }
//   }

//   .subscribeArea {
//     flex: 1.3;

//     .title {
//       & + span {
//         font-size: 1rem;
//         margin-block: 5px;
//       }
//     }

//     .emailInput {
//       display: flex;
//       align-items: center;

//       input {
//         width: 100%;
//         height: 44px;
//         outline: none;
//         border-radius: 22px;
//         border-width: 0;
//         padding-left: 10px;
//         padding-right: 43px;
//         font-size: 1rem;
//         margin-block: 15px;
//         color: #fff;
//         background-color: #091031;
//         border: 2px solid #f0f0f2;
//       }

//       .arrowButton {
//         height: 30px;
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         aspect-ratio: 1 !important;
//         border-radius: 15px;
//         background-color: #4266ff;
//         z-index: 2;
//         transform: translateX(-36px);
//       }
//     }

//     .socialMediaWrapper {
//       display: flex;
//       margin-block: 20px;
//       align-items: center;
//       a {
//         margin-right: 22px;
//       }
//     }
//   }
// }

// @media only screen and (max-width: 768px) {
//   .footer {
//     flex-direction: column;
//     padding: 16px 18px;
//   }
// }

.footer {
  display: flex;
  flex-direction: column;
  padding: 60px 40px 40px 40px;
  width: 100%;
  background: #091031;
}

.footerTop {
  display: flex;
  // align-items: center;
  // gap: 80px;
  justify-content: space-between;
  border-bottom: 1px solid #fff;
  padding-bottom: 60px;
  .footerLeft {
    display: flex;
    align-items: center;
    gap: 60px;
    // width: 50%;
    .imagesBox {
      // width: 5%;
      width: 165px;
      // height: 68px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .informationLeftBox {
    display: flex;
    flex-direction: column;
    gap: 12px;
    // width: 50%;
    .fastServices {
      color: #fff;

      /* Text L/Bold */
      // font-family: Poppins;
      font-size: calc(14px + (16 - 14) * ((100vw - 280px) / (1600 - 280)));
      font-style: normal;
      font-weight: 700;
      line-height: 140%; /* 22.4px */
    }
    .detailInformation {
      display: flex;
      flex-direction: column;
      gap: 8px;
      .textAddress {
        color: #fff;

        /* Text M/Reguler */
        // font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        width: 55%;
      }
      .text {
        color: #fff;

        /* Text M/Reguler */
        // font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
      }
    }
  }

  .navigationBox {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 20%;
    .nav {
      color: #fff;

      /* Text L/Bold */
      // font-family: Poppins;
      font-size: calc(14px + (16 - 14) * ((100vw - 280px) / (1600 - 280)));
      font-style: normal;
      font-weight: 700;
      line-height: 140%; /* 22.4px */
    }
    .textBox {
      display: flex;
      flex-direction: column;
      gap: 8px;
      .p {
        color: #fff;
        cursor: pointer;
        /* Text M/Reguler */
        // font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
      }
    }
  }
}

.footerBottom {
  padding-top: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  .textBottom {
    color: var(--neutral-10, #fff);

    /* Text M/Medium */
    // font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
  }
  .images {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
    border-radius: 50px;
    background: var(--neutral-10, #fff);
    img {
      width: 24px;
      height: 24px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .footer {
    padding: 60px 18px 40px 18px;
  }
  .footerTop {
    flex-direction: column;
    gap: 16px;
    .footerLeft {
      flex-direction: column;
      align-items: flex-start;
      gap: 19px;
    }

    .navigationBox {
      width: 100%;
      .nav {
        font-size: 16px;
      }
    }

    .informationLeftBox {
      .fastServices {
        font-size: 16px;
      }
      .detailInformation {
        .textAddress {
          width: 100%;
        }
      }
    }
  }
}
