@import "../../../theme/variables.scss";

.wrapper {
  display: flex;
  align-items: center;
  gap: 16px;

  .container {
    height: 100%;
    width: 100%;
    position: relative;
  }

  .textArea {
    width: 100%;
    padding: 12px;
    min-height: 40px;
    border-radius: 8px;
    border: 1px solid $NEUTRAL_40;
    color: $NEUTRAL_100;
    font-size: 13px;
    font-weight: 400;
    resize: vertical;
    transition: 0.2s;
    font-family: 'Poppins';

    &.error {
      border-color: #F5610D;
    }

    &::placeholder {
      font-size: 13px;
    }

    &:disabled {
      background-color: $NEUTRAL_30;
      color: $NEUTRAL_60!important;
    }
  }

  .errorText {
    color: var(--Helper, #F5610D);
    /* Caption 1/Reguler */
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 133.333% */
  }

  .textArea:focus {
    outline: none !important;
    border-width: 1px;
    border-style: solid;

    &:not(.error){
      border-color: $PRIMARY_MAIN;
    }
  }

  .addressIconWrapper {
    position: absolute;
    top: 5px;
    right: 5px;
    height: 30px;
    width: 30px;
    border-radius: 15px;
    background-color: $TEXTAREA_ICON_BG;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  button {
    cursor: pointer;
    background-color: transparent;
    border: none;
    color: $DANGER_MAIN;
  }
}