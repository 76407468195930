@import "../../../../../theme/variables.scss";

.container {
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  background-color: $NEUTRAL_10;
  max-width: 100vw;
  width: 436px;
  filter: drop-shadow(0px 4px 9px rgba(0, 0, 0, 0.12));
  gap: 24px;

  .header {
    padding: 16px 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(217, 234, 255, 1);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .iconWrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    padding: 0 24px;
    text-align: center;

    .title {
      font-size: 20px;
      font-weight: 600;
      color: $NEUTRAL_100;
    }

    .text {
      font-size: 14px;
      font-weight: 400;
      color: $NEUTRAL_60;
    }
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px 24px 24px;
    gap: 8px;

    .primaryButton {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $DANGER_MAIN;
      padding: 10px 0;
      flex: 1;
      border-radius: 8px;
      border: 1px solid $DANGER_MAIN;
      cursor: pointer;

      .text {
        font-size: 14px;
        font-weight: 500;
        color: $NEUTRAL_10;
      }
    }

    .primaryButton:hover {
      background-color: #d42701;
    }

    .secondaryButton {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $NEUTRAL_10;
      padding: 10px 0;
      border-radius: 8px;
      border: 1px solid $NEUTRAL_60;
      cursor: pointer;

      .text {
        font-size: 14px;
        font-weight: 500;
        color: $NEUTRAL_90;
      }
    }

    .secondaryButton:hover {
      background-color: $NEUTRAL_30;
    }
  }
}

@media (width <= 768px) {
  .container {
    width: 100%;
  }
}
