@import "../../../../../../../theme/variables.scss";

.container {
  padding: 30px;
  padding-bottom: 10px;

  .controlHeader {
    width: 100%;
    height: 46px;
    padding-inline: 30px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    padding-bottom: 10px;
  }

  .buttonWrapper {
    height: 36px;
    min-width: 104px;
  }
}

.breadcrumbWrapper {
  width: 100%;
  padding-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
  background-color: $NEUTRAL_LIGHTER;

  >span {
    background-color: $NEUTRAL_LIGHTEST;
    width: 40px;
    height: 40px;
    display: grid;
    place-items: center;
    border-radius: 50%;
    cursor: pointer;
  }

  .breadcrumbText {
    font-weight: 500;
    font-size: 16px !important;
    line-height: 20px;
    color: $NEUTRAL_60;
    cursor: pointer;
  }

  .breadcrumbText2 {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: $NEUTRAL_100;
  }
}

.filterWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;

  .searchWrapper {
    height: 40px;
    width: 356px;
  }

  .dropdownWrapper {
    width: 220px;
  }

  // >*div:first-child {
  //   height: 40px;
  // }

}

@media screen and (max-width: 768px) {
  .container {
    padding: 18px;
    padding-bottom: 8px; 
    
    .breadcrumbWrapper {
      padding-bottom: 10px;
    }
  }
  
}

@media screen and (max-width: 620px) {
  .filterWrapper {
    // flex-direction: column;

    .dropdownWrapper {
      width: 150px;
      flex-shrink: 0;
    }
  }
}
@media screen and (max-width: 480px) {
  .filterWrapper {
    flex-direction: column;
    gap: 8px;

    .dropdownWrapper, .searchWrapper {
      width: 100%;
    }
  }
}

