@import "../../../../../../../../../theme/variables.scss";

.container {
  padding: 16px;
  border-radius: 8px;
  border: 1px solid $PLATINUM_SOLID;

  .top {
    display: flex;
    gap: 16px;

    > * {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      gap: 4px;

      span {
        font-family: "Poppins";
        font-style: normal;

        &:first-child {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: $NEUTRAL_60;
        }

        &:last-child {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: $NEUTRAL_100;
        }
      }
    }
  }

  .bottom {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    padding-block: 16px;

    > * {
      flex-grow: 1;
      background: #f5f5f5;
      border-radius: 6px;
      padding: 16px;

      h5 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 140%;
        color: $NEUTRAL_100;
        padding-bottom: 2px;
      }

      .stacked {
        padding-top: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
          font-family: "Poppins";
          font-style: normal;
          font-size: 14px;
          line-height: 20px;
          color: $NEUTRAL_DARKEST;

          &:first-child {
            font-weight: 400;
          }

          &:last-child {
            font-weight: 500;
          }
        }
      }
    }
  }

  .qualification {
    h5 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      color: $NEUTRAL_100;
      padding-bottom: 8px;
    }

    .card {
      border-radius: 8px;
      border: 1px solid $NEUTRAL_30;
      overflow: hidden;

      .stackedHead {
        display: flex;
        align-items: center;
        padding: 12px 16px;
        gap: 16px;
        background-color: $NEUTRAL_20;

        > * {
          width: 50%;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: $NEUTRAL_100;
        }
      }

      .body {
        padding: 16px;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .stacked {
          display: flex;
          align-items: center;
          gap: 16px;

          > span {
            width: 50%;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: $NEUTRAL_100;

            &:nth-child(2) {
              font-weight: 500;
            }
          }
        }

        .NoQualification {
          text-align: center;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: $NEUTRAL_100;
        }
      }
    }
  }

  .Note {
    display: block;
    padding-top: 16px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $DANGER_MAIN;
  }
}

@media screen and (max-width: 768px) {
  .container {
    .top {
      display: flex;
      flex-direction: column;
      gap: 8px;

      > * {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        gap: 4px;

        span {
          font-family: "Poppins";
          font-style: normal;

          &:first-child {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: $NEUTRAL_60;
          }

          &:last-child {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: $NEUTRAL_100;
          }
        }
      }
    }

    .bottom {
      display: grid;
      grid-template-columns: 1fr;
      gap: 16px;

      > * {
        flex-grow: 1;
        background: #f5f5f5;
        border-radius: 6px;
        padding: 16px;

        h5 {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 140%;
          color: $NEUTRAL_100;
          padding-bottom: 2px;
        }

        .stacked {
          padding-top: 8px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          span {
            font-family: "Poppins";
            font-style: normal;
            font-size: 14px;
            line-height: 20px;
            color: $NEUTRAL_DARKEST;

            &:first-child {
              font-weight: 400;
            }

            &:last-child {
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}
