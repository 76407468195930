@import "../../../../../../../../theme/variables.scss";

.container {
  padding: 16px;
  background-color: $NEUTRAL_20;
  border-radius: 8px;

  h4 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500 !important;
    font-size: 14px;
    line-height: 20px;
    color: $NEUTRAL_100;
    padding-bottom: 8px;
  }

  .contactsWrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .each {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 6px 10px;
      background-color: $NEUTRAL_10;
      border: 1px solid $NEUTRAL_40;
      border-radius: 8px;

      > span {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $NEUTRAL_100;
      }
    }
  }
}
