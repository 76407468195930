.jobSeekersTestimoniesContainerCarousel {
  .slick-list {
    height: 200px;
  }

  .slick-center {
    > div {
      > div {
        padding-inline: 24px;
        > div {
          background: #f5f7fe !important;
        }
      }
    }
    // padding-left: 24px;
    // margin-right: 24px;
    .jobSeekersTestimoniesContainerTestimonies {
      -webkit-line-clamp: 1000 !important;
    }
  }

  > :first-child {
    > :last-child {
      display: none !important;
    }
  }
}

@media only screen and (max-width: 768px) {
  .jobSeekersTestimoniesContainerCarousel {
    .slick-list {
      height: 250px;
    }

    .slick-center {
      > div {
        > div {
          padding-inline: 16px;
        }
      }
    }
  }
}

@media only screen and (max-width: 368px) {
  .jobSeekersTestimoniesContainerCarousel {
    .slick-list {
      height: 300px;
    }
  }
}
