@import "../../../../../theme/variables.scss";

.Main {
  background: #f5f5f5;
  // height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  padding: 32px;

  .Container {
    background: $NEUTRAL_10;
    border-radius: 12px;
    width: 637px;

    .Headers {
      background: #001e58;
      padding: 24px 24px 16px 24px;
      display: flex;
      color: $NEUTRAL_10;
      gap: 16px;
      align-items: center;
      border-radius: 12px 12px 0 0;

      >p {
        font-weight: 600;
        font-size: 20px;
      }

      .Logo {
        background: $NEUTRAL_10;
        border-radius: 50%;

        >img {
          margin: 9px 15px;
          width: 26px;
          height: 38px;
        }
      }
    }

    .Content {
      padding: 24px;
      display: flex;
      flex-direction: column;
      gap: 24px;

      .ContentText {
        &> :first-child {
          text-align: center;
          font-weight: 600;
          font-size: 24px;
          margin-bottom: 10px;
        }

        &> :last-child {
          text-align: center;
          font-weight: 400;
          font-size: 14px;

          >span {
            font-weight: 600;
          }
        }
      }

      .ContentDetails {
        display: flex;
        flex-direction: column;
        gap: 8px;

        >p {
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          color: $NEUTRAL_90;
        }

        .DetailsWrapper {
          padding: 16px;
          border-radius: 8px;
          border: 1px solid $NEUTRAL-40;

          >div {
            display: grid;
            grid-template-columns: 100px auto 1fr;
            gap: 16px;

            >span {
              font-family: Poppins;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;
              color: $NEUTRAL_90;

              &:last-child {
                font-weight: 600;
              }
            }
          }
        }
      }

      .ContentFile {
        display: flex;
        flex-direction: column;
        gap: 8px;

        >p {
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          color: $NEUTRAL_90;
        }

        .File {
          display: flex;
          padding: 16px;
          justify-content: space-between;
          align-items: center;
          border: 1px solid #ededed;
          border-radius: 8px;
          gap: 8px;
          cursor: pointer;

          &> :last-child {
            cursor: pointer;
          }

          .FileWrapper {
            display: flex;
            align-items: center;
            gap: 8px;
          }
        }
      }

      .DownloadCard {
        background-color: #D8FBFF;
        padding: 16px;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;

        .DownloadTitle {
          width: 326px;
          display: flex;
          flex-direction: column;
          gap: 4px;
          text-align: center;

          >h4 {
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            color: #120071;
          }

          >span {
            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            color: #120071;
          }
        }

        .Downloads {
          width: 100%;
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 8px;

          .Button {
            background-color: $NEUTRAL_100;
            color: $NEUTRAL-10;
            border-radius: 8px;
            padding: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
            cursor: pointer;

            &:hover {
              background-color: #120071;

              >span {
                font-weight: 800;
                font-size: 15;
              }
            }

            >img {
              height: 24px;
              object-fit: contain;
            }

            >span {
              font-family: Poppins;
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: 20px;
            }
          }
        }
      }

      .ContentFooter {
        &> :first-child {
          text-align: center;
          font-weight: 600;
          font-size: 16px;
        }

        .ContactWrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 24px;
          margin-top: 16px;

          .Contact {
            display: flex;
            align-items: center;
            gap: 8px;
          }
        }
      }
    }
  }
}

@media (width <=768px) {
  .Main {
    align-items: start;
    padding: 20px 16px;

    .Container {
      .Headers {
        >p {
          font-size: 16px !important;
        }

        .Logo {
          width: 32px !important;
          height: 32px !important;
          display: flex;
          justify-content: center;
          align-items: center;

          >img {
            width: 15px !important;
            height: 21px !important;
          }
        }
      }

      .ContactWrapper {
        flex-direction: column;
        align-items: start;
        gap: 8px !important;
      }

      .Content {
        padding: 16px !important;
      }

      .ContentText {
        &>:first-child {
          font-size: 16px !important;

        }
      }
    }
  }
}