@import "../../../../../../theme/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;

  .information {
    width: 100%;
    display: flex;
    background-color: $PRIMARY_SOFT;
    padding: 8px;
    gap: 8px;
    border-radius: 8px;
    align-items: center;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
  }

  .dateWrapper {
    background-color: $PRIMARY-SURFACE;
    padding: 16px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    >h4 {
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
    }

    .field {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-areas:
        "one one"
        "two three"
      ;
      row-gap: 16px;
      column-gap: 8px;

      >* {
        &:first-child {
          grid-area: one;
        }

        &:nth-child(2) {
          grid-area: two;
        }

        &:last-child {
          grid-area: three;
        }
      }
    }
  }
}