@import "../../../../../../../theme/variables.scss";

.container {
  padding: 24px 30px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;

  .autoScroll {
    display: hidden;
    position: absolute;
    top: 0;
  }

  .emptyCard {
    background-color: $NEUTRAL_10;
    padding: 16px;
    border-radius: 8px;
    text-align: center;
    color: $NEUTRAL_60;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 500;
  }

  .schemeCard {
    background-color: $NEUTRAL_10;
    padding: 16px;
    border-radius: 8px;


    .top {
      display: flex;
      align-items: center;
      gap: 25px;
      padding-bottom: 16px;
      position: relative;

      .left {
        flex-shrink: 0;

        .icon {
          color: $NEUTRAL_70;
        }
      }

      .middle {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        padding-right: 80px;

        h5 {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 140%;
          color: $NEUTRAL_100;
          padding-bottom: 4px;
        }

        >span {
          font-family: 'Poppins';
          font-style: normal;
          font-size: 14px;
          line-height: 20px;

          &:nth-child(2) {
            font-weight: 500;
            padding-bottom: 2px;
            color: $NEUTRAL_100;
          }

          &:last-child {
            padding-top: 4px;
            color: $NEUTRAL_60;
          }
        }

        .location {
          display: flex;
          align-items: center;
          gap: 4px;
          color: $PRIMARY_MAIN;
          padding-bottom: 8px;

          >*:first-child {
            flex-shrink: 0;
          }

          span {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;

          }
        }
      }



      .status {
        position: absolute;
        top: 0;
        right: 0;
        padding: 8px 16px;
        border-radius: 8px;

        span {
          font-size: 14px;
          font-family: Poppins;
          font-weight: 500;
          line-height: 20px;
        }

        &.sent {
          background-color: $ACTIVE_SURFACE;

          span {
            color: $ACTIVE_MAIN;
          }
        }

        &.draft {
          background-color: $PRIMARY_SURFACE;

          span {
            color: $PRIMARY_MAIN;
          }
        }

        &.incomplete {
          background-color: #F3EEE0;

          span {
            color: #E9B62F;
          }
        }
      }
    }

    .bottom {
      display: flex;
      gap: 8px;
      align-items: center;

      button {
        padding: 8px 12px;
        border-radius: 8px;
        border: 1px solid $PRIMARY_MAIN;
        background-color: transparent;
        display: flex;
        align-items: center;
        gap: 4px;
        color: $PRIMARY_MAIN;

        span {
          text-align: center;
          font-size: 14px;
          font-family: Poppins;
          line-height: 20px;
        }
      }

      .seeDetail {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        &.hide {
          z-index: -4;
          display: none !important;
        }



        button {
          background-color: transparent;
          border: none;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: $PRIMARY_MAIN;
          padding: 0;
          border-radius: 0;
        }


      }
    }

  }
}

@media screen and (max-width: 768px) {
  .container {
    padding: 16px 24px;

    .schemeCard {

      .top {
        .left {
          height: 100%;
          position: relative;
        }
      }

      .bottom {
        // flex-direction: column;

        // >* {
        //   width: 100%;
        // }

        span {
          display: none;
        }
      }


    }
  }
}