@import "../../../../theme/variables.scss";

.container {
  background-color: $NEUTRAL_20;
  width: 100%;
  min-height: calc(100% - 70px);
  display: flex;
  flex-direction: column;

  .header {
    padding: 32px 30px 16px 30px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    border-bottom: 1px solid $NEUTRAL_40;
    position: sticky;
    top: 70px;
    z-index: 3;
    background-color: $NEUTRAL_20;

    .title {
      font-size: 38px;
      font-weight: 700;
      color: #000;
      display: none;
    }

    .sortSection {
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: 100%;

      .top {
        display: flex;
        justify-content: space-between;
        width: inherit;
        gap: 16px;

        .searchbarWrapper {
          width: 35%;
          height: 40px;
        }

        .button {
          width: fit-content;
          background-color: $PRIMARY_MAIN;
          border-radius: 8px;
          display: flex;
          gap: 4px;
          padding: 10px 12px;
          cursor: pointer;
          transition: 200ms;
          align-items: center;

          .icon {
            color: $NEUTRAL_10;
          }

          .buttonText {
            font-size: 14px;
            font-weight: 700;
            color: $NEUTRAL_10;
          }
        }

        .button:hover {
          background-color: #125eb9;
          transition: 200ms;
        }
      }

      .bottom {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 16px;

        .sortWrapper {
          display: flex;
          gap: 16px;
          width: 70%;

          .sort {
            display: flex;
            gap: 8px;
            width: 50%;

            .dropdownWrapper,
            .datepickerWrapper {
              width: 50%;
            }
          }
        }

        .pagination {
          display: flex;
          gap: 16px;
          align-items: center;
          width: 30%;
          justify-content: flex-end;

          .paginationText {
            font-size: 14px;
            font-weight: 400;
            color: #0a0a0a;
          }

          .mobilePaginationText {
            display: none;
          }

          .arrowsWrapper {
            display: flex;
            gap: 8px;

            .arrow {
              height: 40px;
              width: 40px;
              border-radius: 50%;
              border: 1px solid $NEUTRAL_40;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: $NEUTRAL_10;
              cursor: pointer;

              .icon {
                color: #0a0a0a;
              }
            }

            .arrow:hover {
              background-color: $NEUTRAL_30;
            }
          }
        }
      }
    }
  }

  .body {
    padding: 16px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    // justify-content: space-evenly;
    gap: 16px;
  }
}

@media (width <= 1040px) {
  .container {
    .header {
      .sortSection {
        .top {
          .searchbarWrapper {
            width: 60%;
          }
        }

        .bottom {
          flex-direction: column;
          gap: 16px;

          .sortWrapper {
            flex-direction: column;
            width: 100%;

            .sort {
              width: 100%;
            }
          }

          .pagination {
            width: 100%;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;

            .paginationText {
              display: none;
            }

            .arrowsWrapper {
              justify-content: space-between;
              width: 100%;

              .mobilePaginationText {
                font-size: 14px;
                font-weight: 400;
                color: #0a0a0a;
                display: flex;
                align-self: center;
              }
            }
          }
        }
      }
    }

    .body {
      justify-content: center;
    }
  }
}

@media (width <= 768px) {
  .container {
    .header {
      padding: 16px;
      gap: 16px;

      .title {
        display: block;
      }

      .sortSection {
        .top {
          flex-direction: column;

          .searchbarWrapper {
            width: 100%;
          }

          .button {
            width: 100%;
            justify-content: center;
            align-items: center;
          }
        }

        // .bottom {
        //   flex-direction: column;
        //   gap: 16px;

        //   .sortWrapper {
        //     flex-direction: column;
        //     width: 100%;
        //   }

        //   .pagination {
        //     width: 100%;
        //     flex-direction: column;
        //     align-items: center;
        //     justify-content: space-between;

        //     .paginationText {
        //       display: none;
        //     }

        //     .arrowsWrapper {
        //       justify-content: space-between;
        //       width: 100%;

        //       .mobilePaginationText {
        //         font-size: 14px;
        //         font-weight: 400;
        //         color: #0a0a0a;
        //         display: flex;
        //         align-self: center;
        //       }
        //     }
        //   }
        // }
      }
    }

    .body {
      padding: 16px;
    }
  }
}

@media (width <= 364px) {
  .container {
    .header {
      .sortSection {
        .bottom {
          .sortWrapper {
            .sort {
              flex-direction: column;

              .dropdownWrapper,
              .datepickerWrapper {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
