@import "../../../../../theme/variables.scss";

.container {
  width: 100%;
  height: calc(100% - 70px);
  background-color: $NEUTRAL_BACKGROUND;
  z-index: 0;
  overflow-y: hidden;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;

  .topWrapper {
    // position: sticky;
    // top: 70;
    // z-index: 1;

   

    .info {
      background-color: #ecefff;
      padding: 8px 30px;
      display: flex;
      align-items: center;
      gap: 24px;

      img {
        width: 32px;
        aspect-ratio: 1;
      }

      .infoContent {
        display: flex;
        flex-direction: column;
        gap: 4px;

        span {
          font-family: "Poppins";
          font-style: normal;

          &:first-child {
            font-weight: 700;
            font-size: 20px;
            line-height: 150%;
            letter-spacing: 0.005em;
            color: $PRIMARY_MAIN;
          }

          &:last-child {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: $NEUTRAL_DARKEST;
          }
        }
      }
    }

 
  }

  .backNav {
    background-color: #fafafa;
    @include padding-inline(30, 18, 18px);
    @include padding-block(30, 18, 18px);
    display: flex;
    align-items: center;
    gap: 24px;
    border-bottom: 1px solid $NEUTRAL-40;

    >button {
      background-color: $NEUTRAL-10;
      width: 40px;
      aspect-ratio: 1;
      border-radius: 50%;
      display: grid;
      place-items: center;
      color: $PRIMARY_MAIN;
      border: none;
    }

    >div {
      display: flex;
      align-items: center;
      gap: 8px;
      
      >img {
        width: 24px;
        aspect-ratio: 1;
        border-radius: 50%;
      }

      >span {
        font-family: 'Poppins';
        font-size: 16px;
        font-weight: 500;
      }
    }
  }

  .content {
    z-index: 0;
    overflow: auto;
  }
}

@media screen and (max-width: 768px) {
  .header {
    padding: 16px !important;
    background-color: $NEUTRAL_BACKGROUND !important;

    > * {
      // height: 30px !important;

      &.searchbarWrapper {
        min-width: 100%;
      }

      &.buttonWrapperLong {
        display: none;
      }

      &.buttonWrapperMobile {
        display: block !important;
        position: absolute;
        z-index: 5;
        // to: 0;
      }
    }

    .info {
      .infoContent {
        span {
          &:first-child {
            font-size: 16px;
          }

          &:last-child {
            font-size: 13px;
          }
        }
      }
    }
  }
}
