@import "../../../../../../theme/variables.scss";

.container {
  position: relative;

  .header {
    width: 100%;
    height: 200px;
    position: relative;

    .image {
      background-color: #d5dce2 !important;
      width: 100%;
      height: 200px;
    }

    .logo {
      position: absolute;
      width: 127px;
      height: 97px;
      left: 30px;
      top: 40px;

      background: #e3e8ed;
      border-radius: 8px;
    }

    .name {
      position: absolute;
      width: 200px;
      height: 22px;
      left: 171px;
      top: 65px;
      background: $NEUTRAL_10;
    }

    .contact {
      position: absolute;
      width: 291px;
      height: 16px;
      left: 171px;
      top: 95px;
      background: $NEUTRAL_10;
    }
  }

  .content {
    padding-inline: 30px;
    margin-top: -28px;
    position: relative;

    .profile {
      // position: relative;
      width: 100%;
      background: $NEUTRAL_10;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      gap: 21px;
      padding: 24px;

      .stacked {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 8px;

        .name {
          width: 94px;
          height: 16px;
          background: #eaecf2;
        }

        .nameContent {
          width: 307px;
          height: 16px;
          background: #eaecf2;
          flex-shrink: 1;
          max-width: 50%;
        }

        .website {
          width: 135px;
          height: 16px;
          background: #eaecf2;
        }

        .websiteContent {
          width: 278px;
          height: 16px;
          background: #eaecf2;
          flex-shrink: 1;
          max-width: 50%;
        }

        .phoneNumber {
          width: 176px;
          height: 16px;
          background: #eaecf2;
        }

        .phoneNumberContent {
          width: 110px;
          height: 16px;
          background: #eaecf2;
          flex-shrink: 1;
          max-width: 50%;
        }

        .address {
          width: 77px;
          height: 16px;
          background: #eaecf2;
        }

        .addressContent {
          width: 490px;
          height: 16px;
          background: #eaecf2;
          flex-shrink: 1;
          max-width: 50%;
        }

        .province {
          width: 73px;
          height: 16px;
          background: #eaecf2;
        }

        .provinceContent {
          width: 92px;
          height: 16px;
          background: #eaecf2;
          flex-shrink: 1;
          max-width: 50%;
        }

        .postal {
          width: 96px;
          height: 16px;
          background: #eaecf2;
        }

        .postalContent {
          width: 69px;
          height: 16px;
          background: #eaecf2;
          flex-shrink: 1;
          max-width: 50%;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .header {
    height: 107px !important;

    .image {
      height: 107px !important;
    }

    .logo {
      left: 18px !important;
      width: 71px !important;
      height: 54px !important;
      top: 16px !important;
    }

    .name {
      left: 100px !important;
      height: 18px !important;
      top: 25px !important;
    }

    .contact {
      left: 100px !important;
      height: 14px !important;
      top: 50px !important;
    }
  }

  .content {
    margin-top: -24px !important;
    padding-inline: 16px !important;

    .profile {
      padding: 16px !important;
    }
  }
}

@media screen and (max-width: 480px) {
  .container {
    .content {
      .profile {
        gap: 8px !important;

        .stacked {
          flex-direction: column;
          align-items: flex-start;
          gap: 4px !important;
        }
      }
    }
  }
}
