.container {
  height: calc(100% - 70px);
  overflow-y: hidden;

  .controllerWrapper {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 2;
  }

  .contentWrapper {
    overflow: auto!important;
    z-index: 0;

    .errorWrapper {
      height: 100%;
    }
  }
}

@media screen and (max-width: 768px) {
  
  .controllerWrapper{
    z-index: 1;
  }
}
