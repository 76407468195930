@import "../../../../../../../../../theme/variables.scss";

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;

  .each {
    background-color: $NEUTRAL_10;
    border-radius: 8px;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px 24px;
      border-bottom: 1px solid $NEUTRAL_40;

      h3 {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: $PRIMARY_MAIN;
      }

      .buttonsWrapper {
        display: flex;
        align-items: center;
        gap: 16px;

        button {
          height: 36px;
          padding-inline: 16px;
          border-radius: 8px;
          cursor: pointer;

          span {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
          }

          &.addButton {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 6px;
            border: 1px solid $PRIMARY_MAIN;
            color: $PRIMARY_MAIN;
            background-color: transparent;

            &.mobile {
              display: none;
            }
          }

          &.deleteButton {
            border: 1px solid $DANGER_MAIN;
            color: $DANGER_MAIN;
            background-color: transparent;
          }
        }
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      padding: 24px;
      gap: 16px;

      .positionsWrapperMobile {
        display: none;
      }

      .areaWorkEquipmentWrapper {
        padding: 16px;
        background-color: $NEUTRAL_20;
        border-radius: 8px;

        .boxHeader {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-wrap: wrap;
          gap: 8px;

          p {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: $NEUTRAL_DARKEST;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .addButtonWrapper {
            display: flex;
            justify-content: flex-end;

            button {
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 6px;
              padding: 8px 12px;
              border: 1px solid $PRIMARY_MAIN;
              border-radius: 8px;
              color: $PRIMARY_MAIN;
              background-color: $NEUTRAL_10;
              cursor: pointer;

              &.mobile {
                display: none;
              }

              span {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
          }

          // button {
          //   display: flex;
          //   align-items: center;
          //   justify-content: center;
          //   gap: 6px;
          //   padding: 8px 12px;
          //   border: 1px solid $PRIMARY_MAIN;
          //   border-radius: 8px;
          //   color: $PRIMARY_MAIN;
          //   background-color: $NEUTRAL_10;
          //   cursor: pointer;

          //   &.mobile {
          //     display: none;
          //   }

          //   span {
          //     font-family: 'Poppins';
          //     font-style: normal;
          //     font-weight: 400;
          //     font-size: 14px;
          //     line-height: 20px;
          //   }
          // }
        }

        .equipmentsWrapper {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
          gap: 16px;
          padding-top: 16px;
        }
      }
    }
  }

  .buttonWrapper {
    width: 100%;
    height: 66px;

    &.mobile {
      display: none;
    }

    .buttonText {
      padding-inline-start: 12px;
    }
  }

  .modalWrapper {
    position: fixed;
    transition: all 0.35s ease-in-out !important;
    z-index: 4;
    background-color: transparent;
    left: 0;
    width: 100%;

    &.hideModal {
      bottom: -1000px;
    }

    &.ShowModal {
      bottom: 0;
    }
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    background-color: $NEUTRAL_OVERLAY;
    opacity: 0;
    z-index: 3;

    &.visible {
      right: 0;
      bottom: 0;
      opacity: 1;
      transition: opacity 0.4s;
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    .desktop {
      display: none !important;
    }

    .content {
      gap: 0 !important;
    }

    .each {
      .header {
        padding: 16px;

        .buttonsWrapper {
          gap: 8px;

          button {
            padding-inline: 8px;

            &.desktop {
              display: none;
            }

            &.mobile {
              display: flex !important;
            }
          }
        }
      }

      .content {
        padding: 16px 0;

        .positionsWrapperWeb {
          display: none;
        }

        .positionsWrapperMobile {
          display: block;
          padding-bottom: 16px;
          z-index: 0 !important;

          .eachJobWrapper {
            padding-inline: 10px;
            padding-bottom: 8px;
          }

          .SingleJobWrapper {
            padding-inline: 10px;
            padding-bottom: 0 !important;
          }
        }

        .areaWorkEquipmentWrapper {
          margin-top: 32px;
          padding: 8px;
          margin-inline: 10px;

          &.Single {
            margin-top: 0 !important;
          }

          p {
            font-size: 14px;
          }

          button {
            &.mobile {
              display: flex !important;
            }

            &.desktop {
              display: none;
            }
          }

          .equipmentsWrapper {
            grid-template-columns: 1fr;
          }
        }
      }
    }

    .buttonWrapper {
      height: 40px !important;

      &.mobile {
        display: block !important;
      }

      &.desktop {
        display: none;
      }
    }
  }
}
