@import "../../../../../../theme/variables.scss";

.container {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 17px;

  .status {
    padding: 4px 10px;
    border-radius: 40px;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 142.857%;
    border: 1px solid transparent;
    width: 100px;

    &.verified {
      background-color: $SUCCESS_SURFACE;
      border-color: $SUCCESS_MAIN;
      color: $SUCCESS_MAIN;
    }

    &.waiting {
      background-color: $ACTIVE_SURFACE;
      border-color: #ee962f;
      color: #ee962f;
    }
    &.expired {
      border: 1px solid var(--Danger-Main, #d42701);
      background: var(--Danger-Surface, rgba(240, 44, 1, 0.1));
      color: var(--Danger-Main, #d42701);
    }
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    position: relative;
    overflow: visible;

    > button {
      background-color: transparent;
      padding: 0;
      display: grid;
      place-items: center;
      border: none;
      width: 36px;
      aspect-ratio: 1;
      background-color: $NEUTRAL-10;
      border: 1px solid $NEUTRAL-30;
      border-radius: 4px;
    }

    .options {
      position: absolute;
      top: calc(100% + 4px);
      right: 0px;
      width: 100%;
      background-color: $NEUTRAL_10;
      border-radius: 8px;
      // overflow: hidden;
      box-shadow: 0px 0px 1px rgba(9, 30, 66, 0.31),
        0px 3px 5px rgba(9, 30, 66, 0.2);
      z-index: 1;
      padding-block: 4px;
      max-height: 200px;
      overflow-y: auto;
      width: 141px;

      > div {
        padding: 8px 12px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $NEUTRAL_90;
        display: flex;
        align-items: center;
        gap: 8px;

        &:hover {
          background-color: $PRIMARY_SURFACE_HOVER;
        }

        > * {
          &:last-child {
            flex-shrink: 0;
          }
        }

        > span {
          font-size: 14px;
          font-weight: 500;
          flex-grow: 1;
        }

        // &.proceedButton {
        //   background-color: $PRIMARY_MAIN;
        //   color: $NEUTRAL-10;
        //   padding: 12px;
        //   border-radius: 8px;
        //   font-family: Poppins;
        //   font-size: 14px;
        //   font-style: normal;
        //   font-weight: 400;
        //   line-height: 142.857%;
        // }
      }
    }
  }

  .header {
    display: flex;
    align-items: center;
    gap: 16px;
    flex-wrap: wrap;

    > h3 {
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      letter-spacing: 0.1px;
      color: $PRIMARY_MAIN;
      flex-grow: 1;
    }

    .searchbarWrapper {
      height: 44px;
      width: 356px;
      max-width: 100%;
    }
  }

  .contentWrapper {
    display: flex;
    flex-direction: column;

    .newInfo {
      background-color: #fffaef;
      border: 1px solid #e9b62f;
      border-radius: 8px 8px 0px 0px;
      padding: 9px 14px;
      display: flex;
      align-items: center;
      gap: 12px;

      > span {
        font-family: Poppins;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 153.846%;
        color: $NEUTRAL-90;
      }
    }

    .switcher {
      display: flex;
      align-items: flex-end;

      > div {
        display: flex;
        align-items: center;
        border-radius: 8px 8px 0px 0px;
        border-top: 1px solid $PRIMARY_SOFT;
        border-right: 1px solid $PRIMARY_SOFT;
        border-left: 1px solid $PRIMARY_SOFT;
        background: $PRIMARY_SURFACE;
        padding: 9px 10px;
        transition: background-color 0.3s ease;
        cursor: pointer;
        box-shadow: 2px 0px 2px 0px rgba(24, 108, 208, 0.25);

        > span {
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 142.857%;
          color: #5c9ce7;
        }

        &.active {
          background-color: $PRIMARY_MAIN;
          padding: 11px 10px;

          > span {
            color: $NEUTRAL-10;
          }
        }
      }
    }

    .content {
      // display: grid;
      // grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
      // gap: 16px;
      // padding-top: 16px;
      min-height: 300px;
      display: flex;
      flex-direction: column;
      border-radius: 0px 0px 8px 8px;
      border: 1px solid $NEUTRAL-40;

      > *:not(table):not(.paginationWrapper, .tableWrapper) {
        flex-grow: 1;
      }

      .tableWrapper {
        overflow: auto;
      }

      .emptyWrapper {
        flex-grow: 1;
        display: grid;
        place-items: center;
      }

      table {
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0;
        overflow: auto;

        thead {
          tr {
            th {
              padding: 16px 20px;
              font-family: Poppins;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 20px;
              border-bottom: 1px solid $NEUTRAL-40;
              white-space: nowrap;
            }
          }
        }

        tbody {
          .docNumber {
            display: flex;
            align-items: center;
            gap: 4px;
          }

          tr {
            cursor: pointer;

            &.odd {
              td {
                background-color: $PRIMARY_SURFACE !important;
              }
            }

            &.new {
              td {
                background-color: #fef8f0 !important;
              }
            }

            td {
              padding: 24px 20px;
              font-family: Poppins;
              font-size: 13px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;
              color: $NEUTRAL-90;

              .div {
                display: -webkit-box;
                line-clamp: 1;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
        }
      }

      .paginationWrapper {
        border-top: 1px solid $NEUTRAL_40;
      }
    }
  }
}
