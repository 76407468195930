@import "../../../../../../../theme/variables.scss";

.container {
  width: 100%;
  height: 100%;
  position: relative;

  .autoScroll {
    display: hidden;
    position: absolute;
    top: 0;
  }

  .header {
    display: flex;
    flex-direction: column;
    gap: 4px;

    h3 {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0.005em;
      color: $NEUTRAL_100;

      span {
        color: $DANGER_MAIN;
      }
    }

    span {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $NEUTRAL_70;
    }
  }

  .content {
    padding-top: 32px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 16px;

    .card {
      padding: 32px;
      background-color: $NEUTRAL_10;
      border: 1px solid $NEUTRAL_40;
      border-radius: 8px;
      cursor: pointer;
      transition: all 0.3s;
      position: relative;


      &:hover {
        border: 1px solid $PRIMARY_SOFT;
      }

      &.active {
        border: 1px solid $PRIMARY_MAIN;
      }

      .IconWrapper {
        position: absolute;
        right: 10px;
        top: 10px;
        color: $PRIMARY_MAIN;
        transition: all 0.3s;

        &.notSelected {
          opacity: 0;
        }

        &.selected {
          opacity: 1;
        }
      }

      .cardHeader {
        display: flex;
        flex-direction: column;
        gap: 16px;
        text-align: center;

        h4 {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 150%;
          text-align: center;
          letter-spacing: 0.005em;
          color: $NEUTRAL_DARKEST;
        }

        span {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: $NEUTRAL_70;
        }
      }

      .cardContent {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding-top: 32px;

        .rule {
          display: flex;
          gap: 16px;

          img {
            height: 18px;
            aspect-ratio: 1;
          }

          p {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            color: $DARK_CHARCOAL;
          }
        }
      }
    }
  }

  .buttonWrapper {
    padding-block: 24px;
    float: right;


    button {
      display: grid;
      place-items: center;
      width: 98px;
      height: 36px;
      background: $PRIMARY_MAIN;
      border: none;
      border-radius: 8px;
      color: $NEUTRAL_10;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      transition: all 0.3s;
      cursor: pointer;

      &:disabled {
        background-color: $PRIMARY_DISABLED;
      }

    }
  }
}

@media screen and (max-width: 768px) {
  .container {

    .header {
      gap: 2px;

      h3 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 140%;
      }
    }

    .content {
      padding-top: 16px;
      grid-template-columns: 1fr;
      gap: 16px;

      .card {
        padding: 16px;
      }
    }

    .buttonWrapper {
      width: 100%;

      button {
        width: 100%;
      }
    }
  }
}