@import "../../../../../../../../../theme/variables.scss";

.container {
  background-color: $NEUTRAL_10;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  position: fixed;
  left: 0;
  overflow: auto;

  padding-inline: 8%;
  padding-block: 5%;

  .burgerButton {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
      display: grid;
      place-items: center;
      background-color: transparent;
      border: none;
    }
  }

  .checkButton {
    background-color: transparent;
    border: none;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration-line: underline;
    color: $PRIMARY_MAIN;
  }

  .previewCVWrapper {
    position: static !important;
  }


  .introHeader {
    display: flex;
    align-items: center;
    gap: 8px;
    padding-bottom: 8px;

    >h5 {
      flex-grow: 1;
    }

    >button {
      display: none;
      place-items: center;
      background-color: transparent;
      border: none;
    }
  }

  .emailLink {
    color: $NEUTRAL_90;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    &:hover {
      color: $PRIMARY_MAIN;
    }
  }

  .profile {
    display: flex;
    align-items: center;
    gap: 8px;
    border: none;
    background-color: transparent;

    &:hover {
      color: $PRIMARY_MAIN !important;
    }

    img {
      height: 36px;
      aspect-ratio: 1;
      object-fit: contain;
      border-radius: 50%;
    }

    span {
      font-family: Poppins;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;


    }
  }



  .header {
    padding-bottom: 16px;
    border-bottom: 1px solid $NEUTRAL_40;

    >button {
      display: flex;
      align-items: center;
      gap: 8px;
      background-color: transparent;
      border: none;

      span {
        color: $NEUTRAL_70;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        /* 22.4px */
      }
    }

    .bottom {
      display: flex;
      align-items: center;
      padding-top: 8px;

      h1 {
        color: #000;
        font-family: Poppins;
        font-size: 26px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        flex-grow: 1;
      }

      .right {
        display: flex;
        align-items: center;
        gap: 16px;

        div {
          height: 52px;
          aspect-ratio: 1;
          border-radius: 50%;
          display: grid;
          place-items: center;
          background-color: #F3F5F7;

          img {
            width: 45%;
          }
        }

        h3 {
          font-family: Poppins;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%;
          /* 27px */
        }
      }
    }
  }

  .intro {
    padding-block: 24px;
    display: flex;
    gap: 16px;

    .left {
      flex-grow: 1;
      display: flex;
      align-items: center;

      .card {
        border: 1px solid $NEUTRAL_40;
        border-radius: 8px;
        display: flex;
        align-self: flex-start;
        padding-block: 16px;

        .each {
          padding-inline: 16px;
          width: 50%;
          display: flex;
          flex-direction: column;

          &:first-child {
            border-right: 1px solid $NEUTRAL_40;
          }

          h5 {
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
            // padding-bottom: 8px;
          }

          .content {
            display: flex;
            flex-direction: column;
            gap: 8px;
            padding: 8px 16px;
            border-radius: 8px;
            background-color: $NEUTRAL_20;
            flex-grow: 1;
            overflow: auto;

            .label {
              display: flex;
              align-items: center;
              gap: 10px;

              img {
                height: 20px;
                aspect-ratio: 1;
                object-fit: cover;
                border-radius: 50%;
                flex-shrink: 0;
              }

              .labelIcon {
                flex-shrink: 0;
              }



              span {
                color: $NEUTRAL_100;
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
                overflow-wrap: anywhere;
              }

            }
          }
        }
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 8px;

      .buttonWrapper {
        flex-grow: 1;

        button {
          display: flex;
          align-items: center;
          gap: 4px;
          background-color: $PRIMARY_MAIN;
          padding: 12px 16px;
          border: none;
          border-radius: 8px;
          color: $NEUTRAL_10;

          &:disabled {
            background-color: $PRIMARY_DISABLED;
          }

          span {
            text-align: center;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
          }

        }
      }

      .filterWrapper {
        display: flex;
        align-items: center;
        gap: 10px;

        >* {
          min-width: 220px;
        }
      }
    }


  }

}

@media screen and (max-width: 1024px) {

  .container {
    .intro {
      flex-direction: column;

      .left {

        .card {
          align-self: stretch;
          width: 100%;
          padding-block: 8px;

          .each {
            padding-inline: 8px;

            .content {
              padding: 8px 12px;
            }
          }
        }
      }

      .right {
        flex-direction: row-reverse;

        .filterWrapper {
          flex-grow: 1;
        }

        .buttonWrapper {
          display: flex;
          justify-content: flex-end;
        }
      }


    }
  }

}

@media screen and (max-width: 768px) {

  .container {
    padding-inline: 5%;

    .header {

      .bottom {
        h1 {
          h1 {
            font-size: 22px;
          }
        }

        .right {
          >div {
            height: 45px;
          }
        }
      }

      h3 {
        display: none;
      }

      .bottom {
        // flex-direction: column;
        gap: 8px;


      }
    }

    .intro {

      .right {
        flex-direction: column-reverse;

        >* {
          width: 100%;

          >* {
            flex-grow: 1;
            width: 50%;
          }

          button {
            justify-content: center;
          }
        }
      }
    }


  }
}

@media screen and (max-width: 480px) {
  .container {
    padding-inline: 0px;
    padding-top: 0px;
    padding-bottom: 100px;

    >* {
      padding-inline: 16px;
    }


    .header {
      padding-top: 50px;
      padding-bottom: 16px;
      position: sticky;
      top: 0;
      background-color: $NEUTRAL_10;

      .bottom {

        h1 {
          font-size: 16px;
        }

        .right {

          >div {
            height: 24px;
          }
        }
      }

    }


    .intro {

      .left {


        .card {
          flex-direction: column;
          width: 100%;
          gap: 8px;
          padding: 16px;

          .each {
            width: 100%;
            padding-inline: 0px;

            &:first-child {
              border-right: none;
              padding-bottom: 8px;
            }

            .introHeader {
              button {
                display: grid;
              }
            }

            .content {
              transition: all 0.3s ease-in-out;
              max-height: 0px;
              overflow: auto;
              opacity: 0;
              padding: 0px;

              &.show {
                display: flex !important;
                max-height: 100%;
                opacity: 1;
                padding: 8px;
              }
            }

          }
        }
      }

      .right {

        .filterWrapper {
          flex-direction: column;

          >* {
            width: 100%;
          }
        }
      }
    }

    .content {
      padding-inline: 0px;
    }

    .buttonWrapper {
      position: fixed;
      padding-bottom: 40px;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 16px;
      border-top: 1px solid $NEUTRAL_40;
      background-color: $NEUTRAL_10;
      z-index: 2;
    }
  }
}