@import "../../../../../theme/variables.scss";

.main {
  flex-grow: 1;
}

.stepperWrapper {
  overflow: auto;
}

.loading {
  width: 25px;
}

.container {
  display: flex;

  .noDoc {
    display: grid;
    place-items: center;
    height: 100%;
    position: relative;

    > span {
      color: $NEUTRAL_70;
    }

    > div {
      display: none;
    }
  }

  > * {
    height: 100%;
  }

  .left {
    width: 40%;
    border-right: 1px solid $NEUTRAL_30;
    display: flex;
    flex-direction: column;
    position: relative;
    height: calc(100vh - 82px - 32px);
    overflow: hidden;
    // padding-bottom: 0;

    .gradientback {
      position: sticky;
      bottom: 0px;
      left: 0px;
      width: 100%;
      height: 100px;
      background: linear-gradient(
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 1)
      );
    }

    .stickyTop {
      position: sticky;
      // top: calc(82px);
      top: 0;
      background-color: $NEUTRAL_10;

      .profile {
        padding: 16px;
        border-bottom: 1px solid $NEUTRAL_30;
        display: flex;
        align-items: center;
        gap: 12px;

        > img {
          height: 44.27px;
          aspect-ratio: 1;
          border-radius: 50%;
          object-fit: cover;
        }

        > div {
          display: flex;
          flex-direction: column;
          gap: 4px;
          flex-grow: 1;

          > span {
            &:first-child {
              font-family: Poppins;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 20px;
            }

            &:last-child {
              font-family: Poppins;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 16px;
              color: $NEUTRAL_60;
            }
          }
        }

        > button {
          place-items: center;
          color: $BS_PRIMARY_MAIN;
          background-color: transparent;
          border: none;
          display: flex;
          align-items: center;
          display: none;
        }
      }

      .detailsHeader {
        display: flex;
        align-items: center;
        padding: 16px;
        gap: 8px;
        border-bottom: 1px solid $NEUTRAL_30;

        > * {
          width: 50%;
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 140%;
        }
      }
    }

    .detailsContent {
      flex-grow: 1;
      padding: 16px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding-bottom: 16px;
      overflow-y: auto; /* Enable vertical scrolling */
      height: calc(100vh - 100px - 32px); /* Adjust height calculation */

      .perType {
        display: flex;
        flex-direction: column;
        gap: 4px;

        > span {
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 20px;
        }

        .list {
          .perDetail {
            display: flex;
            gap: 8px;

            > * {
              width: 50%;
              font-family: Poppins;
              font-size: 13px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;
              padding-block: 8px;

              &:first-child {
                color: $NEUTRAL_80;
              }
            }
          }
        }
      }
    }
  }

  .right {
    position: sticky;
    top: calc(82px);
    display: flex;
    flex-direction: column;
    width: 60%;
    height: calc(100vh - 85px - 32px);
    overflow: clip;
    padding: 0;

    > * {
      width: 100%;

      &.top {
        // width: 100%;
        border-bottom: 1px solid $NEUTRAL_30;
        padding: 8px 16px;
        display: flex;
        align-items: center;

        > * {
          width: 100%;
        }

        .profile {
          display: none;
        }
      }

      &.bottom {
        padding-top: 16px;
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow: auto;
        gap: 16px;
        position: relative;

        .previewWrapper {
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: 2;
          overflow: clip;
        }

        > * {
          padding-inline: 16px;
        }

        .docWrapper {
          display: flex;
          flex-direction: column;
          gap: 16px;
          overflow-x: clip;
          // padding: 16px;
          height: 100%;
          padding-bottom: 0;

          .mainDoc {
            flex-grow: 1;
            // overflow: hidden;
            // height: 20%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;
            background-color: $NEUTRAL_90;
            border-radius: 8px;
            padding-inline: 8px;
            overflow: clip;
            min-height: 300px;

            > img {
              // height: 100%;
              // width: 300px;
              max-height: 300px;
              max-width: 80%;
              object-fit: cover;
              transition: all 0.3s;
              // aspect-ratio: 4/5;
            }

            .imageButtons {
              border-radius: 2px;
              position: absolute;
              bottom: 8px;
              right: 8px;
              display: flex;
              align-items: center;
              gap: 8px;

              .button {
                height: 32px;
                aspect-ratio: 1;
                display: grid;
                place-items: center;
                border-radius: 2px;
                border: none;
                background-color: $NEUTRAL_10;
                cursor: pointer;

                > img {
                  width: 24px;
                  aspect-ratio: 1;
                  object-fit: contain;
                }
              }
            }

            > button {
              height: 40px;
              aspect-ratio: 1;
              border-radius: 50%;
              background-color: $NEUTRAL_10;
              display: grid;
              place-items: center;
              box-shadow: (0px 4px 9px rgba(0, 0, 0, 0.12));
              border: none;
              z-index: 1;
            }
          }

          .type {
            padding: 8px 16px;
            background-color: $NEUTRAL_100;
            border-radius: 8px;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 140%;
            color: $NEUTRAL_10;
            align-self: flex-start;
          }

          .sliderWrapper {
            width: 100%;
            // overflow-x: scroll;
            // display: flex;
            // flex-direction: column;
            // gap: 16px;

            .slider {
              display: flex;
              overflow-x: auto;
              gap: 16px;
              transition: all 0.3s;
              position: relative;

              .parameter {
                width: 1px;
                // overflow: hidden;
                opacity: 0;
              }

              .thumbnail {
                // width: 120px;
                flex-shrink: 0;
                height: auto;
                aspect-ratio: 0.75;
                overflow: clip;
                border-radius: 8px;
                height: 140px;
                // background-color: $NEUTRAL_20;
                // overflow: hidden;

                &.active {
                  border: 3px solid $PRIMARY_MAIN !important;
                }

                > img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  border-radius: 5px;
                }
              }

              .verticalGradient {
                position: sticky;
                // bottom: 0px;
                right: 0;
                width: 140px;
                height: 140px;
                background: linear-gradient(
                  rgba(255, 255, 255, 0),
                  rgba(255, 255, 255, 1)
                );
                transform: rotate(-90deg);
                display: block;
                flex-shrink: 0;

                &.v2 {
                  left: 0;
                  transform: rotate(90deg);
                }
              }
            }
          }
        }

        .DocumentContainer {
          height: 100%;
          display: grid;
          grid-template-columns: 1fr 150px;
          gap: 16px;
          overflow: hidden;

          .pdfWrapper {
            width: 100%;
            height: 100%;

            embed {
              width: 100%;
              height: 100%;
              border-radius: 8px;
            }
          }

          .SignContainer {
            height: 100%;
            display: flex;
            flex-direction: column;
            gap: 22px;
            overflow: auto;

            .SignWrapper {
              height: 100%;

              & > :first-child {
                font-weight: 600;
                font-size: 14px;
              }

              .Sign {
                width: 100%;
                height: 145px;
                border: 1px solid #ededed;
                background: $NEUTRAL_10;
                border-radius: 8px;
                display: flex;
                justify-content: center;
                align-items: center;

                & > img {
                  max-width: 80%;
                  height: 80%;
                }
              }

              & > img {
                width: 100%;
                // height: 100%;
              }
            }
          }
        }

        .contractWrapper {
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;

          h4 {
            font-family: Poppins;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 150%;
            letter-spacing: 0.1px;
            padding-top: 16px;
            padding-bottom: 8px;
          }

          > span {
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            color: $NEUTRAL_60;
          }
        }
      }
    }
  }
}

.remarkBox {
  // border-radius: 0px 0px 0px 12px;
  border-top: 1px solid var(--Neutral-Stroke, #ededed);
  background: #ffe9e4;
  position: sticky;
  // margin-bottom: 0;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px 16px;
  .contentNotes {
    display: flex;
    flex-direction: column;
    gap: 4px;
    .textP {
      color: var(--Neutral-90, #404040);

      /* Ception 2/Regular */
      // font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 133.333% */
    }

    .detailText {
      color: var(--Primary-Main, #1571de);
      // font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
      text-decoration-line: underline;
      cursor: pointer;
    }
  }
  .titleNotes {
    display: flex;
    align-items: center;
    gap: 8px;
    .catatan {
      color: #000;

      /* Text M/SemiBold */
      // font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px; /* 142.857% */
    }
  }
}

.contractContainer {
  // height: calc(100vh - 85px - 32px);
  // flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 85px - 32px);
  overflow: hidden;

  .imagesContainer {
    position: fixed;
    z-index: 1;
    top: 80px;
    left: 0;
    width: 100vw;
    height: calc(100vh - 60px);
    overflow: auto;
    display: flex;
    flex-direction: column;
    background-color: $NEUTRAL_20;
    padding: 16px;
    gap: 8px;

    .button {
      position: relative;
      width: 100%;
      text-align: center;
      padding-bottom: 8px;

      > *:first-child {
        position: absolute;
        left: 0;
        top: -2px;
        background-color: transparent;
        border: none;
        display: grid;
        place-items: center;
      }

      > span {
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 26px;
      }
    }
  }

  .topHeaderContract {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-bottom: 1px solid $NEUTRAL_30;
    padding-bottom: 16px;
    .errorSubmit {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      > span {
        font-size: 12px;
        color: red;
      }
    }
  }

  .header {
    display: flex;
    align-items: center;

    .left {
      flex-grow: 1;
    }

    .right {
      display: flex;
      align-items: center;
      gap: 8px;
      .button {
        display: flex;
        padding: 10px 12px;
        justify-content: center;
        align-items: flex-start;
        gap: 4px;
        border-radius: 8px;
        border: 1px solid $PRIMARY_MAIN;
        background-color: $NEUTRAL_10;
        color: $PRIMARY_MAIN;
        > span {
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
        }

        &.disabledButton {
          background-color: #f4f4f4;
          border: none;
          color: #b3acac;
        }
      }

      .buttonSave {
        display: flex;
        padding: 10px 12px;
        justify-content: center;
        align-items: flex-start;
        gap: 4px;
        border-radius: 8px;
        border: 1px solid $PRIMARY_MAIN;
        background-color: $PRIMARY_MAIN;
        color: $NEUTRAL_10;
        > span {
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
        }

        &.disabledButton {
          background-color: #f4f4f4;
          border: none;
          color: #b3acac;
        }
      }

      // > button {
      //   display: flex;
      //   padding: 10px 12px;
      //   justify-content: center;
      //   align-items: flex-start;
      //   gap: 4px;
      //   border-radius: 8px;

      //   > span {
      //     font-family: Poppins;
      //     font-size: 14px;
      //     font-style: normal;
      //     font-weight: 500;
      //     line-height: 20px;
      //   }

      //   &:last-child {
      //     border: none;
      //     background-color: $PRIMARY_MAIN;
      //     color: $NEUTRAL_10;

      //     &:disabled {
      //       background-color: $PRIMARY_DISABLED;
      //     }
      //   }
      // }
    }
  }

  .content {
    width: 100%;
    display: flex;
    align-items: center;
    // height: 50vh;
    flex-grow: 1;
    // background-color: yellow;

    > * {
      width: 50%;
      height: 100%;
      // height: calc(100vh - 85px - 64px);
      // overflow: hidden;

      &.left {
        border-right: 1px solid $NEUTRAL_30;
        overflow: auto;
        display: flex;
        flex-direction: column;

        .top {
          border-bottom: 1px solid $NEUTRAL_30;
          padding: 16px;
          display: flex;
          flex-direction: column;

          > h4 {
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 140%;
            padding-bottom: 16px;
          }

          .navs {
            display: flex;
            align-items: center;
            justify-content: space-between;

            > button {
              background-color: transparent;
              border: none;
              font-family: Poppins;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 20px;
              color: $BS_PRIMARY_MAIN;

              &:disabled {
                color: $NEUTRAL_50;
              }
            }
          }
        }

        .bottom {
          height: 50vh;
          flex-grow: 1;
          overflow: auto;
          display: flex;
          flex-direction: column;
          height: 20vh;

          > div {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
          }
        }
      }
    }

    .right {
      padding: 16px;

      embed {
        border-radius: 8px;
        width: 100%;
        height: 100%;
      }

      .pdfWrapper {
        width: 100%;
        height: 100%;
      }

      .loadingWrapper {
        height: 100%;
        display: grid;
        place-items: center;
      }

      .contractWrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        h4 {
          font-family: Poppins;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 150%;
          letter-spacing: 0.1px;
          padding-top: 16px;
          padding-bottom: 8px;
        }

        > span {
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          color: $NEUTRAL_60;
        }
      }
    }
  }
}

.nameClientBox {
  display: flex;
  align-items: center;
  gap: 8px;
  border-bottom: 1px solid var(--Neutral-Stroke, #ededed);
  background: var(--Neutral-20, #fafafa);
  padding: 8px 16px;

  .nameClient {
    display: flex;
    flex-direction: column;
    gap: 4px;
    .titleClient {
      color: var(--Neutral-60, #9e9e9e);

      /* Ception 2/Regular */
      // font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 133.333% */
    }
    .nameText {
      color: var(--Neutral-90, #404040);

      /* Text M/Medium */
      // font-family: Poppins;
      font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
    }
  }
}

.searchAdditionalBox {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  border-bottom: 1px solid var(--Neutral-Stroke, #ededed);
  background: var(--Neutral-20, #fafafa);
  .searchWrapper {
    width: 100%;
  }
}

@media screen and (max-width: 1024px) {
  .container {
    .right {
      .top {
        height: auto !important;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    flex-direction: column;

    > * {
      width: 100% !important;
      height: calc(100vh - 85px) !important;
    }

    .left {
      .stickyTop {
        .profile {
          > button {
            place-items: center;
            color: $BS_PRIMARY_MAIN;
            background-color: transparent;
            border: none;
            display: flex;
            align-items: center;
          }
        }
      }
      .profile {
        > button {
          display: grid !important;
        }
      }
    }

    .right {
      position: static !important;
      overflow: auto;
      top: 100px;
      // height: auto !important;
      // height: 100%!important;

      .top {
        display: flex;
        flex-direction: column;
        padding: 0px;
        position: sticky;
        top: 0;
        z-index: 2;
        background-color: $NEUTRAL_10;

        > * {
          width: 100%;
          padding: 16px;
        }

        .profile {
          padding: 16px;
          border-bottom: 1px solid $NEUTRAL_30;
          display: flex;
          align-items: center;
          gap: 12px;

          > img {
            height: 44.27px;
            aspect-ratio: 1;
            border-radius: 50%;
            object-fit: cover;
          }

          > div {
            display: flex;
            flex-direction: column;
            gap: 4px;
            flex-grow: 1;

            > span {
              &:first-child {
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
              }

              &:last-child {
                font-family: Poppins;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                color: $NEUTRAL_60;
              }
            }
          }

          > button {
            display: flex;
            place-items: center;
            color: $BS_PRIMARY_MAIN;
            background-color: transparent;
            border: none;
          }
        }
      }

      .bottom {
        height: 100%;
        display: flex;
        flex-direction: column;

        .docWrapper {
          .mainDoc {
            justify-content: center;

            .controlButton {
              position: absolute;

              &.prev {
                left: 0;
              }

              &.next {
                right: 0;
              }
            }

            > img {
              max-height: 50vh !important;
              max-width: 100% !important;
              height: 300px;
            }

            .type {
              left: 0;
            }
          }
        }
      }
    }

    .hide {
      display: none;
    }
  }

  .contractContainer {
    height: calc(100vh - 85px);

    .loadingWrapper {
      height: calc(100vh - 85px);
      display: grid;
      place-items: center;
    }

    .header {
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
    }

    .content {
      .left {
        width: 100%;
        border-right: none;

        .top {
          padding: 8px 0px;

          > *:not(:nth-child(2)) {
            padding-inline: 16px;
          }
        }
      }

      .right {
        // display: none;
        position: fixed;
        right: -1000px;
      }
    }
  }

  .verticalGradient {
    display: none !important;
  }
}

@media screen and (max-width: 480px) {
  .contractContainer {
    .hideOnMobile {
      display: none;
    }
  }
}
