@import "../../../../../theme/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  padding: 0 24px 32px 24px;
  width: 100%;
  gap: 24px;

  .header {
    display: flex;
    gap: 24px;
    align-items: center;

    .profilePicture {
      height: 75px;
      width: 75px;
      background-color: #E6E6E6;
    }

    .headerContent {
      display: flex;
      flex-direction: column;
      gap: 12px;
      width: 80%;
      
      .title {
        height: 14px;
        width: 100%;
        background-color: #E6E6E6;
      }

      .information {
        height: 14px;
        width: 10%;
        background-color: #E6E6E6;
      }
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .content {
      display: flex;
      gap: 16px;
      
      .contentLeft {
        height: 14px;
        width: 200px;
      }

      .contentRight {
        height: 14px;
        width: 500px;
      }
    }
  }
}