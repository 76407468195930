.bodyPipeline {
  .header {
    display: flex;
    flex-direction: column;
    padding: 16px 30px;
    border-bottom: 1px solid var(--Color-Neutal-Neutral-40, #e0e0e0);
    background: var(--Color-Neutal-Neutral-20, #f5f5f5);
    position: sticky;
    top: 70px;
    z-index: 2;
    .pipelineText {
      display: none;
    }
    .searchPipeline {
      width: 36%;
      height: 40px;
    }
  }

  .contentPipeline {
    padding: 16px 30px;
    .pipelineTableBox {
      border-radius: 8px;
      border: 1px solid var(--Color-Neutal-Neutral-40, #e0e0e0);
      background: var(--Color-Neutal-Neutral-10, #fff);
      height: 100%;
      .Headertable {
        padding: 16px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        .filterBox {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 16px;
        }

        .filterType {
          display: flex;
          align-items: center;
          gap: 8px;
          .buttonType {
            border-radius: 100px;
            border: 1px solid var(--Neutral-40, #e0e0e0);
            background: var(--Neutral-10, #fff);
            display: flex;
            padding: 12px 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));
            text-align: center;

            /* Text M/Medium */
            // font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 142.857% */
            cursor: pointer;
            &.typeActive {
              border: 1px solid var(--Primary-Main, #1571de);
              background: var(--Primary-Surface, #f5f7fe);
              color: var(--Primary-Main, #1571de);
            }
          }
        }

        .filterAll {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
          border-radius: 8px;
          background: var(--Primary-Main, #1571de);
          padding: 12px;
          cursor: pointer;
          .filterText {
            color: var(--Color-Neutal-Neutral-10, #fff);
            text-align: center;

            /* Text M/Reguler */
            // font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
          }
        }
        .daftarTko {
          color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));

          /* Heading 7/Bold */
          //   font-family: Poppins;
          font-size: calc(14px + (20 - 14) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 700;
          line-height: 150%; /* 30px */
          letter-spacing: 0.1px;
        }
      }
    }
  }

  .main {
    border-top: 1px solid #e0e0e0;
  }
}

@media screen and (max-width: 1100px) {
  .bodyPipeline {
    .header {
      .searchPipeline {
        width: 53%;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .bodyPipeline {
    .header {
      gap: 16px;
      .pipelineText {
        display: flex;
        color: #000;

        /* Heading 4/Bold */
        // font-family: Poppins;
        font-size: calc(24px + (32 - 24) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 700;
        line-height: 140%; /* 44.8px */
        letter-spacing: 0.16px;
      }
      .searchPipeline {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .contentPipeline {
    .pipelineTableBox {
      .Headertable {
        .filterType {
          width: 100%;
          .buttonType {
            width: 100%;
          }
        }
        .filterBox {
          flex-direction: column;
          align-items: flex-start;
        }
        .filterAll {
          width: 100%;
        }
      }
    }
  }
}
