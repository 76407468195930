.slick-dots.slick-thumb {
  display: flex;
  flex-direction: row;
  justify-content: center;
  z-index: 0 !important;

  li {
    width: 10px;
    height: 10px;
    margin: 0 3px;
    border-radius: 999px;
    transition: all 0.5s ease-in-out;
    will-change: width;
    background-color: #9e9e9e;

    &.slick-active {
      background-color: #1571de;
    }
  }
}

.slick-list {
  // overflow-x: visible!important;
  // overflow: hidden!important;
  z-index: 1 !important;
}
