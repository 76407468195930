.container {
  height: 100vh;
  width: 100vw;
  background-color: #00000080;
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  > div {
    height: 100vh;
    border-radius: 12px 0px 0px 12px;
    background: var(--Color-Neutal-Neutral-10, #fff);
    width: 400px;
    position: relative;
    animation: slideLeft 0.5s ease-out forwards; /* animasi slide ke kiri */
    transform: translateX(100%); /* awalnya sembunyikan ke kanan */
    .top {
      display: flex;
      flex-direction: column;
      padding: 24px;
      gap: 16px;
      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
        border-bottom: 1px solid #e0e0e0;
        padding-bottom: 16px;
        .filter {
          color: var(--Neutral-90, #404040);

          /* Heading 3/Medium */
          //   font-family: Poppins;
          font-size: calc(20px + (24 - 20) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 500;
          line-height: 30px; /* 125% */
        }

        .reset {
          color: var(--Danger-Main, #d42701);

          /* Text L/Medium */
          // font-family: Poppins;
          font-size: calc(14px + (16 - 14) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 500;
          line-height: 24px; /* 150% */
          cursor: pointer;
        }
      }

      .inputBox {
        display: flex;
        flex-direction: column;
        gap: 4px;
        border-bottom: 1px solid #e0e0e0;
        padding-bottom: 16px;
        &.last {
          padding-bottom: 0px;
          border: none;
        }
        > span:first-child {
          color: var(--Neutral-90, #404040);

          /* Text L/Medium */
          // font-family: Poppins;
          font-size: calc(14px + (16 - 14) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 500;
          line-height: 24px; /* 150% */
        }
        .searchBar {
          height: 43px;
        }
      }
    }

    .bottom {
      position: absolute;
      bottom: 0px;
      width: 100%;
      background: var(--Color-Neutal-Neutral-10, #fff);
      box-shadow: 0px -4px 24px 0px rgba(132, 132, 132, 0.15);
      padding: 12px 24px;
      display: flex;
      // align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 4px;
      > span {
        font-size: 12px;
        color: red;
      }
      > div {
        border-radius: 8px;
        background: var(--Primary-Main, #1571de);
        display: flex;
        padding: 10px 24px;
        justify-content: center;
        color: var(--Color-Neutal-Neutral-10, #fff);
        text-align: center;

        /* Text M/Medium */
        // font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
        width: 100%;
        cursor: pointer;
        &.disabled {
          background: #9ebce6;
          cursor: unset;
        }
      }
    }
  }

  @keyframes slideLeft {
    to {
      transform: translateX(0); /* geser ke kiri sampai muncul */
    }
  }
}

@media screen and (max-width: 450px) {
  .container {
    > div {
      width: 100%;
      border-radius: 12px 12px 0px 0px;
      max-height: 80%;
      position: fixed;
      bottom: 0;
      animation: slideUp 0.5s ease-out forwards; /* Animation sliding from bottom to top */
      transform: translateY(100%); /* Initially hide below */
    }

    @keyframes slideUp {
      to {
        transform: translateY(0); /* Slide up to reveal */
      }
    }
  }
}
