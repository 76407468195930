@import "../../../../../theme/variables.scss";

.container {
  width: 100%;
  min-height: 100%;
  background-color: #f4f4f4;
  z-index: inherit !important;
  padding-bottom: 32px;

  .controlHeader {
    width: 100%;
    height: 46px;
    padding-inline: 30px;
    background-color: $NEUTRAL_LIGHTER;

    display: flex;
    align-items: center;
    justify-content: space-between;

    padding-bottom: 10px;

    .searchBarWrapper {
      height: 36px;
      width: 356px;
    }

    .buttonWrapper {
      height: 36px;
      width: 104px;
    }
  }

  .detailHeader {
    z-index: 0;
    position: relative;

    >img {
      width: 100%;
      height: 200px;
      object-fit: cover;
      z-index: 1;
    }

    .headerItems {
      position: absolute;
      top: calc(50% - 60px);
      padding-inline: 30px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 16px;
      width: 100%;
      max-width: 100vw;

      .backButton {
        display: flex;
        align-items: center;
        gap: 8px;
        color: $NEUTRAL-10;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 142.857%;
        background-color: transparent;
        border: none;
      }

      .defaultLogo {
        display: grid;
        place-items: center;
        width: 127px;
        height: 97px;
        background: $NEUTRAL_LIGHTEST;
        border-radius: 8px;
        flex-shrink: 0;

        .initial {
          background-color: #4a7dca;
          width: 57px;
          height: 57px;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 26px;
          line-height: 140%;
          letter-spacing: 0.005em;
          color: $NEUTRAL_10;
        }

        // .defaultImage {
        //   width: 48px !important;
        //   height: 48px !important;
        //   object-fit: contain !important;
        // }
      }

      .headerDesc {
        display: flex;
        flex-direction: column;
        gap: 4px;
        color: $NEUTRAL_LIGHTEST;
        flex-grow: 1;
        overflow: clip;
        

        span {
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 150%;
          letter-spacing: 0.005em;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          white-space: normal;
          overflow: hidden;
          text-overflow: ellipsis;
          word-wrap: break-word;
          word-break: break-word;
  

          max-width: 50vw;
        
        }

        p {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: $NEUTRAL_LIGHTEST;
        }

        >div {
          align-self: flex-start;
          padding: 6px 18px;
          border-radius: 50px;
          font-family: Poppins;
          font-size: 13px;
          font-style: normal;
          font-weight: 500;
          line-height: 153.846%;

          &.owner {
            background-color: $PRIMARY_MAIN;
          }

          &.service {
            background-color: #DE7515;
          }
        }
      }

      .addButton {
        background-color: $PRIMARY_MAIN;
        color: $NEUTRAL-10;
        padding: 8px 12px;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        border: none;
        border-radius: 8px;
        display: flex;
        align-items: center;
        gap: 4px;
        white-space: nowrap;
      }
    }
  }

  .newContent {
    width: calc(100% - 64px);
    margin: auto;
    margin-top: -30px;
    // z-index: 1;
    position: relative;
    border-radius: 8px;
    overflow: clip;

    .infoSection {
      display: flex;
      flex-direction: column;
      gap: 32px;
    }

    .informationContactWrapper {
      background-color: $NEUTRAL-10;
      padding: 16px;
    }

    .delegationWrapper {
      background-color: $NEUTRAL-10;
      padding: 16px;
      border-radius: 8px;
    }

    .ordersWrapper {
      background-color: $NEUTRAL-10;
    }
  }

  .content {
    padding-inline: 30px;
    position: relative;
    width: 100%;
    height: 100%;
    margin-top: -30px;
    z-index: 0;

    .contentContainer {
      display: flex;
      flex-direction: column;
      gap: 24px;
      padding-bottom: 30px;

      >* {
        padding: 0;
      }
    }
  }

  .modalWrapper {
    position: fixed;
    transition: all 0.35s ease-in-out !important;
    z-index: 4;
    background-color: transparent;
    left: 0;
    width: 100%;

    &.desktop {

      &.showModal {
        height: 100vh;
        display: grid;
        place-items: center;
        position: absolute;
      }
    }

    &.hideModal {
      bottom: -500px;
    }

    &.showModal {
      bottom: 0;
    }
  }

  .modalWrapperV2 {
    position: fixed;
    transition: all 0.35s ease-in-out !important;
    z-index: 4;
    background-color: transparent;
    left: 0;
    width: 100%;

    &.desktop {
      transition: none !important;

      &.showModal {
        height: 100vh;
        display: grid;
        place-items: center;
        position: absolute;
      }
    }

    &.hideModal {
      bottom: -1000px;
    }

    &.showModal {
      bottom: 0;
    }
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    background-color: $NEUTRAL_OVERLAY;
    opacity: 0;
    z-index: 3;

    &.visible {
      right: 0;
      bottom: 0;
      opacity: 1;
      transition: opacity 0.4s;
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    .controlHeader {
      padding-inline: 18px;
    }

    .initial {
      width: 32px !important;
      height: 32px !important;
      font-size: 16px !important;
    }

    .buttonWrapper {
      display: none;
    }

    .detailHeader {
      // >img {
      //   height: 107px;
      // }

      .headerItems {
        position: absolute;
        top: calc(50% - 55px);
        padding-inline: 18px;

        .defaultLogo {
          width: 71px;
          height: 54px;

          .defaultImage {
            width: 32px !important;
            height: 32px !important;
            object-fit: contain !important;
          }
        }

        .headerDesc {
          gap: 2px;
          justify-content: space-between;
          height: 100%;

          span {
            font-size: 16px;
          }

          p {
            font-size: 13px;
          }
        }
      }
    }

    .content {
      padding-inline: 18px;
      margin-top: -20px;

      .contentContainer {
        // width: calc(100% - 36px);
        gap: 16px;
      }
    }
  }
}