@import "../../../theme/variables.scss";

.container {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  // right: 0;
  z-index: -5;

  .overlay {
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    z-index: -10;

    &:not(.transparent) {
      background-color: $NEUTRAL_OVERLAY;
    }

    &.onHide {
      display: none;
    }
  }

  &.forceCenter {
    display: grid;
    place-items: center;
  }

  &.onShow {
    z-index: 10;
  }

  &.normal {
    display: grid;
    place-items: center;
  }

  &.onHide {
    > * {
      z-index: -5;
    }

    &.forceHide {
      display: none;
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    &.normal {
      &.default {
        > *:first-child {
          position: fixed;
          left: 0;
          // right: 0;
          transition: all 0.3s ease-in-out !important;
        }

        &.onShow {
          > *:first-child {
            bottom: 0;
            width: 100vw;

            > * {
              width: 100vw;
            }
          }
        }
      }

      &.onHide {
        // background-color: transparent;
        > *:first-child {
          bottom: -1000px;
        }
      }
    }

    &.forceCenter {
      > div {
        display: grid;
        place-items: center;
      }
    }
  }
}
