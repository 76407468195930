@import "../../../theme/variables.scss";

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  width: 100%;
  border-radius: 9px;
  padding: 10px 8px;

  .dot {
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }

  .text {
    font-size: 14px;
    font-weight: 500;
    color: $NEUTRAL_100;
  }
}
