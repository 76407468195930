@import "../../../../../theme/variables.scss";

.container {
  background-color: $NEUTRAL_10;
  width: 516px;
  border-radius: 8px;

  >* {
    padding: 16px;

    &.header {
      display: flex;
      align-items: center;
      gap: 16px;

      .iconWrapper {
        width: 54px;
        aspect-ratio: 1;
        display: grid;
        place-items: center;
        border-radius: 50%;
        background-color: #fafafa;

        >img {
          width: 32px;
          aspect-ratio: 1;
          object-fit: cover;
        }
      }

      >span {
        flex-grow: 1;
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 26px;
      }

      >button {
        display: grid;
        place-items: center;
        background-color: transparent;
        border: none;
      }

    }

    &.empty {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 24px;

      >img {
        width: 50px;
        aspect-ratio: 100%;
      }

      >span {

        &:nth-child(2) {
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
          display: block;
          padding-top: 16px;
          padding-bottom: 2px;
        }

        &:last-child {
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          color: $NEUTRAL_60;
        }
      }
    }

    &.content {
      display: flex;
      flex-direction: column;
      padding-inline-start: 20px;

      .each {
        display: flex;
        align-items: center;
        padding: 12px 16px;

        border-left: 1px dashed $NEUTRAL_50;
        position: relative;

        &:first-child {
          padding-top: 0px;

          .overflowHider {
            width: 3px;
            height: 2px;
            background-color: $NEUTRAL_10;
            position: absolute;
            left: -2px;
            top: 0;
            z-index: 1;
          }


          .bullet {
            top: 2px;
          }
        }

        &:last-child {
          padding-bottom: 0px;

          .overflowHider {
            width: 3px;
            height: 3px;
            background-color: $NEUTRAL_10;
            position: absolute;
            left: -2px;
            bottom: 0;
            z-index: 1;
          }
        }

        .bullet {
          width: 15px;
          height: 15px;
          border-radius: 50%;
          background-color: $PRIMARY_MAIN;
          border: 3px solid #D9EAFF;
          position: absolute;
          left: -8px;
          top: 15px;
        }

        .left {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          gap: 4px;

          >span {

            &:first-child {
              font-family: Poppins;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 20px;
              color: $PRIMARY_MAIN;
            }

            &:nth-child(2) {
              font-family: Poppins;
              font-size: 13px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;
              color: $NEUTRAL_90;
            }
          }
        }

        >span {
          font-family: Poppins;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          color: $NEUTRAL_60;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    width: 100%;
  // border-radius: 20px 20px 0 0;
  }
}