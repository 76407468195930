.bodySixthStep {
  padding: 12px 24px 12px 24px;
  // overflow-x: scroll;
  // max-height: 330px;
}

// .bodySixthStep::-webkit-scrollbar {
//   display: none;
// }

.cardDocument {
  display: flex;
  flex-wrap: wrap;
  // gap: 16px;
  gap: 16px;
  .card {
    width: calc(50% - 16px); /* 50% lebar dengan sedikit jarak */
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    border: 1px solid #e7e7e7;
    background: var(--Color-Neutal-Neutral-10, #fff);
    padding: 12px;
    gap: 4px;
    .left {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .iconDownload {
      cursor: pointer;
    }

    .iconNull {
      border-radius: 8px;
      background: #e5f1ff;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 12px;
    }

    .images {
      width: 45px;
      // height: 40px;
      border-radius: 8px;
      img {
        width: 45px;
        height: 40px;
        border-radius: 8px;
      }
    }

    .title {
      display: flex;
      flex-direction: column;
      gap: 4px;
      .preview {
        color: #a0a0a0;
        // font-family: Poppins;
        font-size: calc(10px + (10 - 10) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .p {
        color: #000;
        // font-family: Poppins;
        font-size: calc(12px + (16 - 12) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .cardDocument {
    .card {
      width: 100%;
      .left {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }
  }
}

// @media screen and (max-width: 768px) {
//   .cardDocument {
//     .card {
//       width: calc(50% - 8px); /* 50% lebar dengan sedikit jarak */
//     }
//   }
// }
