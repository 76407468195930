@import "../../../../../../../../theme//variables.scss";

.container {
  border-radius: 8px;
  border: 1px solid #e8e8e8;
  background-color: $NEUTRAL_10;
  > div:first-child {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background: #f3f9ff;
    border-radius: 8px 8px 0px 0px;
    color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));
    text-align: center;

    /* Heading 7/Bold */
    // font-family: Poppins;
    font-size: calc(16px + (20 - 16) * ((100vw - 280px) / (1600 - 280)));
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 30px */
    letter-spacing: 0.1px;
  }

  > div:nth-child(2) {
    padding: 16px;
    .notFound {
      display: flex;
      align-items: center;
      justify-content: center;
      > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4px;
        > div {
          img {
            width: 150px;
            height: 100%;
          }
        }

        > span {
          color: var(--Neutral-50, var(--Color-Neutal-Neutral-50, #c2c2c2));
          text-align: center;

          /* Text S/Reguler */
          // font-family: Poppins;
          font-size: calc(10px + (13 - 10) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 153.846% */
        }
      }
    }
    .tableBox {
      border-radius: 8px;
      border: 1px solid var(--Color-Neutal-Neutral-30, #ededed);
      background: var(--Color-Neutal-Neutral-10, #fff);

      .tableHead {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
        padding: 16px;
        border-bottom: 1px solid #e0e0e0;

        > span {
          width: 200px;
          color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));

          /* Text M/Medium */
          //   font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 142.857% */
        }
      }

      .tablebody {
        display: flex;
        flex-direction: column;
        .body {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 16px;
          padding: 16px;
          border-bottom: 1px solid var(--Color-Neutal-Neutral-30, #ededed);
          > span {
            width: 200px;
            color: var(--Neutral-90, var(--Color-Neutal-Neutral-90, #404040));
            // font-family: Poppins;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }

          &.borderNone {
            border-bottom: none;
          }
        }
      }
    }

    .tableBoxResponsive {
      display: none;
    }
  }
}

@media screen and (max-width: 1100px) {
  .container {
    > div:nth-child(2) {
      .tableBox {
        display: none;
      }

      .tableBoxResponsive {
        border-radius: 8px;
        border: 1px solid var(--Color-Neutal-Neutral-30, #ededed);
        background: var(--Color-Neutal-Neutral-10, #fff);
        display: flex;
        flex-direction: column;

        .tableHead {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 16px;
          padding: 16px;
          border-bottom: 1px solid #e0e0e0;

          > span {
            width: 100%;
            color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));

            /* Text M/Medium */
            //   font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 142.857% */
          }
        }

        .tablebody {
          display: flex;
          flex-direction: column;
          .body {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 16px;
            padding: 16px;
            border-bottom: 1px solid var(--Color-Neutal-Neutral-30, #ededed);
            > span {
              width: 100%;
              color: var(--Neutral-90, var(--Color-Neutal-Neutral-90, #404040));
              // font-family: Poppins;
              font-size: 13px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
            > div {
              width: 100%;
              > div {
                width: 150px;
                padding: 8px;
                background: $PRIMARY_MAIN;
                border-radius: 8px;
                font-family: "Poppins";
                font-style: normal;
                font-weight: 500;
                font-size: 13px;
                line-height: 20px;
                color: $NEUTRAL_10;
                border: none;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
              }
            }

            &.borderNone {
              border-bottom: none;
            }
          }
        }
      }
    }
  }

  .containerDetail {
    display: flex;
    flex-direction: column;
    gap: 12px;
    background-color: #fff;
    border-radius: 8px;
    padding: 12px;
    width: 400px;
    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;

      > span:first-child {
        color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));

        /* Text M/Medium */
        // font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
      }

      > span:last-child {
        color: var(--Neutral-90, var(--Color-Neutal-Neutral-90, #404040));
        // font-family: Poppins;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    .title {
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));
      text-align: center;

      /* Heading 7/Bold */
      //   font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 30px */
      letter-spacing: 0.1px;
    }
  }
}

@media screen and (max-width: 768px) {
  .containerDetail {
    border-radius: 8px 8px 0px 0px;
  }
}
