@import "../../../../../theme/variables.scss";

.container {
  position: relative;

  .autoScroll {
    display: hidden;
    position: absolute;
    top: 0;
  }

  >h3 {
    @include padding-top(24, 18, 18px);
    padding-bottom: 30px;
    background-color: $NEUTRAL-10;
    z-index: 1;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .content {
    display: grid;
    grid-template-columns: 358px 1fr;
    gap: 16px;

    .left {
      display: flex;
      flex-direction: column;

      .logoCard {
        width: 100%;
        position: sticky;
        background-color: $NEUTRAL-20;
        aspect-ratio: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        text-align: center;
        gap: 8px;
        padding: 10px;

        >img {
          width: 100%;
          object-fit: contain;
        }

        >span {
          text-align: center;
          font-family: Poppins;
          font-size: 13px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }

        >p {
          font-family: Poppins;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          color: $NEUTRAL_70;
        }
      }
    }

    .right {
      .grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 15px;
        row-gap: 20px;
      }

      .personalData,
      .familyData {
        display: flex;
        flex-direction: column;
        gap: 12px;

        >span {
          font-family: Poppins;
          @include font-size (20, 18, 18px);
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }

        .fields {
          display: flex;
          flex-direction: column;
          gap: 20px;
        }
      }
    }


  }

}

.card {
  padding: 20px;
  border: 1px solid $NEUTRAL-30;
  border-radius: 20px;
}

.rightColumn {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

@media screen and (max-width: 768px) {
  .container {
    padding-top: 16px;

    >h3 {
      height: 0px;
      position: absolute;
      top: 0!important;
      z-index: -1;
      opacity: 0;
    }

    .content {
      grid-template-columns: 1fr;

      .left {
        display: none;
      }

      .right {
        .grid {
          grid-template-columns: 1fr;
        }
      }
    }

  }

  .card {
    padding: 0;
    border: none;
  }
}