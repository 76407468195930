@import "../../../../theme/variables.scss";

.jobSeekersTestimoniesContainer {
  min-width: 100%;
  .titleWrapper {
    padding-top: 60px;
    text-align: center;
    padding-bottom: 60px;
    .titleHead {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      > p {
        @include font-size(16, 14, 14px);
        font-weight: 400;
      }
      .line {
        width: 18px;
        height: 3px;
        background: $PRIMARY_MAIN;
      }
    }
    .tagLine {
      margin-top: 8px;
      > p {
        @include font-size(26, 20, 20px);
        font-weight: 600;
        color: $NEUTRAL_90;
        line-height: 36px;
        > span {
          color: $PRIMARY_MAIN;
          padding-bottom: 2px;
        }
      }
    }
  }

  .cardContainer {
    .card {
      width: 474px;
      padding: 16px;
      border: 1px solid $NEUTRAL_30;
      border-radius: 16px;
      // margin-right: 24px;
      .cardHead {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        .profile {
          display: flex;
          gap: 8px;
          align-items: center;
          > img {
            width: 32px;
            height: 32px;
          }
          .profileText {
            > :first-child {
              font-weight: 500;
              font-size: 14px;
            }
            > :last-child {
              font-weight: 400;
              font-size: 12px;
              color: $NEUTRAL_60;
            }
          }
        }
        .doubleQuote {
          width: 40px;
          height: 40px;
        }
      }
      .cardBody {
        margin-top: 16px;
        .textBody {
          font-weight: 400;
          font-size: 14px;
          overflow: hidden;
          display: -webkit-box;
          line-clamp: 2;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          color: $NEUTRAL_70;
        }
      }
    }
  }
  .sliderAction {
    display: flex;
    gap: 16px;
    justify-content: center;
    color: $NEUTRAL_10;
    padding-top: 24px;
    .icon {
      width: 55px;
      height: 55px;
      border-radius: 50%;
      background: $PRIMARY_MAIN;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: 768px) {
  .jobSeekersTestimoniesContainer {
    .cardContainer {
      .card {
        width: 60vw !important;
        .cardBody {
          margin-top: 12px !important;
        }
      }
    }
  }
}
