@import "../../../../../theme/variables.scss";

.container {
  @include padding-inline(30, 18, 18px);
  @include padding-block(24, 16, 16px);
  display: flex;
  flex-direction: column;
  gap: 12px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .searchWrapper {
      width: 346px;
      max-width: calc(100vw - 36px);
      height: 44px;
    }

    .legends {
      display: flex;
      align-items: center;
      gap: 24px;

      >div {
        display: flex;
        align-items: center;
        gap: 8px;

        >div {
          height: 10px;
          width: 37px;
          border-radius: 10px;
        }

        >p {
          font-size: 13px;

          >span {
            font-style: italic;
          }
        }
      }
    }
  }

  .tableWrapper {
    background-color: $NEUTRAL_10;
    border-radius: 8px;


    .odd {

      td {
        background-color: $PRIMARY_SURFACE !important;
      }
    }

    .tooltipWrapper {
      width: 100%;
      display: flex;
      align-items: center;
      z-index: 0;

      >* {
        width: 100%;
        z-index: 0;

      }

    }

    .sticky {
      // position: sticky;
      // left: 300px;
      border-right: 1px solid $NEUTRAL-40;
      z-index: 1;
    }

    .tooltip {
      display: grid;
      grid-template-columns: 1fr;
      gap: 4px;

      >p {
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 133.333%;
        color: $NEUTRAL-100;
        display: grid;
        grid-template-columns: 109px auto 1fr;
        gap: 8px;
        text-align: left;

        >span {

          &:first-child {
            color: $NEUTRAL-70;
          }
        }
      }
    }

    .ppTitle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
    }

    .ppWrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;

      .pp {
        display: flex;
        align-items: center;
        gap: 8px;
  
        >img {
          width: 36px;
          aspect-ratio: 1;
          object-fit: cover;
          border-radius: 50%;
        }
  
        >div {
          display: flex;
          flex-direction: column;
  
          >span {
            font-family: 'Poppins';
  
            &:first-child {
              font-weight: 500;
            }
  
            &:last-child {
              color: $NEUTRAL-90;
            }
          }
        }
      }
    }

  
  }
}

@media screen and (max-width: 768px) {
  .container {

    .header {
      flex-direction: column;
      gap: 12px;

      >*{
        width: 100%!important;
        max-width: none!important;
      }

      .legends {
        justify-content: flex-end;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .container {
    .header {

      .legends {
        display: none;
      }
    }
  }
}