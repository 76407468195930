@import "../../../../theme/variables.scss";

.container {
  background-color: $NEUTRAL-10;
  width: 662px;
  border-radius: 8px;
  height: 400px;
  // display: grid;
  // grid-template-columns: 1fr;
  // grid-template-rows: auto 1fr;
  display: flex;
  flex-direction: column;

  .header {
    .top {
      display: flex;
      align-items: center;
      padding: 16px;
      padding-bottom: 8px;
      gap: 16px;

      > img {
        width: 24px;
        object-fit: contain;
      }

      > h3 {
        flex-grow: 1;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
      }

      > button {
        display: grid;
        place-items: center;
        background-color: transparent;
        border: none;
      }
    }

    .bottom {
      display: flex;
      align-items: flex-end;
      gap: 24px;
      border-bottom: 1px solid $NEUTRAL-30;
      overflow: auto;
      max-width: 662px;

      > div {
        padding: 14px 8px;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 142.857%;
        cursor: pointer;
        text-align: center;
        min-width: 150px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &.active {
          box-shadow: 0px -2px 0px 0px #2b6ed3 inset;
          color: #2e3192;
        }
      }
    }
  }

  .loadingWrapper {
    display: grid;
    place-items: center;
    height: 100%;
  }

  .content {
    padding: 16px;
    overflow: auto;

    &.contentNotFound {
      overflow: hidden;
    }

    .each {
      position: relative;
      display: grid;
      grid-template-columns: auto 1fr;
      gap: 16px;

      &:not(:last-child) {
        padding-bottom: 24px;
      }

      .border {
        position: absolute;
        width: 2px;
        height: 100%;
        border-left: 2px dashed $NEUTRAL-50;
        z-index: 0;
        left: 7px;
      }

      .dot {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: $PRIMARY_MAIN;
        z-index: 1;
        border: 3px solid $PRIMARY_SOFT;
      }

      .eContent {
        display: grid;
        // flex-direction: column;
        grid-template-columns: 1fr;
        gap: 4px;

        .eTop {
          display: grid;
          grid-template-columns: 1fr auto;

          > h4 {
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 142.857%;
            color: $PRIMARY_MAIN;
          }

          > span {
            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 133.333%;
            color: $NEUTRAL-60;
          }
        }

        > span {
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 142.857%;
        }

        .desc {
          font-family: Poppins;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 133.333%;
          color: $NEUTRAL-90;

          .bold {
            font-weight: 700;
          }

          .italic {
            font-style: italic;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    .header {
      .bottom {
        max-width: 120%;
      }
    }
  }
}
