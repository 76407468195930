@import "../../../../theme/variables.scss";

.container {
  padding: 32px;
  @include padding-inline(32, 18, 18px);
  background-color: $NEUTRAL_10;
  border-radius: 8px;
  max-width: 400px;
  z-index: 5;
  max-height: 85vh;
  overflow: auto;
  min-width: 354px;

  .title {
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: 0.12px;
  }

  .subTitle {
    color: #878787;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .confirmationWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    span {
      &:first-child {
        padding-bottom: 8px;
      }
    }

    .inputWrapper {
      padding-top: 8px;
      width: 100%;
      text-align: left;
    }

    .errorWrapper {
      padding-top: 8px;
      padding-bottom: 16px;
      width: 100%;
      text-align: left;
      font-size: 13px;
      color: $DANGER_MAIN;
      opacity: 0;

      &.error {
        opacity: 1;
      }
    }

    .buttonsWrapper {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 8px;

      > * {
        width: 50%;
        padding: 8px 12px;
        border-radius: 8px;
        border: 1px solid transparent;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;

        &:first-child {
          border-color: $NEUTRAL_100;
          background-color: $NEUTRAL_10;
        }

        &:last-child {
          background-color: $PRIMARY_MAIN;
          border-color: $PRIMARY_MAIN;
          color: $NEUTRAL_10;

          &:disabled {
            background-color: $PRIMARY_DISABLED;
            border-color: $PRIMARY_DISABLED;
          }
        }
      }
    }
  }

  .successWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    > div {
      height: 86px;
      aspect-ratio: 1;
      overflow: hidden;
      display: grid;
      place-items: center;
      position: relative;

      img {
        height: 170px;
        aspect-ratio: 1;
        position: absolute;
      }

      span {
        &:nth-child(2) {
          padding-top: 16px;
          padding-bottom: 4px;
        }
      }
    }

    > button {
      padding: 8px 12px;
      border-radius: 8px;
      border: 1px solid $PRIMARY_MAIN;
      background-color: $NEUTRAL_10;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: $PRIMARY_MAIN;
      margin-top: 8px;
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    max-width: unset;
    min-width: unset;
    width: 100%;
    border-radius: 8px 8px 0px 0px;
    // padding: 16px;
    .confirmationWrapper {
      .buttonsWrapper {
        flex-direction: column;
        > * {
          width: 100%;
        }
      }
    }

    &:not(.success) {
      border-radius: 16px 16px 0 0;
      max-width: 100vw;
    }
  }
}
