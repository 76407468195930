@import "../../../../../../../theme/variables.scss";

.container {
  .card {
    background-color: $NEUTRAL_10;
    border-radius: 8px;
    padding: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

  }

  .autoScroll {
    display: hidden;
    position: absolute;
    top: 0;
  }

  img {
    width: 411px;
    height: 247px;
    object-fit: contain;
  }

  h4 {
    margin-top: 32px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 38px;
    text-align: center;
    color: $PRIMARY_MAIN;

    &.error {
      color: $DANGER_MAIN;
    }
  }

  p {
    padding-top: 8px;
    width: 406px;
    text-align: center;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: $NEUTRAL_70;

    &.error {
      color: $DANGER_MAIN;
      display: flex;
      flex-direction: column;
    }
  }

  .buttonsWrapper {
    padding-top: 24px;
    display: flex;
    align-items: center;
    gap: 16px;

    .left {
      flex-grow: 1;

      button {
        height: 36px;
        border-radius: 8px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        cursor: pointer;
        padding-inline: 16px;

        &.prev {
          background-color: $NEUTRAL_10;
          border: 1px solid $NEUTRAL_100;
          color: $NEUTRAL_100;
        }
      }
    }

    .right {
      display: flex;
      gap: 16px;
      width: 436px;
      align-items: center;
      justify-content: flex-end;

      button {
        height: 36px;
        border-radius: 8px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        cursor: pointer;
        padding-inline: 16px;

        &.saveToDraft {
          background-color: $NEUTRAL_10;
          border: 1px solid $PRIMARY_MAIN;
          color: $PRIMARY_MAIN;
        }

        &.submit {
          background-color: $PRIMARY_MAIN;
          border: 1px solid $PRIMARY_MAIN;
          color: $NEUTRAL_10;

          &:disabled {
            background-color: $PRIMARY_DISABLED;
            border-color: $PRIMARY_DISABLED;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    // padding: 16px;

    img {
      width: 266px;
      height: 160px;
    }

    h4 {
      font-size: 20px;
    }

    p {
      width: 100%;
    }

    .buttonsWrapper {
      width: 100%;
      flex-direction: column-reverse;
      gap: 12px;
      .left, .right, button {
        width: 100%;
      }

      .right {
        gap: 12px;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .container {

    .buttonsWrapper {
      gap: 12px;

      .right {
        flex-direction: column;
      }
    }
  }
}