@import "../../../../theme/variables.scss";

.container {
  background-color: $NEUTRAL_90;
  width: 100%;
  height: 100%;
  overflow: clip;
  position: relative;
  max-width: 100vw;
  max-height: 85vh;
  display: grid;
  place-items: center;

  .img {
    width: 100%;
    max-height: 600px;
    // height: 100%;
    object-fit: contain;
  }

  .pushDown {
    margin-top: 20%;
    margin-bottom: 63px;
    min-width: 600px;
  }

  .button {
    position: absolute;
    right: 8px;
    height: 32px;
    aspect-ratio: 1;
    display: grid;
    place-items: center;
    border-radius: 2px;
    border: none;
    background-color: $NEUTRAL_10;
    cursor: pointer;

    &.close {
      top: 8px;
      width: auto;
      height: auto;
      border-radius: 50%;
      padding: 0;
      background-color: transparent;
    }

    &.rotate {
      bottom: 8px;
    }

    > img {
      width: 24px;
      // max-height: 600px;
      aspect-ratio: 1;
      object-fit: contain;
    }
  }
}

.pdfContainer {
  background-color: $NEUTRAL_10;
  width: 100%;
  height: 100%;
  height: auto;
  overflow: auto;
  position: relative;
  max-width: 100vw;
  max-height: 85vh;
  display: flex;
  flex-direction: column;

  .buttonWrapper {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-inline: 12px;
  }

  .button {
    right: 8px;
    height: 32px;
    aspect-ratio: 1;
    display: grid;
    place-items: center;
    border-radius: 2px;
    border: none;
    background-color: $NEUTRAL_10;
    cursor: pointer;

    &.close {
      top: 8px;
      width: auto;
      height: auto;
      border-radius: 50%;
      padding: 0;
      background-color: transparent;
      width: 40px;
    }

    > img {
      width: 24px;
      aspect-ratio: 1;
      object-fit: contain;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 16px;

    > img {
      width: 100%;
      // height: 100%;

      object-fit: contain;
    }
  }
}
