@import "../../../theme/variables.scss";

.container {
  width: 100%;
  // height: 20px;
  display: flex;
  align-items: center;
  gap: 4px;

  // &.v2 {
  //   height: 32px;
  // }

  span {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
  }

  .totalBar {
    width: 90%;
    height: 100%;
    background-color: $NEUTRAL_30;
    border-radius: 20px;
    display: flex;
    align-items: center;
    position: relative;
    overflow: clip;
    flex-grow: 1;

    >span {
      color: $NEUTRAL_100;
      position: absolute;
      right: 8px;
    }

    .candidatesBar {
      height: 100%;
      background-color: $PRIMARY_DISABLED;
      // border-radius: 20px;
      text-align: right;
      padding-inline: 8px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: absolute;
      z-index: 0;

      &.v2,
      &.v3 {
        background-color: #C1DAFF;
      }


      span {
        color: $NEUTRAL_10;
        width: 100%;

        &.v2,
        &.v3 {
          color: $PRIMARY_MAIN;
        }
      }
    }

    .fulfilledBar {
      height: 100%;
      background-color: $PRIMARY_MAIN;
      // border-radius: 20px;
      text-align: right;
      padding-inline: 8px;
      position: relative;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      z-index: 1;

      &.v2 {
        height: calc(100% - 8px);
        left: 4px;
      }

      span {
        color: $NEUTRAL_10;
        width: 100%;
        white-space: nowrap;
      }

      >p {
        color: #C1DAFF;
        position: absolute;
        width: 100%;
        font-family: Poppins;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
        text-align: center;
      }
    }
  }
}