@import "../../../../../../../theme/variables.scss";

.tableBody {
  .tableRow {
    .showOnMobile {
      display: none;
    }

    &:first-child {
      .tableCell {
        width: 20%;
      }
    }

    .tableCell {
      border-bottom: 1px solid $NEUTRAL_30;
      background-color: $NEUTRAL_10;

      > div {
        background-color: $NEUTRAL_10;
        padding: 16px 14px;

        text-overflow: ellipsis;
        white-space: nowrap;

        &.profile {
          display: flex;
          align-items: center;
          gap: 12px;

          .desc {
            display: flex;
            flex-direction: column;
            gap: 4px;

            span {
              font-family: "Poppins";
              font-style: normal;

              &:first-child {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: $NEUTRAL_DARKEST;
              }

              &:last-child {
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                color: $NEUTRAL_60;
              }
            }
          }
        }

        &.contact {
          display: flex;
          flex-direction: column;
          gap: 4px;

          .stacked {
            display: flex;
            align-items: center;
            gap: 6px;

            .icon {
              color: $NEUTRAL_50;
            }

            span {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 400;
              font-size: 13px;
              line-height: 20px;
              color: $NEUTRAL_DARKEST;
            }
          }
        }

        &.contract {
          display: flex;
          align-items: center;

          .period {
            display: flex;
            flex-direction: column;
            gap: 4px;
            padding-right: 16px;
            border-right: 1px solid $NEUTRAL_30;

            span {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 400;
              font-size: 13px;
              line-height: 20px;
              color: $NEUTRAL_DARKEST;
            }
          }

          .length {
            padding-left: 8px;

            span {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 500;
              font-size: 13px;
              line-height: 20px;
              color: $NEUTRAL_DARKEST;
            }
          }
        }

        &.status {
          &.active {
            > span {
              padding: 8px 16px;
              border: 1px solid $ACTIVE_MAIN;
              background-color: $ACTIVE_SURFACE;
              border-radius: 4px;

              font-family: "Poppins";
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 20px;
              color: $ACTIVE_MAIN;
            }
          }

          &.inactive {
            > span {
              padding: 8px 16px;
              border: 1px solid $DANGER_MAIN;
              background-color: $DANGER_SURFACE;
              border-radius: 4px;

              font-family: "Poppins";
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 20px;
              color: $DANGER_MAIN;
            }
          }
        }

        &.seeDetail {
          button {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: $PRIMARY_MAIN;
            height: 20px;
            background-color: transparent;
            border: none!important;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .tableBody {
    &:before {
      line-height: 8px;
    }

    .tableRow {
      .showOnMobile {
        display: table-cell;
      }

      .hideOnMobile {
        display: none;
      }

      &:first-child {
        .tableCell {
          width: 100%;

          &:first-child {
            border-radius: 8px 0 0 0;
            overflow: hidden;
          }

          &:last-child {
            border-radius: 0 8px 0 0;
            overflow: hidden;
          }
        }
      }

      .tableCell {
        border-bottom: 1px solid $NEUTRAL_30;
        background-color: $NEUTRAL_10;

        > div {
          background-color: $NEUTRAL_10;
          padding: 10px 9px;

          &.profile {
            display: flex;
            align-items: center;
            gap: 12px;

            .desc {
              display: flex;
              flex-direction: column;
              gap: 4px;

              span {
                font-family: "Poppins";
                font-style: normal;
                text-overflow: ellipsis;
                white-space: nowrap;

                &:first-child {
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 20px;
                  color: $NEUTRAL_DARKEST;
                }

                &:last-child {
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 16px;
                  color: $NEUTRAL_60;
                }
              }
            }
          }

          &.contact {
            display: flex;
            flex-direction: column;
            gap: 4px;

            .stacked {
              display: flex;
              align-items: center;
              gap: 6px;

              .icon {
                color: $NEUTRAL_50;
              }

              span {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 20px;
                color: $NEUTRAL_DARKEST;
              }
            }
          }

          &.contract {
            display: flex;
            align-items: center;

            .period {
              display: flex;
              flex-direction: column;
              gap: 4px;
              padding-right: 16px;
              border-right: 1px solid $NEUTRAL_30;

              span {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 20px;
                color: $NEUTRAL_DARKEST;
              }
            }

            .length {
              padding-left: 8px;

              span {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 500;
                font-size: 13px;
                line-height: 20px;
                color: $NEUTRAL_DARKEST;
              }
            }
          }

          &.status {
            text-overflow: ellipsis;
            white-space: nowrap;

            &.active {
              > span {
                padding: 8px 16px;
                border: 1px solid $ACTIVE_MAIN;
                background-color: $ACTIVE_SURFACE;
                border-radius: 4px;

                font-family: "Poppins";
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: $ACTIVE_MAIN;
              }
            }

            &.inactive {
              > span {
                padding: 8px 16px;
                border: 1px solid $DANGER_MAIN;
                background-color: $DANGER_SURFACE;
                border-radius: 4px;

                font-family: "Poppins";
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: $DANGER_MAIN;
              }
            }
          }

          &.seeMore {
            display: flex;

            button {
              width: 100%;
              min-width: 77px;
              height: 28px;
              text-overflow: ellipsis;
              white-space: nowrap;
              background-color: $PRIMARY_MAIN;
              color: $NEUTRAL_10;
              border-radius: 8px;
              font-family: "Poppins";
              font-style: normal;
              font-weight: 500;
              font-size: 13px;
              line-height: 20px;
              border: none;
              padding: 4px 8px;
            }
          }
        }
      }
    }
  }
}
