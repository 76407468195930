@import "../../../theme/variables.scss";

.disabledTextValue{
  color: $NEUTRAL_30;
}

.selectWrapper {
  height: 19px;
  background: $NEUTRAL_10;
  // border: 1px solid $NEUTRAL_40;
  border-radius: 8px;
  position: relative;
  display: flex;
  align-items: center;
  &.disableInput {
    background-color: $NEUTRAL_30;
  }

  .disableInput {
    background-color: $NEUTRAL_30;
  }

  input {
    border: none;
    color: $NEUTRAL_100 !important;
    // padding-inline-start: 14px !important;
    // padding-inline-end: 53px !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &::placeholder {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      color: $NEUTRAL_70;
      width: 100%;
      opacity: 1;
    }

    &:focus {
      border: none;
      outline: none;
    }
  }

  > span,
  input {
    // padding-inline: 12px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: $NEUTRAL_70;
    width: 100%;

    &.filled {
      color: $NEUTRAL_100;
    }
  }

  &.active {
    border-color: $PRIMARY_MAIN;
  }

  .expandButton {
    position: absolute;
    right: 0px;
    // top: 10.5px;
    display: grid;
    place-items: center;
    padding: 0;
    background-color: transparent;
    border: none;
  }

  .clearButton {
    position: absolute;
    right: 35px;
    // top: 13.5px;
    display: grid;
    place-items: center;
    padding: 0;
    background-color: transparent;
    border: none;
  }

  .optionsWrapper {
    position: absolute;
    top: 22px;
    width: 100%;
    background-color: $NEUTRAL_10;
    border-radius: 0px 0px 8px 8px;
    overflow: hidden;
    box-shadow: 0px 0px 1px rgba(9, 30, 66, 0.31),
      0px 3px 5px rgba(9, 30, 66, 0.2);
    z-index: 1;
    padding-block: 4px;
    max-height: 200px;
    overflow: auto;

    > div {
      padding: 8px 12px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $NEUTRAL_90;
      display: flex;
      align-items: center;
      gap: 8px;

      &:hover {
        background-color: $PRIMARY_SURFACE_HOVER;
      }

      > img {
        width: 20px;
        aspect-ratio: 1;
        border-radius: 50%;
        object-fit: cover;
      }
    }
  }
}
