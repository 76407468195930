.updateLogsBox {
  width: 100%;
  border-radius: 8px;
  border: 1px solid #e8e8e8;
  background: var(--Color-Neutal-Neutral-10, #fff);
  height: 100%;
  position: sticky;
  top: 16px;
  // min-width: 387px;
  .headerLogs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    border-radius: 8px 8px 0px 0px;
    border-bottom: 1px solid var(--Stroke, #e8e8e8);
    background: #f3f9ff;
    padding: 10px 12px;
    > span:first-child {
      color: var(--Neutral-100, #0a0a0a);

      /* Heading 7/Bold */
      // font-family: Poppins;
      font-size: calc(16px + (20 - 16) * ((100vw - 280px) / (1600 - 280)));
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 30px */
      letter-spacing: 0.1px;
    }

    > span:last-child {
      color: var(--Neutral-60, var(--Color-Neutal-Neutral-60, #9e9e9e));

      /* Ception 2/Regular */
      // font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 133.333% */
    }
  }

  .contentLogs {
    min-height: 62vh;
    max-height: 62vh; /* Set the maximum height to the full height of the viewport */
    overflow-y: auto; /* Add scrolling if content exceeds the height */
    box-sizing: border-box;
    padding: 12px 12px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    // &.overFlowHidden {
    //   overflow: hidden;
    // }

    .logs {
      display: flex;
      flex-direction: column;
      border-radius: 8px;
      border: 1px solid var(--Color-Neutal-Neutral-30, #ededed);
      background-color: var(--Color-Neutal-Neutral-10, #fff);
      box-shadow: 1px 4px 15px 0px rgba(118, 118, 148, 0.1);
      padding: 12px;
      cursor: pointer;
      transition: 1s ease-in-out;
      &.active {
        border-radius: 8px;
        border: 1px solid var(--Primary-Main, #1571de);
        background: var(--Neutral-20, #fafafa);
        box-shadow: 1px 4px 15px 0px rgba(49, 49, 82, 0.23);
      }

      > div:first-child {
        display: flex;
        align-items: flex-start;
        gap: 6px;
        padding-bottom: 8px;
        > div:first-child {
          position: relative;
          top: 3px;
          width: 14px;
          height: 14px;
          background-color: #1571de;
          border-radius: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        > div:last-child {
          display: flex;
          flex-direction: column;
          width: 100%;
          gap: 4px;
          .remark {
            color: #000;

            /* Text M/Medium */
            // font-family: Poppins;
            font-size: calc(
              12px + (14 - 12) * ((100vw - 280px) / (1600 - 280))
            );
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 142.857% */
            > span {
              font-weight: 700;
            }
          }

          .descBox {
            display: flex;
            flex-direction: column;
            gap: 4px;
            > span {
              color: var(--Neutral-70, #757575);

              /* Ception 2/Regular */
              // font-family: Poppins;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 16px; /* 133.333% */
              > span {
                color: var(--Neutral-70, #757575);

                /* Ception 2/Regular */
                // font-family: Poppins;
                font-size: 12px;
                font-style: normal;
                font-weight: 800;
                line-height: 16px; /* 133.333% */
                // color: red;
              }
            }
          }

          .desc {
            display: flex;
            flex-direction: column;
            gap: 4px;
            > span {
              color: var(--Neutral-70, #757575);

              /* Ception 2/Regular */
              // font-family: Poppins;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 16px; /* 133.333% */
              display: flex;
              gap: 4px;

              > span:first-child {
                width: 20%;
              }
              > span:last-child {
                width: 100%;
              }
            }
          }
        }
      }

      > div:nth-child(2) {
        display: flex;
        // align-items: center;
        gap: 6px;
        border-top: 1px solid #ededed;
        padding-top: 8px;
        padding-left: 20px;
        > span:first-child {
          color: var(--Color-Neutal-Neutral-70, #757575);
          // font-family: Poppins;
          font-size: calc(10px + (12 - 10) * ((100vw - 280px) / (1600 - 280)));
          font-style: italic;
          font-weight: 400;
          line-height: 16px; /* 123.077% */
          min-width: 70px;
          width: 70px;
        }
        > span:nth-child(2) {
          color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));
          // font-family: Poppins;
          font-size: calc(10px + (12 - 10) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 700;
          line-height: 16px;
          min-width: 8px;
        }
        > span:nth-child(3) {
          color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));
          // font-family: Poppins;
          font-size: calc(10px + (12 - 10) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 700;
          line-height: 16px;
          width: 100%;
        }
      }
    }
  }
}
