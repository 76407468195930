
.formWrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    row-gap: 20px;
    column-gap: 15px;
}

@media screen and (max-width: 768px) {
    .content {
      grid-template-columns: 1fr!important;
    }
  }