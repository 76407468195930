@import "../../../../theme/variables.scss";

.container {
  background-color: $NEUTRAL_10;
  border-radius: 16px;
  width: 248px;
  padding: 32px 16px 24px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 1px 9px 0px rgba(0, 0, 0, 0.12);
  position: relative;

  .header {
    position: absolute;
    height: 107px;
    width: auto;
    top: 0;
    left: 0;
    right: 0;
    background-color: linear-gradient(100deg, #1571DE -1.73%, rgba(21, 113, 222, 0.81) 102.52%);
    // background-color: #1571DE;
  }

  .profilePicture {
    width: 120px;
    aspect-ratio: 1;
    object-fit: cover;
    border-radius: 50%;
    z-index: 1;
    background-color: $NEUTRAL_60;
    display: grid;
    place-items: center;
    overflow: clip;

    >img {
      width: 100%;
      object-fit: cover;
      z-index: 1;
    }

  }


  >h3 {
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    padding-top: 8px;
    padding-bottom: 2px;
  }

  >span {
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: $NEUTRAL_60;
    padding-bottom: 16px;
  }

  >button {
    display: flex;
    align-items: center;
    gap: 4px;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: $DANGER_MAIN;
    background-color: transparent;
    border: none;
  }
}