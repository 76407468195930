@import "../../../../../../theme/variables.scss";

.container {
  position: relative;

  .redStar {
    color: $DANGER_MAIN;
  }

  .autoScroll {
    display: hidden;
    position: absolute;
    top: 0;
  }

  > h3 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    padding-bottom: 30px;
    padding-top: 24px;
    position: sticky;
    top: 0;
    background-color: $NEUTRAL_10;
    z-index: 3;
  }

  .content {
    display: flex;
    gap: 20px;

    .left {
      width: 30%;
      aspect-ratio: 1;
      background: #f4f4f4;
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 10%;
      align-self: flex-start;
      min-width: 300px !important;

      > img {
        width: 95%;
      }

      .buttonWrapper {
        width: 100%;
        display: grid;
        place-items: center;

        > button {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $PRIMARY_MAIN;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 24px;
          color: $NEUTRAL_10;
          border: none;
          gap: 8%;
          padding: 14px;
          border-radius: 50px;
          width: 65%;

          > *:first-child {
            flex-shrink: 0;
          }
        }
      }
    }

    .right {
      flex-grow: 1;
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    padding-top: 10px;

    .zero {
      height: 0px !important;
      padding: 0 !important;
    }

    .none {
      display: none !important;
    }

    > h3 {
      display: none;
    }

    .content {
      flex-direction: column;
      align-items: center;
      gap: 0;

      .left {
        width: 100%;
        gap: 5vh;
        background-color: transparent;
        aspect-ratio: 2/1;
        padding-top: 25%;
        padding-bottom: 8%;
        position: static !important;
        > img {
          width: 250px;
          height: 100%;
        }

        .buttonWrapper {
          position: fixed !important;
          bottom: 0 !important;
          padding: 15px 18px;
          padding-bottom: 24px;
          background-color: $NEUTRAL_10;
          border-top: 1px solid $NEUTRAL_30;
          z-index: 3;

          > button {
            width: 100%;
            padding: 8px;
          }
        }
      }

      .right {
        width: 100%;
        // padding-bottom: 160px;
      }
    }
  }
}
