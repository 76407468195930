@import "../../../theme/variables.scss";

.container {
  background-color: $NEUTRAL_20;
  padding: 0 16px 0 16px;
  border-radius: 4px;
}

.title {
  font-weight: 600;
  font-size: 16px;
  color: $NEUTRAL_100;
  padding-bottom: 8px;
  background-color: $NEUTRAL_LIGHTEST;
}

.cardWrapper {
  padding: 8px 0 8px 0;
  border-bottom-color: $NEUTRAL_40;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  // display: flex;
  // align-items: center;
}

.cardWrapper:first-child {
  padding-top: 16px;
}

.cardWrapper:last-child {
  padding-bottom: 16px;
  border-bottom: 0;
}

.Detail {
  display: flex;
  gap: 8px;
  align-items: center;
  margin-top: 10px;
  flex-wrap: wrap;
  .Divider {
    background: $NEUTRAL_60;
    height: 12px;
    width: 1px;
  }
  .Contact {
    display: flex;
    align-items: center;
    gap: 10px;
    & > :first-child {
      color: $PRIMARY_MAIN;
    }
    span {
      color: $NEUTRAL_60;
    }
  }
}
