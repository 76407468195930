@import "../../../../../../../../../../../theme/variables.scss";

.wrapper {
  .container {
    width: 100%;
    height: 100%;
    background: #ffffff;
    border-radius: 8px;
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h4 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      color: $NEUTRAL_100;

      span {
        color: $DANGER_MAIN
      }
    }

    .dateWrapper {
      width: 40%;
    }
  }

  .buttonWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 16px;
    gap: 12px;

    button {
      padding: 8px 16px;
      border: none;
      border-radius: 8px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      transition: all 0.3s;

      &:first-child {
        display: flex;
        align-items: center;
        gap: 6px;
        padding: 10px;
        padding-right: 16px;
        border: none;
        border-radius: 20px;
        background-color: $PRIMARY_SOFT;
        color: $PRIMARY_MAIN;

        span {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
        }

        &:disabled {
          color: $NEUTRAL_60;
        }
      }

      &:last-child {
        display: flex;
        align-items: center;
        gap: 6px;
        padding: 10px;
        padding-left: 16px;
        border: none;
        border-radius: 20px;
        background-color: $PRIMARY_SOFT;
        color: $PRIMARY_MAIN;

        span {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
        }

        &:disabled {
          color: $NEUTRAL_60;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    padding: 8px;

    > * {
      width: 100% !important;
    }

    h4 {
      font-size: 14px;
    }
  }
}
