@import "../../../../../../../../theme/variables.scss";

// .container {
//   padding: 20px;
//   border: 1px solid #e7e7e7;
//   border-radius: 20px;

//   > h3 {
//     font-family: Poppins;
//     font-size: 20px;
//     font-style: normal;
//     font-weight: 500;
//     line-height: normal;
//     padding-bottom: 12px;
//   }

//   > h2 {
//     font-family: Poppins;
//     font-size: 18px;
//     font-style: normal;
//     font-weight: 600;
//     line-height: normal;
//     padding-bottom: 8px;
//     display: none;
//   }

//   .content {
//     display: flex;
//     flex-direction: column;
//     gap: 20px;

//     .top {
//       display: grid;
//       grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
//       gap: 15px;

//       &.full {
//         grid-template-columns: 1fr;
//       }
//     }

//     .rest {
//       display: grid;
//       grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
//       row-gap: 20px;
//       column-gap: 15px;
//       padding-bottom: 20px;
//     }
//   }

//   > button {
//     width: 100%;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     gap: 10px;
//     padding: 8px;
//     background: $MISTY_ROSE;
//     border-radius: 8px;
//     border: none;
//     font-family: "Poppins";
//     font-style: normal;
//     font-weight: 500;
//     font-size: 14px;
//     line-height: 24px;
//     color: $ROSSO_CORSA;
//   }
// }

// @media screen and (max-width: 768px) {
//   .container {
//     padding: 0px;
//     border: none;
//     border-radius: 0;

//     h3 {
//       display: block !important;
//       font-family: "Poppins";
//       font-style: normal;
//       font-weight: 600;
//       font-size: 18px;
//       line-height: 27px;
//       padding-bottom: 8px !important;
//     }

//     h2 {
//       display: block;
//     }

//     .content {
//       grid-template-columns: 1fr;
//     }
//   }
// }

.container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 20px 20px 20px 20px;
  border-radius: 20px;
  border: 1px solid #e7e7e7;
  .textH3 {
    color: #000;
    // font-family: Poppins;
    font-size: calc(16px + (20 - 16) * ((100vw - 280px) / (1600 - 280)));
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

.contentNotSelf {
  display: flex;
  flex-direction: column;
  gap: 16px;
  .top {
    display: flex;
    align-items: center;
    gap: 16px;
    .input {
      width: 100%;
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    border: none;
    padding: 16px 0px;
  }
  .contentNotSelf {
    .top {
      flex-direction: column;
    }
  }
}
