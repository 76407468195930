@import "theme/variables.scss";

.container {
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100% - 70px);

  img {
    width: 413px;
    max-width: 100%;
  }

  h3 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 140%;
    letter-spacing: 0.005em;
    color: $PRIMARY_MAIN;
    padding-top: 32px;
    max-width: 100%;
    text-align: center;
  }

  p {
    width: 574px;
    padding-top: 8px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    color: $NEUTRAL_60;
    max-width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .container{

    h3 {
      font-size: 28px;
    }
  
    p {
      font-size: 14px;
    }
  }
}
