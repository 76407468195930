@import "../../../../theme/variables.scss";

.container {
  background-color: $NEUTRAL_LIGHTEST;
  border-radius: 20px 20px 0px 0px;
  width: 100%;
  // padding-top: 15px;
  padding-inline: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 35px;
  position: relative;
  max-height: 80vh;
  overflow: auto;

  > * {
    width: 100%;
  }

  .topLine {
    background-color: #b4b7ba;
    height: 5px;
    width: 42px;
    border-radius: 5px;
  }

  > h4 {
    padding-top: 16px;
    padding-bottom: 32px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
    color: $NEUTRAL_100;
  }

  .header {
    text-align: center;
    border-bottom: 1px solid $NEUTRAL_40;
    padding-block: 16px;
    position: sticky;
    top: 0;
    background-color: $NEUTRAL-10;

    h3 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      text-align: center;
      color: $NEUTRAL_100;
    }
  }

  .infoWrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .stackedInfo {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: auto;
      padding-bottom: 16px;
      border-bottom: 1px solid $NEUTRAL_20;

      &:last-child {
        border-bottom: none;
      }

      > *:first-child {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $NEUTRAL_70;
      }

      p {
        text-align: right;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        text-align: right;
        // color: $NEUTRAL_DARKEST;
      }
    }
  }

  .buttonWrapper {
    > button,
    .button,
    .holdText {
      width: 100%;
      padding: 8px 16px;
      border-radius: 8px;
      background-color: $PRIMARY_MAIN;
      border: 1px solid $PRIMARY_MAIN;
      color: $NEUTRAL_10;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $NEUTRAL_10;
      cursor: pointer;

      &.holdText {
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500 !important;
        line-height: 20px;
        width: 100%;
        cursor: auto;
        background-color: transparent;
        color: $NEUTRAL_100;
        padding: 0;
        border: none;
        width: 100%;
        display: block;
        text-align: center;
        border-radius: 0;
      }

      &.result {
        background-color: $NEUTRAL_10;
        border: 1px solid $PRIMARY_MAIN;
        color: $PRIMARY_MAIN;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        position: relative;
        display: flex;
        align-items: center;
        gap: 8px;
      }

      &:disabled {
        background-color: $PRIMARY_DISABLED;
        border-color: $PRIMARY_DISABLED;
      }

      &.rejected {
        background-color: transparent;
        color: $DANGER_MAIN;
        border: none;
        cursor: auto;
      }
    }

    .secondaryButton {
      width: 100%;
      padding: 8px 16px;
      border-radius: 8px;
      background-color: $NEUTRAL_10;
      border: 1px solid $PRIMARY_MAIN;
      color: $PRIMARY_MAIN;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }

  .resultPopup {
    position: absolute;
    background-color: $NEUTRAL_10;
    border-radius: 8px;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);
    top: -80px;
    width: 100%;
    z-index: 1;
    // padding: 4px 12px;

    > * {
      text-align: left;

      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: $NEUTRAL_90;
      position: relative;

      &:not(.divider) {
        padding: 8px 12px;
      }

      .divider {
        width: calc(100% - 24px);
        height: 1px;
        background-color: $NEUTRAL_40;
        position: absolute;
        bottom: 0;
      }

      &:hover {
        background-color: $NEUTRAL_20;
      }

      // &:first-child {
      //   // border-bottom: 1px solid $NEUTRAL_40;
      // }
    }
  }

  .bottomLine {
    position: absolute;
    width: 134px;
    height: 5px;
    left: calc(50% - 134px / 2);
    bottom: 8px;
    background: #000000;
    border-radius: 100px;
  }
}

.detail {
  color: #1571de;
  // font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  text-decoration-line: underline;
  cursor: pointer;
  &.inactive {
    color: var(--Neutral-60, var(--Color-Neutal-Neutral-60, #9e9e9e));
  }
}

.nullLink {
  color: var(--Neutral-60, var(--Color-Neutal-Neutral-60, #9e9e9e));
  // font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}
