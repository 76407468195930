@import "../../../../../../../theme/variables.scss";

.container {
  background-color: $NEUTRAL_LIGHTEST;
  border-radius: 20px 20px 0px 0px;
  width: 100%;
  padding-top: 15px;
  padding-inline: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 35px;

  > * {
    width: 100%;
  }

  .topLine {
    background-color: #b4b7ba;
    height: 5px;
    width: 42px;
    border-radius: 5px;
  }

  .header {
    padding-top: 17px;
    text-align: center;
    padding-bottom: 20px;

    h3 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      text-align: center;
      color: $NEUTRAL_DARKEST;
    }
  }

  .infoWrapper {
    display: flex;
    flex-direction: column;
    padding-bottom: 24px;

    > * {
      h4 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: $NEUTRAL_100;
        padding-bottom: 8px;
      }
    }

    .profile {
      display: flex;
      align-items: center;
      gap: 8px;
      padding-bottom: 8px;
      border-bottom: $NEUTRAL_LINE;

      .avatar {
        width: 34px;
        height: 34px;
      }

      .desc {
        display: flex;
        flex-direction: column;
        gap: 4px;

        span {
          font-family: "Poppins";
          font-style: normal;

          &:first-child {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: $NEUTRAL_DARKEST;
          }

          &:last-child {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: $NEUTRAL_60;
          }
        }
      }
    }

    .contact {
      padding-block: 8px;
      border-bottom: $NEUTRAL_LINE;

      .stacked {
        display: flex;
        align-items: center;
        gap: 6px;

        .icon {
          color: $NEUTRAL_50;
        }

        span {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 20px;
          color: $NEUTRAL_DARKEST;
        }
      }
    }

    .rating {
      padding-block: 8px;
      border-bottom: $NEUTRAL_LINE;
    }

    .status {
      padding-top: 8px;

      .active,
      .inactive {
        display: inline-block;
        padding: 8px 16px;
        border-radius: 4px;

        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
      }

      .active {
        color: $ACTIVE_MAIN;
        background-color: $ACTIVE_SURFACE;
        border: 1px solid $ACTIVE_MAIN;
      }

      .inactive {
        color: $DANGER_MAIN;
        background-color: $DANGER_SURFACE;
        border: 1px solid $DANGER_MAIN;
      }
    }
  }

  > button {
    height: 40px;
    background: $PRIMARY_MAIN;
    border: none;
    border-radius: 5px;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: $NEUTRAL_10;
  }
}
