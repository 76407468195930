@import "../../../../theme/variables.scss";

.container {
  width: 400px;

  &.wide {
    width: 658px;
  }

  .header {
    background-color: $PRIMARY_SURFACE;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 8px 8px 0 0;

    >img {
      height: 146px;
    }

    >h3 {
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
      color: $PRIMARY_MAIN;
    }
  }

  .content,
  .newContent {
    background-color: $NEUTRAL-10;
    border-radius: 0 0 8px 8px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 32px;

    .actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 8px;

      >button {
        padding: 12px 16px;
        border-radius: 8px;
        border: 1px solid $PRIMARY_MAIN;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 142.857%;

        &:first-child {
          background-color: $NEUTRAL-10;
          color: $PRIMARY_MAIN;
        }

        &:last-child {
          background-color: $PRIMARY_MAIN;
          color: $NEUTRAL-10;

          &:disabled {
            background-color: $PRIMARY_DISABLED;
            border-color: $PRIMARY_DISABLED;
          }
        }


      }
    }

    .ncHeader {
      display: flex;
      align-items: center;
      gap: 8px;
      justify-content: space-between;

      >button {
        display: flex;
        align-items: center;
        gap: 8px;
        background-color: transparent;
        border: none;
        font-size: 14px;

        &:disabled {
          color: $NEUTRAL-50;
        }
      }

      >span {
        font-size: 12px;
        color: $NEUTRAL-60;
      }
    }
  }


}