@import "../../../../theme/variables.scss";

.containerModal {
  height: 100vh;
  width: 100vw;
  background-color: #00000080;
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
}

.boxModal {
  border-radius: 8px;
  background: var(--neutral-10, #fff);

  /* Big Card Shadow */
  box-shadow: 0px 4px 15px 4px rgba(0, 0, 0, 0.03);
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 40%;

  .inputForm {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;

    input {
      //   display: flex;
      padding: 12px;
      border-radius: 8px;
      border: 1px solid #e6eaec;
      background: #fff;
      width: 100%;

      //   gap: 10px;
      //   align-self: stretch;
    }

    input:focus {
      outline: none !important;
      border-width: 1px;
      border-style: solid;
      border-color: #1571de;
    }

    .label {
      color: var(--neutral-70, #757575);

      /* Caption 1/Medium */
      //   font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      /* 133.333% */
    }

    .limitIndicator {
      display: flex;
      align-items: center;
      gap: 16px;
      justify-content: space-between;
      padding-top: 4px;

      >span {
        &:first-child {
          font-family: Poppins;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          color: $NEUTRAL_60;
        }

        &:last-child {
          font-family: Poppins;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }
      }
    }
  }

  .top {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
  }

  .textModal {
    color: var(--neutral-100, #0a0a0a);

    /* Heading 7/SemiBold */
    // font-family: Poppins;
    font-size: calc(14px + (20 - 14) * ((100vw - 280px) / (1600 - 280)));
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    /* 30px */
    letter-spacing: 0.1px;
  }

  .buttonBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .button {
      display: flex;
      align-items: center;
      gap: 8px;

      .cancel {
        cursor: pointer;
        display: flex;
        padding: 12px;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        border: 1px solid var(--primary-main, #1571de);
        background: var(--neutral-10, #fff);
        color: var(--primary-main, #1571de);
        text-align: center;

        /* Text M/Reguler */
        // font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        /* 142.857% */
      }

      .save {
        cursor: pointer;
        display: flex;
        padding: 14px;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        background: var(--primary-main, #1571de);
        color: #fff;
        text-align: center;

        /* Text M/Reguler */
        // font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        /* 142.857% */
      }
    }
  }
}

.saveDisabled {
  display: flex;
  padding: 14px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: var(--primary-disabled, #91bdf1);
  // background: var(--primary-main, #1571de);
  border: none;
  color: #fff;
  text-align: center;

  /* Text M/Reguler */
  // font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
}

@media screen and (max-width: 900px) {
  .boxModal {
    width: 80%;
  }
}

@media screen and (max-width: 540px) {
  .containerModal {
    // justify-content: flex-end;
    // align-items: flex-end;
    padding: 0px;
  }

  .boxModal {
    position: fixed;
    bottom: 0;
    width: 100%;
    // height: 100%;
  }

  .saveDisabled {
    width: 100%;
  }

  .buttonBox {
    width: 100%;

    .button {
      width: 100%;

      .cancel {
        width: 100%;
      }

      .save {
        width: 100%;
      }
    }
  }
}