@import "../../../../../theme/variables.scss";

.container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  >* {
    width: 80%;
  }

  h2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #333333;
  }

  p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    color: #A4A4A4;
  }
}

@media screen and (max-width: 768px) {
  .container {
    padding-bottom: 80px;

    >* {
      width: 95%;
    }
  }
}