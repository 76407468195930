@import "../../../../../../../../../theme/variables.scss";

.container {
  width: 100%;
  min-height: 100%;
  border-radius: 8px;
  background-color: $NEUTRAL_10;
  padding: 24px;

  h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
    letter-spacing: 0.005em;
    color: $NEUTRAL_100;
  }

  .content {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    // align-items: center;
    gap: 16px;
    padding-top: 16px;

    >* {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 12px;
      border: 1px solid $NEUTRAL-40;
      border-radius: 8px;
      background-color: $NEUTRAL-10;
      cursor: pointer;

      &.disabled {
        background-color: $NEUTRAL-20;
        cursor: auto;
      }

      &.error {
        border-color: #F5610D !important;
      }

      >span {
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 142.857%;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    padding: 0;
    background-color: transparent;

    h3 {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 140%;
    }

    .content {
      padding-top: 8px;
      grid-template-columns: 1fr;
    }
  }
}