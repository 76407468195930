.main {
  .autoScroll {
    display: hidden;
    position: absolute;
    top: 0;
  }

  > * {
    // &.locationSection {

    // background-color: red;
    // }

    &:not(.homeLanding, .links, .jobSeekerListSection, .testimonialSection) {
      max-width: calc(1440px);
      margin-inline: auto;
    }
  }

  .homeLanding {
    position: relative;
  }

  .section {
    display: flex;
    flex-direction: column;
    padding-inline: 40px;
  }

  > div {
    // padding-inline: 40px;
  }
  .jobSeekerListSection {
    padding-top: 40px;
    max-width: 1440px;
    margin: auto;
  }
  .mobileAppAdSection {
    margin-top: 180px;
    margin-bottom: 100px;
  }
  .locationSection {
    margin-bottom: 120px;
  }
  > :last-child {
    transform: translateY(20px);
  }
  .loadingContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    //   position: relative;
    //   top: 250px;
    height: 100vh;
  }
  > :first-child {
    padding-inline: 0;
  }
}

.getApp {
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 768px) {
  .main {
    > div {
      // padding-inline: 18px !important;
    }

    .section {
      padding-inline: 18px;
    }
  }
}
