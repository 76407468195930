@import "../../../theme/variables.scss";

.boxDropdown {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.optionsWrapper {
  position: absolute;
  top: 50px;
  width: 100%;
  background-color: $NEUTRAL_10;
  border-radius: 8px;

  box-shadow: 0px 0px 1px rgba(9, 30, 66, 0.31),
    0px 3px 5px rgba(9, 30, 66, 0.2);
  padding: 12px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 2;
  .noFound {
    color: var(--Neutral-60, var(--Color-Neutal-Neutral-60, #9e9e9e));

    /* Caption 1/Reguler */
    // font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .loading {
    color: var(--Neutral-60, var(--Color-Neutal-Neutral-60, #9e9e9e));

    /* Caption 1/Reguler */
    // font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
  }

  .optionSelect {
    display: flex;
    flex-direction: column;
    gap: 8px;
    .options {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
      border-bottom: 1px solid #ededed;
      padding-bottom: 8px;
      cursor: pointer;
      &.noBorder {
        border: none;
        padding-bottom: 0px;
      }
      .left {
        display: flex;
        align-items: center;
        gap: 8px;
        width: 100%;
        > div:first-child {
          border-radius: 36px;
          img {
            width: 36px;
            height: 36px;
            border-radius: 36px;
            object-fit: cover;
          }
        }

        > div:last-child {
          display: flex;
          flex-direction: column;
          gap: 2px;
          > span:first-child {
            color: var(--Neutral-90, var(--Color-Neutal-Neutral-90, #404040));

            /* Text M/Medium */
            // font-family: Poppins;
            font-size: calc(
              12px + (14 - 12) * ((100vw - 280px) / (1600 - 280))
            );
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 142.857% */
          }

          > span:last-child {
            color: var(--Neutral-60, var(--Color-Neutal-Neutral-60, #9e9e9e));

            /* Caption 1/Reguler */
            // font-family: Poppins;
            font-size: calc(
              10px + (12 - 10) * ((100vw - 280px) / (1600 - 280))
            );
            font-style: normal;
            font-weight: 400;
            line-height: 16px; /* 133.333% */
          }
        }
      }

      .right {
        width: 50%;
        > span {
          border-radius: 8px;
          border: 1px solid var(--Primary-Main, #1571de);
          background: var(--Primary-Surface, #f5f7fe);
          padding: 4px 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: var(--Primary-Main, #1571de);
          text-align: center;

          /* Caption 1/Medium */
          // font-family: Poppins;
          font-size: calc(10px + (12 - 10) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 500;
          line-height: 16px; /* 133.333% */
        }
      }
    }
  }
}

.container {
  display: flex;
  height: 100%;
  width: 100%;
  border-radius: 8px;
}

.inputWrapper {
  width: calc(100% - 40px);
  height: 100%;
  align-items: center;
  display: flex;
  position: relative;
}

.clearButton,
.spinner {
  position: absolute;
  right: 10px;
  top: 15px;
  display: grid;
  place-items: center;
  padding: 0;
  background-color: transparent;
  border: none;
  &.clearButtonBS {
    right: -5px;
  }
  &.clearButtonPosition {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 4px;
    top: 10px;
    width: 50%;
    > span {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      border: 1px solid var(--Primary-Main, #1571de);
      background: var(--Primary-Surface, #f5f7fe);
      padding: 4px 8px;
      color: var(--Primary-Main, #1571de);
      text-align: center;
      // max-width: 100px;

      /* Caption 1/Medium */
      // font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px; /* 133.333% */
    }
  }

  &.spinner {
    right: 60px;
    top: 10px;
  }
}

.input {
  width: 100%;
  height: 100%;
  padding: 0 12px 0 12px;
  border-radius: 8px 0 0 8px;
  border: 1px solid $NEUTRAL_40;
  border-right: 0;
  transition: 0.2s;
  &.error {
    border-top: 1px solid red;
    border-left: 1px solid red;
    border-bottom: 1px solid red;
  }
}

.input:focus {
  outline: none !important;
  border-color: $PRIMARY_MAIN;
  border-width: 1px 0 1px 1px;
  border-style: solid;
}
.inputNone:focus {
  outline: none !important;
  border-color: $NEUTRAL_40;
  border-width: 1px 0 1px 1px;
  border-style: solid;
}
.searchWrapper {
  width: 40px;
  background-color: $PRIMARY_MAIN;
  border-radius: 0 8px 8px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &.searchNone {
    background: transparent;
    border-right: 1px solid $NEUTRAL_40;
    border-top: 1px solid $NEUTRAL_40;
    border-bottom: 1px solid $NEUTRAL_40;
    &.error {
      border-right: 1px solid red;
      border-top: 1px solid red;
      border-bottom: 1px solid red;
    }
  }
}

::placeholder {
  color: $NEUTRAL_60;
  font-size: 12px;
  font-weight: 400;
}

.searchIcon {
  color: $NEUTRAL_10;
  &.searchIconNone {
    color: black;
  }

  &.searchIconBS {
    color: #e0e0e0;
  }
}
