@import "../../../../../../../theme/variables.scss";

.container {

  h3{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: $NEUTRAL_DARKEST;
    padding-bottom: 15px;

  }

  .content {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    row-gap: 20px;
    column-gap: 15px;

    .autocompleteFieldWrapper {

      >:first-child {
        padding-bottom: 4px;
        font-size: 13px;
        font-weight: 600;
        color: $NEUTRAL_100;

      }
    }

    .stackedField {
      display: flex;
      flex-direction: column;
      gap: 11px;
    }



    .floatingText {

      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      text-align: center;
      color: #A3A3A3;    }
  }
}

@media screen and (max-width: 768px) {
  .content {
    grid-template-columns: 1fr!important;
  }
}