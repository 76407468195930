@import "../../../../../theme/variables.scss";

.container {
  background-color: $NEUTRAL_10;
  border-radius: 8px;
  width: 440px;

  .header {
    padding: 16px;
    padding-bottom: 8px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    >h3 {
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
      color: $PRIMARY_MAIN;
    }

    .searchWrapper {
      height: 40px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    padding: 16px;
    padding-top: 8px;
    height: 438px;
    overflow: auto;

    >div {
      display: flex;
      align-items: center;
      padding-block: 10px;
      border-bottom: 1px solid $NEUTRAL_30;

      >span {
        flex-grow: 1;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }

  .footer {
    position: sticky;
    bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    padding: 16px;
    border-top: 1px solid $NEUTRAL_30;

    >button {
      padding: 8px 16px;
      border-radius: 8px;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      border: 1px solid $PRIMARY_MAIN;

      &:first-child {
        background-color: $NEUTRAL_10;
        color: $PRIMARY_MAIN;
      }

      &:last-child {
        background-color: $PRIMARY_MAIN;
        color: $NEUTRAL_10;
      }
    }
  }


}
