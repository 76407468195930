@import "../../../../theme/variables.scss";

.container {
  background-color: $NEUTRAL-10;
  // display: flex;
  // flex-direction: column;
  width: 600px;
  max-width: 100vw;
  height: 406px;
  max-height: 85vh;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;

  .header {
    display: grid;
    // flex-direction: column;
    grid-template-columns: 1fr;
    border-bottom: 1px solid $NEUTRAL-30;

    >h3 {
      padding: 16px;
      padding-bottom: 12px;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
      color: $PRIMARY_MAIN;
    }

    >span {
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 142.857%;
      padding-inline: 16px;
      padding-bottom: 12px;
    }



    .regionsSwitcher {
      display: flex;
      align-items: center;
      gap: 32px;
      overflow: auto;

      >div {
        white-space: nowrap;
        padding: 13px 8px;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 142.857%;
        color: $NEUTRAL-50;
        cursor: pointer;
        transition: all 0.3s;

        &.active {
          color: #2E3192;
          box-shadow: 0px -2px 0px 0px #2B6ED3 inset;
        }
      }
    }
  }

  .content {
    overflow: auto;
    padding: 16px;

    .qualifications {
      display: flex;
      flex-direction: column;
      gap: 16px;
      position: relative;

      .autoScroll {
        position: absolute;
      }

      .qualification {
        display: flex;
        align-items: center;
        gap: 4px;

        >p {

          >span {
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 142.857%;

            &:first-child {
              color: $NEUTRAL_70;
            }

            &:last-child {
              color: $NEUTRAL_100;
            }
          }
        }


      }
    }

    .form {
      display: grid;
      grid-template-columns: 1fr;
      gap: 16px;

      >button {
        padding: 8px;
        border-radius: 6px;
        background-color: transparent;
        border: 1px solid $PRIMARY_MAIN;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        color: $PRIMARY_MAIN;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 142.857%;
      }

      .fQualification {
        display: grid;
        grid-template-columns: 1fr 1fr auto;
        gap: 8px;

        >*:last-child {
          display: flex;
          align-items: flex-end;
          padding-bottom: 8px;

          >button {
            padding: none;
            background-color: transparent;
            border: none;
            color: $DANGER_MAIN;

            &:disabled {
              color: $NEUTRAL-50;
            }
          }
        }
      }
    }
  }

  .actions {
    padding: 16px;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
    display: grid;
    grid-auto-flow: column;
    gap: 8px;

    >button {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      background-color: $PRIMARY_MAIN;
      color: $NEUTRAL-10;
      padding: 12px 16px;
      border-radius: 8px;
      border: 1px solid $PRIMARY_MAIN;

      &:disabled {
        background-color: $PRIMARY_DISABLED;
        border-color: $PRIMARY_DISABLED;
      }

      &.cancel {
        background-color: $NEUTRAL-10;
        color: $PRIMARY_MAIN;
      }

      >span {
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 142.857%;
        border: none;
        border-radius: 8px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {

    .content {

      .form {

        .fQualification {
          grid-template-columns: 1fr !important;
          position: relative;

          &:not(:last-child) {
            border-bottom: 1px solid $NEUTRAL-40;
            padding-bottom: 16px;
          }

          >*:last-child {
            position: absolute;
            right: 0;
          }
        }
      }
    }

    .actions {
      padding-bottom: 45px;
    }
  }
}