@import "../../../../../../../../theme/variables.scss";

.container {
  background-color: $NEUTRAL_LIGHTEST;
  border-radius: 20px 20px 0px 0px;
  width: 100%;
  padding-top: 15px;
  padding-inline: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 35px;

  > * {
    width: 100%;
  }

  .topLine {
    background-color: #b4b7ba;
    height: 5px;
    width: 42px;
    border-radius: 5px;
  }

  .header {
    padding-top: 17px;
    text-align: center;
    padding-bottom: 20px;

    h3 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      text-align: center;
      color: $NEUTRAL_DARKEST;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 20px;

    > * {
      padding-bottom: 8px;

      &:first-child {
        border-bottom: $NEUTRAL_LINE;
      }

      span {
        padding-bottom: 8px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 14px !important;
        line-height: 20px;
        color: $NEUTRAL_DARKEST;
      }
    }
  }
}
