@import "../../../theme/variables.scss";

.container {
  position: relative;
  background: $NEUTRAL-10;
  border-radius: .4em;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  padding: 8px;

  &:not(.noTip) {
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 20px;
      width: 0;
      height: 0;
      border: 8px solid transparent;
      border-bottom-color: $NEUTRAL-10;
      border-top: 0;
      margin-left: -8px;
      margin-top: -8px;
    }
  }



  >span {
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 133.333%;
    color: #475569;
  }
}