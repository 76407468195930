.body {
  display: flex;
  flex-direction: column;
  width: 80vw;
  gap: 32px;
}

.topList {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  .titleListBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    .h1 {
      color: #000;
      text-align: center;

      /* Heading 5/Bold */
      //   font-family: Poppins;
      font-size: calc(20px + (26 - 20) * ((100vw - 280px) / (1600 - 280)));
      font-style: normal;
      font-weight: 700;
      line-height: 140%; /* 36.4px */
      letter-spacing: 0.13px;
    }
    .p {
      color: var(--neutral-60, #9e9e9e);
      text-align: center;

      /* Text M/Reguler */
      // font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
    }
  }

  .filterViewBox {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .filter {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid var(--neutral-40, #e0e0e0);
    background: var(--neutral-20, #f5f5f5);
    cursor: pointer;
    &.activeButton {
      border-radius: 8px;
      border: 1px solid var(--primary-main, #1571de);
      background: var(--primary-surface, #f5f7fe);
    }
    .text {
      &.activeText {
        color: var(--primary-main, #1571de);
      }
      color: var(--neutral-90, #404040);
      text-align: center;

      /* Text M/Reguler */
      // font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
    }
  }
}

.buttonBox {
  display: flex;
  align-items: center;
  justify-content: center;
  .textButton {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    border-radius: 8px;
    border: 1px solid var(--primary-main, #1571de);
    background: var(--neutral-10, #fff);
    cursor: pointer;
    color: var(--primary-main, #1571de);
    text-align: center;

    /* Text M/Reguler */
    // font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
}
