@import "../../../theme/variables.scss";

.container {
  border-radius: 8px;
  border: 1px solid $NEUTRAL_40;
  display: flex;
  flex-direction: column;
  width: 356px;

  .header {
    background-color: #dcf6ff;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    position: relative;
    display: flex;
    height: 146px;

    .left {
      width: 60%;
      display: flex;
      flex-direction: column;
      padding: 16px 0 16px 16px;
      justify-content: space-between;
      // gap: 48px;

      .title {
        width: 100%;
        font-size: 16px;
        font-weight: 600;
        color: #000;
      }

      .tag {
        border-radius: 4px;
        padding: 4px 8px;
        background-color: #78d1f2;
        width: fit-content;

        .tagName {
          font-size: 12px;
          font-weight: 400;
          color: $NEUTRAL_10;
        }
      }
    }

    .right {
      width: 40%;
      position: relative;

      img {
        position: absolute;
        bottom: 0;
        right: 0;
        height: 100%;
        width: fit-content;
        z-index: 1;
      }

      .thumbnail {
        z-index: 2;
        position: absolute;
        bottom: 0;
        right: 0;
        height: 100%;

        img {
          height: 100%;
          width: fit-content;
        }
      }
    }
  }

  .body {
    height: fit-content;
    background-color: $NEUTRAL_10;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // gap: 16px;
    height: 182px;

    .contentWrapper {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .title {
        font-size: 16px;
        font-weight: 600;
        color: #000;
      }

      .information {
        font-size: 14px;
        font-weight: 400;
        color: #404040;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }

    .creatorWrapper {
      display: flex;
      align-items: center;
      gap: 8px;

      .creator {
        display: flex;
        gap: 4px;

        .creatorName {
          font-size: 12px;
          font-weight: 500;
          color: #9e9e9e;
        }

        .picture {
          height: 16px;
          width: 16px;
          border-radius: 50%;
          object-fit: contain;

          img {
            height: 100%;
          }
        }
      }

      .createdAt {
        font-size: 12px;
        font-weight: 400;
        color: #9e9e9e;
      }
    }
  }
}
