@import "../../../../../theme/variables.scss";

.container {
  padding: 24px 30px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;

  .emptyCard {
    background-color: $NEUTRAL_10;
    padding: 16px;
    border-radius: 8px;
    text-align: center;
    color: $NEUTRAL_60;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 500;
  }

  .autoScroll {
    display: hidden;
    position: absolute;
    top: 0;
  }

  .schemeCard {
    background-color: $NEUTRAL_10;
    padding: 16px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 25px;

    .left {
      flex-shrink: 0;

      .icon {
        color: $NEUTRAL_70;
      }
    }

    .middle {
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      h5 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 140%;
        color: $NEUTRAL_100;
        padding-bottom: 4px;
      }

      >span {
        font-family: 'Poppins';
        font-style: normal;
        font-size: 14px;
        line-height: 20px;

        &:nth-child(2) {
          font-weight: 500;
          padding-bottom: 2px;
          color: $NEUTRAL_100;
        }

        &:last-child {
          padding-top: 4px;
          color: $NEUTRAL_60;
        }
      }

      .location {
        display: flex;
        align-items: center;
        gap: 4px;
        color: $PRIMARY_MAIN;
        padding-bottom: 8px;

        >*:first-child {
          flex-shrink: 0;
        }

        span {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;

        }
      }
    }

    .right {
      button {
        background-color: transparent;
        border: none;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: $PRIMARY_MAIN;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    padding: 16px 24px;

    .schemeCard {

      .left {
        height: 100%;
        position: relative;
      }
    }
  }
}