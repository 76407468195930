@import "../../../theme/variables.scss";

.container {
  display: flex;
  align-items: center;
  gap: 12px;

  &.button {
    cursor: pointer;
  }

  > img {
    aspect-ratio: 1;
    object-fit: cover;
    border-radius: 50%;
    flex-shrink: 0;
  }

  .desc {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .title {
      display: flex;
      align-items: center;
      gap: 4px;

      > span {
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        color: $NEUTRAL_DARKEST;
      }
    }

    .label {
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      color: $NEUTRAL_60;
    }
  }
}
