@import "../../../../../theme/variables.scss";

.showOfferingCard {
  border-radius: 8px;
  border: 1px solid $NEUTRAL_40;

  > * {
    padding: 16px;
  }

  .button {
    display: flex;
    align-items: center;
    gap: 12px;

    > span {
      flex-grow: 1;
      font-family: Poppins;
      @include font-size(16, 13, 13px);
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
    }
  }

  .content {
    padding-top: 0;
  }
}
.pkmTextDate {
  color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));

  /* Text M/Medium */
  // font-family: Poppins;
  font-size: calc(8px + (14 - 8) * ((100vw - 280px) / (1600 - 280)));
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}
.notesContainer {
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: calc(596px + 32px);
  overflow: scroll !important;
  gap: 32px;
  margin: auto;
  padding: 32px 16px;
  height: 100vh;
  // min-height: 100%;
  // position: relative;
  // max-height: 100vh;

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;

    > img {
      @include height(71, 48, 48px);
      object-fit: contain;
    }

    .title {
      display: flex;
      flex-direction: column;
      gap: 12px;

      > h3 {
        font-family: Poppins;
        // font-size: 32px;
        @include font-size(32, 24, 24px);
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        letter-spacing: 0.16px;
      }

      > span {
        font-family: Poppins;
        @include font-size(14, 12, 12px);
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    @include gap(32, 16, 16px);

    .each {
      display: flex;
      flex-direction: column;

      > span {
        font-family: Poppins;
        @include font-size(24, 16, 16px);
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
        letter-spacing: 0.12px;
      }

      > p {
        font-family: Poppins;
        @include font-size(14, 12, 12px);
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
      }

      .box {
        padding-top: 12px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        @include gap(24, 16, 16px);

        > div {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 10px;

          > img {
            width: 100%;
            aspect-ratio: 3/4;
          }
        }
      }
    }
  }

  .button {
    height: 36px;
    background: $PRIMARY_MAIN;
    border-radius: 8px;
    border: none;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $NEUTRAL_10;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:disabled {
      background-color: $PRIMARY_DISABLED;
    }
  }
}

.notesContainer::-webkit-scrollbar {
  display: none;
}

.timeExpOfferingCandidate {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px;
  background-color: $ACTIVE_SURFACE;
  color: #ffa928;
  border-radius: 4px;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  > *:first-child {
    flex-shrink: 0;
  }
}

.agreed {
  display: flex;
  align-items: center;
  gap: 12px;

  > span {
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
}

.container {
  .left {
    position: fixed;
    height: 100vh;
    width: 45%;
    background-image: linear-gradient(
        159.91deg,
        #1571de -1.6%,
        rgba(21, 113, 222, 0.57) 124.46%
      ),
      url(../../../../../assets//Images/background-linear-gradient.png);
    background-size: cover;
    padding: 2%;
    display: grid;
    place-items: center;

    .topWrapper {
      display: flex;
      align-items: center;
      gap: 16px;
      position: absolute;
      top: 40px;
      left: 30px;

      .logoWrapper {
        background-color: $NEUTRAL_20;
        width: 52px;
        height: 52px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 25px;
        }
      }

      span {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: calc(14px + (18 - 14) * ((100vw - 280px) / (1600 - 280)));
        line-height: 150%;
        color: $NEUTRAL_10;
      }
    }

    .box {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding-block: 10%;
      gap: 24px;

      > img {
        width: 57%;
      }

      .textLeft {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;

        .nameOffer {
          display: flex;
          flex-direction: column;
          gap: 4px;
        }
      }

      h3 {
        color: #fff;
        text-align: center;

        /* Heading 5/Reguler */
        // font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
        /* 36.4px */
        letter-spacing: 0.13px;
        // padding-bottom: 24px;
      }

      p {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        text-align: center;
        color: $NEUTRAL_10;
      }

      .position {
        color: #e5dd1c;

        /* Text L/Bold */
        // font-family: Poppins;
        font-size: calc(14px + (16 - 14) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
      }

      .nameCandidate {
        color: #e5dd1c;
        text-align: center;

        /* Heading 4/Bold */
        // font-family: Poppins;
        // font-size: 32px;
        font-size: calc(14px + (32 - 14) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
        /* 44.8px */
        letter-spacing: 0.16px;
      }
    }
  }

  .right {
    margin-left: 45%;
    width: 55%;
    display: flex;
    flex-direction: column;
    // align-items: center;
    // justify-content: center;
    height: 100vh;
    padding: 4%;
    gap: 24px;
    overflow-y: scroll;

    > * {
      width: 100%;
    }

    .header {
      display: flex;
      flex-direction: column;
      gap: 12px;

      h3 {
        font-family: Poppins;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        letter-spacing: 0.16px;
      }

      p {
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      gap: 32px;

      .contractDate,
      .offering,
      .closure {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .H4 {
          color: #000;

          /* Heading 5/SemiBold */
          // font-family: Poppins;
          font-size: calc(14px + (26 - 14) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 600;
          line-height: 140%;
          /* 36.4px */
          letter-spacing: 0.13px;
        }

        .p {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          // color: #6f6f70;
        }

        .dateCard {
          display: flex;
          align-items: center;
          gap: 32px;
          background-color: $PRIMARY_SURFACE;
          border-radius: 8px;
          padding: 8px 12px;

          .range {
            display: flex;
            flex-direction: column;

            > span {
              &:first-child {
                font-family: Poppins;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 21px;
                color: $NEUTRAL_60;
              }

              &:last-child {
                font-family: Poppins;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: 150%;
                letter-spacing: 0.1px;
              }
            }
          }
        }

        .detailsCard {
          @include padding(24, 16, 16px);
          background-color: $NEUTRAL-10;
          border: 1px solid $NEUTRAL-40;
          border-radius: 12px;
          display: flex;
          flex-direction: column;
          gap: 12px;

          > span {
            font-family: Poppins;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 150%;
            letter-spacing: 0.1px;
          }

          .cardHeader {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 8px;
            border-radius: 8px;
            background-color: $NEUTRAL_20;
            gap: 16px;

            > span {
              font-family: Poppins;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 20px;
              color: $NEUTRAL_80;
            }
          }

          .cardList {
            display: flex;
            flex-direction: column;
            gap: 12px;

            .each {
              display: flex;
              align-items: center;
              justify-content: space-between;
              // flex-wrap: wrap;
              gap: 16px;
              // overflow-x: scroll;

              > div {
                display: flex;
                align-items: center;
                padding-left: 4px;

                > *:first-child {
                  color: $PRIMARY_MAIN;
                }

                > span {
                  &:nth-child(2) {
                    font-family: Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 21px;
                    color: $NEUTRAL_70;
                  }

                  &:nth-child(3) {
                    font-family: Poppins;
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 16px;
                    color: $NEUTRAL_50;
                  }
                }
              }

              > span {
                font-family: Poppins;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: 150%;
                letter-spacing: 0.1px;
              }
            }
          }

          .divider {
            height: 1px;
            background-color: $NEUTRAL_100;
          }

          .cardFooter {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 16px;

            > span {
              &:first-child {
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 21px;
                color: $NEUTRAL_70;
              }

              &:last-child {
                font-family: Poppins;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: 140%;
                letter-spacing: 0.12px;
                color: $PRIMARY_MAIN;
              }
            }
          }
        }
      }
    }

    .buttonBox {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    .buttonReject {
      // height: 36px;
      display: flex;
      padding: 12px;
      align-items: center;
      justify-content: center;
      color: var(--primary-main, #1571de);
      text-align: center;
      // font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      /* 142.857% */
      text-decoration-line: underline;
      cursor: pointer;
    }

    .buttonContact {
      display: flex;
      align-items: center;
      justify-content: center;
      background: $PRIMARY_MAIN;
      border-radius: 8px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $NEUTRAL_10;
      gap: 4px;
      height: 36px;
      cursor: pointer;
    }

    .buttonNext {
      height: 36px;
      // left: 0px;
      // top: 130px;
      background: $PRIMARY_MAIN;
      border-radius: 8px;
      border: none;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $NEUTRAL_10;

      &:disabled {
        background-color: $PRIMARY_DISABLED;
      }

      &.dNone {
        display: none;
      }
    }
  }
}

.finishBox {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  .buttonNext {
    width: 100%;
  }
}

.buttonNext {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .container {
    min-height: 100vh;

    .left {
      position: relative;
      height: auto;
      min-height: 35vh;
      width: 100%;
      background-image: linear-gradient(
          159.91deg,
          #1571de -1.6%,
          rgba(21, 113, 222, 0.57) 124.46%
        ),
        url(../../../../../assets//Images/background-linear-gradient.png);
      background-size: cover;
      padding: 5%;
      display: grid;
      place-items: center;

      .topWrapper {
        display: flex;
        // flex-direction: column;
        align-items: center;
        gap: 16px;
        position: absolute;
        top: 16px;
        left: 5%;

        .logoWrapper {
          background-color: $NEUTRAL_20;
          width: 24px;
          height: 24px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 40%;
          }
        }

        span {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 150%;
          color: $NEUTRAL_10;
        }
      }

      .box {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        // gap: 24px;
        padding-block: 0;
        // gap: 5%;
        padding-top: 12%;

        .textLeft {
          gap: 10px;
        }

        > img {
          width: 80%;
          // width: 309px;
          // height: 188px;
        }

        h3 {
          font-size: 16px;
        }

        p {
          font-size: 14px;
        }
      }
    }

    .right {
      margin-left: 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: auto;
      padding: 5%;
      padding-top: 32px;

      > * {
        width: 100%;
      }

      // .H4 {
      //   // font-size: 16px;
      // }

      p {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #6f6f70;
        padding-bottom: 32px;
      }

      button {
        height: 36px;
        left: 0px;
        top: 130px;
        background: $PRIMARY_MAIN;
        border-radius: 8px;
        border: none;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $NEUTRAL_10;
      }
    }
  }
}
