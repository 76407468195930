@import "../../../../../../theme/variables.scss";

.containerModal {
  .header {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px 24px;
    position: sticky;
    top: 70px;
    border-bottom: 1px solid var(--Color-Neutal-Neutral-40, #e0e0e0);
    background: var(--Color-Neutal-Neutral-20, #f5f5f5);
    z-index: 2;
    > div:first-child {
      display: flex;
      align-items: center;
      gap: 16px;
      .iconArrow {
        width: 30px;

        height: 30px;
        border-radius: 80px;
        background: var(--Color-Neutal-Neutral-10, #fff);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      .router {
        display: flex;
        align-items: center;
        gap: 4px;
        > span:first-child {
          color: var(--Neutral-60, var(--Color-Neutal-Neutral-60, #9e9e9e));
          //   font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }

        > div:nth-child(2) {
          width: 18px;
          height: 18px;
          position: relative;
          top: 1px;
        }

        > span:last-child {
          color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));
          //   font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          position: relative;
          top: 1px;
        }
      }
    }

    > div:last-child {
      color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));

      /* Heading 7/Bold */
      //   font-family: Poppins;
      font-size: calc(16px + (20 - 16) * ((100vw - 280px) / (1600 - 280)));
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 30px */
      letter-spacing: 0.1px;
    }
  }

  .contents {
    padding: 16px 24px;
    > div {
      padding: 24px;
      display: flex;
      flex-direction: column;
      gap: 24px;
      border-radius: 12px;
      background: var(--Color-Neutal-Neutral-10, #fff);
      .inputSection {
        display: flex;
        flex-direction: column;
        gap: 20px;
        > div:first-child {
          display: flex;
          align-items: center;
          gap: 8px;
          .images {
            > img {
              width: 60px;
              height: 60px;
              border-radius: 60px;
              object-fit: cover;
            }
          }

          .nameCandidate {
            display: flex;
            flex-direction: column;
            gap: 2px;
            > span:first-child {
              display: flex;
              align-items: center;
              gap: 8px;
              color: var(
                --Neutral-100,
                var(--Color-Neutal-Neutral-19, #0a0a0a)
              );

              /* Heading 7/Bold */
              //   font-family: Poppins;
              font-size: calc(
                16px + (20 - 16) * ((100vw - 280px) / (1600 - 280))
              );
              font-style: normal;
              font-weight: 700;
              line-height: 150%; /* 30px */
              letter-spacing: 0.1px;
              > div {
                width: 10px;
                height: 10px;
                background-color: #0a0a0a;
                border-radius: 50px;
              }
            }

            > span:last-child {
              display: flex;
              align-items: center;
              gap: 4px;
              color: var(
                --Neutral-100,
                var(--Color-Neutal-Neutral-19, #0a0a0a)
              );

              /* Text M/Reguler */
              //   font-family: Poppins;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;
              > div {
                width: 20px;
                position: relative;
                top: 2px;
              }
            }
          }
        }

        > div:nth-child(2) {
          display: flex;
          flex-direction: column;
          gap: 8px;
          > span {
            color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));

            /* Text L/SemiBold */
            // font-family: Poppins;
            font-size: calc(
              14px + (16 - 14) * ((100vw - 280px) / (1600 - 280))
            );
            font-style: normal;
            font-weight: 600;
            line-height: 140%; /* 22.4px */
          }

          .range {
            display: flex;
            // align-items: center;
            gap: 8px;
            .searchWrapper {
              width: 100%;
            }

            .separator {
              color: #000;
              position: relative;
              top: 10px;

              /* Text S/Reguler */
              //   font-family: Poppins;
              font-size: 13px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px; /* 153.846% */
            }
          }
        }

        > div:nth-child(3) {
          display: flex;
          flex-direction: column;
          gap: 8px;

          > span {
            color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));

            /* Text L/SemiBold */
            // font-family: Poppins;
            font-size: calc(
              14px + (16 - 14) * ((100vw - 280px) / (1600 - 280))
            );
            font-style: normal;
            font-weight: 600;
            line-height: 140%; /* 22.4px */
          }

          .requirements {
            display: flex;
            align-items: center;
            gap: 8px;
            .searchWrapper {
              width: 100%;
            }
          }
        }

        > div:nth-child(4) {
          display: flex;
          flex-direction: column;
          gap: 8px;
          .headerAllowances {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 16px;
            > span {
              color: var(
                --Neutral-100,
                var(--Color-Neutal-Neutral-19, #0a0a0a)
              );

              /* Text L/SemiBold */
              // font-family: Poppins;
              font-size: calc(
                14px + (16 - 14) * ((100vw - 280px) / (1600 - 280))
              );
              font-style: normal;
              font-weight: 600;
              line-height: 140%; /* 22.4px */
            }

            .addAllowance {
              display: flex;
              align-items: center;
              gap: 4px;
              background-color: $NEUTRAL-10;
              color: $PRIMARY_MAIN;
              font-family: Poppins;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 20px;
              border: 1px solid $PRIMARY_MAIN;
              border-radius: 8px;
              padding: 10px 12px;

              &.absolute {
                position: absolute;
                top: 12px;
                right: 0;
              }
            }
          }

          .empty {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 8px;
            flex-wrap: wrap;
            padding: 8px 8px 8px 16px;
            border: 1px solid $NEUTRAL-40;
            border-radius: 8px;

            .addAllowance {
              display: flex;
              align-items: center;
              gap: 4px;
              background-color: $NEUTRAL-10;
              color: $PRIMARY_MAIN;
              font-family: Poppins;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 20px;
              border: 1px solid $PRIMARY_MAIN;
              border-radius: 8px;
              padding: 10px 12px;

              &.absolute {
                position: absolute;
                top: 12px;
                right: 0;
              }
            }

            > span {
              font-family: Poppins;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;
              color: $NEUTRAL_60;
            }
          }

          .fieldsWrapper {
            display: flex;
            flex-direction: column;
            gap: 8px;

            .each {
              display: flex;
              flex-direction: column;
              gap: 4px;

              > span {
                color: var(
                  --Neutral-100,
                  var(--Color-Neutal-Neutral-19, #0a0a0a)
                );

                /* Text L/SemiBold */
                // font-family: Poppins;
                font-size: calc(
                  12px + (14 - 12) * ((100vw - 280px) / (1600 - 280))
                );
                font-style: normal;
                font-weight: 600;
                line-height: 140%; /* 22.4px */
              }

              .fields {
                // display: grid;
                // grid-template-columns: 1fr 1fr auto;
                // gap: 8px;
                display: flex;
                align-items: center;
                gap: 8px;
                > div {
                  display: flex;
                  align-items: center;
                  gap: 8px;
                  width: 100%;
                  > div {
                    width: 100%;
                  }
                }

                > button {
                  padding: 0;
                  display: grid;
                  place-items: center;
                  background-color: transparent;
                  border: none;
                  color: $DANGER_MAIN;
                  margin-top: 8px;
                  margin-bottom: 8px;
                }
              }
            }
          }
        }
      }

      .buttoneSection {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        > div {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          gap: 4px;
          > span {
            color: red;
            font-size: 12px;
            text-align: right;
          }
          > div {
            border-radius: 6px;
            background: var(--Primary-Main, #1571de);
            display: flex;
            width: 120px;
            height: 36px;
            padding: 8px;
            justify-content: center;
            align-items: center;
            color: var(--Color-Neutal-Neutral-10, #fff);
            text-align: center;

            /* Text M/Reguler */
            //   font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
            cursor: pointer;
            &.disabled {
              background: var(--Color-Neutal-Neutral-20, #cfcdcd);
              color: var(--Neutral-60, var(--Color-Neutal-Neutral-60, #989696));
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .containerModal {
    .contents {
      > div {
        .inputSection {
          > div:first-child {
            flex-direction: column;
            align-items: flex-start;
          }
          > div:nth-child(2) {
            .range {
              flex-direction: column;
              align-items: flex-start;
              .separator {
                display: none;
              }
            }
          }

          > div:nth-child(3) {
            .requirements {
              flex-direction: column;
            }
          }

          > div:nth-child(4) {
            .headerAllowances {
              flex-direction: column;
              align-items: flex-start;
            }
            .fieldsWrapper {
              .each {
                .fields {
                  > div {
                    flex-direction: column;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
