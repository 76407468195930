@import "../../../../theme/variables.scss";

.container {
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  background-color: $NEUTRAL_10;
  max-width: 100vw;
  width: 436px;
  filter: drop-shadow(0px 4px 9px rgba(0, 0, 0, 0.12));
  gap: 24px;

  .header {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    padding: 24px 24px 0 24px;
    text-align: center;

    .title {
      display: flex;
      flex-direction: row;
      font-size: 16px;
      font-weight: 600;
      color: $NEUTRAL_100;
      align-items: center;
      gap: 12px;
    }

    .text {
      font-size: 13px;
      font-weight: 400;
      color: $NEUTRAL_100;
    }
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px 24px 24px;
    gap: 8px;

    .primaryButton {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: transparent;
      padding: 10px 0;
      flex: 1;
      border-radius: 8px;
      border: 1px solid $PRIMARY_MAIN;
      cursor: pointer;

      color: $PRIMARY_MAIN;
      font-weight: 400;
      font-size: 14px;

      &:disabled {
        border-color: $PRIMARY_DISABLED;
      }
    }
  }
}

@media (width <= 768px) {
  .container {
    width: 100%;
  }
}
