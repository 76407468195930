.container {
  .headerWrapper {
    position: sticky;
    top: 70px;
    z-index: 2;
  }

  .contentWrapper {
    z-index: 0;
  }
}

.loadingWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
