@import "../../../../../../theme/variables.scss";

.container {
  display: flex;
  flex-direction: row;
  gap: 16px;

  .iconWrapper {
    height: 40px;
    width: 40px;
    border-radius: 8px;
    background-color: $NEUTRAL_20;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      color: $PRIMARY_MAIN;
    }
  }

  .durationBorder {
    border-left: 1px solid $NEUTRAL_60;
    height: 60%;
    align-self: center;
  }

  .informationWrapper {
    display: flex;
    flex-direction: column;
    gap: 2px;

    .informationTitleText {
      color: $NEUTRAL_60;
      font-size: 13px;
      font-weight: 400;
    }

    .informationText {
      color: $NEUTRAL_100;
      font-size: 14px;
      font-weight: 500;
    }

    .span {
      color: $NEUTRAL_70;
      font-size: 14px;
      font-weight: 500;
      padding-right: 4px;
    }

    .spanBlack {
      color: $NEUTRAL_100;
      font-size: 14px;
      font-weight: 500;
    }
  }
}
