@import "../../../../../../theme/variables.scss";

.container {
  background-color: $NEUTRAL_LIGHTER;
  padding: 30px;
  padding-bottom: 10px;
  display: flex;

  > * {
    height: 40px !important;
    display: flex;
    align-items: center;
    width: 100%;

    &.leftController {
      gap: 12px;

      .searchBarWrapper {
        width: 332px;
        height: 100%;
      }

      .dropdownWrapper {
        width: 244px;
      }
    }

    &.rightController {
      justify-content: flex-end;

      > * {
        display: flex;
        align-items: center;
        > span {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: #6f6f70;
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .container {
    padding: 8px 18px;
    flex-direction: column;

    > * {
      height: 40px !important;
      display: flex;
      align-items: center;
      width: 100%;

      &.leftController {
        gap: 12px;

        .searchBarWrapper {
          width: 100%;
          height: 100%;
        }

        .dropdownWrapper {
          width: 244px;
        }
      }

      &.rightController {
        justify-content: flex-end;

        > * {
          display: flex;
          align-items: center;
          > span {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: #6f6f70;
          }
        }
      }
    }
  }
}
