.bodyNotif {
  min-height: 100vh;
  width: 100vw;
  background-color: #00000080;
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
}
.contentNotif {
  border-radius: 8px;
  background: var(--Neutral-10, #fff);
  box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.12);
  width: 472px;
  max-height: 80%;
  overflow-y: scroll;
  .topNotif {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    padding: 16px 16px 8px 16px;
    border-bottom: 1px solid var(--Neutral-Stroke, #ededed);
    position: sticky;
    top: 0;
    background: var(--Neutral-10, #fff);
  }

  .icon {
    padding: 12px;
    border-radius: 50px;
    background-color: #fafafa;
  }
  .cat {
    color: var(--Neutral-100, #0a0a0a);

    /* Heading 4/SemiBold */
    // font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px; /* 130% */
  }
  .left {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}

.contentNotif::-webkit-scrollbar {
  display: none;
}

.iconClose {
  cursor: pointer;
}

.containerNotif {
  padding: 8px 16px 16px 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  .top {
    display: flex;
    flex-direction: column;
    gap: 12px;
    .spanTwo {
      color: var(--Neutral-90, #404040);

      /* Text M/Regular */
      //   font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
    }
    .spanOne {
      color: #000;

      /* Text M/Medium */
      //   font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
    }
  }

  .buttonClose {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 12px;
    border-radius: 8px;
    border: 1px solid var(--Primary-Main, #1571de);
    background: var(--Neutral-10, #fff);
    width: 100%;
    cursor: pointer;
    color: var(--Primary-Main, #1571de);
    text-align: center;

    /* Text M/Medium */
    // font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
  }
}

@media (width <= 768px) {
  .contentNotif {
    width: 100vw;
    position: fixed;
    bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
