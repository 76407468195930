@import "../../../../theme/variables.scss";

.container {
  width: 100vw;
  display: grid;
  grid-template-columns: 1fr;
  position: relative;

  .tooltip {
    display: grid;
    grid-template-columns: 1fr;
    gap: 4px;

    >p {
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 133.333%;
      color: $NEUTRAL-100;
      display: grid;
      grid-template-columns: 109px auto 1fr;
      gap: 8px;

      >span {

        &:first-child {
          color: $NEUTRAL-70;
        }
      }
    }
  }

  .header {
    // z-index: 0;
    position: relative;
    height: 144px;
    overflow: clip;
    width: 100%;
    margin: auto;
    max-width: 1440px;
    margin-inline: auto;


    >img {
      position: absolute;
      top: 0;
      object-fit: cover;
      height: 144px;
      width: 100%;
    }

    .headerItems {
      position: absolute;
      // top: 30px;
      z-index: 1;
      @include top(30, 12, 12px);
      @include left(36, 16, 16px);
      left: 36px;
      // width: calc(100vw - 72px);
      width: calc(100vw - 72px);
      max-width: calc(1440px - 72px);
      display: flex;
      align-items: center;
      gap: 16px;

      >h2 {
        font-family: Poppins;
        @include font-size(26, 16, 16px);
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
        letter-spacing: 0.13px;
        color: $NEUTRAL-10;
        flex-grow: 1;

        >span {
          font-style: italic;
        }
      }

      .hActions {
        display: grid;
        // align-items: center;
        grid-template-columns: auto auto;
        gap: 12px;

        >button {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 4px;
          padding: 8px 12px;
          border-radius: 8px;
          height: 46px;
          border: none;

          >span {
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 142.857%;
          }

          &.add {
            background-color: $PRIMARY_MAIN;
            color: $NEUTRAL-10;

            &:disabled {
              background-color: $PRIMARY_DISABLED;
              border-color: $PRIMARY_DISABLED;
            }
          }

          &.log {
            color: $PRIMARY_MAIN;
          }

        }

      }


    }
  }

  .content {
    // padding-inline: 36px;
    @include padding-inline (36, 18, 18px);
    margin: auto;
    margin-top: -30px;
    // z-index: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    max-width: 1440px;
    margin-inline: auto;


    .section {
      background-color: $NEUTRAL-10;
      // z-index: 1;
      border-radius: 8px;
    }

    .quotaFulfillment {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 16px;


      .qFHeader {
        // display: flex;
        // align-items: center;
        display: grid;
        grid-template-columns: 1fr auto;
        gap: 16px;

        >h3 {
          // flex-grow: 1;
          font-family: Poppins;
          // font-size: 20px;
          @include font-size(20, 16, 16px);
          font-style: normal;
          font-weight: 600;
          line-height: 150%;
          /* 30px */
          letter-spacing: 0.1px;
          color: $PRIMARY_MAIN;
        }

        .indicators {
          display: flex;
          align-items: center;
          @include gap(25, 16, 16px);
          flex-wrap: wrap;

          .indicator {
            display: flex;
            align-items: center;
            gap: 8px;

            >div {
              width: 37px;
              height: 10px;
              border-radius: 10px;
            }

            >span {
              font-family: Poppins;
              @include font-size(13, 12, 12px);
              font-weight: 500;
              line-height: normal;
              color: $NEUTRAL_90;
            }
          }
        }


      }
    }

    .positions {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding-bottom: 24px;

      .positionCard {

        .pHeader {
          display: flex;
          align-items: center;
          gap: 10px;
          border-bottom: 1px solid $NEUTRAL_30;
          padding: 16px;

          >h3 {
            flex-grow: 1;
            font-family: Poppins;
            @include font-size(20, 16, 16px);
            font-style: normal;
            font-weight: 600;
            line-height: 150%;
            letter-spacing: 0.1px;
            color: #3E4856;
          }

          .pHActions {
            display: flex;
            align-items: center;
            gap: 8px;

            >div {
              position: relative;

              >button {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 4px;
                background-color: $NEUTRAL-10;
                color: $PRIMARY_MAIN;
                border: 1px solid $PRIMARY_MAIN;
                padding: 8px;
                border-radius: 8px;
              }

              .moreOptions {
                position: absolute;
                top: 100%;
                background-color: $NEUTRAL-10;
                box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.25);
                right: 0;
                border-radius: 8px;
                overflow: clip;

                >div {
                  white-space: nowrap;
                  padding: 10px 12px;

                  &:not(.disabled) {
                    cursor: pointer;
                  }

                  &:hover {
                    background-color: $PRIMARY_SURFACE;
                  }

                  >p {
                    font-family: Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 142.857%;
                    color: $PRIMARY_MAIN;

                    >span {
                      font-style: italic;
                    }
                  }

                  &.disabled {
                    background-color: $NEUTRAL-20;

                    >p {
                      color: $NEUTRAL-40 !important;
                    }
                  }
                }


              }

            }

          }
        }

        .pIndicators {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr;

          .pIndicator {
            padding: 6px 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            gap: 4px;
            border-bottom: 1px solid $NEUTRAL_30;


            &:not(:last-child) {
              border-right: 1px solid $NEUTRAL_30;
            }

            .pTitle {
              display: flex;
              align-items: center;
              gap: 3px;

              >span {
                font-family: Poppins;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                /* 133.333% */
                color: #556377;
              }

              .extra {
                display: flex;
                align-items: center;
                gap: 4px;

                >span {
                  font-family: Poppins;
                  font-size: 10px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 120%;
                  color: $PRIMARY_MAIN;
                }

                >div {
                  width: 14px;
                  aspect-ratio: 1;
                  border-radius: 50%;
                  display: grid;
                  place-items: center;
                  background-color: $PRIMARY_SURFACE;
                  object-fit: clip;

                  >img {
                    width: 10px;
                    aspect-ratio: 1;
                    object-fit: contain;
                  }
                }

              }
            }

            >span {
              font-family: Poppins;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 140%;
              color: $PRIMARY_MAIN;
            }

            >p {
              font-family: Poppins;
              font-size: 11px;
              font-style: normal;
              font-weight: 400;
              line-height: 145.455%;
              color: $NEUTRAL-50
            }
          }
        }

        .pList {
          padding: 16px;

          >table {
            width: 100%;
            border: 1px solid $NEUTRAL_30;
            border-radius: 8px;
            border-spacing: 0;
            overflow: visible;

            thead {
              tr {
                th {
                  text-align: left;
                  padding: 16px 20px;
                  font-family: Poppins;
                  font-size: 14px;
                  font-weight: 500;
                  line-height: 142.857%;

                  .mobileTHeader {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 8px;
                  }
                }
              }
            }

            tbody {
              tr {
                &.odd {
                  td {
                    background-color: #F3F6FF;
                  }
                }

                td {
                  padding: 16px 20px;
                  font-family: Poppins;
                  font-size: 13px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;

                  .progressWrapper {
                    display: grid;
                    grid-template-columns: 1fr;

                  
                  }

                  &.bold {
                    font-weight: 500;
                  }

                  .div {
                    display: grid;
                    grid-template-columns: 1fr;
                    gap: 16px;

                    >*:first-child {
                      display: flex;
                      align-items: center;
                      gap: 16px;
                      justify-content: space-between;
                    }

                    .bold {
                      font-weight: 500;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .empty {
      display: flex;
      flex-direction: column;
      align-items: center;

      >img {
        @include width(429, 320, 320px);
        max-width: 90vw;
        object-fit: contain;
      }

      >span {
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
      }

      >p {
        font-family: Poppins;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 153.846%;
        color: $NEUTRAL-80;
        width: 362px;
        max-width: 90vw;
        padding-bottom: 24px;
        text-align: center;
      }

      >button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        border-radius: 8px;
        background-color: $PRIMARY_MAIN;
        padding: 8px 12px;
        border: none;
        color: $NEUTRAL-10;
        width: 231px;
        max-width: 90vw;

        >span {
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 142.857%;
        }

        &:disabled {
          background-color: $PRIMARY_DISABLED;
          border-color: $PRIMARY_DISABLED;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {

    .header {
      height: 200px;

      .headerItems {
        flex-direction: column;
        // top: 12px;
        left: 16px;
        max-width: 100vw;
        width: calc(100% - 32px);

        >h2 {
          text-align: center;
        }

        .hActions {
          width: 100%;
          grid-template-columns: 1fr auto;
        }
      }
    }

    .content {

      .quotaFulfillment {

        .qFHeader {
          grid-template-columns: 1fr;
        }
      }

      .positionCard {

        .pIndicators {
          grid-template-columns: 1fr 1fr !important;

          .pIndicator {

            .pTitle {
              flex-direction: column;
            }
          }
        }
      }
    }
  }
}