@import "../../../theme/variables.scss";

.container {
  display: flex;
  align-items: center;
  width: 100%;
  // padding: 8px;
  background-color: #F8FBFF;
  padding-left: 0px;
  z-index: inherit;

  .blueBg {
    height: 56px;
    width: 6px;
    background-color: $PRIMARY_MAIN;
    border-radius: 8px 0px 0px 8px;
  }

  .wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 8px;

    .each {
      display: flex;
      align-items: center;
      gap: 8px;

      &.verified {
        color: $PRIMARY_MAIN;

        >*:first-child {
          color: $PRIMARY_MAIN;
        }
      }

      &:first-child {
        width: calc(50% + 12px);
      }

      &:last-child {
        width: calc(50% - 12px);
      }

      >*:first-child {
        color: $NEUTRAL_50;
      }

      .title {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        >span {

          &:first-child {
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
          }

          &:nth-child(2) {
            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            color: $NEUTRAL_60;
          }
        }


      }

      .divider {
        height: 23px;
        width: 1px;
        background-color: $NEUTRAL_60;
        margin-right: 12px;
      }

      .verificationButtonWrapper {
        position: relative;

        .popupWrapper {
          z-index: 1;
          position: absolute;
          margin-top: 4px;
          right: 0;
        }
        .IconButton{
          cursor: pointer;
        }
      }

      .verificationButton {
        display: flex;
        align-items: center;
        gap: 4px;
        padding-block: 10px;
        padding-left: 12px;
        padding-right: 8px;
        border-radius: 8px;
        border: 1px solid $PRIMARY_MAIN;
        background-color: $NEUTRAL_10;
        color: $PRIMARY_MAIN;

        >span {
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
        }
      }

      .verifiedIndicator {
        display: flex;
        align-items: center;
        gap: 4px;
        padding-block: 10px;
        padding-left: 12px;
        padding-right: 8px;
        border-radius: 8px;
        background-color: rgba(34, 151, 15, 0.10);
        color: #22970F;


        >span {
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
        }

        &.declined {
          background-color: $DANGER-SURFACE;
          color: $DANGER_MAIN;
        }

      }

      .reInjectWrapper {
        display: flex;
        align-items: center;
        gap: 8px;

        >button {
          display: grid;
          place-items: center;
          background-color: transparent;
          border: none;
          color: $PRIMARY_MAIN;
        }
      }
      .preVerifiedIndicator {
        display: flex;
        align-items: center;
        gap: 4px;
        padding-block: 10px;
        padding-left: 12px;
        padding-right: 8px;
        border-radius: 8px;
        background-color: rgba(233, 182, 47, 0.10);
        color: #E9B62F;


        >span {
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
        }

       

      }
    }

    .eachExtend {
      display: flex;
      align-items: center;
      gap: 8px;
      justify-content: space-between;
      width: 100%;

      .header{
        display: flex;
        align-items: center;
        gap: 8px;

        &.verified {
          color: $PRIMARY_MAIN;
  
          >*:first-child {
            color: $PRIMARY_MAIN;
          }
        }

        &:first-child {
          width: calc(50% + 12px);
        }
  
        &:last-child {
          width: calc(50% - 12px);
        }
  
        >*:first-child {
          color: $NEUTRAL_50;
        }
  
        .title {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
  
          >span {
  
            &:first-child {
              font-family: Poppins;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 20px;
            }
  
            &:nth-child(2) {
              font-family: Poppins;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 16px;
              color: $NEUTRAL_60;
            }
          }
  
  
        }
      }

      &.verified {
        color: $PRIMARY_MAIN;

        >*:first-child {
          color: $PRIMARY_MAIN;
        }
      }

      .divider {
        height: 23px;
        width: 1px;
        background-color: $NEUTRAL_60;
        margin-right: 12px;
      }

      .verificationButtonWrapper {
        position: relative;

        .popupWrapper {
          z-index: 1;
          position: absolute;
          margin-top: 4px;
          right: 0;
        }
        .IconButton{
          cursor: pointer;
        }
      }

      .verificationButton {
        display: flex;
        align-items: center;
        gap: 4px;
        padding-block: 10px;
        padding-left: 12px;
        padding-right: 8px;
        border-radius: 8px;
        border: 1px solid $PRIMARY_MAIN;
        background-color: $NEUTRAL_10;
        color: $PRIMARY_MAIN;

        >span {
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
        }
      }

      .verifiedIndicator {
        display: flex;
        align-items: center;
        gap: 4px;
        padding-block: 10px;
        padding-left: 12px;
        padding-right: 8px;
        border-radius: 8px;
        background-color: rgba(34, 151, 15, 0.10);
        color: #22970F;


        >span {
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
        }

        &.declined {
          background-color: $DANGER-SURFACE;
          color: $DANGER_MAIN;
        }

      }

      .reInjectWrapper {
        display: flex;
        align-items: center;
        gap: 8px;

        >button {
          display: grid;
          place-items: center;
          background-color: transparent;
          border: none;
          color: $PRIMARY_MAIN;
        }
      }
      .preVerifiedIndicator {
        display: flex;
        align-items: center;
        gap: 4px;
        padding-block: 10px;
        padding-left: 12px;
        padding-right: 8px;
        border-radius: 8px;
        background-color: rgba(233, 182, 47, 0.10);
        color: #E9B62F;


        >span {
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
        }

       

      }
    }
  }


}

@media screen and (max-width: 1024px) {
  .container {
    height: 94px;
    border-radius: 0 8px 8px 0;

    .blueBg {
      height: 94px;
    }
    .popupWrapper {
      z-index: 1;
      position: absolute;
      right: 0;
    }
    .wrapper {
      flex-direction: column;

      .divider {
        display: none;
      }

      >* {
        width: 100%!important;
        padding-block: 4px;

        &:first-child {
          border-bottom: 1px solid $NEUTRAL_30;
        }
      }

    }
  }
}