@import "../../../../../../../../theme/variables.scss";

.tableWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll !important;
  z-index: 0;
  height: 420px;

  .showOnMobile {
    display: none;
  }

  .emptyWrapper {
    flex-grow: 1;
  }

  .tableContainer {
    overflow: auto;
    background-color: inherit;
    border-bottom: 0;
    // border: 1px solid $NEUTRAL_50;
    border-top: 0;
    border-inline: none;
    overflow-y: scroll !important;
    height: auto;

    &.grow {
      height: 100px;
      flex-grow: 1;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    .table {
      border-collapse: separate;
      // min-width: 900px !important;

      .tableHead {
        .tableHeadCell {
          padding-inline: 20px !important;
          padding-block: 16px !important;
          position: sticky;
          top: 0;
          background-color: $NEUTRAL-10;

          &:first-child {
            border-right: 1px solid $NEUTRAL_40;
            position: sticky;
            left: 0;
            background-color: $NEUTRAL_10 !important;
            z-index: 1;
            padding-inline: 16px !important;

            > div {
              width: 304px !important;
              display: flex;
              align-items: center;
              gap: 16px;
            }
          }

          &.actions {
            position: sticky;
            right: 0;
            z-index: 1;
            background-color: $NEUTRAL_10 !important;
            border-left: 1px solid $NEUTRAL_40;

            .statusOption {
              position: absolute;
              // background-color: red;
              width: 100%;
              height: 200px;
            }

            div {
              width: 108px !important;
              display: flex;
              justify-content: center;

              button {
                margin: auto;
                background-color: transparent;
                border: none;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 8px;

                &.Expand {
                  color: $PRIMARY_MAIN;

                  span {
                    color: $PRIMARY_MAIN !important;
                  }
                }
              }
            }
          }

          div {
            width: 220px !important;
          }
        }
      }

      .tableBody {
        .tableCell {
          padding: 16px !important;

          > span {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: $NEUTRAL_100;
          }

          &.stickyLeft {
            position: sticky;
            left: 0;
            background-color: $NEUTRAL_10 !important;
            border-right: 1px solid $NEUTRAL_40;

            > div {
              display: flex;
              align-items: center;
              gap: 16px;

              .stackedProfile {
                background-color: transparent;
                border: none;
                display: flex;
                align-items: center;
                gap: 8px;
                cursor: pointer;

                span {
                  font-family: "Poppins";
                  font-style: normal;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 20px;
                  color: $PRIMARY_MAIN;
                  text-align: left;
                }
              }
            }
          }

          &.stickyRight {
            position: sticky;
            right: 0;
            background-color: $NEUTRAL_10 !important;
            border-left: 1px solid $NEUTRAL_40;
          }

          &.actions {
            > button {
              width: 100px;
              background-color: $PRIMARY_MAIN;
              padding: 8px;
              border-radius: 8px;
              border: none;
              font-family: Poppins;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;
              color: $NEUTRAL_10;

              &:disabled {
                background-color: $PRIMARY_DISABLED;
              }
            }
          }
        }
      }
    }
  }

  .paginationContainer {
    border-top: 1px solid $NEUTRAL_50;
  }

  .modalWrapper {
    position: fixed;
    transition: all 0.35s ease-in-out !important;
    z-index: 4;
    background-color: transparent;
    width: 100%;
    left: 0;

    &.hideModal {
      bottom: -1000px;
    }

    &.showModal {
      bottom: 0;
    }
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    background-color: $NEUTRAL_OVERLAY;
    opacity: 0;
    z-index: 3;

    &.visible {
      right: 0;
      bottom: 0;
      opacity: 1;
      transition: opacity 0.4s;
    }
  }
}

.nullLink {
  color: var(--Neutral-60, var(--Color-Neutal-Neutral-60, #9e9e9e));
  // font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.linkInterview {
  color: var(--Primary-Main, #1571de);
  // font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  text-decoration-line: underline;
  cursor: pointer;
  &.inactive {
    // cursor: none;
    color: var(--Neutral-60, var(--Color-Neutal-Neutral-60, #9e9e9e));
  }
}
@media screen and (max-width: 768px) {
  .tableWrapper {
    .showOnMobile {
      display: table-cell;
    }

    .tableContainer {
      .table {
        width: 100% !important;

        .tableHead {
          .tableHeadCell {
            padding: 12px 16px !important;

            &:first-child {
              position: relative;
              border-right: none;

              > div {
                width: 100% !important;
              }
            }
          }

          .showOnMobile {
            min-width: 100px !important;
            max-width: 150px !important;
          }
        }

        .tableBody {
          .tableCell {
            &.stickyLeft {
              position: relative;
              border-right: none;

              > div {
                width: 100% !important;
              }
            }

            &.showOnMobile {
              button {
                width: 100%;
                padding: 6px 12px;
                background-color: $PRIMARY_MAIN;
                border: none;
                border-radius: 8px;

                font-family: "Poppins";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: $NEUTRAL_10;
              }
            }
          }
        }

        .hideOnMobile {
          display: none !important;
        }
      }
    }
  }
}
