@import "../../../../../theme/variables.scss";

.container {
  // display: flex;
  // flex-direction: column;
  display: grid;
  grid-template-columns: 1fr;
  // gap: 24px;
  @include gap(24, 20, 20px);
  @include padding-top(24, 18, 18px);
  position: relative;

  .autoScroll {
    display: hidden;
    position: absolute;
    top: 0;
  }

  .section {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;

    &:not(:last-child) {
      padding-bottom: 24px;
      @include padding-bottom(24, 20, 20px);
      border-bottom: 1px solid $NEUTRAL_30;
    }


    >span {
      color: #000;
      font-family: Poppins;
      // @include font-size(18, 16, 16px);
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  .subSection {
    // display: flex;
    // flex-direction: column;
    display: grid;
    grid-template-columns: 1fr;
    gap: 12px;

    >span {
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    &:not(:last-child) {
      padding-bottom: 24px;
      border-bottom: 1px solid $NEUTRAL_30;
    }

  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 15px;

}

.adornment {
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: $NEUTRAL-50;
}

.personalData {

  .eachP {
    // display: flex;
    // flex-direction: column;
    display: grid;
    grid-template-columns: 1fr;
    gap: 8px;

    .extra {
      display: flex;
      align-items: center;
      gap: 10px;

      >span {
        font-family: Poppins;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }

}

.address {
  // display: flex;
  // flex-direction: column;
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;

  .eachA {
    // display: flex;
    // flex-direction: column;
    display: grid;
    grid-template-columns: 1fr;
    gap: 15px;

    >span {
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .isDomicileTheSame {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 10px;
      background-color: $NEUTRAL-20;
      border-radius: 8px;

      >span {
        font-family: Poppins;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    .aFields {
      // display: flex;
      // flex-direction: column;
      display: grid;
      grid-template-columns: 1fr;  
      gap: 15px;

    }
  }
}

.contact {
  // display: flex;
  // flex-direction: column;
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;



  .addressField {
    padding-top: 8px;
  }
}

.other {
  // display: flex;
  // flex-direction: column;
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
}

@media screen and (max-width: 768px) {
  .grid {
    grid-template-columns: 1fr !important;
  }
}