@import "../../../../theme/variables.scss";

.container {
  background-color: $NEUTRAL-10;
  width: 440px;
  max-width: 100vw;
  border-radius: 8px;
  // height: 548px;
  // max-height: 85vh;

  > * {
    padding-inline: 16px;
    border-radius: 8px;

    &.header {
      padding-top: 16px;
      padding-bottom: 24px;
      position: sticky;
      top: 0;
      background-color: $NEUTRAL-10;
      z-index: 1;

      > h3 {
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
      }
    }

    &.searchWrapper {
      height: 50px;
      padding-bottom: 10px;
    }

    &.content {
      display: flex;
      flex-direction: column;
      min-height: 400px;
      overflow-y: scroll;
      max-height: 400px;

      > div {
        display: flex;
        align-items: center;
        gap: 16px;
        padding-block: 10px;
        border-bottom: 1px solid $NEUTRAL-40;
      }
    }

    &.actions {
      padding-top: 24px;
      padding-bottom: 16px;
      background-color: $NEUTRAL-10;
      z-index: 1;
      position: sticky;
      bottom: 0;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 10px;
      box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.12);
      border-top: 1px solid $NEUTRAL-40;

      > button {
        padding: 8px;
        border-radius: 6px;
        border: 1px solid transparent;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;

        &:first-child {
          background-color: $NEUTRAL-10;
          border-color: $PRIMARY_MAIN;
          color: $PRIMARY_MAIN;
        }

        &:last-child {
          background-color: $PRIMARY_MAIN;
          border-color: $PRIMARY_MAIN;
          color: $NEUTRAL-10;

          &:disabled {
            background-color: $PRIMARY_DISABLED;
            border-color: $PRIMARY_DISABLED;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    border-radius: 8px 8px 0px 0px;
    > * {
      border-radius: 8px 8px 0px 0px;
    }
  }
}
