@import "../../../theme//variables.scss";

.background {
  height: 100vh;
  width: 100vw;
  background-color: $NEUTRAL_OVERLAY;
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .modalContainer {
    background-color: $NEUTRAL-10;
    width: 90%;
    // height: 90%;
    border-radius: 8px;
    &.maxHeight {
      max-height: 90%;
      overflow-y: scroll;
    }
    // max-height: 80%;
    // overflow-y: scroll;
    // z-index: 100;
  }
}

@media screen and (max-width: 768px) {
  .background {
    &.moveToEnd {
      justify-content: flex-end;
      align-items: flex-end;

      .modalContainer {
        width: 100% !important;
        border-radius: 4px 4px 0 0;
        position: fixed;
        bottom: 0;
      }
    }

    &.reduceWidth {
      .modalContainer {
        width: 90% !important;
      }
    }
  }
}
