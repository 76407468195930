@import "../../../../theme/variables.scss";


.container {
  background-color: $NEUTRAL_10;
  width: 364px;
  max-width: 90vw;
  border-radius: 8px;
  padding: 16px;

  .nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    padding-bottom: 16px;

    >button {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      background-color: transparent;
      border: none;

      &:first-child {
        color: $PRIMARY_MAIN;

        >span {
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
        }
      }
    }
  }

  .header {
    display: flex;
    flex-direction: column;
    gap: 8px;

    >h3 {
      color: #5D5871;
      /* Heading 7/SemiBold */
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      /* 30px */
      letter-spacing: 0.1px;
    }

    p {
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: $NEUTRAL_60;
    }
  }


  .content {
    width: 100%;
    padding-top: 24px;

    form {
      width: 100%;
      display: flex;
      flex-direction: column;

      label {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        color: $NEUTRAL_100;
        padding-bottom: 4px;
      }

      input {
        height: 44px;
        background: $NEUTRAL_LIGHTEST;
        border: 1px solid $NEUTRAL_40;
        border-radius: 8px;
        padding-inline: 12px;
        -webkit-transition: all 0.2s ease-out;
        -moz-transition: all 0.2s ease-out;
        -ms-transition: all 0.2s ease-out;
        -o-transition: all 0.2s ease-out;
        transition: all 0.2s ease-out;

        &:focus {
          outline: none !important;
          border-color: $PRIMARY_MAIN;
          border-width: 1px;
          border-style: solid;
        }

        &.alert {
          border-color: $DANGER_OUTLINE_BORDER !important;
          -webkit-box-shadow: 0 0 5px $DANGER_OUTLINE_BORDER !important;
          -moz-box-shadow: 0 0 5px $DANGER_OUTLINE_BORDER !important;
          box-shadow: 0 0 5px $DANGER_OUTLINE_BORDER !important;
        }
      }

      .resendWrapper {
        padding-top: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .resendController {
          >button {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: $PRIMARY_MAIN;
            background-color: transparent !important;
            border: none !important;

            &.ableResend {
              opacity: 1;
              cursor: pointer;
            }

            &.disableResend {
              opacity: 0.5;
            }
          }
        }

        .timerWrapper {
          display: flex;
          align-items: center;
          gap: 9px;

          .baseTimer {
            width: 22px;
            height: 22px;

            .SVG {
              transform: scaleX(-1);
            }

            .circle {
              fill: none;
              stroke: none;
            }

            .pathElapsed {
              stroke-width: 10px;
              stroke: none;
            }

            .pathRemaining {
              stroke-width: 12px;
              stroke-linecap: round;
              transform: rotate(90deg);
              transform-origin: center;
              transition: 1s linear all;
              stroke: $PRIMARY_MAIN;
            }
          }

          >span {
            display: inline-block;
            width: 37px;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 145%;
          }
        }
      }

      .noAlert {
        display: block;
        padding-top: 8px;
        color: $DANGER_MAIN;
        text-align: right;
        font-size: 12px;
        opacity: 0;
      }

      .alertText {
        display: block;
        padding-top: 8px;
        color: $DANGER_MAIN;
        text-align: right;
        font-size: 12px;
        opacity: 1;
      }

      .buttonWrapper {
        width: 100%;
        padding-top: 24px;

        button {
          width: 100%;
          background: $PRIMARY_MAIN;
          height: 40px;
          border: none;
          border-radius: 5px;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          text-align: center;
          color: $NEUTRAL_LIGHTEST;

          &:disabled {
            background: $PRIMARY_DISABLED;
          }

          &:focus {
            outline: none;
            box-shadow: $BOX_SHADOW_INPUT_ONFOCUS;
          }
        }
      }
    }
  }
}