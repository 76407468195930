@import "../../../theme/variables.scss";

.container {
  padding: 4px 8px;
  border-radius: 4px;
  border: 1px solid transparent;
  display: inline-block;
  text-align: center;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  width: 100%;
  white-space: nowrap;
  &.hold {
    // border-radius: 31px;
    background: var(--Color-Neutal-Neutral-30, #ededed);
    color: var(--Neutral-70, var(--Color-Neutal-Neutral-70, #757575));
  }

  &.contracted {
    background: var(--Color-Neutal-Neutral-30, #ededed);
  }
  &.waitingApproval,
  &.verification {
    // border-radius: 4px;

    border: 1px solid var(--Alert-Main, #e9b62f);
    background: var(--Alert-Surface, rgba(233, 182, 47, 0.1));
    color: var(--Alert-Main, #e9b62f);
  }

  &.new {
    background-color: $PRIMARY_SURFACE;
    border-color: $PRIMARY_MAIN;
    color: $PRIMARY_MAIN;
  }

  &.gagalInject,
  &.revisionContract,
  &.revisionOffer {
    // border-radius: 4px;
    border: 1px solid var(--Danger-Main, #d42701);
    background: var(--Danger-Surface, rgba(240, 44, 1, 0.1));
    color: var(--Danger-Main, #d42701);
  }

  &.generate {
    // border-radius: 4px;
    border: 1px solid var(--Neutral-60, #9e9e9e);
    background: var(--Neutral-20, #fafafa);
    color: var(--Neutral-60, var(--Color-Neutal-Neutral-60, #9e9e9e));
  }

  &.process {
    background-color: $BS_ALERT_SURFACE;
    border-color: $BS_ALERT_MAIN;
    color: $BS_ALERT_MAIN;
  }

  &.hired,
  &.active {
    background-color: $BS_SUCCESS_SURFACE;
    border-color: $BS_SUCCESS_MAIN;
    color: $BS_SUCCESS_MAIN;
  }

  &.decline,
  &.inactive {
    background-color: $BS_DANGER_SURFACE;
    border-color: $BS_DANGER_MAIN;
    color: $BS_DANGER_MAIN;
  }
}
