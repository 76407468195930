@import "../../../../../theme/variables.scss";

.container {
  width: 100%;
  min-height: calc(100% - 70px);

  .topWrapper {
    position: sticky;
    top: 70px;
    z-index: 1;
  }

  .controlHeader {
    width: 100%;
    height: 46px;
    padding-inline: 30px;
    background-color: $NEUTRAL_LIGHTER;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;

    padding-bottom: 10px;

    .searchBarWrapper {
      height: 36px;
      width: 356px;
      position: relative;

      .adornmentDiv {
        height: 100%;
        width: 36px;
        position: absolute;
        background-color: $PRIMARY_MAIN;
        top: 0;
        right: 0;
        display: grid;
        place-items: center;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }

    .buttonWrapper {
      height: 36px;
      min-width: 104px;
    }
  }

  .tabs {
    background-color: $NEUTRAL-10;
    border-top: 1px solid $NEUTRAL_40;
    border-bottom: 1px solid $NEUTRAL_40;
    padding: 12px 26px;
    display: flex;
    align-items: center;
    gap: 4px;

    .tab {
      padding: 6px 10px;
      border-radius: 8px;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      border: none;
      background-color: transparent;
      color: $NEUTRAL_60;
      transition: all 0.3s ease;

      &.active {
        background-color: $PRIMARY_MAIN;
        color: $NEUTRAL-10;
      }
    }
  }

  .breadcrumbWrapper {
    width: 100%;
    padding: 30px;
    padding-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 24px;
    background-color: $NEUTRAL_LIGHTER;

    >span {
      background-color: $NEUTRAL_LIGHTEST;
      width: 40px;
      height: 40px;
      display: grid;
      place-items: center;
      border-radius: 50%;
      cursor: pointer;
      flex-shrink: 0;
    }

    .breadcrumbText {
      font-weight: 500;
      font-size: 16px !important;
      line-height: 20px;
      color: $NEUTRAL_60;
      cursor: pointer;
    }

    .breadcrumbText2 {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: $NEUTRAL_100;
    }
  }

  .contentWrapper {
    width: 100%;
    min-height: calc(100vh - 220px);
    z-index: 0 !important;
  }

  .errorWrapper,
  .loadingWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 280px);
  }
}

@media screen and (max-width: 768px) {
  .container {
    .breadcrumbWrapper {
      padding: 18px;
      padding-bottom: 8px;
      padding-top: 10px;
      gap: 16px;
    }
  }

  .controlHeader {
    width: 100%;
    padding-inline: 18px !important;
    flex-direction: column;
    height: 100% !important;
    gap: 8px;

    .buttonWrapper {
      // display: none;
      width: 100% !important;
    }

    .searchBarWrapper {
      width: 100% !important;
    }
  }
}