@import "../../../../theme/variables.scss";

.newContactContainer {
  overflow: auto;
  width: 726px;
  max-width: 100vw;
  max-height: 85vh;
  background-color: $NEUTRAL-10;
  border-radius: 8px;

  >* {
    padding-inline: 24px;

  }

  .newHeader {
    display: flex;
    align-items: center;
    gap: 8px;
    border-bottom: 1px solid $NEUTRAL_30;
    padding-block: 16px;
    position: sticky;
    top: 0;
    background-color: $NEUTRAL-10;
    z-index: 1;

    >button {
      display: grid;
      place-items: center;
      padding: 0;
      background-color: transparent;
      border: none;
    }

    >span {
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
    }
  }

  .newContent {
    padding-block: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .buttonsWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 32px;
    gap: 8px;
    padding-bottom: 24px;
  
    button {
      padding: 8px 16px;
      border-radius: 8px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      transition: all 0.3s;
  
      &:first-child {
        background-color: $NEUTRAL_10;
        border: 1px solid $PRIMARY_MAIN;
        color: $PRIMARY_MAIN;
      }
  
      &:last-child {
        background-color: $PRIMARY_MAIN;
        border: 1px solid $PRIMARY_MAIN;
        color: $NEUTRAL_10;
  
        &:disabled {
          background-color: $PRIMARY_DISABLED;
          border-color: $PRIMARY_DISABLED
        }
      }
    }
  }
}
