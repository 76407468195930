@import "../../../../../../theme/variables.scss";

.container {
  position: relative;

  .autoScroll {
    display: hidden;
    position: absolute;
    top: 0;
  }

  > h3 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    padding-bottom: 30px;
    padding-top: 24px;
    position: sticky;
    top: 0;
    background-color: $NEUTRAL_10;
    z-index: 3;
  }

  .content {
    display: flex;
    gap: 20px;

    > .left {
      width: 30%;
      aspect-ratio: 1;
      background: #f4f4f4;
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 5%;
      align-self: flex-start;
      min-width: 300px !important;

      > img {
        width: 80%;
      }
    }

    > .right {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      gap: 18px;
    }
  }

  .section {
    h5 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      padding-bottom: 12px;
    }

    .docs {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .card {
        border: 2px dashed $NEUTRAL_30;
        border-radius: 8px;
        padding: 8px;
        display: flex;
        align-items: center;
        gap: 3%;

        .left {
          // width: 12.5%;
          // min-width: 50px;
          width: 100%;
          max-width: 84px;
          aspect-ratio: 1;
          background-color: #e6f1ff;
          display: grid;
          place-items: center;
          border-radius: 8px;
          color: $PRIMARY_MAIN;

          > img {
            width: 100%;
            max-width: 84px;
            aspect-ratio: 1;
            object-fit: cover;
            border-radius: 8px;
          }
        }

        .middle {
          flex-grow: 1;

          p {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 600;
            font-size: calc(
              12px + (16 - 12) * ((100vw - 280px) / (1600 - 280))
            );
            line-height: 24px;
          }

          span {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: calc(7px + (14 - 7) * ((100vw - 280px) / (1600 - 280)));
            line-height: 24px;

            &.mobile {
              display: none;
            }
          }
        }

        .right {
          // width: 25%;
          .icon {
            color: $PRIMARY_MAIN;
          }

          button {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;

            border: 1px solid #e6eaec;
            border-radius: 8px;
            padding: 8px 16px;
            background-color: transparent;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: calc(
              10px + (14 - 10) * ((100vw - 280px) / (1600 - 280))
            );

            // line-height: 21px;
            .iconCamera {
              width: 20px;
            }
          }
        }
      }

      .cardError {
        border-color: $DANGER_MAIN;
      }
      
      .errorText {
        color: var(--Helper, #F5610D);
        /* Caption 1/Reguler */
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        /* 133.333% */
      }
    }
  }
}

.completed {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 43px;
  padding: 24px;
  position: relative;

  .autoScroll {
    display: hidden;
    position: absolute;
    top: 0;
  }

  > img {
    width: 20%;
  }

  .desc {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    text-align: center;

    > h3 {
      font-family: Poppins;
      // font-size: 32px;
      @include font-size(32, 24, 24px);
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      letter-spacing: 0.16px;
      color: $PRIMARY_MAIN;
    }

    > span {
      font-family: Poppins;
      // font-size: 16px;
      @include font-size(16, 14, 14px);
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      color: $NEUTRAL_80;
    }
  }

  // .buttonWrapper {
  //   display: flex;
  //   align-items: center;
  //   gap: 4%;
  //   width: 50%;
  //   padding-inline: 4%;

  //   > * {
  //     width: 100%;
  //     padding: 2%;
  //     border-radius: 50px;
  //     font-family: "Poppins";
  //     font-style: normal;
  //     font-weight: 500;
  //     font-size: 14px;
  //     line-height: 24px;

  //     &:first-child {
  //       border: 1px solid $NEUTRAL_100;
  //       color: $NEUTRAL_100;
  //       background-color: transparent;
  //     }

  //     &:last-child {
  //       border: none;
  //       color: $NEUTRAL_10;
  //       background-color: $PRIMARY_MAIN;

  //       &:disabled {
  //         background-color: $PRIMARY_DISABLED;
  //       }
  //     }
  //   }
  // }
}

@media screen and (max-width: 768px) {
  .container {
    padding-top: 10px;

    .none {
      display: none !important;
    }

    > h3 {
      display: none;
    }

    .content {
      flex-direction: column;
      align-items: center;
      gap: 0;

      > .left {
        width: 100%;
        gap: 3vh;
        height: 0px;
        background-color: transparent;
        position: static !important;
      }

      > .right {
        width: 100%;
      }
    }

    .section {
      h5 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        padding-bottom: 12px;
      }

      .docs {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .card {
          border: 2px dashed $NEUTRAL_30;
          border-radius: 8px;
          padding: 8px;
          display: flex;
          align-items: center;
          gap: 3%;

          .left {
            // width: 12.5%;
            // min-width: 50px;
            width: 100%;

            max-width: 84px;
            aspect-ratio: 1;
            background-color: #e6f1ff;
            display: grid;
            place-items: center;
            border-radius: 8px;
            color: $PRIMARY_MAIN;
          }

          .middle {
            flex-grow: 1;

            // p {
            //   font-family: "Poppins";
            //   font-style: normal;
            //   font-weight: 600;
            //   font-size: 16px;
            //   line-height: 24px;
            // }

            // span {
            //   font-family: "Poppins";
            //   font-style: normal;
            //   font-weight: 400;
            //   font-size: 14px;
            //   line-height: 24px;

            //   &.mobile {
            //     display: block;
            //   }

            //   &.desktop {
            //     display: none;
            //   }
            // }
          }

          // .right {
          //   // button {
          //   //   border: 1px solid #e6eaec;
          //   //   border-radius: 8px;
          //   //   padding: 8px 16px;
          //   //   background-color: transparent;
          //   //   font-family: "Poppins";
          //   //   font-style: normal;
          //   //   font-weight: 500;
          //   //   // font-size: 14px;
          //   //   line-height: 21px;
          //   // }
          // }
        }

        .cardError {
          border-color: $DANGER_MAIN;
        }
      }
    }
  }

  .completed {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 43px;
    padding: 10px;
    padding-top: 30px;

    > img {
      width: 100%;
    }
  }
}

.buttonWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;

  padding: 16px;

  position: sticky;
  bottom: -30px;

  background-color: #fff;

  > * {
    width: 200px;
    padding: 4px;
    border-radius: 50px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
    line-height: 24px;

    &:first-child {
      border: 1px solid $NEUTRAL_100;
      color: $NEUTRAL_100;
      background-color: transparent;
    }

    &:last-child {
      border: none;
      color: $NEUTRAL_10;
      background-color: $PRIMARY_MAIN;

      &:disabled {
        background-color: $PRIMARY_DISABLED;
      }
    }
  }
}
