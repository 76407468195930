@import "../../../../../theme/variables.scss";

.background {
  width: 100vw;
  height: 100vh;
  background-color: $NEUTRAL_OVERLAY;
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .container {
    background-color: $NEUTRAL_10;
    border-radius: 8px;
    padding: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 519px;

    .messageWrapper{
        display: flex;
        gap: 12px;
        background-color: #FBF6DB;
        font-size: 12px;
        font-weight: 400;
        color: $NEUTRAL-90;
        padding: 12px;
        border-radius: 8px;
        text-align: left;
        align-items: center;
        justify-content: center;

        .iconDash{
            height: 24px;
            width: 24px;
        }
    }

    .circle {
      background-color: $PRIMARY_MAIN;
      width: 86px;
      aspect-ratio: 1;
      border-radius: 50%;
      display: grid;
      place-items: center;

      .checkIcon {
        color: $NEUTRAL_10;
      }
    }

    h4 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 140%;
      color: $CHINESE_BLACK;
      margin-top: 16px;
      margin-bottom: 4px;
    }

    p {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      text-align: center;
      color: $TAUPE_GRAY;
      margin-bottom: 16px;
    }

    .buttonsWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 16px;
        padding-bottom: 16px;
        gap: 8px;
        width: 100%;
      
        button {
          flex-grow: 1;
          padding: 8px 16px;
          border-radius: 8px;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          transition: all 0.3s;

          &.submitOnly{
            flex-grow: 0;
          }
      
          &:first-child {
            background-color: $NEUTRAL_10;
            border: 1px solid $PRIMARY_MAIN;
            color: $PRIMARY_MAIN;
          }
      
          &:last-child {
            background-color: $PRIMARY_MAIN;
            border: none;
            color: $NEUTRAL_10;
            
            &.process {
                background-color: $PRIMARY_SOFT;
            }

            &:disabled {
              background-color: $PRIMARY_DISABLED;
              border-color: $PRIMARY_DISABLED
            }
          }
        }
    }
    .errorWrapper {
        display: flex;
        padding: 12px;
        color: $DANGER_MAIN;
        font-size: 14px;
        text-align: left;
    }
  }
}