@import "../../../../../../../../../../../theme/variables.scss";

.wrapper {

  .container {
    width: 100%;
    height: 100%;
    background-color: $NEUTRAL_10;
    padding: 24px;
    border-radius: 8px;

    .topWrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 16px;

      h4 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: $NEUTRAL_100;

        span {
          color: $DANGER_MAIN;
        }
      }

      button {
        padding: 8px 12px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 6px;
        border: 1px solid $PRIMARY_MAIN;
        background-color: $NEUTRAL_10;
        color: $PRIMARY_MAIN;

        span {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
        }

        &.mobile {
          display: none;
        }
      }
    }

    .contentWrapper {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
      gap: 16px;

      .boxInput {

        .label {
          padding-bottom: 4px;
          font-size: 13px;
          font-weight: 500;
          color: $NEUTRAL_100;

        }

        .selectWrapper {
          padding-bottom: 4px;

        }
      }
    }




  }

  .buttonWrapper {
    padding-top: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    button {
      display: flex;
      align-items: center;
      gap: 6px;
      padding: 10px;
      padding-right: 16px;
      border: none;
      border-radius: 20px;
      background-color: $PRIMARY_SOFT;
      color: $PRIMARY_MAIN;
      transition: all 0.3s;

      span {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;

      }

      &:disabled {
        color: $NEUTRAL_60;
      }


    }
  }


  @media screen and (max-width: 768px) {
    .container {
      padding: 8px;
    }
    .contentWrapper {
      grid-template-columns: 1fr !important;

     
    }

    .topWrapper {
      padding-bottom: 10px!important;
    }

    button {
      &.mobile {
        display: flex !important;
      }

      &.desktop {
        display: none!important;
      }
    }

  }
}