@import "../../../../../../theme/variables.scss";

.container {
  width: 100%;
  height: 100%;
  position: relative;

  .errorBorder {
    border: 1px solid #f5610d;
  }

  .autoScroll {
    display: hidden;
    position: absolute;
    top: 0;
  }

  .empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    padding: 24px;
    text-align: center;

    > img {
      width: 150px;
      object-fit: contain;
      max-width: 90vw;
    }

    > div {
      width: 374px;
      max-width: 90vw;
      display: flex;
      flex-direction: column;
      gap: 8px;

      > span {
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        letter-spacing: 0.09px;
        color: $PRIMARY_MAIN;
      }

      > p {
        font-family: Poppins;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 153.846%;
        color: $NEUTRAL_70;
      }
    }
  }

  .contractButton {
    padding: 8px;
    background-color: $PRIMARY_MAIN;
    border-radius: 8px;
    border: none;
    border-radius: 8px;
    color: $NEUTRAL-10;

    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 142.857%;
  }

  .seeFile {
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
    color: $PRIMARY_MAIN;
    cursor: pointer;
    &.disabled {
      cursor: unset;
      color: $NEUTRAL_50;
      text-decoration-line: none;
    }
  }

  .deleteButton {
    background-color: transparent;
    padding: 0;
    display: grid;
    place-items: center;
    border: none;
  }

  .odd {
    > td {
      background-color: $PRIMARY_SURFACE !important;
    }
  }

  .header {
    display: flex;
    flex-direction: column;
    gap: 4px;

    h3 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0.005em;
      color: $NEUTRAL_100;

      span {
        color: $DANGER_MAIN;
      }
    }

    span {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $NEUTRAL_70;
    }
  }

  .content {
    padding-top: 32px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 16px;
    padding-bottom: 24px;

    .card {
      padding: 32px;
      background-color: $NEUTRAL_10;
      border: 1px solid $NEUTRAL_40;
      border-radius: 8px;
      cursor: pointer;
      transition: all 0.3s;
      position: relative;

      &:hover {
        border: 1px solid $PRIMARY_SOFT;
      }

      &.active {
        border: 1px solid $PRIMARY_MAIN;
      }

      .iconWrapper {
        position: absolute;
        right: 10px;
        top: 10px;
        color: $PRIMARY_MAIN;
        transition: all 0.3s;

        &.notSelected {
          opacity: 0;
        }

        &.selected {
          opacity: 1;
        }
      }

      .cardHeader {
        display: flex;
        flex-direction: column;
        gap: 16px;
        text-align: center;

        h4 {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 150%;
          text-align: center;
          letter-spacing: 0.005em;
          color: $NEUTRAL_DARKEST;
        }

        span {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: $NEUTRAL_70;
        }
      }

      .cardContent {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding-top: 32px;

        .Rule {
          display: flex;
          gap: 16px;

          img {
            height: 18px;
            aspect-ratio: 1;
          }

          p {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            color: $DARK_CHARCOAL;
          }
        }
      }
    }
  }

  .contracts {
    background-color: $NEUTRAL-10;
    padding: 24px;
    border-radius: 8px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;

    .cHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
    }

    .table {
      overflow: auto;

      .rowActions {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }
  }

  .buttonWrapper {
    padding-block: 24px;
    float: right;

    button {
      display: grid;
      place-items: center;
      width: 98px;
      height: 36px;
      background: $PRIMARY_MAIN;
      border: none;
      border-radius: 8px;
      color: $NEUTRAL_10;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      transition: all 0.3s;
      cursor: pointer;

      &:disabled {
        background-color: $PRIMARY_DISABLED;
      }
    }
  }
}

.remarkRow {
  color: var(--Primary-Main, #1571de);
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
  cursor: pointer;
  width: 100px;
  &.disabled {
    text-decoration-line: none;
    color: #404040;
    cursor: unset;
  }
}

@media screen and (max-width: 768px) {
  .container {
    .header {
      gap: 2px;

      h3 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 140%;
      }
    }

    .content {
      padding-top: 16px;
      grid-template-columns: 1fr;
      gap: 16px;

      .card {
        padding: 16px;
      }
    }

    .buttonWrapper {
      width: 100%;

      button {
        width: 100%;
      }
    }
  }
}
