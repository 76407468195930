@import "../../../../../theme/variables.scss";


.container {
  background-color: $NEUTRAL_10;
  border-radius: 20px 20px 0 0;
  padding: 5px 16px;
  padding-bottom: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .topLine {
    width: 42px;
    height: 5px;
    background-color: #B4B7BA;
    border-radius: 5px;
  }

  >*:not(.topLine) {
    width: 100%;
  }

  .title {
    padding-top: 12px;
    padding-bottom: 24px;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    text-align: center;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .each {
      display: flex;
      align-items: center;
      gap: 8px;
      
      >*:first-child {
        width: 25%;
      }

    }
  }
}