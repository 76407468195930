@import "../../../../theme/variables.scss";

.background {
  height: 100vh;
  width: 100vw;
  background-color: #00000080;
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  .container {
    background-color: $NEUTRAL_10;
    border-radius: 8px;
    width: 732px;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 100px);
    max-width: calc(100vw - 50px);
    overflow-y: hidden;
    .headWrapper {
      border-bottom: 1px solid $NEUTRAL_30;
      .header {
        padding: 24px 24px 16px 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .logoWrapper {
          display: flex;
          align-items: center;
          gap: 16px;
          > div {
            height: 75px;
            aspect-ratio: 1;
            display: grid;
            place-items: center;
            border-radius: 50%;
            background-color: #f3f5f7;

            > img {
              height: 50px;
              aspect-ratio: 1;
              object-fit: contain;
            }
          }

          > span {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 150%;
            color: $NEUTRAL_DARKEST;
          }
        }
        .watermarkWrapper {
          display: flex;
          flex-direction: column;
          gap: 4px;
          > p {
            font-weight: 400;
            font-size: 12px;
            color: $NEUTRAL_60;
          }
          > img {
            width: 95px;
            // height: 41px;
          }
        }
      }
    }
    .body {
      padding: 16px;
      overflow-y: scroll;
      > :first-child {
        text-align: center;
        font-weight: 600;
        font-size: 16px;
        margin-bottom: 16px;
      }
      .text {
        font-weight: 400;
        font-size: 14px;
      }
      .textList {
        font-weight: 400;
        font-size: 13px;
        white-space: pre-wrap;
        margin-bottom: 1px;
      }
      .textHead {
        font-weight: 500;
        font-size: 13px;
      }
      // .pharagraph {
      // }
      .pharagraphListWrapper {
        padding-block: 16px;
        .pharagraphList {
          > ul {
            padding-left: 16px;
            > li {
              padding-block: 1px;
              > p {
                font-weight: 400;
                font-size: 13px;
                line-height: 20px;
              }
            }
          }
        }
        display: flex;
        flex-direction: column;
        gap: 16px;
      }
    }
  }
}
