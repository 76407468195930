@import "../../../../theme/variables.scss";
.container {
  width: 100vw;
  z-index: 5;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 16px;
  border-radius: 8px;
}
.text {
  display: flex;
  align-items: center;
  width: 50%;
  gap: 4px;
  display: flex;
  align-items: center;
  padding: 12px 12px;
  background: #22970f;
  color: $NEUTRAL-10;
  font-feature-settings: "pwid" on;
  text-align: center;
  justify-content: space-between;
  border-radius: 8px;

  /* Body/Body 2 */
  //   font-family: Noto Sans;
  font-size: calc(10px + (14 - 10) * ((100vw - 280px) / (1600 - 280)));
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */

  .content {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  .cancelButton {
    border-radius: 6px;
    padding: 8px;
    color: $NEUTRAL-10;
    gap: 4px;
    border: 1px solid $NEUTRAL-10;
    background-color: #22970F;
  }
}

.icon {
  cursor: pointer;
}

.containerRecruitment {
  position: fixed;
  top: -100px; /* start above the viewport */
  left: 50%;
  transform: translateX(-50%);
  border-radius: 8px;
  background: var(--Success-Main, #27bb83);
  color: var(--Neutral-10, var(--Color-Neutal-Neutral-10, #fff));
  // text-align: center;

  /* Text M/Medium */
  // font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  padding: 8px 12px;
  // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: top 0.5s ease-in-out;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  &.show {
    top: 20px;
  }
}

@media screen and (max-width: 768px) {
  .container {
    align-items: flex-start;
  }
}
