@import "../../../../../../theme/variables.scss";

.container {
  z-index: inherit !important;

  .autoScroll {
    display: hidden;
    position: absolute;
    top: 0;
  }

  .content {
    background-color: $NEUTRAL-10;
    border-radius: 4px;

    .steps {
      display: flex;
      align-items: center;
      gap: 4px;
      padding: 12px 16px;

      >* {
        padding: 6px 10px;
        border-radius: 5px;
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        color: $NEUTRAL-60;
        line-height: 142.857%;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: $PRIMARY_SURFACE;
        }

        &.active {
          background-color: $PRIMARY_MAIN;
          color: $NEUTRAL-10;
        }

      }
    }

    .info {
      background-color: $PRIMARY_SOFT;
      display: flex;
      align-items: center;
      gap: 24px;
      padding: 8px 16px;

      >img {
        height: 32px;
        aspect-ratio: 1;
        object-fit: contain;
      }

      .desc {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        gap: 4px;

        >span {
          font-size: 16px;
          font-weight: 700;
          line-height: 140%;
          color: $NEUTRAL-90;
        }

        >p {
          font-size: 14px;
          line-height: 142.857%;
          color: $NEUTRAL-70;
        }
      }

      .actions {
        display: flex;
        gap: 16px;
        // width: 436px;
        // max-width: 100vw;
        align-items: center;
        justify-content: flex-end;

        button {
          height: 36px;
          border-radius: 8px;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          cursor: pointer;
          padding-inline: 16px;
          white-space: nowrap;

          &.saveToDraft {
            background-color: $NEUTRAL_10;
            border: 1px solid $PRIMARY_MAIN;
            color: $PRIMARY_MAIN;
          }

          &.submit {
            background-color: $PRIMARY_MAIN;
            border: 1px solid $PRIMARY_MAIN;
            color: $NEUTRAL_10;

            &:disabled {
              background-color: $PRIMARY_DISABLED;
              border-color: $PRIMARY_DISABLED;
            }
          }
        }
      }
    }

    .main {
      padding: 24px;
    }

  }


  .buttonsWrapper {
    padding-top: 24px;
    display: flex;
    align-items: center;
    gap: 16px;
    padding-bottom: 110px;

    .left {
      flex-grow: 1;

      button {
        height: 36px;
        border-radius: 8px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        cursor: pointer;
        padding-inline: 16px;

        &.prev {
          background-color: $NEUTRAL_10;
          border: 1px solid $NEUTRAL_100;
          color: $NEUTRAL_100;
        }
      }
    }

    .right {
      display: flex;
      gap: 16px;
      // width: 436px;
      // max-width: 100vw;
      align-items: center;
      justify-content: flex-end;

      button {
        height: 36px;
        border-radius: 8px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        cursor: pointer;
        padding-inline: 16px;

        &.saveToDraft {
          background-color: $NEUTRAL_10;
          border: 1px solid $PRIMARY_MAIN;
          color: $PRIMARY_MAIN;
        }

        &.submit {
          background-color: $PRIMARY_MAIN;
          border: 1px solid $PRIMARY_MAIN;
          color: $NEUTRAL_10;

          &:disabled {
            background-color: $PRIMARY_DISABLED;
            border-color: $PRIMARY_DISABLED;
          }
        }
      }
    }
  }

}

@media screen and (max-width: 768px) {
  .container {

    .content {

      .info {

        .actions {
          flex-direction: column;
          gap: 8px;

          >* {
            width: 100%;
          }
        }
      }
    }
  }
}