$bg-tutorial: url("../../../../assets/Images/bg-tutorial.png");
$images_tutorial-1: url("../../../../assets/Images/Tutorial/tutorial-1.png");
$images_tutorial-2: url("../../../../assets/Images/Tutorial/tutorial-2.png");
$images_tutorial-3: url("../../../../assets/Images/Tutorial/tutorial-3.png");
$images_tutorial-4: url("../../../../assets/Images/Tutorial/tutorial-4.png");
$images_tutorial-5: url("../../../../assets/Images/Tutorial/tutorial-5.png");
$images_tutorial-6: url("../../../../assets/Images/Tutorial/tutorial-6.png");

.containerModal {
  height: 100vh;
  width: 100vw;
  background-color: #00000080;
  z-index: 5;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
}

.modalTutorial {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-radius: 8px;
  background: var(--Color-Neutal-Neutral-10, #fff);
}

.textModal {
  color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));

  /* Text L/SemiBold */
  //   font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 22.4px */
}
.carouselImages {
  background-image: $bg-tutorial;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  //   padding: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel {
  width: 100%;
  height: 270px;
  transition: 0.4s ease-in-out;
  // animation: slideAnimation 10s linear infinite;

  &.Background_1 {
    background-image: $images_tutorial-1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    // transition: transform 0.5s ease-in-out;
    // transform: translateX(100vw);
  }
  &.Background_2 {
    background-image: $images_tutorial-2;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    // transition: transform 0.5s ease-in-out;
  }
  &.Background_3 {
    background-image: $images_tutorial-3;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    // transition: transform 0.5s ease-in-out;
    // transform: translateX(-100vw);
  }
  &.Background_4 {
    background-image: $images_tutorial-4;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    // transition: transform 0.5s ease-in-out;
  }
  &.Background_5 {
    background-image: $images_tutorial-5;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  &.Background_6 {
    background-image: $images_tutorial-6;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

 
}



.descBox {
  display: flex;
  flex-direction: column;
  gap: 8px;
  .textDesc {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .bullet {
    // color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));

    /* Text M/Reguler */
    // font-family: Poppins;
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    position: relative;
    bottom: 9px;
  }
  .p {
    color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));

    /* Text M/Reguler */
    // font-family: Poppins;
    font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
}

.buttonBox {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  .buttonBack {
    cursor: pointer;
    width: 100%;
    border-radius: 8px;
    border: 1px solid var(--Primary-Main, #1571de);
    background: var(--Neutral-10, #fff);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    color: var(--Primary-Main, #1571de);
    text-align: center;

    /* Text M/Reguler */
    // font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
  .buttonNext {
    cursor: pointer;
    width: 100%;
    border-radius: 8px;
    background: var(--Primary-Main, #1571de);
    display: flex;
    padding: 12px;
    justify-content: center;
    align-items: center;
    color: var(--Color-Neutal-Neutral-10, #fff);
    text-align: center;

    /* Text M/Reguler */
    // font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
}

@media screen and (max-width: 540px) {
  .containerModal {
    align-items: flex-end;
    padding: 0;
  }
  .modalTutorial {
    position: fixed;
    bottom: 0;
    width: 100vw !important;
    height: 80%;
    overflow: auto !important;
  }

  // .buttonBox {
  //   background-color: #fff;
  //   position: sticky;
  //   bottom: 0;
  //   padding-top: 16px;
  // }
}

@media screen and (max-width: 385px) {
  .buttonBox {
    flex-direction: column;
  }
}
