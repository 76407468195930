@import "../../../../../theme/variables.scss";

.container {
  .left {
    position: fixed;
    height: 100vh;
    width: 45%;
    background-image: linear-gradient(
        159.91deg,
        #1571de -1.6%,
        rgba(21, 113, 222, 0.57) 124.46%
      ),
      url(../../../../../assets//Images/background-linear-gradient.png);
    background-size: cover;
    padding: 2%;
    display: grid;
    place-items: center;

    .topWrapper {
      display: flex;
      align-items: center;
      gap: 16px;
      position: absolute;
      top: 40px;
      left: 30px;

      .logoWrapper {
        background-color: $NEUTRAL_20;
        width: 52px;
        height: 52px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 25px;
        }
      }

      span {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: calc(14px + (18 - 14) * ((100vw - 280px) / (1600 - 280)));
        line-height: 150%;
        color: $NEUTRAL_10;
      }
    }

    .box {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding-block: 10%;
      gap: 24px;

      > img {
        width: 57%;
      }

      .textLeft {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        .nameOffer {
          display: flex;
          flex-direction: column;
          gap: 4px;
        }
      }

      h3 {
        color: #fff;
        text-align: center;

        /* Heading 5/Reguler */
        // font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 36.4px */
        letter-spacing: 0.13px;
        // padding-bottom: 24px;
      }

      p {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        text-align: center;
        color: $NEUTRAL_10;
      }

      .position {
        color: #e5dd1c;

        /* Text L/Bold */
        // font-family: Poppins;
        font-size: calc(14px + (16 - 14) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
      }

      .nameCandidate {
        color: #e5dd1c;
        text-align: center;

        /* Heading 4/Bold */
        // font-family: Poppins;
        // font-size: 32px;
        font-size: calc(14px + (32 - 14) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 700;
        line-height: 140%; /* 44.8px */
        letter-spacing: 0.16px;
      }
    }
  }

  .right {
    margin-left: 45%;
    width: 55%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    padding: 4%;

    > * {
      width: 100%;
    }

    .H4 {
      color: #000;

      /* Text L/SemiBold */
      // font-family: Poppins;
      font-size: calc(14px + (26 - 14) * ((100vw - 280px) / (1600 - 280)));
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 22.4px */
      padding-bottom: 4px;
    }

    p {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 120%;
      color: #6f6f70;
      padding-bottom: 32px;
    }

    .buttonBox {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    .buttonReject {
      // height: 36px;
      display: flex;
      padding: 12px;
      align-items: center;
      justify-content: center;
      color: var(--primary-main, #1571de);
      text-align: center;
      // font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      text-decoration-line: underline;
      cursor: pointer;
    }

    .buttonNext {
      height: 36px;
      // left: 0px;
      // top: 130px;
      background: $PRIMARY_MAIN;
      border-radius: 8px;
      border: none;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $NEUTRAL_10;

      &:disabled {
        background-color: $PRIMARY_DISABLED;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    min-height: 100vh;

    .left {
      position: relative;
      height: auto;
      min-height: 35vh;
      width: 100%;
      background-image: linear-gradient(
          159.91deg,
          #1571de -1.6%,
          rgba(21, 113, 222, 0.57) 124.46%
        ),
        url(../../../../../assets//Images/background-linear-gradient.png);
      background-size: cover;
      padding: 5%;
      display: grid;
      place-items: center;

      .topWrapper {
        display: flex;
        // flex-direction: column;
        align-items: center;
        gap: 16px;
        position: absolute;
        top: 16px;
        left: 5%;

        .logoWrapper {
          background-color: $NEUTRAL_20;
          width: 24px;
          height: 24px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 40%;
          }
        }

        span {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 150%;
          color: $NEUTRAL_10;
        }
      }

      .box {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        // gap: 24px;
        padding-block: 0;
        // gap: 5%;
        padding-top: 12%;

        .textLeft {
          gap: 10px;
        }

        > img {
          width: 80%;
          // width: 309px;
          // height: 188px;
        }

        h3 {
          font-size: 16px;
        }

        p {
          font-size: 14px;
        }
      }
    }

    .right {
      margin-left: 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: auto;
      padding: 5%;
      padding-top: 32px;

      > * {
        width: 100%;
      }
      .H4 {
        // font-size: 16px;
      }

      p {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #6f6f70;
        padding-bottom: 32px;
      }

      button {
        height: 36px;
        left: 0px;
        top: 130px;
        background: $PRIMARY_MAIN;
        border-radius: 8px;
        border: none;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $NEUTRAL_10;
      }
    }
  }
}
