@import "../../../../../../../../../theme/variables.scss";

.newTag {
  padding: 2px 4px;
  border-radius: 25px;
  background-color: $PRIMARY_SURFACE;

  color: $PRIMARY_MAIN;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.newContactContainer {
  height: 100px;
  flex-grow: 1;
  overflow: auto;

  > * {
    padding-inline: 24px;
  }

  .newHeader {
    display: flex;
    align-items: center;
    gap: 8px;
    border-bottom: 1px solid $NEUTRAL_30;
    padding-block: 16px;
    position: sticky;
    top: 0;
    background-color: $NEUTRAL-10;
    z-index: 1;

    > button {
      display: grid;
      place-items: center;
      padding: 0;
      background-color: transparent;
      border: none;
    }

    > span {
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
    }
  }

  .newContent {
    padding-block: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}

.buttonsWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 16px;
  gap: 8px;
  padding-bottom: 24px;

  button {
    padding: 8px 16px;
    border-radius: 8px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    transition: all 0.3s;

    &:first-child {
      background-color: $NEUTRAL_10;
      border: 1px solid $PRIMARY_MAIN;
      color: $PRIMARY_MAIN;
    }

    &:last-child {
      background-color: $PRIMARY_MAIN;
      border: 1px solid $PRIMARY_MAIN;
      color: $NEUTRAL_10;

      &:disabled {
        background-color: $PRIMARY_DISABLED;
        border-color: $PRIMARY_DISABLED;
      }
    }
  }
}

.background {
  width: 100vw;
  height: 100%;
  background-color: $NEUTRAL_OVERLAY;
  z-index: 3;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  &.backgroundRecommended {
    background-color: unset;
    z-index: unset;
  }

  .container {
    width: 705px;
    background: $NEUTRAL_10;
    border-radius: 8px;
    // padding-block: 24px;
    // max-height: calc(100vh - 50px);
    // min-height: calc(100vh - 50px);
    height: 596px;
    max-width: calc(100vw - 50px);
    overflow-y: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .header,
    .normal {
      padding-inline: 24px;
    }

    .header {
      display: flex;
      align-items: center;
      padding-top: 24px;

      h3 {
        display: flex;
        align-items: center;
        flex-grow: 1;

        span {
          font-family: "Poppins";

          &:first-child {
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 150%;
            letter-spacing: 0.005em;
            color: $NEUTRAL_100;
            padding-right: 10px;
          }

          &:last-child {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 4px 16px;
            gap: 4px;
            margin-left: 14px;

            // height: 28px;

            background: $PRIMARY_SURFACE;
            border: 1px solid $PRIMARY_MAIN;
            border-radius: 8px;

            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: $PRIMARY_MAIN;
          }
        }
      }

      .searchBarWrapper {
        width: 250px;
      }
    }

    .normal {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      gap: 16px;
      overflow: auto;
      height: 100px;

      .title {
        display: flex;
        align-items: center;
        gap: 16px;

        > h4 {
          flex-grow: 1;
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 140%;
        }

        > button {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 4px;
          padding: 8px;
          border-radius: 6px;
          background-color: $NEUTRAL-10;
          border: 1px solid $PRIMARY_MAIN;
          color: $PRIMARY_MAIN;

          > span {
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
          }
        }
      }

      .content {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        gap: 8px;

        .titleWrapper {
          display: flex;
          align-items: center;
          padding-top: 16px;

          > h4 {
            flex-grow: 1;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 140%;
          }
        }

        h4 {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: $NEUTRAL_100;
          padding-bottom: 8px;
        }

        .tableWrapper {
          height: 100px;
          flex-grow: 1;
          display: flex;

          .tableContainer {
            border: 1px solid #d9d9d9;
            border-radius: 8px;
            flex-grow: 1;

            .table {
              border-collapse: separate;

              .tableHead {
                position: sticky;
                top: 0;
                background-color: $NEUTRAL-10;
                z-index: 1;

                .tableHeadRow {
                  .tableHeadCell {
                    padding: 14px 16px !important;
                  }
                }
              }

              .tableBody {
                .tableRow {
                  &.selected {
                    background-color: $PRIMARY_SURFACE;
                  }

                  .tableCell {
                    padding: 12px 16px !important;

                    .stackedProfile {
                      background-color: transparent;
                      border: none;
                      display: flex;
                      align-items: center;
                      gap: 8px;
                    }
                  }

                  .buttonIcon {
                    display: grid;
                    place-items: center;
                    background-color: transparent;
                    border: none;
                    color: $PRIMARY_MAIN;

                    &.expand {
                      position: relative;
                    }

                    &:disabled {
                      color: $NEUTRAL_60;
                    }

                    > img {
                      height: 24px;
                      aspect-ratio: 1;
                      z-index: 0;
                    }
                  }
                }
              }
            }

            .bottom {
              width: 100%;
              height: 24px;
            }
          }
        }

        .checkBoxWrapper {
          display: grid;
          align-items: center;
          grid-template-columns: 50% 50%;
          gap: 8px;

          .containerSend {
            display: flex;
            align-items: center;
            gap: 16px;
            padding: 12px;
            border: 1px solid $NEUTRAL-40;
            border-radius: 12px;
          }

          .containerSendDisable {
            display: flex;
            align-items: center;
            gap: 16px;
            padding: 12px;
            border: 1px solid $NEUTRAL-40;
            border-radius: 12px;
            background-color: $NEUTRAL-20;
            color: $NEUTRAL_60;
          }

          .containerSendEmailError {
            display: flex;
            // justify-content: center;
            align-items: center;
            color: $PRIMARY_MAIN;
            gap: 8px;
          }
        }
      }
    }
  }
}

.mobileContainer {
  z-index: 1000;
  width: 100%;
  background: $NEUTRAL_10;
  border-radius: 20px 20px 0 0;
  // padding: 24px;
  padding-top: 0;
  // max-height: calc(100vh - 50px);
  // min-height: calc(100vh - 50px);
  height: 85vh;
  overflow-y: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  &.mobileContainerRecommended {
    background: unset;
    z-index: unset;
  }

  > * {
    width: 100%;
  }

  .header,
  .normal {
    padding-inline: 24px;
  }

  .header {
    padding-top: 24px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 16px;
    position: sticky;
    top: 0;
    background-color: $NEUTRAL_10;
    z-index: 5;
    padding-bottom: 16px;
    border-bottom: 1px solid $NEUTRAL_40;

    h3 {
      display: flex;
      align-items: center;
      flex-grow: 1;
      width: 100%;
      flex-wrap: wrap;
      gap: 8px;

      > * {
        flex-shrink: 0;
      }

      span {
        font-family: "Poppins";

        &:first-child {
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 150%;
          letter-spacing: 0.005em;
          color: $NEUTRAL_100;
          padding-right: 10px;
        }

        &:last-child {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          padding: 4px 16px;
          gap: 4px;

          // height: 28px;

          background: $PRIMARY_SURFACE;
          border: 1px solid $PRIMARY_MAIN;
          border-radius: 8px;

          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: $PRIMARY_MAIN;
        }
      }
    }

    .searchBarWrapper {
      width: 100%;
    }
  }

  .normal {
    width: 100%;
    flex-grow: 1;
    padding-top: 16px;

    .title {
      display: flex;
      align-items: center;
      gap: 16px;

      > h4 {
        flex-grow: 1;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
      }

      > button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        padding: 8px;
        border-radius: 6px;
        background-color: $NEUTRAL-10;
        border: 1px solid $PRIMARY_MAIN;
        color: $PRIMARY_MAIN;

        > span {
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }
      }
    }
  }

  .content {
    padding-top: 16px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-bottom: 80px;

    h4 {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: $NEUTRAL_100;
      padding-bottom: 8px;
    }

    .tableWrapper {
      flex-grow: 1;
      display: flex;

      .tableContainer {
        border: 1px solid #d9d9d9;
        border-radius: 8px;
        flex-grow: 1;

        .table {
          .tableHead {
            .tableHeadRow {
              .tableHeadCell {
                padding: 14px 16px !important;
              }
            }
          }

          .tableBody {
            .tableRow {
              &.selected {
                background-color: $PRIMARY_SURFACE;
              }

              .tableCell {
                padding: 12px 16px !important;

                .stackedProfile {
                  background-color: transparent;
                  border: none;
                  display: flex;
                  align-items: center;
                  gap: 8px;
                }
              }

              .buttonIcon {
                display: grid;
                place-items: center;
                background-color: transparent;
                border: none;
                color: $PRIMARY_MAIN;

                &.expand {
                  position: relative;
                }

                &:disabled {
                  color: $NEUTRAL_60;
                }

                > img {
                  height: 24px;
                  aspect-ratio: 1;
                  z-index: 0;
                }
              }
            }
          }
        }

        .bottom {
          width: 100%;
          height: 24px;
        }
      }
    }

    .titleWrapper {
      display: flex;
      align-items: center;
      padding-top: 16px;

      > h4 {
        flex-grow: 1;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
      }
    }

    .checkBoxWrapper {
      display: grid;
      align-items: center;
      grid-template-columns: 100%;
      gap: 8px;

      .containerSend {
        display: flex;
        align-items: center;
        gap: 16px;
        padding: 12px;
        border: 1px solid $NEUTRAL-40;
        border-radius: 12px;
      }

      .containerSendDisable {
        display: flex;
        align-items: center;
        gap: 16px;
        padding: 12px;
        border: 1px solid $NEUTRAL-40;
        border-radius: 12px;
        background-color: $NEUTRAL-20;
        color: $NEUTRAL_60;
      }

      .containerSendEmailError {
        display: flex;
        // justify-content: center;
        align-items: center;
        color: $PRIMARY_MAIN;
        gap: 8px;
      }
    }

    .buttonsWrapper {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 8px;
      background-color: $NEUTRAL_10;
      padding: 8px 16px;
      padding-bottom: 24px;
      border-top: 1px solid $NEUTRAL_40;

      button {
        padding: 8px 16px;
        border-radius: 8px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        transition: all 0.3s;
        width: 100%;

        &:first-child {
          background-color: $NEUTRAL_10;
          border: 1px solid $PRIMARY_MAIN;
          color: $PRIMARY_MAIN;
        }

        &:last-child {
          background-color: $PRIMARY_MAIN;
          border: none;
          color: $NEUTRAL_10;

          &:disabled {
            background-color: $PRIMARY_DISABLED;
          }
        }
      }
    }
  }
}
