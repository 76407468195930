@import "../../../../../../../../theme/variables.scss";

.container {
  padding: 20px;
  border: 1px solid #E7E7E7;
  border-radius: 20px;

  >h3 {
    display: none;
  }

  .content {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    row-gap: 20px;
    column-gap: 15px;
    padding-bottom: 20px;
  }

  >button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 8px;
    background: $MISTY_ROSE;
    border-radius: 8px;
    border: none;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: $ROSSO_CORSA;

  }
}

@media screen and (max-width: 768px) {
  .container {
    padding: 0px;
    border: none;
    border-radius: 0;

    h3 {
      display: block!important;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      padding-bottom: 8px!important;
    }

    .content {
      grid-template-columns: 1fr;

    }
  }
}