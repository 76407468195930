@import "../../../../../../theme/variables.scss";

.container{
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;

    .title {
        font-size: 13px;
        font-weight: 600;
        color: $NEUTRAL_100;
    }
    
    .content{
        display: flex;
        width: 100%;
        gap: 8px;

        .buttonWrap{
            width: 50%;
            background-color: $NEUTRAL_20;
            border: 1px solid $NEUTRAL_20;
            border-radius: 8px;
            padding: 10px 16px 10px 16px;
            color: $NEUTRAL_60;
            font-weight: 500;
            font-size: 14px;
            font-family: 'Poppins';

            &:active{
                color: $PRIMARY_MAIN;
                border-color: $PRIMARY_MAIN;
            }
        }

        .selectedButton{
            color: $PRIMARY_MAIN;
            border-color: $PRIMARY_MAIN;
        }
    }
}