@import "../../../../theme/variables.scss";

.container {
  background-color: $NEUTRAL_10;
  width: 100vw;
  min-height: 100vh;
  z-index: 4;
  padding-inline: 8%;
  padding-block: 5%;

  .checkButton {
    background-color: transparent;
    border: none;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration-line: underline;
    color: $PRIMARY_MAIN;
  }

  .burgerButton {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
      display: grid;
      place-items: center;
      background-color: transparent;
      border: none;
    }
  }

  .actionsWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;

    button {
      padding: 8.5px 12px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;
      border: none;
      color: $NEUTRAL_10;

      span {
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }

      &.rejected,
      &.noresponse {
        background-color: transparent;
        color: $DANGER_MAIN !important;
        border: none;
        cursor: auto;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        padding: 0;

        &.noresponse {
          color: $NEUTRAL_100 !important;
        }
      }

      &.rejectButton {
        background-color: $DANGER_MAIN;
        width: 50%;

        &:disabled {
          background-color: $DANGER_SURFACE;
        }
      }

      &.acceptButton {
        background-color: $PRIMARY_MAIN;
        width: 50%;

        &:disabled {
          background-color: $PRIMARY_DISABLED;
        }
      }

      &.rejectedButton {
        background-color: $DANGER_SURFACE;
        color: $DANGER_MAIN;
        width: 100%;
      }

      &.acceptedButton {
        background-color: $SUCCESS_SURFACE;
        color: $SUCCESS_MAIN;
        width: 100%;
      }
    }
  }

  .previewCVButton {
    background-color: transparent;
    border: 1px solid $PRIMARY_MAIN;
    border-radius: 8px;
    color: $PRIMARY_MAIN;
    display: flex;
    align-items: center;
    gap: 4px;
    width: 100%;
    padding: 8px 12px;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .stickyLeft {
    position: sticky;
    left: 300px;
  }

  .customLabel {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }

  .emailLink {
    color: $NEUTRAL_90;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    &:hover {
      color: $PRIMARY_MAIN;
    }
  }

  .profile {
    display: flex;
    align-items: center;
    gap: 8px;
    border: none;
    background-color: transparent;

    img {
      height: 36px;
      aspect-ratio: 1;
      object-fit: contain;
      border-radius: 50%;
    }

    span {
      font-family: Poppins;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
  }

  .header {
    padding-bottom: 16px;
    border-bottom: 1px solid $NEUTRAL_40;

    display: flex;
    align-items: center;
    padding-top: 8px;

    h1 {
      color: #000;
      font-family: Poppins;
      font-size: 26px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      flex-grow: 1;
    }

    .right {
      display: flex;
      align-items: center;
      gap: 16px;

      div {
        height: 52px;
        aspect-ratio: 1;
        border-radius: 50%;
        display: grid;
        place-items: center;
        background-color: #f3f5f7;

        img {
          width: 45%;
        }
      }

      h3 {
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        /* 27px */
      }
    }
  }

  .intro {
    padding-block: 24px;
    display: flex;
    gap: 16px;

    .left {
      flex-grow: 1;
      display: flex;
      align-items: center;

      .card {
        border: 1px solid $NEUTRAL_40;
        border-radius: 8px;
        display: flex;
        align-self: flex-start;
        padding-block: 16px;
        width: 80%;

        .each {
          padding-inline: 16px;
          width: 50%;
          display: flex;
          flex-direction: column;

          .introHeader {
            display: flex;
            align-items: center;
            gap: 8px;
            padding-bottom: 8px;

            > h5 {
              flex-grow: 1;
            }

            > button {
              display: none;
              place-items: center;
              background-color: transparent;
              border: none;
            }
          }

          &:first-child {
            border-right: 1px solid $NEUTRAL_40;
          }

          h5 {
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
            // padding-bottom: 8px;
          }

          .content {
            display: flex;
            flex-direction: column;
            gap: 8px;
            padding: 8px 16px;
            border-radius: 8px;
            background-color: $NEUTRAL_20;
            flex-grow: 1;

            .label {
              display: flex;
              align-items: center;
              gap: 10px;

              img {
                height: 20px;
                aspect-ratio: 1;
                object-fit: contain;
                border-radius: 50%;
                flex-shrink: 0;
              }

              .labelIcon {
                flex-shrink: 0;
              }

              span {
                color: $NEUTRAL_100;
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
                overflow-wrap: anywhere;
              }
            }
          }
        }
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 8px;
      width: 40%;

      .notionWrapper {
        flex-grow: 1;

        p {
          padding: 16px;
          border-radius: 8px;
          border: 1px solid $NEUTRAL_40;
          color: #6f6f70;
          font-family: Poppins;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;

          span {
            font-style: italic;
            font-weight: 700;

            &.accept {
              color: $PRIMARY_MAIN;
            }

            &.reject {
              color: $DANGER_MAIN;
            }

            &.offering {
              color: $SUCCESS_MAIN;
            }
          }
        }
      }

      .filterWrapper {
        display: flex;
        align-items: center;
        gap: 10px;
        width: 100%;

        > * {
          width: 100%;
          // min-width: 220px;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .container {
    .intro {
      flex-direction: column;

      .left {
        .card {
          align-self: stretch;
          width: 100%;
          padding-block: 8px;

          .each {
            padding-inline: 8px;

            .content {
              padding: 8px 12px;
            }
          }
        }
      }

      .right {
        flex-direction: row;
        align-items: flex-start;
        width: 100%;

        .notionWrapper {
          display: flex;
          justify-content: flex-end;
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .stickyLeft {
    position: static !important;
  }
}

@media screen and (max-width: 768px) {
  .container {
    padding-inline: 5%;

    .header {
      h1 {
        font-size: 22px;
      }

      .right {
        > div {
          height: 45px;
        }
      }
    }

    .hideOnMobile {
      display: none;
    }

    .header {
      // flex-direction: column;
      gap: 8px;

      h3 {
        display: none;
      }
    }

    .intro {
      .right {
        flex-direction: column;

        > * {
          width: 100%;

          > * {
            flex-grow: 1;
            width: 50%;
          }

          p {
            justify-content: center;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .container {
    padding-inline: 0px;
    padding-top: 0px;

    > * {
      padding-inline: 16px;
    }

    .header {
      padding-top: 50px;
      padding-bottom: 16px;
      position: sticky;
      top: 0;
      background-color: $NEUTRAL_10;

      h1 {
        font-size: 16px;
      }

      .right {
        > div {
          height: 24px;
        }
      }
    }

    .intro {
      .left {
        .card {
          flex-direction: column;
          width: 100%;
          gap: 8px;
          padding: 16px;

          .each {
            width: 100%;
            padding-inline: 0px;

            &:first-child {
              border-right: none;
              padding-bottom: 0px;
            }

            .introHeader {
              button {
                display: grid;
              }
            }

            .content {
              transition: all 0.3s ease-in-out;
              max-height: 0px;
              overflow: hidden;
              opacity: 0;
              padding: 0px;

              &.show {
                display: flex !important;
                max-height: 100%;
                opacity: 1;
                padding: 8px;
              }
            }
          }
        }
      }

      .right {
        .filterWrapper {
          flex-direction: column;

          > * {
            width: 100%;
          }
        }
      }
    }

    .content {
      padding-inline: 0;
    }
  }
}
