@import "../../../../../theme/variables.scss";

.container {
  .inputWrapper {
    padding-bottom: 16px;
    // @include padding-bottom(16, );
    display: flex;
    justify-content: space-between;
    gap: 16px;

    .searchWrapper {
      height: 40px;
      width: 400px;
    }

    .sortWrapper {
      height: 40px;
    }
  }

  .tableContainer {
    background-color: $NEUTRAL_LIGHTEST;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    overflow: hidden;

    .table {
      .tableHeader {
        font-weight: 500;
        font-size: 14px;
        color: $NEUTRAL_DARKEST;
      }

      .clientName {
        display: flex;
        align-items: center;
        gap: 12px;

        & > :first-child {
          width: 32px;
          height: 32px;
          background-color: $NEUTRAL_30;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          flex-shrink: 0;
        }

        & > :last-child {
          font-weight: 400;
          font-size: 13px;
          color: $PRIMARY_MAIN;
          text-decoration: none;
          cursor: pointer;
        }
      }

      .cities {
        overflow: hidden;
        text-overflow: ellipsis;
        width: 150px;
        white-space: nowrap;
      }
    }

    .loadingWrapper {
      padding-block: 48px;
    }

    .paginationWrapper {
      border-top: 1px solid #e0e0e0;
      padding: 9px 4px;
    }
  }
}

@media screen and (max-width: 480px) {
  .container {
    .inputWrapper {
      flex-direction: column;
      gap: 8px;

      .searchWrapper {
        width: 100%;
      }
    }
  }
}
