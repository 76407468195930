@import "../../../../../../../../theme/variables.scss";

.container {
  .showOnMobile {
    display: none;
  }

  h4 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 150%;
    letter-spacing: 0.005em;
    color: $PRIMARY_MAIN;
  }

  .tableWrapper {
    .tableContainer {
      .table {
        .tableHead {
          .tableHeadRow {
            .tableHeadCell {
              padding: 0px !important;
              padding-block: 15px !important;

              span {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-family: "Poppins";
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: $NEUTRAL_DARKEST;
              }
            }
          }
        }

        .tableBody {
          .tableRow {
            .tableCell {
              padding: 0px !important;
              font-family: "Poppins";
              font-style: normal;
              font-weight: 400;
              font-size: 13px;
              line-height: 20px;
              color: $NEUTRAL_DARKEST;
              padding-block: 15px !important;

              &:first-child {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }

            &:last-child {
              .tableCell {
                border-bottom: none !important;
              }
            }
          }
        }
      }
    }
  }

  .summary {
    width: 337px;
    float: right;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .PreCalc {
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: space-between;
      gap: 10px;
      padding-block: 16px;
      border-top: 1px solid $NEUTRAL_DARKEST;
      border-bottom: 1px solid $NEUTRAL_DARKEST;

      .stacked {
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
          font-family: "Poppins";
          font-style: normal;
          line-height: 20px;
          color: $NEUTRAL_DARKEST;
          font-weight: 400;
          font-size: 13px;
        }
      }
    }

    .calc {
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: space-between;
      gap: 4px;
      padding-block: 8px;

      .stacked {
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 13px;
          line-height: 20px;
          color: $NEUTRAL_DARKEST;
        }
      }
    }
  }

  .ModalWrapper {
    position: fixed;
    transition: all 0.35s ease-in-out !important;
    z-index: 4;
    background-color: transparent;
    left: 0;
    right: 0;

    &.hideModal {
      bottom: -500px;
    }

    &.showModal {
      bottom: 0;
    }
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    background-color: $NEUTRAL_OVERLAY;
    opacity: 0;
    z-index: 3;

    &.visible {
      right: 0;
      bottom: 0;
      opacity: 1;
      transition: opacity 0.4s;
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    .showOnMobile {
      display: table-cell;
      width: 25%;

      > button {
        width: 100%;
        min-width: 77px;
        flex-shrink: 0;
        height: 28px;
        background: $PRIMARY_MAIN;
        border-radius: 8px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        color: $NEUTRAL_10;
        border: none;
      }
    }

    .hideOnMobile {
      display: none;
    }

    h4 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 150%;
      letter-spacing: 0.005em;
      color: $PRIMARY_MAIN;
    }

    .tableWrapper {
      .tableContainer {
        .table {
          .tableHead {
            .tableHeadRow {
              .tableHeadCell {
                padding: 0px !important;
                padding-block: 15px !important;

                span {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  font-family: "Poppins";
                  font-style: normal;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 20px;
                  color: $NEUTRAL_DARKEST;
                }
              }
            }
          }

          .tableBody {
            .tableRow {
              .tableCell {
                padding: 0px !important;
                font-family: "Poppins";
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 20px;
                color: $NEUTRAL_DARKEST;
                padding-block: 15px !important;

                &:first-child {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
              }

              &:last-child {
                .tableCell {
                  border-bottom: none !important;
                }
              }
            }
          }
        }
      }
    }

    .summary {
      width: 100%;
      float: right;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .PreCalc {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: space-between;
        gap: 10px;
        padding-block: 16px;
        border-top: 1px solid $NEUTRAL_DARKEST;
        border-bottom: 1px solid $NEUTRAL_DARKEST;

        .stacked {
          display: flex;
          align-items: center;
          justify-content: space-between;

          span {
            font-family: "Poppins";
            font-style: normal;
            line-height: 20px;
            color: $NEUTRAL_DARKEST;
            font-weight: 400;
            font-size: 13px;
          }
        }
      }

      .calc {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: space-between;
        gap: 4px;
        padding-block: 8px;

        .stacked {
          display: flex;
          align-items: center;
          justify-content: space-between;

          span {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 600;
            font-size: 13px;
            line-height: 20px;
            color: $NEUTRAL_DARKEST;
          }
        }
      }
    }
  }
}
