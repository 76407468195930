.container {
  height: 100vh;
  width: 100vw;
  background-color: #00000080;
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  //   align-items: flex-end;
  //   padding: 16px;
}

.modalPreviw {
  height: 100vh;
  background: var(--neutral-10, #fff);
  display: flex;

  flex-direction: column;
  width: 400px;
}

.boxTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--neutral-40, #dee2e6);
  background: var(--neutral-10, #fff);
  padding: 16px 32px 24px 32px;
  .iconCross {
    cursor: pointer;
  }
  .preview {
    color: var(--neutral-100, #000);
    text-align: right;
    font-feature-settings: "pwid" on;

    /* Heading/H6 */
    // font-family: Noto Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px; /* 144.444% */
  }
}

.previewContent {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  position: relative;
  padding: 30px 32px 30px 32px;
  .imagePreview {
    background: #000;
    height: 480px;
    width: 250px;
    border-radius: 20px;
    object-fit: cover;
  }
  .video {
    background: #000;
    height: 480px;
    width: 270px;
    border-radius: 30px;
    object-fit: cover;
    cursor: pointer;
  }
  .templateBox {
    position: absolute;
    // background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #000000 130%);
    border-radius: 45px;

    img {
      // top: 30px;
      width: 280px;
      height: 500px;
      // object-fit: cover;
    }
  }
}

.previewDisable {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //   position: relative;
  padding: 300px 32px 300px 32px;
  .disableText {
    color: var(--neutral-100, #000);
    text-align: center;
    font-feature-settings: "pwid" on;

    /* Heading/H6 */
    // font-family: Noto Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 144.444% */
  }
}

.iconPlay {
  border-radius: 100px;
  background: var(--neutral-10, #fff);
  display: flex;
  width: 50px;
  height: 50px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: 240px;
  // left: 115px;
  cursor: pointer;
}

.descJobs {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #000000 100%);
  display: flex;
  flex-direction: column;
  gap: 6px;
  position: absolute;
  bottom: 40px;
  padding: 24px 16px;
  left: 12px;
  // right: 12px;
  // left: 24.5px;
  //   background-color: #000;
  width: 90%;
  bottom: 10px;
  border-radius: 20px;
  .positionName {
    color: #fff;
    // text-align: right;
    // font-feature-settings: "pwid" on;

    /* Heading/H6 */
    // font-family: Noto Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    // line-height: 26px; /* 144.444% */
  }

  .address {
    display: flex;
    align-items: center;
    gap: 4px;

    > div {
      display: flex;
      align-items: center;
      gap: 4px;
      width: 100%;
      > span {
        color: #fff;
        // text-align: right;
        // font-feature-settings: "pwid" on;

        /* Heading/H6 */
        // font-family: Noto Sans;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
      }
    }
  }

  .tagsBox {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    color: #fff;
    width: 100%;
    // width: 125%;
    // width: 10%;
    .tags {
      // width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px;
      border-radius: 50px;
      border: 1px solid var(--neutral-40, #e0e0e0);
      color: #fff;
      // text-align: right;
      // font-feature-settings: "pwid" on;

      /* Heading/H6 */
      // font-family: Noto Sans;
      font-size: 8px;
      // width: 40%;
      font-style: normal;
      font-weight: 400;

      //   background: var(--neutral-10, #fff);
    }
  }
}

@media screen and (max-width: 600px) {
  .modalPreviw {
    width: 100%;
  }
}

@media screen and (max-width: 320px) {
  .iconPlay {
    top: 198px;
  }
  .previewContent {
    .imagePreview {
      height: 38 0px;
      width: 190px;
    }
    .video {
      height: 380px;
      width: 190px;
    }
    .templateBox {
      img {
        width: 200px;
        height: 390px;
      }
    }
  }
}
