@import "../../../theme/variables.scss";

.wrapper {
  display: flex;
  align-items: center;
  gap: 16px;

  .error {
    border-color: #F5610D !important;
  }

  .innerWrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    >* {
      width: 100%;
    }

    gap: 4px;
  }

  .container {
    height: 100%;
    width: 100%;
    position: relative;

    &.withCurrency {
      display: flex;
      align-items: center;
      // background-color: red;
    }
  }

  .currencyAdornment {
    // position: absolute;
    height: 46px;
    padding: 12px;
    background-color: $NEUTRAL_20;
    border-radius: 8px 0 0 9px;
    border: 1px solid $NEUTRAL_40;
    border-right: none;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: $NEUTRAL_60;

    &.error {
      border-color: #F5610D;
    }
  }

  .input {
    width: 100%;
    padding: 12px;
    border-radius: 8px;
    border: 1px solid $NEUTRAL_40;
    transition: 0.2s;
    font-family: Poppins;
    font-size: 13px;

    &.currency {
      border-radius: 0 8px 8px 0;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type=number] {
      appearance: textfield;
      -moz-appearance: textfield;
    }



  }

  .errorText {
    color: var(--Helper, #F5610D);
    /* Caption 1/Reguler */
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 133.333% */
  }

  .floatingStartAdornment {
    position: absolute;

  }

  .floatingEndAdornment {
    position: absolute;
    top: 12px;
    right: 12px;
  }

  .input:focus {
    outline: none !important;
    border-width: 1px;
    border-style: solid;

    &:not(.error) {
      border-color: $PRIMARY_MAIN;
    }
  }

  .input:disabled {
    outline: none !important;
    border-color: $NEUTRAL_40;
    border-width: 1px;
    border-style: solid;
    background-color: $NEUTRAL_30;
    color: $NEUTRAL_60;
  }

  .input::placeholder {
    color: $NEUTRAL_60;
    font-size: 13px;
  }

  button {
    cursor: pointer;
    background-color: transparent;
    border: none;
    color: $DANGER_MAIN;
  }
}