@import "../../../../theme/variables.scss";


.container {
  padding: 16px;
  z-index: 0;

  .tablesWrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(600px, 1fr));
    gap: 16px;
    z-index: -1;

    .showDetailButton {
      display: grid;
      place-items: center;
      background-color: transparent;
      border: none;
      text-decoration: underline;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      color: $BS_PRIMARY_MAIN;

    }

  }
}

@media screen and (max-width: 768px) {
  .container {

    .tablesWrapper {
      // flex-direction: column;
      grid-template-columns: 1fr;

      >* {
        width: 100%;
      }
    }
  }
}