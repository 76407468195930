@import "../../../../../../theme/variables.scss";

.container {
  display: grid;
  grid-template-rows: 1fr;
  gap: 24px;

  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    gap: 16px;
  }

  .notes {
    display: flex;
    align-items: center;
    gap: 8px;
    padding-top: 8px;

    > * {
      &:first-child {
        flex-shrink: 0;
      }
    }

    > p {
      font-size: 14px;

      .italic {
        font-style: italic;
      }

      .bold {
        font-weight: 700;
      }
    }
  }

  .more {
    background-color: $NEUTRAL-20;
    padding: 16px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    > p {
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 142.857%;

      > span {
        font-weight: 700;
      }
    }

    > button {
      display: flex;
      align-items: center;
      gap: 8px;
      background-color: transparent;
      border: none;
      color: $PRIMARY_MAIN;
    }
  }

  .addLocationButton {
    background-color: $PRIMARY_MAIN;
    color: $NEUTRAL_10;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: 100%;
    border-radius: 8px;
    padding: 10px;
    border: none;

    > span {
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
    }
  }

  > * {
    @include padding(24, 18, 18px);
    border-radius: 8px;

    &.bg {
      background-color: $NEUTRAL-10;
    }

    &.main {
      display: grid;
      grid-template-columns: 237px auto;

      .steps {
        .step {
          display: flex;
          align-items: center;
          position: relative;
          gap: 12px;
          cursor: pointer;

          .border {
            position: absolute;
            width: 1px;
            height: 100%;
            background-color: transparent;
            border-left: 1px dashed $NEUTRAL-60;
            top: 50%;
            left: calc(37px / 2);
          }

          &:first-child {
            padding-bottom: 24px;
          }

          > div {
            display: grid;
            place-items: center;
            width: 37px;
            z-index: 1;

            > span {
              width: 100%;
              aspect-ratio: 1;
              display: grid;
              place-items: center;
              border-radius: 50%;
              background-color: $NEUTRAL-20;
              font-family: Poppins;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 142.857%;
              color: $NEUTRAL-60;
              transition: all 0.3s ease;

              &.active {
                background-color: $PRIMARY_SOFT;
                color: #2e3192;
              }
            }
          }

          > span {
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 142.857%;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 142.857%;
            color: $NEUTRAL-60;
            transition: all 0.3s ease;

            &.active {
              color: $PRIMARY_MAIN;
            }
          }
        }
      }

      .content {
        display: grid;
        grid-template-rows: 1fr auto;
        gap: 24px;

        .delegationsAllCities {
          display: flex;
          flex-direction: column;
          gap: 16px;

          .delegationPerCity {
            padding: 16px;
            border-radius: 8px;
            background-color: $NEUTRAL-20;
            display: grid;
            grid-template-rows: auto 1fr;
            gap: 20px;

            .dPCHeader {
              display: flex;
              align-items: center;
              gap: 12px;

              > span {
                font-family: Poppins;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 150%;
                letter-spacing: 0.1px;
                color: $PRIMARY_MAIN;
                flex-grow: 1;
              }

              > button {
                display: flex;
                align-items: center;
                gap: 4px;
                background-color: $NEUTRAL-10;
                color: $DANGER_MAIN;
                border: 1px solid $DANGER_MAIN;
                padding: 12px;
                border-radius: 8px;
              }
            }

            .roles {
              .role {
                background-color: $NEUTRAL-10;
                padding: 16px;
                border-radius: 8px;
                display: flex;
                flex-direction: column;

                &.error {
                  border: 1px solid #f5610d !important;
                }

                > p {
                  font-family: Poppins;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                  padding-bottom: 16px;

                  .redStar {
                    color: $DANGER_MAIN;
                    font-weight: 400;
                    font-size: 14px;
                  }
                }

                .rSearchWrapper {
                  position: relative;
                  padding-bottom: 8px;
                  display: grid;
                  grid-template-columns: 1fr;
                  grid-template-rows: 1fr;
                  height: 44px;

                  > input {
                    height: 44px;
                    padding: 8px 12px;
                    border: 1px solid $NEUTRAL-40;
                    border-radius: 8px;

                    &:focus {
                      border-color: $PRIMARY_MAIN;
                      outline: none;
                    }

                    &.error {
                      border: 1px solid #f5610d !important;
                    }
                  }

                  .rSearchIcon {
                    position: absolute;
                    top: 8px;
                    right: 12px;
                    color: $NEUTRAL-60;
                  }

                  .rSearchOptions {
                    position: absolute;
                    background-color: $NEUTRAL-10;
                    width: 100%;
                    z-index: 1;
                    top: calc(100% + 4px);
                    padding-inline: 12px;
                    box-shadow: 0px 0px 1px 0px rgba(9, 30, 66, 0.31),
                      0px 3px 5px 0px rgba(9, 30, 66, 0.2);
                    border-radius: 0px 0px 8px 8px;
                    max-height: 350px;
                    overflow: auto;

                    .user {
                      padding-block: 10px;
                      border-bottom: 1px solid $NEUTRAL-40;
                      display: flex;
                      align-items: center;
                      gap: 16px;

                      > span {
                        flex-grow: 1;
                        cursor: pointer;
                        font-family: Poppins;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 171.429%;
                      }
                    }
                  }
                }

                .delegatedWrapper {
                  padding-top: 8px;
                  display: flex;
                  flex-direction: column;
                  gap: 8px;

                  .delegated {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    padding: 6px 10px;
                    border: 1px solid $NEUTRAL-40;
                    border-radius: 8px;

                    > span {
                      flex-grow: 1;
                      font-family: Poppins;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 142.857%;
                    }

                    .dAction {
                      display: flex;
                      align-items: center;
                      gap: 16px;

                      .picSetter {
                        display: flex;
                        align-items: center;
                        gap: 4px;

                        > span {
                          font-family: Poppins;
                          font-size: 11px;
                          font-style: normal;
                          font-weight: 400;
                          line-height: 145.455%;
                        }
                      }

                      > button {
                        display: grid;
                        place-items: center;
                        background-color: transparent;
                        border: none;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .positionsAllCities {
          display: grid;
          grid-template-columns: 1fr;
          grid-template-columns: 1fr;
          gap: 16px;

          .perPosition {
            background-color: $NEUTRAL-20;
            padding: 16px;
            border-radius: 16px;

            .pHeader {
              display: flex;
              align-items: center;
              gap: 16px;

              .pHLogo {
                background-color: $PRIMARY_SOFT;
                width: 42px;
                aspect-ratio: 1;
                border-radius: 50%;
                display: grid;
                place-items: center;
              }

              .pHName {
                flex-grow: 1;
                display: flex;
                align-items: center;
                gap: 8px;

                > span {
                  font-family: Poppins;
                  font-size: 20px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 150%;
                  letter-spacing: 0.1px;
                  color: $PRIMARY_MAIN;
                }

                > button {
                  background-color: transparent;
                  border: none;
                  display: grid;
                  place-items: center;
                }
              }

              > button {
                background-color: transparent;
                border: none;
              }

              .expandButton {
                background-color: $NEUTRAL-40;
                width: 36px;
                aspect-ratio: 1;
                border-radius: 50%;
                display: grid;
                place-items: center;
              }
            }

            .pDetails {
              padding-top: 20px;
              display: grid;
              grid-template-columns: 1fr;
              gap: 20px;

              .pDEach {
                background-color: $NEUTRAL-10;
                padding: 16px;
                display: grid;
                grid-template-columns: 1fr;
                grid-template-rows: auto 1fr;
                gap: 12px;
                border-radius: 16px;

                .pDEHeader {
                  display: flex;
                  align-items: center;
                  gap: 8px;
                  justify-content: space-between;

                  > span {
                    font-family: Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 142.857%;
                    color: $PRIMARY_MAIN;
                  }
                }

                .addQualificationButton {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 4px;
                  background-color: $NEUTRAL-10;
                  border: 1px solid $PRIMARY_MAIN;
                  border-radius: 8px;
                  padding: 8px;

                  &.error {
                    border: 1px solid #f5610d !important;
                  }

                  > span {
                    font-family: Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 142.857%;
                    color: $PRIMARY_MAIN;
                  }
                }

                .qualifications {
                  display: grid;
                  grid-template-columns: 1fr;
                  gap: 16px;
                }

                .cities {
                  display: grid;
                  grid-template-columns: 1fr;
                  grid-template-rows: 1fr;
                  gap: 16px;

                  .perPositionPerCity {
                    padding: 16px;
                    border-radius: 16px;
                    border: 1px solid $NEUTRAL-40;
                    display: grid;
                    grid-template-columns: 1fr;
                    grid-template-rows: 1fr;
                    gap: 16px;

                    &.isApplied {
                      background-color: #fbfcff;
                    }

                    .pCHeader {
                      display: flex;
                      align-items: center;
                      gap: 8px;

                      > span {
                        font-family: Poppins;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 140%;
                        flex-grow: 1;
                      }
                    }

                    .pCDetails {
                      display: grid;
                      grid-template-columns: 1fr;
                      grid-template-rows: 1fr;
                      gap: 16px;

                      .pCDHeader {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        justify-content: space-between;

                        > span {
                          font-family: Poppins;
                          font-size: 13px;
                          font-style: normal;
                          font-weight: 500;
                          line-height: 153.846%;
                        }
                      }

                      .pCDMain {
                        display: grid;
                        grid-template-columns: 1fr;
                        grid-template-rows: 1fr;
                        gap: 12px;

                        .salarySection {
                          display: grid;
                          grid-template-columns: repeat(
                            auto-fit,
                            minmax(400px, 1fr)
                          );
                          gap: 16px;

                          .salary {
                            display: grid;
                            grid-template-columns: 1fr;
                            // gap: 8px;

                            .salaryFields {
                              display: grid;
                              grid-auto-flow: column;
                              gap: 4px;
                            }
                          }

                          .equipments,
                          .allowances {
                            display: flex;
                            flex-direction: column;
                            gap: 8px;

                            .multifieldsWrapper {
                              display: flex;
                              flex-direction: column;
                              gap: 8px;
                              .multifields {
                                display: flex;
                                align-items: flex-end;
                                gap: 8px;

                                .inputForm {
                                  width: 100%;
                                  display: flex;
                                  align-items: center;
                                  gap: 4px;
                                  .dropdown {
                                    width: 100%;
                                  }
                                  .iconDelete {
                                    width: 20px;
                                    cursor: pointer;
                                  }
                                }
                              }
                            }
                          }
                        }

                        .pCDMEach {
                          display: grid;
                          grid-template-columns: 1fr;
                          grid-template-rows: 1fr;
                          gap: 12px;

                          .pCDMEHeader {
                            display: flex;
                            align-items: center;
                            gap: 8px;
                            justify-content: space-between;

                            > span {
                              font-family: Poppins;
                              font-size: 14px;
                              font-style: normal;
                              font-weight: 700;
                              line-height: 142.857%;
                              color: $PRIMARY_MAIN;
                            }
                          }

                          .valueBPU {
                            background-color: $PRIMARY_SOFT;
                            padding: 4px 8px;
                            border-radius: 32px;
                            font-family: Poppins;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 133.333%;
                            color: $PRIMARY_MAIN;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .noPosition {
          background-color: $NEUTRAL-20;
          padding: 16px;
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          border-radius: 8px;

          &.error {
            border: 1px solid #f5610d !important;
          }

          > img {
            width: 195px;
            max-width: 90vw;
            object-fit: contain;
          }

          .desc {
            padding-top: 16px;
            padding-bottom: 24px;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 8px;

            > h3 {
              width: 317px;
              max-width: 90vw;
              font-family: Poppins;
              font-size: 18px;
              font-style: normal;
              font-weight: 600;
              line-height: 150%;
              letter-spacing: 0.09px;
              color: $PRIMARY_MAIN;
            }

            > p {
              font-family: Poppins;
              font-size: 13px;
              font-style: normal;
              font-weight: 400;
              line-height: 153.846%;
              color: $NEUTRAL-70;
            }
          }

          > button {
            display: flex;
            align-items: center;
            gap: 4px;
            background-color: $NEUTRAL-10;
            border: 1px solid $PRIMARY_MAIN;
            padding: 12px;
            border-radius: 8px;
            color: $PRIMARY_MAIN;

            > span {
              font-family: Poppins;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 142.857%;
            }
          }
        }

        .cActions {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          align-items: center;
          gap: 32px;

          > button {
            display: flex;
            align-items: center;
            gap: 8px;
            background-color: transparent;
            border: none;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 142.857%;

            &:last-child {
              color: $PRIMARY_MAIN;
            }

            &:disabled {
              color: $NEUTRAL-50;
            }
          }
        }
      }
    }

    &.remark {
      display: grid;
      grid-template-columns: 1fr;
      gap: 16px;
      background-color: $NEUTRAL-10;

      > span {
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
      }
    }
  }

  .buttonWrapper {
    display: flex;
    gap: 12px;
    justify-content: flex-end;
    padding-top: 16px;

    button {
      padding: 8px 16px;
      border-radius: 8px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      cursor: pointer;
      border: none;

      &:first-child {
        background: $NEUTRAL_10;
        border: 1px solid $NEUTRAL_100;
        color: $NEUTRAL_100;
      }

      &:last-child {
        background: $PRIMARY_MAIN;
        color: $NEUTRAL_10;

        &:disabled {
          background-color: $PRIMARY_DISABLED;
        }
      }
    }
  }
}

.noLocationContainer {
  background-color: $NEUTRAL-20;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  border-radius: 8px;
  text-align: center;

  .bubbleWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &.error {
    border: 1px solid #f5610d !important;
  }

  > img {
    height: 180px;
    object-fit: contain;
  }

  > span {
    padding-top: 16px;
    padding-bottom: 8px;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: 0.09px;
    color: $PRIMARY_MAIN;
  }

  > p {
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 153.846%;
    color: $NEUTRAL-70;
    max-width: 439px;
    padding-bottom: 24px;
  }

  > button {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 12px;
    border-radius: 8px;
    border: 1px solid $PRIMARY_MAIN;
    background-color: $NEUTRAL-10;
    color: $PRIMARY_MAIN;

    > span {
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 142.857%;
    }
  }
}

@media screen and (max-width: 1040px) {
  .container {
    .main {
      grid-template-columns: 150px auto !important;
      gap: 16px;
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    // max-width: calc(100vw - 48px);

    .main {
      grid-template-columns: 1fr !important;
      gap: 16px;
    }
  }
}
