@import "../../../../../../../../../theme/variables.scss";

.container {
  background: #f3f6ff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  width: 100%;
  border-radius: 8px;

  .rightSection {
    width: 45%;
    .card {
      background: $NEUTRAL_10;
      display: flex;
      align-items: center;
      padding: 16px;
      gap: 16px;
      width: 100%;
      border-radius: 8px;

      img {
        width: 60px;
        height: 60px;
      }
      .cardTitle {
        font-weight: 400;
        font-size: 14px;
        color: $NEUTRAL_90;
      }
      .cardBody {
        font-weight: 600;
        font-size: 20px;
        color: $NEUTRAL_100;
      }
      .cardDesc {
        font-weight: 400;
        font-size: 13px;
        color: $NEUTRAL_70;
        padding-top: 4px;
      }
    }
  }
  .leftSection {
    width: 40%;
    .headTitle {
      font-weight: 600;
      font-size: 16px;
      color: $NEUTRAL_100;
      margin-bottom: 15px;
    }
    .summaryWrapper {
      display: flex;
      flex-direction: column;
      gap: 15px;
      color: $NEUTRAL_100;
      .variable {
        display: flex;
        align-items: center;
        justify-content: space-between;
        :first-child {
          font-weight: 400;
          font-size: 13px;
        }
        :last-child {
          font-weight: 500;
          font-size: 13px;
        }
      }
    }
    .total {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 10px;
      padding-top: 10px;
      border-top: 1px solid #000000;
      :first-child {
        font-weight: 600;
        font-size: 16px;
      }
      :last-child {
        font-weight: 500;
        font-size: 16px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    // display: flex !important;
    flex-direction: column-reverse;
    gap: 20px;
    .rightSection {
      width: 100% !important;
    }
    .leftSection {
      width: 100% !important;
    }
  }
}
