@import "../../../../../theme/variables.scss";

.container {
  height: 100%;
  flex-grow: 1;
  height: calc(100vh - 85px - 32px);
  display: flex;
  flex-direction: column;
  // background-color: purple;

  .actionsWrapper {
    display: flex;
    align-items: center;
    gap: 16px;
    z-index: 1;
    // position: relative;

    .options {
      position: absolute;
      top: 30px;
      right: 0px;
      z-index: 1;
    }

    .button {
      display: grid;
      place-items: center;
      background-color: transparent;
      border: none;
      color: $PRIMARY_MAIN;

      &.expand {
        position: relative;
      }

      > img {
        height: 24px;
        aspect-ratio: 1;
        z-index: 0;
      }
    }
  }

  .divider {
    background-color: $NEUTRAL_30;
    width: 1px;
    height: 20px;
  }

  .stepperWrapper {
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    justify-content: center;
    border-bottom: 1px solid $NEUTRAL_30;
  }

  .controllerWrapper {
    display: flex;
    flex-direction: column;
    // gap: 8px;

    .topSearch {
      padding: 16px 16px 10px 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
      .searchbar {
        width: 360px;
        height: 45px;
      }

      .total {
        display: flex;
        align-items: center;
        gap: 8px;

        h4 {
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
          color: $NEUTRAL_100;
        }

        > *:last-child {
          background-color: $PRIMARY_SURFACE;
          padding: 8px;
          border-radius: 8px;
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 140%;
          color: $PRIMARY_MAIN;
        }
      }
    }

    .lastHeader {
      // padding: 0px 30px 14px 30px;
      width: 100%;

      .stepperBox {
        display: flex;
        justify-content: space-between;
        overflow: hidden;
        // width: 100%;
        max-width: 100%;
        // min-height: 100%;
        margin-top: 14px;
        box-sizing: border-box;
        padding-right: 1%;

        // gap: 1%; /* Atur jarak antar elemen */

        .step {
          position: relative;
          cursor: pointer;
          width: 100%;
          //   margin-top: 14px;
          margin-right: -1%; /* Add a small margin */
          flex: 1;

          img {
            width: 100%;
            height: 84px;

            position: relative;
            padding: 0;
          }

          > div {
            position: absolute;
            top: 20px;
            left: 12%;
            display: flex;
            align-items: center;
            gap: 8px;
            &.leftPlace {
              left: 10%;
            }

            > div {
              background-color: #1571de;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50px;
              // padding: 4px 8px;

              width: 40px;
              height: 40px;
              color: var(--Neutral-10, var(--Color-Neutal-Neutral-10, #fff));
              text-align: center;
              // font-family: Roboto;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }

            > span {
              color: var(
                --Neutral-100,
                var(--Color-Neutal-Neutral-19, #0a0a0a)
              );

              /* Text S/Medium */
              // font-family: Poppins;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 20px; /* 153.846% */
            }
          }
        }
      }

      .stepperBox::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
      }

      //   .stepperBox {
      //     //   padding: 0px 30px 16px 30px;
      //     display: flex;
      //     align-items: center;
      //     justify-content: space-between;
      //     gap: 8px;
      //     height: 100px;

      //     overflow: scroll;
      //     max-width: 120%;

      //     // .step {
      //     //   position: relative;
      //     //   flex: 1;
      //     //   padding: 4px;
      //     //   text-align: center;
      //     //   background-color: #fff;
      //     //   // border: 1px solid #ccc;
      //     //   //   transition: background-color 0.3s, border-color 0.3s;
      //     //   min-width: 250px;
      //     //   height: 56px;
      //     //   display: flex;
      //     //   align-items: center;
      //     //   justify-content: center;
      //     //   gap: 8px;
      //     //   border-radius: 4px;
      //     //   cursor: pointer;

      //     //   > div {
      //     //     background-color: #1571de;
      //     //     display: flex;
      //     //     align-items: center;
      //     //     justify-content: center;
      //     //     border-radius: 50px;
      //     //     padding: 4px 8px;
      //     //     color: var(--Neutral-10, var(--Color-Neutal-Neutral-10, #fff));
      //     //     text-align: center;
      //     //     // font-family: Roboto;
      //     //     font-size: calc(
      //     //       12px + (14 - 12) * ((100vw - 280px) / (1600 - 280))
      //     //     );
      //     //     font-style: normal;
      //     //     font-weight: 500;
      //     //     line-height: normal;
      //     //   }

      //     //   > span {
      //     //     color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));

      //     //     /* Text S/Medium */
      //     //     // font-family: Poppins;
      //     //     font-size: calc(
      //     //       10px + (13 - 10) * ((100vw - 280px) / (1600 - 280))
      //     //     );
      //     //     font-style: normal;
      //     //     font-weight: 500;
      //     //     line-height: 20px; /* 153.846% */
      //     //   }
      //     // }
      //     // .step:after {
      //     //   content: "";
      //     //   position: absolute;
      //     //   top: 0;
      //     //   left: 0px;
      //     //   width: 0;
      //     //   height: 0;
      //     //   border-top: 28px solid transparent;
      //     //   border-bottom: 28px solid transparent;
      //     //   border-left: 20px solid #f5f5f5;

      //     //   z-index: 1;
      //     // }

      //     // .step:before {
      //     //   content: "";
      //     //   position: absolute;
      //     //   top: 0;
      //     //   right: -20px;
      //     //   width: 0;
      //     //   height: 0;
      //     //   border-top: 28px solid transparent;
      //     //   border-bottom: 28px solid transparent;
      //     //   border-left: 20px solid #fff;
      //     //   z-index: 2;
      //     // }
      //     // .step:first-child::after {
      //     //   display: none;
      //     // }

      //     // .step:last-child::before {
      //     //   display: none;
      //     // }

      //     // .active {
      //     //   position: relative;
      //     //   flex: 1;
      //     //   padding: 4px;
      //     //   text-align: center;
      //     //   background-color: #ebedfb;
      //     //   // border: 1px solid #ccc;
      //     //   //   transition: background-color 0.3s, border-color 0.3s;
      //     //   min-width: 250px;
      //     //   height: 56px;
      //     //   display: flex;
      //     //   align-items: center;
      //     //   justify-content: center;
      //     //   gap: 8px;
      //     //   border-radius: 2px;

      //     //   cursor: pointer;
      //     //   > div {
      //     //     background-color: #1571de;
      //     //     display: flex;
      //     //     align-items: center;
      //     //     justify-content: center;
      //     //     border-radius: 50px;
      //     //     padding: 4px 8px;
      //     //     color: var(--Neutral-10, var(--Color-Neutal-Neutral-10, #fff));
      //     //     text-align: center;
      //     //     // font-family: Roboto;
      //     //     font-size: calc(
      //     //       12px + (14 - 12) * ((100vw - 280px) / (1600 - 280))
      //     //     );
      //     //     font-style: normal;
      //     //     font-weight: 500;
      //     //     line-height: normal;
      //     //   }

      //     //   > span {
      //     //     color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));

      //     //     /* Text S/Medium */
      //     //     // font-family: Poppins;
      //     //     font-size: calc(
      //     //       10px + (13 - 10) * ((100vw - 280px) / (1600 - 280))
      //     //     );
      //     //     font-style: normal;
      //     //     font-weight: 500;
      //     //     line-height: 20px; /* 153.846% */
      //     //   }
      //     // }
      //     // .active:after {
      //     //   content: "";
      //     //   position: absolute;
      //     //   top: 0;
      //     //   left: 0px;
      //     //   width: 0;
      //     //   height: 0;
      //     //   border-top: 28px solid transparent;
      //     //   border-bottom: 28px solid transparent;
      //     //   border-left: 20px solid #f5f5f5;

      //     //   z-index: 1;
      //     // }

      //     // .active:before {
      //     //   content: "";
      //     //   position: absolute;
      //     //   top: 0;
      //     //   right: -20px;
      //     //   width: 0;
      //     //   height: 0;
      //     //   border-top: 28px solid transparent;
      //     //   border-bottom: 28px solid transparent;
      //     //   border-left: 20px solid #ebedfb;
      //     //   z-index: 2;
      //     // }
      //     // .active:first-child::after {
      //     //   display: none;
      //     // }

      //     // .active:last-child::before {
      //     //   display: none;
      //     // }
      //   }
    }

    .controllers {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 16px;
      padding: 10px 16px 16px 16px;

      .activeFilterWrapper {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        @include gap(10, 8, 8px);
        // gap: 16px;

        .result {
          text-align: center;
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          color: $NEUTRAL_70;
          white-space: nowrap;
        }

        .active {
          color: var(--Neutral-70, #757575);
          text-align: center;
          // font-family: Poppins;
          font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 142.857% */
        }

        .status {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 16px;

          border-radius: 40px;
          border: 1px solid var(--Color-Neutal-Neutral-30, #ededed);
          background: var(--Color-Neutal-Neutral-10, #fff);
          padding: 8px 12px;

          > span {
            text-align: center;
            // font-family: Poppins;
            font-size: calc(
              12px + (14 - 12) * ((100vw - 280px) / (1600 - 280))
            );
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 142.857% */
            color: var(--Neutral-90, #404040);
            display: flex;
            align-items: center;
            gap: 8px;
          }

          .icon {
            cursor: pointer;
          }
        }
      }

      .right {
        display: flex;
        align-items: center;
        gap: 16px;
        position: relative;

        .filterButton {
          display: flex;
          align-items: center;
          gap: 4px;
          background-color: transparent;
          border: none;

          > span {
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            color: $NEUTRAL_90;
          }
        }

        .downloadExcelWrapper {
          display: flex;
          align-items: center;
          position: relative;

          .downloadFilter {
            padding: 8px 12px;
            display: grid;
            place-items: center;
            height: 40px;
            background-color: $NEUTRAL_30;
            border-radius: 8px 0 0 8px;
            color: $NEUTRAL_60;
            border: 1px solid $NEUTRAL_30;

            &.active {
              border-color: $PRIMARY_MAIN;
            }
          }

          .downloadExpander {
            height: 40px;
            display: flex;
            align-items: center;
            gap: 8px;
            padding: 8px 12px;
            background-color: $NEUTRAL_10;
            border: 1px solid $NEUTRAL_30;
            border-radius: 0 8px 8px 0;
            color: $NEUTRAL_50;
            white-space: nowrap;

            &.active {
              border-color: $PRIMARY_MAIN;
            }

            > *:first-child {
              color: $NEUTRAL_80;
            }
          }
        }

        .downloadPopupWrapper,
        .filterPopupWrapper {
          position: absolute;
          top: 45px;
          right: 0;
          z-index: 3;
          width: auto;
        }
      }
    }
  }

  .warningBox {
    display: flex;
    align-items: center;
    gap: 12px;
    border: 1px solid var(--Alert-Main, #e9b62f);
    background: #fffaef;
    padding: 9px 14px;
    > div {
      width: 20px;
      position: relative;
      top: 2px;
    }
    > span {
      color: var(--Neutral-90, #404040);

      /* Text S/Regular */
      // font-family: Poppins;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 153.846% */
    }
  }

  .tableWrapper {
    height: 100%;
    height: 50vh;
    // min-height: 350px;
    flex-grow: 1;
    // background-color: red;
  }
}

.clientNameRow {
  width: 150px;
}

// .stackedProfileRow {
//   width: 285px;
// }

// .roProfileRow {
//   width: 193px;
// }

// @media screen and (max-width: 1280px) {
//   // .container {
//   //   .controllerWrapper {
//   //     .controllers {
//   //       align-items: flex-start;

//   //       .left {
//   //         flex-direction: column;
//   //         justify-content: flex-start;
//   //         align-items: flex-start;
//   //       }
//   //     }

//   //     .activeFilterWrapper {
//   //       flex-direction: column;
//   //       align-items: flex-start;

//   //       .activeFilters {
//   //         flex-direction: column;
//   //         width: 100%;
//   //         align-items: flex-start;

//   //         > div {
//   //           // width: 100%;
//   //           align-items: flex-start;
//   //         }
//   //       }
//   //     }
//   //   }
//   // }
// }

@media screen and (max-width: 1554px) {
  .container {
    .controllerWrapper {
      .lastHeader {
        .stepperBox {
          overflow: scroll;
          max-width: 100%;

          .step {
            min-width: 270px;
            margin-right: -13px;
            img {
              height: 60px;
            }

            > div {
              // position: absolute;
              top: 15px;
              left: 12%;
              &.leftPlace {
                left: 10%;
              }

              > div {
                width: 28px;
                height: 28px;

                font-size: calc(
                  12px + (14 - 12) * ((100vw - 280px) / (1600 - 280))
                );
              }

              > span {
                color: var(
                  --Neutral-100,
                  var(--Color-Neutal-Neutral-19, #0a0a0a)
                );

                /* Text S/Medium */
                // font-family: Poppins;
                font-size: calc(
                  10px + (14 - 10) * ((100vw - 280px) / (1600 - 280))
                );
                font-style: normal;
                font-weight: 500;
                line-height: 20px; /* 153.846% */
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .container {
    .controllerWrapper {
      gap: 16px;
      .controllers {
        flex-direction: column;
        align-items: flex-start;

        .left {
          > * {
            width: 100%;

            // &.buttons {
            //   overflow-x: scroll;
            // }
          }
        }

        .right {
          .downloadPopupWrapper {
            // left: 0;
            transform: translateX(110px);
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    // height: auto;
    height: calc(100vh - 83px);

    .downloadPopupWrapper,
    .filterPopupWrapper {
      left: -60px;
      right: auto !important;
    }

    .stickyTop {
      position: sticky;
      top: 82px;
      z-index: 2;
      background-color: $NEUTRAL_10;
    }

    .controllerWrapper {
      .topSearch {
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        .searchbar {
          width: 100%;
        }
      }
      .controllers {
        .left {
          .searchbar {
            width: 100%;
          }
        }

        .right {
          width: 100%;

          .downloadExpander {
            flex-grow: 1;
            width: 100%;

            > span {
              flex-grow: 1;
              text-align: start;
            }
          }

          .downloadPopupWrapper {
            transform: translateX(0);
          }
        }
      }
    }

    .tableWrapper {
      // height: auto;
      position: sticky;
    }
  }
}
