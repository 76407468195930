.container {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 70px);

  .header {
    position: sticky;
    top: 70px;
    // z-index: 2;
    display: flex;
    flex-direction: column;
    // gap: 16px;
    background: var(--Color-Neutal-Neutral-20, #f5f5f5);
    z-index: 4;
    .topHeader {
      display: flex;
      flex-direction: column;
      gap: 8px;
      border-bottom: 1px solid var(--Color-Neutal-Neutral-40, #e0e0e0);

      padding: 16px 30px;

      .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 16px;
        .buttonBox {
          display: flex;
          align-items: center;
          gap: 8px;
          .buttonRefresh {
            display: flex;
            align-items: center;
            padding: 12px;
            justify-content: center;
            border-radius: 8px;
            border: 1px solid var(--Primary-Main, #1571de);
            gap: 4px;
            cursor: pointer;
            > span {
              color: var(--Primary-Main, #1571de);
              text-align: center;

              /* Text M/Reguler */
              //   font-family: Poppins;
              font-size: calc(
                12px + (14 - 12) * ((100vw - 280px) / (1600 - 280))
              );
              font-style: normal;
              font-weight: 400;
              line-height: 20px; /* 142.857% */
            }
          }

          .buttonType {
            display: flex;
            align-items: center;

            gap: 8px;
            > div {
              &.active {
                border-radius: 100px;
                border: 1px solid var(--Primary-Main, #1571de);
                background: var(--Primary-Surface, #f5f7fe);
                color: var(--Primary-Main, #1571de);
              }
              border-radius: 100px;
              border: 1px solid var(--Neutral-40, #e0e0e0);
              background: var(--Neutral-10, #fff);
              padding: 12px 16px;
              display: flex;
              align-items: center;
              justify-content: center;
              color: var(
                --Neutral-100,
                var(--Color-Neutal-Neutral-19, #0a0a0a)
              );
              text-align: center;

              /* Text M/Reguler */
              // font-family: Poppins;
              font-size: calc(
                12px + (14 - 12) * ((100vw - 280px) / (1600 - 280))
              );
              font-style: normal;
              font-weight: 400;
              line-height: 20px; /* 142.857% */
              cursor: pointer;
            }
          }
        }

        .filterBox {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 8px;
          width: 50%;
          .buttonFilter {
            display: flex;
            align-items: center;
            gap: 4px;
            justify-content: center;
            padding: 12px;
            border-radius: 8px;
            background: var(--Primary-Main, #1571de);
            height: 45px;
            cursor: pointer;
            > span {
              color: var(--Color-Neutal-Neutral-10, #fff);
              text-align: center;

              /* Text M/Reguler */
              //   font-family: Poppins;
              font-size: calc(
                12px + (14 - 12) * ((100vw - 280px) / (1600 - 280))
              );
              font-style: normal;
              font-weight: 400;
              line-height: 20px; /* 142.857% */
            }
          }

          .searchWrapper {
            height: 45px;
            width: 400px;
          }
        }
      }

      .listFilterBox {
        display: flex;
        align-items: center;
        gap: 8px;
        justify-content: flex-end;
        > div {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 6px;
          border: 1px solid var(--Primary-Main, #1571de);
          background: var(--Primary-Surface, #f5f7fe);

          padding: 8px;
          > div {
            display: flex;
            align-items: center;
            gap: 8px;
            > span:first-child {
              color: var(--Neutral-70, #757575);

              /* Caption 1/Reguler */
              // font-family: Poppins;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 16px; /* 133.333% */
            }
            > span:nth-child(2) {
              color: var(--Primary-Main, #1571de);

              /* Caption 1/Reguler */
              // font-family: Poppins;
              font-size: 12px;
              font-style: normal;
              font-weight: 700;
              line-height: 16px;
            }
          }

          .iconDelete {
            cursor: pointer;
          }
        }
      }
    }

    .bottomHeader {
      padding: 16px 30px 0px 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      > div {
        display: flex;
        // align-items: center;
        justify-content: space-between;
        width: 100%;
        gap: 16px;
        padding: 4px 4px;
        border-radius: 8px;
        background: #fff;
        > span {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 8px 8px;
          background: #fff;
          color: var(--Primary-Main, #1571de);
          text-align: center;

          /* Text M/Medium */
          //   font-family: Poppins;
          font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 142.857% */
          cursor: pointer;
          &.active {
            border-radius: 5px;
            background: var(--Primary-Main, #1571de);
            color: var(--Neutral-10, var(--Color-Neutal-Neutral-10, #fff));
          }
        }
      }
    }
    .lastHeader {
      padding: 0px 30px 14px 30px;
      width: 100%;

      .stepperBox {
        display: flex;
        justify-content: space-between;
        overflow: hidden;
        // width: 100%;
        max-width: 100%;
        // min-height: 100%;
        margin-top: 14px;
        box-sizing: border-box;
        padding-right: 1%;

        // gap: 1%; /* Atur jarak antar elemen */

        .step {
          position: relative;
          cursor: pointer;
          width: 100%;
          //   margin-top: 14px;
          margin-right: -1%; /* Add a small margin */
          flex: 1;

          img {
            width: 100%;
            height: 84px;

            position: relative;
            padding: 0;
          }

          > div {
            position: absolute;
            top: 20px;
            left: 12%;
            display: flex;
            align-items: center;
            gap: 8px;
            &.leftPlace {
              left: 10%;
            }

            > div {
              background-color: #1571de;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50px;
              // padding: 4px 8px;

              width: 40px;
              height: 40px;
              color: var(--Neutral-10, var(--Color-Neutal-Neutral-10, #fff));
              text-align: center;
              // font-family: Roboto;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }

            > span {
              color: var(
                --Neutral-100,
                var(--Color-Neutal-Neutral-19, #0a0a0a)
              );

              /* Text S/Medium */
              // font-family: Poppins;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 20px; /* 153.846% */
            }
          }
        }
      }

      .stepperBox::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
      }

      //   .stepperBox {
      //     //   padding: 0px 30px 16px 30px;
      //     display: flex;
      //     align-items: center;
      //     justify-content: space-between;
      //     gap: 8px;
      //     height: 100px;

      //     overflow: scroll;
      //     max-width: 120%;

      //     // .step {
      //     //   position: relative;
      //     //   flex: 1;
      //     //   padding: 4px;
      //     //   text-align: center;
      //     //   background-color: #fff;
      //     //   // border: 1px solid #ccc;
      //     //   //   transition: background-color 0.3s, border-color 0.3s;
      //     //   min-width: 250px;
      //     //   height: 56px;
      //     //   display: flex;
      //     //   align-items: center;
      //     //   justify-content: center;
      //     //   gap: 8px;
      //     //   border-radius: 4px;
      //     //   cursor: pointer;

      //     //   > div {
      //     //     background-color: #1571de;
      //     //     display: flex;
      //     //     align-items: center;
      //     //     justify-content: center;
      //     //     border-radius: 50px;
      //     //     padding: 4px 8px;
      //     //     color: var(--Neutral-10, var(--Color-Neutal-Neutral-10, #fff));
      //     //     text-align: center;
      //     //     // font-family: Roboto;
      //     //     font-size: calc(
      //     //       12px + (14 - 12) * ((100vw - 280px) / (1600 - 280))
      //     //     );
      //     //     font-style: normal;
      //     //     font-weight: 500;
      //     //     line-height: normal;
      //     //   }

      //     //   > span {
      //     //     color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));

      //     //     /* Text S/Medium */
      //     //     // font-family: Poppins;
      //     //     font-size: calc(
      //     //       10px + (13 - 10) * ((100vw - 280px) / (1600 - 280))
      //     //     );
      //     //     font-style: normal;
      //     //     font-weight: 500;
      //     //     line-height: 20px; /* 153.846% */
      //     //   }
      //     // }
      //     // .step:after {
      //     //   content: "";
      //     //   position: absolute;
      //     //   top: 0;
      //     //   left: 0px;
      //     //   width: 0;
      //     //   height: 0;
      //     //   border-top: 28px solid transparent;
      //     //   border-bottom: 28px solid transparent;
      //     //   border-left: 20px solid #f5f5f5;

      //     //   z-index: 1;
      //     // }

      //     // .step:before {
      //     //   content: "";
      //     //   position: absolute;
      //     //   top: 0;
      //     //   right: -20px;
      //     //   width: 0;
      //     //   height: 0;
      //     //   border-top: 28px solid transparent;
      //     //   border-bottom: 28px solid transparent;
      //     //   border-left: 20px solid #fff;
      //     //   z-index: 2;
      //     // }
      //     // .step:first-child::after {
      //     //   display: none;
      //     // }

      //     // .step:last-child::before {
      //     //   display: none;
      //     // }

      //     // .active {
      //     //   position: relative;
      //     //   flex: 1;
      //     //   padding: 4px;
      //     //   text-align: center;
      //     //   background-color: #ebedfb;
      //     //   // border: 1px solid #ccc;
      //     //   //   transition: background-color 0.3s, border-color 0.3s;
      //     //   min-width: 250px;
      //     //   height: 56px;
      //     //   display: flex;
      //     //   align-items: center;
      //     //   justify-content: center;
      //     //   gap: 8px;
      //     //   border-radius: 2px;

      //     //   cursor: pointer;
      //     //   > div {
      //     //     background-color: #1571de;
      //     //     display: flex;
      //     //     align-items: center;
      //     //     justify-content: center;
      //     //     border-radius: 50px;
      //     //     padding: 4px 8px;
      //     //     color: var(--Neutral-10, var(--Color-Neutal-Neutral-10, #fff));
      //     //     text-align: center;
      //     //     // font-family: Roboto;
      //     //     font-size: calc(
      //     //       12px + (14 - 12) * ((100vw - 280px) / (1600 - 280))
      //     //     );
      //     //     font-style: normal;
      //     //     font-weight: 500;
      //     //     line-height: normal;
      //     //   }

      //     //   > span {
      //     //     color: var(--Neutral-100, var(--Color-Neutal-Neutral-19, #0a0a0a));

      //     //     /* Text S/Medium */
      //     //     // font-family: Poppins;
      //     //     font-size: calc(
      //     //       10px + (13 - 10) * ((100vw - 280px) / (1600 - 280))
      //     //     );
      //     //     font-style: normal;
      //     //     font-weight: 500;
      //     //     line-height: 20px; /* 153.846% */
      //     //   }
      //     // }
      //     // .active:after {
      //     //   content: "";
      //     //   position: absolute;
      //     //   top: 0;
      //     //   left: 0px;
      //     //   width: 0;
      //     //   height: 0;
      //     //   border-top: 28px solid transparent;
      //     //   border-bottom: 28px solid transparent;
      //     //   border-left: 20px solid #f5f5f5;

      //     //   z-index: 1;
      //     // }

      //     // .active:before {
      //     //   content: "";
      //     //   position: absolute;
      //     //   top: 0;
      //     //   right: -20px;
      //     //   width: 0;
      //     //   height: 0;
      //     //   border-top: 28px solid transparent;
      //     //   border-bottom: 28px solid transparent;
      //     //   border-left: 20px solid #ebedfb;
      //     //   z-index: 2;
      //     // }
      //     // .active:first-child::after {
      //     //   display: none;
      //     // }

      //     // .active:last-child::before {
      //     //   display: none;
      //     // }
      //   }
    }
  }

  .tableContainer {
    height: 100%;
    padding: 0px 30px 16px 30px;

    .tableHeader {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      background: var(--Color-Neutal-Neutral-10, #fff);
      padding: 16px;
      border-radius: 8px 8px 0px 0px;
      border-top: 1px solid #e0e0e0;
      border-right: 1px solid #e0e0e0;
      border-left: 1px solid #e0e0e0;
      > div {
        display: flex;
        padding: 8px;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        background: var(--Primary-Main, #1571de);
        color: var(--Color-Neutal-Neutral-10, #fff);
        text-align: center;

        /* Text M/Reguler */
        // font-family: Poppins;
        font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        cursor: pointer;
        z-index: 1;
      }
    }

    .downloadReportBox {
      display: flex;
      align-items: center;
      gap: 16px;
      justify-content: space-between;
      padding-bottom: 16px;
      .left {
        display: flex;
        align-items: center;
        gap: 8px;
        > span {
          color: #000;

          /* Text M/Medium */
          // font-family: Poppins;
          font-size: calc(12px + (14 - 12) * ((100vw - 280px) / (1600 - 280)));
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 142.857% */
        }
        > div {
          min-width: 200px;
        }
      }

      .right {
        > div {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
          border-radius: 6px;
          background: var(--Primary-Main, #1571de);
          padding: 10px;
          > span {
            color: var(--Color-Neutal-Neutral-10, #fff);
            text-align: center;

            /* Text M/Reguler */
            // font-family: Poppins;
            font-size: calc(
              12px + (14 - 12) * ((100vw - 280px) / (1600 - 280))
            );
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
            cursor: pointer;
          }
        }
      }
    }

    .main {
      border-top: 1px solid #e0e0e0;
      border-right: 1px solid #e0e0e0;
      border-left: 1px solid #e0e0e0;
      height: 100%;
      background: var(--Color-Neutal-Neutral-10, #fff);
    }
  }
}

.notification {
  position: fixed;
  top: -200px; /* start above the viewport */
  left: 50%;
  transform: translateX(-50%);
  border-radius: 8px;
  background: var(--Success-Main, #27bb83);
  color: var(--Neutral-10, var(--Color-Neutal-Neutral-10, #fff));
  // text-align: center;

  /* Text M/Medium */
  // font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  padding: 8px 12px;
  // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: top 0.5s ease-in-out;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  &.show {
    top: 20px;
  }
}

@media screen and (max-width: 1554px) {
  .container {
    .header {
      .lastHeader {
        .stepperBox {
          overflow: scroll;
          max-width: 100%;

          .step {
            min-width: 270px;
            margin-right: -13px;
            img {
              height: 60px;
            }

            > div {
              // position: absolute;
              top: 15px;
              left: 12%;
              &.leftPlace {
                left: 10%;
              }

              > div {
                width: 28px;
                height: 28px;

                font-size: calc(
                  12px + (14 - 12) * ((100vw - 280px) / (1600 - 280))
                );
              }

              > span {
                color: var(
                  --Neutral-100,
                  var(--Color-Neutal-Neutral-19, #0a0a0a)
                );

                /* Text S/Medium */
                // font-family: Poppins;
                font-size: calc(
                  10px + (14 - 10) * ((100vw - 280px) / (1600 - 280))
                );
                font-style: normal;
                font-weight: 500;
                line-height: 20px; /* 153.846% */
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 820px) {
  .container {
    .header {
      .topHeader {
        .top {
          flex-direction: column;
          align-items: flex-start;
          gap: 10px;

          .filterBox {
            // order: 1;
            width: 100%;
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;
            .buttonFilter {
              width: 100%;
              order: 2;
            }
            .searchWrapper {
              width: 100%;
              order: 1;
            }
          }
        }
        .listFilterBox {
          flex-direction: column;
          align-items: flex-start;
          > div {
            width: 100%;
            justify-content: space-between;
          }
        }
      }
    }

    .tableContainer {
      .downloadReportBox {
        flex-direction: column;
        // gap: 8px;
        align-items: flex-start;
        .left {
          width: 100%;
          > div {
            width: 100%;
            min-width: unset;
          }
        }
        .right {
          width: 100%;
          > div {
            width: 100%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .container {
    .header {
      .topHeader {
        .top {
          .buttonBox {
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            .buttonRefresh {
              order: 1;
            }
            .buttonType {
              order: 2;
              width: 100%;
              > div {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
